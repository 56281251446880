/** @format */

import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as FormikMuiLab from "formik-material-ui-lab";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Hooks from "src/app/hooks";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";

export declare namespace Form {
  export interface Props {
    editingArticle?: Models.Set.Article;
  }

  export interface Values {
    name: any;
    code: any;
    hundCode: string;
    description: string;
    industrySegmentsId: string;
    isFree?: boolean;
    validityPeriodInDays: number;
    licenseValidityPeriod?: number;
    netPricePerItem: number;
    licensesPerArticle: number;
    iconClass: string;
    linkForMoreInfo: string;
    setID: Array<string | number>;
    dependentSets?: Array<number>;
    isLimited?: boolean;
  }

  export namespace Options {
    export interface Icon {
      iconName: string;
      iconType: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export function Form({ editingArticle }: Form.Props) {
  const { t } = ReactI18next.useTranslation();
  const [iconOptions, setIconOptions] = React.useState<Form.Options.Icon[]>([]);
  const [functionOptions, setFunctionOptions] = React.useState<
    Models.Set.Function[]
  >([]);
  const [articleNameExists, setArticleNameExists] =
    React.useState<string>("false");
  const [articleCodeExists, setArticleCodeExists] =
    React.useState<string>("false");
  const [articleModelExists, setArticleModelExists] =
    React.useState<string>("false");
  const [initError, setInitError] = React.useState<boolean>(false);
  const [articleName, setArticleName] = React.useState<string>("");
  const [articleCode, setArticleCode] = React.useState<string>("");
  const [articleModel, setArticleModel] = React.useState<string>("");
  const [dependentSetOptions, setDependentSetOptions] = React.useState<
    Models.Set.Article[]
  >([]);
  const [industrySegmentOptions, setIndustrySegmentOptions] = React.useState<
    Models.IndustrySegment.Main[]
  >([]);
  const { touched, errors, values, setFieldValue } =
    Formik.useFormikContext<Form.Values>();
  const forceUpdate = Hooks.useForceUpdate();

  const iconService = new Services.Icon({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const functionService = new Services.Function({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const industrySegmentService = new Services.IndustrySegment({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    (async function () {
      try {
        const {
          result: industrySegments,
        } = await industrySegmentService.getAllData();
        setIndustrySegmentOptions(
          industrySegments.filter((industry) => industry.status === 1)
        );

        if (values.industrySegmentsId) {
          values.industrySegmentsId = (industrySegments.find(
            (industrySegment) =>
              // eslint-disable-next-line eqeqeq
              ((values.industrySegmentsId as unknown) as string) ==
              ((industrySegment.industrySegmentsId as unknown) as string)
          ) as unknown) as string;
          forceUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    (async function () {
      try {
        const { result: icons } = await iconService.getAllData();
        setIconOptions(icons);
        if (values.iconClass) {
          values.iconClass = {
            iconName: values.iconClass,
          } as unknown as string;
          forceUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async function () {
      try {
        const result = await functionService.getAllData();
        const functions = result.result
          .filter((func) => func.status === 1)
          .map(
            ({ funcationName, iconClass, setFunctionID }) =>
              ({
                funcationName,
                iconClass,
                setFunctionID,
              } as Models.Set.Function)
          );
        setFunctionOptions(functions);

        if (values.setID) {
          values.setID = functions.filter((func) =>
            values.setID.includes(func.setFunctionID)
          ) as unknown as Array<string | number>;
          forceUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async function () {
      try {
        const result = await articleService.getAllData();
        const articles = result.result.filter((article) =>
          editingArticle
            ? article.status === 1 && article.setID !== editingArticle?.setID
            : article.status === 1
        );

        setDependentSetOptions(articles);

        if (values.dependentSets) {
          values.dependentSets = articles.filter((article) =>
            values.dependentSets?.includes(article.setID)
          ) as unknown as Array<number>;
          forceUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingArticle]);

  

  React.useEffect(() => {
    if (
      Configs.Workaround.isCloudArticle({ code: values.code }) ||
      Configs.Workaround.isCloudArticle({ code: values.hundCode })
    ) {
      setFieldValue("licensesPerArticle", 1);
    }
  }, [setFieldValue, values]);
  const articleStore = React.useContext(App.Contexts.articleStore);
  const handleArticleName = (event: any) => {
    setArticleName(event.target.value);
    const articleNameFilter = articleStore.articles
      .map((k) => k.setName)
      .includes(event.target.value)
      .toString();
    setArticleNameExists(articleNameFilter);
    localStorage.setItem("articleName", event.target.value);
  };
  const handleArticleCode = (event: any) => {
    setArticleCode(event.target.value);
    const articleCodeFilter = articleStore.articles
      .map((k) => k.setCode)
      .includes(event.target.value)
      .toString();
    setArticleCodeExists(articleCodeFilter);
    localStorage.setItem("articleCode", event.target.value);
  };
  const handleArticleModel = (event: any) => {
    setArticleModel(event.target.value);
    const articleModelFilter = articleStore.articles
      .map((k) => k.hundCode)
      .includes(event.target.value)
      .toString();
    setArticleModelExists(articleModelFilter);
    localStorage.setItem("articleModel", event.target.value);
  };

  return (
    <>
      <Formik.Form>
        <Mui.Grid container spacing={4}>
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name"
              component={FormikMui.TextField}
              label={
                <ReactI18next.Trans i18nKey={"Article name"}>
                  Article name
                </ReactI18next.Trans>
              }
              fullWidth
              autoFocus

            
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="code"
             
              component={FormikMui.TextField}
              label={
                <ReactI18next.Trans i18nKey={"Article code (Type code)"}>
                  Article code (Type code)
                </ReactI18next.Trans>
              }
              fullWidth
             
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="hundCode"
              component={FormikMui.TextField}
            
              label={
                <ReactI18next.Trans i18nKey={"Model number"}>
                  Model number
                </ReactI18next.Trans>
              }
              fullWidth
             
            />
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="description"
              component={FormikMui.TextField}
              label={
                <ReactI18next.Trans i18nKey={"Article description"}>
                  Article description
                </ReactI18next.Trans>
              }
              multiline
              rows={3}
              rowsMax={5}
              fullWidth
            />
          </Mui.Grid>


         
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="number"
              name="licenseValidityPeriod"
              component={FormikMui.TextField}
              // disabled={!values.isFree}
              label={
                <ReactI18next.Trans i18nKey={"Validity period in days"}>
                  Validity period in days
                </ReactI18next.Trans>
              }
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="number"
              name="netPricePerItem"
              component={FormikMui.TextField}
              label={
                Configs.Workaround.isCloudArticle({ code: values.code }) ||
                Configs.Workaround.isCloudArticle({
                  code: values.hundCode,
                }) ? (
                  <ReactI18next.Trans i18nKey={"Price (net) per month in €"}>
                    Price (net) per month in €
                  </ReactI18next.Trans>
                ) : (
                  <ReactI18next.Trans i18nKey={"Price (net) per item in €"}>
                    Price (net) per item in €
                  </ReactI18next.Trans>
                )
              }
              fullWidth
            />
          </Mui.Grid>
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Mui.FormControl
              component="fieldset"
            >
              <Mui.FormGroup>
                <Mui.FormControlLabel
                  control={
                    <Formik.Field
                      name="isLimited"
                      type="checkbox"
                      component={FormikMui.Checkbox}
                    />
                  }
                  label={t("Is unlimited installation ?")}
                />
             
              </Mui.FormGroup>
            </Mui.FormControl>
          </Mui.Grid>
          {!values.isLimited && <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="number"
              name="licensesPerArticle"
              component={FormikMui.TextField}
              disabled={
                Configs.Workaround.isCloudArticle({
                  code: values.hundCode,
                }) || Configs.Workaround.isCloudArticle({ code: values.code }) 
              }
              label={
                Configs.Workaround.isCloudArticle({
                  code: values.hundCode,
                }) ||
                Configs.Workaround.isCloudArticle({ code: values.code }) ? (
                  <ReactI18next.Trans i18nKey={"Users per article"}>
                    Users per article
                  </ReactI18next.Trans>
                ) : (
                  <ReactI18next.Trans i18nKey={"Licenses per article"}>
                    Licenses per article
                  </ReactI18next.Trans>
                )
              }
              fullWidth
            />
          </Mui.Grid>}

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              component={FormikMuiLab.Autocomplete}
              name="iconClass"
              fullWidth
              options={iconOptions}
              getOptionLabel={(option: Form.Options.Icon) => option.iconName}
              renderTags={(tagValue: Form.Options.Icon[], getTagProps: any) =>
                tagValue.map((option, index) => (
                  <Mui.Chip
                    label={option.iconName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(option: Form.Options.Icon) => (
                <Mui.Box key={uuidv4()} display="flex" alignItems="baseline">
                  <Mui.Box marginRight={1} component="span">
                    <i className={option.iconName} />
                  </Mui.Box>
                  <Mui.Typography variant="body1">
                    {option.iconName}
                  </Mui.Typography>
                </Mui.Box>
              )}
              renderInput={(
                params: FormikMuiLab.AutocompleteRenderInputParams
              ) => (
                <Mui.TextField
                  {...params}
                  name="iconClass"
                  error={
                    touched["iconClass"] && errors["iconClass"] ? true : false
                  }
                  helperText={touched["iconClass"] && errors["iconClass"]}
                  label={
                    <ReactI18next.Trans i18nKey={"Icon name"}>
                      Icon name
                    </ReactI18next.Trans>
                  }
                />
              )}
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="linkForMoreInfo"
              component={FormikMui.TextField}
              label={
                <ReactI18next.Trans i18nKey={"Link for more information"}>
                  Link for more information
                </ReactI18next.Trans>
              }
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              component={FormikMuiLab.Autocomplete}
              multiple
              name="setID"
              fullWidth
              options={functionOptions}
              getOptionLabel={(option: Models.Set.Function) =>
                option.funcationName
              }
              renderTags={(tagValue: Models.Set.Function[], getTagProps: any) =>
                tagValue.map((option, index) => (
                  <Mui.Chip
                    label={option.funcationName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(option: Models.Set.Function) => (
                <Mui.Box key={uuidv4()} display="flex" alignItems="baseline">
                  <Mui.Box marginRight={1} component="span">
                    <i className={option.iconClass} />
                  </Mui.Box>
                  <Mui.Typography variant="body1">
                    {option.funcationName}
                    
                  </Mui.Typography>
                </Mui.Box>
              )}
              renderInput={(
                params: FormikMuiLab.AutocompleteRenderInputParams
              ) => (
                <Mui.TextField
                  {...params}
                  name="setID"
                  error={touched["setID"] && errors["setID"] ? true : false}
                  helperText={touched["setID"] && errors["setID"]}
                  label={
                    <ReactI18next.Trans i18nKey={"Select functions"}>
                      Select functions
                    </ReactI18next.Trans>
                  }
                />
              )}
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              component={FormikMuiLab.Autocomplete}
              multiple
              name="dependentSets"
              fullWidth
              options={dependentSetOptions}
              getOptionLabel={(option: Models.Set.Article) => option.setName}
              renderTags={(tagValue: Models.Set.Article[], getTagProps: any) =>
                tagValue.map((option, index) => (
                  <Mui.Chip
                    label={option.setName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(option: Models.Set.Article) => (
                <Mui.Box key={uuidv4()} display="flex" alignItems="baseline">
                  <Mui.Box marginRight={1} component="span">
                    <i className={option.iconClass} />
                  </Mui.Box>
                  <Mui.Typography variant="body1">
                    {option.setName}
                  </Mui.Typography>
                </Mui.Box>
              )}
              renderInput={(
                params: FormikMuiLab.AutocompleteRenderInputParams
              ) => (
                <Mui.TextField
                  {...params}
                  name="dependentSets"
                  error={
                    touched["dependentSets"] && errors["dependentSets"]
                      ? true
                      : false
                  }
                  helperText={
                    touched["dependentSets"] && errors["dependentSets"]
                  }
                  label={
                    <ReactI18next.Trans i18nKey={"Select dependent articles"}>
                      Select dependent articles
                    </ReactI18next.Trans>
                  }
                />
              )}
            />
          </Mui.Grid>
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              component={FormikMuiLab.Autocomplete}
              name="industrySegmentsId"
              fullWidth
              options={industrySegmentOptions}
              getOptionLabel={(option: Models.IndustrySegment.Main) =>
                option.nameEN
              }
              renderInput={(
                params: FormikMuiLab.AutocompleteRenderInputParams
              ) => (
                <Mui.TextField
                  {...params}
                  name="industrySegmentsId"
                  error={
                    touched["industrySegmentsId"] &&
                    errors["industrySegmentsId"]
                      ? true
                      : false
                  }
                  helperText={
                    touched["industrySegmentsId"] &&
                    errors["industrySegmentsId"]
                  }
                  label={
                    <ReactI18next.Trans i18nKey={"Industry / Segment"}>
                      Industry / Segment
                    </ReactI18next.Trans>
                  }
                />
              )}
            />
          </Mui.Grid>
          <Mui.Grid item xs={12} >
            <Mui.FormControl
              error={errors.isFree ? true : false}
              component="fieldset"
            >
            
              <Mui.FormGroup>
                <Mui.FormControlLabel
                  control={
                    <Formik.Field
                      name="isFree"
                      type="checkbox"
                      component={FormikMui.Checkbox}
                    />
                  }
                  label={t("Enable trial")}
                />
             
              </Mui.FormGroup>
              {errors.isFree ? (
                <Mui.FormHelperText error>{errors.isFree}</Mui.FormHelperText>
              ) : null}
            </Mui.FormControl>
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4} >
            <Formik.Field
              type="number"
              name="validityPeriodInDays"
              component={FormikMui.TextField}
              disabled={!values.isFree}
              label={
                <ReactI18next.Trans i18nKey="Trial period in days">
                  Trial period in days
                </ReactI18next.Trans>
              }
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
