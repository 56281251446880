/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";

export declare namespace InviteStatus {
  export type Status = 1 | 2 | 3 | 4 | 5;
  export type Labels = {
    [Key in InviteStatus.Status]: string;
  };

  export interface Props {
    labels?: InviteStatus.Labels;
    status: InviteStatus.Status;
  }

  export interface GetLabelProps
    extends Pick<InviteStatus.Props, "labels" | "status"> {}
}

export function getLabels({
  status,
  labels,
}: InviteStatus.GetLabelProps): string {
  if (!labels && status === 1) {
    return "Invite sent";
  }

  if (!labels && status === 2) {
    // return "Import-ETLicense";
    return "License assigned";
  }

  if (!labels && status === 3) {
    return "License activated";
  }
  if (!labels && status === 4) {
    return "Direct customer";
    
  }
  if (!labels && status === 5) {
    return "License unassigned";
    
  }
  if (!labels) {
    return "";
  }

  return labels[status];
}


// eslint-disable-next-line no-redeclare
export function InviteStatus({ status, labels }: InviteStatus.Props) {
  const theme = Mui.useTheme();

  return (
    <Mui.Chip
      size="medium"
      label={getLabels({ status, labels })}
     
      variant="outlined"
      icon={
        status === 1 ? (
          <MuiIcons.CheckCircle style={{ color: theme.palette.success.main }} />
        ) : status === 2 ? (
          <MuiIcons.ArrowCircleDown
            style={{ color: theme.palette.primary.main }}
          />
        ) : status === 3 ? (
          <MuiIcons.PlayCircleOutline
            style={{ color: theme.palette.primary.dark }}
          />
        ) :  status === 4 ? (
          <MuiIcons.ExitToApp
          style={{ color: theme.palette.info.light }}
        />
        ) :  status === 5 ? (
          <MuiIcons.Cancel
          style={{ color: theme.palette.secondary.light }}
        />
        ):(
          <></>
        )
      }
    />
  );
}
