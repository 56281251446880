/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace EasyTouchDevice {
  export interface IEasyTouchDevice {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = EasyTouchDevice.IEasyTouchDevice;

  export namespace GetAllCustomerData {
    export interface Props {
      rows?: number;
      pageId?: number;
    }

    export type Return = Models.API.Response<Models.Customer.Main[]>;
  }

  export namespace GetAllData {
    export interface Body {
      status: null | number;
      userID?: string | number;
    }

    export interface Props {
      body?: EasyTouchDevice.GetAllData.Body;
      status?: number;
      userRole: string;
      uid: number;
    }

    export type Return = Models.API.Response<Models.License[]>;
  }
}

// eslint-disable-next-line no-redeclare
export class EasyTouchDevice {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: EasyTouchDevice.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllCustomerData(
    props?: EasyTouchDevice.GetAllCustomerData.Props
  ): Promise<EasyTouchDevice.GetAllCustomerData.Return> {
    const url = this.urlFactory.create({
      named: ["UserManager", "getcustomers"],
    });

    const response =
      await this.httpManager.get<EasyTouchDevice.GetAllCustomerData.Return>({
        url,
      });

    return response;
  }

  public async getAllData({
    body,
    userRole,
    status,
    uid,
  }: EasyTouchDevice.GetAllData.Props): Promise<EasyTouchDevice.GetAllData.Return> {
    const url =
      userRole === "customer"
        ? this.urlFactory.create({
            named: ["license", "get", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["license", "GetAdmin"],
          });

    const response = await (userRole === "customer"
      ? this.httpManager.get<EasyTouchDevice.GetAllData.Return>({
          url,
        })
      : this.httpManager.post<
          EasyTouchDevice.GetAllData.Return,
          EasyTouchDevice.GetAllData.Body
        >({
          body: body ?? {
            status: null,
          },
          url,
        }));

    return response;
  }
}
