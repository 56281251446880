/** @format */

import * as Mui from "@material-ui/core";
import { TrendingUpRounded } from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Shop from "src/app/pages/shop";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";
import { number } from "yup";

const useStyles = Mui.makeStyles((theme) => ({
  message: {
    width: "100%",
  },
  root:
    theme.palette.type === "light"
      ? {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.primary.main,
        }
      : {},
}));

export const Main = Mobx.observer(function () {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);
  const [functionData, setFunctionData] = React.useState<Models.Set.Function[]>(
    []
  );
  const [groupFilter, setGroupFilter] = React.useState<string>("All groups");
  const [search, setSearch] = React.useState<string>("");
  const theme = Mui.useTheme();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const userStore = React.useContext(App.Contexts.userStore);
  const cartStore = React.useContext(App.Contexts.cartStore);
  const articleStore = React.useContext(App.Contexts.articleStore);
  const { i18n } = ReactI18next.useTranslation();
  const classes = useStyles();
  const { pathname } = Router.useLocation();
  const [basicData, setBasicData] = React.useState("");
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  const homeStore = React.useContext(App.Contexts.homeStore);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    // userStore.setPreviousUrl({ urls: "/dealercustomer?roles=all" });
    const userRole = "customer";
    if (uid !== undefined && uid !== null) {
      dCustomerStore.read({
        uid,
        userRole,
      });
    } 
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dCustomerStore]);
  React.useEffect(() => {
    const uid = userStore.user?.ETUID ? userStore.user?.ETUID : "0";
    //alert(uid);
    const userRole = "customer"
    const EmailId = userStore.user?.UserEmail ? userStore.user?.UserEmail : undefined;
    const status = 1;
    if (uid !== undefined && uid !== null) {
      homeStore.read({
        uid,
        userRole,
        EmailId,
      });
    }

    if (
      userRole === ("customer" || "dealer") &&
      uid !== undefined &&
      uid !== null
    ) {
      articleStore.read({status:status});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(()=> {
    async function fetchMyAPI() {
     
      const value = userStore.user?.UserEmail;
      const res : any= await customerInviteStore.getCustomerDetails({
                EmailID: value,
                ETUID: '0',
             });
             const result: any = res?.result;
             const isBasicSet = result?.map?.((k: any) => k?.isBasicSetFlag);
             setBasicData(isBasicSet)
    }
    fetchMyAPI()
    localStorage.setItem("BasicSetFromShop", "true")
  },[customerInviteStore, userStore.user])

  if(typeof basicData !== 'undefined' && basicData.length > 0 ){
    const strConvertBasic  = basicData?.toString()
    localStorage.setItem("isBasicSetFromShop", strConvertBasic)
  }


  React.useEffect(() => {
    if (articleStore.articles.length) {
      return;
    }

    articleStore.read({
      status: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const customerDealerId = userStore.user?.CustomerDealerID;

   

    const articleService = new Services.Article({
      httpManager: new Services.Managers.Axios({ agent: App.axios }),
      urlFactory: new Services.Factories.URL({
        protocol: Constants.environment.apiBaseUrlProtocol as "http",
        hostname: Constants.environment.apiBaseUrlHostname,
        version: Constants.environment.apiBaseUrlVersion,
      }),
    });
    const functionService = new Services.Function({
      httpManager: new Services.Managers.Axios({ agent: App.axios }),
      urlFactory: new Services.Factories.URL({
        protocol: Constants.environment.apiBaseUrlProtocol as "http",
        hostname: Constants.environment.apiBaseUrlHostname,
        version: Constants.environment.apiBaseUrlVersion,
      }),
    });

    functionService
      .getAllData({ userRole: userStore.user?.RoleName ?? "customer" })
      .then((result) => setFunctionData(result.result))
      .catch(console.error);

    setIsLoading(true);
    articleService
      .getAllDataWithPrice({
        customerDealerId: customerDealerId || 0,
      })
      .then((result) => {
        setIsLoading(false);
        const { sageOLPriceRespone, sets } = result.result as any;
        const taxPercent = sageOLPriceRespone?.vatTaxPercent;

        cartStore.setVatTaxPercent({
        percent: taxPercent as number,
        });

        const reducedSets = sets.reduce((reducedSets: any[], set: any) => {
          const [langData] = set.setsLang.filter(
            (lang: any) =>
              lang.langCode.toLowerCase() === i18n.language.toLowerCase()
          );
          set.setCost = sageOLPriceRespone.adressNr === 0 ? set.setCost : sageOLPriceRespone.positions
          .filter((position: any) => position.article === set.setCode)
          .map((position: any) => position.singleNetPrice);
          return (reducedSets = [
            ...reducedSets,
            {
              ...set,
              // TODO: Remove this temporary section after fixing the olPrice in the new API controllers
              ...(true && {
                setCost: set.setCost,
                vatPercentage: taxPercent,
                olPrice: {
                  amount: set.setCode === "TSEQ-01-A" ? "990":  set.setCost,
                  article: set,
                  grossPositionSinglePriceInclVAT: set.setCode === "TSEQ-01-A" ? "990":  set.setCost,
                  grossPositionTotalPriceInclVAT: set.setCode === "TSEQ-01-A" ? "990":  set.setCost,
                  singleNetPrice: set.setCode === "TSEQ-01-A" ? "990":  set.setCost,
                  totalNetPrice: set.setCode === "TSEQ-01-A" ? "990":  set.setCost,
                  vatTaxPercent: taxPercent,
                  status: 1,
                  message: "Some message",
                },
              }),
              ...(langData && {
                setName: langData.setName,
                setDescriptions: langData.setDescriptions,
                industrySegment: langData.industrySegment,
              }),
            },
          ]);
        }, []);

        setData(reducedSets);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
const [enterKeyEvent, setEnterKeyEvent] = React.useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as string;
    setGroupFilter(status);
  };
  const navigate = Router.useNavigate();
  const { t } = ReactI18next.useTranslation();


  return (
    <>
  
    {/* <Mui.Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={cartStore.cart.size > 0 && pathname === "/shop"}
        onClose={() => {}}
        ContentProps={{
          classes: {
            message: classes.message,
            root: classes.root,
          },
        }}
        message={
            // <Mui.Button
            //   fullWidth
            //   variant="contained"
            //   color="primary"
            //   component={Router.Link}
            //   to="/shop/cart"
            // >
            //   <ReactI18next.Trans i18nKey="Proceed to checkout">Proceed to checkout</ReactI18next.Trans>
              
            // </Mui.Button>
        }
      /> */}
      

      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.FormControl style={{ width: 300, marginRight: theme.spacing(2) }}>
          <Mui.InputLabel id="demo-simple-select-outlined-label">
            <ReactI18next.Trans i18nKey="Filter by group">
              Filter by group
            </ReactI18next.Trans>
          </Mui.InputLabel>
          <Mui.Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={groupFilter}
            onChange={handleChange}
            label={t("Filter by group")}
          >
            <Mui.MenuItem value="All groups">
              <ReactI18next.Trans i18nKey="All groups">
                All groups
              </ReactI18next.Trans>
            </Mui.MenuItem>
            {Array.from(
              data
                .reduce((acc: Set<any>, set) => {
                  return acc.add(set.industrySegment);
                }, new Set())
                .values()
            ).map((industrySegment: any) => (
              <Mui.MenuItem key={uuidv4()} value={industrySegment}>
                {industrySegment}
              </Mui.MenuItem>
            ))}
          </Mui.Select>
        </Mui.FormControl>
        <Mui.FormControl style={{ width: 300 }}>
          <Mui.TextField label={t("Search")} onChange={handleSearch} />
        </Mui.FormControl>
      </Mui.Grid>

      <Mui.Grid container spacing={3} style={{ height: "100%" }}>
        {articleStore.articles.length < 1 && data.length < 1 && isLoading && (
          <Mui.Box
            padding={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            minHeight={"100%"}
            maxHeight={"100%"}
            height={"100%"}
            flexDirection="column"
          >
            <Mui.CircularProgress />
            <MuiLab.Alert
              severity="info"
              style={{ marginTop: theme.spacing(3) }}
            >
              <ReactI18next.Trans i18nKey="We are building your articles for purchase with best pricing offers.">
                We are building your articles for purchase with best pricing
                offers.
              </ReactI18next.Trans>
            </MuiLab.Alert>
          </Mui.Box>
        )}
        {((data.length && data) || articleStore.articles)
          .filter((set) => {
            if (groupFilter === "All groups") {
              return true;
            }

            return set.industrySegment === groupFilter;
             })
             //Sorting based on Article Set Code
             .sort(function (a, b) {
              const setNumbersA = parseInt((a.setCode.replace(/^'|'$/g, "").split(/\s*\-\s*/g))[1])
              const setNumbersB = parseInt((b.setCode.replace(/^'|'$/g, "").split(/\s*\-\s*/g))[1])
              return setNumbersA - setNumbersB;
            })
          .filter((set) => {
            if (!search) {
              return true;
            }
            return (
              (set.setName &&
                set.setName.toLowerCase().includes(search.toLowerCase())) ||
              (set.setDescriptions &&
                set.setDescriptions
                  .toLowerCase()
                  .includes(search.toLowerCase())) ||
              (set.industrySegment &&
                set.industrySegment
                  .toLowerCase()
                  .includes(search.toLowerCase())) ||
              (set.setCode &&
                `${set.setCode}`
                  .toLowerCase()
                  .includes(search.toLowerCase())) ||
              (set.setCost &&
                `${set.setCost}`.toLowerCase().includes(search.toLowerCase()))
            );
          })
          .map((set) => (
            set.hundCode !== "SEQ-01" && <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={3}>
              <Shop.Card
                isLoading={isLoading}
                functionData={functionData}
                article={set as Models.Set.Article}
                handleAddToCartClick={({ article }) => {
                  if(userStore.user?.RoleName === "dealer"){
                    localStorage.setItem("DealerBasicSetValidation","true");
                    cartStore.addProduct({product: article});
                    enqueueSnackbar(t("Added to cart!"), {
                      variant: "success",
                      preventDuplicate: false,
                    });
                  }else{
                  
                      
                      let validationData = [];
                      let dashboardReplica:any = [];
                      dashboardReplica = homeStore.dashboard?.licenseSummary
                        for (var i = 0; i < dashboardReplica.length; i++) {
                        dashboardReplica[i].totalQuantity = +dashboardReplica[i].totalQuantity;
                      }
                      const dash = dashboardReplica.map((k:any)=>{
                        return {
                          hundCode: k.hundCode,
                          totalQuantity: k.totalQuantity / 4,
                          setName: k.setName
                        }
                      })
                      
                       let  cartStores:any = Array.from(cartStore.cart.values())
                       
                        
                       for (var j = 0; j < cartStores.length; j++) {
                        cartStores[j].quantity = +cartStores[j].quantity;
                    }
                    const cart = cartStores.map((k:any)=>{
                      return {
                        hundCode: k.hundCode,
                        totalQuantity: k.quantity,
                        setName: k.setName
                      }
                    })
                    
                       if(dashboardReplica.length > 0 && cartStores.length > 0){
                        const result = Object.values([...cart, ...dash].reduce((acc, { hundCode, totalQuantity,setName }) => {
                          acc[hundCode] = { hundCode, totalQuantity: (acc[hundCode] ? acc[hundCode].totalQuantity : 0) + totalQuantity,setName  };
                          return acc;
                        }, {}));
                        validationData = result;
                        
                         
                       }
                       else if(dashboardReplica.length > 0 && (cartStores.length === 0 || cartStores === undefined )){
                        const dash = dashboardReplica.map((k:any)=>{
                          return {
                            hundCode: k.hundCode,
                            totalQuantity: k.totalQuantity / 4,
                            setName: k.setName
                          }
                        })
                        validationData = dash
                      }
                      else if((dashboardReplica.length === 0 || dashboardReplica === undefined ) && cartStores.length > 0){
                        const cart = cartStores.map((k:any)=>{
                          return {
                            hundCode: k.hundCode,
                            totalQuantity: k.quantity,
                            setName: k.setName
                          }
                        })
                        validationData = cart;
    
                      }
                      else
                      {
                        validationData = [];
                      }
                      
                      
                    
                      for(var m = 0; m < validationData.length; m++){
                        if(validationData[m].hundCode === article.hundCode){
                          validationData[m].totalQuantity = validationData[m].totalQuantity + 1;
                        }
                        
                      }
                      
                      const validationCheckfilter = validationData.filter((k:any)=>k.hundCode === article.hundCode)
                      if(validationCheckfilter.length > 0){
                        
                        
                      }
                      else
                      {
                        
                        validationData.push({
                        hundCode: article.hundCode,
                        totalQuantity: 1,
                        setName: article.setName
                        })
                      }
    
                      
                      const filteringSelectedArticleQuantity = validationData.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)
                      const filteringBasicSetQuantity= validationData.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
                     
                      if(article.hundCode === "SET-01"){
                        cartStore.addProduct({ product: article })
                          enqueueSnackbar(t("Added to cart!"), {
                            variant: "success",
                            preventDuplicate: false,
                          });
                      }
                      else if(article.hundCode !== "SET-01"){
    
                        const FilteringSelectedArticleQuantityisZeroChecks = filteringSelectedArticleQuantity.length === 0 || filteringSelectedArticleQuantity.length === undefined || filteringSelectedArticleQuantity.length === null ? 0 : filteringSelectedArticleQuantity[0]
                        const FilteringBasicSetQuantityisZeroChecks = filteringBasicSetQuantity.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
                        
                        const checkForAddToCart = FilteringSelectedArticleQuantityisZeroChecks <= FilteringBasicSetQuantityisZeroChecks 
                        
                        const cartStoress = cartStores.map((k:any)=>{
                          return {
                            hundCode: k.hundCode,
                            totalQuantity: k.totalQuantity,
                            setName: k.setName
                          }
                        })
                        const filteringBasicSetQuantityAutomateBasicAdd= cartStoress.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
                        const FilteringBasicSetQuantityAutomateBasicAddInCart = filteringBasicSetQuantityAutomateBasicAdd.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
                        let isEnableBasicset = false;
                        if((FilteringBasicSetQuantityisZeroChecks === 0 )&& (FilteringBasicSetQuantityAutomateBasicAddInCart === 0)){
                          isEnableBasicset = true
                          const articled:any = data.filter((k:any) => k.hundCode === "SET-01")
                          
                              const articles = Object.assign({}, ...articled);
                              cartStore.addProduct({ product: articles })
                              enqueueSnackbar(t("Added to cart!"), {
                                variant: "success",
                                preventDuplicate: false,
                              });
                              }else{
                                isEnableBasicset = false
                              }
                        
                        if((checkForAddToCart === true)|| (isEnableBasicset === true)){
                          cartStore.addProduct({ product: article })
                          enqueueSnackbar(t("Added to cart!"), {
                            variant: "success",
                            preventDuplicate: false,
                          });
                        }
                        else
                        {
                          enqueueSnackbar(
                                    t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                                    {
                                      variant: "warning",
                                      preventDuplicate: false,
                                    }
                                  );
                        }
                     
                    }
    
                     
                    }
                  
                  }}
              />
            </Mui.Grid>
          ))}
      </Mui.Grid>
    </>
  );
});
