import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    appbar: {
      maxWidth: theme.breakpoints.values.md,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    paper: {
      maxWidth: theme.breakpoints.values.md,
      width: "100%",
    },
    title: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
  })
);

export declare namespace LightBox {
  export type VARIANT =
    | "DEFAULT"
    | "RESPECT_ONLY_CONTENT_AND_IGNORE_COMPONENT_STYLES";

  export interface Props {
    open: boolean;
    handleClose(): void;
    title: React.ReactNode;
    content: React.ReactNode;
    actions?: React.ReactNode;
    variant?: LightBox.VARIANT;
  }
}

// eslint-disable-next-line no-redeclare
export function LightBox({
  open,
  handleClose,
  content,
  title,
  actions,
  variant = "DEFAULT",
}: LightBox.Props) {
  const classes = useStyles();
  const theme = Mui.useTheme();

  return (
    <Mui.Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Mui.Container maxWidth="md" style={{ padding: 0 }}>
        <div className={classes.root}>
          <Mui.AppBar
            color="inherit"
            className={classes.appbar}
            position="fixed"
          >
            <Mui.Toolbar>
              {title}
              <Mui.IconButton onClick={handleClose}>
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </div>
        <div className={classes.toolbar} style={{ marginBottom: 12 }} />
        {variant === "RESPECT_ONLY_CONTENT_AND_IGNORE_COMPONENT_STYLES" ? (
          content
        ) : (
          <>
            <Mui.Box padding={3}>{content}</Mui.Box>
            {actions && (
              <>
                <div className={classes.toolbar} style={{ marginBottom: 12 }} />
                <Mui.Box
                  position="fixed"
                  bottom={0}
                  width={1}
                  maxWidth={theme.breakpoints.values.md}
                  style={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Mui.Divider />
                  <Mui.DialogActions>{actions}</Mui.DialogActions>
                </Mui.Box>
              </>
            )}
          </>
        )}
      </Mui.Container>
    </Mui.Drawer>
  );
}
