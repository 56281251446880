import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as Icon from "src/app/pages/icon";
import { v4 as uuidv4 } from "uuid";

export const Main = Mobx.observer(function () {
  const theme = Mui.useTheme();
  const iconStore = React.useContext(App.Contexts.iconStore);

  React.useEffect(() => {
    iconStore.read({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {iconStore.uploadDialogOpen && (
        <Icon.Upload
          open={iconStore.uploadDialogOpen}
          handleClose={() => {
            iconStore.setUploadDialogOpen({ uploadDialogOpen: false });
            iconStore.read({});
          }}
        />
      )}

      {iconStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          <Mui.Paper>
            <Mui.Box padding={2}>
              <Mui.Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gridGap: theme.spacing(3),
                }}
              >
                {iconStore.icons.map((data: any) => (
                  <Mui.Grid item key={uuidv4()}>
                    <Mui.Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Mui.Box fontSize={"h3.fontSize"}>
                        <i className={data.iconName} />
                      </Mui.Box>
                      <Mui.Typography variant="body2" color="textSecondary">
                        {data.iconName}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                ))}
              </Mui.Box>
            </Mui.Box>
          </Mui.Paper>
        </>
      )}
    </>
  );
});
