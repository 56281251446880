import * as Mui from "@material-ui/core";
import * as ReactUndraw from "iblis-react-undraw";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";

export function Cancelled() {
  const theme = Mui.useTheme();
  const cartStore = React.useContext(App.Contexts.cartStore);
  const navigate = Router.useNavigate();

  React.useEffect(() => {
    (async function () {
      try {
        const orderDetails = localStorage.getItem("orderDetails");
        if (orderDetails) {
          cartStore.resetCart();
          navigate("/shop/payment/success");
          return;
        }

        if (!orderDetails && cartStore.cart.size === 0) {
          navigate("/shop");
          return;
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Mui.Paper>
        <Mui.Box padding={2}>
          <ReactUndraw.SvgCancel
            style={{
              marginBottom: theme.spacing(6),
            }}
            accentcolor={theme.palette.primary.main}
            primarycolor={theme.palette.secondary.main}
            height={300}
          />
          <Mui.Typography variant="h4" align="center" gutterBottom>
            <ReactI18next.Trans i18nKey="We couldn't process your payment">
              We couldn't process your payment
            </ReactI18next.Trans>
          </Mui.Typography>
          <Mui.Typography
            variant="body2"
            align="center"
            style={{ marginBottom: theme.spacing(4) }}
          >
            <ReactI18next.Trans
              i18nKey="Unfortunately, we couldn't collect payment on your purchase. Please
            take a moment to review your order and continue purchase."
            >
              Unfortunately, we couldn't collect payment on your purchase.
              Please take a moment to review your order and continue purchase.
            </ReactI18next.Trans>
          </Mui.Typography>
          <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
            <Mui.Button
              variant="contained"
              component={Router.Link}
              to="/shop/checkout"
            >
              <ReactI18next.Trans i18nKey="Try again">
                Try again
              </ReactI18next.Trans>
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      </Mui.Paper>
    </>
  );
}
