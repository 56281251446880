import * as Mui from "@material-ui/core";
import * as ReactUndraw from "iblis-react-undraw";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";

export function Failure() {
  const theme = Mui.useTheme();
  const cartStore = React.useContext(App.Contexts.cartStore);
  const navigate = Router.useNavigate();

  React.useEffect(() => {
    (async function () {
      try {
        const orderDetails = localStorage.getItem("orderDetails");
        if (orderDetails) {
          cartStore.resetCart();
          navigate("/shop/payment/success");
          return;
        }

        if (!orderDetails && cartStore.cart.size === 0) {
          navigate("/shop");
          return;
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Mui.Paper>
        <Mui.Box padding={2}>
          <ReactUndraw.SvgCupOfTea
            style={{
              marginBottom: theme.spacing(6),
            }}
            accentcolor={theme.palette.primary.main}
            primarycolor={theme.palette.secondary.main}
            height={300}
          />
          <Mui.Typography variant="h4" align="center" gutterBottom>
            <ReactI18next.Trans i18nKey="Payment failure">
              Payment failure
            </ReactI18next.Trans>
          </Mui.Typography>
          <Mui.Typography
            component="div"
            variant="body2"
            style={{
              marginBottom: theme.spacing(4),
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <ReactI18next.Trans i18nKey="If you received a payment failure alert, it means the payment may have failed due to one of the following reasons">
                If you received a payment failure alert, it means the payment may have failed due to one of the following reasons
              </ReactI18next.Trans>
              <ul>
                <li>
                  <ReactI18next.Trans i18nKey="Payment was declined by your bank or credit card provider">
                    Payment was declined by your bank or credit card provider
                  </ReactI18next.Trans>
                </li>
                <li>
                  {" "}
                  <ReactI18next.Trans i18nKey="Credit card provided has expired">
                    Credit card provided has expired
                  </ReactI18next.Trans>
                </li>
                <li>
                  {" "}
                  <ReactI18next.Trans i18nKey="Credit card has insufficient funds">
                    Credit card has insufficient funds
                  </ReactI18next.Trans>
                </li>
                <li>
                  <ReactI18next.Trans i18nKey="Credit card number provided is invalid">
                    Credit card number provided is invalid
                  </ReactI18next.Trans>
                </li>
                <li>
                  <ReactI18next.Trans i18nKey="Credit card provided has not been approved for recurring">
                    Credit card provided has not been approved for recurring
                  </ReactI18next.Trans>
                </li>
                <li>
                  <ReactI18next.Trans i18nKey="Payments">
                    Payments
                  </ReactI18next.Trans>
                </li>
              </ul>
              <ReactI18next.Trans
                i18nKey="For more information on why the payment failed, please contact your bank or credit card company."
              >
                For more information on why the payment failed, please contact
                your bank or credit card company.
              </ReactI18next.Trans>
            </div>
          </Mui.Typography>
          <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
            <Mui.Button
              variant="contained"
              component={Router.Link}
              to="/shop/checkout"
            >
              <ReactI18next.Trans i18nKey=" Try again">
                Try again
              </ReactI18next.Trans>
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      </Mui.Paper>
    </>
  );
}
