/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace User {
  export interface IUser {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = User.IUser;

  export namespace Authenticate {
    export type Body = Models.User.Credential;

    export interface Props {
      body: User.Authenticate.Body;
    }

    export type Return = Models.API.Response<Models.User.Main[]>;
  }

  export namespace AuthenticateFromSSO {
    export type Body = Models.User.TokenFromSSO;

    export interface Props {
      body: User.AuthenticateFromSSO.Body;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class User {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: User.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async authenticate({
    body,
  }: User.Authenticate.Props): Promise<User.Authenticate.Return> {
    const url = this.urlFactory.create({
      named: ["auth", "login"],
    });
    const response = await this.httpManager.post<
      User.Authenticate.Return,
      User.Authenticate.Body
    >({
      body,
      url,
    });


    return response;
  }

  public async authenticateFromSSO({
    body,
  }: User.AuthenticateFromSSO.Props): Promise<User.AuthenticateFromSSO.Return> {
    const url = this.urlFactory.create({
      named: ["auth", "SSOtoken"],
    });
    const response = await this.httpManager.post<
      User.AuthenticateFromSSO.Return,
      User.AuthenticateFromSSO.Body
    >({
      body,
      url,
    });


    return response;
  }
}
