import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace AddDealer {
  export interface IAddDealer {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = AddDealer.IAddDealer;

  export namespace GetAllData {
    export type Return = Models.API.Response<Models.AddDealer.Main>;
  }
}

// eslint-disable-next-line no-redeclare
export class AddDealer {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: AddDealer.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  // eslint-disable-next-line no-empty-pattern
  public async getAllData(): Promise<AddDealer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "GetDealer", `${1}`, `${1}`],
    });

    const response = await this.httpManager.get<AddDealer.GetAllData.Return>({
      url,
    });


    return response;
  }
}
