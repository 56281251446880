/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace AddCustomer {
  export interface IAddCustomer {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export namespace InviteCustomer {
    export type Return = Models.API.Response<any>;
    export type Body = Models.InviteCustomer.Main;

    export interface Props {
      body: InviteCustomer.Body;
    }
  }
  export namespace InviteFromManageOrder {
    export type Return = Models.API.Response<any>;
    export type Body = Models.InviteFromManageOrder.Main;

    export interface Props {
      body: InviteFromManageOrder.Body;
    }
  }
  export namespace CancelInvite {
    export type Return = Models.API.Response<any>;
    export interface Body {
      EmailID: any;
      DealerID: any;
      OrderCode: any;
    }
    export interface Props {
      body: CancelInvite.Body;
    }
  }

  export namespace UnAssignAll {
    export type Return = Models.API.Response<any>;
    export interface Body {
      DealerETUID: any;
      OrderID: any;
      OrderCode: any;
    }
    export interface Props {
      body: UnAssignAll.Body;
    }
  }

  export namespace CancelInviteForSingleLicense {
    export type Return = Models.API.Response<any>;

    export interface Body {
      CustomerETUID: number;
      CustomerEmailID: string;
      DealerETUID: number;
      LicenseCode: string;
      OrderDetailID: number;
      SetID: number;
    }

    export interface Props {
      body: CancelInviteForSingleLicense.Body;
    }
  }

  export namespace getAllTrialCustomer {
    export type Return = Models.API.Response<any>;
    export interface Body {
      EmailID: string;
      DealerID: number;
      OrderCode: string | null;
    }
    export interface Props {
      body: CancelInvite.Body;
    }
  }
  export type Options = AddCustomer.IAddCustomer;

  export namespace GetAllData {
    export type Return = Models.API.Response<Models.AddDealer.Main>;
  }
}

// eslint-disable-next-line no-redeclare
export class AddCustomer {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: AddCustomer.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  // eslint-disable-next-line no-empty-pattern
  public async getAllData(): Promise<AddCustomer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "GetDealer", `${1}`, `${1}`],
    });

    const response = await this.httpManager.get<AddCustomer.GetAllData.Return>({
      url,
    });


    return response;
  }

  public async inviteCustomer({
    body,
  }: AddCustomer.InviteCustomer.Props): Promise<AddCustomer.InviteCustomer.Return> {
    const url = this.urlFactory.create({
      named: ["Customer", "AddCustomer"],
    });

    const response = await this.httpManager.post<
      AddCustomer.InviteCustomer.Return,
      AddCustomer.InviteCustomer.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  public async inviteFromManageOrder({
    body,
  }: AddCustomer.InviteFromManageOrder.Props): Promise<AddCustomer.InviteFromManageOrder.Return> {
    const url = this.urlFactory.create({
      named: ["Customer", "AddNewCustomer"],
    });

    const response = await this.httpManager.post<
      AddCustomer.InviteFromManageOrder.Return,
      AddCustomer.InviteFromManageOrder.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  public async cancelInvite({
    body,
  }: AddCustomer.CancelInvite.Props): Promise<AddCustomer.CancelInvite.Return> {
    const url = this.urlFactory.create({
      named: [
        "Dealer",
        "Dealer_Customer_CancelInvite",
        `${body.EmailID}`,
        `${body.DealerID}`,
        `${body.OrderCode}`,
      ],
    });

    const response = await this.httpManager.post<
      AddCustomer.CancelInvite.Return,
      AddCustomer.CancelInvite.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  public async unAssignAll({
    body,
  }: AddCustomer.UnAssignAll.Props): Promise<AddCustomer.UnAssignAll.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "UnAssignAllLicensebyOrder"],
    });

    const response = await this.httpManager.post<
      AddCustomer.UnAssignAll.Return,
      AddCustomer.UnAssignAll.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  public async cancelInviteForSingleLicense({
    body,
  }: AddCustomer.CancelInviteForSingleLicense.Props): Promise<AddCustomer.CancelInviteForSingleLicense.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "DealerCustomerUnassignLicense"],
    });

    const response = await this.httpManager.post<
      AddCustomer.CancelInviteForSingleLicense.Return,
      AddCustomer.CancelInviteForSingleLicense.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  public async getAllTrialCustomer({
    body,
  }: AddCustomer.CancelInvite.Props): Promise<AddCustomer.CancelInvite.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "DealerCustomerCancelInvite"],
    });

    const response = await this.httpManager.post<
      AddCustomer.CancelInvite.Return,
      AddCustomer.CancelInvite.Body
    >({
      body: body,
      url,
    });

    return response;
  }
}
