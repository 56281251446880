import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Containers from "src/app/containers";
import { v4 as uuidv4 } from "uuid";

export declare namespace TaskPopupMenu {
  export interface Props {}

  export interface Task {
    severity: "success" | "error" | "info" | "warning";
    title: string;
    body: string;
    roles: Array<"customer" | "admin" | "dealer" | "superadmin">;
    link: {
      isLink: boolean;
      to: string;
    };
  }
}

const ITEM_HEIGHT = 48;

// eslint-disable-next-line no-redeclare
export const TaskPopupMenu = Mobx.observer(function (
  props?: TaskPopupMenu.Props
) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const globalTenantStore = React.useContext(App.Contexts.globalTenantStore);
  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const userStore = React.useContext(App.Contexts.userStore);

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;

    if (userRole === "customer") {
      return;
    }

    tenantStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const etuid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName;

    if (!etuid || (userRole === "admin" || userRole === "superadmin")) {
      return;
    }

    if (globalTenantStore.tenant === undefined) {
      globalTenantStore.read({ etuid }).then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Mui.IconButton
        aria-label="tasks"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Mui.Badge
          color="secondary"
          badgeContent={
            [
              ...(userStore.user?.RoleName === "customer"
                ? globalTenantStore.tasks
                : []),
              ...(userStore.user?.RoleName === "admin"
                ? tenantStore.tasks
                : []),
                ...(userStore.user?.RoleName === "superadmin"
                ? tenantStore.tasks
                : []),
            ].length
          }
        >
          <MuiIcons.AddTask />
        </Mui.Badge>
      </Mui.IconButton>
      <Mui.Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: anchorEl?.getBoundingClientRect().bottom ?? 0,
          left: anchorEl?.getBoundingClientRect().left ?? 0,
        }}
        PaperProps={{
          style: {
            height: "100%",
            maxHeight: ITEM_HEIGHT * 10,
            width: 300,
          },
        }}
      >
        <Mui.MenuItem disabled>Tasks</Mui.MenuItem>
        <Mui.List style={{ padding: 0 }}>
          {[
            ...(userStore.user?.RoleName === "customer"
              ? globalTenantStore.tasks
              : []),
            ...((userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin") ? tenantStore.tasks : []),
          ].map((task, index) => (
            <Containers.ShowIfAuthorised roles={task.roles} key={uuidv4()}>
              <>
                <Mui.Divider />
                <Mui.ListItem
                  style={{ padding: 0 }}
                  {...(task?.link?.isLink &&
                    ({
                      button: true,
                      component: Router.Link,
                      to: task?.link.to,
                      onClick: () => handleClose(),
                    } as any))}
                >
                  <MuiLab.Alert
                    severity={task.severity}
                    style={{ maxWidth: 300 }}
                  >
                    <MuiLab.AlertTitle>{task.title}</MuiLab.AlertTitle>
                    <div dangerouslySetInnerHTML={{ __html: task.body }} />
                  </MuiLab.Alert>
                </Mui.ListItem>
              </>
            </Containers.ShowIfAuthorised>
          ))}
        </Mui.List>
      </Mui.Menu>
    </>
  );
});
