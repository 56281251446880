import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Notistack from "notistack";

export declare namespace ProceedToPaymentButton {
  export interface Props {
    status?: string | null;
  }
}

// eslint-disable-next-line no-redeclare
export function ProceedToPaymentButton({
  status,
}: ProceedToPaymentButton.Props) {
  const { submitForm } = Formik.useFormikContext();
  const cartStore = React.useContext(App.Contexts.cartStore);
  // const theme = Mui.useTheme();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();
  const [enterKeyEvent,setEnterKeyEvent] = React.useState<boolean>(false)
 const handleProceedToPayment = () => {
  
   
      const totalPurchaseAmount:any= localStorage.getItem("totalPurchaseValue")
      // console.log("totalPurchaseAmount",parseInt(totalPurchaseAmount))
      if(totalPurchaseAmount > 0){
        submitForm()
      }
      else{
        enqueueSnackbar(
          t(`Total amount should be greater than zero, Please add more items`),
          {
            variant: "warning",
            preventDuplicate: false,
          }
        );
      }
     
    
  
    
 }
  
  return (
    <Mui.Button
      style={{
        marginLeft: "auto",
      
      }}
      color="primary"
      variant="contained"
      onClick={handleProceedToPayment}
    >
      {status === "cancelled" || status === "failure" ? (
        <ReactI18next.Trans i18nKey="Retry payment">
          Retry payment
        </ReactI18next.Trans>
      ) : (
        <ReactI18next.Trans i18nKey="Proceed to payment">
          Proceed to payment
        </ReactI18next.Trans>
      )}
    </Mui.Button>
  );
}
