/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace License {
  export interface ILicense {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = License.ILicense;

  export namespace GetAllData {
    export interface Body {
      status: null | number;
      userID: string | number;
    }

    export interface Props {
      body: License.GetAllData.Body;
      userRole: string;
      uid: any;
      isGetAll?: boolean;
    }

    export type Return = Models.API.Response<Models.License[]>;
  }

  export namespace Create {
    export interface Body {
      orderID: number;
      customerName?: string;
      licenseKey: string;
      macId: string;
      remarks?: string;
      createdby: number | string;
      sets: number[];
      eT_version?: number;
      tenantId?: number;
      generatedBy?: number;
    }

    export interface Props
      extends Pick<
        License.Create.Body,
        | "macId"
        | "remarks"
        | "sets"
        | "customerName"
        | "tenantId"
        | "eT_version"
        | "generatedBy"
      > {
      userId: string | number;
    }

    export type Result = {
      orderID?: number;
      customerName?: string;
      licenseKey: string;
      macId?: string;
      remarks?: string;
      createdby?: number;
      setId?: number[];
      eT_version?: number;
      generatedBy?: number;
    };

    export type Return = Models.API.Response<License.Create.Result>;
  }

  export namespace GetAllManualLicenses {
    export interface Props {}

    export type Result = Models.ManualLicense[];

    export type Return =
      Models.API.Response<License.GetAllManualLicenses.Result>;
  }

  export namespace GetManualLicenseByMachineId {
    export interface Props {
      machineId: string;
    }

    export type Result = Models.ManualLicense[];

    export type Return =
      Models.API.Response<License.GetManualLicenseByMachineId.Result>;
  }

  export namespace Deactivate {
    export interface Body {
      LicenseDetailsList: Array<{ LicenseDetailID: number; MacID: string }>;
    }

    export interface Props {
      body: License.Deactivate.Body;
    }

    export type Return = any;
  }

  export namespace DeactivateAll {
    export interface Body {
      MacID: string | number;
      UserId: string | number;
    }

    export interface Props {
      body: License.DeactivateAll.Body;
    }

    export type Return = any;
  }

  export namespace Activate {
    export interface Body {
      LicenseDetailID: number;
      MacID: string;
      ActivationKey: string | null;
      ActivationAck: number | null;
      Status: number;
      DeviceDetail: {
        MacID: string | null;
        DeviceName: string | null;
        SerialNumber: string | null;
        CustomerDeviceID: string | null;
        Status: number | null;
      };
    }

    export interface Props {
      body: License.Activate.Body;
    }

    export type Return = any;
  }

  export namespace CreateManualLicenseAfterActivation {
    export interface Body {
      CustomerName?: string;
      MacId: string;
      Remarks?: string;
      Sets: number[];
      TenantId?: string;
    }

    export interface Props {
      body: License.CreateManualLicenseAfterActivation.Body;
    }

    export type Return = any;
  }

  export namespace GetCustomerAssignedLicense {
    export interface Props {
      ETUID: any;
      SetId: any;
    }
    export type Result = Models.AssignedLicenseDetail[];

    export type Return =
      Models.API.Response<License.GetCustomerAssignedLicense.Result>;
  }
}

// eslint-disable-next-line no-redeclare
export class License {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: License.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async createManualLicenseAfterActivation({
    body,
  }: License.CreateManualLicenseAfterActivation.Props) {
    const url = this.urlFactory.create({
      named: ["license", "CreateManualLicenseByActivated"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      License.CreateManualLicenseAfterActivation.Return,
      License.CreateManualLicenseAfterActivation.Body
    >({
      body,
      url,
    });
    // console.log({ response });

    return response;
  }

  public async activate({
    body,
  }: License.Activate.Props): Promise<License.Activate.Return> {
    const url = this.urlFactory.create({
      named: ["license", "Activate"],
    });
    console.log({ url });

    const response = await this.httpManager.post<
      License.Activate.Return,
      License.Activate.Body
    >({
      body,
      url,
    });
    // console.log({ response });

    return response;
  }

  public async deactivate({
    body,
  }: License.Deactivate.Props): Promise<License.Deactivate.Return> {
    const url = this.urlFactory.create({
      named: ["license", "DeActivate"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      License.Deactivate.Return,
      License.Deactivate.Body
    >({
      body,
      url,
    });
    // console.log({ response });

    return response;
  }

  public async deactivateAll({
    body,
  }: License.DeactivateAll.Props): Promise<License.DeactivateAll.Return> {
    const url = this.urlFactory.create({
      named: ["license", "DeactivateAll"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      License.DeactivateAll.Return,
      License.DeactivateAll.Body
    >({
      body,
      url,
    });
    // console.log({ response });

    return response;
  }

  public async getAllData({
    body,
    userRole,
    uid,
  }: License.GetAllData.Props): Promise<License.GetAllData.Return> {
    const url =
      userRole === "customer" || userRole === "dealer"
        ? this.urlFactory.create({
            named: ["license", "get", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["license", "GetAdmin"],
          });
    // console.log({ url });

    const response = await (userRole === "customer" || userRole === "dealer"
      ? this.httpManager.get<License.GetAllData.Return>({ url })
      : this.httpManager.post<
          License.GetAllData.Return,
          License.GetAllData.Body
        >({
          body: {
            ...body,
            status: 2,
          },
          url,
        }));
    // console.log({ response });

    return response;
  }

  public async getAllCustomerInviteData({
    body,
    userRole,
    uid,
    isGetAll = false,
  }: License.GetAllData.Props): Promise<License.GetAllData.Return> {
    const url =
      userRole === "customer" || userRole === "dealer"
        ? isGetAll === false
          ? this.urlFactory.create({
              named: ["license", "get", `${uid}`],
            })
          : this.urlFactory.create({
              named: ["license", "GetAll", `${uid}`],
            })
        : this.urlFactory.create({
            named: ["license", "GetAdmin"],
          });
    // console.log({ url });

    const response = await (userRole === "customer" || userRole === "dealer"
      ? this.httpManager.get<License.GetAllData.Return>({ url })
      : this.httpManager.post<
          License.GetAllData.Return,
          License.GetAllData.Body
        >({
          body: {
            ...body,
            status: 2,
          },
          url,
        }));
    // console.log({ response });

    return response;
  }

  public async Create({
    userId,
    ...restOfProps
  }: License.Create.Props): Promise<License.Create.Return> {
    const url = this.urlFactory.create({
      named: ["License", "CreateManualLicense"],
    });

    const {
      customerName,
      macId,
      remarks,
      tenantId,
      eT_version,
      sets,
      generatedBy,
    } = restOfProps;
    const response = await this.httpManager.post<
      License.Create.Return,
      {
        CustomerName?: string;
        MacId?: string;
        Remarks?: string;
        tenantId?: number;
        Sets: number[];
        eT_version?: number;
        generatedBy?: number;
      }
    >({
      body: {
        CustomerName: customerName,
        MacId: macId,
        Remarks: remarks,
        Sets: sets,
        tenantId: tenantId,
        eT_version: eT_version,
        generatedBy: generatedBy,
      },
      url,
    });
    // console.log({ response });

    return response;
  }

  public async GetAllManualLicenses(
    props?: License.GetAllManualLicenses.Props
  ): Promise<License.GetAllManualLicenses.Return> {
    const url = this.urlFactory.create({
      named: ["License", "GetAllManualLicense"],
    });
    // console.log({ url });

    const response =
      await this.httpManager.get<License.GetAllManualLicenses.Return>({
        url,
      });
    // console.log({ response });

    return response;
  }

  public async GetManualLicenseByMachineId({
    machineId,
  }: License.GetManualLicenseByMachineId.Props): Promise<License.GetManualLicenseByMachineId.Return> {
    const url = this.urlFactory.create({
      named: ["License", "GetManualLicenseByMacId", machineId],
    });
    // console.log({ url });

    const response =
      await this.httpManager.get<License.GetManualLicenseByMachineId.Return>({
        url,
      });
    // console.log({ response });

    return response;
  }

  public async GetCustomerAssignedLicense({
    ETUID,
    SetId,
  }: License.GetCustomerAssignedLicense.Props): Promise<License.GetCustomerAssignedLicense.Return> {
    const url = this.urlFactory.create({
      named: ["License", "GetCustomerAssignedLicense"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      License.GetCustomerAssignedLicense.Return,
      {
        ETUID: any;
        SetId: any;
      }
    >({
      body: {
        ETUID: ETUID,
        SetId: SetId,
      },
      url,
    });
    // console.log({ response });

    return response;
  }
}
