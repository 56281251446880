import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Constants from "src/constants";
import * as Services from "src/services";

export declare namespace Form {
  export interface Values {
    accept: boolean;
  }
}

// eslint-disable-next-line no-redeclare
export function Form() {
  const [
    generalTermsAndConditionsDialogOpen,
    setGeneralTermsAndConditionsDialogOpen,
  ] = React.useState<boolean>(false);
  const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = React.useState<
    boolean
  >(false);
  const [privacyPolicy, setPrivacyPolicy] = React.useState<any>();
  const [
    generalTermsAndConditions,
    setGeneralTermsAndConditions,
  ] = React.useState<any>();

  const { errors } = Formik.useFormikContext<Form.Values>();
  const { t, i18n } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const handleGeneralTermsAndConditionsLink = React.useCallback(
    async function ({
      language,
    }: Services.Order.GetGeneralTermsAndConditions.Props) {
      try {
        if (!generalTermsAndConditionsDialogOpen) {
          return;
        }
        const generalTermsAndConditions = await orderServices.getGeneralTermsAndConditions(
          { language }
        );
        setGeneralTermsAndConditions(generalTermsAndConditions.result?.result);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generalTermsAndConditionsDialogOpen]
  );

  const handlePrivacyPolicyLink = React.useCallback(
    async function ({ language }: Services.Order.GetPrivacyPolicy.Props) {
      try {
        if (!privacyPolicyDialogOpen) {
          return;
        }
        const privacyPolicy = await orderServices.getPrivacyPolicy({
          language,
        });
        setPrivacyPolicy(privacyPolicy.result?.result);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [privacyPolicyDialogOpen]
  );

  React.useEffect(() => {
    handleGeneralTermsAndConditionsLink({ language: i18n.language });
    handlePrivacyPolicyLink({ language: i18n.language });
  }, [
    handleGeneralTermsAndConditionsLink,
    handlePrivacyPolicyLink,
    i18n.language,
  ]);
const [isChecked,setChecked] = React.useState(true)
  return (
    <Formik.Form>
      <Components.ResponsiveFullscreenDialogWithScroll
        open={generalTermsAndConditionsDialogOpen}
        title={t("General terms and conditions")}
        handleClose={() => setGeneralTermsAndConditionsDialogOpen(false)}
        contentText={
          <>
            {generalTermsAndConditions && (
              <div
                dangerouslySetInnerHTML={{ __html: generalTermsAndConditions }}
              ></div>
            )}
          </>
        }
        actions={
          <Mui.Button
            variant="contained"
            onClick={() => setGeneralTermsAndConditionsDialogOpen(false)}
          >
            <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
          </Mui.Button>
        }
      />
      <Components.ResponsiveFullscreenDialogWithScroll
        open={privacyPolicyDialogOpen}
        title={t("Privacy policy")}
        handleClose={() => setPrivacyPolicyDialogOpen(false)}
        contentText={
          <>
            {privacyPolicy && (
              <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
            )}
          </>
        }
        actions={
          <Mui.Button
            variant="contained"
            onClick={() => setPrivacyPolicyDialogOpen(false)}
          >
            <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
          </Mui.Button>
        }
      />
      <Mui.FormControl
        required
        style={{ marginBottom: theme.spacing(2) }}
        component="fieldset"
      >
        <Mui.FormLabel component="legend">
          <ReactI18next.Trans i18nKey="Choose your payment type">
            Choose your payment type
          </ReactI18next.Trans>
        </Mui.FormLabel>
        <Mui.RadioGroup
          aria-label="gender"
          name="gender1"
          value={"CREDIT_CARD"}
        >
          <Mui.FormControlLabel
            value="CREDIT_CARD"
            control={<Mui.Radio />}
            label={t("Credit Card")}
          />
        </Mui.RadioGroup>
      </Mui.FormControl>

      <Mui.FormControl
        error={errors.accept ? true : false}
        required
        component="fieldset"
      >
        <Mui.FormLabel
          style={{ marginBottom: theme.spacing(0.5) }}
          component="legend"
        >
          <ReactI18next.Trans i18nKey="Please confirm our general terms & conditions">
            Please confirm our general terms & conditions
          </ReactI18next.Trans>
        </Mui.FormLabel>
        <Mui.FormGroup>
        <Mui.FormControlLabel
            control={
              <Formik.Field
                name="accept"
                type="checkbox"
                component={FormikMui.Checkbox}
              />
            }
            label={
              <Mui.Typography>
                <ReactI18next.Trans i18nKey="By proceeding I declare agreement with the">
                  By proceeding I declare agreement with the
                </ReactI18next.Trans>{" "}
                <Mui.Link
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    setGeneralTermsAndConditionsDialogOpen(true);
                    handleGeneralTermsAndConditionsLink({
                      language: i18n.language,
                    });
                  } }
                >
                  <ReactI18next.Trans i18nKey="General terms & conditions">
                    General terms & conditions
                  </ReactI18next.Trans>
                </Mui.Link>{" "}
                and{" "}
                <Mui.Link
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    setPrivacyPolicyDialogOpen(true);
                    handlePrivacyPolicyLink({
                      language: i18n.language,
                    });
                  } }
                >
                  <ReactI18next.Trans i18nKey="privacy policy">
                    privacy policy
                  </ReactI18next.Trans>
                </Mui.Link>
                .
              </Mui.Typography>
            }
            {...errors.accept ?(  
              <ReactI18next.Trans i18nKey="Please accept the terms before proceeding">
              Please accept the terms before proceeding                  
              </ReactI18next.Trans>):""}
            
          />
        </Mui.FormGroup>

      </Mui.FormControl>
    </Formik.Form>
  );
}
