/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Configs from "src/configs";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as MuiIcons from "@material-ui/icons";
import * as Notistack from "notistack";

export declare namespace Card {
  export interface Props {
    isLoading?: boolean;
    article: Models.Set.Article;
    functionData: Models.Set.Function[];
    handleAddToCartClick(props: { article: Models.Set.Article }): void;
    handleClick?(): void;
  }
}

// eslint-disable-next-line no-redeclare
export function Card({
  isLoading,
  article,
  functionData,
  handleClick,
  handleAddToCartClick,
}: Card.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const [disableAddtoCartwhenValueZero,setDisableAddtoCartwhenValueZero] = React.useState(false);
  const [articleName,setArticleName] = React.useState("");
  const [language,setLanguage] = React.useState('en-IN');
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language;
  React.useEffect(()=>{
    if(languageValue === "en"){
      setLanguage('en-IN')
    }else if(languageValue === "fr")
    {
      setLanguage('fr-FR')
    }
    else{
      setLanguage('de-DE')
    }
  },[languageValue])
  const grossPositionSinglePriceInclVAT =  article?.olPrice?.grossPositionSinglePriceInclVAT
  const singleNetPrice = article?.olPrice?.singleNetPrice
  const localeGrossPositionSinglePriceInclVAT = grossPositionSinglePriceInclVAT?.toLocaleString?.(language,{minimumFractionDigits: 0, maximumFractionDigits: 0})
  const localeSingleNetPrice = singleNetPrice?.toLocaleString?.(language,{minimumFractionDigits: 2, maximumFractionDigits: 2})
  const isSingleNetpriceGreaterThanZero = article?.olPrice?.singleNetPrice.toString() == "0" ? true : false;
  const { enqueueSnackbar } = Notistack.useSnackbar();

  const handleMoreInfo = async () => {
   
      if(article?.olPrice?.singleNetPrice?.toString() == "0")
      {
   
        
       // setDisableAddtoCartwhenValueZero(true)
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="This article will not be available for purchase at this time">
             This article will not be available for purchase at this time
          </ReactI18next.Trans>,
          {
            variant: "warning",
          }
        );
        
      }
      else{
// Input string
var inputString = article.setCode;
        
// Remove the last character
var removedLastCharacter = inputString.slice(0, -1);

// Text to add
var textToAddForBasic = "C";

var textToAddForOther = "B";

var result = "";
// Combine the modified string with the new text
if(article.setCode === "TSET-01-B"){

result = removedLastCharacter + textToAddForBasic;

}
else{

result = removedLastCharacter + textToAddForOther;

}
// Output the result
console.log(result); // This will print "TSET-01-D"
var KernURL = `https://www.kern-sohn.com/shop/en/products/software/${result}`;

var KernURL = `https://www.kern-sohn.com/shop/en/products/software/${result}`;

window.open(KernURL, '_blank');

       // handleAddToCartClick({ article });
        
        //localStorage.setItem("isCustomerInvitePurchase", "0");
      }
      
    
  }

  const CardInternals = (
    <>
      <Mui.CardContent style={{ textAlign: "center", height: "100%" }}>
        <Mui.Grid
          container
          // justify="center"
          alignItems="center"
          direction="column"
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <Mui.Box>
          <Mui.Typography style={{marginLeft:"220px",backgroundColor:"#8a8888",color:"white",border:"1px solid #8a8888",borderColor:"#8a8888",padding:"4px",borderRadius:"4px",}}>
              {article.hundCode}
            </Mui.Typography>
          </Mui.Box>
          <Mui.Box 
          style={{ fontSize: theme.typography.h5.fontSize,backgroundColor: theme.palette.divider,borderRadius:"50%",width:"60px",height:"60px",paddingTop:"13px",marginBottom:"12px"}}>
            <i className={article.iconClass} />
          </Mui.Box>

          <Mui.Box marginBottom={3}>
            <Mui.Typography variant="h5" style={{ fontWeight: "bold" }}>
              {article.setName}
            </Mui.Typography>
            {/* <Mui.Typography color="textSecondary">
              {article.hundCode}
            </Mui.Typography> */}
            <Mui.Typography style={{ fontSize: "13px"}}>
              {'( '}
              {functionData
              .filter((func) => article.functions.includes(func.setFunctionID))
              .map((func, index) => (
                 func.funcationName 
              )).join(", ")}
              {' )'}
        </Mui.Typography>
          </Mui.Box>

       

          


          <Mui.Box marginBottom={4}>
           
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 ,}}>
            {/* <Mui.ListItemIcon>
              <Mui.Box lineHeight={0} component="span">
              <MuiIcons.CheckCircle style={{ color: theme.palette.success.main,fontSize: "1.2rem"}}/></Mui.Box>&nbsp;
              <Mui.Typography style={{color:"#000"}}>
               { article.hundCode === "SEQ-01" ?
               <Mui.Typography style={{color:"#000"}}> 
               {t(`Duration`)}: {`${2}`} {t(`Years`)}
               </Mui.Typography>
               : 
                <ReactI18next.Trans i18nKey="One time payment">
                  One time payment
                </ReactI18next.Trans>  }
                </Mui.Typography>
            </Mui.ListItemIcon> */}
            </Mui.ListItem>
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 ,}}>
            <Mui.ListItemIcon>
              <Mui.Box lineHeight={0} component="span">
              <MuiIcons.CheckCircle style={{ color: theme.palette.success.main,fontSize: "1.2rem"}}/></Mui.Box>&nbsp;
              <Mui.Typography style={{color:"#000"}}>
                {t(`Installable on upto`)} {`${article.licensePerSet}`} {t(`device(s)`)}</Mui.Typography>
            </Mui.ListItemIcon>
            </Mui.ListItem>
            
          
          </Mui.Box>

          <Mui.Box height={theme.spacing(5)} width={1} />

          <Mui.Box position="absolute" bottom={0}>
          <Mui.Button
              onClick={handleMoreInfo}
              variant="contained"
              color={article?.olPrice?.singleNetPrice?.toString() == "0" ? "default" : "primary"}
              disabled={isLoading}
            >
              {/* <MuiIcons.ShoppingCart style={{color:"white"}} />   */}
               {t("More info")}
            </Mui.Button>
          <Mui.Box height={theme.spacing(2)}/>
          {/* {article.moreInfo && (
              <Mui.Box >
               {article?.olPrice?.singleNetPrice?.toString() !== "0" ? (<Mui.Link
                  href={article.moreInfo }
                  rel="noopener"
                  target="__blank"
               
                >
                  {t("More info")}<MuiIcons.ArrowForward style={{paddingTop:"5px", marginBottom:"-4px"}}/>
                </Mui.Link>):(
                  <Mui.Typography>{t("More info")}<MuiIcons.ArrowForward style={{paddingTop:"5px", marginBottom:"-4px"}}/></Mui.Typography>
                )}
              </Mui.Box> */}
            
            
          </Mui.Box>
        </Mui.Grid>
        {isLoading && (
          <Mui.Box position="relative" bottom={theme.spacing(-2.5)}>
            <Mui.LinearProgress />
          </Mui.Box>
        )}
      </Mui.CardContent>
    </>
  );

  return (
    <Mui.Card
      style={{
        height: "100%",
      }}
    >
      {handleClick ? (
        <Mui.CardActionArea
          style={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "unset",
            alignItems: "unset",
            verticalAlign: "unset",
          }}
          onClick={() => handleClick()}
        >
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        CardInternals
      )}
    </Mui.Card>
  );
}

