/** @format */

import * as Mui from "@material-ui/core";
import * as Components from "src/app/components";
import * as DateFns from "date-fns";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Configs from "src/configs";
import * as Mobx from "mobx-react-lite";
import * as MuiIcons from "@material-ui/icons";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import LanguageIcon from "@material-ui/icons/Language";
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import * as ReactUndraw from "iblis-react-undraw";

export declare namespace Customer {
  export interface Props {
    isLoading : boolean
    order: Models.CustomerProfileOnDealer.Main;
    row?: Models.DealerCustomer.Main;
    hideHeader?: boolean;
    hideAddress?: boolean;
    hideUserDetails?: boolean;
    customers?: any;
  }
}

export declare namespace Card {
  export interface Props {
    device: Models.CustomerProfileOnDealer.DeviceActivationDetails;
    // handleClick?(event: any): void;
  }
}

// eslint-disable-next-line no-redeclare
const Card = Mobx.observer(function ({
  device,
  // handleClick,
}: Card.Props) {
  const functionStore = React.useContext(App.Contexts.functionStore);
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(16),
      border: '2px solid #dadde9',
    },
  }))(Mui.Tooltip);
  const CardInternals = (
    <>
      <Mui.CardHeader
        title={
          <Mui.ListItem component="div" style={{ padding: 0, margin: 0 ,}}>
            <Mui.ListItemIcon>
              <Mui.Box fontSize={theme.typography.h4.fontSize} lineHeight={0}>
                <i className="icon-etc-clients" />
              </Mui.Box>
            </Mui.ListItemIcon>

            <Mui.ListItemText
              primary={
                <Mui.Typography>{device.deviceName ?? ""}</Mui.Typography>
              }
              secondary={
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="MAC#:">
                    MAC#:&nbsp;
                  </ReactI18next.Trans>
                  <Mui.Typography component="span" variant="overline">
                    {device.macID}
                  </Mui.Typography>
                </Mui.Typography>
              }
            />
          </Mui.ListItem>
        }
        action={
          <Components.WorkingStatus
            variant="SHORT"
            status={1}
          />
        }
      />
      <Mui.Divider style={{ margin: theme.spacing(0, 2) }} />
      <Mui.CardContent>
        <Mui.Typography variant="body2" color="textSecondary">
          <ReactI18next.Trans i18nKey="Installed articles">
          Installed articles
          </ReactI18next.Trans>
        </Mui.Typography>
        <Mui.Box
        display="flex"
        justifyContent="flex-start"
        
      >
        {device.artiles.slice(0,4).map((func) => (
          <HtmlTooltip title={func.setName} key={uuidv4()}>
            <Mui.Avatar style={{ marginRight: theme.spacing(1.0) }}>
              <Mui.Box color="text.primary">
                <i className={func.iconClass} />
              </Mui.Box>
            </Mui.Avatar>
          </HtmlTooltip>
        ))}
        {
         device.artiles.length > 4 ?(<HtmlTooltip title={<>{device.artiles.slice(4,device.artiles.length).map((k)=>(<><i className={k.iconClass} style={{margin:"6px"}} />
         { k.setName}<br/></>))}</>} >
         <Mui.Typography
         >+{device.artiles.length - 4}more </Mui.Typography>
         </HtmlTooltip>
         ):""}
        </Mui.Box>
        
        {/* {device.artiles
                  .map((article) => (
            <Mui.Chip
              key={article.setID}
              icon={<i className={article.iconClass} />}
              style={{
                margin: theme.spacing(1.5, 2, 0, 0),
              }}
              label={article.setName}
            />
          ))} */}
      </Mui.CardContent>
    </>
  );

  return (
    <>
    <Mui.Box  
            display="flex"
            flexDirection={theme.direction === "rtl" ? "" : "column"}
            alignItems="center"
            width={"100%"}
            >
      <Mui.Card 
           style={{backgroundColor: theme.palette.background.default,
            }}>
        {/* {handleClick ? ( */}
          <Mui.CardActionArea
            component="div"
            style={{
              // minHeight: "50%",
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "unset",
              // alignItems: "unset",
              // verticalAlign: "unset",
            }}
            // onClick={handleClick}
          >
            {CardInternals}
          </Mui.CardActionArea>
        
        
      </Mui.Card>
      </Mui.Box>
    </>
  );
});
// eslint-disable-next-line no-redeclare
export function Customer({
  isLoading,
  customers,
  order,
  row,
  hideAddress,
  hideHeader,
  hideUserDetails,
}: Customer.Props) {
  const theme = Mui.useTheme();
  // console.log("rowcustomer",JSON.stringify(row))
  const useStyles = makeStyles((theme) => ({
    
    paper: {
      display: 'flex',
      border: `2px solid ${theme.palette.divider}`,
      flexWrap: 'wrap',
      width:"100%",
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
    
  }));
  
  const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
      width:"100%",
      margin: theme.spacing(0.5),
      maxHeight:"50px"
      // '&:not(:first-child)': {
      //   borderRadius: theme.shape.borderRadius,
      // },
      // '&:first-child': {
      //   borderRadius: theme.shape.borderRadius,
      // },
      
      // selected: {
      //   backgroundColor: theme.palette.primary.main,
      //   color:  theme.palette.primary.main,
      //   "&:hover": {
      //     backgroundColor: theme.palette.primary.main,
      //     color: theme.palette.primary.main
      //   }
      // }
    },
  }))(ToggleButtonGroup);
  
  // const StyledToggleButton = withStyles((theme) => ({
  //   grouped: {
  //     width:"100%",
  //     margin: theme.spacing(0.5),
  //     '&:not(:first-child)': {
  //       borderRadius: theme.shape.borderRadius,
  //     },
  //     '&:first-child': {
  //       borderRadius: theme.shape.borderRadius,
  //     },
      
  //     // selected: {
  //     //   backgroundColor: theme.palette.primary.main,
  //     //   color:  theme.palette.primary.main,
  //     //   "&:hover": {
  //     //     backgroundColor: theme.palette.primary.main,
  //     //     color: theme.palette.primary.main
  //     //   }
  //     // }
  //   },
  // }))(ToggleButton);
  const classes = useStyles();
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  // const totalQuantity = order?.orderDetails
  //   .map((k) => k.quantity)
  //   .reduce((a, b) => a + b, 0);
    
  //   const totalAmount = order?.orderDetails
  //   .map((k) => k.quantity * k.singleNetPrice).reduce((a, b) => a + b, 0);
    const [alignment, setAlignment] = React.useState('left');

    const handleChange = (event:any, newAlignment:any) => {
      setAlignment(newAlignment);
    };
    const easyTouchDeviceStore = React.useContext(
      App.Contexts.easyTouchDeviceStore
    );
    const licenseStore = React.useContext(App.Contexts.license.store);
    // const split  = row?.customerName?.split(/(\s+)/)
    // console.log(JSON.stringify(split))
    // alert(totalAmount)
    const uid:any = 11;
    React.useEffect(()=>{
      easyTouchDeviceStore.read({ userRole: "customer", status: -1, uid });
      const userRole = userStore.user?.RoleName;
        if (userRole === "admin" || userRole === "superadmin") {
          easyTouchDeviceStore.read({
            body: { status: null, userID: easyTouchDeviceStore.customer },
            userRole: "admin",
            uid: uid,
          });
          return;
        }
    },[easyTouchDeviceStore, uid, userStore.user])
    const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
    const easyTouch = easyTouchDeviceStore.filtered.map(
      (device: Models.Devices & { sets: Set<Models.Set.Article> }) => (device))
      // console.log("easyTouch",JSON.stringify(easyTouch))
      // console.log("customerd",JSON.stringify(customers))
  return (
    <>
      {/* {hideHeader ? null : (
        <Mui.CardHeader
          title={order?.orderCode ?? null}
          action={
            order?.orderedOn
              ? `Ordered on: ${DateFns.format(
                  new Date(order?.orderedOn),
                  "dd.MM.yyyy"
                )}`
              : null
          }
        />
      )} */}
      <Mui.Grid xs={12} >
            <Mui.Grid container style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
              <Mui.Grid item xs={6} style={{display:"flex"}}>
                <LanguageIcon
                  style={{
                    border: "1px grey",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    width: "53px",
                    height: "53px",
                    color: "grey",
                    margin: "0px 17px 10px 33px",
                  }}
                />
                <Mui.Box>
                <Mui.Typography
                  style={{
                    display:"flex",
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "12px 0px 2px 10px",
                    opacity: "0.8",
                  }}
                >
                  {row?.companyName || customers?.companyName}
                  {/* {"Marlensoft"} */}
                  {/* {data.companyName} */}
                </Mui.Typography>
                </Mui.Box>
                
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Box   style={{
                    display:"flex",
                    fontWeight: "bold",
                    fontSize: "10px",
                     margin: "12px 0px 0px 0px",
                  }}>
                <Mui.Typography
                style={{
                  display:"flex",
                  fontWeight: "bold",
                  fontSize: "18px",
                  margin: "1px 0px 0px 0px",
                  opacity: "0.8",
                }}
                >
                  {"Status"}:&nbsp;&nbsp;
                  {/* {data.companyName} */}
                </Mui.Typography>
                <Components.InviteStatus
                 
                 status={row?.etUserStatus as 1 | 2 | 3 | 4 || customers?.etUserStatus as 1 | 2 | 3 | 4}
               />
                </Mui.Box>
                
                
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          {/* <Mui.Accordion>
        <Mui.AccordionSummary
          expandIcon={<><Mui.Tooltip title="click to expand"><MuiIcons.ExpandMore style={{color:theme.palette.primary.main}}/></Mui.Tooltip></>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Mui.Typography style={{fontWeight:"bold"}}>Customer Information</Mui.Typography>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails> */}
        <Mui.Grid xs={12}>
            <Mui.Grid container>
              {/* <Mui.Grid item xs={3}>
                <Mui.Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: theme.spacing(2),
                    margin: "1px 0 0 33px",
                    opacity: "0.8",
                  }}
                >
                  <ReactI18next.Trans i18nKey="Company details">
                    Company details
                  </ReactI18next.Trans>
                </Mui.Typography>
              </Mui.Grid> */}
              <Mui.Grid item xs={3} style={{ marginLeft: "80px" }}>
                {/* <Mui.Typography
              style={{
                fontWeight: 1000,
                fontSize: theme.spacing(2),
                margin: "6px 0 0 0",
              }}
            >
              Address
            </Mui.Typography> */}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider
              style={{
                // marginTop: theme.spacing(1),
              }}
            />
            <Mui.Grid container>
              <Mui.Grid item xs={4}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} sm={8} md={3} lg={3} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                         width: "120px",
                        height: "22px",
                        margin: "14px 0 0px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Email Id">
                      Email Id  
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={8} md={6} lg={6} xl={3}>
                    <Mui.Typography
                    onClick={()=>{window.open(`mailto:${row?.emailID || customers?.emailID}`)}}
                      style={{
                        margin: "14px 0 0 0",
                      }}
                    >
                      <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{row?.emailID || customers?.emailID}</Mui.Link></Mui.Tooltip>
                    
                      {/* {"Amrish"} */}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={6}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        height: "22px",
                        margin: "16px 0 24px 0",
                        width:theme.spacing(30),
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="First name">
                      First name
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "16px 0 24px 0",
                      }}
                    >
                      {row?.customerName?.split(/(\s+)/)[0] || customers?.customerName?.split(/(\s+)/)[0]}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                {/* <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Country">
                        Country
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "16px 0 0 0px",
                      }}
                    >
                      {row?.countryName}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid> */}
              </Mui.Grid>
              <Mui.Grid item xs={2}></Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 14px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Last name">
                      Last name
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography style={{ margin: "14px 0 14px 0" }}>
                      {row?.customerName?.split(/(\s+)/)[2] || customers?.customerName?.split(/(\s+)/)[2]}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                 
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
         

 
         
    <Grid container spacing={4}>
      
    <Grid item xs={12} >
      <Mui.Paper elevation={0} className={classes.paper} style={{backgroundColor:"#fafafa"}}>
        <StyledToggleButtonGroup size="small" style={{width:"200%"}} value={alignment} exclusive onChange={handleChange} >
          <ToggleButton style={{backgroundColor:alignment==="left"?theme.palette.primary.main:theme.palette.common.white,color:alignment==="left"?theme.palette.common.white:theme.palette.common.black}} value="left" >
            License summary
          </ToggleButton >
          <ToggleButton style={{backgroundColor:alignment==="center"?theme.palette.primary.main:theme.palette.common.white,color:alignment==="center"?theme.palette.common.white:theme.palette.common.black}} value="center" >
          Installed devices
          </ToggleButton >
          <ToggleButton style={{backgroundColor:alignment==="right"?theme.palette.primary.main:theme.palette.common.white,color:alignment==="right"?theme.palette.common.white:theme.palette.common.black}} value="right" >
          Assigned details
          </ToggleButton >
        </StyledToggleButtonGroup>
      </Mui.Paper>
    </Grid>
    </Grid>
    

   <Mui.Grid container spacing={4} style={{}}>
    {alignment ==="left" ? (<Mui.Grid item xs={12} style={{width:"100%"}}> 
    {isLoading && (
               <Mui.Box
               padding={2}
               display="flex"
               justifyContent="center"
               alignItems="center"
               width={"100%"}
               minHeight={"100%"}
               maxHeight={"100%"}
               height={400}
               flexDirection="column"
             >
               <Mui.CircularProgress />
             </Mui.Box>
              )}
      {order?.licenseSummary?.length === 0 && (<Mui.Box padding={2} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignContent:"center"}}>
              <ReactUndraw.SvgNoData
                style={{
                  backgroundImage: `radial-gradient(closest-side at 50%, ${theme.palette.text.disabled}, ${theme.palette.background.paper})`,
                  marginBottom: theme.spacing(6),
                  // marginLeft:"theme.spacing(6)"
                }}
                accentcolor={theme.palette.primary.main}
                primarycolor={theme.palette.primary.main}
                height={200}
              />
              <Mui.Typography variant="h5" align="center" style={{fontWeight:"bold",opacity:"1"
                }} gutterBottom>
                <ReactI18next.Trans i18nKey="Licenses not available">
                Licenses not available
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography
                variant="body2"
                align="center"
                style={{ marginBottom: theme.spacing(4) }}
              >
                <ReactI18next.Trans i18nKey="The customer does not have a license to display">
                The customer does not have a license to display
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
               
              </Mui.Box>
            </Mui.Box>
                )}
     <Mui.Table >
     {order?.licenseSummary?.length > 0 && (<Mui.TableHead>
          <Mui.TableRow>
            <Mui.TableCell
            align="left"
              style={{
                color: theme.palette.text.secondary,
                textTransform: "uppercase",
              }}
            >
              <ReactI18next.Trans i18nKey="Model">Model</ReactI18next.Trans>
            </Mui.TableCell>
            <Mui.TableCell
              style={{
                color: theme.palette.text.secondary,
                textTransform: "uppercase",
              }}
            >
              <ReactI18next.Trans i18nKey="Product name">Product name</ReactI18next.Trans>
            </Mui.TableCell>
            <Mui.TableCell
              align="center"
              style={{
                color: theme.palette.text.secondary,
                textTransform: "uppercase",
              }}
            >
              <ReactI18next.Trans i18nKey="Quantity">
                Quantity
              </ReactI18next.Trans>
            </Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableHead>)}
        <Mui.TableBody>
          {order?.licenseSummary?.map((product: any) => (
            <Mui.TableRow key={uuidv4()}>
              <Mui.TableCell align="left" style={{ fontWeight: "bold" }}>
                {product?.hundCode}
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Box
                    // color="primary.main"
                    marginRight={1}
                    component="span"
                    style={{ fontSize: theme.typography.h5.fontSize }}
                  >
                    <i className={product?.iconClass} />
                  </Mui.Box>
                  <Mui.Typography
                    // color="primary"
                    style={{ fontWeight: "bold" }}
                    variant="body1"
                  >
                    {product?.setName}
                  </Mui.Typography>
                </Mui.Box>
              </Mui.TableCell>
              <Mui.TableCell align="center" style={{ fontWeight: "bold" }}>
                {product.totalQuantity}
              </Mui.TableCell>
            </Mui.TableRow>
          ))}
        </Mui.TableBody>
      </Mui.Table>
     </Mui.Grid>):(alignment ==="center" ? (
       <>
              <Mui.Grid style={{width:"100%"}}>
              {isLoading && (
               <Mui.Box
               padding={2}
               display="flex"
               justifyContent="center"
               alignItems="center"
               width={"100%"}
               minHeight={"100%"}
               maxHeight={"100%"}
               height={400}
               flexDirection="column"
             >
               <Mui.CircularProgress />
             </Mui.Box>
              )}
                {order?.deviceActivationDetails.length === 0 && (<Mui.Box padding={2} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignContent:"center"}}>
              <ReactUndraw.SvgNoData
                style={{
                  backgroundImage: `radial-gradient(closest-side at 50%, ${theme.palette.text.disabled}, ${theme.palette.background.paper})`,
                  marginBottom: theme.spacing(6),
                  // marginLeft:"theme.spacing(6)"
                }}
                accentcolor={theme.palette.primary.main}
                primarycolor={theme.palette.primary.main}
                height={200}
              />
              <Mui.Typography variant="h5" style={{fontWeight:"bold"
                }} align="center" gutterBottom>
                <ReactI18next.Trans i18nKey="Installed devices not found">
                Installed devices not found
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography
                variant="body2"
                align="center"
                style={{ marginBottom: theme.spacing(4) }}
              >
                <ReactI18next.Trans i18nKey="The customer has not completed the installation on his device">
                The customer has not completed the installation on his device
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
                
              </Mui.Box>
            </Mui.Box>
                )}
           </Mui.Grid>  
           <Mui.Grid>
      {order?.deviceActivationDetails.map((device: any) => (
                <Mui.Grid key={uuidv4()} style={{margin:"36px"}}>
                  <Card
                    device={device}
                   
                  />
                </Mui.Grid>

                
              )
            )}
            </Mui.Grid>
     </>
   ):(
     
   <Mui.Grid item xs={12}> 
        

              <Mui.Grid style={{width:"100%"}}>
              {isLoading && (
               <Mui.Box
               padding={2}
               display="flex"
               justifyContent="center"
               alignItems="center"
               width={"100%"}
               minHeight={"100%"}
               maxHeight={"100%"}
               height={400}
               flexDirection="column"
             >
               <Mui.CircularProgress />
             </Mui.Box>
              )}
                {(((order?.dealerAssignedLicenseDetails.length === 0 && userStore.user?.RoleName ==="admin") || (order?.dealerAssignedLicenseDetails.length === 0 && userStore.user?.RoleName ==="superadmin"))
                 &&(<Mui.Box padding={2} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignContent:"center"}}>
              <ReactUndraw.SvgNoData
                style={{
                  backgroundImage: `radial-gradient(closest-side at 50%, ${theme.palette.text.disabled}, ${theme.palette.background.paper})`,
                  marginBottom: theme.spacing(6),
                  // marginLeft:"theme.spacing(6)"
                }}
                accentcolor={theme.palette.primary.main}
                primarycolor={theme.palette.primary.main}
                height={200}
              />
              <Mui.Typography variant="h5" style={{fontWeight:"bold"
                }} align="center" gutterBottom>
                <ReactI18next.Trans i18nKey="No assigned details">
                No assigned details
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography
                variant="body2"
                align="center"
                style={{ marginBottom: theme.spacing(4) }}
              >
                <ReactI18next.Trans i18nKey="No dealer has assigned the license to this customer">
                No dealer has assigned the license to this customer
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
              
              </Mui.Box>
            </Mui.Box>
                ))}
           </Mui.Grid>  
    <Mui.Table >
       {order?.dealerAssignedLicenseDetails?.length > 0 &&(<Mui.TableHead>
         <Mui.TableRow>
           <Mui.TableCell
             style={{
               color: theme.palette.text.secondary,
               textTransform: "uppercase",
             }}
           >
             <ReactI18next.Trans i18nKey="Order code  #">Order code #</ReactI18next.Trans>
           </Mui.TableCell>
           <Mui.TableCell
             style={{
               color: theme.palette.text.secondary,
               textTransform: "uppercase",
             }}
           >
             <ReactI18next.Trans i18nKey="Name">Product name</ReactI18next.Trans>
           </Mui.TableCell>
           <Mui.TableCell
             align="center"
             style={{
               color: theme.palette.text.secondary,
               textTransform: "uppercase",
             }}
           >
             <ReactI18next.Trans i18nKey="Assigned quantity">
               Assigned quantity
             </ReactI18next.Trans>
           </Mui.TableCell>
         </Mui.TableRow>
       </Mui.TableHead>)}
       <Mui.TableBody>
         {order?.dealerAssignedLicenseDetails?.map((product: any) => (
           <Mui.TableRow key={uuidv4()}>
             <Mui.TableCell style={{ fontWeight: "bold" }}>
               {product.orderCode}
             </Mui.TableCell>
             <Mui.TableCell>
               <Mui.Box display="flex" alignItems="baseline">
                 <Mui.Box
                   // color="primary.main"
                   marginRight={1}
                   component="span"
                   style={{ fontSize: theme.typography.h5.fontSize }}
                 >
                   <i className={product?.iconClass} />
                 </Mui.Box>
                 <Mui.Typography
                   // color="primary"
                   style={{ fontWeight: "bold" }}
                   variant="body1"
                 >
                   {product?.productName}
                 </Mui.Typography>
               </Mui.Box>
             </Mui.TableCell>
             <Mui.TableCell align="center" style={{ fontWeight: "bold" }}>
               {product?.assignedQuantity}
             </Mui.TableCell>
           </Mui.TableRow>
         ))}
       </Mui.TableBody>
     </Mui.Table>
    </Mui.Grid>
    ))}
     
</Mui.Grid>

    </>
  );
}
