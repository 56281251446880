/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as DealerCustomer from "src/app/pages/dealercustomer";
import * as Models from "src/models";
import * as MuiIcon from "@material-ui/icons";

interface Data extends Models.DealerCustomer.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numselected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowcount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export function EnhancedTableUser({
  rows,
}: {
  rows: Models.DealerCustomer.Main[];
 
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("customerName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.customerID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const navigate = Router.useNavigate();
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const [adminAndCustomerRoleCheck, setAdminAndCustomerRoleCheck] =
    React.useState(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  React.useEffect(() => {
    if (
      userRole === "admin" ||
      userRole === "superadmin" ||
      userRole === "customer"
    ) {
      setAdminAndCustomerRoleCheck(false);
    } else {
      setAdminAndCustomerRoleCheck(true);
    }
  }, [userRole, adminAndCustomerRoleCheck]);
  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: any;
  }) => {
    const selectedIndex = dCustomerStore.selected.indexOf(dealerProfile);
    let newSelected: Models.CustomerLicense.Main[] = [];
    dCustomerStore.setSelected({ selected: newSelected });
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(dCustomerStore.selected, dealerProfile);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(1));
    } else if (selectedIndex === dCustomerStore.selected.length - 1) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        dCustomerStore.selected.slice(0, selectedIndex),
        dCustomerStore.selected.slice(selectedIndex + 1)
      );
    }
    dCustomerStore.setSelected({ selected: newSelected });
    let arrayCustomer: any = [];
    arrayCustomer = newSelected;

    const customerName = arrayCustomer.map((k: any) => k.customerName);
    const mailID: any = arrayCustomer.map((k: any) => k.emailID);
    const companyName: any = arrayCustomer.map((k: any) => k.companyName);
    const customerID: any = arrayCustomer.map((k: any) => k.customerID);
    localStorage.setItem("mID", mailID);
    localStorage.setItem("cuName", customerName);
    localStorage.setItem("coName", companyName);
    localStorage.setItem("customerID", customerID);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const [customerId, setCustomerId] = React.useState<number | string>();
  const [MailID, setMailID] = React.useState<number | string>();
  const [Row, setRow] = React.useState<any>();
  const [customerDetailsDialogOpen, setCustomerDetailsDialogOpen] =
    React.useState<boolean>(false);
  const uid = userStore.user?.ETUID;
  const roleName = "dealer";

  return (
    <div className={classes.root}>
      <Components.responsiveScreen
        fullscreenBreakpoint="md"
        open={customerDetailsDialogOpen}
        handleClose={() => setCustomerDetailsDialogOpen(false)}
        title={t("Customer details")}
        contentText={
          <DealerCustomer.Details
            uid={customerId}
            userRole={roleName}
            mailID={MailID}
            row={Row}
          />
        }
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => window.print()}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setCustomerDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
            
                   <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="customerName"
                  label={t("Customer dealer ID")}
                />
                <EnhancedTableHead
                  
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="customerName"
                  label={t("Company name")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="companyName"
                  label={t("Customer name")}
                />
                
                <EnhancedTableHead
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="emailID"
                  label={t("Email ID")}
                />
              

                
                  <EnhancedTableHead
                    rightmost
                    classes={classes}
                    numselected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowcount={rows.length}
                    id="etUserStatus"
                    label={t("Status")}
                  />
              
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.customerID as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    style={{ height: "70px" }}
                    hover
                    onClick={(event) => {
                      if (row.etUserStatus !== 5) {
                        handleClick(event, row.customerID as string);
                        setCustomerId(row.customerID);
                        setMailID(row.emailID);
                        setRow(row);
                        if (row.etuid) {
                          navigate(
                            `/dealercustomer/customerdetail?q=${row.etuid}&m=${row.customerID}`
                          );
                        }
                      } else {
                        enqueueSnackbar(
                          t("Detail view not available for license unassigned"),
                          {
                            variant: "warning",
                            preventDuplicate: false,
                          }
                        );
                      }
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.customerID}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                      specialRowColor
                    >
                      {row.customerDealerID}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      specialRowColor
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.companyName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      specialRowColor
                    >
                      {row.customerName}
                    </Components.Mui.TableCell>
                   

                    <Components.Mui.TableCell
                    
                      align="left"
                      onClick={() => {
                        window.open(`mailto:${row.emailID}`);
                      }}
                      specialRowColor
                    >
                      <Mui.Tooltip
                        title={
                          <ReactI18next.Trans i18nKey="click to open an mail">
                            click to open an mail
                          </ReactI18next.Trans>
                        }
                      >
                        <Mui.Link style={{ color: "black" }}>
                          {row.emailID}
                        </Mui.Link>
                      </Mui.Tooltip>
                    </Components.Mui.TableCell>
                    
                    
                      <Components.Mui.TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        rightmost
                        specialRowColor
                      >
                        <Mui.Typography>
                          {row.etUserStatus > 0 ? (
                            <Mui.Grid container justify="space-between">
                              <Components.CustomerStatus
                                status={
                                  row.etUserStatus === 1
                                    ? 1
                                    : 3
                                }
                              />
                             
                            </Mui.Grid>
                          ) : (
                            <Mui.Divider />
                          )}
                        </Mui.Typography>
                      </Components.Mui.TableCell>
                   
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No customers found">
                        No customers found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows, display: "none" }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
     
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
     
    </div>
  );
}