/** @format */

import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Generate() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["superadmin", "admin", "customer"]}
          path="/"
          element={<Pages.License.Generate.Main />}
        />
        <Routes.Route
          restricted
          roles={["superadmin", "admin", "customer"]}
          path="success"
          element={<Pages.License.Generate.Success />}
        />
      </Router.Routes>
    </>
  );
}
