import * as React from "react";
import * as Router from "react-router-dom";
import * as Components from "src/app/components";
import * as Layouts from "src/app/layouts";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";
import * as Constants from "src/constants";

export function Account() {
  return (
    <>
      <Layouts.None.Main>
        <Router.Routes>
          <Routes.Route path="login" element={<Pages.Account.Login />} />
          <Router.Route
            path="register"
            element={
              <Components.Redirect to={Constants.environment.KARegisterURL} />
            }
          />
        
        </Router.Routes>
      </Layouts.None.Main>
    </>
  );
}
