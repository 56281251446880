/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FlagIcon from "@material-ui/icons/Flag";
import InfoIcon from "@material-ui/icons/Info";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ReactI18next from "react-i18next";
import { useMediaQuery } from "react-responsive";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as ManageDealer from "src/app/pages/dealercustomer/managedealer";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as MuiIcon from "@material-ui/icons";

interface Data extends Models.ImportDealer.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({
  rows,
  displayData,
  isEmailFlag,
}: {
  rows: Models.ImportDealer.Main[];
  isEmailFlag: boolean;
  displayData: boolean;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("companyName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.dealerProfileID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const navigate = Router.useNavigate();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const stopnavigationHandle = (event: any) => {
    event.stopPropagation();
  };
  const importDealerStoreS = React.useContext(App.Contexts.importDealerStore);
  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="companyName"
                  label={t("Company")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="firstName"
                  label={t("Dealer user name")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="email"
                  label={t("Email ID")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="phoneNumber"
                  label={t("Phone no")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="country"
                  label={t("Country")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="city"
                  label={t("City")}
                />
                <EnhancedTableHead rightmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="status"
                  label={t("Status")}
                />
            
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>

          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.dealerProfileID as any);
                const labelId = `enhanced-table-checkbox-${index}`;
                var data = row.dealerAddressID ?? "";
                var dealerMasterID = btoa(data.toString());
                var dataProfile = row.dealerMasterID ?? "";
                var dealerProfileID = btoa(dataProfile.toString());
                return (
                  <Mui.TableRow
                    hover
                    classes={classes}
                    onClick={() => {
                      let newSelected: any[] = [];
                       importDealerStoreS.setSelected({
                         selected: newSelected,
                       });
                      localStorage.setItem("dealerID", JSON.stringify(row));
                      localStorage.setItem("etID", dealerProfileID.toString());
                      

                      navigate(
                        `/dealercustomer/adddealeruser?q=${dealerProfileID}&m=${dealerMasterID}`
                      );
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.dealerProfileID}
                  >
                    <Components.Mui.TableCell leftmost component="th" id={labelId}>
                      {row.companyName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.firstName}
                      {row.userType === "P" ? (
                        <Mui.Tooltip title={
                          <ReactI18next.Trans i18nKey={"Primary user"}>
                            Primary user
                          </ReactI18next.Trans>}>
                          <FlagIcon
                            style={{
                              color: theme.palette.success.main,
                              marginLeft: "20px",
                              verticalAlign: "text-bottom",
                            }}
                          />
                        </Mui.Tooltip>
                      ) : (
                        ""
                      )}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell onClick={()=>{window.open(`mailto:${row.email}`)}}>
                    <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{row.email}</Mui.Link></Mui.Tooltip>
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell component="span"   onClick={stopnavigationHandle}>
                      <Mui.Grid style={{justifyContent:"space-between",display:"flex"}}>
                     <Mui.Typography>{row.phoneNumber}</Mui.Typography>
                      <Mui.Tooltip
                        // title={copied === row.macId ? "Copied!" : ""}
                        title={
                          <ReactI18next.Trans i18nKey={"Click to copy the phone number"}>
                            Click to copy the phone number.
                          </ReactI18next.Trans>}
                      >

                        <ReactCopyToClipboard.CopyToClipboard 
                        component="span"
                          text={row.phoneNumber}
                          // style={{ float: "right", top: "-5px"}}
                          onCopy={() => {
                            enqueueSnackbar(
                              t(
                                `Phone number of ${row.firstName} has been successfully copied to clipboard.`
                              ),
                              {
                                variant: "success",
                                preventDuplicate: false,
                              }
                            );
                          }}
                        >
                          <Mui.IconButton size="small" onClick={() => {}}>
                            <MuiIcons.ContentCopy />
                          </Mui.IconButton>
                        </ReactCopyToClipboard.CopyToClipboard>
                      </Mui.Tooltip>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.countryName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.city}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell rightmost>
                   
                       <Mui.Grid container justify="space-between">
                       {row.status === "0" ? (
                        <Mui.Chip
                          icon={
                            <InfoIcon
                              style={{
                                color: theme.palette.info.main,
                              }}
                            />
                          }
                          label="Yet to import"
                        ></Mui.Chip>
                      ) : (
                        <Mui.Chip
                          icon={
                            <CheckCircleIcon
                              style={{ color: theme.palette.success.main }}
                            />
                          }
                          label="Imported"
                        ></Mui.Chip>
                      )}
                        <Mui.IconButton
                          size="medium"
                          style={{
                            padding: theme.spacing(0.125),
                            fontSize: "18px",
                          }}
                        >
                          <MuiIcon.ChevronRight
                            style={{ fontSize: "30px" }}
                            color="primary"
                          />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                   
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No dealer found">No dealer found</ReactI18next.Trans></MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell style={{ display: "none" }} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
     
      {rows.length > 7 && (
        <Mui.Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: theme.spacing(4),
          }}
        >
          <Mui.Grid item>
            
          </Mui.Grid>
          <Mui.Grid item>
            <MuiLab.Pagination
              // NOTE: Mui Lab Pagination assumes page starts at 1
              page={page + 1}
              count={Math.ceil(rows.length / rowsPerPage)}
              // NOTE: Mui Lab Pagination assumes page starts at 1
              onChange={(event, page) => handleChangePage(event, page - 1)}
              shape="rounded"
            />
          </Mui.Grid>
        </Mui.Grid>
      )}
    </div>
  );
}
export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const [state, setState] = React.useState<any>();
  const [isEmailColor, setIsEmailColor] = React.useState<boolean>(true);
  const [displayNoData, setDisplayNoData] = React.useState<boolean>(false);
  const theme = Mui.useTheme();
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const importDealerStore = React.useContext(App.Contexts.manageDealerStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const etuid = userStore.user?.ETUID;
  const RoleName = userStore.user?.RoleName;
  const { t } = ReactI18next.useTranslation();
  React.useEffect(() => {
    userStore.setPreviousUrl({
      urls: "/dealercustomer/managedealer",
    });
    if (RoleName === "dealer") {
      const dealerLogin = importDealerStore.readUsers({
        searchtext: "All",
        roleName: etuid,
      });
      localStorage.setItem("dealerID", JSON.stringify(dealerLogin));
    } else {
      importDealerStore.readUsers({ searchtext: "All", roleName: 0 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let searchHighlight: Boolean = false;
  const getSearchHighlight = (highlightFlag: boolean) => {
    searchHighlight = highlightFlag;
    return highlightFlag;
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };

  const Nodata = importDealerStore.manageDealer.length > 0 ? true : false;
  const handleChange = (event: any) => {
    setSearch(event.target.value as string);
  };
  return (
    <>
      <Mui.Grid
        container
        style={{
          marginBottom: theme.spacing(3),
          justifyContent: "space-between",
        }}
      >
        {Nodata && (
          <Mui.Grid item style={{ marginRight: "auto" }}>
            <Mui.FormControl
              style={{
                width: "250px",
                margin: "14px 0 0px 0px",
              }}
            >
              <Mui.InputLabel id="demo-simple-select-label">
                <ReactI18next.Trans i18nKey="Country">
                  Country
                </ReactI18next.Trans>
              </Mui.InputLabel>
              <Mui.Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state}
                onChange={handleChange}
              >
                <Mui.MenuItem value="">None</Mui.MenuItem>
                {Array.from(
                  importDealerStore &&
                    importDealerStore.manageDealer
                      .reduce((acc: Set<any>, set) => {
                        return acc.add(set.countryName);
                      }, new Set())
                      .values()
                ).map((countryName: any) => (
                  <Mui.MenuItem key={uuidv4()} value={countryName}>
                    {countryName}
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
          </Mui.Grid>
        )}
       
        {Nodata && (
          <Mui.FormControl style={{}}>
            <Mui.TextField
              style={{
                marginTop: "14px",
                marginRight: theme.spacing(1),
                width: theme.spacing(40),
              }}
              label={t("Select dealer")}
              onChange={handleSearch}
              placeholder={t("Search")}
            />
          </Mui.FormControl>
        )}
      </Mui.Grid>
      {importDealerStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {importDealerStore.view === "CARD" && (
            <Mui.Hidden smDown>
              <ManageDealer.Views.Cards
                dealers={importDealerStore.manageDealer.filter(
                  (manageDealer) => {
                    if (!search) {
                      return true;
                    }
                    return (
                      manageDealer?.companyName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.firstName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.countryName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.email
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.phoneNumber
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    );
                  }
                )}
              />
            </Mui.Hidden>
          )}

          {isPortrait && (
            <Mui.Hidden mdUp>
              <ManageDealer.Views.Cards
                dealers={importDealerStore.manageDealer.filter(
                  (manageDealer) => {
                    if (!search) {
                      return true;
                    }
                    return (
                      manageDealer?.companyName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.firstName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.countryName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      manageDealer?.email
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    );
                  }
                )}
              />
            </Mui.Hidden>
          )}
          {importDealerStore.view === "TABULAR" && (
            <Mui.Hidden smDown>
              <EnhancedTable
                rows={importDealerStore.manageDealer.filter((userData) => {
                  if (!search) {
                    return true;
                  }
                  return (
                    userData?.companyName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    userData?.firstName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    userData?.email
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    userData?.phoneNumber
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    userData?.countryName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    userData?.city?.toLowerCase().includes(search.toLowerCase())
                  );
                })}
                displayData={displayNoData}
                isEmailFlag={isEmailColor}
              />
            </Mui.Hidden>
          )}
        </>
      )}
    </>
  );
});
