import * as Mobx from "mobx";
import * as i18n from "src/app/i18n";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    search: string;
    status?: number;
    createDialogOpen: boolean;
    industrySegments: Models.IndustrySegment.Main[];
  }

  export type Options = {
    industrySegmentService: Services.IndustrySegment;
  };

  export namespace SetCreateDialogOpen {
    export interface Props extends Pick<Store.IStore, "createDialogOpen"> {}
  }

  export namespace Read {
    export interface Props extends Services.IndustrySegment.GetAllData.Props {}
  }

  export namespace SetSearch {
    export interface Props extends Pick<Store.IStore, "search"> {}
  }

  export namespace SetStatus {
    export interface Props extends Pick<Store.IStore, "status"> {}
  }

  export namespace SetLocale {
    export interface Props {
      locale: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable private _locale: string = "en";

  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public search: string = "";
  @Mobx.observable public status?: number = 1;
  @Mobx.observable public createDialogOpen: boolean = false;
  @Mobx.observable public industrySegments: Models.IndustrySegment.Main[] = [];

  private _industrySegmentService: Services.IndustrySegment;

  constructor({ industrySegmentService }: Store.Options) {
    this._industrySegmentService = industrySegmentService;
    i18n.instance.on("languageChanged", (language) =>
      this.setLocale({ locale: language })
    );
  }

  @Mobx.action public reset() {
    this.search = "";
    this.status = 1;
  }

  @Mobx.action public setLocale({ locale }: Store.SetLocale.Props) {
    this._locale = locale.toLowerCase();

    this.industrySegments = this.industrySegments.map((industrySegment) => ({
      ...industrySegment,
      name: (industrySegment[
        `name${this._locale.toUpperCase()}` as keyof Models.IndustrySegment.Main
      ] ?? industrySegment.nameEN) as string,
    }));
  }

  @Mobx.action setCreateDialogOpen({
    createDialogOpen,
  }: Store.SetCreateDialogOpen.Props) {
    this.createDialogOpen = createDialogOpen;
  }

  @Mobx.action public setSearch({ search }: Store.SetSearch.Props) {
    this.search = search;
  }

  @Mobx.action public setStatus({ status }: Store.SetStatus.Props) {
    this.status = status;
  }

  @Mobx.action public async read(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this._industrySegmentService.getAllData(props);

      this.industrySegments = result;
      this.setLocale({ locale: i18n.instance.language });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.industrySegments.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get filtered() {
    return this.industrySegments
      .filter((segment: any) => {
        if (!this.status) {
          return true;
        }
        return segment.status === this.status;
      })
      .filter((segment) => {
        if (!this.search) {
          return true;
        }
        return (
          ((segment[
            `name${this._locale.toUpperCase()}` as keyof Models.IndustrySegment.Main
          ] ?? segment.nameEN) as string) &&
          ((segment[
            `name${this._locale.toUpperCase()}` as keyof Models.IndustrySegment.Main
          ] ?? segment.nameEN) as string)
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      });
  }
}
