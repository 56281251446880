import * as Mobx from "mobx";
import * as i18n from "src/app/i18n";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD";

  export interface IStore {
    isLoading: boolean;
    search: string;
    status?: number;
    view: Store.View;
    createDialogOpen: boolean;
    articles: Models.Set.Article[];
  }

  export type Options = Pick<IStore, "view"> & {
    articleService: Services.Article;
  };

  export namespace SetView {
    export interface Props extends Pick<Store.IStore, "view"> {}
  }

  export namespace SetCreateDialogOpen {
    export interface Props extends Pick<Store.IStore, "createDialogOpen"> {}
  }

  export namespace SetSearch {
    export interface Props extends Pick<Store.IStore, "search"> {}
  }

  export namespace SetStatus {
    export interface Props extends Pick<Store.IStore, "status"> {}
  }

  export namespace SetLocale {
    export interface Props {
      locale: string;
    }
  }

  export namespace Read {
    export interface Props extends Services.Article.GetAllData.Props {}
  }

  export namespace GetAllSetData {
    export interface Props {
      userID?: string | any;
      customerDealerId?: string | any;
    }
  }

  export namespace GetAllPurchanseSetData {
    export interface Props {
      orderID?: string | any;
    }
    export type Return = Models.API.Response<Models.Set.Article[]>;
  }

}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable private _locale: string = "en";

  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public search: string = "";
  @Mobx.observable public status?: number = 1;
  @Mobx.observable public view: Store.View = "TABULAR";
  @Mobx.observable public createDialogOpen: boolean = false;
  @Mobx.observable public articles: Models.Set.Article[] = [];

  private _translations: Map<string, Models.Set.Article> = new Map();
  private _articleService: Services.Article;

  constructor({ view, articleService }: Store.Options) {
    this.view = view;
    this._articleService = articleService;
    i18n.instance.on("languageChanged", (language) =>
      this.setLocale({ locale: language })
    );
  }

  @Mobx.action public reset() {
    this.search = "";
    this.status = 1;
  }

  @Mobx.action setLocale({ locale }: Store.SetLocale.Props) {
    this._locale = locale.toLowerCase();

    this.articles = this.articles.map((article) => {
      const translatedArticle = this._translations.get(
        `${article.setID}${this._locale.toLowerCase()}`
      );

      return {
        ...article,
        setName: translatedArticle?.setName ?? article.setName,
        setDescriptions:
          translatedArticle?.setDescriptions ?? article.setDescriptions,
      };
    });
  }

  @Mobx.action setView({ view }: Store.SetView.Props) {
    this.view = view;
  }

  @Mobx.action public setSearch({ search }: Store.SetSearch.Props) {
    this.search = search;
  }

  @Mobx.action public setStatus({ status }: Store.SetStatus.Props) {
    this.status = status;
  }

  @Mobx.action setCreateDialogOpen({
    createDialogOpen,
  }: Store.SetCreateDialogOpen.Props) {
    this.createDialogOpen = createDialogOpen;
  }

  private _prepareTranslations() {
    this._translations = this.articles.reduce(
      (acc: Map<string, Models.Set.Article>, article: Models.Set.Article) => {
        const { setID } = article;
        acc.set(`${setID}en`, { ...article });
        article.setsLang.map((lang) =>
          acc.set(`${setID}${lang.langCode.toLowerCase()}`, {
            ...article,
            setName: lang.setName,
            setDescriptions: lang.setDescriptions,
          })
        );
        return acc;
      },
      new Map()
    );
  }

  @Mobx.action public async read(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this._articleService.getAllData(props);

      this.articles = result;
      this._prepareTranslations();
      this.setLocale({ locale: i18n.instance.language });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async readForCustomerInvite(
    props?: Store.GetAllSetData.Props
  ) {
    try {
      this.isLoading = true;
      const { result } = await this._articleService.getAllSetForInviteData(
        props
      );

      this.articles = result;
      this._prepareTranslations();
      this.setLocale({ locale: i18n.instance.language });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async readCustomerInvitePurchange(
    props?: Store.GetAllPurchanseSetData.Props
  ) {
    try {
      this.isLoading = true;
      const { result } =
      await this._articleService.getAllPurchangeSetForInviteData(props);
      return result;
    
    }

    catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.articles.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get filtered() {
    return this.articles
      .filter((article) => {
        if (!this.status) {
          return true;
        }
        return article.status === this.status;
      })
      .filter((article) => {
        if (!this.search) {
          return true;
        }
        return (
          (article.setName &&
            article.setName
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (article.setCode &&
            article.setCode
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (article.hundCode &&
            article.hundCode.toLowerCase().includes(this.search.toLowerCase()))
        );
      });
  }
}
