import * as Mobx from "mobx";
import * as i18n from "src/app/i18n";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    search: string;
    status?: number;
    createDialogOpen: boolean;
    functions: Models.Set.Function[];
  }

  export type Options = {
    functionService: Services.Function;
  };

  export namespace SetCreateDialogOpen {
    export interface Props extends Pick<Store.IStore, "createDialogOpen"> {}
  }

  export namespace Read {
    export interface Props extends Services.Function.GetAllData.Props {}
  }

  export namespace SetSearch {
    export interface Props extends Pick<Store.IStore, "search"> {}
  }

  export namespace SetStatus {
    export interface Props extends Pick<Store.IStore, "status"> {}
  }

  export namespace SetLocale {
    export interface Props {
      locale: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable private _locale: string = "en";

  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public search: string = "";
  @Mobx.observable public status?: number = 1;
  @Mobx.observable public createDialogOpen: boolean = false;
  @Mobx.observable public functions: Models.Set.Function[] = [];

  private _translations: Map<string, Models.Set.Function> = new Map();
  private _functionService: Services.Function;

  constructor({ functionService }: Store.Options) {
    this._functionService = functionService;
    i18n.instance.on("languageChanged", (language) =>
      this.setLocale({ locale: language })
    );
  }

  @Mobx.action public reset() {
    this.search = "";
    this.status = 1;
  }

  private _prepareTranslations() {
    this._translations = this.functions.reduce(
      (acc: Map<string, Models.Set.Function>, func: Models.Set.Function) => {
        const { setFunctionID } = func;
        acc.set(`${setFunctionID}en`, { ...func });
        func.setFunctionsLangs.map((lang) =>
          acc.set(`${setFunctionID}${lang.langCode.toLowerCase()}`, {
            ...func,
            funcationName: lang.funcationName,
          })
        );
        return acc;
      },
      new Map()
    );
  }

  @Mobx.action public setLocale({ locale }: Store.SetLocale.Props) {
    this._locale = locale.toLowerCase();

    this.functions = this.functions.map((func) => ({
      ...func,
      funcationName:
        this._translations.get(
          `${func.setFunctionID}${this._locale.toLowerCase()}`
        )?.funcationName ?? func.funcationName,
    }));
  }

  @Mobx.action public setSearch({ search }: Store.SetSearch.Props) {
    this.search = search;
  }

  @Mobx.action public setStatus({ status }: Store.SetStatus.Props) {
    this.status = status;
  }

  @Mobx.action public async read(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this._functionService.getAllData(props);

      this.functions = result;
      this._prepareTranslations();
      this.setLocale({ locale: i18n.instance.language });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.functions.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get filtered() {
    return this.functions
      .filter((func) => {
        if (!this.status) {
          return true;
        }
        return func.status === this.status;
      })
      .filter((func) => {
        if (!this.search) {
          return true;
        }
        return (
          (func.funcationName &&
            func.funcationName
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (func.funcationCode &&
            func.funcationCode
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (func.iconClass &&
            func.iconClass.toLowerCase().includes(this.search.toLowerCase()))
        );
      });
  }

  @Mobx.action setCreateDialogOpen({
    createDialogOpen,
  }: Store.SetCreateDialogOpen.Props) {
    this.createDialogOpen = createDialogOpen;
  }
}
