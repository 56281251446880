/** @format */

import * as Mui from "@material-ui/core";
import PropTypes from "prop-types";
import * as MuiIcon from "@material-ui/icons";
import React from "react";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Containers from "src/app/containers";
import { v4 as uuidv4 } from "uuid";
import * as Configs from "src/configs";

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  Icon: PropTypes.string,
  items: PropTypes.array,
  // roles: PropTypes.string,
};

type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, "items">;

export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
};
const useStyles = Mui.makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  themeTogglePaperRoot: {
    position: "fixed",
    top: `calc(50vh - (119px + ${theme.spacing(-3)}px))`,
    left: 93,
    marginLeft: 10,
    maxWidth: "fit-content",
    boxShadow: "none",
    display: "block",
    backgroundColor: theme.palette.background.default,
  },
}));
const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
  const { name, Icon, route, items = [] } = props;

  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const etuid = userStore.user?.ETUID;
  const roles = userStore.user?.RoleName;
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getPreviousURL() {
    let url;
    if (roles === "admin") {
      url = "/dealercustomer?roles=admin" as string;
      //  alert("admin");
    }
    if (roles === "superadmin") {
      url = "/dealercustomer?roles=superadmin" as string;
      //  alert("admin");
    }
    if (roles === "customer") {
      url = "/dealercustomer?roles=customer" as string;
      // alert("customer");
    }
    if (roles === "dealer") {
      url = "/dealercustomer/managedealer" as string;
      // alert("dealer");
    }
    return url as string;
  }

  function handleClick() {
    setOpen(!open);
  }

  function handleClickFoucs() {
    if (route === "menu") {
      setOpen(!open);
    }
  }

  function handleMainClickFoucs() {
    if (route != "menu") {
      setOpen(false);
    }
  }

  function handleSubClick() {
    setOpen(true);
  }

  const theme = Mui.useTheme();
  const location = Router.useLocation();
  React.useEffect(() => {
    setOpen(false)
  }, [location]);

  const MenuItemRoot = (
    <Mui.Box onMouseLeave={handleMainClickFoucs}>
      <Mui.ListItem
        button
        style={{ height: 110 }}
        to={route === "menu" ? getPreviousURL() : route}
        component={Router.NavLink}
        onClick={handleMainClickFoucs}
         disableGutters={true}
        onFocusCapture={handleClickFoucs}
        activeStyle={{
          backgroundColor: theme.palette.background.default,
          borderLeft: `${theme.spacing(0.8)}px solid ${
            theme.palette.primary.main
          }`,
        }}
      >
        <Mui.Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          
        >
          <Mui.Box fontSize={theme.typography.h5.fontSize}>
            <img
                src={Configs.APP.prefixPublicUrl({
                  url: `assets/drawer-logo/${Icon}.svg`,
                })}
                style={{ height: "24px", maxHeight: "24px" }}
                alt="Easy Touch Logo"
              />
          </Mui.Box>

          <Mui.Typography
            style={{ lineHeight: 1,
              display: "inline-block",
              wordBreak: "break-word"  }}
            variant="body2"
            align="center"
            
          >
            {name}
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>
    </Mui.Box>
  );

  const MenuItemChildren = isExpandable ? (
 

    <Mui.Collapse
      className={classes.themeTogglePaperRoot}
      in={open}
      timeout="auto"
      unmountOnExit
      style={{ boxShadow: "5px 10px 8px #888888" }}
    >
      <Mui.Box onMouseLeave={handleSubClick}>
        {items.map((itemsSub) => (
          <Containers.ShowIfAuthorised roles={["superadmin","admin", "dealer"]}>
            <Mui.ListItem
              style={{ height: 80 }}
              button
              onClick={handleSubClick}
              component={Router.NavLink}
              to={itemsSub.route}
             
            >
              <Mui.Grid
                container
                justify="center"
                style={{
                  borderRadius: 6,
                }}
                alignItems="center"
                direction="column"
              >
                <Mui.Box fontSize={theme.typography.h5.fontSize}>
                  {(itemsSub.name === "Admin" || itemsSub.name === "superadmin") ? (
                    <img
                    src={Configs.APP.prefixPublicUrl({
                      url: `assets/drawer-logo/Administrator.svg`,
                    })}
                    style={{ height: "24px", maxHeight: "24px" }}
                    alt="Easy Touch Logo"
                  />
                  ) : itemsSub.name === "Customers" ? (
                    <img
                    src={Configs.APP.prefixPublicUrl({
                      url: `assets/drawer-logo/Customers.svg`,
                    })}
                    style={{ height: "24px", maxHeight: "24px" }}
                    alt="Easy Touch Logo"
                  />
                  ) : (
                    <img
                    src={Configs.APP.prefixPublicUrl({
                      url: `assets/drawer-logo/Dealers.svg`,
                    })}
                    style={{ height: "24px", maxHeight: "24px" }}
                    alt="Easy Touch Logo"
                  />
                  )}
                </Mui.Box>
                <Mui.Typography
                  style={{ lineHeight: 1 }}
                  variant="body2"
                  align="center"

                >
                  {itemsSub.name}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.ListItem>
          </Containers.ShowIfAuthorised>
        ))}
      </Mui.Box>
    </Mui.Collapse>
  ) : null;

  return (
    <>
      <React.Fragment key={uuidv4()}>
        {MenuItemRoot}
        {MenuItemChildren}
      </React.Fragment>
    </>
  );
};
AppMenuItem.propTypes = AppMenuItemPropTypes;
export default AppMenuItem;
