import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
  })
);

export declare namespace Create {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  >;
}

// eslint-disable-next-line no-redeclare
export const Create = Mobx.observer(function (props: Create.Props) {
  const { t } = ReactI18next.useTranslation();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const userStore = React.useContext(App.Contexts.userStore);
  const articleStore = React.useContext(App.Contexts.articleStore);
  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  async function handleCreate(
    values: Article.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Article.Form.Values>
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error("uid is undefined");
      }

      const functions = ((values.setID as unknown) as Models.Set.Function[]).map(
        (set) => set.setFunctionID
      );

      const dependentSets = ((values.dependentSets as unknown) as Models.Set.Article[]).map(
        (article) => article.setID
      );

      const industrySegment = (values.industrySegmentsId as unknown) as Models.IndustrySegment.Main;

      const iconClass = ((values.iconClass as unknown) as Article.Form.Options.Icon)
        .iconName;

      const article = ({
        functions,
        dependentSets,
        hundCode: values.hundCode,
        iconClass,
        industrySegment: industrySegment.nameEN,
        industrySegmentsId: industrySegment.industrySegmentsId,
        isItFree: values.isFree,
        licensePerSet: values?.isLimited === true ? 1 : values.licensesPerArticle,
        moreInfo: values.linkForMoreInfo,
        setCode: values.code,
        setCost: values.netPricePerItem,
        setDescriptions: values.description,
        setID: 0,
        setName: values.name,
        status: 1,
        licenseValidityPeriod: values.licenseValidityPeriod,
        validityPeriod: values.isFree ? values.validityPeriodInDays : 0,
        isLimited: !values?.isLimited
      } as unknown) as Models.Set.Article;
      const res = await articleService.create({ body: article, uid });
      const result: any = res?.result;
      // console.log("result",result);
      if(result == 1){
      enqueueSnackbar(t("Article successfully created!"), {
        variant: "success",
        preventDuplicate: false,
      });
    }
    if(result == 2){
      enqueueSnackbar(t("Article successfully updated!"), {
        variant: "info",
        preventDuplicate: false,
      });
    }
      articleStore.read();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      props.handleClose();
    }
  }

  return (
    <>
      <Formik.Formik
        initialValues={
          {
            name: "",
            code: "",
            hundCode: "",
            description: "",
            isFree: false,
            isLimited: false,
            validityPeriodInDays: 0,
            netPricePerItem: 0,
            licensesPerArticle: 4,
            industrySegmentsId: "",
            iconClass: "",
            linkForMoreInfo: "",
            setID: [],
            dependentSets: [],
          } as Article.Form.Values
        }
        validationSchema={Article.Shared({ t }).Form.validationSchema}
        onSubmit={handleCreate}
      >
        <Form {...props} />
      </Formik.Formik>
    </>
  );
});

function Form({ open, handleClose }: Create.Props) {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const { isSubmitting, submitForm, resetForm } = Formik.useFormikContext();
  React.useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
 
  return (
    <Components.LightBox
      open={open}
      handleClose={handleClose}
      content={<Article.Form />}
      title={
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          <ReactI18next.Trans i18nKey="Add new article">
            Add new article
          </ReactI18next.Trans>
        </Mui.Typography>
      }
      actions={
        <>
          <Mui.Button variant="contained" onClick={handleClose}>
            <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => {
              submitForm();
            }}
          >
            <ReactI18next.Trans i18nKey="Save">Save</ReactI18next.Trans>
            {isSubmitting && (
              <Mui.CircularProgress
                style={{
                  marginLeft: theme.spacing(1),
                }}
                size={theme.typography.button.fontSize}
                color={"inherit"}
              />
            )}
          </Mui.Button>
        </>
      }
    />
  );
}
