import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Main() {
  return (
    <>
      <Router.Routes>
        <Routes.Route restricted path="/" element={<Pages.License.Main />} />
        <Routes.Route
          restricted
          roles={["superadmin","admin"]}
          path="manual"
          element={<Pages.License.Manual />}
        />
        {/* <Router.Route path="AddLicense/*" element={<Routes.License.AddLicense />} /> */}
        <Router.Route path="GetCustomerAssignedLicense/" element={<Routes.License.AssignedLicenseDetail />} />
        <Router.Route path="activate/*" element={<Routes.License.Activate />} />
        <Router.Route path="generate/*" element={<Routes.License.Generate />} />
      </Router.Routes>
    </>
  );
}
