import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Constants from "src/constants";
import * as Services from "src/services";
import * as Mui from "@material-ui/core";
import * as Models from "src/models";
import * as Pages from "src/app/pages";
import { UpdateDisabledOutlined } from "@material-ui/icons";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ManageOrder } from "src/app/routes/order";


export declare namespace ManageOrderDetail {
  export interface Props {
    customerList?: any;
    license?:Models.License;
    licenseList:Models.License[];
    isCustomerAdded?: boolean;
    isAssign?: number;
    unAssign?: number;
    isAssignAll: boolean;
    orderCode:string;
    isAvailable:boolean;
  }
}

// eslint-disable-next-line no-redeclare
export const ManageOrderDetail = Mobx.observer(function ({
    customerList,
    license,
    licenseList,
    isCustomerAdded,
    isAssign,
    unAssign,
    isAssignAll = false,
    orderCode = "",
    isAvailable = false
  
}: ManageOrderDetail.Props) {
 
  const [order, setOrder] = React.useState<any>();
  const [isCustomerExist, setIsCustomerExist] = React.useState<boolean>(false);
  const [isLoader, setIsLoader] = React.useState<boolean>(true);
  const [customerObject, setCustomerObject] = React.useState<Models.DealerCustomer.Main>();
  const userStore = React.useContext(App.Contexts.userStore);
  const uid = userStore.user?.ETUID;
  const theme = Mui.useTheme();
 const navigate = Router.useNavigate();
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const licenseSingleObjList = [license];
  React.useEffect(() => {
    

    if(!isAssignAll){
    const uid = license?.assignedCustomerID ?? "0";
    
    (async ()=>{
       
    
    const userRole = "customer";
    if (uid !== undefined && uid !== null && uid !== 0) {
     await dCustomerStore.read({
        uid,
        userRole,
      });
      
    
      setIsCustomerExist(true);
   var customer : any = dCustomerStore.delaerCustomer.filter(k=>k.customerID === uid.toString())[0];
   var selectedCustomer : any = dCustomerStore.delaerCustomer.filter(k=>k.customerID === uid.toString());
      setCustomerObject(customer);
      dCustomerStore.setSelectedCustomer(selectedCustomer);
    }
    setIsLoader(false); })();
  }else{
    setIsLoader(false)
  }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function FormRow({labelList,isLabel}:{labelList:any,isLabel: boolean} ) {
    
    const listCount = labelList.length === 3 ? 4: 3;
    
    return (
        <React.Fragment>
        {labelList.map((title:any) => {
            return (
          <>
        <Mui.Grid item xs={listCount} style={{backgroundColor: isLabel === true ? "" : "rgb(245,244,250)",borderRadius:"5px"}}>{
          <Mui.Tooltip title={title} >
   <Mui.Typography style={{textOverflow:"ellipsis",overflow:"hidden"}}>{title ?? "-"}</Mui.Typography></Mui.Tooltip> }
   
        </Mui.Grid>
         </>
            )})}
      </React.Fragment>
    );
  }

  function DeviceCard({license,isLabel,customerID}:{license?:Models.License,isLabel: boolean,customerID:any} ) {
    return (
    <>
    { license !== undefined && license?.deviceList.length > 0 ? <Mui.Grid container spacing={4} style={{marginBottom:"14px",marginTop:"12px"}}>
        <Mui.Grid item xs>
          <Mui.CardHeader
            style={{ padding: 0 }}
            title={
              <Mui.Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
              >
                <ReactI18next.Trans i18nKey="List of devices">
                  List of devices
                </ReactI18next.Trans>
              </Mui.Typography>
            }
          />
        </Mui.Grid>
      </Mui.Grid>:<></>}

      <Mui.Grid container spacing={3}>
        {license?.deviceList?.map((device: Models.Devices) =>{
                  

          return (
          <Mui.Grid item xs={12} md={6} lg={3} key={uuidv4()}>
            <Mui.Card
              style={{
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Mui.CardActionArea
                onClick={() =>
                  navigate(
                    `/easy-touch-devices?macId=${device?.macID}${ `&customer=${customerID}&isFromManageOrder=${"1"}`
                    }`
                  )
                }
              >
                <Mui.CardContent>
                  <Mui.Typography variant="body2" color="textSecondary">
                    <ReactI18next.Trans i18nKey="MAC ID (PC, tablet):">
                      MAC ID (PC, tablet):
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography
                    style={{ textTransform: "capitalize" }}
                    variant="body2"
                    color="textPrimary"
                  >
                    {device?.macID}
                  </Mui.Typography>
                  <br />
                  <Mui.Typography variant="body2" color="textSecondary">
                    <ReactI18next.Trans i18nKey="Device name">
                      Device name
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography
                    style={{ textTransform: "capitalize" }}
                    variant="body2"
                    color="textPrimary"
                  >
                    {device?.deviceName}
                  </Mui.Typography>
                  <br />
                  <Mui.Typography variant="body2" color="textSecondary">
                    <ReactI18next.Trans i18nKey="Customer device id">
                      Customer device id
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography variant="body2" color="textPrimary">
                    {device?.customerDeviceID}
                  </Mui.Typography>
                </Mui.CardContent>
              </Mui.CardActionArea>
            </Mui.Card>
          </Mui.Grid>
        )})}
      </Mui.Grid>
    </>
  );
  }
  return (
    isLoader ? <Mui.Box
    padding={2}
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={"100%"}
    minHeight={"100%"}
    maxHeight={"100%"}
    height={600}
    flexDirection="column"
  >
    <Mui.CircularProgress />
  </Mui.Box> : 
    <>
       <Mui.Grid container spacing={4}  style={{paddingLeft:"16px"}}>
       <Mui.Typography
                style={{ fontWeight:"bold",marginLeft:"3px",marginBottom:"11px",fontSize:"16px"}}
              >
                <ReactI18next.Trans i18nKey="Assigned to">
                  Selected license
                </ReactI18next.Trans>
        </Mui.Typography>
      {  isAssignAll && <Pages.Order.ManageOrder  licenseList={licenseList} isManageOrderDetail={true} />}
        <Mui.Grid  container item xs={12} spacing={4} >
        {  !isAssignAll &&<><FormRow labelList={["Serial number","Model","Name"]} isLabel = {true}/>
        <Mui.Grid  container item xs={12} spacing={4} style={{background:"rgb(245,244,250)",padding:"0px",marginTop:"5px",marginLeft:"2px",borderRadius:"10px",marginBottom:"5px"}}>
          <FormRow labelList={[license?.licenseCode,license?.setCode,license?.setName]} isLabel = {false}/>
          </Mui.Grid></> }
          <Mui.Typography
             style= {{  fontWeight: "bold",marginLeft:"3px",marginTop:"22px",marginBottom:"11px",fontSize:"16px"}}
              >
                <ReactI18next.Trans i18nKey="Assigned to">
                {isCustomerExist ?  "Assigned to" : "Select the customer to assign the license"}
                </ReactI18next.Trans>
              </Mui.Typography>
         { !isCustomerExist ? <Pages.Order.CustomerMain  unAssign={unAssign} customer={customerObject} isAssign = {isAssign} license = {license} orderCode={orderCode} licenseList = {licenseList} isAssignAll = {isAssignAll} isCustomerExist={isCustomerExist}/> : 
         <>
      <Mui.Grid container xs={12}>    

      </Mui.Grid>
         <FormRow labelList={["KTO Number","Company name","Contact person","Email ID"]} isLabel = {true}/>
         <Mui.Grid  container item xs={12} spacing={4} style={{background:"rgb(245,244,250)",padding:"0px",marginTop:"5px",marginLeft:"2px",borderRadius:"10px",marginBottom:"5px"}}>
          <FormRow labelList={[customerObject?.customerDealerID,customerObject?.companyName,customerObject?.customerName,customerObject?.emailID]} isLabel = {false}/>
         </Mui.Grid>
          <DeviceCard license={license} isLabel = {false} customerID={customerObject?.customerID}/></> }
           </Mui.Grid>
        </Mui.Grid>

    </>
  );
});
