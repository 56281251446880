/** @format */

import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
  })
);

export declare namespace Edit {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  > & {
    article?: Models.Set.Article;
  };
}

// eslint-disable-next-line no-redeclare
export const Edit = Mobx.observer(function ({
  open,
  handleClose,
  article,
}: Edit.Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);
  const [initialValues, setInitialValues] =
    React.useState<Article.Form.Values>();

  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const articleStore = React.useContext(App.Contexts.articleStore);
  async function handleEdit(
    values: Article.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Article.Form.Values>
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      if (!article) {
        throw new Error(t("article is undefined"));
      }

      const functions = (values.setID as unknown as Models.Set.Function[]).map(
        (set) => set.setFunctionID
      );

      const dependentSets = (
        values.dependentSets as unknown as Models.Set.Article[]
      ).map((article) => article.setID);

      const industrySegment = (values.industrySegmentsId as unknown) as Models.IndustrySegment.Main;
      
      const iconClass = (
        values.iconClass as unknown as Article.Form.Options.Icon
      ).iconName;

      const updatedArticle = {
        setID: article.setID,
        dependentSets,
        functions,
        hundCode: values.hundCode,
        iconClass,
        industrySegment: industrySegment.nameEN,
        industrySegmentsId: industrySegment.industrySegmentsId,
        isItFree: values.isFree,
        licensePerSet: values?.isLimited === true ? 1 : values.licensesPerArticle,
        moreInfo: values.linkForMoreInfo,
        setCode: values.code,
        setCost: values.netPricePerItem,
        setDescriptions: values.description,
        setName: values.name,
        licenseValidityPeriod: values.licenseValidityPeriod,
        validityPeriod: values.isFree ? values.validityPeriodInDays : 0,
        isLimited: !values?.isLimited 
      } as Models.Set.Article;
      await articleService.create({ body: updatedArticle, uid });
      enqueueSnackbar(t("Article successfully updated!"), {
        variant: "success",
        preventDuplicate: false,
      });
      articleStore.read();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }

  React.useEffect(() => {
    if (!article) {
      setInitialValues(undefined);
      return;
    }

    const initialValues: Article.Form.Values = {
      code: article?.setCode,
      description: article?.setDescriptions,
      hundCode: article?.hundCode,
      iconClass: article?.iconClass,
      industrySegmentsId: (article?.industrySegmentsId as unknown) as string,
      licensesPerArticle: article?.licensePerSet,
      linkForMoreInfo: article?.moreInfo,
      name: article?.setName,
      netPricePerItem: article?.setCost,
      setID: article?.functions,
      validityPeriodInDays: article?.validityPeriod,
      isFree: article?.isItFree,
      dependentSets: article?.dependentSets,
      licenseValidityPeriod: article.licenseValidityPeriod,
      isLimited: !article?.isLimited
    };

    setInitialValues(initialValues);
  }, [article]);

  return (
    <>
      <Components.LightBox
        open={open}
        handleClose={handleClose}
        variant={"RESPECT_ONLY_CONTENT_AND_IGNORE_COMPONENT_STYLES"}
        content={
          <>
            {initialValues && (
              <Formik.Formik
                enableReinitialize
                initialValues={initialValues || ({} as Article.Form.Values)}
                validationSchema={Article.Shared({ t }).Form.validationSchema}
                onSubmit={handleEdit}
              >
                {({ isSubmitting, submitForm }) => {
               
                  return (
                    <>
                      <Mui.Box padding={3}>
                        {article && <Article.Form editingArticle={article} />}
                      </Mui.Box>
                      <div
                        className={classes.toolbar}
                        style={{ marginBottom: 12 }}
                      />
                      <Mui.Box
                        position="fixed"
                        bottom={0}
                        width={1}
                        maxWidth={theme.breakpoints.values.md}
                        style={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                      >
                        <Mui.Divider />
                        <Mui.DialogActions>
                          <>
                            <Mui.Button
                              variant="contained"
                              onClick={handleClose}
                            >
                              <ReactI18next.Trans i18nKey="CLOSE">
                                CLOSE
                              </ReactI18next.Trans>
                            </Mui.Button>
                            <Mui.Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              onClick={() => submitForm()}
                            >
                              <ReactI18next.Trans i18nKey="Update">
                                Update
                              </ReactI18next.Trans>
                              {isSubmitting && (
                                <Mui.CircularProgress
                                  style={{
                                    marginLeft: theme.spacing(1),
                                  }}
                                  color={"inherit"}
                                  size={theme.typography.button.fontSize}
                                />
                              )}
                            </Mui.Button>
                          </>
                        </Mui.DialogActions>
                      </Mui.Box>
                    </>
                  );
                }}
              </Formik.Formik>
            )}
          </>
        }
        title={
          <Mui.Typography
            style={{ fontWeight: "bold" }}
            variant="h6"
            className={classes.title}
          >
            <ReactI18next.Trans i18nKey="Edit article">
              Edit article
            </ReactI18next.Trans>
          </Mui.Typography>
        }
      />
    </>
  );
});
