import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Themes from "src/themes";

const useStyles = Mui.makeStyles((theme) => ({
  themeTogglePaperRoot: {
    position: "fixed",
    top: `calc(50vh - (-40px + ${theme.spacing(0.5)}px))`,
    right: 0,
    maxWidth: "fit-content",
    padding: theme.spacing(0.5),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: "none",
  },
}));

export declare namespace ThemeToggle {
  export interface Props {
    darkMode: boolean;
    handleThemeTaggleClick(): () => void;
  }
}

// eslint-disable-next-line no-redeclare
export function ThemeToggle(props: ThemeToggle.Props) {
  const classes = useStyles();

  const themeMode: Themes.Global.Palette.ThemeMode = props.darkMode
    ? "light"
    : "dark";
  const theme = Mui.createMuiTheme({
    ...Themes.Global.Shared({ themeMode }),
    palette: {
      ...Themes.Global.Palette({ themeMode }).palette,
      type: themeMode,
    },
  });

  return (
    <Mui.ThemeProvider theme={theme}>
      <Mui.Tooltip
        placement="left"
        title={
          props.darkMode ? (
            <ReactI18next.Trans i18nKey="tooltipsthemeToggledark">
              Toggle theme to light mode
            </ReactI18next.Trans>
          ) : (
            <ReactI18next.Trans i18nKey="tooltipsthemeTogglelight">
              Toggle theme to dark mode
            </ReactI18next.Trans>
          )
        }
      >
        <Mui.Paper classes={{ root: classes.themeTogglePaperRoot }}>
          <Mui.IconButton
            onClick={props.handleThemeTaggleClick()}
            color="primary"
            size="small"
          >
            {props.darkMode ? (
              <MuiIcons.Brightness7 />
            ) : (
              <MuiIcons.Brightness4 />
            )}
          </Mui.IconButton>
        </Mui.Paper>
      </Mui.Tooltip>
    </Mui.ThemeProvider>
  );
}
