import * as Mui from "@material-ui/core";

export declare namespace Palette {
  export type ThemeMode = "light" | "dark";

  export interface Props {
    themeMode?: Palette.ThemeMode;
  }
}

// eslint-disable-next-line no-redeclare
export function Palette(
  props?: Palette.Props
): Pick<Mui.ThemeOptions, "palette"> {
  return {
    palette: {
      ...(props?.themeMode === "light" && {
        background: {
          default: "#f5f4fa",
          paper: "#ffffff",
        },
      }),
      primary:
        props?.themeMode === "dark"
          ? Mui.colors.orange
          : {
              main: "#5780d9",
              dark: Mui.darken("#5780d9", 0.2),
              light: Mui.lighten("#5780d9", 0.2),
              contrastText: "#ffffff",
            },
      secondary:
        props?.themeMode === "dark" ? Mui.colors.pink : Mui.colors.pink,
    },
  };
}
