/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Models from "src/models";

export declare namespace CardDevice {
  export interface Props {
    device: Models.Devices & { sets: Set<Models.Set.Article> };
    handleClick?(event: any): void;
  }
}

// eslint-disable-next-line no-redeclare
export const CardDevice = Mobx.observer(function ({
  device,
  handleClick,
}: CardDevice.Props) {
  const functionStore = React.useContext(App.Contexts.functionStore);
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const CardInternals = (
    <>
      <Mui.CardHeader
        title={
          <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
            <Mui.ListItemIcon>
              <Mui.Box fontSize={theme.typography.h4.fontSize} lineHeight={0}>
                <i className="icon-etc-clients" />
              </Mui.Box>
            </Mui.ListItemIcon>

            <Mui.ListItemText
              primary={
                <Mui.Typography>{device.deviceName ?? ""}</Mui.Typography>
              }
              secondary={
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="MAC#:">
                    MAC#:&nbsp;
                  </ReactI18next.Trans>
                  <Mui.Typography component="span" variant="overline">
                    {device.macID}
                  </Mui.Typography>
                </Mui.Typography>
              }
            />
          </Mui.ListItem>
        }
        action={
          <Components.WorkingStatus
            variant="SHORT"
            status={device.status as 1 | 2}
          />
        }
      />
      <Mui.Divider style={{ margin: theme.spacing(0, 2) }} />
      <Mui.CardContent>
        <Mui.Typography variant="body2" color="textSecondary">
          <ReactI18next.Trans i18nKey="Installed articles">
          Installed articles
          </ReactI18next.Trans>
        </Mui.Typography>
        {Array.from(device.sets.values())
                  .filter((article) => article)
                  .map((article) => (
            <Mui.Chip
              key={article.setID}
              icon={<i className={article.iconClass} />}
              style={{
                margin: theme.spacing(1.5, 2, 0, 0),
              }}
              label={article.setName}
            />
          ))}
      </Mui.CardContent>
    </>
  );

  return (
    <>
      <Mui.Card style={{ height: "100%",backgroundColor: theme.palette.background.default }}>
        {handleClick ? (
          <Mui.CardActionArea
            component="div"
            style={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "unset",
              alignItems: "unset",
              verticalAlign: "unset",
            }}
            onClick={handleClick}
          >
            {CardInternals}
          </Mui.CardActionArea>
        ) : (
          CardInternals
        )}
      </Mui.Card>
    </>
  );
});
