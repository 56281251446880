import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";

const CustomTextField = Mobx.observer(function (
  props: FormikMui.TextFieldProps
) {
  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const easyTouchDeviceStore = React.useContext(
    App.Contexts.easyTouchDeviceStore
  );
  const userStore = React.useContext(App.Contexts.userStore);
  const prevValue = React.useRef("");

  const {
    form: { setFieldTouched },
    field: { name },
  } = props;

  const onBlur = React.useCallback(
    async (event) => {
      const { value } = event.target;
      const etuid = userStore.user?.ETUID;
      setFieldTouched(name);

      if (!value && activateStore.installed.length) {
        activateStore.setInstalled({ installed: [] });
      }

      if (!value || !etuid || value === prevValue.current) {
        prevValue.current = value;
        return;
      }

      try {
        activateStore.setIsLoadingPreviouslyInstalledArticles({
          isLoadingPreviouslyInstalledArticles: true,
        });

        await easyTouchDeviceStore.read({
          userRole: "customer",
          status: -1,
          uid: etuid,
        });

        const installed: Set<number> = new Set();
        easyTouchDeviceStore.devices
          ?.filter((device) => device.macID === value)
          .map((device) => {
            device.sets.forEach((set) => installed.add(set.setID));
          });

        if (installed.size > 0 && activateStore.selected.length > 0) {
          activateStore.setSelected({
            selected: activateStore.selected.filter(
              (setID) => !installed.has(setID)
            ),
          });
        }

        activateStore.setInstalled({
          installed: Array.from(installed.values()),
        });
      } catch (error) {
        console.error(error);
      } finally {
        activateStore.setIsLoadingPreviouslyInstalledArticles({
          isLoadingPreviouslyInstalledArticles: false,
        });
        prevValue.current = value;
      }
    },
    [activateStore, easyTouchDeviceStore, name, setFieldTouched, userStore.user]
  );

  return (
    <>
      <Mui.TextField {...FormikMui.fieldToTextField(props)} onBlur={onBlur} />
      {activateStore.isLoadingPreviouslyInstalledArticles && (
        <Mui.LinearProgress />
      )}
      {activateStore.isLoadingPreviouslyInstalledArticles && (
        <Mui.FormHelperText>
          Looking for previously installed articles...
        </Mui.FormHelperText>
      )}
    </>
  );
});

export const Form = Mobx.observer(function () {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();

  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const licenseStore = React.useContext(App.Contexts.license.store);

  const { submitCount, submitForm } = Formik.useFormikContext();

  return (
    <>
      <Formik.Form>
        <Formik.Field
          name="machineId"
          component={CustomTextField}
          fullWidth
          label={t("Machine ID")}
          margin="dense"
        />
        <Formik.Field
          name="machineName"
          component={FormikMui.TextField}
          fullWidth
          label={t("Machine name")}
          margin="dense"
        />
        <Formik.Field
          name="customerName"
          component={FormikMui.TextField}
          fullWidth
          label={t("Customer name")}
          margin="dense"
        />
        <Formik.Field
          name="remark"
          component={FormikMui.TextField}
          fullWidth
          label={t("Remarks")}
          margin="dense"
        />

        <Mui.Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: theme.spacing(2) }}
        >
          <ReactI18next.Trans key={"Selected license"}>
            Selected license
          </ReactI18next.Trans>
        </Mui.Typography>

        <Mobx.Observer>
          {() => (
            <>
              {submitCount > 0 && activateStore.selected.length === 0 && (
                <Mui.FormHelperText error>
                  <ReactI18next.Trans i18nKey="Please select an license before proceeding">
                    Please select an license before proceeding
                  </ReactI18next.Trans>
                </Mui.FormHelperText>
              )}

              {submitCount > 0 &&
                activateStore.selected.length > 0 &&
                !activateStore.isDependentArticlesValid && (
                  <Mui.FormHelperText error>
                    <ReactI18next.Trans i18nKey="Please select dependent license">
                      Please select dependent license
                      {` (${Array.from(
                        activateStore.dependentArticles.values()
                      ).join(", ")})`}
                    </ReactI18next.Trans>
                  </Mui.FormHelperText>
                )}

              <div
                style={{
                  overflow: "auto",
                  height: "calc(100vh - 288px - 350px)",
                }}
              >
                <Mui.Grid container>
                  {licenseStore.getArticlesFromLicenses
                    ?.filter((article) =>
                      activateStore.selected.includes(article.setID)
                    )
                    .map((article) => (
                      <Mui.Tooltip title={article.setName} key={article.setID}>
                        <Mui.Avatar
                          style={{
                            marginRight: theme.spacing(2.5),
                            marginTop: theme.spacing(1),
                          }}
                        >
                          <Mui.Box color="text.primary">
                            <i className={article.iconClass} />
                          </Mui.Box>
                        </Mui.Avatar>
                      </Mui.Tooltip>
                    ))}
                </Mui.Grid>
              </div>
            </>
          )}
        </Mobx.Observer>

        <Mui.Box height={theme.spacing(5)} width={1} />

        <Mui.Box
          position="absolute"
          width={"calc(100% - 40px)"}
          bottom={theme.spacing(2.5)}
          right={theme.spacing(2.5)}
        >
          <Mui.Button
            onClick={submitForm}
            color="primary"
            variant="contained"
            fullWidth
          >
            <ReactI18next.Trans i18nKey="Generate license and activate">
              Generate license and activate
            </ReactI18next.Trans>
          </Mui.Button>
        </Mui.Box>
      </Formik.Form>
    </>
  );
});
