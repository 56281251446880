import * as React from "react";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as ReactI18next from "react-i18next";
import * as Mui from "@material-ui/core";

export declare namespace Form {
  export interface Values {
    name_en: string;
    name_de: string;
    name_fr: string;
    name_it: string;
    name_es: string;
  }
}

// eslint-disable-next-line no-redeclare
export function Form() {
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Formik.Form>
        <Mui.Grid container spacing={4}>
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_en"
              component={FormikMui.TextField}
              label={t("Group name EN")}
              fullWidth
              autoFocus
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_de"
              component={FormikMui.TextField}
              label={t("Group name DE")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_fr"
              component={FormikMui.TextField}
              label={t("Group name FR")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_it"
              component={FormikMui.TextField}
              label={t("Group name IT")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_es"
              component={FormikMui.TextField}
              label={t("Group name ES")}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
