import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";

const useStyles = Mui.makeStyles((theme) => ({
  outlined: {
    color: (props: { error: boolean }) =>
      props.error ? theme.palette.error.main : "inherit",
    borderColor: (props: { error: boolean }) =>
      props.error ? theme.palette.error.main : "inherit",
  },
}));

export function UploadButton({
  field,
  form: { isSubmitting, touched, errors, setFieldValue, setTouched },
  label,
  disabled = false,
  InputProps: inputProps,
  FormControlProps: formControlProps,
}: FormikMui.SimpleFileUploadProps) {
  const theme = Mui.useTheme();
  const [fileName, setFileName] = React.useState<string>("");
  const error =
    Formik.getIn(touched, field.name) && Formik.getIn(errors, field.name);
  const classes = useStyles({ error });

  return (
    <Mui.FormControl {...formControlProps}>
      <Mui.Button
        classes={{ outlined: classes.outlined }}
        onBlur={() => setTouched({ ...touched, [field.name]: true })}
        component="label"
        {...{ disabled: disabled || isSubmitting }}
        {...((inputProps as unknown) as any)}
      >
        {label}
        {fileName && (
          <>
            :&nbsp;
            <Mui.Box style={{ textTransform: "none" }}>{fileName}</Mui.Box>
          </>
        )}
        <input
          type="file"
          onChange={(event: React.ChangeEvent<any>) => {
            if (inputProps?.onChange) {
              inputProps.onChange(event);
            } else {
              const file: File = event.currentTarget.files[0];
              setFieldValue(field.name, file);
              setFileName(file.name);
              // console.log({ file });
            }
          }}
          style={{ display: "none" }}
        />
      </Mui.Button>
      {error && (
        <Mui.FormHelperText style={{ marginLeft: theme.spacing(2) }} error>
          {error}
        </Mui.FormHelperText>
      )}
    </Mui.FormControl>
  );
}
