import * as React from "react";
import * as Formik from "formik";
import * as Components from "src/app/components";
import * as ReactI18next from "react-i18next";

export declare namespace Form {
  export interface Values {
    cssFile: File;
    eotFile: File;
    svgFile: File;
    ttfFile: File;
    woffFile: File;
  }
}
// eslint-disable-next-line no-redeclare
export function Form() {
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Formik.Form>
        <Formik.Field
          type="file"
          name="cssFile"
          component={Components.UploadButton}
          label={t("CSS file")}
          InputProps={{
            variant: "outlined",
            fullWidth: true,
          }}
          FormControlProps={{
            fullWidth: true,
            margin: "normal",
          }}
        />
        <br />

        <Formik.Field
          type="file"
          name="eotFile"
          component={Components.UploadButton}
          label={t("EOT file")}
          InputProps={{
            variant: "outlined",
            fullWidth: true,
          }}
          FormControlProps={{
            fullWidth: true,
            margin: "normal",
          }}
        />
        <br />

        <Formik.Field
          type="file"
          name="svgFile"
          component={Components.UploadButton}
          label={t("SVG file")}
          InputProps={{
            variant: "outlined",
            fullWidth: true,
          }}
          FormControlProps={{
            fullWidth: true,
            margin: "normal",
          }}
        />
        <br />

        <Formik.Field
          type="file"
          name="ttfFile"
          component={Components.UploadButton}
          label={t("TTF file")}
          InputProps={{
            variant: "outlined",
            fullWidth: true,
          }}
          FormControlProps={{
            fullWidth: true,
            margin: "normal",
          }}
        />
        <br />

        <Formik.Field
          type="file"
          name="woffFile"
          component={Components.UploadButton}
          label={t("WOFF file")}
          InputProps={{
            variant: "outlined",
            fullWidth: true,
          }}
          FormControlProps={{
            fullWidth: true,
            margin: "normal",
          }}
        />
      </Formik.Form>
    </>
  );
}
