import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    icons: Models.Icon.Name[];
    uploadDialogOpen: boolean;
    iconService: Services.Icon;
  }

  export type Options = {
    iconService: Services.Icon;
  };

  export namespace SetUploadDialogOpen {
    export interface Props extends Pick<Store.IStore, "uploadDialogOpen"> {}
  }

  export namespace Read {
    export interface Props extends Services.Icon.GetAllData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public icons: Models.Icon.Name[] = [];
  @Mobx.observable public uploadDialogOpen: boolean = false;

  public iconService: Services.Icon;

  constructor({ iconService }: Store.Options) {
    this.iconService = iconService;
  }

  @Mobx.action setUploadDialogOpen({
    uploadDialogOpen,
  }: Store.SetUploadDialogOpen.Props) {
    this.uploadDialogOpen = uploadDialogOpen;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.iconService.getAllData(props);
      this.icons = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.icons.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
