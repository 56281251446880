/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as AdminManage from "src/app/pages/dealercustomer/manageadmin";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

interface Data extends Models.AdminManage.Main {}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export declare namespace Cards {
  export interface Props {
    customers: Models.AdminManage.Main[];
  }
}

// eslint-disable-next-line no-redeclare
export function Cards({ customers }: Cards.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();

  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  const [page, setPage] = React.useState(0);
  const [select, setSelect] = React.useState(false);
  const [rowsPerPage] = React.useState(8);

  React.useEffect(() => {
    setPage(0);
  }, [customers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState<boolean>(
    false
  );
  const functionStore = React.useContext(App.Contexts.functionStore);
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  const navigate = Router.useNavigate();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const [selectedValue, setSelectedValue] = React.useState<any>("");
  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: any;
  }) => {

    const selectedIndex = dCustomerStore.selected.indexOf(dealerProfile);
    let newSelected: Models.CustomerLicense.Main[] = [];
    dCustomerStore.setSelected({ selected: newSelected });
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(dCustomerStore.selected, dealerProfile);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(1));
    } else if (selectedIndex === dCustomerStore.selected.length - 1) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        dCustomerStore.selected.slice(0, selectedIndex),
        dCustomerStore.selected.slice(selectedIndex + 1)
      );
    }
    dCustomerStore.setSelected({ selected: newSelected });

  };
  return (
    <>
      <AdminManage.Details
        open={detailsDrawerOpen}
        handleClose={toggleDetailsDrawer}
        functionData={functionStore.functions}
        license={currentLicense}
      />
      <Mui.Grid
        container
        spacing={3}
        style={{ marginBottom: theme.spacing(0.5) }}
      >
        {stableSort(customers as any, getComparator("asc", "customerName"))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((Customer, index) => (
            <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={4}>
              <AdminManage.Card
                selected={selectedValue}
                handleClick={({ CustomerID }) => {
                  setOrderId(CustomerID);
                  setSelectedValue(CustomerID);
                  handleSelectDealerUser({
                    dealerProfile: (Customer as unknown) as Models.CustomerLicense.Main[],
                  });
                }}
                customer={(Customer as unknown) as Models.AdminManage.Main}
              />
            </Mui.Grid>
          ))}
      </Mui.Grid>
      <Mui.Button
        style={{
          float: "right",
          borderRadius: "4px",
          width: "122px",
          height: "43px",
          marginBottom: theme.spacing(3),
          padding: "8px 30px 8px 24px",
          backgroundColor: "#4d7fe0",
        }}
        color="primary"
        variant="contained"
        type="submit"
        onClick={async () => {
          localStorage.clear();
          if (selectedValue) {
            navigate(`/dealercustomer/customerinvite`);
          } else {
            enqueueSnackbar(
              t("Please select one customer for customer invite !"),
              { variant: "error" }
            );
            navigate(`/dealercustomer/`);
          }
        }}
      >
        <ReactI18next.Trans i18nKey="Assign">Assign</ReactI18next.Trans>
      </Mui.Button>
      {customers.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          page={page + 1}
          count={Math.ceil(customers.length / rowsPerPage)}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
    </>
  );
}
