import React from 'react';
import ExcelJS from 'exceljs';
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import { orderBy } from 'lodash';
import { getLabelle } from './order-status';


export declare namespace ExcelComponent {
    export interface Props {
       headerObject?: any;
       contentList?: any;
       fileName?: string;
     //  headerTextColor?: string;
      // headerFillColor?: string; 
    }
    export interface OrderStatus{
        status:any,
    }
  }

// eslint-disable-next-line no-redeclare
export function ExcelComponent  ({
headerObject,
contentList,
fileName,
// headerTextColor,
// headerFillColor 
}: ExcelComponent.Props)
  {
    
  const handleExport = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const headerRow = worksheet.addRow(['Ordered on', 'Order code', 'Created by','Status']);
    
  //  Style the header row cells
    headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: 'FFFFFF' } };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009933' } };
      });

    // Add data rows
    const data = [
        { id: 1, name: 'Alice' },
        { id: 2, name: 'Bob' },
        { id: 3, name: 'Charlie' },
        // ... more objects with different properties
      ];
      function extractValues(objects:any, properties:any) {
        
         return objects.map((record:any) => properties.map((property:any) =>{ 
            var returnValue = record[property];
            if(property === 'status'){
                returnValue = getStatusString({ status:record[property]})

            }
            if(property === 'userFirstName' )
            {
                returnValue = record['userFirstName'] +" "+record['userLastName'];
            }
            if(property === 'orderedOn' && record[property] != null )
            {
                const utcDateWithoutZ = record[property ];
                const utcDate = new Date(utcDateWithoutZ);
                const formattedDate = `${utcDate.getUTCDate()}.${utcDate.getUTCMonth() + 1}.${utcDate.getUTCFullYear()} ${utcDate.getUTCHours()}:${utcDate.getUTCMinutes()}`;
                returnValue = formattedDate;
            }
            return returnValue
        }));
        
      }
      
      const propertyNames = ['orderedOn', 'orderCode','userFirstName','status'];
      
      const extract = extractValues(contentList, propertyNames);
      
      extract.forEach((record:any) => {
        //const row = record);
        worksheet.addRow(record);
      });
    //   contentList.forEach((record:any) => {
    //     const row = Object.values(record);
    //     worksheet.addRow(row);
    //   });
    // worksheet.addRow(['John Doe', 'johndoe@example.com']);
    // worksheet.addRow(['Jane Smith', 'janesmith@example.com']);

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName ?? 'example.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
     <Mui.Button onClick={handleExport}>
     <i className="icon-etc-export-excel" style={{color:"green",fontSize:"18px",marginRight:"4px"}}/>
   <Mui.Typography style={{color:"green",fontSize:"14px"}}>Export</Mui.Typography> 
                </Mui.Button>
    </div>
  );
};
export function getStatusString({
    status
  }: ExcelComponent.OrderStatus): string {
    if (status === 1) {
      return "Order processed";
    }
  
    if (status === 4) {
      // return "Import-ETLicense";
      return "Partially returned";
    }
  
    if (status === 3) {
      return "Return succeeded";
    }
  
    if (status === 5) {
      return "Return failed";
    }
      return "";
    
  }
export default ExcelComponent;
