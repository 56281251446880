/** @format */

import * as Yup from "yup";
import * as Function from "src/app/pages/function";
import * as React from "react";
import * as App from "src/app";

export declare namespace Shared {
  export interface Props {
    t(key: string): string;
  }
}

// eslint-disable-next-line no-redeclare
export function Shared({ t }: Shared.Props) {
  const functionStore = React.useContext(App.Contexts.functionStore);
  const functionIdFromEdit: any = localStorage.getItem("functionIdFromEdit");
  return {
    Form: {
      validationSchema: Yup.object({
        funcationCode: Yup.string()
          .required(t("Function code is required"))
          .test(
            "Already exists",
            t("Function code already exists"),
            (value) => {
              if (functionIdFromEdit?.length > 0) {
                if (
                  functionStore.functions
                    .filter(
                      (k) => k.setFunctionID !== parseInt(functionIdFromEdit)
                    )
                    .map((k: any) => k.funcationCode)
                    .includes(value)
                ) {
                  return false;
                } else {
                  return true;
                }
              } else if (
                functionStore.functions
                  .map((k: any) => k.funcationCode)
                  .includes(value)
              ) {
                return false;
              } else {
                return true;
              }
            }
          ),
        funcationName: Yup.string()
          .required(t("Function name is required"))
          .test(
            "Already exists",
            t("Function name already exists"),
            (value) => {
              if (functionIdFromEdit?.length > 0) {
                if (
                  functionStore.functions
                    .filter(
                      (k) => k.setFunctionID !== parseInt(functionIdFromEdit)
                    )
                    .map((k: any) => k.funcationName)
                    .includes(value)
                ) {
                  return false;
                } else {
                  return true;
                }
              } else if (
                functionStore.functions
                  .map((k: any) => k.funcationName)
                  .includes(value)
              ) {
                return false;
              } else {
                return true;
              }
            }
          ),
        iconClass: Yup.string().required(t("Icon name is required")),
      }),
    },
    Translate: {
      Form: {
        validationSchema: Yup.object<Function.TranslateForm.Values>({
          funcationName_de: Yup.string().required(
            t("Function name in DE is required")
          ),
          funcationName_es: Yup.string().required(
            t("Function name in ES is required")
          ),
          funcationName_fr: Yup.string().required(
            t("Function name in FR is required")
          ),
          funcationName_it: Yup.string().required(
            t("Function name in IT is required")
          ),
        }),
      },
    },
  };
}
