import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Models from "src/models";
import * as MuiIcon from "@material-ui/icons";

interface Data extends Models.UserManagement.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const getRoleColor = (colors: any) => {
  const themeSelected = Mui.useTheme().palette.type;
  if (themeSelected === "light") {
    if (colors === "admin" || colors === "superadmin") {
      return "#bcf7c2";
    } else if (colors === "customer") {
      return "#8dc6ff";
    } else {
      return "#fffbd1";
    }
  } else {
    if (colors === "admin" || colors === "superadmin") {
      return "#9FFEA9";
    } else if (colors === "customer") {
      return "#9FDBFE";
    } else {
      return "#FEF79F";
    }
  }
};

const RoleInput = Mui.withStyles((theme) =>
  Mui.createStyles({
    root: {
      borderRadius: 4,
    },
    input: {
      width: 80,
      borderRadius: 4,
      position: "relative",
      fontSize: 16,
      padding: "8px 26px 8px 10px",
      border: "1px solid transparent",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color:
        theme.palette.type === "light" ? "initial" : theme.palette.common.black,
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(Mui.InputBase);

function EnhancedTable({
  rows,
  rolesOptions: rolesOption,
  handleUpdateRoleClick: handleRoleClick,
}: {
  handleUpdateRoleClick(props: { roles: any; userId: any; roleName:string }): void;
  rows: Models.UserManagement.Main[];
  rolesOptions: Models.Role.Main[];
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("firstName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
         {rows.length > 0 && ( <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead
                leftmost
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="firstName"
                label={t("First name")}
              />

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="lastName"
                label={t("Last name")}
              />

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="userEmail"
                label={t("Email")}
              />
              <EnhancedTableHead
                rightmost
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="roleId"
                label={t("Access / Role")}
              />
            </Mui.TableRow>
          </Mui.TableHead>)}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.etuid as string);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <Mui.TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.etuid}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                    >
                      {row.firstName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.lastName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                    onClick={()=>{window.open(`mailto:${row.userEmail}`)}}
                    >
                                          <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{row.userEmail}</Mui.Link></Mui.Tooltip>

                    </Components.Mui.TableCell>

                    <Components.Mui.TableCell rightmost>
                      <Mui.FormControl>
                      <Mui.Tooltip title={row.roleName ==="dealer"?"disabled":""}>
                        <Mui.Select
                        
                          disabled={row.roleName === "dealer"}
                          value={row.roleId}
                          input={<RoleInput />}
                          onChange={(event) =>
                            // handleChangeUpdateRole(event, row.etuid as string)
                            handleRoleClick({
                              roles: event.target.value as number,
                              userId: row.etuid as number,
                              roleName: row.roleName as string,
                            })
                          }
                          style={{
                            backgroundColor: getRoleColor(row.roleName),
                          }}
                        >
                          {rolesOption.map((role) => (
                            <Mui.MenuItem key={role.roleId} value={role.roleId} disabled ={role.roleName === "dealer"}>
                              {role.roleName}
                            </Mui.MenuItem>
                          ))}
                        </Mui.Select>
                        </Mui.Tooltip>
                      </Mui.FormControl>
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}

            {rows.length === 0 && (
              <Mui.TableCell colSpan={4} style={{ border: "none", padding: 0 }}>
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No users found">No users found</ReactI18next.Trans></MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}

            {rows.length > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          page={page + 1}
          count={Math.ceil(rows.length / rowsPerPage)}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const [updateDialogOpen, setUpadateRoleDialogOpen] = React.useState<
    | {
        roles: number;
        userId: number;
      }
    | undefined
  >();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const [isLoading] = React.useState<boolean>(false);
  const theme = Mui.useTheme();
  const userManagmentStore = React.useContext(App.Contexts.userManagementStore);
  React.useEffect(() => {
    userManagmentStore.readUsers();
    userManagmentStore.readRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const { t } = ReactI18next.useTranslation();

  const handleChangeUpdateRole = async () => {
    if (updateDialogOpen === undefined) {
      return;
    }

    try {
      setUpadateRoleDialogOpen(undefined);
      await userManagmentStore.updateRole({
        body: {
          ETUID: updateDialogOpen?.userId,
          RoleId: updateDialogOpen?.roles,
        },
      });
      userManagmentStore.readUsers();
      enqueueSnackbar(t("User role updated successfully!"), {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Components.ResponsiveFullscreenDialogWithScroll
        open={updateDialogOpen !== undefined ? true : false}
        handleClose={() => {
          setUpadateRoleDialogOpen(undefined);
        }}
        title={t("Update role")}
        contentText={t("Are you sure you want to update role?")}
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => {
                setUpadateRoleDialogOpen(undefined);
              }}
            >
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            {
              <Mui.Button
                variant="contained"
                onClick={() => handleChangeUpdateRole()}
                disabled={isLoading}
                {...(!isLoading && {
                  style: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                })}
              >
                <ReactI18next.Trans i18nKey="Update role">
                  Update role
                </ReactI18next.Trans>
                {isLoading && (
                  <Mui.CircularProgress
                    style={{
                      marginLeft: theme.spacing(1),
                    }}
                    color={"inherit"}
                    size={theme.typography.button.fontSize}
                  />
                )}
              </Mui.Button>
            }
          </>
        }
      />
      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.FormControl style={{ width: 300 }}>
         {userManagmentStore.userManagement.length > 0 &&(<Mui.TextField label={t("Search")} onChange={handleSearch} />)}
        </Mui.FormControl>
      </Mui.Grid>
      {userManagmentStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          <EnhancedTable
            rows={userManagmentStore?.userManagement?.filter?.((userData) => {
              if (!search) {
                return true;
              }
              return (
                userData?.firstName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                userData?.lastName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                userData?.userEmail
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                userData?.roleName?.toLowerCase().includes(search.toLowerCase())
              );
            })}
            rolesOptions={userManagmentStore.userRoles}
            handleUpdateRoleClick={({ roles, userId, roleName }) => {
              if(roleName === "dealer"){
                enqueueSnackbar(t("The role of the dealer cannot be updated to admin/customer"), {
                  variant: "warning",
                  preventDuplicate: false,
                });
                return;
              }else{
                setUpadateRoleDialogOpen({ roles, userId });
              }
              
            }}
          />
        </>
      )}
    </>
  );
});
