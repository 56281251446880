/** @format */

import * as AxiosTypes from "axios";
import jwtDecode from "jwt-decode";
import * as Mobx from "mobx";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace User {
  export interface IUser {
    user?: User.User;
    userService: Services.User;
  }

  export type Options = Pick<User.IUser, "userService">;
  export type User = Models.User.Main;

  export namespace Login {
    export interface Props {
      axios: AxiosTypes.AxiosInstance;
      credential: Models.User.Credential;
      getCommonCookieOptions(): void;
      setCookie(...args: any): void;
      setSubmitting(submitting: boolean): void;
    }
    export type Return = void;
  }
  export namespace LoginUsingSSO {
    export interface Props {
      axios: AxiosTypes.AxiosInstance;
      token: Models.User.TokenFromSSO;
      getCommonCookieOptions(): void;
      setCookie(...args: any): void;
    }
    export type Return = void;
  }
  export namespace Logout {
    export interface Props {
      axios: AxiosTypes.AxiosInstance;
      setCookie(...args: any[]): void;
      removeCookie(...args: any[]): void;
    }
    export type Return = void;
  }

  export namespace SetUserFromToken {
    export interface Props {
      token: string;
    }
    export type Return = string;
  }
}

// eslint-disable-next-line no-redeclare
export class User implements User.IUser {
  @Mobx.observable public user?: User.User;
  @Mobx.observable public previousUrl: string = "/dealercustomer";
  public userService: Services.User;

  constructor({ userService }: User.Options) {
    this.userService = userService;
  }

  public setUserFromToken({
    token,
  }: User.SetUserFromToken.Props): User.SetUserFromToken.Return {
    const decodedResult: any = jwtDecode(token);
    const user = JSON.parse(decodedResult.ETU) as Models.User.Main;
    // TODO: Remove this hardcoded user id
    this.user = { ...user, uid: user.uid || 0 };
    return token;
  }

  public setPreviousUrl({ urls }: any): String {
    const data = urls;
    this.previousUrl = data;
    return this.previousUrl;
  }
  public getpreviousUrlRoute(): string {
    const url = this.previousUrl;
    return url;
  }
  @Mobx.action public logout({
    axios,
    setCookie,
    removeCookie,
  }: User.Logout.Props): User.Logout.Return {
    try {
      setCookie("etutoken", "", {
        expires: new Date(),
        path: "/",
      });
      removeCookie("etutoken");
      localStorage.removeItem("utoken");
      axios.defaults.headers.common["Authorization"] = undefined;
      this.user = undefined;
    } catch (error) {
      console.error(error);
    } finally {
      window.location.reload();
    }
  }

  @Mobx.action public async login({
    axios,
    credential,
    getCommonCookieOptions,
    setCookie,
    setSubmitting,
  }: User.Login.Props): Promise<User.Login.Return> {
    const result = await this.userService.authenticate({ body: credential });

    const successfulLogin =
      result.status === Constants.environment.apiStatus.Success &&
      result.result;
    if (successfulLogin) {
      const token = result.result as unknown as string;
      const decodedResult: any = jwtDecode(token);
      const userToken = decodedResult.userToken;
      const futureTime = new Date();
      futureTime.setHours(futureTime.getHours() + 7);
      setCookie("usertoken", userToken, {
        expires: futureTime,
        path: "/",
        domain: ".kern-sohn.com",
      });
      setCookie("etutoken", token, getCommonCookieOptions());
      localStorage.setItem("utoken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setSubmitting(false);
      this.setUserFromToken({ token });
      return;
    }

    const error = new Error();
    error.name = "INVALID_CREDENTIAL";
    error.message = "Username or password is invalid!";
    throw error;
  }

  @Mobx.action public async loginUsingSSO({
    axios,
    token,
    getCommonCookieOptions,
    setCookie,
  }: User.LoginUsingSSO.Props): Promise<User.LoginUsingSSO.Return> {
    const result = await this.userService.authenticateFromSSO({ body: token });

    const successfulLoginUsingSSO =
      result.status === Constants.environment.apiStatus.Success &&
      result.result;
    if (successfulLoginUsingSSO) {
      const token = result.result as unknown as string;
      setCookie("etutoken", token, getCommonCookieOptions());
      localStorage.setItem("utoken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      this.setUserFromToken({ token });
      return;
    }

    console.log("error");
  }
}
