import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD";

  export interface IStore {
    isLoading: boolean;
    customers: Models.Customer.Main[];
    customerService: Services.Customer;
  }

  export type Options = Pick<IStore, "customerService">;

  export namespace Read {
    export interface Props extends Services.Customer.GetAllData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public customers: Models.Customer.Main[] = [];
  @Mobx.observable public isLoading: boolean = false;

  public customerService: Services.Customer;

  constructor({ customerService }: Store.Options) {
    this.customerService = customerService;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.customerService.getAllData(props);
      this.customers = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.customers.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
