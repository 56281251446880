import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Function from "src/app/pages/function";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
  }),
);

export declare namespace Create {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  >;
}

// eslint-disable-next-line no-redeclare
export const Create = Mobx.observer(function (props: Create.Props) {
  const functionStore = React.useContext(App.Contexts.functionStore);
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);
  const functionService = new Services.Function({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  async function handleCreate(
    values: Function.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Function.Form.Values>
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error("uid is undefined");
      }
      const iconClass = ((values.iconClass as unknown) as Function.Form.Options.Icon)
        .iconName;
      const func: Models.Set.Function = {
        iconClass,
        funcationCode: values.funcationCode,
        funcationName: values.funcationName,
        status: 1,
      } as Models.Set.Function;
      const res = await functionService.create({ body: func, uid });
      const result: any = res?.result;
      
      if(result == 1){
      enqueueSnackbar(t("Function successfully created!"), {
        variant: "success",
        preventDuplicate: false,
      });
    }
    if(result == 3){
      enqueueSnackbar(t("Function code already exist!"), {
        variant: "warning",
        preventDuplicate: false,
      });
    }
      functionStore.read();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      props.handleClose();
    }
  }

  return (
    <>
      <Formik.Formik
        initialValues={
          {
            funcationName: "",
            funcationCode: "",
            iconClass: "",
          } as Function.Form.Values
        }
        validationSchema={Function.Shared({ t }).Form.validationSchema}
        onSubmit={handleCreate}
      >
        <Form {...props} />
      </Formik.Formik>
    </>
  );
});

function Form({ open, handleClose }: Create.Props) {
  const classes = useStyles();
  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const { isSubmitting, submitForm, resetForm } = Formik.useFormikContext();
  React.useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Components.LightBox
      open={open}
      handleClose={handleClose}
      content={<Function.Form />}
      title={
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          {t("Create new function")}
        </Mui.Typography>
      }
      actions={
        <>
          <Mui.Button variant="contained" onClick={handleClose}>
            <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => {
              submitForm();
            }}
          >
            <ReactI18next.Trans i18nKey="Save">Save</ReactI18next.Trans>
            {isSubmitting && (
              <Mui.CircularProgress
                style={{
                  marginLeft: theme.spacing(1),
                }}
                color={"inherit"}
                size={theme.typography.button.fontSize}
              />
            )}
          </Mui.Button>
        </>
      }
    />
  );
}
