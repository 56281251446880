/** @format */

import * as Mobx from "mobx";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export namespace License {
    export type Created = Pick<Services.License.Create.Result, "licenseKey"> & {
      sets: Models.Set.Article[];
    };
  }

  export interface IStore {
    isLoadingManualLicenses: boolean;
    selectedArticles: { [Key: number]: boolean; [Key: string]: boolean };
    created?: Store.License.Created;
    manualLicenses: Models.ManualLicense[];
    isBasicSetSelected: boolean;
    articles: Map<number, Models.Set.Article>;
    tenantSelectionDialogOpen: boolean;
    selectedTenant?: Models.Tenant.Main;
    cloudArticle?: Models.Set.Article;
  }

  export interface Options {
    licenseService: Services.License;
  }

  export namespace SetArticles {
    export interface Props {
      articles: Models.Set.Article[];
    }
  }

  export namespace SetSelectedArticle {
    export interface Props {
      article: Models.Set.Article;
    }
  }

  export namespace SetIsBasicSetSelected {
    export interface Props extends Store.SetSelectedArticle.Props {
      isArticleSelected: boolean;
    }
  }

  export namespace SetSelectedArticles {
    export interface Props extends Pick<Store.IStore, "selectedArticles"> {}
  }

  export namespace SetCreated {
    export interface Props extends Required<Pick<Store.IStore, "created">> {}
  }

  export namespace FetchWithMachineId {
    export type Props = Services.License.GetManualLicenseByMachineId.Props & {
      availableArticles: Models.Set.Article[];
    };
  }

  export namespace SetTenantSelectionDialogOpen {
    export interface Props
      extends Pick<Store.IStore, "tenantSelectionDialogOpen"> {}
  }

  export namespace SetSelectedTenant {
    export interface Props extends Pick<Store.IStore, "selectedTenant"> {}
  }

  export namespace SetCloudArticle {
    export interface Props extends Pick<Store.IStore, "cloudArticle"> {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public isLoadingManualLicenses: boolean = false;
  @Mobx.observable public selectedArticles: {
    [Key: number]: boolean;
    [Key: string]: boolean;
  } = {};
  @Mobx.observable public created?: Store.License.Created;
  @Mobx.observable public manualLicenses: Models.ManualLicense[] = [];
  @Mobx.observable public isBasicSetSelected: boolean = false;
  @Mobx.observable public articles: Map<number, Models.Set.Article> = new Map();
  @Mobx.observable public tenantSelectionDialogOpen: boolean = false;
  @Mobx.observable public selectedTenant?: Models.Tenant.Main;
  @Mobx.observable public industrySegmentId?: unknown;
  @Mobx.observable public cloudArticle?: Models.Set.Article;
  @Mobx.observable public manualLicensesByMachineID: Models.ManualLicense[] =
    [];

  private licenseService: Services.License;

  constructor({ licenseService }: Store.Options) {
    this.licenseService = licenseService;
  }

  @Mobx.action public reset() {
    this.selectedArticles = {};
    this.created = undefined;
    this.articles = new Map();
    this.selectedTenant = undefined;
    this.industrySegmentId = 0;
    this.cloudArticle = undefined;
  }

  @Mobx.action public setSelectedTenant({
    selectedTenant,
  }: Store.SetSelectedTenant.Props) {
    this.selectedTenant = selectedTenant;
  }

  @Mobx.action public async setIndustrySegmentID(industrySegmentId: unknown) {
    this.industrySegmentId = industrySegmentId;
    console.log("industrySegmentId", industrySegmentId);
  }

  @Mobx.action public async fetchManualLicenses() {
    try {
      this.isLoadingManualLicenses = true;
      const { status, result } =
        await this.licenseService.GetAllManualLicenses();
      if (status === Constants.environment.apiStatus.Success) {
        this.manualLicenses = result;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingManualLicenses = false;
    }
  }

  @Mobx.computed public get isInitialLoadingManualLicenses(): boolean {
    if (this.manualLicenses.length !== 0) {
      return false;
    }

    if (!this.isLoadingManualLicenses) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get isDependentArticlesValid(): boolean {
    return (
      Object.keys(this.selectedArticles)
        .filter((key) => this.selectedArticles[key])
        .map((key) => this.articles.get(parseInt(key, 10)))
        .filter((article) => article) as Models.Set.Article[]
    ).reduce((acc: boolean, article: Models.Set.Article) => {
      if (!acc) {
        return acc;
      }
      return article.dependentSets.reduce((acc, set) => {
        if (!acc) {
          return acc;
        }
        if (this.selectedArticles[set]) {
          return true;
        }
        return false;
      }, true);
    }, true);
  }

  @Mobx.computed public get dependentArticles(): Set<string> {
    return (
      Object.keys(this.selectedArticles)
        .filter((key) => this.selectedArticles[key])
        .map((key) => this.articles.get(parseInt(key, 10)))
        .filter((article) => article) as Models.Set.Article[]
    ).reduce((acc: Set<string>, article: Models.Set.Article) => {
      article.dependentSets.forEach((set) => {
        if (this.selectedArticles[set]) {
          return;
        }
        acc.add(this.articles.get(set)?.hundCode ?? "");
      });
      return acc;
    }, new Set());
  }

  @Mobx.action public setArticles({ articles }: Store.SetArticles.Props) {
    this.articles = new Map();
    articles.forEach((article) => {
      this.articles.set(article.setID, article);
    });
  }

  @Mobx.action
  public setTenantSelectionDialogOpen({
    tenantSelectionDialogOpen,
  }: Store.SetTenantSelectionDialogOpen.Props) {
    this.tenantSelectionDialogOpen = tenantSelectionDialogOpen;
  }

  @Mobx.action public setCloudArticle({
    cloudArticle,
  }: Store.SetCloudArticle.Props) {
    this.cloudArticle = cloudArticle;
  }

  @Mobx.action public setSelectedArticle({
    article,
  }: Store.SetSelectedArticle.Props) {
    const { setID, hundCode, setCode } = article;
    const selected = this.selectedArticles[setID];
    const newSelected = !Boolean(selected);

    if (
      newSelected &&
      (Configs.Workaround.isCloudArticle({ code: hundCode }) ||
        Configs.Workaround.isCloudArticle({ code: setCode }))
    ) {
      this.setCloudArticle({ cloudArticle: article });
      if (!this.selectedTenant) {
        this.setTenantSelectionDialogOpen({ tenantSelectionDialogOpen: true });
      }
    }

    if (
      !newSelected &&
      (Configs.Workaround.isCloudArticle({ code: hundCode }) ||
        Configs.Workaround.isCloudArticle({ code: setCode }))
    ) {
      this.setCloudArticle({ cloudArticle: undefined });
      this.setSelectedTenant({ selectedTenant: undefined });
    }

    this.selectedArticles[setID] = newSelected;
    this.setIsBasicSetSelected({ article, isArticleSelected: newSelected });
  }

  public setIsBasicSetSelected({
    article,
    isArticleSelected,
  }: Store.SetIsBasicSetSelected.Props) {
    if (
      (article.setName.toLowerCase().includes("os") ||
        article.setName.toLowerCase().includes("basic")) &&
      isArticleSelected
    ) {
      this.isBasicSetSelected = true;
    }
    if (
      (article.setName.toLowerCase().includes("os") ||
        article.setName.toLowerCase().includes("basic")) &&
      !isArticleSelected
    ) {
      this.isBasicSetSelected = false;
    }
  }

  @Mobx.computed public get noOfSelectedArticles(): number {
    return Object.keys(this.selectedArticles).filter(
      (key) => this.selectedArticles[key]
    ).length;
  }

  @Mobx.computed public get onlySelectedArticles(): number[] {
    return Object.keys(this.selectedArticles)
      .filter((key) => this.selectedArticles[key])
      .map((key) => parseInt(key, 10));
  }

  @Mobx.action public setSelectedArticles({
    selectedArticles,
  }: Store.SetSelectedArticles.Props) {
    this.selectedArticles = selectedArticles;
  }

  @Mobx.action public setCreated({ created }: Store.SetCreated.Props) {
    this.created = created;
  }

  @Mobx.action public async fetchWithMachineId({
    machineId,
    availableArticles,
  }: Store.FetchWithMachineId.Props) {
    try {
      const availableArticlesMap = new Map<number, Models.Set.Article>();
      availableArticles.forEach((article) => {
        availableArticlesMap.set(article.setID, article);
      });

      const { status, result } =
        await this.licenseService.GetManualLicenseByMachineId({
          machineId,
        });
      const [license] = result || [];
      if (status === Constants.environment.apiStatus.Success && license) {
        this.manualLicensesByMachineID = [license];
        license.sets.forEach((setId) => {
          this.selectedArticles[setId] = true;
          const article = availableArticlesMap.get(setId);
          if (article) {
            this.setIsBasicSetSelected({ article, isArticleSelected: true });
          }
        });
        return { availableArticlesMap, license };
      }
      return { availableArticlesMap };
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.computed public get getManualLicenseByMacID(): Models.ManualLicense[] {
    if (this.getManualLicenseByMacID.length > 0) {
      return this.getManualLicenseByMacID;
    } else {
      return [];
    }
  }
}
