/** @format */

import * as Mui from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import * as MuiLab from "@material-ui/lab";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as ManageDealer from "src/app/pages/dealercustomer/managedealer";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

interface Data extends Models.ImportDealer.Main {}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export declare namespace Cards {
  export interface Props {
    dealers: Models.ImportDealer.Main[];
  }
}

// eslint-disable-next-line no-redeclare
export function Cards({ dealers }: Cards.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();

  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  const [page, setPage] = React.useState(0);
  const [select, setSelect] = React.useState(false);
  const [rowsPerPage] = React.useState(8);

  React.useEffect(() => {
    setPage(0);
  }, [dealers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState<boolean>(
    false
  );
  const functionStore = React.useContext(App.Contexts.functionStore);
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  const navigate = Router.useNavigate();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const managaeStore = React.useContext(App.Contexts.manageDealerStore);
  const [selectedValue, setSelectedValue] = React.useState<any>("");
  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: any;
  }) => {
    
  };
  return (
    <>
     
      <Mui.Grid
        container
        spacing={3}
        style={{ marginBottom: theme.spacing(0.5) }}
      >
        {stableSort(dealers as any, getComparator("asc", "customerName"))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((Dealer, index) => (
            <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={4}>
              <ManageDealer.Card
                selected={selectedValue}
                handleClick={({ DealerProfileID }) => {
                  setOrderId(DealerProfileID);
                  setSelectedValue(DealerProfileID);
                  localStorage.setItem("dealerID", JSON.stringify(Dealer));
                  handleSelectDealerUser({
                    dealerProfile: (Dealer as unknown) as Models.CustomerLicense.Main[],
                  });
                  var _dealerProfileID = btoa(DealerProfileID.toString());
                  navigate(
                    `/dealercustomer/adddealeruser?q=${_dealerProfileID}`
                  );
                }}
                dealer={(Dealer as unknown) as Models.ImportDealer.Main}
              />
            </Mui.Grid>
          ))}
        {dealers.length === 0 && (
          <Mui.Grid container>
            <Mui.Grid
              item
              xs={12}
              style={{ marginTop: "29.5px", marginLeft: "12px" }}
            >
              <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No dealer found">No dealer found</ReactI18next.Trans></MuiLab.Alert>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
     
      {/* dealers.length !== 0 */}
      {dealers.length > 7 && (
        <Mui.Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: theme.spacing(34),
          }}
        >
          <Mui.Grid item>
           
          </Mui.Grid>
          <Mui.Grid item>
            <MuiLab.Pagination
              // NOTE: Mui Lab Pagination assumes page starts at 1
              page={page + 1}
              count={Math.ceil(dealers.length / rowsPerPage)}
              // NOTE: Mui Lab Pagination assumes page starts at 1
              onChange={(event, page) => handleChangePage(event, page - 1)}
              shape="rounded"
            />
          </Mui.Grid>
        </Mui.Grid>
      )}
    </>
  );
}
