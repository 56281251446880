/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Models from "src/models";
import * as ReactI18next from "react-i18next";

export declare namespace CustomerCard {
  export interface Props {
    customer: Models.DealerCustomer.Main;
    selected: number | string;
    handleClick?(props: { CustomerID: number | string ,EmailID: any,customerData:any,etuid:any}): void;
  }
}

// eslint-disable-next-line no-redeclare
export function CustomerCard({
  customer,
  selected,
  handleClick,
}: 
CustomerCard.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const [
    adminAndCustomerRoleCheck,
    setAdminAndCustomerRoleCheck,
  ] = React.useState(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  React.useEffect(() => {
    if (userRole === "admin" || userRole === "superadmin" || userRole === "customer") {
      setAdminAndCustomerRoleCheck(false);
    } else {
      setAdminAndCustomerRoleCheck(true);
    }
  }, [userRole, adminAndCustomerRoleCheck]);
  const CardInternals = (
    <>
      <Mui.Card
        style={{
          height: "265px",
       
          padding: "10px",
          paddingBottom: "10px",
        }}
       
      >
        <Mui.CardHeader
          style={{
            minHeight: "80px",
            paddingTop: "7px",
            padding: "5px",
            margin: "2px",
          }}
          title={
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
              <Mui.ListItemIcon>
                <Mui.ListItemAvatar>
                  <Mui.Avatar
                    alt={
                      "userStore.user?.FirstName" +
                      " " +
                      "userStore.user?.LastName"
                    }
                  >
                    <MuiIcons.Person />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>
              </Mui.ListItemIcon>
            
              <Mui.ListItemText
                primary={
                  <Mui.Typography
                    style={{ fontWeight: "bold", fontSize: "20px" }}
                  >
                    {customer.companyName}
                  </Mui.Typography>
                }
                secondary={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                     
                    </div>
                  </>
                }
              />
             
            </Mui.ListItem>
          }
          action={
            <>
             
            </>
          }
        />
        <Mui.CardContent
          style={{
            minHeight: "135px",
            padding: 6,
            paddingTop: 2,
            margin: 6,
            marginTop: 2,
          }}
        >
          <Mui.Divider />
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "6px",
                marginBottom: "8px",
              }}
            >
              <Mui.Typography color="textSecondary">
              <ReactI18next.Trans i18nKey="Customer name">
              Customer name
                </ReactI18next.Trans>:
              </Mui.Typography>
              <Mui.Typography
                style={{ color: "black", fontSize: "14px", marginLeft: "6px" }}
              >
                {customer.customerName}
              </Mui.Typography>
            </div>
          </>

          {/*  */}
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "8px",
              }}
            >
              <Mui.Typography color="textSecondary">
                {" "}
                {t("Email ID")}:
              </Mui.Typography>
              <Mui.Typography
              onClick={()=>{window.open(`mailto:${customer.emailID}`)}}
                style={{
                  color: "black",
                  fontSize: "14px",
                  marginLeft: "6px",
                }}
              >
                <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{customer.emailID}</Mui.Link></Mui.Tooltip>
                
              </Mui.Typography>
            </div>
          </>

          <Mui.Divider />
          <Mui.Grid
            container
            spacing={2}
            direction={"row"}
            style={{ marginTop: "2px" }}
          >
            <Mui.Grid
              item
              xs={8}
              style={{ fontWeight: "bold", paddingTop: "2px" }}
            >
              <Mui.Grid>
                <Mui.Typography
                  style={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  <ReactI18next.Trans i18nKey="Purchased license">
                  Purchased license
                </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography style={{ marginTop: "4px" }}>
                <Components.ArticleCountIcons
                          articles={
                            (customer.sets as unknown) as Models.Order.articles[]
                          }
                        />

                  
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Divider orientation="vertical" flexItem />
            <Mui.Grid item xs={2}>
              <Mui.Typography
                style={{ fontWeight: "bold", marginBottom: "8px" }}
              >
                {t("Status")}
              </Mui.Typography>
              <Mui.Typography>
                <Components.InviteStatus
                  status={customer.etUserStatus as 1 | 2}
                />
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );

  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea
          onClick={() =>handleClick({ CustomerID: customer.customerID,EmailID: customer.emailID ,customerData:customer,etuid:customer.etuid})}
        >
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        
        { CardInternals }
      )}
    </Mui.Card>
  );
}
