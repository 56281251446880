import * as Icon from "src/app/pages/icon";
import * as Yup from "yup";

export declare namespace Shared {
  export interface Props {
    t(key: string): string;
  }
}

const FILE_SIZE = 4096 * 1024;

// eslint-disable-next-line no-redeclare
export function Shared({ t }: Shared.Props) {
  return {
    Form: {
      validationSchema: Yup.object<Icon.Form.Values>({
        cssFile: Yup.mixed<File>()
          .required(t("A CSS file is required"))
          .test(
            t("fileSize"),
            t("File too large"),
            (value) => value && value.size <= FILE_SIZE,
          )
          .test(t("fileFormat"), t("Unsupported Format"), (value) => {
            return value && ["text/css"].includes(value.type);
          }),
        eotFile: Yup.mixed<File>()
          .required(t("A EOT file is required"))
          .test(
            t("fileSize"),
            t("File too large"),
            (value) => value && value.size <= FILE_SIZE,
          )
          .test(t("fileFormat"), t("Unsupported format"), (value) => {
            return value && !value.type && value.name.includes(".eot");
          }),
        svgFile: Yup.mixed<File>()
          .required(t("A SVG file is required"))
          .test(
            t("fileSize"),
            t("File too large"),
            (value) => value && value.size <= FILE_SIZE,
          )
          .test(t("fileFormat"), t("Unsupported Format"), (value) => {
            return value && ["image/svg+xml"].includes(value.type);
          }),
        ttfFile: Yup.mixed<File>()
          .required(t("A TTF file is required"))
          .test(
            t("fileSize"),
            t("File too large"),
            (value) => value && value.size <= FILE_SIZE,
          )
          .test(t("fileFormat"), t("Unsupported format"), (value) => {
            return value && !value.type && value.name.includes(".ttf");
          }),
        woffFile: Yup.mixed<File>()
          .required(t("A WOFF file is required"))
          .test(
            t("fileSize"),
            t("File too large"),
            (value) => value && value.size <= FILE_SIZE,
          )
          .test(t("fileFormat"), t("Unsupported format"), (value) => {
            return value && ["application/font-woff"].includes(value.type);
          }),
      }),
    },
  };
}
