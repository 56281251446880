import * as Mui from "@material-ui/core";
import * as React from "react";

export declare namespace Main {
  export interface Props {
    children?: React.ReactNode;
  }
}

// eslint-disable-next-line no-redeclare
export function Main(props: Main.Props) {
  return (
    <Mui.Container maxWidth="md">
      <>{props.children}</>
    </Mui.Container>
  );
}
