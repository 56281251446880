import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";

export declare namespace Counter {
  export interface Props {
    min: number;
    max: number;
    value: number;
    handleDecrement(): void;
    handleIncrement(): void;
    style?: React.CSSProperties;
  }
}

// eslint-disable-next-line no-redeclare
export function Counter({
  min,
  max,
  value,
  handleDecrement,
  handleIncrement,
  style,
}: Counter.Props) {
  const theme = Mui.useTheme();
  return (
    <Mui.Grid container alignItems="center" style={style}>
      <Mui.IconButton
        onClick={handleDecrement}
        disabled={value <= Math.min(min, max)}
        size="small"
        style={{ marginRight: theme.spacing(0.5) }}
      >
        <MuiIcons.Remove />
      </Mui.IconButton>
      {value}
      <Mui.IconButton
        onClick={handleIncrement}
        size="small"
        style={{ marginLeft: theme.spacing(0.5) }}
      >
        <MuiIcons.Add />
      </Mui.IconButton>
    </Mui.Grid>
  );
}
