/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace ImportDealer {
  export interface IImportDealer {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = ImportDealer.IImportDealer;

  export namespace GetAllData {
    export type Return = Models.API.Response<Models.ImportDealer.Main[]>;
    export interface Props {
      searchtext?: string | any;
      roleName?: string | any;
    }
    export interface DealerProps {
      searchtext?: string | any;
    }
  }

  export namespace AddDealer {
    export type Return = Models.API.Response<any>;
    export type Body = Models.ImportDealer.RequestDealer;
    // }

    export interface Props {
      body: ImportDealer.AddDealer.Body;
    }
  }
}

// eslint-disable-next-line no-redeclare
export class ImportDealer {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: ImportDealer.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  // eslint-disable-next-line no-empty-pattern
  public async getDealerCompanyDetails({
    searchtext,
    roleName,
  }: ImportDealer.GetAllData.Props): Promise<ImportDealer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "GetDealerCompany", `${searchtext}`, `${roleName}`],
    });

    const response = await this.httpManager.get<ImportDealer.GetAllData.Return>(
      {
        url,
      }
    );
    // console.log(response);
    return response;
  }

  // eslint-disable-next-line no-empty-pattern
  public async getManageDealerCompanyDetails({
    searchtext,
    roleName,
  }: ImportDealer.GetAllData.Props): Promise<ImportDealer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "GetManageDealer", `${searchtext}`, `${roleName}`],
    });

    const response = await this.httpManager.get<ImportDealer.GetAllData.Return>(
      {
        url,
      }
    );
    // console.log(response);
    return response;
  }
  // eslint-disable-next-line no-empty-pattern
  public async getDealerUserDetails({
    searchtext,
  }: // roleName,
  ImportDealer.GetAllData.DealerProps): Promise<ImportDealer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "GetDealerUsers", `${searchtext}`],
    });

    const response = await this.httpManager.get<ImportDealer.GetAllData.Return>(
      {
        url,
      }
    );
    // console.log(response);
    return response;
  }

  public async addDealer({
    body,
  }: ImportDealer.AddDealer.Props): Promise<ImportDealer.AddDealer.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "AddDealer"],
    });

    const response = await this.httpManager.post<
      ImportDealer.AddDealer.Return,
      ImportDealer.AddDealer.Body
    >({
      body: body,
      url,
    });
    // console.log({ response });

    return response;
  }

  public async getDealerFromSSO({
    searchtext,
  }: // roleName,
  ImportDealer.GetAllData.DealerProps): Promise<ImportDealer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Dealer", "GetDealerSAGEOL", `${searchtext}`],
    });

    const response = await this.httpManager.get<ImportDealer.GetAllData.Return>(
      {
        url,
      }
    );
    // console.log(response);
    return response;
  }
}
