/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import clsx from 'clsx';
import PersonIcon from '@material-ui/icons/Person';
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import * as Services from "src/services";
import * as Constants from "src/constants";
import { AddLicense } from "src/app/routes/license";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import * as Notistack from "notistack";
import * as Router from "react-router-dom";
import * as MuiIcons from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface Data extends Models.AddLicense {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    title: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  })
);

function EnhancedTable({ rows }: { rows: Models.AddLicense[] }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("hundCode");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  const uid = userStore.user?.ETUID;
  const key = 'hundCode';

  const orderArrayUniqueByKey = [...new Map(rows.map(item =>
  [item[key], item])).values()];
const orderDetaile = rows
  
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const [language,setLanguage] = React.useState('en-IN');
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language
  React.useEffect(()=>{
    if(languageValue === "en"){
      setLanguage('en-IN')
    }else if(languageValue === "fr")
    {
      setLanguage('fr-FR')
    }
    else{
      setLanguage('de-DE')
    }
  },[languageValue])

  // 
  return (
    <div>
    
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
          <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead                 leftmost                 classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="SetName"
                  label={t("Set Name")}
                />
                <EnhancedTableHead 
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="HundCode"
                  label={t("Hund code")}
                />
                <EnhancedTableHead rightmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="Quantity"
                  label={t("Quantity")}
                />
                
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(orderArrayUniqueByKey as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) =>{
                    }
                    }
                    key={row.setName}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id="hundCode"
                      scope="row"
                    >
                      {row.setName}
                     
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      
                      component="th"
                      id="hundCode"
                      scope="row"
                    >
                      {row.hundCode}
                     
                    </Components.Mui.TableCell>
                   
                    <Components.Mui.TableCell rightmost id="quantity">
                   {row.quantity}
                    </Components.Mui.TableCell>
                    
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No orders found">No Sales data found for {localStorage.getItem("setName")}</ReactI18next.Trans></MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const [shipmentNumber, setShipmentNumber] = React.useState<string>("");
  const [customerMailID, setCustomerMailID] = React.useState<string>("");
  const [data, setData] = React.useState<any>();
  const [assignStatus, setAssignStatus] = React.useState<any>();
  const [customerData, setCustomerData] = React.useState<any>();
  const [enableDialog, setIsEnableDialog] = React.useState<boolean>(false);
  const [OrderObject, setOrderObject] = React.useState<Models.AddLicense>();
  const [orderErrorTxt, setOrderErrorTxt] = React.useState<string>("");
  const [customerError, setCustomerError] = React.useState<boolean>(false);
  const [orderError, setOrderError] = React.useState<boolean>(false);
  const [isCustomerSelectionCompleted, setIsCustomerSelectionCompleted] = React.useState<boolean>(false);
  const [isOrderSelectionCompleted, setIsOrderSelectionCompleted] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [assignStatusObject, setAssignStatusObject] = React.useState<string>('');
  const [CustomerObject, setCustomerObject] = React.useState<Models.Customer.Main>();
  const userStore = React.useContext(App.Contexts.userStore);
  const roleName = userStore.user?.RoleName;
  const licenseStore = React.useContext(App.Contexts.license.assignedLicenseDetailStore);
  const theme = Mui.useTheme();
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const customerService = new Services.Customer({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  
  const labelSize = "17px";
  const labelFontWeight = "bold";
  const { t } = ReactI18next.useTranslation();
  const useStyles = makeStyles((theme) => ({
    avatar: {
      backgroundColor: '#5780d9',
      border: `1px solid #5780d9`,
      color: theme.palette.info.main
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  }));
  const classes = useStyles();
  function FormRow({
    labelList,
    isLabel,
  }: {
    labelList: any;
    isLabel: boolean;
  }) {
    const listCount = labelList.length === 3 ? 4 : 3;
    
    return (
      <React.Fragment>
        {labelList.map((title: any) => {
          return (
            <>
              <Mui.Grid item xs={listCount} >
                {" "}
                <Mui.Typography
                  style={{
                    fontSize: isLabel ? labelSize : "13.5px",
                    fontWeight: isLabel ? labelFontWeight : "normal",
                    opacity: isLabel ? "0.8" : "",
                  }}
                >
                  {title}
                </Mui.Typography>
              </Mui.Grid>
            </>
          );
        })}
      </React.Fragment>
    );
  }
  const handleChangeShipment = (event:any)=>{
    setShipmentNumber(event.target.value);
    console.log(event.target.value);
  }
  
  const handleChangeCustomer = (event:any)=>{
    setCustomerMailID(event.target.value);
    console.log(event.target.value);
  }
  const navigate = Router.useNavigate();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { i18n } = ReactI18next.useTranslation();
  const handleAssignLicense = ()=>{
    const fetchData = async () => {
      try {
        const order: Models.Order.AddOrderUsingReference = {
          OrderCode: shipmentNumber,
          ETUID:userStore.user?.RoleName !== 'admin' ? userStore.user?.ETUID : CustomerObject!.etuid ?? 0,
          LangCode: i18n.language.toUpperCase()
        } as Models.Order.AddOrderUsingReference;
        setLoading(true)
        const {result} = await orderServices.AssignOrder({
          body: order,
        });
        if(result != null && result.length != null && result.length > 0 && Array.isArray(result))
       {
        setAssignStatus(result);
        setAssignStatusObject(result[0])
        setIsEnableDialog(true);
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Successfully assigned to customer!">
            Successfully assigned to customer!
          </ReactI18next.Trans>,
          {
            variant: "success",
          }
        );
        
       }else{
        setAssignStatusObject(result[0])
        enqueueSnackbar(
          assignStatusObject,
          {
            variant: "error",
          }
        );
       }
       setLoading(false);
      //  if(result !== null && result === '200'){
      //  enqueueSnackbar(
      //   <ReactI18next.Trans i18nKey="Successfully assigned to customer!">
      //     Successfully assigned to customer!
      //   </ReactI18next.Trans>,
      //   {
      //     variant: "success",
      //   }
      // );
      // if(userStore.user?.RoleName === 'admin'){
      //   navigate(`/licenses?userID=${CustomerObject!.etuid ?? ''}`);
      // }else{
      //   navigate(`/licenses`);
      // }
      //  }else{
      //   enqueueSnackbar(
      //     assignStatusObject,
      //     {
      //       variant: "error",
      //     }
      //   );
      //  }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 
  }
  const handleCheckCustomer = ()=>{
    
    const fetchData = async () => {
      try {
        setLoading(true)
        const {result} = await customerService.GetCustomerOrDealerByEmailID({mailId:customerMailID});
        setCustomerData(result);
       setCustomerObject(result[0])
       
       if(result != null && result.length != null && result.length > 0)
       {
        setIsCustomerSelectionCompleted(true)
        setCustomerError(false)
        console.log("customer exists")
        localStorage.setItem("userID",JSON.stringify(CustomerObject!.etuid ?? 0))
       }else{
        setCustomerError(true)
        setIsCustomerSelectionCompleted(false)
        console.log("customer not exists")
       }
       setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 
  }

  const handleCheckShipment = ()=>{
    const fetchData = async () => {
      try {
        //console.log("customer ID"+CustomerObject!.etuid)
        const order: Models.Order.AddOrderUsingReference = {
          OrderCode: shipmentNumber,
          ETUID:userStore.user?.RoleName !== 'admin' ? userStore.user?.ETUID : CustomerObject!.etuid ?? 0,
          LangCode: i18n.language.toUpperCase()
        } as Models.Order.AddOrderUsingReference;
        setLoading(true);
        const {result} = await orderServices.AddOrderUsingReference({
          body: order,
        });
     
       if(result != null && result.length != null && result.length > 0 && Array.isArray(result))
       {
        setData(result);
        setOrderObject(result[0])
        setIsOrderSelectionCompleted(true)
        setOrderError(false)
       }else{
        setOrderError(true)
        setIsOrderSelectionCompleted(false)
        setOrderErrorTxt(result)
       }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData(); 
  }
  return (
    <>
    {/* Start of Customer form */}
   {!isCustomerSelectionCompleted && roleName === 'admin' && <Mui.Paper
        square
        style={{
          borderLeft: "4px solid grey",
          borderRadius: "4px",
          margin: "4px",
          padding:"14px"
        }}
        
      >
        
        <Mui.Grid container item style={{marginBottom: "12px"}}>
        <Avatar className={classes.avatar} style={{ marginRight: "10px"}}>
        <PersonIcon
            style={{
              color: "#FFFFFF",
              width: "20px",
              height: "20px",
            }}
          />
          </Avatar>
          <Mui.Typography style={{fontSize:"16px",color:"GrayText",marginTop:"9px" }}>Enter the customer mail ID </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container item style={{marginBottom: "12px",}}>
          <Mui.TextField
          label="Customer mail ID"
          id="standard-start-adornment"
          onChange={handleChangeCustomer}
          className={clsx(classes.margin, classes.textField)}
         
        />
        <Mui.Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleCheckCustomer}
                    style={{height:"34px",width:"84px",marginTop:"20px"}}
                    disabled={customerMailID.length <= 0}
                  >
                    <ReactI18next.Trans i18nKey="Assign license">
                    Next
                    </ReactI18next.Trans>
                  </Mui.Button>
         </Mui.Grid>
        </Mui.Paper>}
  {/* End of Customer form  */}
  {/* Start of Customer detail  */}
    {isCustomerSelectionCompleted && roleName === 'admin' && <Mui.Paper
        square
        style={{
          //marginBottom: "12px",
         // marginTop:"6px",
          borderLeft: "4px solid grey",
          borderRadius: "4px",
          margin: "4px",
          padding:"14px"
        }}
        
      >
        <Mui.Grid container item style={{marginBottom: "12px",}}>
        <Avatar className={classes.avatar} style={{ marginRight: "10px",}}>
        <PersonIcon
            style={{
             
              color: "#FFFFFF",
              width: "20px",
              height: "20px",
            }}
          />
          </Avatar>
          <Mui.Typography style={{fontSize:"16px",color:"GrayText",marginTop:"9px" }}>Selected user</Mui.Typography>
          </Mui.Grid>
                          <Mui.Grid container item xs={12} >
                            
                            <FormRow
                              labelList={[
                                "Company name",
                                "First name",
                                "Last name",
                                "Email address",
                              ]}
                              isLabel={false}
                            />
                            <FormRow
                              labelList={[
                                CustomerObject?.companyName,
                                CustomerObject?.firstName,
                                CustomerObject?.lastName,
                                CustomerObject?.userEmail,
                              ]}
                              isLabel={true}
                            />
                    

                           <Mui.Box mt={6} /> 
                            
                          </Mui.Grid>
        </Mui.Paper>}
   {/* End of Customer detail  */}

    {/* Start of shipment form */}
    { roleName !== 'admin' && !isOrderSelectionCompleted && <Mui.Paper
        square
        style={{
          borderLeft: "4px solid grey",
          borderRadius: "4px",
          margin: "4px",
          padding:"14px"
        }}
        
      >
        
        <Mui.Grid container item style={{marginBottom: "12px",}}>
        <Avatar className={classes.avatar} style={{ marginRight: "10px",}}>
        <LocalShippingOutlinedIcon
            style={{
              color: "#FFFFFF",
              width: "20px",
              height: "20px",
            }}
          />
          </Avatar>
          <Mui.Typography style={{fontSize:"16px",color:"GrayText",marginTop:"9px" }}>Enter the shipment number </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container item style={{marginBottom: "12px",}}>
          <Mui.TextField
          label="Shipment number"
          id="standard-start-adornment"
          onChange={handleChangeShipment}
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: <Mui.InputAdornment position="start" style={{fontWeight:600}}>L-YYYY-</Mui.InputAdornment>,
          }}
        />
        <Mui.Button color="primary" variant="contained" style={{height:"34px",width:"84px",marginTop:"20px"}} disabled={shipmentNumber.length <= 0} onClick={handleCheckShipment}>Next</Mui.Button>
         </Mui.Grid>
        </Mui.Paper>}

   { roleName === 'admin' && isCustomerSelectionCompleted && !isOrderSelectionCompleted && <Mui.Paper
        square
        style={{
          borderLeft: "4px solid grey",
          borderRadius: "4px",
          margin: "4px",
          padding:"14px"
        }}
      >
        
        <Mui.Grid container item style={{marginBottom: "12px"}}>
        <Avatar className={classes.avatar} style={{ marginRight: "10px"}}>
        <LocalShippingOutlinedIcon
            style={{
              color: "#FFFFFF",
              width: "20px",
              height: "20px",
            }}
          />
          </Avatar>
          <Mui.Typography style={{fontSize:"16px",color:"GrayText",marginTop:"9px" }}>Enter the shipment number </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container item style={{marginBottom: "12px",}}>
          <Mui.TextField
          label="Shipment number"
          id="standard-start-adornment"
          onChange={handleChangeShipment}
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: <Mui.InputAdornment position="start" style={{fontWeight:600}}>L-YYYY-</Mui.InputAdornment>,
          }}
        />
        <Mui.Button color="primary" variant="contained" style={{height:"34px",width:"84px",marginTop:"20px"}} disabled={shipmentNumber.length <= 0} onClick={handleCheckShipment}>Next</Mui.Button>
         </Mui.Grid>
        </Mui.Paper>}
  {/* End of shipment form  */}
  {/* Start of Shipment detail  */}
    {isOrderSelectionCompleted &&  <Mui.Paper
        square
        style={{
          borderLeft: "4px solid grey",
          borderRadius: "4px",
          margin: "4px",
          padding:"14px"
        }}
        
      >
        <Mui.Grid container item style={{marginBottom: "12px",}}>
        <Avatar className={classes.avatar} style={{ marginRight: "10px",}}>
        <LocalShippingOutlinedIcon
            style={{
              color: "#FFFFFF",
              width: "20px",
              height: "20px",
            }}
          />
          </Avatar>
          <Mui.Typography style={{fontSize:"16px",color:"GrayText",marginTop:"9px" }}>The following licenses are purchased aganist the shipment number : <Mui.Typography component="span" style ={{fontWeight:"bold",color:"#505050"}}>L-YYYY-{OrderObject?.orderCode || 0}</Mui.Typography>  </Mui.Typography>
          </Mui.Grid>
                          <Mui.Grid container item xs={12} >
                            
                            <FormRow
                              labelList={[
                                "Order number",
                                "Reference Number",
                                "Order date",
                                "Created by",
                              ]}
                              isLabel={false}
                            />
                            <FormRow
                              labelList={[
                                OrderObject?.orderCode,
                                OrderObject?.sageOLReferenceBillID,
                                OrderObject?.orderedOn,
                                OrderObject?.createdBy,
                              ]}
                              isLabel={true}
                            />
                    

                           <Mui.Box mt={6} /> 
                            
                          </Mui.Grid>
        </Mui.Paper>}
        <Mui.Dialog
        open={enableDialog}
        keepMounted
        disableBackdropClick
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Mui.DialogTitle id="alert-dialog-slide-title">{""}</Mui.DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon
            style={{
              color: "green",
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: theme.palette.primary.main, }}>
          <ReactI18next.Trans i18nKey="Success">
          Success!
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-slide-description">
         The order succesfully assigned!
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
              setIsEnableDialog(false)
              if(userStore.user?.RoleName === 'admin'){
                navigate(`/licenses?userID=${CustomerObject!.etuid ?? ''}`);
              }else{
                navigate(`/licenses`);
              }
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <ReactI18next.Trans i18nKey="Ok">
            Ok
          </ReactI18next.Trans>
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
   {/* End of Shipment detail  */}
  {customerError && <Mui.Grid container>
                  <Mui.Grid item style={{marginTop:"100px"}} xs={12}>
                    <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No users found">No users found</ReactI18next.Trans></MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>}
                {orderError && <Mui.Grid container >
                  <Mui.Grid item xs={12} style={{marginTop:"100px"}} >
                    <MuiLab.Alert severity="info">{orderErrorTxt ?? ""}</MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>}
              <Mui.Box
                      position="fixed"
                      bottom={theme.spacing(2.5)}
                      right={theme.spacing(2.5)}
                      zIndex={3}
                    >
                     {isCustomerSelectionCompleted && <Mui.Button
                    style={{
                      marginRight: theme.spacing(2),
                    }}
                    color="default"
                    variant="contained"
                    type="submit"
                    onClick={()=>{
                      setIsCustomerSelectionCompleted(false);
                    }}
                  >
                    <ReactI18next.Trans i18nKey="Assign license">
                      Change customer
                    </ReactI18next.Trans>
                  </Mui.Button>}
                      {isOrderSelectionCompleted && <Mui.Button
                    style={{
                      marginRight: theme.spacing(2),
                    }}
                    color="default"
                    variant="contained"
                    type="submit"
                    onClick={()=>{
                      setIsOrderSelectionCompleted(false);
                    }}
                  >
                    <ReactI18next.Trans i18nKey="Assign license">
                      Change Order code
                    </ReactI18next.Trans>
                  </Mui.Button>}
                  {roleName === 'admin' && isCustomerSelectionCompleted && isOrderSelectionCompleted && <Mui.Button
                    style={{
                      marginRight: theme.spacing(2),
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleAssignLicense}
                  >
                    <ReactI18next.Trans i18nKey="Assign license">
                      Assign license
                    </ReactI18next.Trans>
                  </Mui.Button>}
                  {roleName !== 'admin' && isOrderSelectionCompleted && <Mui.Button
                    style={{
                      marginRight: theme.spacing(2),
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleAssignLicense}
                  >
                    <ReactI18next.Trans i18nKey="Assign license">
                      Assign license
                    </ReactI18next.Trans>
                  </Mui.Button>}
                  </Mui.Box>
      {licenseStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {isLoading && 
          <Mui.Grid container
          justify="center"
          alignItems="center"
          style={{ height: "60vh", width: "100vw" }}
        >
          <Mui.CircularProgress/></Mui.Grid>}
          { !isLoading && isOrderSelectionCompleted && data !== undefined && data.length > 0 && licenseStore.view === "TABULAR" && (
            <>
            <Mui.Typography style={{marginTop:"18px",marginBottom:"6px",fontWeight:"bold",marginLeft:"6px",fontSize:"18px"}}>Order summary</Mui.Typography>
            <EnhancedTable
              rows={data.filter((license:any) => {
                if (!search) {
                  return true;
                }
                return (
                  license?.HundCode
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                    license?.SetName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) 
                );
              })}
            />
            </>
          )}
         
        </>
      )}
    </>
  );
});
