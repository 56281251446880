import * as Mui from "@material-ui/core";
import * as Themes from "src/themes";

export declare namespace Shared {
  export interface Props extends Themes.Global.Palette.Props {}
}

// eslint-disable-next-line no-redeclare
export function Shared(sharedProps?: Shared.Props): Mui.ThemeOptions {
  const { themeMode = "light" } = sharedProps || {};

  const palette = Themes.Global.Palette({ themeMode });
  const typography = Themes.Global.Typography();

  const theme = Mui.createMuiTheme();

  const overrides = Themes.Global.Overrides({ palette, theme, themeMode });
  const props = Themes.Global.Props({ palette, theme, themeMode });

  const { shadows } = theme;
  shadows.push("5px 5px 5px #e2e2e2");

  return {
    ...typography,
    ...overrides,
    ...palette,
    ...props,
    shadows,
  };
}
