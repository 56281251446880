/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

interface Data extends Models.Set.Article {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps &
    Pick<Components.Mui.TableCell.Props, "leftmost" | "rightmost"> & {
      id: string;
      label: string;
      numeric?: boolean;
      disablePadding?: boolean;
    }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({
  rows,
  handleEditClick,
  handleTranslateClick,
  handleDeleteClick,
  handleActivateClick,
  handleView,
}: {
  rows: Models.Set.Article[];
  handleEditClick(props: { article: Models.Set.Article }): void;
  handleTranslateClick(props: { article: Models.Set.Article }): void;
  handleDeleteClick(props: { id: string | number }): void;
  handleActivateClick(props: { id: string | number }): void;
  handleView(props: { article: Models.Set.Article }): void;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("setName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = ReactI18next.useTranslation();

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.setID);
      setSelected((newSelecteds as unknown) as string[]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const theme = Mui.useTheme();

  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="setName"
                  label={t("Name")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="setCode"
                  label={t("Code")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="hundCode"
                  label={t("Model")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="status"
                  label={t("Status")}
                />

                <Components.Mui.TableCell
                  rightmost
                  color={theme.palette.divider}
                  align="right"
                >
                  <ReactI18next.Trans i18nKey="Actions">
                    Actions
                  </ReactI18next.Trans>
                </Components.Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableBody>
            <tr></tr>
          </Mui.TableBody>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.setID as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => handleClick(event, row.setID as string)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.setID}
                    // selected={isItemSelected}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.setName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.setCode}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.hundCode}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      <Components.WorkingStatus status={row.status as 1 | 2} />
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell rightmost align="right">
                      <Mui.Box
                        maxWidth="fit-content"
                        marginLeft="auto"
                        display="grid"
                        gridAutoFlow="column"
                        gridColumnGap={5}
                      >
                        {false && (
                          <Mui.IconButton
                            onClick={() =>
                              handleEditClick({
                                article: (row as unknown) as Models.Set.Article,
                              })
                            }
                            size="small"
                            style={{
                              padding: theme.spacing(0.125),
                            }}
                          >
                            <Mui.Box fontSize={26} lineHeight={0}>
                              <i className="icon-etc-edit" />
                              {/* <MuiIcons.Edit /> */}
                            </Mui.Box>
                          </Mui.IconButton>
                        )}

                        {false && (
                          <Mui.IconButton
                            onClick={() => handleDeleteClick({ id: row.setID })}
                            size="small"
                            style={{
                              padding: theme.spacing(0.125),
                            }}
                          >
                            <Mui.Box fontSize={26} lineHeight={0}>
                              <i className="icon-etc-delete" />
                              {/* <MuiIcons.Delete /> */}
                            </Mui.Box>
                          </Mui.IconButton>
                        )}

                        {false && (
                          <Mui.IconButton
                            onClick={() =>
                              handleActivateClick({ id: row.setID })
                            }
                            size="small"
                            style={{
                              padding: theme.spacing(0.125),
                            }}
                          >
                            <Mui.Box fontSize={26} lineHeight={0}>
                              <i className="icon-etc-delete" />
                              {/* <MuiIcons.Delete /> */}
                            </Mui.Box>
                          </Mui.IconButton>
                        )}

                        {false && (
                          <Mui.IconButton
                            onClick={() =>
                              handleTranslateClick({
                                article: (row as unknown) as Models.Set.Article,
                              })
                            }
                            size="small"
                            style={{
                              padding: theme.spacing(0.125),
                            }}
                          >
                            <Mui.Box fontSize={26} lineHeight={0}>
                              {/* <MuiIcons.Translate /> */}
                              <i className="icon-etc-language"></i>
                            </Mui.Box>
                          </Mui.IconButton>
                        )}

                        <Mui.IconButton
                          onClick={() =>
                            handleView({
                              article: (row as unknown) as Models.Set.Article,
                            })
                          }
                          style={{
                            padding: theme.spacing(0.125),
                          }}
                          size="small"
                        >
                          <Mui.Box fontSize={30} lineHeight={0}>
                            {/* <MuiIcons.Visibility /> */}
                            <i className="icon-etc-view"></i>
                          </Mui.Box>
                        </Mui.IconButton>
                      </Mui.Box>
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No articles found">No articles found</ReactI18next.Trans></MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [validationFailedDialog, setValidationFailedDialog] = React.useState<{
    open: boolean;
    action: "ACTIVATE" | "DELETE";
    dependentArticles: Models.Set.Article[];
    selectedArticles: Models.Set.Article[];
  }>({
    open: false,
    action: "ACTIVATE",
    dependentArticles: [],
    selectedArticles: [],
  });
  const [currentArticle, setCurrentArticle] = React.useState<
    Models.Set.Article
  >();
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState<boolean>(
    false
  );
  const [editDialogOpen, setEditDialogOpen] = React.useState<
    Models.Set.Article
  >();
  const [translateDialogOpen, setTranslateDialogOpen] = React.useState<
    Models.Set.Article
  >();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<
    number | string
  >();
  const [activateDialogOpen, setActivateDialogOpen] = React.useState<
    number | string
  >();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const articleStore = React.useContext(App.Contexts.articleStore);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const { enqueueSnackbar } = Notistack.useSnackbar();

  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    articleStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getArticlesDependentOnThis({
    setID,
  }: {
    setID: number;
  }): Models.Set.Article[] {
    return articleStore.articles
      .filter((article) => article.status === 1)
      .filter((article) => article.dependentSets.includes(setID));
  }

  function getDependentArticlesOfThis({
    setID,
  }: {
    setID: number;
  }): Models.Set.Article[] {
    const selectedArticle = articleStore.articles.find(
      (article) => article.setID === setID
    );

    if (!selectedArticle) {
      return [];
    }

    return articleStore.articles
      .filter((article) => article.status === 2)
      .filter((article) =>
        selectedArticle.dependentSets.includes(article.setID)
      );
  }

  async function handleDeleteArticle() {
    setIsLoading(true);
    const uid = userStore.user?.uid;
    if (deleteDialogOpen === undefined || uid === undefined) {
      return;
    }

    try {
      await articleService.delete({
        articleId: deleteDialogOpen,
        uid,
      });
      enqueueSnackbar(t("Article successfully deleted!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setDeleteDialogOpen(undefined);
      articleStore.read();
    }
  }
  async function handleActivateArticle() {
    setIsLoading(true);
    const uid = userStore.user?.uid;
    if (activateDialogOpen === undefined || uid === undefined) {
      // console.log({ activateDialogOpen, uid });
      return;
    }
    try {
      await articleService.activate({
        setId: activateDialogOpen,
        uid,
      });
      enqueueSnackbar(t("Article successfully activated!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setActivateDialogOpen(undefined);
      articleStore.read();
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    articleStore.setSearch({ search: event.target.value as string });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as string | number;
    if (typeof status === "string" && status !== "") {
      return;
    }

    if (status === "") {
      articleStore.setStatus({ status: undefined });
      return;
    }

    articleStore.setStatus({ status });
  };

  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  return (
    <>
      <Article.Details
        open={detailsDrawerOpen}
        handleClose={toggleDetailsDrawer}
        article={currentArticle!}
        functionData={functionStore.functions}
        articlesData={articleStore.articles}
        handleEditClick={({ article }) => {
          toggleDetailsDrawer();
          setEditDialogOpen(article);
        }}
        handleTranslateClick={({ article }) => {
          toggleDetailsDrawer();
          setTranslateDialogOpen(article);
        }}
        handleDeleteClick={({ id }) => {
          toggleDetailsDrawer();

          const articlesDepentOnThis = getArticlesDependentOnThis({
            setID: id as number,
          });

          const selectedArticle = articleStore.articles.find(
            (article) => article.setID === id
          );

          if (articlesDepentOnThis.length > 0) {
            setValidationFailedDialog({
              open: true,
              action: "DELETE",
              dependentArticles: articlesDepentOnThis,
              selectedArticles: selectedArticle ? [selectedArticle] : [],
            });
            return;
          }

          setDeleteDialogOpen(id);
        }}
        handleActivateClick={function ({ id }) {
          toggleDetailsDrawer();

          const dependentArticlesOfThis = getDependentArticlesOfThis({
            setID: id as number,
          });

          const selectedArticle = articleStore.articles.find(
            (article) => article.setID === id
          );

          if (dependentArticlesOfThis.length > 0) {
            setValidationFailedDialog({
              open: true,
              action: "ACTIVATE",
              dependentArticles: dependentArticlesOfThis,
              selectedArticles: selectedArticle ? [selectedArticle] : [],
            });
            return;
          }

          setActivateDialogOpen(id);
        }}
      />
      <Article.Create
        open={articleStore.createDialogOpen}
        handleClose={() => {
          articleStore.setCreateDialogOpen({ createDialogOpen: false });
          // articleStore.read();
        }}
      />
      <Article.Translate
        open={translateDialogOpen !== undefined ? true : false}
        article={translateDialogOpen}
        handleClose={() => {
          setTranslateDialogOpen(undefined);
          // articleStore.read();
        }}
      />
      <Article.Edit
        article={editDialogOpen}
        open={editDialogOpen !== undefined ? true : false}
        handleClose={() => {
          setEditDialogOpen(undefined);
          // articleStore.read();
        }}
      />
      <Components.ResponsiveFullscreenDialogWithScroll
        open={validationFailedDialog.open}
        title={
          <>
            {validationFailedDialog.action === "DELETE" && "Deletion"}
            {validationFailedDialog.action === "ACTIVATE" && "Activation"}
            &nbsp;interrupted!
          </>
        }
        handleClose={() => {
          setValidationFailedDialog({
            action: "ACTIVATE",
            dependentArticles: [],
            open: false,
            selectedArticles: [],
          });
          setDetailsDrawerOpen(true);
        }}
        contentText={
          <>
            {(function () {
              if (validationFailedDialog.dependentArticles.length === 0) {
                return null;
              }

              return (
                <MuiLab.Alert
                  severity="error"
                  style={{ marginBottom: theme.spacing(1.5) }}
                >
                  <MuiLab.AlertTitle>Can't proceed!</MuiLab.AlertTitle>
                  {validationFailedDialog.action === "DELETE" && (
                    <>
                      Based on the selected license for deletion, we found that
                      there are other article(s) which depend on it. For a
                      successful re-attempt, please try deleting all those
                      article(s) first.
                    </>
                  )}
                  {validationFailedDialog.action === "ACTIVATE" && (
                    <>
                      Based on the selected license for activation, we found
                      that it is dependent on other article(s). For a successful
                      re-attempt, please try activating all those article(s)
                      first.
                    </>
                  )}
                </MuiLab.Alert>
              );
            })()}

            <Mui.Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: theme.spacing(0.5) }}
            >
              Selected license for{" "}
              {validationFailedDialog.action === "DELETE" && "deletion"}
              {validationFailedDialog.action === "ACTIVATE" && "activation"}
            </Mui.Typography>
            <Mui.Box
              style={{
                overflow: "auto",
                whiteSpace: "nowrap",
                padding: theme.spacing(2, 0),
              }}
            >
              {validationFailedDialog.selectedArticles.map((set) => (
                <Mui.Box
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    width: "100%",
                    marginRight: theme.spacing(3),
                    display: "inline-block",
                  }}
                  key={`Article${set.setID}`}
                >
                  <Article.Card
                    style={{
                      minWidth: "100%",
                      backgroundColor: theme.palette.background.default,
                    }}
                    functions={functionStore.functions.filter((func) =>
                      set.functions.includes(func.setFunctionID)
                    )}
                    article={set}
                    variant={"DETAILED"}
                  />
                </Mui.Box>
              ))}
            </Mui.Box>
            {(function () {
              if (validationFailedDialog.dependentArticles.length === 0) {
                return null;
              }

              return (
                <>
                  <Mui.Typography variant="body2" color="textSecondary">
                    {validationFailedDialog.action === "ACTIVATE" && (
                      <>
                        Deleted dependent license(s) of your selected license
                        for activation
                      </>
                    )}
                    {validationFailedDialog.action === "DELETE" && (
                      <>
                        Active license(s) dependent on your selected license for
                        deletion
                      </>
                    )}
                  </Mui.Typography>
                  <Mui.Box
                    style={{
                      overflow: "auto",
                      whiteSpace: "nowrap",
                      padding: theme.spacing(2, 0),
                    }}
                  >
                    {validationFailedDialog.dependentArticles.map((set) => (
                      <Mui.Box
                        style={{
                          minWidth: 300,
                          maxWidth: 300,
                          width: "100%",
                          marginRight: theme.spacing(3),
                          display: "inline-block",
                        }}
                        key={`Article#${set.setID}`}
                      >
                        <Article.Card
                          style={{
                            minWidth: "100%",
                            backgroundColor: theme.palette.background.default,
                          }}
                          functions={functionStore.functions.filter((func) =>
                            set.functions.includes(func.setFunctionID)
                          )}
                          article={set}
                          variant={"DETAILED"}
                        />
                      </Mui.Box>
                    ))}
                  </Mui.Box>
                </>
              );
            })()}
          </>
        }
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => {
                setValidationFailedDialog({
                  action: "ACTIVATE",
                  dependentArticles: [],
                  open: false,
                  selectedArticles: [],
                });
                setDetailsDrawerOpen(true);
              }}
            >
              <ReactI18next.Trans i18nKey="OK">OK</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      <Components.ResponsiveFullscreenDialogWithScroll
        open={deleteDialogOpen !== undefined ? true : false}
        handleClose={() => setDeleteDialogOpen(undefined)}
        title={t("Delete article")}
        contentText={t("Are you sure you want to delete this article?")}
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => setDeleteDialogOpen(undefined)}
            >
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              onClick={() => handleDeleteArticle()}
              disabled={isLoading}
              {...(!isLoading && {
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                },
              })}
            >
              <ReactI18next.Trans i18nKey="Delete">Delete</ReactI18next.Trans>
              {isLoading && (
                <Mui.CircularProgress
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  color={"inherit"}
                  size={theme.typography.button.fontSize}
                />
              )}
            </Mui.Button>
          </>
        }
      />
      <Components.ResponsiveFullscreenDialogWithScroll
        open={activateDialogOpen !== undefined ? true : false}
        handleClose={() => setActivateDialogOpen(undefined)}
        title={t("Activate article")}
        contentText={t("Are you sure you want to activate this article?")}
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => setActivateDialogOpen(undefined)}
            >
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              onClick={() => handleActivateArticle()}
              disabled={isLoading}
              {...(!isLoading && {
                style: {
                  backgroundColor: theme.palette.success.main,
                  color: theme.palette.common.white,
                },
              })}
            >
              <ReactI18next.Trans i18nKey="Activate">
                Activate
              </ReactI18next.Trans>
              {isLoading && (
                <Mui.CircularProgress
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  color={"inherit"}
                  size={theme.typography.button.fontSize}
                />
              )}
            </Mui.Button>
          </>
        }
      />
     { articleStore.articles.length > 0 &&(<Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.FormControl style={{ width: 300, marginRight: theme.spacing(2) }}>
          <Mui.InputLabel id="demo-simple-select-outlined-label">
            <ReactI18next.Trans i18nKey="Filter by status">
              Filter by status
            </ReactI18next.Trans>
          </Mui.InputLabel>
          <Mui.Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={articleStore.status || ""}
            onChange={handleChange}
            label={
              <ReactI18next.Trans i18nKey="Filter by status">
                Filter by status
              </ReactI18next.Trans>
            }
          >
            <Mui.MenuItem value="">
              <em>
                <ReactI18next.Trans i18nKey="None">None</ReactI18next.Trans>
              </em>
            </Mui.MenuItem>

            <Mui.MenuItem value={1}>
              <Components.WorkingStatus status={1} />
            </Mui.MenuItem>

            <Mui.MenuItem value={2}>
              <Components.WorkingStatus status={2} />
            </Mui.MenuItem>
          </Mui.Select>
        </Mui.FormControl>
        <Mui.FormControl style={{ width: 300 }}>
        { articleStore.articles.length > 0 &&(<Mui.TextField label={t("Search")} onChange={handleSearch} />)}
        </Mui.FormControl>
      </Mui.Grid>)}
      {articleStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {articleStore.view === "CARD" && (
            <Article.Views.Cards
              handleClick={({ article }) => {
                setCurrentArticle(article);
                toggleDetailsDrawer();
              }}
              articles={articleStore.filtered}
            />
          )}
          {articleStore.view === "TABULAR" && (
            <EnhancedTable
              handleEditClick={({ article }) => {
                setEditDialogOpen(article);
              }}
              handleTranslateClick={({ article }) => {
                setTranslateDialogOpen(article);
              }}
              handleDeleteClick={({ id }) => {
                setDeleteDialogOpen(id);
              }}
              handleActivateClick={({ id }) => {
                setActivateDialogOpen(id);
              }}
              handleView={({ article }) => {
                setCurrentArticle(article);
                toggleDetailsDrawer();
              }}
              rows={articleStore.filtered}
            />
          )}
        </>
      )}
    </>
  );
});
