/** @format */

import * as Mui from "@material-ui/core";
import * as React from "react";
import * as Router from "react-router-dom";
import { useLocation } from 'react-router-dom';

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export function Main() {
  const classes = useStyles();
  const location = useLocation();
  React.useEffect(() => {
    var URL = "http://localhost:3000/device-configuration-upload";
    var res =  location.pathname
    if(res === '/device-configuration-upload')
    {
      window.location.href = 'http://easytouchcloudtest.kern-sohn.com/umbraco#/login/false?returnPath=%252Fcontent';
    } else if(res === '/umbraco#/')
    {
      window.location.href =  'https://ssodev.kern-sohn.com'
    }

  }, [location.pathname]);
  return (
    <div className={classes.root}>
      <Router.Outlet />
    </div>
  );
}
