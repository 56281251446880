import * as Mui from "@material-ui/core";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Components from "src/app/components";
import * as Models from "src/models";

export declare namespace OrderDetails {
  export interface Props {
    open: boolean;
    order: Models.Order.Main;
    handleClose(): void;
  }
}

// eslint-disable-next-line no-redeclare
export function OrderDetails({ open, order, handleClose }: OrderDetails.Props) {
  return (
    <Components.ResponsiveFullscreenDialogWithScroll
      {...{
        open,
        handleClose,
        title: "Order details",
        fullscreenBreakpoint: "md",
        actions: (
          <>
            <Mui.Button
              variant="contained"
              color="primary"
              onClick={() => window.print()}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button variant="contained" onClick={handleClose}>
              <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
            </Mui.Button>
          </>
        ),
        contentText: <Components.OrderForSuccessPage {...{ order }}  />,
      }}
    />
  );
}
