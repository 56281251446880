/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Article from "src/app/pages/article";
import * as DateFns from "date-fns";
import * as Containers from "src/app/containers";
import * as Components from "src/app/components";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import { makeStyles, Theme  } from "@material-ui/core";
import * as CustomerDetail from "src/app/pages/dealercustomer/customerdetail";
import { withStyles } from "@material-ui/core";
import * as Services from "src/services";
import * as Constants from "src/constants";
import * as MuiIcon from "@material-ui/icons";

interface Data extends Models.Order.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);

function EnhancedTable({ rows, cid }: { rows: Models.Order.Main[]; cid: any }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderedOn, setOrderedOn] = React.useState<any>();
  const [orderBy, setOrderBy] = React.useState<keyof Data>("orderCode");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderCode, setOrderCode] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] =
    React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const uid = userStore.user?.ETUID;
  React.useEffect(() => {
    setPage(0);
  }, [rows]);
  const key = "orderCode";

  const orderArrayUniqueByKey = [
    ...new Map(rows.map((item) => [item[key], item])).values(),
  ];
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  

  const orderDetaile = rows;
  var keys = ["setName"];
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(16),
      border: "2px solid #dadde9",
    },
  }))(Mui.Tooltip);
  const [language, setLanguage] = React.useState("en-IN");
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language;
  const [orders, setOrders] = React.useState<Models.Order.Main>();
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  React.useEffect(() => {
    (async function () {
      // 
      if (!orderId) {
        return;
      }

      try {
        //alert('Order');
        const {
          result: [order],
        } = await orderServices.getData({
          body: { id: orderId, cid: cid },
        });

        var { result } = await orderServices.getData({
          body: { id: orderId, cid: cid },
        });

        //overAllStatus = 3 -- > Order completely returned
        if (result.find((k: any) => k.status === 3)) {
          order.overAllStatus = 3;
          var partialReturnOrderObjectForStatus3 = result.find(
            (k: any) => k.status === 2
          );
          partialReturnOrderObjectForStatus3?.orderDetails.map((k: any) => {
            // eslint-disable-next-line array-callback-return
            order.orderDetails.map((q: any) => {
              if (q.setID === k.setID) {
                q.returnQuantity = k.quantity;
              }
            });
          });
        }
        //overAllStatus = 2 -- > Order paretially returned
        else if (result.find((k: any) => k.status === 4)) {
          order.overAllStatus = 4;
          var partialReturnOrderObjectForStatus4 = result.find(
            (k: any) => k.status === 2
          );
          partialReturnOrderObjectForStatus4?.orderDetails.map((k: any) => {
            // eslint-disable-next-line array-callback-return
            order.orderDetails.map((q: any) => {
              if (q.setID === k.setID) {
                q.returnQuantity = k.quantity;
              }
            });
          });
          // 
        } else if (result.find((k: any) => k.status === 5)) {
          order.overAllStatus = 5;
          var partialReturnOrderObjectForStatus5 = result.find(
            (k: any) => k.status === 2
          );
          partialReturnOrderObjectForStatus5?.orderDetails.map((k: any) => {
            // eslint-disable-next-line array-callback-return
            order.orderDetails.map((q: any) => {
              if (q.setID === k.setID) {
                q.returnQuantity = k.quantity;
              }
            });
          });
          // 
        }
        //overAllStatus = 1 -- > Order paretially returned Order with no return
        else {
          order.overAllStatus = 1;
        }
        
        setOrders(order);
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsDialogOpen]);
  // 
  React.useEffect(() => {
    if (languageValue === "en") {
      setLanguage("en-IN");
    } else if (languageValue === "fr") {
      setLanguage("fr-FR");
    } else {
      setLanguage("de-DE");
    }
  }, [languageValue]);

  return (
    <div className={classes.root}>
      {/* <Components.ResponsiveFullscreenDialogWithScroll
        fullscreenBreakpoint="md"
        open={orderDetailsDialogOpen}
        handleClose={() => setOrderDetailsDialogOpen(false)}
        title={t("Order details")}
        contentText={<CustomerDetail.Details orderId={orderId} cid={uid} />}
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => window.print()}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setOrderDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      /> */}
      <Components.LightBox
        open={orderDetailsDialogOpen}
        handleClose={() => setOrderDetailsDialogOpen(false)}
        content={
          <CustomerDetail.Details
            orderId={orderId}
            cid={cid}
            orderCode={orderCode}
            orderedOn={orderedOn}
          />
        }
        title={
          <>
            <Mui.Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Mui.Grid item xs={2} style={{ display: "flex" }}>
                <Mui.Typography
                  style={{ fontWeight: "bold" }}
                  variant="h6"
                  className={classes.title}
                >
                  <ReactI18next.Trans i18nKey="Order details">
                    Order details
                  </ReactI18next.Trans>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={2} style={{ display: "flex" }}>
                <Components.OrderStatus
                  status={orders?.overAllStatus as 1 | 2 | 3 | 4 | 5}

                  //  status={row?.etUserStatus as 1 | 2 | 3 | 4 || customers?.etUserStatus as 1 | 2 | 3 | 4}
                />
              </Mui.Grid>
            </Mui.Grid>
          </>
        }
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setOrderDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => {
                window.print();
              }}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      {/* <Mui.Paper className={classes.paper}> */}
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderCode"
                  label={t("Order #")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="Orderdate"
                  label={t("Order date")}
                />

 <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="Sourceoforder"
                  label={t("Order created by")}
                />

                <EnhancedTableHead
                  rightmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="grossTotalPriceInclVAT"
                  label={t("Status")}
                />
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(
              orderArrayUniqueByKey as any,
              getComparator(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.orderCode as string);
                const labelId = `enhanced-table-checkbox-${index}`;
                const grossTotalPriceInclVAT: any = row.grossTotalPriceInclVAT;
                const totalPrice =
                  row?.vatTaxPercent === 0
                    ? (19 / 100) * grossTotalPriceInclVAT +
                      +grossTotalPriceInclVAT
                    : row.grossTotalPriceInclVAT;
                const assignedDetails =
                  row.assignedTo === "0" ? "KERN" : "Dealer";
                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => {
                      handleClick(event, row.orderCode as string);
                      setOrderCode(row.orderCode);
                      setOrderedOn(row.orderedOn);
                      setOrderId(row.orderID);
                      setOrderDetailsDialogOpen(true);
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderCode}

                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                      specialRowColor

                    >
                      {row.dataFrom === "webshop" &&
                      row.sageOLReferenceBillID != null &&
                      row.sageOLReferenceBillID > 0 &&
                      row.assignedTo === "0"
                        ? row.orderCode + "-" + row.sageOLReferenceBillID
                        : row.orderCode}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell specialRowColor>
                      {DateFns.format(new Date(row.orderedOn), "dd.MM.yyyy")}
                    </Components.Mui.TableCell>
                 <Components.Mui.TableCell specialRowColor>
                      {row.dataFrom === "webshop" && row.assignedTo === "0"
                        ? ""
                        : row.userFirstName + " " + row.userLastName}
                    </Components.Mui.TableCell>

                 

                    <Components.Mui.TableCell rightmost specialRowColor>
                      <Mui.Grid container justify="space-between">
                        <Components.OrderStatus
                          status={row?.status as 1 | 2 | 3 | 4 | 5}

                         
                        />
                        <Mui.IconButton
                          size="medium"
                          style={{
                            padding: theme.spacing(0.125),
                            fontSize: "18px",
                          }}
                        >
                          <MuiIcon.ChevronRight
                            style={{ fontSize: "30px" }}
                            color="primary"
                          />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                  
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: "0 0 60px 0" }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No orders found">
                        No orders found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
     
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [searchOrder, setSearchOrder] = React.useState<string>("");
  const [searchUser, setSearchUser] = React.useState<string>("");
  const [searchOrderLicense, setSearchOrderLicense] =
    React.useState<string>("");
  const [searchOrderDevice, setSearchOrderDevice] = React.useState<string>("");
  const theme = Mui.useTheme();
  const importDealerStore = React.useContext(App.Contexts.importDealerStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const addCustomerStore = React.useContext(App.Contexts.addCustomerStore);
  const [searchParams] = Router.useSearchParams();
  var searchParam = searchParams.get("q");
  var searchParamCustomerID = searchParams.get("m");
  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = "customer";
    if (uid !== undefined && uid !== null) {
      dCustomerStore.read({
        uid,
        userRole,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  React.useEffect(() => {
    const uid = searchParam;
    const userRole = "customer";
    if (
      userRole === "customer" ||
      userRole === "dealer" ||
      userRole === "admin" ||
      userRole === "superadmin"
    ) {
      if (uid !== undefined && uid !== null) {
        orderStore.read({
          uid,
          userRole,
        });
      }
    }
    importDealerStore.readUsers({ searchtext: "All"});
    localStorage.removeItem("namedatad");
    let dealer: any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let searchHighlight: Boolean = false;
  const getSearchHighlight = (highlightFlag: boolean) => {
    searchHighlight = highlightFlag;
    return highlightFlag;
  };
  const handleSearchUser = (event: any) => {
    // 
    setSearchUser(event.target.value);
  };
  const handleSearchOrder = (event: any) => {
    // 
    setSearchOrder(event.target.value);
  };
  const customerName: any = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.customerName);
    
  const emailID = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.emailID);
    const dealerCustomerID = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.customerDealerID);
  const etUserStatus: any = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.etUserStatus);
  const companyName: any = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.companyName);
  const fullName = customerName[0] === undefined ? "" : customerName[0].toString();
  const statusConversion = parseInt(etUserStatus.toString());
  const userRoleName = userStore.user?.RoleName;
  //  
  const [state, setState] = React.useState<any>();
  const { t } = ReactI18next.useTranslation();
  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  function a12yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
    },
    link: {
      display: "flex",
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }));
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Mui.Box p={3}>
            <Mui.Typography>{children}</Mui.Typography>
          </Mui.Box>
        )}
      </div>
    );
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeTab2 = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const handleChangeIndex2 = (index: number) => {
    setValue(index);
  };
  const [detailsDrawerOpen, setDetailsDrawerOpen] =
    React.useState<boolean>(false);
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const customerStore = React.useContext(App.Contexts.customerStore);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const handleSearchLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOrderLicense(event.target.value);
    licenseStore.setSearch({ search: event.target.value as string });
  };
  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  const handleStatusFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const status = event.target.value as string | number;
    if (typeof status === "string" && status !== "") {
      return;
    }

    if (status === "") {
      licenseStore.setStatus({ status: undefined });
      return;
    }

    licenseStore.setStatus({ status });
  };
  React.useEffect(() => {
    const uid: any = searchParam;
    const userRole = "customer";
    if (
      (userRole === "customer" || userRole === "dealer") &&
      uid !== undefined &&
      uid !== null
    ) {
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });
    }


    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removingLicenseDuplication = licenseStore.filtered.filter((k) =>
    orderStore.orders.length === 0
      ? k.licenseCode === "CustInvite"
      : k.setID !== 2
  );

  //  

  /********************End of duplication article remove filter  ********************/

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [deactivationDialogOpen, setDeactivationDialogOpen] =
    React.useState<boolean>(false);
  const [detailsDrawerOpenDevice, setDetailsDrawerOpenDevice] =
    React.useState<boolean>(false);
  const [currentDevice, setCurrentDevice] = React.useState<
    Models.Devices & { sets: Set<Models.Set.Article> }
  >();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const easyTouchDeviceStore = React.useContext(
    App.Contexts.easyTouchDeviceStore
  );

  React.useEffect(() => {
    const macId = searchParams.get("macId");
    if (!macId) {
      return;
    }
    if (!easyTouchDeviceStore.devices) {
      return;
    }
    const device = easyTouchDeviceStore.devices.find(
      (device) => device.macID === macId
    );
    if (!device) {
      return;
    }
    if (device) {
      Array.from(licenseStore.selectedArticlesForDeactivation.values()).forEach(
        (setID) => {
          licenseStore.toggleArticleSelectionForDeactivation({ setID });
        }
      );
      device?.sets?.forEach((set) => {
        licenseStore.toggleArticleSelectionForDeactivation({
          setID: set?.setID,
        });
      });
      setDetailsDrawerOpen(true);
      setCurrentDevice(device);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [easyTouchDeviceStore.devices, deactivationDialogOpen]);

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;

    if (userRole === "customer") {
      return;
    }

    const customer = searchParams.get("customer");

    if (!customer) {
      return;
    }

    customerChange(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerChange = (customer: number | string) => {
    const uid: any = searchParam;

    easyTouchDeviceStore.setCustomer({ customer });

    if (uid === undefined || uid === null) {
      return;
    }

    if (customer === "" || customer === undefined || customer === null) {
      return;
    }

    easyTouchDeviceStore.read({
      body: { status: null, userID: customer },
      userRole: "customer",
      uid,
    });
  };

  const handleChangeDevice = (event: React.ChangeEvent<{ value: unknown }>) => {
    const customer = event.target.value as string | number;
    customerChange(customer);
  };

  const handleSearchDevice = (event: any) => {
    setSearchOrderDevice(event.target.value);
    easyTouchDeviceStore.setSearch({ search: event.target.value as string });
  };

  const toggleDetailsDrawerDevice = () => {
    setDetailsDrawerOpenDevice(!detailsDrawerOpenDevice);
  };

  React.useEffect(() => {
    functionStore.read();

    const userRole = "customer";
    const uid: any = searchParam;

    if (
      (userRole === "customer" || userRole === "EU") &&
      uid !== undefined &&
      uid !== null
    ) {
      easyTouchDeviceStore.read({ userRole: "customer", status: -1, uid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDeactivateAll() {
    Array.from(licenseStore.selectedArticlesForDeactivation.values()).forEach(
      (setID) => {
        licenseStore.toggleArticleSelectionForDeactivation({ setID });
      }
    );
    currentDevice?.sets.forEach(({ setID }) => {
      licenseStore.toggleArticleSelectionForDeactivation({ setID });
    });
    setDetailsDrawerOpenDevice(false);
    setDeactivationDialogOpen(true);
  }

  function handleDeactivateSelected() {
    setDetailsDrawerOpenDevice(false);
    setDeactivationDialogOpen(true);
  }
  React.useEffect(() => {
    functionStore.read();

    const userRole = userStore.user?.RoleName ?? ("customer" || "EU");
    const uid = userStore.user?.ETUID;

    if (userRole === "admin" || userRole === "superadmin") {
      customerStore.read({});
    }
    if (
      (userRole === "customer" || userRole === "EU") &&
      uid !== undefined &&
      uid !== null
    ) {
      easyTouchDeviceStore.read({ userRole: "customer", status: -1, uid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleDeactivate() {
    try {
      const userRole = userStore.user?.RoleName;
      const etuid = parseInt(searchParam ?? "");

      if (!etuid) {
        return;
      }

      if (!currentDevice) {
        return;
      }

      setIsLoading(true);

      const successAcknowledgement = () => {
        enqueueSnackbar(t("License(s) deactivated successfully."), {
          variant: "success",
          preventDuplicate: false,
        });


        easyTouchDeviceStore.read({
          userRole: "customer",
          status: -1,
          uid: etuid,
        });
      };

      if (
        userRole === "customer" &&
        licenseStore.selectedArticlesForDeactivation.size > 0 &&
        licenseStore.selectedArticlesForDeactivation.size ===
          currentDevice?.sets.size
      ) {
        await licenseStore.deactivateAll({
          body: {
            MacID: currentDevice.macID,
            UserId: etuid,
          },
        });
        successAcknowledgement();
        return;
      }

      if (
        (userRole === "admin" || userRole === "superadmin") &&
        easyTouchDeviceStore.customer &&
        licenseStore.selectedArticlesForDeactivation.size > 0 &&
        licenseStore.selectedArticlesForDeactivation.size ===
          currentDevice?.sets.size
      ) {
        await licenseStore.deactivateAll({
          body: {
            MacID: currentDevice.macID,
            UserId: easyTouchDeviceStore.customer as number,
          },
        });
        successAcknowledgement();
        return;
      }

      const dependentSetIds = Array.from(currentDevice?.sets?.values() ?? [])
        .filter((set) =>
          licenseStore.selectedArticlesForDeactivation.has(set?.setID)
        )
        .reduce((acc, set) => {
          Array.from(currentDevice?.sets?.values() ?? [])
            .filter(
              (set) =>
                !licenseStore.selectedArticlesForDeactivation.has(set?.setID)
            )
            .forEach((otherSet) => {
              if (
                otherSet.dependentSets.includes(set.setID) &&
                licenseStore.selectedArticlesForDeactivation.has(otherSet.setID)
              ) {
                return;
              }
              if (otherSet.dependentSets.includes(set.setID)) {
                acc.add(otherSet.setID);
              }
            });
          return acc;
        }, new Set<number>());

      const dependentSets = Array.from(
        currentDevice?.sets?.values() ?? []
      ).filter((set) => dependentSetIds.has(set?.setID));

      const selectedSets = Array.from(currentDevice.sets.values()).filter(
        (set) => licenseStore.selectedArticlesForDeactivation.has(set.setID)
      );

      await licenseStore.deactivateSelected({
        body: {
          LicenseDetailsList: [...dependentSets, ...selectedSets].map((set) => {
            return {
              LicenseDetailID: (set as any)?.licenseDetailID,
              MacID: currentDevice.macID,
            };
          }),
        },
      });
      successAcknowledgement();
    } catch (error) {
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setDeactivationDialogOpen(false);
    }
  }
  const navigate = Router.useNavigate();
  return (
    <>
      {userRoleName !== "customer" && (
        <Mui.Button
          variant="contained"
          style={{
            marginTop: "30px",
            position: "fixed",
            bottom: "36px",
            left: "166px",
            zIndex: 1,
          }}
          onClick={() => {
            navigate(`/dealercustomer/`);
          }}
        >
          <ReactI18next.Trans i18nKey="Back">Back</ReactI18next.Trans>
        </Mui.Button>
      )}
      {(userRoleName === "dealer" &&
      <Mui.Button
          variant="contained"
          color="primary"
          style={{
            marginTop: "30px",
            position: "fixed",
            bottom: "36px",
            right: "30px",
            zIndex: 1,
          }}
          onClick={() => {
           navigate(`/dealercustomer/customerinvite?e=${emailID}&ci=${dealerCustomerID}`);
          }}
        >
          <ReactI18next.Trans i18nKey="Back">Add more license</ReactI18next.Trans>
        </Mui.Button>)}
      { statusConversion === 1 && userRoleName === "dealer" &&  <Mui.Button
        style={{
          height: "43px",
          marginTop: "30px",
          position: "fixed",
          bottom: "36px",
          right: "24px",
        }}
        color="primary"
        variant="contained"
        type="submit"
       
        onClick={async () => {
    const uid = userStore.user?.ETUID;

          var requestHeader = {
            EmailID: emailID[0],
            DealerID: uid,
            OrderCode: "0"
          }
              var result = await addCustomerStore.cancelInvite({
               body:requestHeader
              })
              enqueueSnackbar(t("The invitation has been canceled and the sold order has been returned to you"), {
                variant: "success",
                preventDuplicate: false,
              });
              navigate("/dealercustomer")
        }}
      >
        <ReactI18next.Trans i18nKey="Cancel invite">
        Cancel invite
        </ReactI18next.Trans>
      </Mui.Button>}

      <Mui.Paper>
        <Mui.Box style={{ padding: "8px 10px 0 10px" }}>
          <Mui.Grid xs={12}>
            <Mui.Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Mui.Grid item xs={6} style={{ display: "flex" }}>
                <Mui.Grid
                  style={{
                    margin: "0px 17px 0px 33px",
                    padding: "2px 0px 2px 4px",
                  }}
                >
                  <MuiIcons.Business
                    style={{
                      width: "53px",
                      height: "43px",
                      color: theme.palette.grey[500],
                    }}
                  />
                </Mui.Grid>
                <Mui.Box>
                  <Mui.Typography
                    style={{
                      display: "flex",
                      fontWeight: "bold",
                      fontSize: "20px",
                      margin: "12px 0px 2px 10px",
                      opacity: "0.8",
                    }}
                  >
                    {companyName[0]}
                  </Mui.Typography>
                </Mui.Box>
              </Mui.Grid>
              <Mui.Grid item>
                <Containers.ShowIfAuthorised
                  roles={["superadmin", "admin", "dealer"]}
                >
                  <Mui.Box
                    style={{
                      display: "flex",
                      fontWeight: "bold",
                      fontSize: "10px",
                      margin: "12px 33px 0px 0px",
                    }}
                  >
                    <Mui.Typography
                      style={{
                        display: "flex",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "0px 0px 0px 0px",
                        opacity: "0.8",
                      }}
                    >
                      {t("Status")}:&nbsp;&nbsp;
                    </Mui.Typography>
                 
                    <Components.CustomerStatus
                                status={
                                  statusConversion === 1
                                    ? 1
                                    : 3
                                }
                              />
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid xs={12}>
            <Mui.Grid container>
              <Mui.Grid item xs={3} style={{ marginLeft: "80px" }}>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider
             
            />
            <Mui.Grid container>
              <Mui.Grid item xs={4}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: theme.spacing(30),
                        height: "22px",
                        margin: "14px 0 0px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="First name">
                        First name
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "14px 0 0 0",
                      }}
                    >
                      {fullName?.split(/(\s+)/)[0]}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} md={8} lg={8} xl={8}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        height: "22px",
                        margin: "16px 0 24px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Email Id">
                        Customer ID
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={3} lg={3} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "16px 0 24px 0",
                      }}
                    >
                 
                      
                        <Mui.Link style={{ color: "black" }}>
                          {dealerCustomerID ?? 0}
                        </Mui.Link>
                      
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                
              </Mui.Grid>
              <Mui.Grid item xs={2}></Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 14px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Last name">
                        Last name
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography style={{ margin: "14px 0 14px 0" }}>
                     
                      {fullName?.split(/(\s+)/)[2]}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "0px 0 14px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Email Id">
                        Email Id
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "0px 0 24px 0",
                      }}
                      onClick={() => {
                        window.open(`mailto:${emailID[0]}`);
                      }}
                    >
                      <Mui.Tooltip
                        title={
                          <ReactI18next.Trans i18nKey="click to open an mail">
                            click to open an mail
                          </ReactI18next.Trans>
                        }
                      >
                        <Mui.Link style={{ color: "black" }}>
                          {emailID[0]}
                        </Mui.Link>
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Mui.Paper>
      {orderStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {userStore.user?.RoleName === "customer" && dCustomerStore.delaerCustomer.length > 1 ?  
          <Mui.Grid xs={12}>
            <Mui.Divider style={{ margin: "6px 6px 0 6px" }} />
            <div className={classes.root}>
              <Mui.AppBar
                position="static"
                color="transparent"
                style={{ margin: "0 4px 0 4px" }}
              >
                <Mui.Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="full width tabs example"
                >
                    <Mui.Tab label={t("Users")} {...a11yProps(0)} />
                  <Mui.Tab label={t("Orders")} {...a11yProps( 1)} />
                  {orderStore.orders.length > 0 && (
                    <Mui.Tab label={t("Licenses")} {...a11yProps(2)} />
                  )}
                  {orderStore.orders.length > 0 && (
                    <Mui.Tab label={t("Terminal devices")} {...a11yProps(3 )} />
                  )}
                 
                </Mui.Tabs>
              </Mui.AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                <>
                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width={1}
                      height={48}
                      marginBottom={4}
                    >
                      <Mui.Box display="flex" alignItems="baseline">
                        <Mui.FormControl style={{ width: 300 }}>
                          {dCustomerStore.delaerCustomer.length > 0 && (
                            <Mui.TextField
                              type="text"
                              value={searchOrder}
                              label={t("Search")}
                              onChange={handleSearchUser}
                              autoFocus={searchUser.length > 0}
                            />
                          )}
                        </Mui.FormControl>
                      </Mui.Box>
                    
                    </Mui.Box>
                    {dCustomerStore.isInitialLoading ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        <CustomerDetail.EnhancedTableUser
                            rows={dCustomerStore.delaerCustomer.filter((user) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                user?.customerID
                                  ?.toLowerCase()
                                  .includes(searchUser.toLowerCase()) ||
                                  user?.customerName
                                  ?.toLowerCase()
                                  .includes(searchUser.toLowerCase()) ||
                                  user?.companyName
                                  ?.toLowerCase()
                                  .includes(searchUser.toLowerCase()) ||
                                  user?.countryName
                                  ?.toLowerCase()
                                  .includes(searchUser.toLowerCase())
                              );
                            })}
                           // handleView={}
                          />
                        
                       
                      </>
                    )}
                  </>

                  
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <>
                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width={1}
                      height={48}
                      marginBottom={4}
                    >
                      <Mui.Box display="flex" alignItems="baseline">
                        <Mui.FormControl style={{ width: 300 }}>
                          {orderStore.orders.length > 0 && (
                            <Mui.TextField
                              type="text"
                              value={searchOrder}
                              label={t("Search")}
                              onChange={handleSearchOrder}
                              autoFocus={searchOrder.length > 0}
                            />
                          )}
                        </Mui.FormControl>
                      </Mui.Box>
                      <Mui.Box>
                        <Mui.Tooltip title="Card View" placement="top">
                          <Mui.Box display="inline-block" color="divider">
                            <Mui.IconButton
                              color={
                                orderStore.view === "CARD"
                                  ? "default"
                                  : "inherit"
                              }
                              onClick={() =>
                                orderStore.setView({ view: "CARD" })
                              }
                              style={{ marginRight: theme.spacing(1) }}
                            >
                              <i className="icon-etc-card-view" />
                            </Mui.IconButton>
                          </Mui.Box>
                        </Mui.Tooltip>

                        <Mui.Tooltip title="Tabular View" placement="top">
                          <Mui.Box display="inline-block" color="divider">
                            <Mui.IconButton
                              color={
                                orderStore.view === "TABULAR"
                                  ? "default"
                                  : "inherit"
                              }
                              onClick={() =>
                                orderStore.setView({ view: "TABULAR" })
                              }
                            >
                              <i className="icon-etc-table-view" />
                            </Mui.IconButton>
                          </Mui.Box>
                        </Mui.Tooltip>
                      </Mui.Box>
                    </Mui.Box>
                    {orderStore.isInitialLoading ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        {orderStore.view === "CARD" && (
                          <CustomerDetail.Views.Cards
                            orders={orderStore.orders.filter((order) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                order?.orderCode
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userFirstName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userLastName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.companyName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase())
                              );
                            })}
                          />
                        )}

                        {orderStore.view === "TABULAR" && (
                          <EnhancedTable
                            rows={orderStore.orders.filter((order) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                order?.orderCode
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userFirstName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userLastName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.companyName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase())
                              );
                            })}
                            cid={searchParam}
                          />
                        )}
                       
                      </>
                    )}
                  </>
                  
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <>
                    <CustomerDetail.DetailsLicense
                      open={detailsDrawerOpen}
                      handleClose={toggleDetailsDrawer}
                      functionData={functionStore.functions}
                      license={currentLicense}
                    />
                    <Mui.Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginBottom: theme.spacing(3) }}
                    >
                      <Mui.FormControl
                        style={{ width: 300, marginRight: theme.spacing(2) }}
                      >
                        {removingLicenseDuplication.length > 0 && (
                          <>
                            <Mui.InputLabel id="demo-simple-select-outlined-label">
                              <ReactI18next.Trans i18nKey="Filter by status">
                                Filter by status
                              </ReactI18next.Trans>
                            </Mui.InputLabel>
                            <Mui.Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={licenseStore.status}
                              onChange={handleStatusFilterChange}
                              label={t("Filter by status")}
                            >
                              <Mui.MenuItem value="">
                                <em>
                                  <ReactI18next.Trans i18nKey="None">
                                    None
                                  </ReactI18next.Trans>
                                </em>
                              </Mui.MenuItem>

                              <Mui.MenuItem value={1}>
                                <Components.WorkingStatus
                                  status={1}
                                  labels={{ 1: "Available", 2: "Used" }}
                                />
                              </Mui.MenuItem>

                              <Mui.MenuItem value={2}>
                                <Components.WorkingStatus
                                  status={2}
                                  labels={{ 1: "Available", 2: "Used" }}
                                />
                              </Mui.MenuItem>
                            </Mui.Select>
                          </>
                        )}
                      </Mui.FormControl>
                      {removingLicenseDuplication.length > 0 && (
                        <Mui.FormControl style={{ width: 300 }}>
                          <Mui.TextField
                            value={searchOrderLicense}
                            label={t("Search")}
                            onChange={handleSearchLicense}
                            autoFocus={searchOrderLicense.length > 0}
                          />
                        </Mui.FormControl>
                      )}
                    </Mui.Grid>
                    <Mui.Grid container spacing={3}>
                      {licenseStore.isInitialLoading  &&
                        licenseStore.isLoading ? (
                        <Mui.Box
                          padding={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={"100%"}
                          minHeight={"100%"}
                          maxHeight={"100%"}
                          height={400}
                          flexDirection="column"
                        >
                          <Mui.CircularProgress />
                        </Mui.Box>
                      ) : (
                        <>
                          {removingLicenseDuplication.length === 0 && (
                            <Mui.Grid
                              container
                              style={{ padding: "60px 0 60px 0" }}
                            >
                              <Mui.Grid item xs={12}>
                                <MuiLab.Alert severity="info">
                                  <ReactI18next.Trans i18nKey="No licenses found">
                                    No licenses found
                                  </ReactI18next.Trans>
                                </MuiLab.Alert>
                              </Mui.Grid>
                            </Mui.Grid>
                          )}
                          {removingLicenseDuplication.map((device: any) => (
                            <Mui.Grid key={uuidv4()} item xs={12} md={6}>
                              <CustomerDetail.CardLicense
                                license={{
                                  ...device,
                                  setFuncstionsList:
                                    functionStore.functions.filter((func) =>
                                      device.setList?.functions.includes(
                                        func.setFunctionID
                                      )
                                    ),
                                }}
                                handleClick={() => {
                                  setCurrentLicense(device);
                                  toggleDetailsDrawer();
                                }}
                              />
                            </Mui.Grid>
                          ))}
                        </>
                      )}
                    </Mui.Grid>
                  </>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Components.ResponsiveFullscreenDialogWithScroll
                    open={deactivationDialogOpen}
                    title={"Deactivate License(s)"}
                    handleClose={() => {}}
                    contentText={
                      <>
                        {(function () {
                          if (
                            currentDevice?.sets.size ===
                            licenseStore.selectedArticlesForDeactivation.size
                          ) {
                            return null;
                          }

                          const dependentSetIds = Array.from(
                            currentDevice?.sets?.values() ?? []
                          )
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .reduce((acc, set) => {
                              Array.from(currentDevice?.sets?.values() ?? [])
                                .filter(
                                  (set) =>
                                    !licenseStore.selectedArticlesForDeactivation.has(
                                      set?.setID
                                    )
                                )
                                .forEach((otherSet) => {
                                  if (
                                    otherSet.dependentSets.includes(
                                      set.setID
                                    ) &&
                                    licenseStore.selectedArticlesForDeactivation.has(
                                      otherSet.setID
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    otherSet.dependentSets.includes(set.setID)
                                  ) {
                                    acc.add(otherSet.setID);
                                  }
                                });
                              return acc;
                            }, new Set<number>());

                          if (dependentSetIds.size > 0) {
                            return (
                              <MuiLab.Alert
                                severity="warning"
                                style={{ marginBottom: theme.spacing(1.5) }}
                              >
                                <MuiLab.AlertTitle>
                                  Proceed with caution!
                                </MuiLab.AlertTitle>
                                Based on your selected license we found the
                                following dependent articles. The following
                                dependent articles will also be deactivated if
                                you proceed.
                              </MuiLab.Alert>
                            );
                          }
                        })()}

                        <Mui.Typography gutterBottom>
                          Are you sure you want to deactivate the following
                          articles for this device with{" "}
                          <strong>Machine ID - {currentDevice?.macID}</strong>?
                        </Mui.Typography>

                        <Mui.Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginTop: theme.spacing(0.5) }}
                        >
                          Selected license
                        </Mui.Typography>
                        <Mui.Box
                          style={{
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            padding: theme.spacing(2, 0),
                          }}
                        >
                          {Array.from(currentDevice?.sets?.values() ?? [])
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .map((set) => (
                              <Mui.Box
                                style={{
                                  minWidth: 300,
                                  maxWidth: 300,
                                  width: "100%",
                                  marginRight: theme.spacing(3),
                                  display: "inline-block",
                                }}
                                key={`Article#${set.setID}`}
                              >
                                <Article.Card
                                  style={{
                                    minWidth: "100%",
                                    backgroundColor:
                                      theme.palette.background.default,
                                  }}
                                  functions={functionStore.functions.filter(
                                    (func) =>
                                      set.functions.includes(func.setFunctionID)
                                  )}
                                  article={set}
                                  variant={"DETAILED"}
                                />
                              </Mui.Box>
                            ))}
                        </Mui.Box>
                        {(function () {
                          if (
                            currentDevice?.sets.size ===
                            licenseStore.selectedArticlesForDeactivation.size
                          ) {
                            return null;
                          }

                          const dependentSetIds = Array.from(
                            currentDevice?.sets?.values() ?? []
                          )
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .reduce((acc, set) => {
                              Array.from(currentDevice?.sets?.values() ?? [])
                                .filter(
                                  (set) =>
                                    !licenseStore.selectedArticlesForDeactivation.has(
                                      set?.setID
                                    )
                                )
                                .forEach((otherSet) => {
                                  if (
                                    otherSet.dependentSets.includes(
                                      set.setID
                                    ) &&
                                    licenseStore.selectedArticlesForDeactivation.has(
                                      otherSet.setID
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    otherSet.dependentSets.includes(set.setID)
                                  ) {
                                    acc.add(otherSet.setID);
                                  }
                                });
                              return acc;
                            }, new Set<number>());

                          const dependentSets = Array.from(
                            currentDevice?.sets?.values() ?? []
                          ).filter((set) => dependentSetIds.has(set?.setID));

                          if (dependentSets.length > 0) {
                            return (
                              <>
                                <Mui.Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Dependent articles based on your selected
                                  license
                                </Mui.Typography>
                                <Mui.Box
                                  style={{
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                    padding: theme.spacing(2, 0),
                                  }}
                                >
                                  {Array.from(dependentSets.values()).map(
                                    (set) => (
                                      <Mui.Box
                                        style={{
                                          minWidth: 300,
                                          maxWidth: 300,
                                          width: "100%",
                                          marginRight: theme.spacing(3),
                                          display: "inline-block",
                                        }}
                                        key={`Article#${set.setID}`}
                                      >
                                        <Article.Card
                                          style={{
                                            minWidth: "100%",
                                            backgroundColor:
                                              theme.palette.background.default,
                                          }}
                                          functions={functionStore.functions.filter(
                                            (func) =>
                                              set.functions.includes(
                                                func.setFunctionID
                                              )
                                          )}
                                          article={set}
                                          variant={"DETAILED"}
                                        />
                                      </Mui.Box>
                                    )
                                  )}
                                </Mui.Box>
                              </>
                            );
                          }
                        })()}
                      </>
                    }
                    actions={
                      <>
                        <Mui.Button
                          variant="contained"
                          onClick={() => {
                            setDeactivationDialogOpen(false);
                            setDetailsDrawerOpenDevice(true);
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Cancel">
                            Cancel
                          </ReactI18next.Trans>
                        </Mui.Button>
                        <Mui.Button
                          variant="contained"
                          onClick={() => handleDeactivate()}
                          disabled={isLoading}
                          {...(!isLoading && {
                            style: {
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.common.white,
                            },
                          })}
                        >
                          <ReactI18next.Trans i18nKey="Deactivate">
                            Deactivate
                          </ReactI18next.Trans>
                          {isLoading && (
                            <Mui.CircularProgress
                              style={{
                                marginLeft: theme.spacing(1),
                              }}
                              color={"inherit"}
                              size={theme.typography.button.fontSize}
                            />
                          )}
                        </Mui.Button>
                      </>
                    }
                  />
                  <CustomerDetail.DetailsDevice
                    open={detailsDrawerOpenDevice}
                    handleClose={toggleDetailsDrawerDevice}
                    device={currentDevice}
                    handleDeactivateAll={handleDeactivateAll}
                    handleDeactivateSelected={handleDeactivateSelected}
                  />
                  <Mui.Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    <Mui.FormControl style={{ width: 300 }}>
                      {easyTouchDeviceStore.filtered.length > 0 && (
                        <Mui.TextField
                          value={searchOrderDevice}
                          label={t("Search")}
                          onChange={handleSearchDevice}
                          autoFocus={searchOrderDevice.length > 0}
                        />
                      )}
                    </Mui.FormControl>
                  </Mui.Grid>
                  <Mui.Grid container spacing={3}>
                    {easyTouchDeviceStore.isInitialLoading &&
                      easyTouchDeviceStore.isLoading ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        {easyTouchDeviceStore.filtered.length === 0 && (
                          <Mui.Grid item xs={12}>
                            <MuiLab.Alert
                              severity="info"
                              style={{ width: "100%" }}
                            >
                              <MuiLab.AlertTitle>
                                <ReactI18next.Trans i18nKey="No terminal devices found">
                                  No terminal devices found
                                </ReactI18next.Trans>
                              </MuiLab.AlertTitle>
                              <ReactI18next.Trans i18nKey="There are no terminal device(s) with installed">
                                There are no terminal device(s) with installed
                                license(s) found for you! Please try installing
                                your purchased license(s) on your terminal
                                device(s) or reach out to KERN admin.
                              </ReactI18next.Trans>
                            </MuiLab.Alert>
                          </Mui.Grid>
                        )}

                        {easyTouchDeviceStore.filtered.map(
                          (
                            device: Models.Devices & {
                              sets: Set<Models.Set.Article>;
                            }
                          ) => (
                            <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={4}>
                              <CustomerDetail.CardDevice
                                device={device}
                                handleClick={(event: any) => {
                                  licenseStore.resetSelectedArticlesForDeactivation();
                                  setCurrentDevice(device);
                                  toggleDetailsDrawerDevice();
                                }}
                              />
                            </Mui.Grid>
                          )
                        )}
                      </>
                    )}
                  </Mui.Grid>
                </TabPanel>
              </SwipeableViews>
            </div>
          </Mui.Grid>
          :
          <Mui.Grid xs={12}>
            <Mui.Divider style={{ margin: "6px 6px 0 6px" }} />
            <div className={classes.root}>
              <Mui.AppBar
                position="static"
                color="transparent"
                style={{ margin: "0 4px 0 4px" }}
              >
                <Mui.Tabs
                  value={value}
                  onChange={handleChangeTab2}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="full width tabs example"
                >
                  <Mui.Tab label={t("Orders")} {...a12yProps(0)} />
                  {orderStore.orders.length > 0 && (
                    <Mui.Tab label={t("Licenses")} {...a12yProps(1)} />
                  )}
                  {orderStore.orders.length > 0 && (
                    <Mui.Tab label={t("Terminal devices")} {...a12yProps(2)} />
                  )}
                 
                </Mui.Tabs>
              </Mui.AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex2}
              >
                <TabPanel value={value} index={0}>
                  <>
                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width={1}
                      height={48}
                      marginBottom={4}
                    >
                      <Mui.Box display="flex" alignItems="baseline">
                        <Mui.FormControl style={{ width: 300 }}>
                          {orderStore.orders.length > 0 && (
                            <Mui.TextField
                              type="text"
                              value={searchOrder}
                              label={t("Search")}
                              onChange={handleSearchOrder}
                              autoFocus={searchOrder.length > 0}
                            />
                          )}
                        </Mui.FormControl>
                      </Mui.Box>
                      <Mui.Box>
                        <Mui.Tooltip title="Card View" placement="top">
                          <Mui.Box display="inline-block" color="divider">
                            <Mui.IconButton
                              color={
                                orderStore.view === "CARD"
                                  ? "default"
                                  : "inherit"
                              }
                              onClick={() =>
                                orderStore.setView({ view: "CARD" })
                              }
                              style={{ marginRight: theme.spacing(1) }}
                            >
                              <i className="icon-etc-card-view" />
                            </Mui.IconButton>
                          </Mui.Box>
                        </Mui.Tooltip>

                        <Mui.Tooltip title="Tabular View" placement="top">
                          <Mui.Box display="inline-block" color="divider">
                            <Mui.IconButton
                              color={
                                orderStore.view === "TABULAR"
                                  ? "default"
                                  : "inherit"
                              }
                              onClick={() =>
                                orderStore.setView({ view: "TABULAR" })
                              }
                            >
                              <i className="icon-etc-table-view" />
                            </Mui.IconButton>
                          </Mui.Box>
                        </Mui.Tooltip>
                      </Mui.Box>
                    </Mui.Box>
                    {orderStore.isInitialLoading ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        {orderStore.view === "CARD" && (
                          <CustomerDetail.Views.Cards
                            orders={orderStore.orders.filter((order) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                order?.orderCode
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userFirstName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userLastName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.companyName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase())
                              );
                            })}
                          />
                        )}

                        {orderStore.view === "TABULAR" && (
                          <EnhancedTable
                            rows={orderStore.orders.filter((order) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                order?.orderCode
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userFirstName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userLastName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.companyName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase())
                              );
                            })}
                            cid={searchParam}
                          />
                        )}
                      
                      </>
                    )}
                  </>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <>
                    <CustomerDetail.DetailsLicense
                      open={detailsDrawerOpen}
                      handleClose={toggleDetailsDrawer}
                      functionData={functionStore.functions}
                      license={currentLicense}
                    />
                    <Mui.Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginBottom: theme.spacing(3) }}
                    >
                      <Mui.FormControl
                        style={{ width: 300, marginRight: theme.spacing(2) }}
                      >
                        {removingLicenseDuplication.length > 0 && (
                          <>
                            <Mui.InputLabel id="demo-simple-select-outlined-label">
                              <ReactI18next.Trans i18nKey="Filter by status">
                                Filter by status
                              </ReactI18next.Trans>
                            </Mui.InputLabel>
                            <Mui.Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={licenseStore.status}
                              onChange={handleStatusFilterChange}
                              label={t("Filter by status")}
                            >
                              <Mui.MenuItem value="">
                                <em>
                                  <ReactI18next.Trans i18nKey="None">
                                    None
                                  </ReactI18next.Trans>
                                </em>
                              </Mui.MenuItem>

                              <Mui.MenuItem value={1}>
                                <Components.WorkingStatus
                                  status={1}
                                  labels={{ 1: "Available", 2: "Used" }}
                                />
                              </Mui.MenuItem>

                              <Mui.MenuItem value={2}>
                                <Components.WorkingStatus
                                  status={2}
                                  labels={{ 1: "Available", 2: "Used" }}
                                />
                              </Mui.MenuItem>
                            </Mui.Select>
                          </>
                        )}
                      </Mui.FormControl>
                      {removingLicenseDuplication.length > 0 && (
                        <Mui.FormControl style={{ width: 300 }}>
                          <Mui.TextField
                            value={searchOrderLicense}
                            label={t("Search")}
                            onChange={handleSearchLicense}
                            autoFocus={searchOrderLicense.length > 0}
                          />
                        </Mui.FormControl>
                      )}
                    </Mui.Grid>
                    <Mui.Grid container spacing={3}>
                      {licenseStore.isInitialLoading  &&
                        licenseStore.isLoading ? (
                        <Mui.Box
                          padding={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={"100%"}
                          minHeight={"100%"}
                          maxHeight={"100%"}
                          height={400}
                          flexDirection="column"
                        >
                          <Mui.CircularProgress />
                        </Mui.Box>
                      ) : (
                        <>
                          {removingLicenseDuplication.length === 0 && (
                            <Mui.Grid
                              container
                              style={{ padding: "60px 0 60px 0" }}
                            >
                              <Mui.Grid item xs={12}>
                                <MuiLab.Alert severity="info">
                                  <ReactI18next.Trans i18nKey="No licenses found">
                                    No licenses found
                                  </ReactI18next.Trans>
                                </MuiLab.Alert>
                              </Mui.Grid>
                            </Mui.Grid>
                          )}
                          {removingLicenseDuplication.map((device: any) => (
                            <Mui.Grid key={uuidv4()} item xs={12} md={6}>
                              <CustomerDetail.CardLicense
                                license={{
                                  ...device,
                                  setFuncstionsList:
                                    functionStore.functions.filter((func) =>
                                      device.setList?.functions.includes(
                                        func.setFunctionID
                                      )
                                    ),
                                }}
                                handleClick={() => {
                                  setCurrentLicense(device);
                                  toggleDetailsDrawer();
                                }}
                              />
                            </Mui.Grid>
                          ))}
                        </>
                      )}
                    </Mui.Grid>
                  </>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Components.ResponsiveFullscreenDialogWithScroll
                    open={deactivationDialogOpen}
                    title={"Deactivate License(s)"}
                    handleClose={() => {}}
                    contentText={
                      <>
                        {(function () {
                          if (
                            currentDevice?.sets.size ===
                            licenseStore.selectedArticlesForDeactivation.size
                          ) {
                            return null;
                          }

                          const dependentSetIds = Array.from(
                            currentDevice?.sets?.values() ?? []
                          )
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .reduce((acc, set) => {
                              Array.from(currentDevice?.sets?.values() ?? [])
                                .filter(
                                  (set) =>
                                    !licenseStore.selectedArticlesForDeactivation.has(
                                      set?.setID
                                    )
                                )
                                .forEach((otherSet) => {
                                  if (
                                    otherSet.dependentSets.includes(
                                      set.setID
                                    ) &&
                                    licenseStore.selectedArticlesForDeactivation.has(
                                      otherSet.setID
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    otherSet.dependentSets.includes(set.setID)
                                  ) {
                                    acc.add(otherSet.setID);
                                  }
                                });
                              return acc;
                            }, new Set<number>());

                          if (dependentSetIds.size > 0) {
                            return (
                              <MuiLab.Alert
                                severity="warning"
                                style={{ marginBottom: theme.spacing(1.5) }}
                              >
                                <MuiLab.AlertTitle>
                                  Proceed with caution!
                                </MuiLab.AlertTitle>
                                Based on your selected license we found the
                                following dependent articles. The following
                                dependent articles will also be deactivated if
                                you proceed.
                              </MuiLab.Alert>
                            );
                          }
                        })()}

                        <Mui.Typography gutterBottom>
                          Are you sure you want to deactivate the following
                          articles for this device with{" "}
                          <strong>Machine ID - {currentDevice?.macID}</strong>?
                        </Mui.Typography>

                        <Mui.Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginTop: theme.spacing(0.5) }}
                        >
                          Selected license
                        </Mui.Typography>
                        <Mui.Box
                          style={{
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            padding: theme.spacing(2, 0),
                          }}
                        >
                          {Array.from(currentDevice?.sets?.values() ?? [])
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .map((set) => (
                              <Mui.Box
                                style={{
                                  minWidth: 300,
                                  maxWidth: 300,
                                  width: "100%",
                                  marginRight: theme.spacing(3),
                                  display: "inline-block",
                                }}
                                key={`Article#${set.setID}`}
                              >
                                <Article.Card
                                  style={{
                                    minWidth: "100%",
                                    backgroundColor:
                                      theme.palette.background.default,
                                  }}
                                  functions={functionStore.functions.filter(
                                    (func) =>
                                      set.functions.includes(func.setFunctionID)
                                  )}
                                  article={set}
                                  variant={"DETAILED"}
                                />
                              </Mui.Box>
                            ))}
                        </Mui.Box>
                        {(function () {
                          if (
                            currentDevice?.sets.size ===
                            licenseStore.selectedArticlesForDeactivation.size
                          ) {
                            return null;
                          }

                          const dependentSetIds = Array.from(
                            currentDevice?.sets?.values() ?? []
                          )
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .reduce((acc, set) => {
                              Array.from(currentDevice?.sets?.values() ?? [])
                                .filter(
                                  (set) =>
                                    !licenseStore.selectedArticlesForDeactivation.has(
                                      set?.setID
                                    )
                                )
                                .forEach((otherSet) => {
                                  if (
                                    otherSet.dependentSets.includes(
                                      set.setID
                                    ) &&
                                    licenseStore.selectedArticlesForDeactivation.has(
                                      otherSet.setID
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    otherSet.dependentSets.includes(set.setID)
                                  ) {
                                    acc.add(otherSet.setID);
                                  }
                                });
                              return acc;
                            }, new Set<number>());

                          const dependentSets = Array.from(
                            currentDevice?.sets?.values() ?? []
                          ).filter((set) => dependentSetIds.has(set?.setID));

                          if (dependentSets.length > 0) {
                            return (
                              <>
                                <Mui.Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Dependent articles based on your selected
                                  license
                                </Mui.Typography>
                                <Mui.Box
                                  style={{
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                    padding: theme.spacing(2, 0),
                                  }}
                                >
                                  {Array.from(dependentSets.values()).map(
                                    (set) => (
                                      <Mui.Box
                                        style={{
                                          minWidth: 300,
                                          maxWidth: 300,
                                          width: "100%",
                                          marginRight: theme.spacing(3),
                                          display: "inline-block",
                                        }}
                                        key={`Article#${set.setID}`}
                                      >
                                        <Article.Card
                                          style={{
                                            minWidth: "100%",
                                            backgroundColor:
                                              theme.palette.background.default,
                                          }}
                                          functions={functionStore.functions.filter(
                                            (func) =>
                                              set.functions.includes(
                                                func.setFunctionID
                                              )
                                          )}
                                          article={set}
                                          variant={"DETAILED"}
                                        />
                                      </Mui.Box>
                                    )
                                  )}
                                </Mui.Box>
                              </>
                            );
                          }
                        })()}
                      </>
                    }
                    actions={
                      <>
                        <Mui.Button
                          variant="contained"
                          onClick={() => {
                            setDeactivationDialogOpen(false);
                            setDetailsDrawerOpenDevice(true);
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Cancel">
                            Cancel
                          </ReactI18next.Trans>
                        </Mui.Button>
                        <Mui.Button
                          variant="contained"
                          onClick={() => handleDeactivate()}
                          disabled={isLoading}
                          {...(!isLoading && {
                            style: {
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.common.white,
                            },
                          })}
                        >
                          <ReactI18next.Trans i18nKey="Deactivate">
                            Deactivate
                          </ReactI18next.Trans>
                          {isLoading && (
                            <Mui.CircularProgress
                              style={{
                                marginLeft: theme.spacing(1),
                              }}
                              color={"inherit"}
                              size={theme.typography.button.fontSize}
                            />
                          )}
                        </Mui.Button>
                      </>
                    }
                  />
                  <CustomerDetail.DetailsDevice
                    open={detailsDrawerOpenDevice}
                    handleClose={toggleDetailsDrawerDevice}
                    device={currentDevice}
                    handleDeactivateAll={handleDeactivateAll}
                    handleDeactivateSelected={handleDeactivateSelected}
                  />
                  <Mui.Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    <Mui.FormControl style={{ width: 300 }}>
                      {easyTouchDeviceStore.filtered.length > 0 && (
                        <Mui.TextField
                          value={searchOrderDevice}
                          label={t("Search")}
                          onChange={handleSearchDevice}
                          autoFocus={searchOrderDevice.length > 0}
                        />
                      )}
                    </Mui.FormControl>
                  </Mui.Grid>
                  <Mui.Grid container spacing={3}>
                    {easyTouchDeviceStore.isInitialLoading ||
                    ((userStore.user?.RoleName === "admin" ||
                      userStore.user?.RoleName === "superadmin") &&
                      easyTouchDeviceStore.isLoading) ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        {easyTouchDeviceStore.filtered.length === 0 && (
                          <Mui.Grid item xs={12}>
                            <MuiLab.Alert
                              severity="info"
                              style={{ width: "100%" }}
                            >
                              <MuiLab.AlertTitle>
                                <ReactI18next.Trans i18nKey="No terminal devices found">
                                  No terminal devices found
                                </ReactI18next.Trans>
                              </MuiLab.AlertTitle>
                              <ReactI18next.Trans i18nKey="There are no terminal device(s) with installed">
                                There are no terminal device(s) with installed
                                license(s) found for you! Please try installing
                                your purchased license(s) on your terminal
                                device(s) or reach out to KERN admin.
                              </ReactI18next.Trans>
                            </MuiLab.Alert>
                          </Mui.Grid>
                        )}

                        {easyTouchDeviceStore.filtered.map(
                          (
                            device: Models.Devices & {
                              sets: Set<Models.Set.Article>;
                            }
                          ) => (
                            <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={4}>
                              <CustomerDetail.CardDevice
                                device={device}
                                handleClick={(event: any) => {
                                  licenseStore.resetSelectedArticlesForDeactivation();
                                  setCurrentDevice(device);
                                  toggleDetailsDrawerDevice();
                                }}
                              />
                            </Mui.Grid>
                          )
                        )}
                      </>
                    )}
                  </Mui.Grid>
                </TabPanel>
              </SwipeableViews>
            </div>
          </Mui.Grid>}
        </>
      )}
    </>
  );
});
