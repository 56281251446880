import * as Tenant from "src/app/pages/tenant";
import * as Services from "src/services";
import * as Stores from "src/stores";
import * as Yup from "yup";

export declare namespace Shared {
  export interface Props {
    t(key: string): string;
    tenantService: Services.Tenant;
    tenantStore: Tenant.Store;
    userStore: Stores.User;
  }
}

// eslint-disable-next-line no-redeclare
export function Shared({
  t,
  tenantService,
  tenantStore,
  userStore,
}: Shared.Props) {
  return {
    Company: {
      Form: {
        validationSchema: Yup.object<Tenant.CompanyForm.Values>({
          ...((userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin") &&
            ({
              userId: Yup.string()
                .nullable()
                .required(t("Customer is required")),
            } as any)),
          companyAliasName: Yup.string()
            .nullable()
            .required(t("Company name is required"))
            .test("companyAliasName", "Same company name exists", function (
              value
            ) {
              return new Promise<boolean>((resolve, reject) => {
                tenantService
                  .isCompanyNameAlreadyExists({
                    companyName: value as string,
                  })
                  .then((result) => {
                    resolve(!result.result);
                  })
                  .catch((error) => resolve(true));
              });
            }),
        }),
      },
    },
    Admin: {
      Account: {
        Form: {
          validationSchema: Yup.object<Tenant.AdminAccountForm.Values>({
            firstName: Yup.string()
              .nullable()
              .required("First name is required"),
            lastName: Yup.string().nullable().required("Last name is required"),
            userName: Yup.string()
              .nullable()
              .required(t("User name is required"))
              .test("userName", "Same user name exists", function (value) {
                return new Promise<boolean>((resolve, reject) => {
                  resolve(true);
                
                });
              }),
            password: Yup.string()
              .nullable()
              .required(t("Password is required")),
            confirmPassword: Yup.string()
              .nullable()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required(t("Confirm password is required")),
          }),
        },
      },
    },
  };
}
