import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as FormikMuiLab from "formik-material-ui-lab";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Containers from "src/app/containers";
import * as Tenant from "src/app/pages/tenant";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";

export declare namespace CompanyForm {
  export interface Values {
    userId?: number;
    companyAliasName: string;
  }

  export interface Props {
    activeStep: number;
    handleContinue(): void;
  }
}

// eslint-disable-next-line no-redeclare
export function CompanyForm({ activeStep, handleContinue }: CompanyForm.Props) {
  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const [customerOptions, setCustomerOptions] = React.useState<
    Models.Customer.Main[]
  >([]);

  const tenantService = new Services.Tenant({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const customerService = new Services.Customer({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const globalTenantStore = React.useContext(App.Contexts.globalTenantStore);
  const navigate = Router.useNavigate();

  function handleSubmit(
    values: CompanyForm.Values,
    formikHelpers: Formik.FormikHelpers<CompanyForm.Values>
  ) {
    tenantStore.setCompanyName({ companyAliasName: values.companyAliasName });
    tenantStore.setEtuid({
      etuid: ((values.userId as unknown) as Models.Customer.Main)
        .etuid as number,
    });
    handleContinue();
  }

  React.useEffect(() => {
    if (activeStep !== 0) {
      return;
    }

    const userRole = userStore.user?.RoleName;

    if (userRole !== "customer") {
      return;
    }

    if (!globalTenantStore.isCloudPurchased || globalTenantStore.tenant) {
      navigate("/dashboard");
    }
  }, [
    activeStep,
    globalTenantStore.isCloudPurchased,
    globalTenantStore.tenant,
    navigate,
    userStore.user,
  ]);

  React.useEffect(() => {
    customerService.getAllData().then((data) => {
      setCustomerOptions(data.result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik.Formik<Tenant.CompanyForm.Values>
        initialValues={{ userId: undefined, companyAliasName: "" }}
        validationSchema={
          Tenant.Shared({ t, tenantService, tenantStore, userStore }).Company
            .Form.validationSchema
        }
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Formik.Form>
            <Mui.Box minHeight={275}>
              <Mui.Grid container spacing={3}>
                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Grid item xs={12}>
                    <Formik.Field
                      style={{ width: "50%" }}
                      component={FormikMuiLab.Autocomplete}
                      name="userId"
                      fullWidth
                      options={customerOptions}
                      getOptionLabel={(option: Models.Customer.Main) =>
                        option?.companyName ??
                        `${option?.firstName} ${option?.lastName}`
                      }
                      renderOption={(option: Models.Customer.Main) => (
                        <Mui.Box
                          key={uuidv4()}
                          display="flex"
                          alignItems="baseline"
                        >
                          <Mui.Typography variant="body1">
                            {option?.companyName ??
                              `${option?.firstName} ${option?.lastName}`}
                          </Mui.Typography>
                        </Mui.Box>
                      )}
                      renderInput={(
                        params: FormikMuiLab.AutocompleteRenderInputParams
                      ) => (
                        <Mui.TextField
                          {...params}
                          name="userId"
                          error={
                            touched["userId"] && errors["userId"] ? true : false
                          }
                          helperText={touched["userId"] && errors["userId"]}
                          label={
                            <ReactI18next.Trans i18nKey={"Select customer"}>
                              Select customer
                            </ReactI18next.Trans>
                          }
                        />
                      )}
                    />
                  </Mui.Grid>
                </Containers.ShowIfAuthorised>

                <Mui.Grid item xs={12}>
                  <Formik.Field
                    style={{ maxWidth: "50%" }}
                    type="text"
                    name="companyAliasName"
                    component={FormikMui.TextField}
                    label={t("Please enter the name of your company")}
                    fullWidth
                    autoFocus
                  />
                </Mui.Grid>

                <Mui.Grid item xs={12}>
                  <Mui.Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <ReactI18next.Trans i18nKey="The setup will be creating">
                    The setup will be creating the following database for your instance
                      </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography>
                    {`${
                      values.companyAliasName
                        ? values.companyAliasName.split(" ").join("_")
                        : "<company_name>"
                    }_ETDB`}
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12}>
                  <Mui.Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <ReactI18next.Trans i18nKey="You can access your ET Cloud">
                    You can access your ET Cloud admin portal from the following URL, once it is ready
                      </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Link>
                    {`${Configs.ET_CLOUD?.BASE_URL}/${
                      values.companyAliasName
                        ? values.companyAliasName
                            .split(" ")
                            .join("_")
                            .toLowerCase()
                        : "<company_name>"
                    }`}
                  </Mui.Link>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Box>
            <Mui.DialogActions
              style={{ padding: 0, marginTop: theme.spacing(4) }}
            >
              <Mui.Button type="submit" variant="contained" color="primary">
              <ReactI18next.Trans i18nKey="Continue">
              Continue
                      </ReactI18next.Trans>
              </Mui.Button>
            </Mui.DialogActions>
          </Formik.Form>
        )}
      </Formik.Formik>
    </>
  );
}
