import * as Mui from "@material-ui/core";
import * as Themes from "src/themes";

export declare namespace Props {
  export interface Props extends Themes.Global.Overrides.Props {}
}

// eslint-disable-next-line no-redeclare
export function Props({
  palette,
  theme,
  themeMode = "light",
}: Props.Props): Pick<Mui.ThemeOptions, "props"> {
  return {
    props: {
      MuiSelect: {
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        },
      },
      ...(themeMode === "light" && {
        MuiPaper: {
          elevation: 25,
        },
        MuiCard: {
          elevation: 25,
        },
      }),
    },
  };
}
