import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Article from "src/app/pages/article";
import * as EasyTouchDevice from "src/app/pages/easy-touch-device";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    link: {
      display: "flex",
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

export const Main = Mobx.observer(function () {
  const [searchParams] = Router.useSearchParams();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [counter, setCounter] = React.useState<number>(0);
  const [deactivationDialogOpen, setDeactivationDialogOpen] = React.useState<
    boolean
  >(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState<boolean>(
    false
  );
  const [currentDevice, setCurrentDevice] = React.useState<
    Models.Devices & { sets: Set<Models.Set.Article> }
  >();
  const { t } = ReactI18next.useTranslation();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const customerStore = React.useContext(App.Contexts.customerStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const easyTouchDeviceStore = React.useContext(
    App.Contexts.easyTouchDeviceStore
  );
  const classes = useStyles();
  const theme = Mui.useTheme();
  const navigate = Router.useNavigate();
  const isFromManageOrder = searchParams.get("isFromManageOrder")
  React.useEffect(() => {
    const macId = searchParams.get("macId");
    if (!macId) {
      return;
    }
    if (!easyTouchDeviceStore.devices) {
      return;
    }
    const device = easyTouchDeviceStore.devices.find(
      (device) => device.macID === macId
    );
    if (!device) {
      return;
    }
    if (device) {
      Array.from(licenseStore.selectedArticlesForDeactivation.values()).forEach(
        (setID) => {
          licenseStore.toggleArticleSelectionForDeactivation({ setID });
        }
      );
      device?.sets?.forEach((set) => {
        licenseStore.toggleArticleSelectionForDeactivation({
          setID: set?.setID,
        });
      });
      setDetailsDrawerOpen(true);
      setCurrentDevice(device);
      licenseStore.setCurrentDevice(device);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [easyTouchDeviceStore.devices]);

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;

    if (userRole === "customer") {
      return;
    }

    const customer = searchParams.get("customer");

    if (!customer) {
      return;
    }

    customerChange(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerChange = (customer: number | string) => {
    const uid = userStore.user?.ETUID;

    easyTouchDeviceStore.setCustomer({ customer });

    if (uid === undefined || uid === null) {
      return;
    }

    if (customer === "" || customer === undefined || customer === null) {
      return;
    }

    easyTouchDeviceStore.read({
      body: { status: null, userID: customer },
      userRole: "admin",
      uid,
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const customer = event.target.value as string | number;
    customerChange(customer);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    easyTouchDeviceStore.setSearch({ search: event.target.value as string });
  };

  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  React.useEffect(() => {
    functionStore.read();

    const userRole = userStore.user?.RoleName ?? ("customer" || "EU");
    const uid = userStore.user?.ETUID;

    if (userRole === "admin" || userRole === "superadmin") {
      customerStore.read({});
    }
    if (
      (userRole === "customer" || userRole === "EU") &&
      uid !== undefined &&
      uid !== null
    ) {
      easyTouchDeviceStore.read({ userRole: "customer", status: -1, uid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDeactivateAll() {
    Array.from(licenseStore.selectedArticlesForDeactivation.values()).forEach(
      (setID) => {
        licenseStore.toggleArticleSelectionForDeactivation({ setID });
      }
    );
    currentDevice?.sets.forEach(({ setID }) => {
      licenseStore.toggleArticleSelectionForDeactivation({ setID });
    });
    setDetailsDrawerOpen(false);
    setDeactivationDialogOpen(true);
  }

  function handleDeactivateSelected() {
    setDetailsDrawerOpen(false);
    setDeactivationDialogOpen(true);
  }

  async function handleDeactivate() {
    try {
      const userRole = userStore.user?.RoleName;
      const etuid = userStore.user?.ETUID;

      if (!etuid) {
        return;
      }

      if (!currentDevice) {
        return;
      }

      setIsLoading(true);

      const successAcknowledgement = () => {
        enqueueSnackbar(t("License(s) deactivated successfully."), {
          variant: "success",
          preventDuplicate: false,
        });

        const userRole = userStore.user?.RoleName;
        if (userRole === "admin" || userRole === "superadmin") {
          easyTouchDeviceStore.read({
            body: { status: null, userID: easyTouchDeviceStore.customer },
            userRole: "admin",
            uid: etuid,
          });
          return;
        }
        const orderID = localStorage.getItem("orderID");
        const overAllStatus = localStorage.getItem("overAllStatus");
        
        if(isFromManageOrder === "1"){
        navigate(`/Orders/manage-order?q=${orderID}&m=${overAllStatus}`);
       }
        easyTouchDeviceStore.read({
          userRole: "customer",
          status: -1,
          uid: etuid,
        });
      };

      if (
        userRole === "customer" &&
        licenseStore.selectedArticlesForDeactivation.size > 0 &&
        licenseStore.selectedArticlesForDeactivation.size ===
          currentDevice?.sets.size
      ) {
        await licenseStore.deactivateAll({
          body: {
            MacID: currentDevice.macID,
            UserId: etuid,
          },
        });
        successAcknowledgement();
        return;
      }

      if (
        (userRole === "admin" || userRole === "superadmin") &&
        easyTouchDeviceStore.customer &&
        licenseStore.selectedArticlesForDeactivation.size > 0 &&
        licenseStore.selectedArticlesForDeactivation.size ===
          currentDevice?.sets.size
      ) {
        await licenseStore.deactivateAll({
          body: {
            MacID: currentDevice.macID,
            UserId: easyTouchDeviceStore.customer as number,
          },
        });
        successAcknowledgement();
        return;
      }

      const dependentSetIds = Array.from(currentDevice?.sets?.values() ?? [])
        .filter((set) =>
          licenseStore.selectedArticlesForDeactivation.has(set?.setID)
        )
        .reduce((acc, set) => {
          Array.from(currentDevice?.sets?.values() ?? [])
            .filter(
              (set) =>
                !licenseStore.selectedArticlesForDeactivation.has(set?.setID)
            )
            .forEach((otherSet) => {
              if (
                otherSet.dependentSets.includes(set.setID) &&
                licenseStore.selectedArticlesForDeactivation.has(otherSet.setID)
              ) {
                return;
              }
              if (otherSet.dependentSets.includes(set.setID)) {
                acc.add(otherSet.setID);
              }
            });
          return acc;
        }, new Set<number>());

      const dependentSets = Array.from(
        currentDevice?.sets?.values() ?? []
      ).filter((set) => dependentSetIds.has(set?.setID));

      const selectedSets = Array.from(
        currentDevice.sets.values()
      ).filter((set) =>
        licenseStore.selectedArticlesForDeactivation.has(set.setID)
      );

      await licenseStore.deactivateSelected({
        body: {
          LicenseDetailsList: [...dependentSets, ...selectedSets].map((set) => {
            return {
              LicenseDetailID: (set as any)?.licenseDetailID,
              MacID: currentDevice.macID,
            };
          }),
        },
      });
      successAcknowledgement();
    } catch (error) {
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setDeactivationDialogOpen(false);
    }
  }

  return (
    <>
      <Components.ResponsiveFullscreenDialogWithScroll
        open={deactivationDialogOpen}
        title={"Deactivate License(s)"}
        handleClose={() => {}}
        contentText={
          <>
            {(function () {
              if (
                currentDevice?.sets.size ===
                licenseStore.selectedArticlesForDeactivation.size
              ) {
                return null;
              }

              const dependentSetIds = Array.from(
                currentDevice?.sets?.values() ?? []
              )
                .filter((set) =>
                  licenseStore.selectedArticlesForDeactivation.has(set?.setID)
                )
                .reduce((acc, set) => {
                  Array.from(currentDevice?.sets?.values() ?? [])
                    .filter(
                      (set) =>
                        !licenseStore.selectedArticlesForDeactivation.has(
                          set?.setID
                        )
                    )
                    .forEach((otherSet) => {
                      if (
                        otherSet.dependentSets.includes(set.setID) &&
                        licenseStore.selectedArticlesForDeactivation.has(
                          otherSet.setID
                        )
                      ) {
                        return;
                      }
                      if (otherSet.dependentSets.includes(set.setID)) {
                        acc.add(otherSet.setID);
                      }
                    });
                  return acc;
                }, new Set<number>());

              if (dependentSetIds.size > 0) {
                return (
                  <MuiLab.Alert
                    severity="warning"
                    style={{ marginBottom: theme.spacing(1.5) }}>
                    <MuiLab.AlertTitle>Proceed with caution!</MuiLab.AlertTitle>
                    Based on your selected license we found the following
                    dependent articles. The following dependent articles will
                    also be deactivated if you proceed.
                  </MuiLab.Alert>
                );
              }
            })()}

            <Mui.Typography gutterBottom>
              Are you sure you want to deactivate the following articles for
              this device with{" "}
              <strong>Machine ID - {currentDevice?.macID}</strong>?
            </Mui.Typography>

            <Mui.Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: theme.spacing(0.5) }}>
              Selected license
            </Mui.Typography>
            <Mui.Box
              style={{
                overflow: "auto",
                whiteSpace: "nowrap",
                padding: theme.spacing(2, 0),
              }}>
              {Array.from(currentDevice?.sets?.values() ?? [])
                .filter((set) =>
                  licenseStore.selectedArticlesForDeactivation.has(set?.setID)
                )
                .map((set) => (
                  <Mui.Box
                    style={{
                      minWidth: 300,
                      maxWidth: 300,
                      width: "100%",
                      marginRight: theme.spacing(3),
                      display: "inline-block",
                    }}
                    key={`Article#${set.setID}`}>
                    <Article.Card
                      style={{
                        minWidth: "100%",
                        backgroundColor: theme.palette.background.default,
                      }}
                      functions={functionStore.functions.filter((func) =>
                        set.functions.includes(func.setFunctionID)
                      )}
                      article={set}
                      variant={"DETAILED"}
                    />
                  </Mui.Box>
                ))}
            </Mui.Box>
            {(function () {
              if (
                currentDevice?.sets.size ===
                licenseStore.selectedArticlesForDeactivation.size
              ) {
                return null;
              }

              const dependentSetIds = Array.from(
                currentDevice?.sets?.values() ?? []
              )
                .filter((set) =>
                  licenseStore.selectedArticlesForDeactivation.has(set?.setID)
                )
                .reduce((acc, set) => {
                  Array.from(currentDevice?.sets?.values() ?? [])
                    .filter(
                      (set) =>
                        !licenseStore.selectedArticlesForDeactivation.has(
                          set?.setID
                        )
                    )
                    .forEach((otherSet) => {
                      if (
                        otherSet.dependentSets.includes(set.setID) &&
                        licenseStore.selectedArticlesForDeactivation.has(
                          otherSet.setID
                        )
                      ) {
                        return;
                      }
                      if (otherSet.dependentSets.includes(set.setID)) {
                        acc.add(otherSet.setID);
                      }
                    });
                  return acc;
                }, new Set<number>());

              const dependentSets = Array.from(
                currentDevice?.sets?.values() ?? []
              ).filter((set) => dependentSetIds.has(set?.setID));

              if (dependentSets.length > 0) {
                return (
                  <>
                    <Mui.Typography variant="body2" color="textSecondary">
                      Dependent articles based on your selected license
                    </Mui.Typography>
                    <Mui.Box
                      style={{
                        overflow: "auto",
                        whiteSpace: "nowrap",
                        padding: theme.spacing(2, 0),
                      }}>
                      {Array.from(dependentSets.values()).map((set) => (
                        <Mui.Box
                          style={{
                            minWidth: 300,
                            maxWidth: 300,
                            width: "100%",
                            marginRight: theme.spacing(3),
                            display: "inline-block",
                          }}
                          key={`Article#${set.setID}`}>
                          <Article.Card
                            style={{
                              minWidth: "100%",
                              backgroundColor: theme.palette.background.default,
                            }}
                            functions={functionStore.functions.filter((func) =>
                              set.functions.includes(func.setFunctionID)
                            )}
                            article={set}
                            variant={"DETAILED"}
                          />
                        </Mui.Box>
                      ))}
                    </Mui.Box>
                  </>
                );
              }
            })()}
          </>
        }
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => {
                setDeactivationDialogOpen(false);
                setDetailsDrawerOpen(true);
              }}>
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              onClick={() => handleDeactivate()}
              disabled={isLoading}
              {...(!isLoading && {
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                },
              })}>
              <ReactI18next.Trans i18nKey="Deactivate">
                Deactivate
              </ReactI18next.Trans>
              {isLoading && (
                <Mui.CircularProgress
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  color={"inherit"}
                  size={theme.typography.button.fontSize}
                />
              )}
            </Mui.Button>
          </>
        }
      />
     {userStore.user?.RoleName === "customer" ? <EasyTouchDevice.DeviceDetail
        open={detailsDrawerOpen}
        handleClose={toggleDetailsDrawer}
        device={currentDevice}
        handleDeactivateAll={handleDeactivateAll}
        handleDeactivateSelected={handleDeactivateSelected}
        licenseList={licenseStore.licenses}
        counter={counter}
      /> :  <EasyTouchDevice.Details
      open={detailsDrawerOpen}
      handleClose={toggleDetailsDrawer}
      device={currentDevice}
      handleDeactivateAll={handleDeactivateAll}
      handleDeactivateSelected={handleDeactivateSelected}
      licenseList={licenseStore.licenses}
      counter={counter}
    />}
      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}>
        <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
          <Mui.FormControl
            style={{ width: 300, marginRight: theme.spacing(2) }}>
            <Mui.InputLabel id="demo-simple-select-outlined-label">
              <ReactI18next.Trans i18nKey="Customer">
                Customer
              </ReactI18next.Trans>
            </Mui.InputLabel>
            <Mui.Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={easyTouchDeviceStore.customer}
              onChange={handleChange}
              label="Customer">
              <Mui.MenuItem value="">
                <em><ReactI18next.Trans i18nKey="None">None</ReactI18next.Trans></em>
              </Mui.MenuItem>
              <Mui.Divider />
              {customerStore.customers.map((customer) => (
                <Mui.MenuItem
                  key={uuidv4()}
                  style={{
                    display: "grid",
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                  value={customer.etuid}>
                  <Mui.Box className={classes.link}>
                    <MuiIcons.Person className={classes.icon} />
                    {customer.firstName} {customer.lastName}
                  </Mui.Box>
                  <Mui.Box className={classes.link} onClick={()=>{window.open(`mailto:${customer.userEmail}`)}}>
                    <MuiIcons.Email className={classes.icon} />
                    <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{customer.userEmail}</Mui.Link></Mui.Tooltip>

                  </Mui.Box>
                  {customer.companyName && (
                    <Mui.Box className={classes.link}>
                      <MuiIcons.Business className={classes.icon} />
                      {customer.companyName}
                    </Mui.Box>
                  )}
                  {customer.departmentName && (
                    <Mui.Box className={classes.link}>
                      <MuiIcons.Category className={classes.icon} />
                      {customer.departmentName}
                    </Mui.Box>
                  )}
                </Mui.MenuItem>
              ))}
            </Mui.Select>
          </Mui.FormControl>
        </Containers.ShowIfAuthorised>
      {!detailsDrawerOpen &&  <Mui.FormControl style={{ width: 300 }}>
        {easyTouchDeviceStore?.filtered.length > 0 && (
          <Mui.TextField label={t("Search")} onChange={handleSearch} />)}
        </Mui.FormControl>}
      </Mui.Grid>
      <Mui.Grid container spacing={3}>
        {easyTouchDeviceStore.isInitialLoading ||
        ((userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin" || userStore.user?.RoleName === "customer") &&
          easyTouchDeviceStore.isLoading) ? (
          <Mui.Box
            padding={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            minHeight={"100%"}
            maxHeight={"100%"}
            height={400}
            flexDirection="column">
            <Mui.CircularProgress />
          </Mui.Box>
        ) : (
         <>
            {easyTouchDeviceStore.showAdminCustomerSelectionInfo  && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                  <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="Select customer!">
                  Select customer!
                </ReactI18next.Trans></MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="Select a customer from the above dropdown">
                Select a customer from the above dropdown selection to view their terminal devices.
                </ReactI18next.Trans>
                </MuiLab.Alert>
              </Mui.Grid>
            )}

            {easyTouchDeviceStore.showAdminNoRecordsInfo && (
              <Mui.Grid item xs={12}>
               <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                  <MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="No terminal devices found">
                  No terminal devices found
                </ReactI18next.Trans></MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="There are no terminal devices found">
                There are no terminal devices found for the selected customer!
                Please try selecting a different customer to view their terminal devices.
                </ReactI18next.Trans>
                  
                </MuiLab.Alert>
              </Mui.Grid>
            )}

            {easyTouchDeviceStore.showCustomerNoRecordsInfo && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                  <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No terminal devices found">
                  No terminal devices found
                </ReactI18next.Trans></MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="There are no terminal device(s) with installed">
                There are no terminal device(s) with installed license(s) found for you!
                Please try installing your purchased license(s) on your terminal device(s) or reach out to KERN admin.
                </ReactI18next.Trans>
                  
                </MuiLab.Alert>              </Mui.Grid>
            )}

            { !detailsDrawerOpen && easyTouchDeviceStore.filtered.map(
              (device: Models.Devices & { sets: Set<Models.Set.Article> }) => (
                <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={4}>
                  <EasyTouchDevice.Card
                    device={device}
                    handleClick={(event: any) => {
                      setCounter(()=> counter + 1)
                      licenseStore.resetSelectedArticlesForDeactivation();
                      setCurrentDevice(device);
                      licenseStore.setCurrentDevice(device);
                      toggleDetailsDrawer();
                    }}
                  />
                </Mui.Grid>
              )
            )}
          </>
        )}
      </Mui.Grid>
    </>
  );
});
