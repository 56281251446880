/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as React from "react";
import * as ReactCookie from "react-cookie";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

export function Main() {
  const theme = Mui.useTheme();
  const [, setCookie, removeCookie] = ReactCookie.useCookies();
  const [order, setOrder] = React.useState<Models.Order.Main>();
  const [loader, setLoader] = React.useState<boolean>(false);

  const userStore = React.useContext(App.Contexts.userStore);
  const cartStore = React.useContext(App.Contexts.cartStore);
  const globalTenantStore = React.useContext(App.Contexts.globalTenantStore);
  const navigate = Router.useNavigate();
  const { t } = ReactI18next.useTranslation();

  React.useEffect(() => {
    const etuid = userStore.user?.ETUID;
    if (!etuid) {
      return;
    }

    globalTenantStore.read({ etuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async function () {
      try {
        const orderDetails = localStorage.getItem("orderDetails");
        const orderId = localStorage.getItem("orderDetails");
        if (!orderDetails && cartStore.cart.size > 0) {
          navigate("/shop/checkout");
          return;
        }
        const langCode = localStorage.getItem("i18nextLng")
        const etuid = userStore.user?.ETUID;
        if (!orderDetails && cartStore.cart.size === 0) {
          navigate("/shop");
          return;
        }

        cartStore.resetCart();
        const orderServices = new Services.Order({
          httpManager: new Services.Managers.Axios({ agent: App.axios }),
          urlFactory: new Services.Factories.URL({
            protocol: Constants.environment.apiBaseUrlProtocol as "http",
            hostname: Constants.environment.apiBaseUrlHostname,
            version: Constants.environment.apiBaseUrlVersion,
          }),
        });

        const { result: orders } = await orderServices.getData({
         body: { id: orderDetails!, cid: etuid },
        });
        const { result:any } =  await orderServices.PassOrderDataForMailTrigger({
          body: { eid: orderId, langCode: langCode },
        });
        const [order] = orders;
        // if(order.orderCode)
        // {
        //   const { result: orders } = await orderService.getData({
        //     body: { eid: orderDetails! },
        //   });
        // }
        setOrder(order);
      } catch (error) {
        console.error(error);
      }
    })();

    return () => {
      cartStore.resetCart();
      localStorage.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {loader && (<Mui.CircularProgress />)}
      <Mui.Box marginBottom={2}>
        <MuiLab.Alert severity="success">
          <MuiLab.AlertTitle>
            <ReactI18next.Trans i18nKey="Thank you for your order!">
              Thank you for your order!
            </ReactI18next.Trans>
          </MuiLab.AlertTitle>
          <ReactI18next.Trans i18nKey="Your order number is">
            Your order number is
          </ReactI18next.Trans>{" "}
          <Mui.Tooltip title={!order?.orderCode ? "Loading..." : ""}>
            <strong>
              {order?.orderCode ?? (
                <Mui.CircularProgress
                  style={{
                    width: theme.typography.body2.fontSize,
                    height: theme.typography.body2.fontSize,
                  }}
                />
              )}
              {". "}
            </strong>
          </Mui.Tooltip>
          <ReactI18next.Trans i18nKey="The order confirmation email will be sent to you shortly.">
            The order confirmation email will be sent to you shortly.
          </ReactI18next.Trans>
        </MuiLab.Alert>
      </Mui.Box>

      <Mui.Card>
        <Mui.CardHeader title={t("Your order")} />
        <Mui.CardContent>
          {order ? (
            <Components.OrderForSuccessPage
              {...{ order }}
              hideAddress
              hideHeader
              hideUserDetails
            />
          ) : (
            <Mui.Grid container justify="center" alignItems="center">
              <Mui.CircularProgress color="primary" />
            </Mui.Grid>
          )}

          <Mui.Box
            style={{
              maxWidth: "max-content",
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(4),
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {order?.orderDetails?.find(
              (item) =>
                Configs.Workaround.isCloudArticle({
                  code: item.sets.setCode,
                }) ||
                Configs.Workaround.isCloudArticle({
                  code: item.sets.hundCode,
                })
            ) &&
              (globalTenantStore.tenant === null ||
                globalTenantStore.tenant === undefined) && (
                <MuiLab.Alert
                  severity="warning"
                  action={
                    <Mui.Button
                      color="primary"
                      variant="contained"
                      size="small"
                      component={Router.Link}
                      to="/tenants/create"
                    >
                      Create Tenant
                    </Mui.Button>
                  }
                >
                  <MuiLab.AlertTitle>
                    <ReactI18next.Trans i18nKey="Immediate next step">
                      Immediate next step
                    </ReactI18next.Trans>
                  </MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="Since you have purchased the ET Cloud article">
                    Since you have purchased the ET Cloud article you'll have to create a tenant first to enjoy your purchased articles.
                  </ReactI18next.Trans>
                </MuiLab.Alert>
              )}
            <Containers.ShowIfAuthorised roles={["customer"]}>
            <MuiLab.Alert severity="info">
            {/* <Containers.ShowIfAuthorised roles={["customer"]}> */}
            <MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="Next step">
                  Next step
                </ReactI18next.Trans>
            </MuiLab.AlertTitle>
            <Mui.Link
              // variant="contained"
              color="primary"
              onClick={() =>{
                // userStore.logout({ axios: App.axios, setCookie, removeCookie })
                window.location.href = "https://www.microsoft.com/store/productId/9N5B88S9Q538"
              }
              }
            >
              <ReactI18next.Trans i18nKey="Now take me to the EASY TOUCH application to activate my licenses by using the KERN account">
              Now take me to the EASY TOUCH application to activate my licenses by using the KERN account
              </ReactI18next.Trans>.
            </Mui.Link>
            </MuiLab.Alert>
            </Containers.ShowIfAuthorised>         
          </Mui.Box>

          <Mui.Box display="flex" justifyContent="center">
         
          </Mui.Box>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );
}
