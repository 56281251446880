import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function AddLicense() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["customer","dealer","admin"]}
          path="/"
          element={<Pages.License.AddLicense.Main />}
        />
      </Router.Routes>
    </>
  );
}
