
import * as MuiIcons from "@material-ui/icons";
import * as Mui from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@material-ui/icons/Info";
import LanguageIcon from "@material-ui/icons/Language";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as CustomerInvite from "src/app/pages/dealercustomer/customerinvite";
import * as Models from "src/models";
import { Props } from "src/themes/global";
import * as Yup from "yup";
import * as Services from "src/services";
import * as Constants from "src/constants";
import SearchIcon from "@material-ui/icons/Search";

export interface FormProps {
  emailID: string;
  firstname?: string;
  lastname?: string;
  companyName?: string;
}

export const Main = Mobx.observer(function () {
  const navigate = Router.useNavigate();
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const [customerType, setCustomerType] = React.useState<any>(0);
  const [finame, setfiname] = React.useState<any>("");
  const [edit, setEdit] = React.useState<boolean>(false);
  const [nextActionColor, setNextActionColor] = React.useState<boolean>(true);
  const [isNextHide, setIsNextHide] = React.useState<boolean>(true);
  const [liname, setliname] = React.useState<any>("");
  const [mailID, setMail] = React.useState<any>("");
  const [emailID, setEmailID] = React.useState<any>("");
  const [custID, setcustID] = React.useState<any>("");
  const [isBasicSetFlag, setisBasicSetFlag] = React.useState<any>("");
  const [flagForField, setFlagForField] = React.useState<boolean>(false);
  const [
    ManageCustomerGridDisplay,
    setManageCustomerGridDisplay,
  ] = React.useState<boolean>(false);
  const [basicData, setBasicData] = React.useState("");
  const [ciname, setciname] = React.useState<any>("");
  const [mail, setmail] = React.useState<any>("");
  const [loader, setLoader] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [errormail, seterrormail] = React.useState<string>("");
  const [companyvalidationflag, setcompanyvalidationflag] = React.useState<
    boolean
  >(false);
  const [lastNameValidationflag, setLastNameValidationflag] = React.useState<
  boolean
>(false);
const [firstNameValidationflag, setFirstNameValidationflag] = React.useState<
  boolean
>(false);
  const [mailvalidationflag, setmailvalidationflag] = React.useState<boolean>(
    false
  );
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  const homeStore = React.useContext(App.Contexts.homeStore);
  React.useEffect(()=>{
    const userRole = "customer"
    const EmailId = mailID ? mailID : undefined;
    const uid = custID ? custID : "0";
    if ((uid !== undefined && uid !== null) && mailID) {
      homeStore.read({
        uid,
        userRole,
        EmailId,
      });
    }
  },[custID, homeStore, mailID])
  localStorage.setItem("customermailID", mailID);
  localStorage.setItem("customerID", custID);
  const userStore = React.useContext(App.Contexts.userStore);
  
  const dealerStore = React.useContext(App.Contexts.addDealerStore);
  const handleemail = (event: any) => {
    setmail(event.target.value);
    if (event.target.value.length === 0) {
      setfiname("");
      setliname("");
      setciname("");
      setmail("");
    }
   
    if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(event.target.value)) {
      setmailvalidationflag(false);
      seterrormail("");
      setNextActionColor(false)
    } else if (event.target.value === "") {
      setFlagForField(false)
      setmailvalidationflag(true);
      seterrormail("Email ID is required!");
      setNextActionColor(true)
    } else {
      setFlagForField(false)
      setmailvalidationflag(true);
      seterrormail("Please enter the valid Email ID");
      setNextActionColor(true)
    }
  };
  const params = new URLSearchParams(window.location.search);
  const parameter = params.toString();
  const manageParam = "q=fromManageCustomer";
  React.useEffect(() => {
    if (parameter === manageParam) {
      setManageCustomerGridDisplay(true);
    }
  }, [parameter]);
  React.useEffect(()=> {
    async function fetchMyAPI() {
      const value = userStore.user?.UserEmail;
      const res : any= await customerInviteStore.getCustomerDetails({
                EmailID: value,
             });
             const result: any = res?.result;
             if(result !== undefined && result.length > 0 && result[0].CustomerID !== undefined) {
              const isBasicSet = result.map((k: any) => k?.isBasicSetFlag) ?? "";
              setBasicData(isBasicSet)
             }    
    }
    fetchMyAPI()
    localStorage.setItem("BasicSetFromShop", "true")
  },[customerInviteStore, userStore.user])

  if(typeof basicData !== 'undefined' && basicData.length > 0 ){
    const strConvertBasic  = basicData?.toString()
    localStorage.setItem("Basicset", strConvertBasic)
  }
  const onBlur = React.useCallback(
    async (event) => {
      const value = mail;
      const etuid = userStore.user?.ETUID;
      if (transientName.current !== value) {
        transientName.current = value;

        // Do other on blur analytics stuff
      }
      try {
        if(mail.length > 0){
          const res = await customerInviteStore.getCustomerDetails({
            EmailID: value,
            ETUID: etuid ? etuid : '0',
          });
          const result: any = res?.result;
        const lengthCheckOfMailResponse = result.length;
          setLoader(true)
          if(result.length === 0){
          setIsNextHide(false)
          }
        localStorage.setItem("responseOfCustMail", lengthCheckOfMailResponse);
        const emailID = result.map((k: any) => k.email);
        //
        const firstName = result.map((k: any) => k.firstName);
        const lastName = result.map((k: any) => k.lastName);
        
        
        const companyName = result.map((k: any) => k.companyName);
        const customerType = result.map((k: any) => k.type);
        const customerId = result.map((k: any) => k.etuid);
        const isBasicSet = result.map((k: any) => k.isBasicSetFlag);
        localStorage.setItem("customerType", customerType);
        if (result.length > 0) {
          setOpen(true);
        }
        var serachMailID = emailID[0];
        // 
        localStorage.setItem("Basicset", isBasicSet[0]);
        localStorage.setItem("Basicsetfromdealerflow", "true");
        setMail(serachMailID);
        setCustomerType(customerType);
        if(companyName == "" && firstName == "" && lastName == "" && mailvalidationflag == false){
          setFlagForField(true)
        }else{
          setFlagForField(false)
        }
       
       // This condition is for value will not be set when companyName ,firstName and lastName is empty
       setciname(companyName[0]);
          setfiname(firstName[0]);
          setliname(lastName[0]);
       
        setcustID(customerId);
        setisBasicSetFlag(isBasicSet[0]);
        localStorage.setItem("custID", custID);
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    [custID, customerInviteStore, mail, mailvalidationflag, userStore.user]
  );
  const [updateDialogOpen, setUpadateRoleDialogOpen] = React.useState<
    | {
        roles: number;
      }
    | undefined
  >();

  const functionStore = React.useContext(App.Contexts.functionStore);
  const [displayLabel, setDisplayLabel] = React.useState<boolean>(false);
  const [displayCustomerInfoSave, setDisplayCustomerInfoSave] = React.useState<
    boolean
  >(false);
  const [flagForExistingUser, setFlagForExistingUser] = React.useState<boolean>(
    false
  );
  const uid = userStore.user?.ETUID as number;
  const transientName = React.useRef("");
  const licenseStore = React.useContext(App.Contexts.license.store);
  const [inputdata, setInputdata] = React.useState([]);
  const validationSchema = Yup.object().shape<FormProps>({
    emailID: Yup.string().required("Email ID is required"),
  });
  const [selectionCompleteDialog,setSelectionCompleteDialog] = React.useState(false)
  const [searchParams] = Router.useSearchParams();

  const searchPara = searchParams.get("q");
  const emailExistingUser = searchParams.get("e");
  const idExistingUser = searchParams.get("ci");
  React.useEffect(()=>{
    (async function () {
    if(emailExistingUser != null && emailExistingUser.length > 0){
      const res = await customerInviteStore.getCustomerDetails({
        EmailID: emailExistingUser,
        ETUID: idExistingUser ? idExistingUser : '0',
      });
      const result: any = res?.result;
    const lengthCheckOfMailResponse = result.length;
      setLoader(true)
      if(result.length === 0){
      setIsNextHide(false)
      }
    localStorage.setItem("responseOfCustMail", lengthCheckOfMailResponse);
    const emailID = result.map((k: any) => k.email);
    const firstName = result.map((k: any) => k.firstName);
    const lastName = result.map((k: any) => k.lastName);
    const companyName = result.map((k: any) => k.companyName);
    const customerType = result.map((k: any) => k.type);
    const customerId = result.map((k: any) => k.etuid);
    const isBasicSet = result.map((k: any) => k.isBasicSetFlag);
    localStorage.setItem("customerType", customerType);
    if (result.length > 0) {
      setOpen(true);
    }
    var serachMailID = emailID[0];
    localStorage.setItem("Basicset", isBasicSet[0]);
    localStorage.setItem("Basicsetfromdealerflow", "true");
    setMail(serachMailID);
    setCustomerType(customerType);
    if(companyName == "" && firstName == "" && lastName == "" && mailvalidationflag == false){
      setFlagForField(true)
    }else{
      setFlagForField(false)
    }
   
      setciname(companyName[0]);
      setfiname(firstName[0]);
      setliname(lastName[0]);
      setmail(emailID[0]);
    setcustID(customerId);
    setisBasicSetFlag(isBasicSet[0]);
    localStorage.setItem("custID", custID);
    localStorage.setItem("cuName", firstName[0]);
    localStorage.setItem("coName", companyName[0]);
    localStorage.setItem("mID", emailID[0]);

    setOpen(false);
    setDisplayLabel(true);
    setDisplayCustomerInfoSave(true);
    }
  })();
  },[]);
  localStorage.setItem(
    "displayCustomerDetailCard",
    displayCustomerInfoSave.toString()
  );
  const handleLabel = (e: any) => {
 
    setDisplayLabel(true);
    setSelectionCompleteDialog(true);
    if(searchPara === "ManageOrder"){
      navigate(`/dealercustomer/addcustomer?m=${"ManageOrder"}`);
    }
    setIsNextHide(true)
  };
  const cartStore = React.useContext(App.Contexts.cartStore);
  
  const handleEditCsutomerInfo = (e: any) => {
    setNextActionColor(true)
    setIsNextHide(true)
    setFlagForField(false)
    setLoader(false)
    setManageCustomerGridDisplay(false);
    setmail("");
    setfiname("");
    setliname("");
    setciname("");
    setDisplayCustomerInfoSave(false);
    setFlagForExistingUser(false);
    setDisplayLabel(false);
    customerInviteStore.resetInviteSummary();
    cartStore.resetCart();
    let newSelected: number[] = [];
    customerInviteStore.setSelected({
      selected: newSelected,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setDisplayLabel(true);
    setDisplayCustomerInfoSave(true);
    if(searchPara === "ManageOrder"){
      navigate(`/dealercustomer/addcustomer?m=${"ManageOrder"}`);
    }
  };

  const handleNo = () => {
    setNextActionColor(true)
    setOpen(false);
    setmail("");
    setfiname("");
    setliname("");
    setciname("");
    setDisplayLabel(false);
  };
  const handlefirst = (e: any) => {
    setfiname(e.target.value);
    if (e.target.value.length === 0) {
      setFirstNameValidationflag(true);
    } else {
      setFirstNameValidationflag(false);
    }
  };
  const custIDs = localStorage.getItem("custID");
  // 
  const handlecompany = (e: any) => {
    setciname(e.target.value);
    if (e.target.value.length === 0) {
      setcompanyvalidationflag(true);
    } else {
      setcompanyvalidationflag(false);
    }
  };
  const handlelast = (e: any) => {
    setliname(e.target.value);
    if (e.target.value.length === 0) {
      setLastNameValidationflag(true);
    } else {
      setLastNameValidationflag(false);
    }
  };
  localStorage.setItem("cfname", finame);
  localStorage.setItem("clname", liname);
  localStorage.setItem("ccname", ciname);
  localStorage.setItem("mailId", mail);
  localStorage.setItem("custID", custID);
  const fName = localStorage.getItem("cfname");
  const lName = localStorage.getItem("clname");
  const cName = localStorage.getItem("ccname");
  const mailId = localStorage.getItem("mail");

  const navigationfName: any = localStorage.getItem("nfname");
  const navigationlName: any = localStorage.getItem("nlname");
  const navigationcName: any = localStorage.getItem("ncname");
  const navigationmailId: any = localStorage.getItem("nmailId");

  React.useEffect(() => {
    var assignLicenseNavigation = searchParams.get("isBack")
      ? searchParams.get("isBack")
      : false;
    if (assignLicenseNavigation == "true") {
      setmail(JSON.parse(navigationmailId));
      setfiname(JSON.parse(navigationfName));
      setliname(JSON.parse(navigationlName));
      setciname(JSON.parse(navigationcName));
      setDisplayLabel(true);
    } else if (assignLicenseNavigation === "existingUser") {
      setmail(JSON.parse(navigationmailId));
      setfiname(JSON.parse(navigationfName));
      setliname(JSON.parse(navigationlName));
      setciname(JSON.parse(navigationcName));
      setDisplayLabel(true);
    }
    if (assignLicenseNavigation === "existingUser") {
      setFlagForExistingUser(true);
    }
  }, [
    navigationcName,
    navigationfName,
    navigationlName,
    navigationmailId,
    searchParams,
  ]);

  const DarkerDisabledTextField = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "black", 
      },
    },
  })(Mui.TextField);
  var mid = localStorage.getItem("mID");
  const cuNAme = localStorage.getItem("cuName");
  const coName = localStorage.getItem("coName");
  React.useEffect(() => {
    if (parameter === manageParam) {
      setciname(coName);
      setmail(mid);
      setfiname(cuNAme);
      setliname(cuNAme);
      setciname(coName);
    }
  }, [coName, cuNAme, mid, parameter]);
 const articleStoreQuantityCheck  = customerInviteStore.getArticlesFromLicenses !== undefined ? customerInviteStore.getArticlesFromLicenses.map((article) => (article.availableQuantity )):[];
 
  return (
    <>
      <Mui.Paper>
        <Mui.Grid
          container
          spacing={2}
          style={{
            padding: "15px",
          }}
        >
          {ManageCustomerGridDisplay ? (
            <>
              <Mui.Grid xs={12} style={{ marginTop: "2px" }}>
                <Mui.Grid container>
                  <Mui.Grid item>
                    <MuiIcons.Business
                      style={{
                        width: "53px",
                        height: "53px",
                        color: "grey",
                        margin: "0px 17px 22px 33px",
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        margin: "12px 0px 10px 10px",
                        opacity: "0.8",
                      }}
                    >
                      {coName || ""}
                      {/* {data.companyName} */}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={12}>
                <Mui.Grid container>
                  <Mui.Grid item xs={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: theme.spacing(2),
                        margin: "0px 0 0 33px",
                        opacity: "0.8",
                      }}
                    >
                      Company details
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={3}
                    style={{ marginLeft: "80px" }}
                  ></Mui.Grid>
                </Mui.Grid>
                <Mui.Divider
                  style={{
                    marginTop: theme.spacing(1),
                  }}
                />
                <Mui.Grid container>
                  <Mui.Grid item xs={3}>
                    
                    <Mui.Grid
                      container
                      style={{
                        alignContent: "space-around",
                        justifyContent: "space-between",
                        margin: "0px 0 0 33px",
                      }}
                    >
                      <Mui.Grid item xs={12} md={6} lg={6} xl={6}>
                        <Mui.Typography
                          style={{
                            fontWeight: "normal",
                            height: "22px",
                            margin: "16px 0 0 0",
                            opacity: "0.5",
                            fontSize: "16px",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Email ID">
                        Email ID
                        </ReactI18next.Trans>
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                        <Mui.Typography
                          style={{
                            margin: "16px 0 0 0",
                          }}
                        >
                          {mid ?? mailID}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid
                      container
                      style={{
                        alignContent: "space-around",
                        justifyContent: "space-between",
                        margin: "0px 0 0 33px",
                      }}
                    >
                      <Mui.Grid item xs={12} md={6} lg={6} xl={6}>
                        <Mui.Typography
                          style={{
                            fontWeight: "normal",
                            width:theme.spacing(30),
                            height: "22px",
                            margin: "16px 0 0 0",
                            opacity: "0.5",
                            fontSize: "16px",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="First name">
                          First name
                        </ReactI18next.Trans>
                          
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                        <Mui.Typography
                          style={{
                            margin: "16px 0 0 0",
                          }}
                        >
                          {cuNAme}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                    
                  </Mui.Grid>
                  <Mui.Grid item xs={2}></Mui.Grid>
                  <Mui.Grid item xs={3}>
                    <Mui.Grid
                      container
                      style={{
                        alignContent: "space-around",
                        justifyContent: "space-between",
                      }}
                    ></Mui.Grid>
                    <Mui.Grid
                      container
                      style={{
                        alignContent: "space-around",
                        justifyContent: "space-between",
                      }}
                    >
                      <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                        <Mui.Typography
                          style={{
                            fontWeight: "normal",
                            width: "80px",
                            height: "22px",
                            margin: "16px 0 0 0",
                            opacity: "0.5",
                            fontSize: "16px",
                          }}
                        >
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                        <Mui.Typography style={{ margin: "14px 0 0 0" }}>
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid
                      container
                      style={{
                        alignContent: "space-around",
                        justifyContent: "space-between",
                      }}
                    >
                      <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                        <Mui.Typography
                          style={{
                            fontWeight: "normal",
                            width: "80px",
                            height: "22px",
                            margin: "16px 0 0 0",
                            opacity: "0.5",
                            fontSize: "16px",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Last name">
                          Last name
                        </ReactI18next.Trans>
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                        <Mui.Typography
                          style={{
                            margin: "14px 0 0 0",
                          }}
                        >
                          {cuNAme}
                          {/* {"Balingen,Germany"} */}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={12} style={{ marginTop: "2px" }}>
                <Mui.Button
                  variant="contained"
                  color="default"
                  onClick={handleEditCsutomerInfo}
                  style={{
                    marginRight: "24px",
                    width: "auto",
                    display: "block",
                    float: "right",
                    flexDirection: "row-reverse",
                  }}
                >
                  <ReactI18next.Trans i18nKey="Change customer">
                    Change customer
                  </ReactI18next.Trans>
                </Mui.Button>
                
              </Mui.Grid>
            </>
          ) : displayCustomerInfoSave ||
            (flagForExistingUser && !ManageCustomerGridDisplay) ? (
            <>
              <Mui.Grid xs={12} style={{ marginTop: "2px" }}>
                <Mui.Grid container>
                  <Mui.Grid item>
                    <MuiIcons.Business
                      style={{
                        width: "53px",
                        height: "53px",
                        color: "grey",
                        margin: "0px 17px 8px 33px",
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        margin: "12px 0px 0px 10px",
                        opacity: "0.8",
                      }}
                    >
                      {ciname || ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={12}>
                <Mui.Grid container>
                  <Mui.Grid item xs={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: theme.spacing(2),
                        margin: "0px 0 0 33px",
                        opacity: "0.8",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Company details">
                      Company details
                        </ReactI18next.Trans>
                      
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={3}
                    style={{ marginLeft: "80px" }}
                  ></Mui.Grid>
                </Mui.Grid>
                <Mui.Divider
                  
                />
                <Mui.Grid container>
              <Mui.Grid item xs={4}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} sm={8} md={3} lg={3} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        height: "22px",
                        width:theme.spacing(30),
                        margin: "14px 0 0px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                     <ReactI18next.Trans i18nKey="First name">
                      First name
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={8} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "14px 0 0 0",
                      }}
                    >
                    {finame}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={6}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        height: "22px",
                        margin: "16px 0 24px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                       <ReactI18next.Trans i18nKey="Email Id">
                      Email Id  
                      </ReactI18next.Trans>
                      
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "16px 0 24px 0",
                      }}
                    >
                     
                      {mail ?? mid}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
             
              </Mui.Grid>
              <Mui.Grid item xs={2}></Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
              
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 14px 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Last name">
                      Last name
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography style={{ margin: "14px 0 14px 0" }}>
                   
                      {liname}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
             
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={12} style={{ marginTop: "0px" }}>
                <Mui.Button
                
                  variant="contained"
                  color="default"
                  onClick={handleEditCsutomerInfo}
               
                  style={{
                 
                    marginRight: "24px",
                    width: "auto",
                    display: "block",
                    float: "right",
                    flexDirection: "row-reverse",
                  }}
                >
                  <ReactI18next.Trans i18nKey="Change customer">
                    Change customer
                  </ReactI18next.Trans>
                </Mui.Button>
              </Mui.Grid>
            </>
          ) : displayLabel ? (
            <>
              <Mui.Grid xs={12}>
              <MuiIcons.Person
                  style={{
                    border: "1px grey",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    width: "30px",
                    height: "30px",
                    color: "grey",
                    margin: "0px 0px 10px 6px",
                    padding: "3px"
                  }}
                />
                <Mui.Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginLeft: "46px",
                    marginTop: "-42px",
                    opacity:"0.8"
                  }}
                >
                  <ReactI18next.Trans i18nKey="Customer details">
                    Customer details
                  </ReactI18next.Trans>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
                <DarkerDisabledTextField
                  autoComplete="off"
                  required
                  name="emailID"
                  fullWidth
                  label={t("Email")}
                  margin="dense"
                  size="small"
                  value={mail || ""}
                  onChange={handleemail}
                  error={mailvalidationflag}
                  disabled={displayLabel}
                  helperText={mailvalidationflag ? t(errormail) : " "}
                ></DarkerDisabledTextField>
              </Mui.Grid>
          
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
                <DarkerDisabledTextField
                  autoComplete="off"
                  required
                  name="Companyname"
                  value={ciname || ""}
                  fullWidth
                  label={t("Company name")}
                  margin="dense"
                  size="small"
                  onChange={handlecompany}
                  error={companyvalidationflag}
                  disabled={displayLabel}
                  helperText={
                    companyvalidationflag ? t("Company name is required!") : " "
                  }
                ></DarkerDisabledTextField>
              </Mui.Grid>
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
                <DarkerDisabledTextField
                  autoComplete="off"
                  name="Firstname"
                  value={finame || ""}
                  fullWidth
                  label={t("First name")}
                  onChange={handlefirst}
                  margin="dense"
                  size="small"
                  disabled={displayLabel}
                ></DarkerDisabledTextField>
              </Mui.Grid>
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
                <DarkerDisabledTextField
                  autoComplete="off"
                  name="Lastname"
                  value={liname || ""}
                  onChange={handlelast}
                  fullWidth
                  disabled={displayLabel}
                  label={t("Last name")}
                  margin="dense"
                  size="small"
                ></DarkerDisabledTextField>
              </Mui.Grid>
              <Mui.Grid xs={12} style={{ marginTop: "2px" }}>
                <Mui.Button
                  variant="contained"
                  color="default"
                  onClick={handleEditCsutomerInfo}  
                  style={{
                    marginTop: "2px",
                    marginRight: "24px",
                    width: "auto",
                    display: "block",
                    float: "right",
                  }}
                >
                  <ReactI18next.Trans i18nKey="Change customer">
                    Change customer
                  </ReactI18next.Trans>
                </Mui.Button>
              </Mui.Grid>
            </>
          ) : (
            <>
              <Mui.Grid xs={12}>
              <MuiIcons.Person
                  style={{
                    border: "1px grey",
              borderRadius: "50%",
              backgroundColor: "lightgray",
              width: "30px",
              height: "30px",
              color: "grey",
              margin: "0px 0px 10px 6px",
              padding: "3px"
                  }}
                />
                <Mui.Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginLeft: "46px",
                    marginTop: "-42px",
                    opacity:"0.8"
                  }}
                >
                  <ReactI18next.Trans i18nKey="Customer details">
                    Customer details
                  </ReactI18next.Trans>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
                <Mui.TextField
                  autoComplete="off"
                  required
                  name="emailID"
                  fullWidth
                  label={t("Email")}
                  disabled={!isNextHide}
                  margin="dense"
                  size="small"
                  value={mail || ""}
                  onChange={handleemail}
                  error={mailvalidationflag}
                  helperText={mailvalidationflag ? t(errormail) : " "}
            
                ></Mui.TextField>
              </Mui.Grid>
              {isNextHide &&   <Mui.Grid item xs={6} lg={3} md={3}>
         <Mui.Button
            color="primary"
            variant="contained"
            style={{ margin: "34px 0 24px 0px", float: "left" }}
            onClick={onBlur}
            disabled={nextActionColor}
          >
           <ReactI18next.Trans i18nKey="Next >>">
            Next{'  '} {'>>  '}
          </ReactI18next.Trans>
          </Mui.Button>
          </Mui.Grid>}
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
               {flagForField &&
               <Mui.TextField
                  autoComplete="off"
                  required
                  name="Companyname"
                  value={ciname || ""}
                  fullWidth
                  label={t("Company name")}
                  margin="dense"
                  size="small"
                  onChange={handlecompany}
                  error={companyvalidationflag}
                  helperText={
                    companyvalidationflag ? t("Company name is required!") : " "
                  }
                ></Mui.TextField>}
              </Mui.Grid>
              <Mui.Grid
                style={{ marginTop: "12px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
                {flagForField &&
                <Mui.TextField
                  required
                  autoComplete="off"
                  name="Firstname"
                  value={finame || ""}
                  fullWidth
                  label={t("First name")}
                  onChange={handlefirst}
                  margin="dense"
                  size="small"
                  error={firstNameValidationflag}
                  helperText={
                    firstNameValidationflag ? t("First name is required!") : " "
                  }
                ></Mui.TextField>}
              </Mui.Grid>
              <Mui.Grid
                style={{ marginTop: "12px", marginBottom: "24px" }}
                item
                xs={12}
                md={4}
                lg={3}
              >
               {flagForField && <Mui.TextField
                  required
                  autoComplete="off"
                  name="Lastname"
                  value={liname || ""}
                  onChange={handlelast}
                  fullWidth
                  label={t("Last name")}
                  margin="dense"
                  size="small"
                  error={lastNameValidationflag}
                  helperText={
                    lastNameValidationflag ? t("Last name is required!") : " "
                  }
                ></Mui.TextField>}
              </Mui.Grid>
              {!displayLabel && !ManageCustomerGridDisplay && (
        isNextHide ? <></> : <Mui.Grid xs={12} style={{ marginTop: "2px" }}>
             <Mui.Button
          color="primary"
          variant="contained"
          onClick={handleLabel}
          disabled={ciname == "" || mail == "" || mailvalidationflag ||lastNameValidationflag || firstNameValidationflag || liname == ""  || finame == "" }
          style={{
            float: "right",
          }}
        >
          <ReactI18next.Trans i18nKey="Next >>">
            Next{'  '} {'>>  '}
          </ReactI18next.Trans>
        </Mui.Button>
                <Mui.Button
                  variant="contained"
                  color="default"
                  onClick={handleEditCsutomerInfo}
                  style={{
                    marginRight: "18px",
                    width: "auto",
                    display: "block",
                    float: "right",
                    flexDirection: "row-reverse",
                  }}
                >
                  <ReactI18next.Trans i18nKey="Change customer">
                    Change customer
                  </ReactI18next.Trans>
                </Mui.Button>
              </Mui.Grid>)}
            </>
          )}
        </Mui.Grid>
      </Mui.Paper>
      
      {(displayLabel || ManageCustomerGridDisplay) && (
        <Mui.Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: theme.spacing(2) }}
        >
         
        </Mui.Typography>
      )}
      {(displayLabel || ManageCustomerGridDisplay) && (
        <>
        
          <Mui.Grid container xs={12}>
            <Mui.Grid item>
              {articleStoreQuantityCheck.length >
                0 && (
                <Mui.Typography
                  style={{
                    height: "23px",
                    fontWeight: "bold",
                        fontSize: "20px",
                    margin: "0px 0 10px 12px",
                    borderRadius: "none",
                    opacity: "0.8",
                  }}
                >
                  <ReactI18next.Trans i18nKey="Select the license(s)">
            Select the license(s)
          </ReactI18next.Trans>
                  {/* Select the article */}
                </Mui.Typography>
              )}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Dialog
        open={selectionCompleteDialog}
        keepMounted
        disableBackdropClick
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Mui.DialogTitle id="alert-dialog-slide-title">{""}</Mui.DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <InfoIcon
            style={{
              color: theme.palette.primary.main,
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: theme.palette.primary.main, }}>
          <ReactI18next.Trans i18nKey="Information">
          Information!
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-slide-description">
          &emsp;<ReactI18next.Trans i18nKey="Great customer selection is completed">Great! customer selection is completed. The system shows licenses available to be purchased in your stock as the</ReactI18next.Trans>
          &nbsp;<MuiIcons.Brightness1
        style={{
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.success.main
        }}
      />&nbsp;
          <ReactI18next.Trans i18nKey="green indicator is for available and the">green indicator is for available and the</ReactI18next.Trans>
          &nbsp;<MuiIcons.Brightness1
        style={{
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.error.main,
        }}
      />&nbsp;
          <ReactI18next.Trans i18nKey="red indicator to be purchased">red indicator to be purchased.</ReactI18next.Trans><br/>
          <br/>
          <ReactI18next.Trans i18nKey="As a next step">As a next step, Please assign the licenses by selecting the required licenses.</ReactI18next.Trans>
          
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
              setSelectionCompleteDialog(false)
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <ReactI18next.Trans i18nKey="Ok">
            Ok
          </ReactI18next.Trans>
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
          <AvailableArticles value={displayLabel} />
        </>
      )}
     

      {/* Load the POP message */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <InfoIcon
            style={{
              color: "grey",
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: "grey" }}>
          <ReactI18next.Trans i18nKey="Information">
          Information
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {customerType == 3
              ? t("Customer Invite has already sent to the user,  Do you want to add more licenses")+" "+"?"
              : customerType == 4
              ? t("The user was already mapped to other dealer or the user will be Admin or Dealer, please try again with some other user")
              : customerType == 5
           
              ?  <ReactI18next.Trans i18nKey="Another dealer has been successfully sent the customer invites to this customer">Another dealer has been successfully sent the customer invites to this customer  
              { " "} <b>{ mailID}</b> <ReactI18next.Trans i18nKey="Once the customer accepts the invite and onboard to ETLicense, you can assign more licenses to the customer. please try again with some other user">
              Once the customer accepts the invite and onboard to ETLicense, you can assign more licenses to the customer. please try again with some other user
              </ReactI18next.Trans></ReactI18next.Trans>
              : customerType == 2
              ? t("The Email ID of the customer was already having the Kern Id, customer can log in to Easy Touch License using the same email Id, Do you want to continue?")
              : customerType == 7
              ?  t("This is an existing customer in Easy Touch License system, Do you want to add more licenses")
              : customerType == 6
              ?  t("This is an existing customer in Easy Touch License system, The user was already mapped to other dealer")
              : t("This is an existing customer in Easy Touch License system, Do you want to associate the customer with your company")}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{

            display: "flex",
            justifyContent: "center",
          }}
        >
          {customerType > 3 && customerType != 7 ? null : (
            <Mui.Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              style={{
                height: "32px",
                width: "150px",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              Yes
            </Mui.Button>
          )}
          {customerType > 3 && customerType != 7 ? null : (
            <Mui.Button
              onClick={handleNo}
              variant="contained"
              color="primary"
              style={{
                height: "32px",
                width: "150px",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              No
            </Mui.Button>
          )}
          {customerType > 3 && customerType != 7 && (
            <Mui.Button
              onClick={
                handleNo
               }
              variant="contained"
              color="primary"
              style={{
                height: "32px",
                width: "150px",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              Ok
            </Mui.Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
});
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  value?: boolean;
}
const AvailableArticles = Mobx.observer((props: Props) => {
  const functionStore = React.useContext(App.Contexts.functionStore);
  const cartStore = React.useContext(App.Contexts.cartStore);
  const { t } = ReactI18next.useTranslation();
  const useStyles = Mui.makeStyles((theme) => ({
    message: {
      width: "100%",
    },
    root:
      theme.palette.type === "light"
        ? {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.primary.main,
          }
        : {},
  }));
  const [searchParams] = Router.useSearchParams();
  var navigationFromManageCustomer = searchParams.get("q");
  const [flagForBasic, setFlagForBasic] = React.useState(false);
  const homeStore = React.useContext(App.Contexts.homeStore);
  const handleSelect = ({ article }: { article: Models.Set.Article }) => {
    
    let validationData = [];
        let dashboardReplica:any = [];
        dashboardReplica = homeStore.dashboard?.licenseSummary ?? [];
       
          for (var i = 0; i < dashboardReplica.length; i++) {
          dashboardReplica[i].totalQuantity = +dashboardReplica[i].totalQuantity;
        }
        const dash = dashboardReplica.map((k:any)=>{
          return {
            hundCode: k.hundCode,
            totalQuantity: k.totalQuantity / 4,
            setName: k.setName
          }
        })
      
        
      
         let  customerInviteStores:any = Array.from(customerInviteStore.article.values())
       
          if(customerInviteStores !== undefined){
         for (var j = 0; j < customerInviteStores.length; j++) {
          customerInviteStores[j].quantity = +customerInviteStores[j].quantity;
          customerInviteStores[j].availableQuantity = +customerInviteStores[j].availableQuantity;
      }
    }
      const customerInviteStoress = customerInviteStores.map((k:any)=>{
        return {
          hundCode: k.hundCode,
          totalQuantity: k.quantity,
          setName: k.setName,
          availableQuantity: k.availableQuantity
        }
      })
      
         if(dashboardReplica.length > 0 && customerInviteStoress.length > 0){
          const result = Object.values([...customerInviteStoress, ...dash].reduce((acc, { hundCode, totalQuantity,setName }) => {
            acc[hundCode] = { hundCode, totalQuantity: (acc[hundCode] ? acc[hundCode].totalQuantity : 0) + totalQuantity,setName  };
            return acc;
          }, {}));
          validationData = result;
          
           
         }
         else if(dashboardReplica.length > 0 && (customerInviteStoress.length === 0 || customerInviteStoress === undefined )){
          const dash = dashboardReplica.map((k:any)=>{
            return {
              hundCode: k.hundCode,
              totalQuantity: k.totalQuantity / 4,
              setName: k.setName
            }
          })
          validationData = dash
        }
        else if((dashboardReplica.length === 0 || dashboardReplica === undefined ) && customerInviteStoress.length > 0){
          const inviteStore = customerInviteStoress.map((k:any)=>{
            return {
              hundCode: k.hundCode,
              totalQuantity: k.totalQuantity,
              setName: k.setName,
              availableQuantity: k.availableQuantity
            }
          })
          validationData = inviteStore;
    
        }
        else
        {
          validationData = [];
        }
        
        for(var m = 0; m < validationData.length; m++){
          if(validationData[m].hundCode === article.hundCode){
            validationData[m].totalQuantity = validationData[m].totalQuantity + 1;
          }
          
        }
        
        const validationCheckfilter = validationData.filter((k:any)=>k.hundCode === article.hundCode)
        if(validationCheckfilter.length > 0){
          
          
        }else{
          
          
          validationData.push({
            hundCode: article.hundCode,
            totalQuantity: 1,
            setName: article.setName
          })
        }
    
        
        const filteringSelectedArticleQuantity = validationData.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)
        const filteringBasicSetQuantity= validationData.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
       
        if(article.hundCode === "SET-01"){
         
            const quantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)[0]
            const availableQuantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.availableQuantity)[0]
            customerInviteStore.increaseProductQuantity({
              article,
             });
             if((quantityOfInvite + +1) - availableQuantityOfInvite >  0){
            
             }
          const FilteringSelectedArticleQuantityisZeroChecks = filteringSelectedArticleQuantity.length === 0 || filteringSelectedArticleQuantity.length === undefined || filteringSelectedArticleQuantity.length === null ? 0 : filteringSelectedArticleQuantity[0]
          const FilteringBasicSetQuantityisZeroChecks = filteringBasicSetQuantity.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
          
          const checkForAddToCart = FilteringSelectedArticleQuantityisZeroChecks <= (FilteringBasicSetQuantityisZeroChecks - 1)
          if (customerInviteStore.selected.includes(article.setID)) 
                    {
              if(checkForAddToCart){
                customerInviteStore.removeProduct({ article: article });
              }
              else
              {
                enqueueSnackbar(
                  t(`Basic Set cannot be removed because it has dependent with other article`),
                  {
                    variant: "warning",
                    preventDuplicate: false,
                  }
                );
                return false;
              }
                    }
        }
        else if(article.hundCode !== "SET-01"){
    
          const FilteringSelectedArticleQuantityisZeroChecks = filteringSelectedArticleQuantity.length === 0 || filteringSelectedArticleQuantity.length === undefined || filteringSelectedArticleQuantity.length === null ? 0 : filteringSelectedArticleQuantity[0]
          const FilteringBasicSetQuantityisZeroChecks = filteringBasicSetQuantity.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
          
          const checkForAddToCart = FilteringSelectedArticleQuantityisZeroChecks <= FilteringBasicSetQuantityisZeroChecks 
          
          const inviteStore = customerInviteStoress.map((k:any)=>{
            return {
              hundCode: k.hundCode,
              totalQuantity: k.totalQuantity,
              setName: k.setName,
              availableQuantity: k.availableQuantity
            }
          })
          const filteringBasicSetQuantityAutomateBasicAdd= inviteStore.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
          const FilteringBasicSetQuantityAutomateBasicAddInvite = filteringBasicSetQuantityAutomateBasicAdd.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
          let isEnableBasicset = false;
          if((FilteringBasicSetQuantityisZeroChecks === 0 )&& (FilteringBasicSetQuantityAutomateBasicAddInvite === 0)){
            isEnableBasicset = true
            const articled:any = customerInviteStore.selectedArticle.filter((k:any) => k.hundCode === "SET-01")
            
                const articles = Object.assign({}, ...articled);
                customerInviteStore.increaseProductQuantity({
                  article,
                 });
              
                   const quantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)[0]
                   const availableQuantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.availableQuantity)[0]
                  
                    if((quantityOfInvite + +1) - availableQuantityOfInvite >  0){
               
                    
             }
                }else{
                  isEnableBasicset = false
                }
          
          if((checkForAddToCart === true)|| (isEnableBasicset === true)){
            customerInviteStore.increaseProductQuantity({
                  article,
                 });
                
                   const quantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)[0]
                   const availableQuantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.availableQuantity)[0]
                  
                    if((quantityOfInvite + +1) - availableQuantityOfInvite >  0){
                   
                    
             }else{
               
             }
            
          }
          else
          {
            
                    if (customerInviteStore.selected.includes(article.setID)) 
                    {
                      customerInviteStore.removeProduct({ article: article });
                    }else{
                      enqueueSnackbar(
                        t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                        {
                          variant: "warning",
                          preventDuplicate: false,
                        }
                      );
                      return false;
                    }
                    
          }
       
      }

    if(article?.olPrice?.singleNetPrice?.toString() === "0.0" || article?.olPrice?.singleNetPrice?.toString() === "0" || article.olPrice.singleNetPrice === null){
      enqueueSnackbar(t("This article will not be available for purchase at this time"), {
        variant: "warning",
        preventDuplicate: false,
      });
      return false;
    }
    const basicResponses = localStorage.getItem("Basicset");
    if (customerInviteStore.selected.includes(article.setID)) {
      customerInviteStore.removeProduct({ article: article });
      if((basicResponses === "0" || basicResponses === "undefined") && article.setID ===1){
        
      } else {
        cartStore.removeProduct({ product: article });
      }

    } else {
      customerInviteStore.addProduct({ article: article });
    }


    const custMailResponseLengthCheck = localStorage.getItem(
      "responseOfCustMail"
    );
    const articled = customerInviteStore.getArticlesFromLicenses.filter(
      (k) => k.setID == 1
    );
    const articles = Object.assign({}, ...articled);
    const inviteStoreCheck = Array.from(customerInviteStore.article.values());
    const inviteStoreLengthCheck = inviteStoreCheck.length;
    const customerType = localStorage.getItem("customerType");
    let convertCustomerType = Number(customerType);

    const selectedIndex = customerInviteStore.selected.indexOf(article.setID);
    let newSelected: number[] = [];
    let newRowSelected: Models.Set.Article[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected,
        article.setID
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle,
        article
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(customerInviteStore.selected.slice(1));
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(1)
      );
    } else if (selectedIndex === customerInviteStore.selected.length - 1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, -1)
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, selectedIndex),
        customerInviteStore.selected.slice(selectedIndex + 1)
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(0, selectedIndex),
        customerInviteStore.selectedArticle.slice(selectedIndex + 1)
      );
    }

    customerInviteStore.setArticleSelected({ selectedArticle: newRowSelected });
    customerInviteStore.setSelected({ selected: newSelected });
    //
    if (convertCustomerType === null || convertCustomerType === undefined) {
      convertCustomerType = 0;
    }
    const basicResponse = localStorage.getItem("Basicset");
    const addingBasicSetForNewCustomerToInviteSummary =
      convertCustomerType < 3 &&
      navigationFromManageCustomer === null &&
      (basicResponse === "0" || basicResponse === "undefined")
        ? (() => {
            customerInviteStore.addProduct({ article: articles });
            let selected: number = 1;
            let seelectedValues = customerInviteStore.selected.push(selected)
            // 
          })()

        : "";

    const purchaseEnableFromInviteCustomer = customerInviteStore.selectedArticle
      .map((k) => k.availableQuantity)
      .some((k) => (k == 0 ? true : false));
    localStorage.setItem(
      "isCustomerInvitePurchase",
      JSON.stringify(purchaseEnableFromInviteCustomer)
    );

    const customerInviteToCart = customerInviteStore.selectedArticle.filter(
      (k: Models.Set.Article) => k.availableQuantity // {
     
    );
    const storealert = localStorage.getItem("isCustomerInvitePurchase");
    const slicer = customerInviteToCart.slice(customerInviteToCart.length - 1);
    const checkBasicInCartOrNot = newSelected.includes(article.setID);
    slicer.map((k) =>
      k.availableQuantity == 0 && checkBasicInCartOrNot
        ? (() => {
          if((basicResponses === "0" || basicResponses === "undefined") &&
          article.setID === 1 ){
            if(customerInviteStore.selected.includes(article.setID)){
              
            }else if(customerInviteStore.selected.includes(1)){
              cartStore.vatTaxPercent = article.vatPercentage;
              cartStore.addProduct({ product: article });
            }

          }else{
            cartStore.vatTaxPercent = article.vatPercentage;
            cartStore.addProduct({ product: article });
            enqueueSnackbar(t("Added to cart!"), {
              variant: "success",
              preventDuplicate: false,
            });
          }



          })()
        : ""
    );
    const checkBasic = newSelected.includes(1);
    const responseBasicset = localStorage.getItem("Basicset");
    const params = new URLSearchParams(window.location.search);
  const parameter = params.toString();
    if(convertCustomerType < 3 &&
      navigationFromManageCustomer === null &&
      (responseBasicset === "0" || responseBasicset === "undefined") &&
      articles.availableQuantity == 0 && !flagForBasic && !parameter){
        cartStore.vatTaxPercent = article.vatPercentage;
        cartStore.addProduct({ product: articles });
        let selected: number = 1;
        let seelectedValues = customerInviteStore.selected.push(selected) 
        setFlagForBasic(true);

    }

  };
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  const licenseStore = React.useContext(App.Contexts.license.store);
  const userStore = React.useContext(App.Contexts.userStore);
  const uid = userStore.user?.ETUID as number;
  const userRole = userStore.user?.RoleName ?? "customer";
  const lengthCheck = customerInviteStore.selected.length > 0;
  const { i18n } = ReactI18next.useTranslation();
  const [disable, setDisable] = React.useState<boolean>(
    lengthCheck ? false : true
  );
  const [articles, setArticle] = React.useState<Models.Set.Article[] |undefined>();

  const theme = Mui.useTheme();
  const navigate = Router.useNavigate();
  React.useEffect(() => {
    licenseStore.readInviteLicense({
      body: { status: null, userID: uid },
      userRole,
      uid,
    });
    if (!functionStore.functions.length) functionStore.read();
    customerInviteStore.readSetFuncations();
    if (navigationFromManageCustomer) {
      customerInviteStore.resetInviteSummary();
      let newSelected: number[] = [];
      customerInviteStore.setSelected({
        selected: newSelected,
      });
    }
    if(customerInviteStore.getArticlesFromLicenses !== undefined){
  
    }
  }, [
    i18n.language,
    customerInviteStore,
    functionStore,
    licenseStore,
    navigationFromManageCustomer,
    uid,
    userRole,
  ]);
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const handleAssignLicense = () => {
    if (customerInviteStore.selected.length > 0) {
      if (navigationFromManageCustomer) {
        const fromManageCustomer = "fromManageCustomer";
        navigate(
          `/dealercustomer/addcustomer?q=${fromManageCustomer}`
        );
      } else {
        navigate(`/dealercustomer/addcustomer`);
      }
    } else {
      enqueueSnackbar("Please the select article!", {
        variant: "warning",
        preventDuplicate: false,
      });
    }
   
  }
  React.useEffect(() => {
    if(customerInviteStore.getArticlesFromLicenses.length > 0 ){
   var   licenseSort = customerInviteStore.getArticlesFromLicenses
    .sort((a, b) =>{  
      if(b.availableQuantity !== null && a.availableQuantity !== null )
      { return parseFloat(b.availableQuantity.toString()) - parseFloat(a.availableQuantity.toString())}
      else{
      return 0
     }
    })
    var filteredArray = licenseSort.filter((k) => k.setID !== 1);
    var selectedObject = licenseSort.filter((k) => k.setID === 1);
    var licenseArray = [...selectedObject,...filteredArray]
    setArticle(licenseArray);
    }
 
  }, [customerInviteStore.getArticlesFromLicenses, customerInviteStore.getArticlesFromLicenses.length]);
  

  return (
    <>
      <Mui.Grid container spacing={2}>
        {customerInviteStore.getAvailableArticlesFromLicenses.length === 0 && (
          <Mui.Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "80vh" }}
          >
            <Mui.CircularProgress
              style={{ justifyContent: "center", alignItems: "center" }}
            />
          </Mui.Grid>
        )}
        {customerInviteStore.getAvailableArticlesFromLicenses.length > 0 &&
          customerInviteStore.getFilteredAvailableArticles.length === 0 &&
          customerInviteStore.search && (
            <Mui.Grid item xs={12}>
              <MuiLab.Alert severity="info">
                <MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="No license(s) found">
                No article(s) found
            </ReactI18next.Trans></MuiLab.AlertTitle>
            <ReactI18next.Trans i18nKey="No available license(s) found for the search term">
            No available license(s) found for the search term. Use a 
            different search term or clear the search to view some available license(s).
            </ReactI18next.Trans>
              </MuiLab.Alert>
            </Mui.Grid>
          )}
        {customerInviteStore.getArticlesFromLicenses !== undefined && customerInviteStore.getArticlesFromLicenses.map((article) =>{ 
         // 
          return (
          
          <Mui.Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
            key={article.setID}
          >
            <CustomerInvite.Card
              selected={customerInviteStore.selected.includes(article.setID)}
              functions={functionStore.functions?.filter((func) =>
                (article as Models.Set.Article).functions.includes(
                  func.setFunctionID
                )
              )}
              handleClick={handleSelect}
              article={article}
            />
          </Mui.Grid>
        )})}
      </Mui.Grid>
      <Mui.Grid style={{ display: "flex", justifyContent: "flex-end" }}>
        {customerInviteStore.getAvailableArticlesFromLicenses.length > 0 && (
         

          <Mui.Button
            style={{
             
              marginTop: "30px",
              position: "fixed",
              bottom: "30px",
              right: "24px",
           
            }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={customerInviteStore.selected.length === 0}
            onClick={handleAssignLicense}
          >
            <ReactI18next.Trans i18nKey="Continue">
            Continue
            </ReactI18next.Trans>
          </Mui.Button>
       
        )}
      </Mui.Grid>
    </>
  );
});
