import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import clsx from "clsx";
import * as Formik from "formik";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as XLSX from "xlsx";
import { useExcelGenerateAndDownload } from "./use-excel-generate-and-download";

export const readFile = (file: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsBinaryString(file);
  });
};

export const parseArr = (arr: Array<any>) => {
  const [headings, ...data] = arr;

  return data.reduce((acc: any, curr: any, index: number) => {
    const newData: any = {};
    for (const [index, heading] of headings.entries()) {
      newData[heading] = curr[index] === undefined ? null : curr[index];
    }

    return [...acc, newData];
  }, []);
};

export const Main = () => {
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();

  const [fileDetails, setFileDetails] = React.useState<any>();
  const [data, setData] = React.useState<any>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const { exportAsExcelFile } = useExcelGenerateAndDownload();

  const classes = useStyles({ fileDetails });

  const deviceConfigurationUploadStore = React.useContext(
    App.Contexts.deviceConfigurationUploadStore
  );

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      e.target.value = null;

      if (!(file.type || "").toLowerCase().includes("sheet")) {
        enqueueSnackbar(
          t("Please attach a valid excel file. Supported file extensions are '.xlsx' and '.xlsb'."),
          {
            variant: "warning",
            preventDuplicate: false,
          }
        );
        return;
      }

      const fileName = file.name;
      const fileSize = `${(file.size / 1024).toFixed(2)} KB`;
      const fileType = file.type;
      const fileModifiedDate = file.lastModifiedDate;
      setFileDetails(undefined);
      setFileDetails({
        fileName,
        fileSize,
        fileType,
        fileModifiedDate,
      });

      let data = await readFile(file);

      let workbook = XLSX.read(data, { type: "binary" });
      workbook.SheetNames.forEach((sheet) => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
          header: 1,
          raw: true,
        });
        setData([]);
        setData(parseArr(rowObject));
      });
    }
  };

  const handleUpload: (
    values: {},
    formikHelpers: Formik.FormikHelpers<{}>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    try {
      await deviceConfigurationUploadStore.upload({
        body: { DynamicDataList: data },
      });

      enqueueSnackbar(
        t("Your configuration excel file has been successfully uploaded!"),
        {
          variant: "success",
          preventDuplicate: true,
        }
      );
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400) {
        enqueueSnackbar(
          t(
            "The file currently uploaded doesn't contains the configuration(s) in an acceptable format."
          ),
          {
            variant: "error",
            preventDuplicate: false,
          }
        );
        return;
      }
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setFileDetails(undefined);
      setData(undefined);
      setSubmitting(false);
    }
  };

  const theme = Mui.useTheme();

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const { result } = await deviceConfigurationUploadStore.download();

      enqueueSnackbar(
        t(
          "Latest configurations has been successfully downloaded! Please, save the file on your system."
        ),
        {
          variant: "success",
          preventDuplicate: true,
        }
      );

      exportAsExcelFile(result, "device-configurations");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Formik.Formik initialValues={{}} onSubmit={handleUpload}>
        {({ isSubmitting, submitForm }) => (
          <Mui.Card>
            <Mui.CardHeader
              title={
                <Mui.Typography color="textSecondary" variant="h6">
                  <ReactI18next.Trans i18nKey="Welcome to KERN's scales">
                  Welcome to KERN's scales configuration management setup
                  </ReactI18next.Trans>
                </Mui.Typography>
              }
            />
            <Mui.Divider />
            <Mui.CardContent>
              <Mui.Grid container spacing={2}>
                <Mui.Grid item xs={12}>
                  {!fileDetails && (
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="Please load the configuration">
                      Please load the configuration excel file using the following button.
                      </ReactI18next.Trans>
                     
                    </MuiLab.Alert>
                  )}

                  {fileDetails && (
                    <MuiLab.Alert severity="success">
                      <ReactI18next.Trans i18nKey="Your excel file has been successfully loaded">
                      Your excel file has been successfully loaded. Now, make sure the file you are going to upload is the right configuration file before proceeding.
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  )}
                </Mui.Grid>

                <Mui.Grid item>
                  <div className={classes.previewContainer}>
                    <Mui.Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Mui.Grid item>
                        <input
                          accept=".xlsx,.xlsb"
                          className={classes.input}
                          id="excel-upload-button"
                          type="file"
                          onChange={onFileChange}
                        />
                        <label htmlFor="excel-upload-button">
                          <Mui.Card
                            component="span"
                            className={clsx(classes.card, classes.inlineFlex)}
                          >
                            <Mui.CardActionArea
                              component={isSubmitting ? "div" : "span"}
                              className={classes.inlineFlex}
                            >
                              <Mui.Tooltip
                                title={
                                  fileDetails
                                    ? "Click to change the config excel file."
                                    : "Click to load the config excel file."
                                }
                              >
                                <Mui.CardContent>
                                  <Mui.Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                  >
                                    <Mui.Typography
                                      variant="h1"
                                      color="inherit"
                                      className={classes.uploadIcon}
                                    >
                                      <i className="icon-etc-export-excel" />
                                    </Mui.Typography>
                                  </Mui.Grid>
                                </Mui.CardContent>
                              </Mui.Tooltip>
                            </Mui.CardActionArea>
                          </Mui.Card>
                        </label>
                      </Mui.Grid>
                    </Mui.Grid>
                  </div>
                </Mui.Grid>

                {fileDetails && (
                  <Mui.Grid item xs>
                    <Mui.Grid container spacing={2}>
                      {fileDetails?.fileName && (
                        <Mui.Grid item xs={12}>
                          <Mui.Typography
                            variant="caption"
                            color="textSecondary"
                          >
                            <ReactI18next.Trans i18nKey="File name">
                            File name
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography variant="body2">
                            {fileDetails.fileName}
                          </Mui.Typography>
                        </Mui.Grid>
                      )}

                      {fileDetails?.fileSize && (
                        <Mui.Grid item xs={12}>
                          <Mui.Typography
                            variant="caption"
                            color="textSecondary"
                          >
                            <ReactI18next.Trans i18nKey="File size">
                            File size
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography variant="body2">
                            {fileDetails.fileSize}
                          </Mui.Typography>
                        </Mui.Grid>
                      )}

                      {fileDetails?.fileType && (
                        <Mui.Grid item xs={12}>
                          <Mui.Typography
                            variant="caption"
                            color="textSecondary"
                          >
                            <ReactI18next.Trans i18nKey="File type">
                            File type
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography variant="body2">
                            {fileDetails.fileType}
                          </Mui.Typography>
                        </Mui.Grid>
                      )}

                      {fileDetails?.fileModifiedDate && (
                        <Mui.Grid item xs={12}>
                          <Mui.Typography
                            variant="caption"
                            color="textSecondary"
                          >
                            <ReactI18next.Trans i18nKey="File modified date">
                            File modified date
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography variant="body2">
                            {(fileDetails.fileModifiedDate as Date).toString()}
                          </Mui.Typography>
                        </Mui.Grid>
                      )}
                    </Mui.Grid>
                  </Mui.Grid>
                )}
              </Mui.Grid>
            </Mui.CardContent>

            <Mui.DialogActions>
              <Mui.Button
                variant="contained"
                onClick={handleDownload}
                disabled={isSubmitting || isDownloading}
              >
                <ReactI18next.Trans i18nKey="Download latest configurations">
                Download latest configurations
                </ReactI18next.Trans>
                {isDownloading && (
                  <Mui.CircularProgress
                    style={{
                      marginLeft: theme.spacing(1),
                    }}
                    color={"inherit"}
                    size={theme.typography.button.fontSize}
                  />
                )}
              </Mui.Button>

              <Mui.Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || !data}
                onClick={submitForm}
              >
                <ReactI18next.Trans i18nKey="Upload configuration">
                Upload configuration
                </ReactI18next.Trans>
                {isSubmitting && (
                  <Mui.CircularProgress
                    style={{
                      marginLeft: theme.spacing(1),
                    }}
                    color={"inherit"}
                    size={theme.typography.button.fontSize}
                  />
                )}
              </Mui.Button>
            </Mui.DialogActions>
          </Mui.Card>
        )}
      </Formik.Formik>
    </>
  );
};

export interface StyledProps {
  fileDetails?: unknown;
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    previewContainer: {
      width: 150,
    },
    inlineFlex: {
      display: "inline-flex",
    },
    card: {
      backgroundColor: theme.palette.background.default,
      width: 150,
      height: 150,
    },
    uploadIcon: {
      color: (props: StyledProps) =>
        props.fileDetails
          ? theme.palette.success.main
          : theme.palette.text.secondary,
    },
    input: {
      display: "none",
    },
  })
);
