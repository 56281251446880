import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    addDealer: Models.AddDealer.Main | undefined;
    adddealerService: Services.AddDealer;
  }

  export type Options = {
    adddealerService: Services.AddDealer;
  };

  export namespace Read {
    export interface Props extends Services.AddDealer.GetAllData.Return {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public addDealer: Models.AddDealer.Main | undefined;

  @Mobx.observable public isLoading: boolean = false;
  public adddealerService: Services.AddDealer;

  constructor({ adddealerService }: Store.Options) {
    this.adddealerService = adddealerService;
  }

  @Mobx.action public async readUsers() {
    try {
      this.isLoading = true;
      const { result } = await this.adddealerService.getAllData();
      return this.addDealer = { ...result};
      
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
