/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Stores from "src/stores";

export declare namespace Cart {
  export interface ICart {
    cart: Map<string, any>;
    order?: Models.Order.Creation;
    billingAddress?: Models.Address;
  }

  export type Options = {
    userStore: Stores.User;
  };

  export namespace SetBillingAddress {
    export interface Props
      extends Required<Pick<Cart.ICart, "billingAddress">> {}
  }

  export namespace AddProduct {
    export interface Props {
      product: any;
    }
  }

  export namespace RemoveProduct {
    export interface Props {
      product: any;
    }
  }

  export namespace PlaceOrder {
    export interface Props {
      order: Models.Order.Creation;
    }
  }

  export namespace IncreaseProductQuantity {
    export interface Props {
      product: any;
    }
  }

  export namespace DecreaseProductQuantity {
    export interface Props {
      product: any;
    }
  }

  export namespace SetVatTaxPercent {
    export interface Props {
      percent: number;
    }
  }
}

// eslint-disable-next-line no-redeclare
export class Cart implements Cart.ICart {
  @Mobx.observable public cart: Map<string, any> = new Map();
  @Mobx.observable public order?: Models.Order.Creation;
  @Mobx.observable public billingAddress?: Models.Address;
  @Mobx.observable public vatTaxPercent?: any;

  private userStore: Stores.User;

  constructor({ userStore }: Cart.Options) {
    this.userStore = userStore;
    this.rehydrate();
    const disposer = Mobx.reaction(
      () => this.userStore.user?.ETUID,
      () => {
        if (
          this.userStore.user?.ETUID !== undefined ||
          this.userStore.user?.ETUID !== null
        ) {
          this.rehydrate();
          disposer();
        }
      }
    );
  }

  @Mobx.action public setVatTaxPercent({
    percent,
  }: Cart.SetVatTaxPercent.Props) {
    this.vatTaxPercent = percent;
  }

  @Mobx.action public setBillingAddress({
    billingAddress,
  }: Cart.SetBillingAddress.Props) {
    this.billingAddress = billingAddress;
    this.persist();
  }

  @Mobx.action public increaseProductQuantity({
    product,
  }: Cart.IncreaseProductQuantity.Props) {
    const productInCart = this.cart.get(product.setID);
    if (!productInCart) {
      return;
    }
    productInCart.quantity += 1;
  }

  @Mobx.action public decreaseProductQuantity({
    product,
  }: Cart.DecreaseProductQuantity.Props) {
    const productInCart = this.cart.get(product.setID);

    if (!productInCart) {
      return;
    }
    productInCart.quantity -= 1;
  }

  @Mobx.action public placeOrder({ order }: Cart.PlaceOrder.Props) {
    this.order = order;
  }

  @Mobx.action public resetCart() {
    const ETUID = this.userStore.user?.ETUID;
    localStorage.removeItem(`cart-${ETUID}`);
    localStorage.removeItem("orderDetails");
    localStorage.removeItem("billingAddress");
    this.cart = new Map();
    this.order = undefined;
  }

  @Mobx.action public addProduct({ product }: Cart.AddProduct.Props) {
    function processAdd(this: Cart) {
      const productPresentInCart = this.cart.get(product.setID);
      if (productPresentInCart) {
        productPresentInCart.quantity += 1;
        return;
      }

      this.cart.set(product.setID, { ...product, quantity: 1 });
    }
    processAdd.bind(this)();
    this.persist();
  }

  @Mobx.action public removeProduct({ product }: Cart.RemoveProduct.Props) {
    this.cart.delete(product.setID);
    this.persist();
  }

  @Mobx.computed public get total(): number {
    let total: number = 0;
    for (const product of this.cart.values()) {
      total += product.quantity * product.olPrice.singleNetPrice;
    }

    return total;
  }

  @Mobx.computed public get totalVat(): number {
    let totalVat: number = 0;
    for (const product of this.cart.values()) {
      totalVat +=
        product.olPrice.grossPositionSinglePriceInclVAT -
        product.olPrice.singleNetPrice;
    }

    return totalVat;
  }

  @Mobx.computed public get grandTotal(): number {
    let grandTotal: number = 0;
    for (const product of this.cart.values()) {
      grandTotal +=
        product.quantity * product.olPrice.grossPositionSinglePriceInclVAT;
    }

    return grandTotal;
  }

  private rehydrate() {
    const ETUID = this.userStore.user?.ETUID;
    if (ETUID === undefined || ETUID === null) {
      return;
    }

    const serialisedCart = localStorage.getItem(`cart-${ETUID}`);
    if (serialisedCart) {
      this.cart = new Map(JSON.parse(serialisedCart));
    }

    const serialisedBillingAddress = localStorage.getItem(
      `billingAddress-${ETUID}`
    );

    if (serialisedBillingAddress) {
      this.billingAddress = JSON.parse(serialisedBillingAddress);
    }
  }

  private persist() {
    const ETUID = this.userStore.user?.ETUID;
    if (ETUID === undefined || ETUID === null) {
      return;
    }

    const cart = JSON.stringify(Array.from(this.cart.entries()));
    localStorage.setItem(`cart-${ETUID}`, cart);

    if (!this.billingAddress) {
      return;
    }

    const billingAddress = JSON.stringify(this.billingAddress);
    localStorage.setItem(`billingAddress-${ETUID}`, billingAddress);
  }
}
