import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Function {
  export interface IFunction {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Function.IFunction;

  export namespace GetAllData {
    export interface Props {
      rows?: number;
      userId?: number;
      userRole: string;
    }

    export type Return = Models.API.Response<Models.Set.Function[]>;
  }

  export namespace Create {
    export type Body = Models.Set.Function;

    export interface Props {
      body: Function.Create.Body;
      uid: string | number;
    }

    export type Return = Models.API.Response<number>;

    export namespace Langs {
      export type Body = Partial<Models.SetFunctionsLang>[];

      export interface Props {
        body: Function.Create.Langs.Body;
        uid: string | number;
      }

      export type Return = Models.API.Response<string>;
    }
  }

  export namespace Delete {
    export interface Props {
      setfuncationId: string | number;
      uid: string | number;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace Activate {
    export interface Props {
      setfuncationId: string | number;
      uid: string | number;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class Function {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Function.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllData(
    props?: Function.GetAllData.Props,
  ): Promise<Function.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: [
        "SetFuncations",
        "GetAll",
        `${props?.userRole === "customer" ? props?.rows ?? 1 : -1}`,
      ],
    });

    const response = await this.httpManager.get<Function.GetAllData.Return>({
      url,
    });

    return response;
  }

  public async create({
    body,
    uid,
  }: Function.Create.Props): Promise<Function.Create.Return> {
    const url = this.urlFactory.create({
      named: ["SetFuncations", "Create"],
    });

    const response = await this.httpManager.post<
      Function.Create.Return,
      Function.Create.Body
    >({
      url,
      body,
    });

    return response;
  }

  public async delete({
    setfuncationId,
    uid,
  }: Function.Delete.Props): Promise<Function.Delete.Return> {
    const url = this.urlFactory.create({
      named: ["SetFuncations", "Delete", `${setfuncationId}`],
    });

    const response = await this.httpManager.get<Function.Create.Return>({
      url,
    });

    return response;
  }

  public async activate({
    setfuncationId,
    uid,
  }: Function.Activate.Props): Promise<Function.Activate.Return> {
    const url = this.urlFactory.create({
      named: ["SetFuncations", "Activate", `${setfuncationId}`],
    });

    const response = await this.httpManager.get<Function.Create.Return>({
      url,
    });

    return response;
  }
  public async createLangs({
    body,
    uid,
  }: Function.Create.Langs.Props): Promise<Function.Create.Langs.Return> {
    const url = this.urlFactory.create({
      named: ["SetFuncationsLang", "Create"],
    });

    const response = await this.httpManager.post<
      Function.Create.Langs.Return,
      Function.Create.Langs.Body
    >({
      url,
      body,
    });

    return response;
  }
}
