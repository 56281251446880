/** @format */

import * as Mobx from "mobx";
import * as Article from "src/app/pages/article";
import * as Models from "src/models";
import * as Services from "src/services";
import * as Stores from "src/stores";
export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    selectedArticlesForDeactivation: Set<number>;
    customer?: number | string;
    search: string;
    status?: number;
    licenses?: Models.License[];
  }

  export interface Options {
    licenseService: Services.License;
    userStore: Stores.User;
    articleStore: Article.Store;
  }

  export namespace SetLicenses {
    export interface Props extends Pick<Store.IStore, "licenses"> {}
  }

  export namespace Read {
    export interface Props extends Services.License.GetAllData.Props {}
  }

  export namespace SetSearch {
    export interface Props extends Pick<Store.IStore, "search"> {}
  }

  export namespace SetStatus {
    export interface Props extends Pick<Store.IStore, "status"> {}
  }

  export namespace SetCustomer {
    export interface Props extends Pick<Store.IStore, "customer"> {}
  }

  export namespace ToggleArticleSelectionForDeactivation {
    export interface Props extends Pick<Models.Set.Article, "setID"> {}
  }

  export namespace DeactivateAll {
    export interface Props extends Services.License.DeactivateAll.Props {}
  }

  export namespace DeactivateSelected {
    export interface Props extends Services.License.Deactivate.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public selectedArticlesForDeactivation: Set<number> =
    new Set();
  @Mobx.observable public licenses?: Models.License[];
  @Mobx.observable public currentDevice?: Models.Devices & {
    sets: Set<Models.Set.Article>;
  };
  @Mobx.observable public manualLicensesAvailableList?: Models.License[];
  @Mobx.observable public search: string = "";
  @Mobx.observable public status?: number = 1;
  @Mobx.observable public customer?: string | number;

  private licenseService: Services.License;
  private userStore: Stores.User;
  private articleStore: Article.Store;

  constructor({ articleStore, licenseService, userStore }: Store.Options) {
    this.articleStore = articleStore;
    this.licenseService = licenseService;
    this.userStore = userStore;
  }

  public activate(props: Services.License.Activate.Props) {
    return this.licenseService.activate(props);
  }

  public getLicenseKeyAfterActivation(
    props: Services.License.CreateManualLicenseAfterActivation.Props
  ) {
    return this.licenseService.createManualLicenseAfterActivation(props);
  }

  @Mobx.action public async deactivateAll(props: Store.DeactivateAll.Props) {
    try {
      await this.licenseService.deactivateAll(props);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.resetSelectedArticlesForDeactivation();
    }
  }

  @Mobx.action public async deactivateSelected(
    props: Store.DeactivateSelected.Props
  ) {
    try {
      await this.licenseService.deactivate(props);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.resetSelectedArticlesForDeactivation();
    }
  }

  @Mobx.action public toggleArticleSelectionForDeactivation({
    setID,
  }: Store.ToggleArticleSelectionForDeactivation.Props) {
    const licenseDetailIDAlreadyInSelectedLicensesForDeactivation =
      this.selectedArticlesForDeactivation.has(setID);
    if (licenseDetailIDAlreadyInSelectedLicensesForDeactivation) {
      this.selectedArticlesForDeactivation.delete(setID);
      return;
    }

    this.selectedArticlesForDeactivation.add(setID);
  }

  @Mobx.action public resetSelectedArticlesForDeactivation() {
    this.selectedArticlesForDeactivation = new Set();
  }

  @Mobx.action public setLicenses({ licenses }: Store.SetLicenses.Props) {
    this.licenses = licenses;
  }

  @Mobx.action public setCurrentDevice({ device }: any) {
    this.currentDevice = device;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;

      if (props.userRole === "dealer") {
        const initLicenses = ({ result }: { result: Models.License[] }) => {
          this.licenses = result
            .filter(
              (license: any, index) =>
                result.findIndex(
                  (a: any) => a["licenseCode"] === license["licenseCode"]
                ) === index
            )
            .map((license: any) => {
              const licenseDetailIDs = result
                .filter(
                  (x) => x.licenseCode === license.licenseCode && !x.macID
                )
                .map((x) => x.licenseDetailID);
              const total = (result.filter(
                (x: any) => x.licenseCode === license.licenseCode
              ).length / license.licensePerSet) as number;
              let totalUsed = result.filter(
                (x: any) =>
                  x.licenseCode === license.licenseCode &&
                  x.macID &&
                  x.assignedTo === "0"
              ).length as number;
              const totalAssigned = result.filter(
                (x: any) =>
                  x.licenseCode === license.licenseCode && x.assignedTo !== "0"
              ).length as number;
              const totalAssignedUsed = result.filter(
                (x: any) =>
                  x.licenseCode === license.licenseCode && x.assignedTo !== "0"
              ).length as number;
              totalUsed = ((totalUsed + totalAssigned) /
                license.licensePerSet) as number;
              const totalAvailable = (total - totalUsed) as number;
              const setList = this.articleStore.articles.find(
                (article) => article.setID === license.setID
              );
              return {
                licenseDetailIDs,
                licenseCodes: [license.licenseCode],
                total,
                totalUsed,
                totalAvailable,
                status: totalAvailable === -1 ? 2 : 1,
                setList: setList && { ...setList, licenseDetailIDs },
                setID: setList?.setID,
                setCode: setList?.setCode,
                setName: setList?.setName,
                hundCode: setList?.hundCode,
                licensePerSet: setList?.licensePerSet,
                devices: result
                  .filter(
                    (x: any) => x.licenseCode === license.licenseCode && x.macID
                  )
                  .map((license: any) => license.device),
              } as any;
            });

          if (this.articleStore.articles.length > 0) {
            const licenses = Array.from(
              this.licenses
                .reduce((acc: Map<any, any>, license: any) => {
                  const licenseAlreadyInAcc = acc.get(license.setID);
                  if (licenseAlreadyInAcc) {
                    acc.set(license.setID, {
                      ...licenseAlreadyInAcc,
                      ...license,
                      ...{
                        licenseCodes: [
                          ...licenseAlreadyInAcc.licenseCodes,
                          ...license.licenseCodes,
                        ],
                        licenseDetailIDs: [
                          ...licenseAlreadyInAcc.licenseDetailIDs,
                          ...license.licenseDetailIDs,
                        ],
                        total: licenseAlreadyInAcc.total + license.total,
                        totalUsed:
                          licenseAlreadyInAcc.totalUsed + license.totalUsed,
                        totalAvailable:
                          licenseAlreadyInAcc.totalAvailable +
                          license.totalAvailable,
                        status:
                          licenseAlreadyInAcc.total + license.totalAvailable ===
                          0
                            ? 2
                            : 1,
                        devices: [
                          ...licenseAlreadyInAcc.devices,
                          ...license.devices,
                        ],
                      },
                    });

                    return acc;
                  }

                  acc.set(license.setID, license);
                  return acc;
                }, new Map())
                .values()
            );

            this.licenses = [];
            this.licenses = licenses;
          }
        };

        const { result } = await this.licenseService.getAllData(props);
        initLicenses({ result });

        await this.articleStore.read();
        initLicenses({ result });
      } else {
        const initLicenses = ({ result }: { result: Models.License[] }) => {
          this.licenses = result
            .filter(
              (license: any, index) =>
                result.findIndex(
                  (a: any) => a["licenseCode"] === license["licenseCode"]
                ) === index
            )
            .map((license: any) => {
              const licenseDetailIDs = result
                .filter(
                  (x) => x.licenseCode === license.licenseCode && !x.macID
                )
                .map((x) => x.licenseDetailID);
              const total = result.filter(
                (x: any) => x.licenseCode === license.licenseCode
              ).length as number;
              const totalUsed = result.filter(
                (x: any) => x.licenseCode === license.licenseCode && x.macID
              ).length as number;
              const totalAvailable = (total - totalUsed) as number;
              const setList = this.articleStore.articles.find(
                (article) => article.setID === license.setID
              );
              return {
                licenseDetailIDs,
                licenseCodes: [license.licenseCode],
                total,
                totalUsed,
                totalAvailable,
                status: totalAvailable === 0 ? 2 : 1,
                setList: setList && { ...setList, licenseDetailIDs },
                setID: setList?.setID,
                setCode: setList?.setCode,
                setName: setList?.setName,
                licensePerSet: setList?.licensePerSet,
                hundCode: setList?.hundCode,
                devices: result
                  .filter(
                    (x: any) => x.licenseCode === license.licenseCode && x.macID
                  )
                  .map((license: any) => license.device),
              } as any;
            });

          if (this.articleStore.articles.length > 0) {
            const licenses = Array.from(
              this.licenses
                .reduce((acc: Map<any, any>, license: any) => {
                  const licenseAlreadyInAcc = acc.get(license.setID);
                  if (licenseAlreadyInAcc) {
                    acc.set(license.setID, {
                      ...licenseAlreadyInAcc,
                      ...license,
                      ...{
                        licenseCodes: [
                          ...licenseAlreadyInAcc.licenseCodes,
                          ...license.licenseCodes,
                        ],
                        licenseDetailIDs: [
                          ...licenseAlreadyInAcc.licenseDetailIDs,
                          ...license.licenseDetailIDs,
                        ],
                        total: licenseAlreadyInAcc.total + license.total,
                        totalUsed:
                          licenseAlreadyInAcc.totalUsed + license.totalUsed,
                        totalAvailable:
                          licenseAlreadyInAcc.totalAvailable +
                          license.totalAvailable,
                        status:
                          licenseAlreadyInAcc.total + license.totalAvailable ===
                          0
                            ? 2
                            : 1,
                        devices: [
                          ...licenseAlreadyInAcc.devices,
                          ...license.devices,
                        ],
                      },
                    });

                    return acc;
                  }

                  acc.set(license.setID, license);
                  return acc;
                }, new Map())
                .values()
            );

            this.licenses = [];
            this.licenses = licenses;
          }
        };

        const { result } = await this.licenseService.getAllData(props);
        initLicenses({ result });

        await this.articleStore.read();
        initLicenses({ result });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async readInviteLicense(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const initLicenses = ({ result }: { result: Models.License[] }) => {
        this.licenses = result
          // .filter(
          //   (license: any, index) =>
          //     result.findIndex(
          //       (a: any) => a["licenseCode"] === license["licenseCode"]
          //     ) === index
          // )
          .map((license: any) => {
            const licenseDetailIDs = result
              .filter((x) => x.licenseCode === license.licenseCode && !x.macID)
              .map((x) => x.licenseDetailID);
            const total = result.filter(
              (x: any) => x.licenseCode === license.licenseCode
            ).length as number;
            const totalUsed = result.filter(
              (x: any) => x.licenseCode === license.licenseCode && x.macID
            ).length as number;
            const totalAvailable = (total - totalUsed) as number;
            const setList = this.articleStore.articles.find(
              (article) => article.setID === license.setID
            );
            return {
              licenseDetailIDs,
              licenseCodes: [license.licenseCode],
              total,
              totalUsed,
              totalAvailable,
              status: totalAvailable === 0 ? 2 : 1,
              setList: setList && { ...setList, licenseDetailIDs },
              setID: setList?.setID,
              setCode: setList?.setCode,
              setName: setList?.setName,
              licensePerSet: setList?.licensePerSet,
              hundCode: setList?.hundCode,
              devices: result
                .filter(
                  (x: any) => x.licenseCode === license.licenseCode && x.macID
                )
                .map((license: any) => license.device),
            } as any;
          });

        if (this.articleStore.articles.length > 0) {
          const licenses = Array.from(
            this.licenses
              .reduce((acc: Map<any, any>, license: any) => {
                const licenseAlreadyInAcc = acc.get(license.setID);
                if (licenseAlreadyInAcc) {
                  acc.set(license.setID, {
                    ...licenseAlreadyInAcc,
                    ...license,
                    ...{
                      licenseCodes: [
                        ...licenseAlreadyInAcc.licenseCodes,
                        ...license.licenseCodes,
                      ],
                      licenseDetailIDs: [
                        ...licenseAlreadyInAcc.licenseDetailIDs,
                        ...license.licenseDetailIDs,
                      ],
                      total: licenseAlreadyInAcc.total + license.total,
                      totalUsed:
                        licenseAlreadyInAcc.totalUsed + license.totalUsed,
                      totalAvailable:
                        licenseAlreadyInAcc.totalAvailable +
                        license.totalAvailable,
                      status:
                        licenseAlreadyInAcc.total + license.totalAvailable === 0
                          ? 2
                          : 1,
                      devices: [
                        ...licenseAlreadyInAcc.devices,
                        ...license.devices,
                      ],
                    },
                  });

                  return acc;
                }

                acc.set(license.setID, license);
                return acc;
              }, new Map())
              .values()
          );

          this.licenses = [];
          this.licenses = licenses;
        }
      };

      const { result } = await this.licenseService.getAllCustomerInviteData(
        props
      );
      initLicenses({ result });

      const ETUID = this.userStore.user?.ETUID;
      const customerDealerId = this.userStore.user?.CustomerDealerID;
      await this.articleStore.readForCustomerInvite({
        userID: ETUID,
        customerDealerId: customerDealerId,
      });

      initLicenses({ result });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.licenses) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.action public setSearch({ search }: Store.SetSearch.Props) {
    this.search = search;
  }

  @Mobx.action public setStatus({ status }: Store.SetStatus.Props) {
    this.status = status;
  }

  @Mobx.action public setCustomer({ customer }: Store.SetCustomer.Props) {
    this.customer = customer;
  }

  @Mobx.computed public get filtered() {
    if (!this.licenses) {
      return [];
    }

    return this.licenses
      .filter((func: any) => {
        if (this.status === undefined) {
          return true;
        }
        return func.status === this.status;
      })
      .filter((device: any) => {
        if (!this.search) {
          return true;
        }

        return (
          ((this.userStore.user?.RoleName === "admin" ||
            this.userStore.user?.RoleName === "superadmin") &&
            device?.setCode
              ?.toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (this.userStore.user?.RoleName === "customer" &&
            device?.hundCode
              ?.toLowerCase()
              .includes(this.search.toLowerCase())) ||
          device?.setName?.toLowerCase().includes(this.search.toLowerCase()) ||
          device?.licenseCode?.toLowerCase().includes(this.search.toLowerCase())
        );
      });
  }

  @Mobx.computed public get showAdminCustomerSelectionInfo() {
    if (
      this.userStore.user?.RoleName !== "admin" &&
      this.userStore.user?.RoleName !== "superadmin"
    ) {
      return false;
    }

    if (this.licenses) {
      return false;
    }

    if (this.customer) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get showCustomerNoRecordsInfo() {
    if (this.userStore.user?.RoleName !== "customer") {
      return false;
    }

    if (!this.licenses) {
      return false;
    }

    if (this.licenses.length) {
      return false;
    }

    return true;
  }
  @Mobx.computed public get showDealerNoRecordsInfo() {
    if (this.userStore.user?.RoleName !== "dealer") {
      return false;
    }

    if (!this.licenses) {
      return false;
    }

    if (this.licenses.length) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get showAdminNoRecordsInfo() {
    if (
      this.userStore.user?.RoleName !== "admin" &&
      this.userStore.user?.RoleName !== "superadmin"
    ) {
      return false;
    }

    if (!this.licenses) {
      return false;
    }

    if (this.licenses.length) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get getArticlesFromLicenses(): Models.Set.Article[] {
    if (!this.licenses?.length) return [];

    return this.licenses
      .map((license: any) => license.setList)
      .filter((article) => article);
  }

  @Mobx.action
  public async getManualLicenseAvailableList(props: Store.Read.Props) {
    //licenseRequest: Services.License.GetAllData.Body props: Store.Read.Props,
    try {
      const licenseResponse =
        await this.licenseService.getAllCustomerInviteData(props);

      licenseResponse.result.map((k) => k);

      var licenseAvailableList = licenseResponse.result.filter(
        (value, index, List) => {
          return (
            List.findIndex(
              (v) =>
                v.setID === value.setID &&
                v.assignedCustomerID === 0 &&
                v.status === 1
            ) === index
          );
        }
      );

      this.manualLicensesAvailableList = licenseAvailableList;
      return this.manualLicensesAvailableList;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
