import * as Mui from "@material-ui/core";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Containers from "src/app/containers";
import * as Models from "src/models";
import * as DateFns from "date-fns";
import * as Components from "src/app/components";

export declare namespace Card {
  export interface Props {
    order: Models.Order.Main;
    handleClick?(props: { orderId: number | string , OrderedOn: any }): void;
    
  }
}
// eslint-disable-next-line no-redeclare

// eslint-disable-next-line no-redeclare
export function Card({ order, handleClick }: Card.Props) {
  const [language,setLanguage] = React.useState('en-IN');
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language
  React.useEffect(()=>{
    if(languageValue === "en"){
      setLanguage('en-IN')
    }else if(languageValue === "fr")
    {
      setLanguage('fr-FR')
    }
    else{
      setLanguage('de-DE')
    }
  },[languageValue])
  const CardInternals = (
    <>
      <Mui.CardHeader
        title={
          <>
            <Mui.Typography variant="body2" color="textSecondary">
              <ReactI18next.Trans i18nKey="Order #">Order #</ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography>{order.dataFrom === "webshop" && order.sageOLReferenceBillID != null && order.sageOLReferenceBillID > 0 && order.assignedTo === "0"? order.orderCode+"-"+order.sageOLReferenceBillID : order.orderCode}</Mui.Typography>
          </>
        }
      />
      <Mui.CardContent>
        
        <Containers.ShowIfAuthorised roles={["dealer","customer","admin"]}>
          <>
            <Mui.Typography variant="body2" color="textSecondary" style={{marginBottom:"4px"}}>
              <ReactI18next.Trans i18nKey=" Status">
              Status
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography>
            {/* {DateFns.format(new Date(order.orderedOn), "dd.MM.yyyy")} */}
            </Mui.Typography>
            <Components.OrderStatus 
              status={order?.status   as 1 | 2 | 3 | 4 | 5}
    
              
               />
          </>
        </Containers.ShowIfAuthorised>
      </Mui.CardContent>
    </>
  );
  
  
  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea
          onClick={() => handleClick({ orderId: order.orderID,OrderedOn: order.orderedOn})}
        >
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        CardInternals
      )}
    </Mui.Card>
  );
}
