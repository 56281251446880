/** @format */

import React from "react";
import { Contexts } from "src/app";
import { License } from "src/app/pages";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import * as App from "src/app";


export declare namespace Article {
  export interface IArticle {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Article.IArticle;

  export namespace GetAllData {
    export interface Props {
      status?: number;
      isManualActivation?: boolean;
      ETUID?: any;
      roleName?: string;
    }

    export type Return = Models.API.Response<Models.Set.Article[]>;
    export type LicenseReturn = Models.API.Response<Models.License[]>;
  }

  export namespace GetAllPurchanseSetData {
    export interface Props {
      orderID?: string | any;
    }
    export type Return = Models.API.Response<Models.Set.PurchangeSetDeatils[]>;
  }

  export namespace GetAllSetData {
    export interface Props {
      userID?: string | null;
      customerDealerId?: string | null;
    }
    export type Return = Models.API.Response<Models.Set.Article[]>;
  }

  export namespace GetAllDataWithPrice {
    export interface Props {
      status?: number;
      customerDealerId: string | number;
    }

    export type Return = Models.API.Response<Models.Set.Article[]>;
  }

  export namespace Create {
    export type Body = Models.Set.Article;

    export interface Props {
      body: Article.Create.Body;
      uid: string | number;
    }

    export type Return = Models.API.Response<number>;

    export namespace Langs {
      export type Body = Partial<Models.SetsLang>[];

      export interface Props {
        body: Article.Create.Langs.Body;
        uid: string | number;
      }

      export type Return = Models.API.Response<number>;
    }
  }

  export namespace Delete {
    export interface Props {
      articleId: string | number;
      uid: string | number;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace Activate {
    export interface Props {
      setId: string | number;
      uid: string | number;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class Article {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Article.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }
  public async getAllDataWithPrice({
    status,
    customerDealerId,
  }: Article.GetAllDataWithPrice.Props): Promise<Article.GetAllDataWithPrice.Return> {
    const url = this.urlFactory.create({
      named: ["Sets", "GetWithPrice", `${customerDealerId}`, `${status || 1}`],
    });

    const response =
      await this.httpManager.get<Article.GetAllDataWithPrice.Return>({
        url,
      });

    if (response.status === Constants.environment.apiStatus.Error) {
      throw new Error(response.result as unknown as string);
    }

    return response;
  }

  public async getAllData(
    props?: Article.GetAllData.Props
  ): Promise<Article.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Sets", "Get", `${props?.status ?? -1}`],
    });

    var response = await this.httpManager.get<Article.GetAllData.Return>({
      url,
    });

    if (response.status === Constants.environment.apiStatus.Error) {
      throw new Error(response.result as unknown as string);
    }
    if (props?.isManualActivation === true && props?.roleName === "customer") {
      const url = this.urlFactory.create({
        named: ["License", "GetAll", `${props.ETUID ?? 1}`],
      });

      const licenseResponse =
        await this.httpManager.get<Article.GetAllData.LicenseReturn>({
          url,
        });
      var licenseAvailableList = licenseResponse?.result.filter(
        (k) => k.status === 1 && k.assignedCustomerID === 0
      );
      const availableLicenseIDs = licenseAvailableList?.map((k) => k.setID);

      const availableArticleList = response.result.filter((k) =>
        availableLicenseIDs?.includes(k.setID)
      );
      var availableList: Services.Article.GetAllData.Return = {
        status: 200,
        result: availableArticleList,
        outputData: availableArticleList,
      };
      response = availableList;
      console.log("licenseResponse" + JSON.stringify(availableArticleList));
    }
    return response;
  }

  public async getAllSetForInviteData(
    props?: Article.GetAllSetData.Props
  ): Promise<Article.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: [
        "Sets",
        "GetSetwithCount",
        `${props?.userID}`,
        `${props?.customerDealerId}`,
      ],
    });

    const response = await this.httpManager.get<Article.GetAllData.Return>({
      url,
    });

    if (response.status === Constants.environment.apiStatus.Error) {
      throw new Error(response.result as unknown as string);
    }

    return response;
  }

  public async getAllPurchangeSetForInviteData(
    props?: Article.GetAllPurchanseSetData.Props
  ): Promise<Article.GetAllPurchanseSetData.Return> {
    const url = this.urlFactory.create({
      named: ["Sets", "GetPurchangeArticleSets", `${props?.orderID}`],
    });

    const response =
      await this.httpManager.get<Article.GetAllPurchanseSetData.Return>({
        url,
      });

    if (response.status === Constants.environment.apiStatus.Error) {
      throw new Error(response.result as unknown as string);
    }

    return response;
  }

  public async create({
    body,
    uid,
  }: Article.Create.Props): Promise<Article.Create.Return> {
    const url = this.urlFactory.create({
      named: ["Sets", "Create"],
    });

    const response = await this.httpManager.post<
      Article.Create.Return,
      Article.Create.Body
    >({
      url,
      body,
    });

    return response;
  }

  public async delete({
    articleId,
    uid,
  }: Article.Delete.Props): Promise<Article.Delete.Return> {
    const url = this.urlFactory.create({
      named: ["Sets", "Delete", `${articleId}`],
    });

    const response = await this.httpManager.get<Article.Create.Return>({
      url,
    });

    return response;
  }

  public async activate({
    setId,
    uid,
  }: Article.Activate.Props): Promise<Article.Activate.Return> {
    const url = this.urlFactory.create({
      named: ["Sets", "Activate", `${setId}`],
    });

    const response = await this.httpManager.get<Article.Create.Return>({
      url,
    });

    return response;
  }

  public async createLangs({
    body,
    uid,
  }: Article.Create.Langs.Props): Promise<Article.Create.Langs.Return> {
    const url = this.urlFactory.create({
      named: ["SetsLang"],
    });

    const response = await this.httpManager.post<
      Article.Create.Langs.Return,
      Article.Create.Langs.Body
    >({
      url,
      body,
    });

    return response;
  }
}
