/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as DateFns from "date-fns";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as DealerCustomer from "src/app/pages/dealercustomer";
import * as Models from "src/models";
import * as MuiIcon from "@material-ui/icons";

interface Data extends Models.DealerCustomer.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numselected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowcount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({
  rows,
  handleView,
}: {
  rows: Models.DealerCustomer.Main[];
  handleView(props: { avilableFunction: Models.Set.Function[] }): void;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("customerName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState<string>("");
  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.customerID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const navigate = Router.useNavigate();
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const [adminAndCustomerRoleCheck, setAdminAndCustomerRoleCheck] =
    React.useState(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  React.useEffect(() => {
    if (
      userRole === "admin" ||
      userRole === "superadmin" ||
      userRole === "customer"
    ) {
      setAdminAndCustomerRoleCheck(false);
    } else {
      setAdminAndCustomerRoleCheck(true);
    }
  }, [userRole, adminAndCustomerRoleCheck]);
  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: any;
  }) => {
    //alert(JSON.stringify(dealerProfile));
    const selectedIndex = dCustomerStore.selected.indexOf(dealerProfile);
    let newSelected: Models.CustomerLicense.Main[] = [];
    dCustomerStore.setSelected({ selected: newSelected });
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(dCustomerStore.selected, dealerProfile);
    } else if (selectedIndex === 0) {
      // alert("4");
      newSelected = newSelected.concat(dCustomerStore.selected.slice(1));
    } else if (selectedIndex === dCustomerStore.selected.length - 1) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(0, -1));
      // alert("5");
    } else if (selectedIndex > 0) {
      //alert("6");
      newSelected = newSelected.concat(
        dCustomerStore.selected.slice(0, selectedIndex),
        dCustomerStore.selected.slice(selectedIndex + 1)
      );
    }
    dCustomerStore.setSelected({ selected: newSelected });
    let arrayCustomer: any = [];
    arrayCustomer = newSelected;

    const customerName = arrayCustomer.map((k: any) => k.customerName);
    const mailID: any = arrayCustomer.map((k: any) => k.emailID);
    const companyName: any = arrayCustomer.map((k: any) => k.companyName);
    const customerID: any = arrayCustomer.map((k: any) => k.customerID);
    localStorage.setItem("mID", mailID);
    localStorage.setItem("cuName", customerName);
    localStorage.setItem("coName", companyName);
    localStorage.setItem("customerID", customerID);
    //dCustomerStore.setSelected();
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const [customerId, setCustomerId] = React.useState<number | string>();
  const [MailID, setMailID] = React.useState<number | string>();
  const [Row, setRow] = React.useState<any>();
  const [customerDetailsDialogOpen, setCustomerDetailsDialogOpen] =
    React.useState<boolean>(false);
  const uid = userStore.user?.ETUID;
  const roleName = "dealer";

  return (
    <div className={classes.root}>
      <Components.responsiveScreen
        fullscreenBreakpoint="md"
        open={customerDetailsDialogOpen}
        handleClose={() => setCustomerDetailsDialogOpen(false)}
        title={t("Customer details")}
        contentText={
          <DealerCustomer.Details
            uid={customerId}
            userRole={roleName}
            mailID={MailID}
            row={Row}
          />
        }
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => window.print()}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setCustomerDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
            
                   <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="customerDealerID"
                  label={t("Customer dealer ID")}
                />
                <EnhancedTableHead
                  
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="companyName"
                  label={t("Company name")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="customerName"
                  label={t("Customer name")}
                />
               
                <EnhancedTableHead
                  classes={classes}
                  numselected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowcount={rows.length}
                  id="emailID"
                  label={t("Email ID")}
                />
                <Containers.ShowIfAuthorised
                  roles={["superadmin", "admin"]}
                >
                  <EnhancedTableHead
                    classes={classes}
                    numselected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowcount={rows.length}
                    id="etUserStatus"
                    label={t("Trial")}
                  />
                </Containers.ShowIfAuthorised>
                <Containers.ShowIfAuthorised
                  roles={["superadmin", "admin", "dealer"]}
                >
                  <EnhancedTableHead
                    rightmost
                    classes={classes}
                    numselected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowcount={rows.length}
                    id="etUserStatus"
                    label={t("Status")}
                  />
                </Containers.ShowIfAuthorised>
                
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.customerID as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    style={{ height: "70px" }}
                    hover
                    onClick={(event) => {
                      if (row.etUserStatus !== 5) {
                        handleClick(event, row.customerID as string);
                        handleView({
                          avilableFunction:
                            row.customerID as unknown as Models.Set.Function[],
                        });
                       
                        setCustomerId(row.customerID);
                        setMailID(row.emailID);
                        setRow(row);
                        if (row.etuid) {
                          navigate(
                            `/dealercustomer/customerdetail?q=${row.etuid}&m=${row.customerID}`
                          );
                        }
                      } else {
                        enqueueSnackbar(
                          t("Detail view not available for license unassigned"),
                          {
                            variant: "warning",
                            preventDuplicate: false,
                          }
                        );
                      }
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.customerID}
                    
                  >
                  
 
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.customerDealerID}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.companyName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.customerName}
                    </Components.Mui.TableCell>
                  

                    <Components.Mui.TableCell
                      align="left"
                      onClick={() => {
                        window.open(`mailto:${row.emailID}`);
                      }}
                    >
                      <Mui.Tooltip
                        title={
                          <ReactI18next.Trans i18nKey="click to open an mail">
                            click to open an mail
                          </ReactI18next.Trans>
                        }
                      >
                        <Mui.Link style={{ color: "black" }}>
                          {row.emailID}
                        </Mui.Link>
                      </Mui.Tooltip>
                    </Components.Mui.TableCell>
                    <Containers.ShowIfAuthorised
                      roles={["superadmin", "admin"]}
                    >
                    <Components.Mui.TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <Mui.Typography>
                          {row.etUserStatus > 1 ? "" : 3 ? "Enabled" :"Yet to enable"}
                        </Mui.Typography>
                      </Components.Mui.TableCell>
                      </Containers.ShowIfAuthorised>
                    <Containers.ShowIfAuthorised
                      roles={["superadmin", "admin", "dealer"]}
                    >
                      <Components.Mui.TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        rightmost
                      >
                        <Mui.Typography>
                          {row.etUserStatus > 0 ? (
                            <Mui.Grid container justify="space-between">
                              <Components.CustomerStatus
                                status={
                                  row.etUserStatus === 1
                                    ? 1
                                    : 3
                                }
                              />
                              <Mui.IconButton
                                size="medium"
                                style={{
                                  padding: theme.spacing(0.125),
                                  fontSize: "18px",
                                }}
                              >
                                <MuiIcon.ChevronRight
                                  style={{ fontSize: "30px" }}
                                  color="primary"
                                />
                              </Mui.IconButton>
                            </Mui.Grid>
                          ) : (
                            <Mui.Divider />
                          )}
                        </Mui.Typography>
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No customers found">
                        No customers found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows, display: "none" }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
     
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
      
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const [state, setState] = React.useState<any>();
  const userStore = React.useContext(App.Contexts.userStore);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const theme = Mui.useTheme();
  const [detailsDrawerOpen, setDetailsDrawerOpen] =
    React.useState<boolean>(false);
  const [searchParams] = Router.useSearchParams();

  const id = searchParams.get("roleId");

  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    dCustomerStore.delaerCustomer = [];
    // userStore.setPreviousUrl({ urls: "/dealercustomer?roles=all" });
    const userRole = "customer";
    if (uid !== undefined && uid !== null) {
      dCustomerStore.read({
        uid,
        userRole,
      });
    }
    console.log("dCustomerStore"+JSON.stringify(dCustomerStore.delaerCustomer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const { t } = ReactI18next.useTranslation();
  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  const [currentAvilableFunction, setCurrentAvilableFunction] =
    React.useState<Models.Set.Function[]>();
  
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);

  const handleChange = (event: any) => {
    if (event.target.value === "All") {
      setSearch("");
    } else {
      setSearch(event.target.value as string);
    }
  };
  return (
    <>
      <Mui.Grid
        xs={12}
        lg={12}
        md={12}
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.Grid item xs={6} lg={6} md={6}>
          <Mui.FormControl style={{ width: 300 }}>
            {dCustomerStore.delaerCustomer.length > 0 && (
              <Mui.TextField label={t("Search")} onChange={handleSearch} />
            )}
          </Mui.FormControl>
        </Mui.Grid>
       
        <Mui.Grid
          item
          xs={1}
          lg={1}
          md={1}
          style={{
            marginLeft: "2px",
          }}
        >
          
        </Mui.Grid>
      </Mui.Grid>
      {dCustomerStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {dCustomerStore.view === "LIST" && (
            <DealerCustomer.Views.CardDetails
              customers={dCustomerStore.delaerCustomer.filter((order) => {
                if (!search) {
                  return true;
                }
                return (
                  order?.companyName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.customerName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.licenseCount
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.emailID
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  (order.companyName &&
                    DateFns.format(new Date(order.companyName), "dd.MM.yyyy")
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                );
              })}
            />
          )}
          {dCustomerStore.view === "CARD" && (
            <DealerCustomer.Views.Cards
              customers={dCustomerStore.delaerCustomer.filter((customer) => {
                if (!search) {
                  return true;
                }
                return (
                  customer?.loginUserRole
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  customer?.customerName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  customer?.emailID
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  customer?.customerName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  customer?.customerDealerID
                    .includes(search) ||
                  customer?.companyName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  (customer.inviteDate &&
                    DateFns.format(new Date(customer.inviteDate), "dd.MM.yyyy")
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                );
              })}
            />
          )}
          {dCustomerStore.view === "TABULAR" && (
            <EnhancedTable
              handleView={({ avilableFunction }) => {
                setCurrentAvilableFunction(avilableFunction);
                toggleDetailsDrawer();
              }}
              rows={dCustomerStore.delaerCustomer.filter((order) => {
                if (!search) {
                  return true;
                }

                return (
                  order?.loginUserRole
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.customerName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.emailID
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.customerName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.companyName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.customerDealerID
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.licenseCount
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
                );
              })}
            />
          )}
        </>
      )}
    </>
  );
});
