import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as IndustrySegment from "src/app/pages/industry-segment";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

interface Data extends Models.IndustrySegment.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps &
    Pick<Components.Mui.TableCell.Props, "leftmost" | "rightmost"> & {
      id: string;
      label: string;
      numeric?: boolean;
      disablePadding?: boolean;
    }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({
  rows,
  handleEditClick,
  handleActivateClick,
  handleDeleteClick,
}: {
  rows: Models.IndustrySegment.Main[];
  handleEditClick(props: {
    industrySegment: Models.IndustrySegment.Main;
  }): void;
  handleDeleteClick(props: { id: string | number }): void;
  handleActivateClick(props: { id: string | number }): void;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    setPage(0);
  }, [rows.length]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map(
        (n) => (n.industrySegmentsId as unknown) as string
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead
                leftmost
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="name"
                label={t("Name")}
              />

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="status"
                label={t("Status")}
              />

              <Components.Mui.TableCell
                color={theme.palette.divider}
                rightmost
                align="right"
              >
                <ReactI18next.Trans i18nKey="Actions">
                  Actions
                </ReactI18next.Trans>
              </Components.Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            <tr></tr>
          </Mui.TableBody>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(
                  row.industrySegmentsId as string
                );
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) =>
                      handleClick(event, row.industrySegmentsId as string)
                    }
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.industrySegmentsId}
                    // selected={isItemSelected}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.name}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      <Components.WorkingStatus status={row.status as 1 | 2} />
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell rightmost align="right">
                      <Mui.Box
                        maxWidth="fit-content"
                        marginLeft="auto"
                        display="grid"
                        gridAutoFlow="column"
                        gridColumnGap={5}
                      >
                        <Mui.IconButton
                          onClick={() =>
                            handleEditClick({
                              industrySegment: row as Models.IndustrySegment.Main,
                            })
                          }
                          size="small"
                          style={{
                            padding: theme.spacing(0.125),
                          }}
                        >
                          <Mui.Box fontSize={26} lineHeight={0}>
                            <i className="icon-etc-edit" />
                            {/* <MuiIcons.Edit /> */}
                          </Mui.Box>
                        </Mui.IconButton>

                        {row.status === 1 ? (
                          <Mui.IconButton
                            onClick={() =>
                              handleDeleteClick({ id: row.industrySegmentsId })
                            }
                            size="small"
                            style={{
                              padding: theme.spacing(0.125),
                            }}
                          >
                            <Mui.Box fontSize={26} lineHeight={0}>
                              <i className="icon-etc-deactivate" />
                              {/* <MuiIcons.Delete /> */}
                            </Mui.Box>
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() =>
                              handleActivateClick({
                                id: row.industrySegmentsId,
                              })
                            }
                            size="small"
                            style={{
                              padding: theme.spacing(0.125),
                            }}
                          >
                            <Mui.Box fontSize={26} lineHeight={0}>
                              <i className="icon-etc-activate" />
                              {/* <MuiIcons.Delete /> */}
                            </Mui.Box>
                          </Mui.IconButton>
                        )}
                      </Mui.Box>
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          page={page + 1}
          count={Math.ceil(rows.length / rowsPerPage)}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
     
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [editDialogOpen, setEditDialogOpen] = React.useState<
    Models.IndustrySegment.Main
  >();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<
    number | string
  >();
  const [activateDialogOpen, setActivateDialogOpen] = React.useState<
    number | string
  >();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const industrySegmentStore = React.useContext(
    App.Contexts.industrySegmentStore
  );
  const theme = Mui.useTheme();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const industrySegmentService = new Services.IndustrySegment({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    industrySegmentStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value as string;
    industrySegmentStore.setSearch({ search });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as string | number;
    if (typeof status === "string" && status !== "") {
      return;
    }

    if (status === "") {
      industrySegmentStore.setStatus({ status: undefined });
      return;
    }

    industrySegmentStore.setStatus({ status });
  };

  async function handleDeleteFunction() {
    setIsLoading(true);
    const uid = userStore.user?.uid;
    if (deleteDialogOpen === undefined || uid === undefined) {
      
      return;
    }
    try {
      await industrySegmentService.delete({
        industrySegmentsId: deleteDialogOpen,
        uid,
      });
      enqueueSnackbar(t("Industry/Segments successfully deleted!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setDeleteDialogOpen(undefined);
      industrySegmentStore.read();
    }
  }
  async function handleActivateFunction() {
    setIsLoading(true);
    const uid = userStore.user?.uid;
    if (activateDialogOpen === undefined || uid === undefined) {
      
      return;
    }
    try {
      await industrySegmentService.activate({
        industrySegmentsId: activateDialogOpen,
        uid,
      });
      enqueueSnackbar(t("Industry/Segments successfully activated!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setActivateDialogOpen(undefined);
      industrySegmentStore.read();
    }
  }

  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Components.ResponsiveFullscreenDialogWithScroll
        open={deleteDialogOpen !== undefined ? true : false}
        handleClose={() => {
          setDeleteDialogOpen(undefined);
        }}
        title={t("Delete group")}
        contentText={t(
          "Are you sure you want to delete this Industry / Segment group?"
        )}
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => {
                setDeleteDialogOpen(undefined);
              }}
            >
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              onClick={() => handleDeleteFunction()}
              disabled={isLoading}
              {...(!isLoading && {
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                },
              })}
            >
              <ReactI18next.Trans i18nKey="Delete">Delete</ReactI18next.Trans>
              {isLoading && (
                <Mui.CircularProgress
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  color={"inherit"}
                  size={theme.typography.button.fontSize}
                />
              )}
            </Mui.Button>
          </>
        }
      />

      <Components.ResponsiveFullscreenDialogWithScroll
        open={activateDialogOpen !== undefined ? true : false}
        handleClose={() => {
          setActivateDialogOpen(undefined);
        }}
        title={t("Activate group")}
        contentText={t(
          "Are you sure you want to activate this Industry / Segment group?"
        )}
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => {
                setActivateDialogOpen(undefined);
              }}
            >
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              onClick={() => handleActivateFunction()}
              disabled={isLoading}
              {...(!isLoading && {
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                },
              })}
            >
              <ReactI18next.Trans i18nKey="Activate">
                Activate
              </ReactI18next.Trans>
              {isLoading && (
                <Mui.CircularProgress
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  color={"inherit"}
                  size={theme.typography.button.fontSize}
                />
              )}
            </Mui.Button>
          </>
        }
      />
      {industrySegmentStore.createDialogOpen && (
        <IndustrySegment.Create
          open={industrySegmentStore.createDialogOpen}
          handleClose={() => {
            industrySegmentStore.setCreateDialogOpen({
              createDialogOpen: false,
            });
          }}
        />
      )}
      {editDialogOpen && (
        <IndustrySegment.Edit
          open={editDialogOpen !== undefined ? true : false}
          industrySegment={editDialogOpen}
          handleClose={() => {
            setEditDialogOpen(undefined);
          }}
        />
      )}
      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.FormControl style={{ width: 300, marginRight: theme.spacing(2) }}>
          <Mui.InputLabel id="demo-simple-select-outlined-label">
            <ReactI18next.Trans i18nKey="Filter by status">
              Filter by status
            </ReactI18next.Trans>
          </Mui.InputLabel>
          <Mui.Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={industrySegmentStore.status || ""}
            onChange={handleChange}
            label={t("Filter by status")}
          >
            <Mui.MenuItem value="">
              <em>
                <ReactI18next.Trans i18nKey="None">None</ReactI18next.Trans>
              </em>
            </Mui.MenuItem>

            <Mui.MenuItem value={1}>
              <Components.WorkingStatus status={1} />
            </Mui.MenuItem>

            <Mui.MenuItem value={2}>
              <Components.WorkingStatus status={2} />
            </Mui.MenuItem>
          </Mui.Select>
        </Mui.FormControl>
        <Mui.FormControl style={{ width: 300 }}>
         {industrySegmentStore.industrySegments.length > 0 && (<Mui.TextField label={t("Search")} onChange={handleSearch} />)}
        </Mui.FormControl>
      </Mui.Grid>
      {industrySegmentStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          <EnhancedTable
            handleEditClick={({ industrySegment }) => {
              setEditDialogOpen(industrySegment);
            }}
            handleDeleteClick={({ id }) => {
              setDeleteDialogOpen(id);
            }}
            handleActivateClick={({ id }) => {
              setActivateDialogOpen(id);
            }}
            rows={industrySegmentStore.filtered}
          />
        </>
      )}
    </>
  );
});
