import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Activate from "src/app/pages/license/activate";
import * as Yup from "yup";
import * as ReactI18next from "react-i18next";

export interface FormProps {
  machineId: string;
  customerName?: string;
  machineName?: string;
  remark?: string;
}

export const Main = Mobx.observer(function () {
  const navigate = Router.useNavigate();
  const { t } = ReactI18next.useTranslation();
  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const licenseStore = React.useContext(App.Contexts.license.store);

  const validationSchema = Yup.object().shape<FormProps>({
    machineId: Yup.string().required(t("Please enter Machine ID here")),
  });

  return (
    <>
      <Formik.Formik<FormProps>
        initialValues={{
          machineId: "",
          customerName: "",
          machineName: "",
          remark: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async ({ machineId, ...restOfValues }, { setSubmitting }) => {
          try {
            const selectedArticles = activateStore.getAvailableArticlesFromLicenses.filter(
              (article) => activateStore.selected.includes(article.setID)
            );

            const installedArticles =
              activateStore.getInstalledArticlesFromLicenses;

            if (selectedArticles.length === 0) return;

            if (
              ![...selectedArticles, ...installedArticles].find(
                (article) =>
                  article.setName.toLowerCase().includes("os") ||
                  article.setName.toLowerCase().includes("basic")
              )
            )
              return;

            if (
              activateStore.selected.length > 0 &&
              !activateStore.isDependentArticlesValid
            )
              return;

            const activatePromises = selectedArticles.map((article) =>
              licenseStore.activate({
                body: {
                  ...restOfValues,
                  Status: 2,
                  MacID: machineId,
                  LicenseDetailID: (article as any)?.licenseDetailIDs[0],
                  ActivationAck: 0,
                  ActivationKey: "NA",
                  DeviceDetail: {
                    ...restOfValues,
                    CustomerDeviceID: "",
                    DeviceName: "",
                    MacID: machineId,
                    SerialNumber: "",
                    Status: 1,
                  },
                },
              })
            );
            await Promise.all(activatePromises);
            const {
              result: { licenseKey },
            } = await licenseStore.getLicenseKeyAfterActivation({
              body: {
                MacId: machineId,
                Sets: selectedArticles.map((article) => article.setID),
                CustomerName: restOfValues.customerName,
                Remarks: restOfValues.remark,
              },
            });

            activateStore.setActivated({
              activated: {
                licenseKey,
                sets: selectedArticles,
              },
            });

            navigate("success");
          } catch (error) {
            console.error(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {() => <Activate.Layout />}
      </Formik.Formik>
    </>
  );
});
