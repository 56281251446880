/** @format */

import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18Next from "react-i18next";
import * as App from "src/app";
import AppMenuItem from "./AppMenuItem";
const useStyles = Mui.makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  themeTogglePaperRoot: {
    position: "fixed",
    top: `calc(50vh - (119px + ${theme.spacing(-3)}px))`,
    left: 93,
    marginLeft: 10,
    maxWidth: "fit-content",
    boxShadow: "none",
    display: "block",
    backgroundColor: theme.palette.background.default,
  },
}));

export const Drawer = Mobx.observer(function () {
  const theme = Mui.useTheme();
  const classes = useStyles();
  const { t } = ReactI18Next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);
  const globalTenantStore = React.useContext(App.Contexts.globalTenantStore);
  const [open, setOpen] = React.useState(false);

  function setOpensData() {
    window.location.pathname === "/dealercustomer"
      ? setOpen(!open)
      : setOpen(false);
  }

  React.useEffect(() => {
    const etuid = userStore.user?.ETUID;
    if (!etuid) {
      return;
    }
    globalTenantStore.read({ etuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawerItems = [
    {
      name: t("Dashboard"),
      Icon: "Dashboard",
      route: "/dashboard",
      roles: ["superadmin","admin", "customer", "EU", "dealer"],
    },
    {
      name: "Apps",
      Icon: "Apps",
      route: "/shop",
      roles: ["customer", "EU","dealer"],
    },
    {
      name: t("Orders"),
      Icon: "Orders",
      route: "/Orders",
      roles: ["superadmin","admin", "customer", "EU", "dealer"],
    },
    {
      name: t("Users"),
      Icon: "Users",
      route: "menu",
      roles: ["superadmin","admin"],
      items: [
        {
          name: t("Customers"),
          Icon: "Customers",
          route: "/dealercustomer",
          roles: ["customer", "EU", "dealer", "admin","superadmin"],
        },
        {
          name: t("Dealers"),
          Icon: "Dealers",
          route: "/dealercustomer/managedealer",
          roles: ["customer", "EU", "dealer", "admin","superadmin"],
        },
        {
          name: t("Admin"),
          Icon: "Administrator",
          route: "/dealercustomer/manageadmin",
          roles: ["superadmin","admin"],
        },
      ],
    },
    {
      name: t("My Customers"),
      Icon: "Customers",
      route: "/dealercustomer",
      roles: ["dealer"],
    },
    {
      name: t("Articles"),
      Icon: "Articles",
      route: "/articles",
      roles: ["superadmin","admin"],
    },
    {
      name: t("Functions"),
      Icon: "Functions",
      route: "/functions",
      roles: ["superadmin","admin"],
    },
    {
      name: t("Industry / Segments"),
      Icon: "Segments",
      route: "/industry-segments",
      roles: ["superadmin","admin"],
    },
    {
      name: t("Licenses"),
      Icon: "Licenses",
      route: "/licenses",
      roles: ["superadmin","admin", "customer", "EU", "dealer"],
    },
    {
      name: t("Terminal devices"),
      Icon: "Terminal devices",
      route: "/easy-touch-devices",
      roles: ["superadmin","admin", "customer", "EU"],
    },
    {
      name: t("Icons"),
      Icon: "icon-etc-icons",
      route: "/icons",
      roles: ["superadmin"],
    },
    {
      name: t("Tenants"),
      Icon: "icon-etc-tenant-creation",
      route: "/tenants",
      roles: ["superadmin"],
    },
    {
      name: t("Customers"),
      Icon: "Customers",
      route: "/customers",
      roles: [],
    }
  ];

  return (
    <div>
      <div className={classes.toolbar} />
      <Mui.List disablePadding>
        {drawerItems
          .filter((item) =>
            item.roles.includes(userStore.user?.RoleName as string)
          )
          .map((item, index) => (
            <AppMenuItem {...item} key={index} />
          ))}
      </Mui.List>
    </div>
  );
});
