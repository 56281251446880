import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as Components from "src/app/components";
import * as Icon from "src/app/pages/icon";
import * as Formik from "formik";
import * as App from "src/app";
import * as Services from "src/services";
import * as Constants from "src/constants";
import * as ReactI18next from "react-i18next";

export declare namespace Upload {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  >;
}

// eslint-disable-next-line no-redeclare
export const Upload = Mobx.observer(function ({
  open,
  handleClose,
}: Upload.Props) {
  const { t } = ReactI18next.useTranslation();

  const iconService = new Services.Icon({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  async function handleCreate(
    values: Icon.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Icon.Form.Values>
  ) {
    try {
      const formData = new FormData();
      formData.append("css", values.cssFile, values.cssFile.name);
      formData.append("eot", values.eotFile, values.eotFile.name);
      formData.append("svg", values.svgFile, values.svgFile.name);
      formData.append("ttf", values.ttfFile, values.ttfFile.name);
      formData.append("woff", values.woffFile, values.woffFile.name);

      await iconService.upload({ formData: formData });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }

  return (
    <>
      <Formik.Formik
        initialValues={
          ({
            cssFile: "",
            eotFile: "",
            svgFile: "",
            ttfFile: "",
            woffFile: "",
          } as unknown) as Icon.Form.Values
        }
        validationSchema={Icon.Shared({ t }).Form.validationSchema}
        onSubmit={handleCreate}
      >
        {({ submitForm, values }) => (
          <Components.ResponsiveFullscreenDialogWithScroll
            open={open}
            handleClose={handleClose}
            title={t("Upload Icons")}
            contentText={<Icon.Form />}
            actions={
              <>
                <Mui.Button variant="contained" onClick={handleClose}>
                  <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>

                </Mui.Button>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    Formik.validateYupSchema(
                      values,
                      Icon.Shared({ t }).Form.validationSchema
                    );
                    submitForm();
                  }}
                >
                  <ReactI18next.Trans i18nKey="Save">Save</ReactI18next.Trans>
                </Mui.Button>
              </>
            }
          />
        )}
      </Formik.Formik>
    </>
  );
});
