/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD" | "LIST";
  export interface IStore {
    isLoading: boolean;
    manageDealer: Models.ImportDealer.Main[];
    manageDealerService: Services.ImportDealer;
    view: Store.View;
  }

  export type Options = Pick<IStore, "view" | "manageDealerService">;

  export namespace Read {
    export interface Props extends Services.ImportDealer.GetAllData.Props {}
  }

  export namespace SetView {
    export interface Props extends Pick<Store.IStore, "view"> {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public view: Store.View = "TABULAR";
  @Mobx.observable public manageDealer: Models.ImportDealer.Main[] = [];
  @Mobx.observable public selected: Models.ImportDealer.DealerUser[] = [];
  @Mobx.observable public isLoading: boolean = false;
  public manageDealerService: Services.ImportDealer;

  constructor({ view, manageDealerService }: Store.Options) {
    this.view = view;
    this.manageDealerService = manageDealerService;
  }

  @Mobx.action setView({ view }: Store.SetView.Props) {
    this.view = view;
  }

  @Mobx.action public async readUsers(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.manageDealerService.getManageDealerCompanyDetails(
        props
      );
      this.manageDealer = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
