import * as React from "react";
import * as Mui from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { withStyles } from "@material-ui/core";
import * as ReactI18Next from "react-i18next";
import * as App from "src/app";
export declare namespace QuickInfoTile {
  export interface Props {
    label: React.ReactNode;
    iconName: string;
    isColorChange: boolean;
    title: React.ReactNode;
    titleSuffix?: React.ReactNode;
    body: React.ReactNode;
    handleClick?(): void;
    isTooltip?: boolean;
    toolTipText?: React.ReactNode;
    paddingRight?: string;
    paddingTop?: string;
  }
}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(16),
    border: '2px solid #dadde9',
    marginTop:"-78px",
  },
}))(Mui.Tooltip);

export function QuickInfoTile({
  handleClick,
  label,
  iconName,
  isColorChange,
  title,
  titleSuffix,
  body,
  isTooltip = false,
  toolTipText = "",
  paddingRight = "24px",
  paddingTop = "24px",
}: QuickInfoTile.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18Next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);
  const CardInternals = (<>
    
    <div style={{ display: "flex", paddingRight: paddingRight,paddingLeft: theme.spacing(4),paddingBottom: theme.spacing(4),paddingTop: paddingTop }}>
      <div
        style={{
          padding: theme.spacing(1),
          paddingRight: theme.spacing(3),
          width: "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // borderRight: `1px solid ${theme.palette.divider}`,
        }}
      >
        
        <Mui.Typography
          style={{
            color: Mui.lighten(theme.palette.text.primary, 0.65),
          }}
          variant="h3"
          align="center"
        >
          <i className={iconName} />
        </Mui.Typography>
        <Mui.Typography
          style={{
            color: Mui.lighten(theme.palette.text.primary, 0.65),
          }}
          variant="body1"
          align="center"
        >
          {label}
        </Mui.Typography>
       
      </div>
      <div
      style={{
         marginLeft: "14px",
          border: `1px solid ${theme.palette.divider}`,
        }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          justifyContent: "center",
          alignItems: "center",
          
        }}
      >
        {isTooltip && userStore?.user?.RoleName === "customer"  && (
        <HtmlTooltip style={{
        marginLeft: "auto",
        marginRight: "0",
        float:"right",
        wordWrap:"break-word", 
        color:theme.palette.primary.main}} 
        title= {toolTipText!}
        >
        <Mui.Grid >
        <InfoIcon/>
        </Mui.Grid>
        </HtmlTooltip>
        )}
        <Mui.CardContent >
        
          <Mui.Typography
            variant="h3"
            align="center"
            style={{
              fontWeight: 700,
              color: !isColorChange ? Mui.lighten(theme.palette.text.primary, 0.5) : theme.palette.success.main,
            }}
          >
            {title}
          </Mui.Typography>
          <Mui.Typography
            variant="h6"
            align="center"
            style={{
              fontWeight: 600,
              color: Mui.lighten(theme.palette.text.primary, 0.5),
              textTransform: "uppercase",
            }}
          >
            {body}
          </Mui.Typography>
        </Mui.CardContent>
      </div>
    </div>
    </>
  );

  return (
    <>
      <Mui.Card>
        {handleClick ? (
          <Mui.CardActionArea onClick={handleClick}>
            {CardInternals}
          </Mui.CardActionArea>
        ) : (
          CardInternals
        )}
      </Mui.Card>
    </>
  );
}
