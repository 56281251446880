/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as React from "react";
import * as Article from "src/app/pages/article";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as ReactI18next from "react-i18next";

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export declare namespace Cards {
  export type VARIANT = "DEFAULT" | "DETAILED";

  export interface Props extends Pick<Mui.GridProps, "spacing"> {
    selectedArticles?: { [Key: string]: boolean; [Key: number]: boolean };
    functions?: Models.Set.Function[];
    articles: Models.Set.Article[];
    handleClick?(props: { article: Models.Set.Article }): void;
    disablePagination?: boolean;
    cardsPerRow?: 2 | 3 | 4;
    variant?: Cards.VARIANT;
  }
}

// eslint-disable-next-line no-redeclare
export function Cards({
  selectedArticles,
  articles,
  handleClick,
  disablePagination,
  cardsPerRow,
  functions,
  spacing,
  variant = "DEFAULT",
}: Cards.Props) {
  const theme = Mui.useTheme();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(
    disablePagination ? articles.length : 16
  );

  React.useEffect(() => {
    setPage(0);
  }, [articles]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const cardsPerRowMapping = {
    xs: {
      2: 12,
      3: 12,
      4: 12,
    },
    md: {
      2: 12,
      3: 12,
      4: 6,
    },
    lg: {
      2: 6,
      3: 4,
      4: 3,
    },
  };

  return (
    <>
      <Mui.Grid
        container
        spacing={spacing || 3}
        style={{ marginBottom: theme.spacing(0.5) }}
      >
        {stableSort(articles as any, getComparator("asc", "setID"))
          .slice(
            ...(disablePagination
              ? [0]
              : [page * rowsPerPage, page * rowsPerPage + rowsPerPage])
          )
          .map((article, index) => (
            <Mui.Grid
              key={uuidv4()}
              item
              xs={cardsPerRowMapping["xs"][cardsPerRow || 4] as Mui.GridSize}
              md={cardsPerRowMapping["md"][cardsPerRow || 4] as Mui.GridSize}
              lg={cardsPerRowMapping["lg"][cardsPerRow || 4] as Mui.GridSize}
            >
              <Article.Card
                selected={(function () {
                  if (!selectedArticles) {
                    return undefined;
                  }

                  const selected = selectedArticles[article.setID];
                  return Boolean(selected);
                })()}
                functions={functions?.filter((func) =>
                  (article as Models.Set.Article).functions.includes(
                    func.setFunctionID
                  )
                )}
                handleClick={handleClick}
                article={(article as unknown) as Models.Set.Article}
                variant={variant}
              />
            </Mui.Grid>
          ))}
        {articles.length === 0 && (
          <Mui.Grid container>
            <Mui.Grid
              item
              style={{ marginTop: "29.5px", marginLeft: "12px" }}
              xs={12}
            >
              <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No articles found">No articles found</ReactI18next.Trans></MuiLab.Alert>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      {/* {articles.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(articles.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )} */}
    </>
  );
}
