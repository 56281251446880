import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Formik from "formik";
import * as ReactUndraw from "iblis-react-undraw";
import * as Mobx from "mobx-react-lite";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Shop from "src/app/pages/shop";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";

export const Checkout = Mobx.observer(function () {
  const cartStore = React.useContext(App.Contexts.cartStore);

  return (
    <>
      {(cartStore.cart.size === 0 ||
        (cartStore.cart.size > 0 && cartStore.billingAddress)) && (
        <CheckoutContent />
      )}
      {cartStore.cart.size > 0 && !cartStore.billingAddress && (
        <Components.Redirect to="/shop/cart" />
      )}
    </>
  );
});

export const CheckoutContent = Mobx.observer(function () {
  const cartStore = React.useContext(App.Contexts.cartStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const [searchParams] = Router.useSearchParams();
  const status = searchParams.get("status");

  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const navigate = Router.useNavigate();

  const addressServices = new Services.Address({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    const orderDetails = localStorage.getItem("orderDetails");

    if ((status === "failure" || status === "cancelled") && orderDetails) {
      cartStore.resetCart();
      navigate("/shop/payment/success");
      return;
    }

    if (
      (status === "failure" || status === "cancelled") &&
      !orderDetails &&
      cartStore.cart.size === 0
    ) {
      navigate("/shop");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {cartStore.cart.size === 0 ? (
        <Mui.Paper>
          <Mui.Box padding={2}>
            <ReactUndraw.SvgEmptyCart
              style={{
                backgroundImage: `radial-gradient(closest-side at 50%, ${theme.palette.text.disabled}, ${theme.palette.background.paper})`,
                marginBottom: theme.spacing(6),
              }}
              accentcolor={theme.palette.primary.main}
              primarycolor={theme.palette.secondary.main}
              height={300}
            />
            <Mui.Typography variant="h4" align="center" gutterBottom>
              <ReactI18next.Trans i18nKey="Empty cart">
                Empty cart
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography
              variant="body2"
              align="center"
              style={{ marginBottom: theme.spacing(4) }}
            >
              <ReactI18next.Trans i18nKey="There are no items in your cart">
                There are no items in your cart.
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
              <Mui.Button
                variant="contained"
                color="primary"
                component={Router.Link}
                to="/shop"
              >
                <ReactI18next.Trans i18nKey="Continue shopping">
                  Continue shopping
                </ReactI18next.Trans>
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </Mui.Paper>
      ) : (
        <Formik.Formik
          initialValues={{ accept: false }}
          validationSchema={Shop.Shared().Form.validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const customerDealerId = userStore.user?.CustomerDealerID;
              const etuid = userStore.user?.ETUID;
              // if (customerDealerId === undefined || customerDealerId === null) {
              //   throw new Error("customerDealerId is undefined or null");
              // }

              if (etuid === undefined || etuid === null) {
                throw new Error("etuid is undefined or null");
              }

              if (cartStore.billingAddress === undefined) {
                return;
              }

              const {
                outputData: billingAddressID,
              } = await addressServices.create({
                body: { ...cartStore.billingAddress, status: 1 },
              });
              const order: Models.Order.Creation = {
                orderCode: "",
                etuid,
                billingAddressID,
                subTotal: cartStore.total,
                totalTax: 0,
                discountAmount: 0,
                totalAmount: cartStore.total,
                paymentID: 0,
                orderedBy: etuid,
                orderedOn: new Date(),
                status: 1,
                orderDetails: Array.from(cartStore.cart.values()).map(
                  (item) =>
                    ({
                      orderDetailID: 0,
                      orderID: 0,
                      orderCode: "",
                      setID: item.setID,
                      unitPrice: item.setCost,
                      quantity: item.quantity,
                      discount: 0,
                      validFrom: new Date(),
                      validTo: new Date(
                        new Date().setFullYear(new Date().getFullYear() + 1),
                      ),
                    } as Models.OrderDetails),
                ),
                billingAddress: ({
                  addressID: undefined,
                  etuid: undefined,
                  firstName: undefined,
                  lastName: undefined,
                  companyName: undefined,
                  address1: undefined,
                  address2: undefined,
                  address3: undefined,
                  city: undefined,
                  state: undefined,
                  postalCode: undefined,
                  country: undefined,
                  status: undefined,
                } as unknown) as Models.AddressDetails,
                paymentDetails: {
                  paymentID: 0,
                  orderID: 0,
                  totalAmount: cartStore.total,
                  paymentTypeID: 1,
                  transDate: new Date(),
                  transCode: "SCC01",
                  transStatus: 1,
                  transMsg: t("Success"),
                  userIpAddress: "",
                },
              } as Models.Order.Creation;

              const { status, result } = await orderServices.create({
                body: order,
                customerDealerId: customerDealerId || 0,
              });
              if (status === Constants.environment.apiStatus.Success) {
          
                window.location = result;
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({ isSubmitting, errors }) => (
            <>
              {status === "cancelled" && (
                <Mui.Box marginBottom={2}>
                  <MuiLab.Alert severity="warning">
                    <MuiLab.AlertTitle>
                      <ReactI18next.Trans i18nKey="We couldn't process your payment">
                        We couldn't process your payment
                      </ReactI18next.Trans>
                    </MuiLab.AlertTitle>
                    <ReactI18next.Trans
                      i18nKey="Unfortunately, we couldn't collect payment on your purchase. Please take a moment to review your order and continue purchase."
                    >
                      Unfortunately, we couldn't collect payment on your
                      purchase. Please take a moment to review your order and
                      continue purchase.
                    </ReactI18next.Trans>
                  </MuiLab.Alert>
                </Mui.Box>
              )}

              {status === "failure" && (
                <Mui.Box marginBottom={2}>
                  <MuiLab.Alert severity="error">
                    <MuiLab.AlertTitle>
                      <ReactI18next.Trans i18nKey="Payment failure">
                        Payment failure
                      </ReactI18next.Trans>
                    </MuiLab.AlertTitle>
                    <Mui.Typography component="div" variant="body2">
                      <ReactI18next.Trans i18nKey="If you received a payment failure alert, it means the payment may have failed due to one of the following reasons">
                        If you received a payment failure alert, it means the payment may have failed due to one of the following reasons
                      </ReactI18next.Trans>
                      <ul>
                        <li>
                          <ReactI18next.Trans i18nKey="Payment was declined by your bank or credit card provider">
                            Payment was declined by your bank or credit card
                            provider
                          </ReactI18next.Trans>
                        </li>
                        <li>
                          <ReactI18next.Trans i18nKey="Credit card provided has expired">
                            Credit card provided has expired
                          </ReactI18next.Trans>
                        </li>
                        <li>
                          <ReactI18next.Trans i18nKey="Credit card has insufficient funds">
                            Credit card has insufficient funds
                          </ReactI18next.Trans>
                        </li>
                        <li>
                          <ReactI18next.Trans i18nKey="Credit card number provided is invalid">
                            Credit card number provided is invalid
                          </ReactI18next.Trans>
                        </li>
                        <li>
                          <ReactI18next.Trans i18nKey="Credit card provided has not been approved for recurring">
                            Credit card provided has not been approved for
                            recurring
                          </ReactI18next.Trans>
                          &nbsp;
                          <ReactI18next.Trans i18nKey="Payments">
                            Payments
                          </ReactI18next.Trans>
                        </li>
                      </ul>
                      <ReactI18next.Trans
                        i18nKey="For more information on why the payment failed, please contact your bank or credit card company."
                      >
                        For more information on why the payment failed, please
                        contact your bank or credit card company.
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </MuiLab.Alert>
                </Mui.Box>
              )}

              <Mui.Card>
                <Mui.CardHeader title={t("Your order")} />
                <Mui.CardContent>
                  <Mui.Table style={{ borderSpacing: "unset" }}>
                    <Mui.TableHead>
                      <Mui.TableRow>
                        <Mui.TableCell
                          style={{
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Product">
                            Product
                          </ReactI18next.Trans>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="right"
                          style={{
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Price incl. VAT">
                            Price incl. VAT
                          </ReactI18next.Trans>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="right"
                          style={{
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="PriceexclVAT">
                            Price excl. VAT
                          </ReactI18next.Trans>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="right"
                          style={{
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Quantity">
                            Quantity
                          </ReactI18next.Trans>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="right"
                          style={{
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Sub total">
                            Sub total
                          </ReactI18next.Trans>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                      {Array.from(cartStore.cart.values()).map((product) => (
                        <Mui.TableRow key={uuidv4()}>
                          <Mui.TableCell>
                            <Mui.Box display="flex" alignItems="baseline">
                              <Mui.Box
                                // color="primary.main"
                                marginRight={1}
                                component="span"
                                style={{
                                  fontSize: theme.typography.h5.fontSize,
                                }}
                              >
                                <i className={product.iconClass} />
                              </Mui.Box>
                              <Mui.Typography
                                // color="primary"
                                style={{ fontWeight: "bold" }}
                                variant="body1"
                              >
                                {product.setName}
                              </Mui.Typography>
                            </Mui.Box>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            {Numeral(
                              product.olPrice.grossPositionSinglePriceInclVAT
                            ).format("$ 0,.00")}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            {Numeral(product.olPrice.singleNetPrice).format(
                              "$ 0,.00"
                            )}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            {product.quantity}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            {Numeral(
                              product.quantity *
                                product.olPrice.grossPositionSinglePriceInclVAT
                            ).format("$ 0,.00")}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      ))}
                      <Mui.TableRow>
                        <td />
                        <td />
                        <td />
                        <Mui.TableCell align="right">
                          <Mui.Box fontWeight="bold">
                            <ReactI18next.Trans i18nKey="Total">
                              Total
                            </ReactI18next.Trans>
                          </Mui.Box>
                        </Mui.TableCell>
                        <Mui.TableCell align="right">
                          <Mui.Box fontWeight="bold">
                            {Numeral(cartStore.total).format("$ 0,.00")}
                          </Mui.Box>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    </Mui.TableBody>
                  </Mui.Table>
                  <Mui.Grid
                    container
                    spacing={2}
                    style={{ marginTop: theme.spacing(3) }}
                  >
                    <Mui.Grid item xs={12} md>
                      {cartStore.billingAddress && (
                        <>
                          <Mui.Typography variant="h5">
                            <ReactI18next.Trans i18nKey="Billing address">
                              Billing address
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <address style={{ textTransform: "capitalize" }}>
                            <Mui.Typography variant="body1">
                              {cartStore.billingAddress?.companyName}
                            </Mui.Typography>
                            <Mui.Typography variant="body1">
                              {cartStore.billingAddress?.firstName}{" "}
                              {cartStore.billingAddress?.lastName}
                            </Mui.Typography>
                            <Mui.Typography variant="body2">
                              {cartStore.billingAddress?.address1}
                              {", "}
                              {cartStore.billingAddress?.address2}
                              {cartStore.billingAddress?.address3}
                            </Mui.Typography>
                            <Mui.Typography variant="body2">
                              {cartStore.billingAddress?.state}
                              {", "}
                              {cartStore.billingAddress?.country}
                            </Mui.Typography>
                            <Mui.Typography variant="body2">
                              {cartStore.billingAddress?.postalCode}
                            </Mui.Typography>
                          </address>
                        </>
                      )}
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md>
                      <Shop.Form />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.CardContent>
                <Mui.CardActions>
                  <Mui.Button component={Router.Link} to="/shop/cart">
                    <ReactI18next.Trans i18nKey="Edit Cart">
                      Edit Cart
                    </ReactI18next.Trans>
                  </Mui.Button>
                  <Shop.ProceedToPaymentButton status={status} />
                </Mui.CardActions>
              </Mui.Card>
            </>
          )}
        </Formik.Formik>
      )}
    </>
  );
});
