import * as IndustrySegment from "src/app/pages/industry-segment";
import * as Yup from "yup";

export declare namespace Shared {
  export interface Props {
    t(key: string): string;
  }
}

// eslint-disable-next-line no-redeclare
export function Shared({ t }: Shared.Props) {
  return {
    Form: {
      validationSchema: Yup.object<IndustrySegment.Form.Values>({
        name_en: Yup.string().required(t("English translation is required")),
        name_de: Yup.string().required(t("German translation is required")),
        name_fr: Yup.string().required(t("French translation is required")),
        name_it: Yup.string().required(t("Italian translation is required")),
        name_es: Yup.string().required(t("Spanish translation is required")),
      }),
    },
  };
}
