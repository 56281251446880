/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Customer {
  export interface ICustomer {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Customer.ICustomer;

  export namespace GetAllData {
    export interface Props {
      rows?: number;
      pageId?: number;
    }

    export type Return = Models.API.Response<Models.Customer.Main[]>;
  }

  export namespace GetCustomerOrDealerByEmailID {
    export interface Props {
      mailId?: string;
    }

    export type Return = Models.API.Response<Models.Customer.Main[]>;
  }
}

// eslint-disable-next-line no-redeclare
export class Customer {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Customer.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllData(
    props?: Customer.GetAllData.Props
  ): Promise<Customer.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["UserManager", "getcustomers"],
    });

    const response = await this.httpManager.get<Customer.GetAllData.Return>({
      url,
    });

    return response;
  }
  public async GetCustomerOrDealerByEmailID(
    props?: Customer.GetCustomerOrDealerByEmailID.Props
  ): Promise<Customer.GetCustomerOrDealerByEmailID.Return> {
    const url = this.urlFactory.create({
      named: ["Users", "GetCustomerOrDealerByEmailID", `${props?.mailId}`],
    });

    const response = await this.httpManager.get<Customer.GetAllData.Return>({
      url,
    });

    return response;
  }
}
