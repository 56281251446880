import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

export declare namespace FunctionIcons {
  export interface Props {
    functions: Models.Set.Function[];
  }
}

export function FuctionIcons({ functions }: FunctionIcons.Props) {
  const theme = Mui.useTheme();
  return (
    <Mui.Box position="relative">
      <Mui.Divider
        style={{
          position: "absolute",
          width: "100%",
          top: theme.spacing(2.5),
        }}
      />
      <Mui.Box
        position="absolute"
        width={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {functions.map((func) => (
          <Mui.Tooltip title={func.funcationName} key={uuidv4()}>
            <Mui.Avatar style={{ marginRight: theme.spacing(2.5) }}>
              <Mui.Box color="text.primary">
                <i className={func.iconClass} />
              </Mui.Box>
            </Mui.Avatar>
          </Mui.Tooltip>
        ))}
      </Mui.Box>
    </Mui.Box>
  );
}
