import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Address {
  export interface IAddress {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Address.IAddress;

  export namespace GetAddress {
    export interface Props {
      contactPersonNumber: number;
      customerAddressNumber: string | number;
    }

    export type Return = Models.API.Response<Models.Address>;
  }

  export namespace Create {
    export type Body = Models.Address;

    export interface Props {
      body: Address.Create.Body;
    }

    export type Return = Models.API.Response<number>;
  }
}

// eslint-disable-next-line no-redeclare
export class Address {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Address.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAddress({
    contactPersonNumber,
    customerAddressNumber,
  }: Address.GetAddress.Props): Promise<Address.GetAddress.Return> {
    const url = this.urlFactory.create({
      named: [
        "Address",
        "GetKA",
        `${contactPersonNumber}`,
        `${customerAddressNumber}`,
      ],
    });

    const response = await this.httpManager.get<Address.GetAddress.Return>({
      url,
    });

    if (response.status === Constants.environment.apiStatus.Error) {
      return ({
        outputData: null,
        result: null,
        status: 100,
      } as unknown) as Address.GetAddress.Return;
    }

    return response;
  }

  public async create({
    body,
  }: Address.Create.Props): Promise<Address.Create.Return> {
    const url = this.urlFactory.create({
      named: ["Address"],
    });

    const response = await this.httpManager.post<
      Address.Create.Return,
      Address.Create.Body
    >({
      url,
      body,
    });

    return response;
  }
}
