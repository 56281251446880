import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Constants from "src/constants";
import * as Services from "src/services";
import * as Models from "src/models";

export declare namespace CustomerDetails {
  export interface Props {
    uid?: string | number;
    userRole?: string | any;
    mailID?:string | any;
    row?: Models.DealerCustomer.Main;
    customers?: Models.DealerCustomer.Main[];
  }
}

// eslint-disable-next-line no-redeclare
export const CustomerDetails = Mobx.observer(function ({
  uid,
  userRole,
  mailID,
  row,
  customers
}: CustomerDetails.Props) {
  const [order, setOrder] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const userStore = React.useContext(App.Contexts.userStore);
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  React.useEffect(() => {
    (async function () {
      // 
      if (!uid) {
        return;
      }

      try {
        setIsLoading(true);
        const roleID:any = userStore.user?.ETUID;
        //
        const result = await dCustomerStore.readOfCustomerProfile({
          uid,
          mailID,
          roleID,
          userRole,
        });
        // 
        setOrder(dCustomerStore.delaerCustomerProfile);
        //
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Components.Customer {...{ order} } {...{row}} {...{customers}} isLoading={isLoading} />
    </>
  );
});
