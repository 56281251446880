/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";

export declare namespace OrderStatus {
  export type Status = 1 | 2 | 3 | 4 | 5;
  export type Labels = {
    [Key in OrderStatus.Status]: string;
  };

  export interface Props {
    labels?: OrderStatus.Labels;
    status: OrderStatus.Status;
    isChip?: boolean;
    assignedCustomerID?: any;
    assignedTo?: string;
    fontSize?: string;
  }

  export interface GetLabelProps
    extends Pick<
      OrderStatus.Props,
      "labels" | "status" | "isChip" | "assignedCustomerID" | "assignedTo"
    > {}
}

export function getLabelle({
  status,
  labels,
}: OrderStatus.GetLabelProps): string {
  if (!labels && status === 1) {
    return "Order processed";
  }

  if (!labels && status === 4) {
    // return "Import-ETLicense";
    return "Partially returned";
  }

  if (!labels && status === 3) {
    return "Return succeeded";
  }

  if (!labels && status === 5) {
    return "Return failed";
  }
  if (!labels) {
    return "";
  }

  return labels[status];
}

export function getChipLabelle({
  status,
  labels,
  assignedCustomerID,
  assignedTo
}: OrderStatus.GetLabelProps): string {
  if (!labels && status === 1 && assignedCustomerID === 0 && assignedTo === "0") {
    return "License available";
  }

  if (!labels && status === 1 && (assignedCustomerID !== 0 ||  assignedCustomerID === 0) && assignedTo !== "0" ) {
    // return "Import-ETLicense";
    return "License assigned";
  }
  if (!labels && status === 2) {
    // return "Import-ETLicense";
    return "License activated";
  }

  if (!labels && status === 3) {
    return "License returned";
  }

  if (!labels && status === 5) {
    return "Return failed";
  }
  if (!labels) {
    return "";
  }

  return labels[status];
}
export function getCol({ status }: OrderStatus.GetLabelProps): string {
  if (status === 1) {
    return "green";
  }

  if (status === 4) {
    // return "Import-ETLicense";
    return "grey";
  }

  if (status === 3) {
    return "red";
  }

  if (status === 5) {
    return "red";
  }

  if (!status) {
    return "white";
  }

  return "green";
}
export function getLicenseCol({
  status,
  assignedCustomerID,
  assignedTo
}: OrderStatus.GetLabelProps): string {
  if (status === 1 && assignedCustomerID === 0 && assignedTo === "0") {
    return "#C4F2C7";
  }

  if (status === 1 && (assignedCustomerID !== 0 ||  assignedCustomerID === 0) && assignedTo !== "0") {
    // return "Import-ETLicense";
    return "#FEEFCB";
  }

  if (status === 2) {
    return "#E2EDFB";
  }

  if (status === 3) {
    return "#F5F6F7";
  }

  if (status === 5) {
    return "#ffc6b3";
  }

  if (!status) {
    return "white";
  }

  return "e6ffe6";
}

export function getLicenseTextCol({
  status,
  assignedCustomerID,
  assignedTo
}: OrderStatus.GetLabelProps): string {
  if (status === 1 && assignedCustomerID === 0 && assignedTo === "0") {
    return "#208924";
  }

  if (status === 1 && (assignedCustomerID !== 0 ||  assignedCustomerID === 0) && assignedTo !== "0") {
    // return "Import-ETLicense";
    return "#E6AC28";
  }

  if (status === 2) {
    return "#0F54B3";
  }

  if (status === 3) {
    return "#636363";
  }

  if (status === 5) {
    return "#DC0D08";
  }

  if (!status) {
    return "white";
  }

  return "#339966";
}
// eslint-disable-next-line no-redeclare
export function OrderStatus({
  status,
  labels,
  isChip = true,
  assignedCustomerID,
  fontSize,
  assignedTo
}: OrderStatus.Props) {
  const theme = Mui.useTheme();

  return isChip ? (
    <Mui.Chip
      size="medium"
      label={getLabelle({ status, labels })}
      style={{
        backgroundColor: getCol({ status }),
        // fontWeight:"bold",
        color: theme.palette.common.white,
        borderColor: "whitesmoke",
        fontSize: fontSize ?? "",
      }}
      variant="outlined"
    
    />
  ) : (
    <Mui.Chip
      size="medium"
      label={getChipLabelle({ status, labels, assignedCustomerID, assignedTo })}
      style={{
        backgroundColor: getLicenseCol({ status, assignedCustomerID, assignedTo }),
        // fontWeight:"bold",
        color: getLicenseTextCol({ status, assignedCustomerID,assignedTo }),
        borderColor: getLicenseTextCol({ status, assignedCustomerID, assignedTo }),
        fontSize: fontSize ?? "",
      }}
      variant="outlined"
     
    />
  );
}
