import * as Mobx from "mobx";
import * as Configs from "src/configs";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Tenant {
  export interface ITenant {
    isCloudPurchased: boolean;
    tenant?: Tenant.Tenant;
    tenantService: Services.Tenant;
    dashboardService: Services.Dashboard;
  }

  export type Options = Pick<
    Tenant.ITenant,
    "tenantService" | "dashboardService"
  >;
  export type Tenant = Models.Tenant.Main;

  export namespace Read {
    export interface Props extends Services.Tenant.GetById.Props {}
  }

  export namespace Tasks {
    export interface Task {
      severity: "success" | "error" | "info" | "warning";
      title: string;
      body: string;
      roles: Array<"customer" | "admin" | "superadmin">;
      link: {
        isLink: boolean;
        to: string;
      };
    }

    export type Return = Tenant.Tasks.Task[];
  }
}

// eslint-disable-next-line no-redeclare
export class Tenant implements Tenant.ITenant {
  @Mobx.observable public tenant?: Tenant.Tenant;
  @Mobx.observable public isCloudPurchased: boolean = false;

  public tenantService: Services.Tenant;
  public dashboardService: Services.Dashboard;

  constructor({ tenantService, dashboardService }: Tenant.Options) {
    this.tenantService = tenantService;
    this.dashboardService = dashboardService;
  }

  @Mobx.action public async read(props: Tenant.Read.Props) {
    try {
      const {
        result: [tenant],
      } = await this.tenantService.getById(props);
      const {
        result: { sets },
      } = await this.dashboardService.getAllData({
        uid: props.etuid,
        userRole: "customer",
      });
      const reducedSets = sets.reduce(
        (reducedSets: Map<string, any>, set: any) => {
          const storedSet = reducedSets.get(set.setID);
          if (storedSet && set.status === 1) {
            storedSet.available += 1;
            return reducedSets;
          }
          if (storedSet && set.status !== 1) {
            storedSet.used += 1;
            return reducedSets;
          }

          if (set.status === 1) {
            set["available"] = 1;
            set["used"] = 0;
          } else {
            set["available"] = 1;
            set["used"] = 1;
          }
          reducedSets.set(set.setID, set);
          return reducedSets;
        },
        new Map()
      );

      this.tenant = tenant ?? null;
      this.isCloudPurchased = Array.from(reducedSets.values()).find(
        (set: any) =>
          Configs.Workaround.isCloudArticle({ code: set.setCode ?? "" }) ||
          Configs.Workaround.isCloudArticle({ code: set.hundCode ?? "" })
      )
        ? true
        : false;
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.computed public get tasks(): Tenant.Tasks.Return {
    if (this.tenant === null && this.isCloudPurchased) {
      return [
        {
          severity: "warning",
          title: "Create tenant!",
          body:
            "You've purchased a cloud article but have not created a tenant yet. Please create a tenant to enjoy your purchased articles!",
          roles: ["customer", "admin", "superadmin"],
          link: {
            isLink: true,
            to: "/tenants/create",
          },
        },
      ];
    }
    return [];
  }
}
