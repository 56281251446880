import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as AddDealer from "src/app/pages/user-management/adddealer";
import * as Yup from "yup";
import * as ReactI18next from "react-i18next";

export interface FormProps {
  emailID: string;
  customerName?: string;
  firstName?: string;
  lastName?: string;
}

export const Main = Mobx.observer(function () {
  const { t } = ReactI18next.useTranslation();
  const validationSchema = Yup.object().shape<FormProps>({
    emailID: Yup.string().required(t("Email ID is required")),
  });

  return (
    <>
      <Formik.Formik<FormProps>
        initialValues={{
          emailID: "",
          customerName: "",
          firstName: "",
          lastName: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async ({ emailID, ...restOfValues }, { setSubmitting }) => {
          alert(
            restOfValues.customerName +
              " " +
              restOfValues.firstName +
              " " +
              restOfValues.lastName
          );
         
        }}>
        {() => <AddDealer.Layout />}
      </Formik.Formik>
    </>
  );
});
