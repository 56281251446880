import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as IndustrySegment from "src/app/pages/industry-segment";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
  }),
);

export declare namespace Create {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  >;
}

// eslint-disable-next-line no-redeclare
export const Create = Mobx.observer(function (props: Create.Props) {
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();
  const industrySegmentStore = React.useContext(
    App.Contexts.industrySegmentStore
  );
  const userStore = React.useContext(App.Contexts.userStore);
  const industrySegmentService = new Services.IndustrySegment({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  async function handleCreate(
    values: IndustrySegment.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<IndustrySegment.Form.Values>,
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      const industrySegment: Models.IndustrySegment.Main = {
        nameDE: values.name_de,
        nameEN: values.name_en,
        nameES: values.name_es,
        nameFR: values.name_fr,
        nameIT: values.name_it,
        status: 1,
      } as Models.IndustrySegment.Main;
      await industrySegmentService.create({ body: industrySegment, uid });
      industrySegmentStore.read();
      enqueueSnackbar(t("Industry/Segments successfully created!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      props.handleClose();
    }
  }

  return (
    <>
      <Formik.Formik
        initialValues={
          {
            name_de: "",
            name_en: "",
            name_es: "",
            name_fr: "",
            name_it: "",
          } as IndustrySegment.Form.Values
        }
        validationSchema={IndustrySegment.Shared({ t }).Form.validationSchema}
        onSubmit={handleCreate}
      >
        <Form {...props} />
      </Formik.Formik>
    </>
  );
});

function Form({ open, handleClose }: Create.Props) {
  const classes = useStyles();
  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const { isSubmitting, submitForm, resetForm } = Formik.useFormikContext();
  React.useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Components.LightBox
      open={open}
      handleClose={handleClose}
      content={<IndustrySegment.Form />}
      title={
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          {t("Create new Industry / Segment")}
        </Mui.Typography>
      }
      actions={
        <>
          <Mui.Button variant="contained" onClick={handleClose}>
            <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
          </Mui.Button>
          <Mui.Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => submitForm()}
          >
            <ReactI18next.Trans i18nKey="Save">Save</ReactI18next.Trans>
            {isSubmitting && (
              <Mui.CircularProgress
                style={{
                  marginLeft: theme.spacing(1),
                }}
                color={"inherit"}
                size={theme.typography.button.fontSize}
              />
            )}
          </Mui.Button>
        </>
      }
    />
  );
}
