import I18next from "i18next";
import * as BrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import * as ReactI18next from "react-i18next";

const detectorOptions: BrowserLanguageDetector.DetectorOptions = {
  lookupQuerystring: "lang",
  order: ["querystring", "cookie", "localStorage"],
  caches: ["localStorage", "cookie"],
};

I18next.use(Backend)
  .use(BrowserLanguageDetector.default)
  .use(ReactI18next.initReactI18next)
  .init({
    fallbackLng: ["en"],
    debug: false,
    whitelist: ["en", "es", "de", "fr", "it"],
    detection: detectorOptions,
    interpolation: {
      escapeValue: false,
    },
  });

export default I18next;
