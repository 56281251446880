import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Models from "src/models";
import * as ReactI18next from "react-i18next";
export declare namespace List {
  export interface Props {
    customer: Models.DealerCustomer.Main;
    selected: number | string;
    handleClick?(props: { CustomerID: number | string }): void;
  }
}

export declare namespace ListDetails {
  export interface Props {
    customerLicense: Models.Set.Function[];
    handleClick?(props: { CustomerID: number | string }): void;
  }
}

// eslint-disable-next-line no-redeclare
//Load the Card details
export function List({ customer, handleClick, selected }: List.Props) {
  const theme = Mui.useTheme();
  const CardInternals = (
    <>
      <Mui.Card
        style={{ height: "230px" }}
        {...(selected === customer.customerID && {
          elevation: selected ? 11 : undefined,
          style: {
            height: "100%",
            border: selected
              ? `2px solid ${theme.palette.success.main}`
              : "inherit",
          },
        })}>
        <Mui.CardHeader
          title={
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
              <Mui.ListItemIcon>
                <Mui.ListItemAvatar>
                  <Mui.Avatar alt={"Bala" + " " + "userStore.user?.LastName"}>
                    <MuiIcons.Person />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>
              </Mui.ListItemIcon>

              <Mui.ListItemText
                primary={
                  <Mui.Typography
                    style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {customer.companyName}
                  </Mui.Typography>
                }
                secondary={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <Mui.Typography color="textSecondary">
                        Role:
                      </Mui.Typography>

                      <Mui.Typography
                        style={{ color: "black", fontSize: "14px" }}>
                        {customer.loginUserRole}
                      </Mui.Typography>
                    </div>
                  </>
                }
              />
              <Components.WorkingStatus status={1 as 1 | 2} />
            </Mui.ListItem>
          }
          action={
            <>
              {selected !== undefined && (
                <Mui.Checkbox
                  size="small"
                  checked={selected === customer.customerID}
                  value={customer.customerName}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                />
              )}
            </>
          }
          style={{ padding: 4, margin: 6 }}
        />
        <Mui.CardContent style={{ padding: 6, margin: 6 }}>
          <Mui.Divider />
          <>
          </>

          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}>
              <Mui.Typography color="textSecondary">
                {" "}
                {"EmailID:"}
              </Mui.Typography>
              <Mui.Typography 
              onClick={()=>{window.open(`mailto:${customer.emailID}`)}}
              style={{ color: "black", fontSize: "14px" }}>
                <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{customer.emailID}</Mui.Link></Mui.Tooltip>

              </Mui.Typography>
            </div>
          </>

          <Mui.Divider />
          <Mui.Grid
            container
            spacing={2}
            direction={"row"}
            style={{ marginTop: "6px" }}>
            <Mui.Grid item xs={8}>
              <Mui.Typography style={{ fontWeight: "bold" }}>
                Available license
              </Mui.Typography>

              <Mui.Typography>
                <Components.FunctionCountIcons
                  functions={customer.functionsDisplay}
                />
              </Mui.Typography>
              {customer?.functionCount > 4 && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "right",
                    float: "right",
                    color: "#5780d9",
                    fontSize: "0.875rem; ",
                  }}>
                  + {customer.functionCount}
                  {}more
                </span>
              )}
            </Mui.Grid>
            <Mui.Divider orientation="vertical" flexItem />
            <Mui.Grid item xs={2}>
              <Mui.Typography style={{ fontWeight: "bold" }}>
                {"Status"}
              </Mui.Typography>
              <Mui.Typography variant="body2" color="textSecondary">
                <Components.InviteStatus
                  status={customer.etUserStatus as 1 | 2}
                />
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );

  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea
          onClick={() => handleClick({ CustomerID: customer.customerID })}>
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        CardInternals
      )}
    </Mui.Card>
  );
}

export function ListDeatils({ customer, handleClick }: List.Props) {
  const customerLic = JSON.stringify(customer);

  const CardInternals = (
    <>
      <Mui.CardHeader
        title={
          <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
            <Mui.ListItemIcon>
              <Mui.ListItemAvatar>
                <Mui.Avatar alt={"Bala" + " " + "userStore.user?.LastName"}>
                  <MuiIcons.Person />
                </Mui.Avatar>
              </Mui.ListItemAvatar>
            </Mui.ListItemIcon>

            <Mui.ListItemText
              primary={
                <Mui.Typography
                  style={{ fontWeight: "bold", fontSize: "20px" }}>
                </Mui.Typography>
              }
              secondary={
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Mui.Typography color="textSecondary">Role:</Mui.Typography>

                    <Mui.Typography
                      style={{ color: "black", fontSize: "14px" }}>
                    </Mui.Typography>
                  </div>
                </>
              }
            />
            <Components.WorkingStatus status={1 as 1 | 2} />
          </Mui.ListItem>
        }
        style={{ padding: 4, margin: 6 }}
      />
      <Mui.CardContent style={{ padding: 6, margin: 6 }}>
        <Mui.Divider />
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <Mui.Typography color="textSecondary">Company name:</Mui.Typography>
            <Mui.Typography style={{ color: "black", fontSize: "14px" }}>
            {customerLic.valueOf()}
            </Mui.Typography>
          </div>
        </>

        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}>
            <Mui.Typography  color="textSecondary"> {"EmailID:"}</Mui.Typography>
            <Mui.Typography style={{ color: "black", fontSize: "14px" }}>
            </Mui.Typography>
          </div>
        </>

        <Mui.Divider />
        <Mui.Grid
          container
          spacing={2}
          direction={"row"}
          style={{ marginTop: "6px" }}>
          <Mui.Grid item xs={8}>
            <Mui.Typography>
              {customer.functionCount > 4 && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "right",
                    float: "right",
                    color: "#5780d9",
                    fontSize: "0.875rem; ",
                  }}>
                  + {customer.functionCount}
                  {}more
                </span>
              )}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Divider orientation="vertical" flexItem />
          <Mui.Grid item xs={2}>
            <Mui.Typography style={{ fontWeight: "bold" }}>
              {"Status"}
            </Mui.Typography>
            <Mui.Typography variant="body2" color="textSecondary">
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.CardContent>
    </>
  );

  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea onClick={() => handleClick({ CustomerID: 1 })}>
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        CardInternals
      )}
    </Mui.Card>
  );
}
