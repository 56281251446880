import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Constants from "src/constants";
import * as Services from "src/services";

export declare namespace Details {
  export interface Props {
    orderId?: string | number;
    cid?: string | number;
    orderCode?: string | number | undefined;
    orderedOn?: string | number ;
    isDealer?: boolean ;
  }
}

// eslint-disable-next-line no-redeclare
export const Details = Mobx.observer(function ({
  orderedOn,
  orderId,
  cid,
  orderCode,
  isDealer = false,
}: Details.Props) {
  const [order, setOrder] = React.useState<any>();
  const userStore = React.useContext(App.Contexts.userStore);
  const uid = userStore.user?.ETUID;
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    (async function () {
      // 
      if (!orderId) {
        return;
      }

      try {
        //alert('Order');
        const {
          result: [order],
        } = await orderServices.getData({
          body: { id: orderId, cid: cid },
        });
     
       var {result} =  await orderServices.getData({
          body: { id: orderId, cid: cid },
        });

        //overAllStatus = 3 -- > Order completely returned
        if(result.find(k=>k.status === 3)){
        order.overAllStatus = 3
        var partialReturnOrderObjectForStatus3 =  result.find(k=>k.status === 2);
        partialReturnOrderObjectForStatus3?.orderDetails.map((k)=>{
         // eslint-disable-next-line array-callback-return
         order.orderDetails.map((q)=>{
        if(q.setID === k.setID ){
        q.returnQuantity = k.quantity
        }
         })
        })
        } 

        //overAllStatus = 2 -- > Order paretially returned
        else if(result.find(k=>k.status === 4)){
          order.overAllStatus = 4
       var partialReturnOrderObjectForStatus4 =  result.find(k=>k.status === 2);
       partialReturnOrderObjectForStatus4?.orderDetails.map((k)=>{
        // eslint-disable-next-line array-callback-return
        order.orderDetails.map((q)=>{
       if(q.setID === k.setID ){
       q.returnQuantity = k.quantity
       }
        })
       })
       // 
     
      } 
      else if(result.find(k=>k.status === 5)){
        order.overAllStatus = 5
     var partialReturnOrderObjectForStatus5 =  result.find(k=>k.status === 2);
     partialReturnOrderObjectForStatus5?.orderDetails.map((k)=>{
      // eslint-disable-next-line array-callback-return
      order.orderDetails.map((q)=>{
     if(q.setID === k.setID ){
     q.returnQuantity = k.quantity
     }
      })
     })
     // 
   
    } 
      //overAllStatus = 1 -- > Order paretially returned Order with no return
      else{
        order.overAllStatus = 1
      }
      
      setOrder(order);
      
      localStorage.setItem("orderStatus",JSON.stringify(order.overAllStatus))
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Components.Order {...{ order }} orderCode = {orderCode} orderedOn ={orderedOn} />
    </>
  );
});
