import * as Mui from "@material-ui/core";
import * as MuiTypes from "@material-ui/types";
import * as React from "react";

export declare namespace ResponsiveFullscreenDialogWithScroll {
  export type BreakpointDefaults = Record<
    "xs" | "sm" | "md" | "lg" | "xl",
    true
  >;
  export interface BreakpointOverrides {}
  export type Breakpoint = MuiTypes.OverridableStringUnion<
    ResponsiveFullscreenDialogWithScroll.BreakpointDefaults,
    ResponsiveFullscreenDialogWithScroll.BreakpointOverrides
  >;

  export interface Props {
    open: boolean;
    handleClose(): void;
    disableDividers?: boolean;
    title?: string | JSX.Element;
    contentText?: string | JSX.Element;
    actions?: React.ReactNode | React.ReactNode[];
    fullscreenBreakpoint?: ResponsiveFullscreenDialogWithScroll.Breakpoint;
  }
}

// eslint-disable-next-line no-redeclare
export function ResponsiveFullscreenDialogWithScroll({
  open,
  handleClose,
  title,
  contentText,
  actions,
  fullscreenBreakpoint,
  disableDividers = false,
}: ResponsiveFullscreenDialogWithScroll.Props) {
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(
    theme.breakpoints.down(fullscreenBreakpoint ?? "sm")
  );

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Mui.Dialog
        maxWidth={fullscreenBreakpoint ?? "sm"}
        fullWidth
        fullScreen={fullScreen}
        scroll="paper"
        open={open}
        onClose={handleClose}
      >
        {title && <Mui.DialogTitle>{title}</Mui.DialogTitle>}
        <Mui.DialogContent dividers={disableDividers ? false : true}>
          {contentText && (
            <Mui.DialogContent
              style={{ margin: 0, padding: 0, overflow: "hidden" }}
              ref={descriptionElementRef}
            >
              {contentText}
            </Mui.DialogContent>
          )}
        </Mui.DialogContent>
        {actions && <Mui.DialogActions>{actions}</Mui.DialogActions>}
      </Mui.Dialog>
    </>
  );
}
