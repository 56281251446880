import * as Mui from "@material-ui/core";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Models from "src/models";

export declare namespace Card {
  export type VARIANT = Article.Views.Cards.VARIANT;

  export interface Props extends Pick<Mui.CardProps, "style"> {
    selected?: boolean;
    functions?: Models.Set.Function[];
    article: Models.Set.Article;
    handleClick?(props: { article: Models.Set.Article }): void;
    variant?: Card.VARIANT;
  }
}

// eslint-disable-next-line no-redeclare
export function Card({
  article,
  style,
  handleClick,
  selected,
  functions,
  variant = "DEFAULT",
}: Card.Props) {
  const theme = Mui.useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = ReactI18next.useTranslation();
  const DefaultCardInternals = (
    <>
      <Mui.CardHeader
        title={
          <>
            <Mui.Typography variant="body2" color="textSecondary">
              <ReactI18next.Trans i18nKey="Name">Name</ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography>{article.setName}</Mui.Typography>
          </>
        }
        action={
          <Components.WorkingStatus
            variant="SHORT"
            status={article.status as 1 | 2}
          />
        }
      />
      <Mui.CardContent>
        <Mui.Typography variant="body2" color="textSecondary">
          <ReactI18next.Trans i18nKey="Code">Code</ReactI18next.Trans>
        </Mui.Typography>
        <Mui.Typography>{article.setCode}</Mui.Typography>
      </Mui.CardContent>
    </>
  );

  const DetailedCardInternals = (
    <>
      <Mui.CardHeader
        title={
          <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
            <Mui.ListItemIcon>
              <Mui.Box fontSize={theme.typography.h4.fontSize} lineHeight={0}>
                <i className={article.iconClass} />
              </Mui.Box>
            </Mui.ListItemIcon>

            <Mui.ListItemText
              primary={article.setName}
              secondary={article.hundCode}
            />
          </Mui.ListItem>
        }
        action={
          <>
            {selected !== undefined && (
              <Mui.Checkbox style={{color:"#4d79ff"}} size="small" checked={selected} />
            )}
          </>
        }
      />
      <Mui.CardContent>
        {functions && functions.length > 0 && (
          <Mui.Box height={48} overflow={"auto"}>
            <Components.FuctionIcons functions={functions} />
          </Mui.Box>
        )}

        <Mui.Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: theme.spacing(2) }}
        >
          <ReactI18next.Trans i18nKey="Available functions">
            Available functions
          </ReactI18next.Trans>
        </Mui.Typography>
        <Mui.Typography noWrap>
          {functions?.map((func) => func.funcationName).join(", ")}
        </Mui.Typography>
        <br />
      </Mui.CardContent>
    </>
  );

  return (
    <Mui.Card
      style={{ ...style, height: "100%" }}
      {...(selected !== undefined && {
        elevation: selected ? 0 : undefined,
        style: {
          ...style,
          height: "100%",
          border: selected
            ? `2px solid ${theme.palette.primary.main}`
            : "inherit",
        },
      })}
    >
      {handleClick ? (
        <Mui.CardActionArea
          style={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "unset",
            alignItems: "unset",
            verticalAlign: "unset",
          }}
          onClick={() => handleClick({ article })}
        >
          {variant === "DEFAULT" && DefaultCardInternals}
          {variant === "DETAILED" && DetailedCardInternals}
        </Mui.CardActionArea>
      ) : (
        <>
          {variant === "DEFAULT" && DefaultCardInternals}
          {variant === "DETAILED" && DetailedCardInternals}
        </>
      )}
    </Mui.Card>
  );
}
