import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function CustomerDetail() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          path="/"
          element={<Pages.CustomerDetail.Main />}
        />
      </Router.Routes>
    </>
  );
}
