import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";

interface Data extends Models.ManualLicense {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({ rows }: { rows: Models.ManualLicense[] }) {
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("macId");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(5);
  const navigate = Router.useNavigate();

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.macId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead
                leftmost
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="macId"
                label={t("Machine ID")}
              />

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="customerName"
                label={t("Customer name")}
              />

              <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="remarks"
                  label={t("Remarks")}
                />
              </Containers.ShowIfAuthorised>

              <Components.Mui.TableCell
                color={theme.palette.divider}
                rightmost
                align="right"
              >
                <ReactI18next.Trans i18nKey="Actions">
                  Actions
                </ReactI18next.Trans>
              </Components.Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.macId as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => handleClick(event, row.macId as string)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.macId}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.macId}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.customerName}
                    </Components.Mui.TableCell>
                    <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                      <Components.Mui.TableCell>
                        {row.remarks}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    <Components.Mui.TableCell rightmost align="right">
                      <Mui.Tooltip
                        // title={copied === row.macId ? "Copied!" : ""}
                        title={
                          <ReactI18next.Trans i18nKey="Click to copy the license key">
                            Click to copy the license key.
                          </ReactI18next.Trans>}
                      >
                        <ReactCopyToClipboard.CopyToClipboard
                          text={row.licenseKey}
                          onCopy={() => {
                            enqueueSnackbar(
                              t(
                                "License key successfully copied to clipboard."
                              ),
                              {
                                variant: "success",
                                preventDuplicate: false,
                              }
                            );
                          }}
                        >
                          <Mui.IconButton size="small" onClick={() => {}}>
                            <MuiIcons.ContentCopy />
                          </Mui.IconButton>
                        </ReactCopyToClipboard.CopyToClipboard>
                      </Mui.Tooltip>
                      <Mui.IconButton
                        onClick={() => {
                          const url = `/licenses/generate?macId=${
                            row.macId
                          }&customerName=${row.customerName}&remarks=${
                            row.remarks
                          }&tenantId=${
                            row.tenantId ?? ""
                          }${((row.sets as unknown) as number[]).reduce(
                            (acc: string, set) => {
                              return acc + `&set=${set}`;
                            },
                            ""
                          )}`;

                          navigate(url);
                        }}
                        size="small"
                        style={{
                          padding: theme.spacing(0.125),
                        }}
                      >
                        <Mui.Box fontSize={26} lineHeight={0}>
                          <i className="icon-etc-edit" />
                        </Mui.Box>
                      </Mui.IconButton>
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          page={page + 1}
          count={Math.ceil(rows.length / rowsPerPage)}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
    </div>
  );
}

export const Manual = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  const theme = Mui.useTheme();

  React.useEffect(() => {
    generateLicenseStore.fetchManualLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.FormControl style={{ width: 300 }}>
          <Mui.TextField label={t("Search")} onChange={handleSearch} />
        </Mui.FormControl>
      </Mui.Grid>
      {generateLicenseStore.isInitialLoadingManualLicenses ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <EnhancedTable
          rows={generateLicenseStore.manualLicenses.filter((license) => {
            if (!search) {
              return true;
            }
            return (
              license?.macId?.toLowerCase().includes(search.toLowerCase()) ||
              license?.customerName
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              license?.remarks?.toLowerCase().includes(search.toLowerCase())
              
            );
          })}
        />
      )}
    </>
  );
});
