import * as Shop from "src/app/pages/shop";
import * as Yup from "yup";
import * as ReactI18next from "react-i18next";
export declare namespace Shared {
  export interface Props {
    t(key: string): string;
  }
}

// eslint-disable-next-line no-redeclare
export function Shared() {
  const { t } = ReactI18next.useTranslation();
  return {
    Form: {
      validationSchema: Yup.object({
        accept: Yup.boolean()
          .required(t("Please accept the terms before proceeding"))
          .oneOf([true], t("Please accept the terms before proceeding")),
      }),
    },
    Address: {
      Form: {
        validationSchema: Yup.object<Shop.AddressForm.Values>({
          address1: Yup.string().required("Address line 1 is required"),
          country: Yup.string().required("Country is required"),
          firstName: Yup.string().required("First name is required"),
          lastName: Yup.string(),
          postalCode: Yup.string().required("Postal code is required"),
          state: Yup.string().required("State is required"),
          address2: Yup.string(),
          address3: Yup.string(),
          city: Yup.string(),
          companyName: Yup.string(),
        }),
      },
    },
  };
}
