/** @format */

import * as Article from "src/app/pages/article";
import * as Yup from "yup";
import * as React from "react";
import * as App from "src/app";

export declare namespace Shared {
  export interface Props {
    t(key: string): string;
  }
}

// eslint-disable-next-line no-redeclare
export function Shared({ t }: Shared.Props) {
  const articleStore = React.useContext(App.Contexts.articleStore);
  const articleIdFromEdit: any = localStorage.getItem("articleIdFromEdit");
  return {
    Form: {
      validationSchema: Yup.object<Article.Form.Values>({
        name: Yup.string()
          .required(t("Article name is required"))
          .test("Already exists", t("Article name already exists"), (value) => {
            if (articleIdFromEdit?.length > 0) {
              if (
                articleStore.articles
                  .filter((k) => k.setID !== parseInt(articleIdFromEdit))
                  .map((k: any) => k.setName)
                  .includes(value)
              ) {
                return false;
              } else {
                return true;
              }
            } else if (
              articleStore.articles.map((k: any) => k.setName).includes(value)
            ) {
              return false;
            } else {
              return true;
            }
          }),
        code: Yup.string()
          .required(t("Article code is required"))
          .test("Already exists", t("Article code already exists"), (value) => {
            if (articleIdFromEdit?.length > 0) {
              if (
                articleStore.articles
                  .filter((k) => k.setID !== parseInt(articleIdFromEdit))
                  .map((k: any) => k.setCode)
                  .includes(value)
              ) {
                return false;
              } else {
                return true;
              }
            } else if (
              articleStore.articles.map((k: any) => k.setCode).includes(value)
            ) {
              return false;
            } else {
              return true;
            }
          }),
        industrySegmentsId: Yup.string()
          .nullable()
          .required(t("Industry / Segment is required")),
        hundCode: Yup.string()
          .required(t("Model number is required"))
          .test("Already exists", t("Model number already exists"), (value) => {
            if (articleIdFromEdit?.length > 0) {
              if (
                articleStore.articles
                  .filter((k) => k.setID !== parseInt(articleIdFromEdit))
                  .map((k: any) => k.hundCode)
                  .includes(value)
              ) {
                return false;
              } else {
                return true;
              }
            } else if (
              articleStore.articles.map((k: any) => k.hundCode).includes(value)
            ) {
              return false;
            } else {
              return true;
            }
          }),
        description: Yup.string().required(
          t("Article description is required")
        ),
        isLimited: Yup.bool().notRequired(),
        isFree: Yup.bool().notRequired(),
        validityPeriodInDays: Yup.number().required(t("Validity is required")),
        netPricePerItem: Yup.number().required(t("Price is required")),
        licensesPerArticle: Yup.number().required(t("Licenses are required")),
        iconClass: Yup.string().nullable().required(t("Icon name is required")),
        linkForMoreInfo: Yup.string()
          .nullable()
          .required(t("More info link is required")),
        setID: Yup.array<string>()
          .nullable()
          .required(t("Functions are required")),
        dependentSets: Yup.array<number>(),
      }),
    },
    Translate: {
      Form: {
        validationSchema: Yup.object<Article.TranslateForm.Values>({
          name_de: Yup.string().required(t("Article name in DE is required")),
          name_es: Yup.string().required(t("Article name in ES is required")),
          name_fr: Yup.string().required(t("Article name in FR is required")),
          name_it: Yup.string().required(t("Article name in IT is required")),
          description_de: Yup.string().required(
            t("Description in DE is required")
          ),
          description_es: Yup.string().required(
            t("Description in ES is required")
          ),
          description_fr: Yup.string().required(
            t("Description in FR is required")
          ),
          description_it: Yup.string().required(
            t("Description in IT is required")
          ),
        }),
      },
    },
  };
}
