/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as DealerCustomer from "src/app/pages/dealercustomer";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as Components from "src/app/components";

interface Data extends Models.DealerCustomer.Main {}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export declare namespace Cards {
  export interface Props {
    customers: Models.DealerCustomer.Main[];
  }
}

// eslint-disable-next-line no-redeclare
export function Cards({ customers }: Cards.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();

  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  
  const [customerData, setCustomerData] = React.useState();
  const [page, setPage] = React.useState(0);
  const [select, setSelect] = React.useState(false);
  const [
    adminAndCustomerRoleCheck,
    setAdminAndCustomerRoleCheck,
  ] = React.useState(false);
  const [rowsPerPage] = React.useState(8);

  React.useEffect(() => {
    setPage(0);
  }, [customers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState<boolean>(
    false
  );
  const functionStore = React.useContext(App.Contexts.functionStore);
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const [customerMailID, setCustomerMailID] = React.useState<string>("");
  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  const navigate = Router.useNavigate();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const [selectedValue, setSelectedValue] = React.useState<any>("");
  const userStore = React.useContext(App.Contexts.userStore);
  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;
    if (userRole === "admin" || userRole === "superadmin" || userRole === "customer") {
      setAdminAndCustomerRoleCheck(false);
    } else {
      setAdminAndCustomerRoleCheck(true);
    }
  }, [userStore.user, adminAndCustomerRoleCheck]);

  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: any;
  }) => {

    const selectedIndex = dCustomerStore.selected.indexOf(dealerProfile);
    let newSelected: Models.CustomerLicense.Main[] = [];
    dCustomerStore.setSelected({ selected: newSelected });
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(dCustomerStore.selected, dealerProfile);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(1));
    } else if (selectedIndex === dCustomerStore.selected.length - 1) {
      newSelected = newSelected.concat(dCustomerStore.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        dCustomerStore.selected.slice(0, selectedIndex),
        dCustomerStore.selected.slice(selectedIndex + 1)
      );
    }
    dCustomerStore.setSelected({ selected: newSelected });
    let arrayCustomer: any = [];
    arrayCustomer = newSelected;
    const customerName = arrayCustomer.map((k: any) => k.customerName);
    const mailID: any = arrayCustomer.map((k: any) => k.emailID);
    const companyName: any = arrayCustomer.map((k: any) => k.companyName);
    const customerID: any = arrayCustomer.map((k: any) => k.customerID);
    localStorage.setItem("mID", mailID);
    localStorage.setItem("cuName", customerName);
    localStorage.setItem("coName", companyName);
    localStorage.setItem("customerID", customerID);
  };
  const [customerId, setCustomerId] = React.useState<number | string>();
  const [customerDetailsDialogOpen, setCustomerDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  const uid = userStore.user?.ETUID;
  const userRole = userStore?.user?.RoleName
  return (
    <>
      <Components.responsiveScreen
        fullscreenBreakpoint="md"
        open={customerDetailsDialogOpen}
        handleClose={() => setCustomerDetailsDialogOpen(false)}
        title={t("Customer details")}
        contentText={<DealerCustomer.Views.CardDetails uid={customerId} userRole={userRole} mailID={customerMailID} customers={customerData}/>}
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => window.print()}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setCustomerDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />

      <Mui.Grid
        container
        spacing={3}
        style={{ marginBottom: theme.spacing(0.5) }}
      >
        {stableSort(customers as any, getComparator("asc", "customerName"))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((Customer, index) => (
            <Mui.Grid key={uuidv4()} item xs={12} md={12} lg={6} xl={4}>
              <DealerCustomer.Card
                selected={selectedValue}
                handleClick={({ CustomerID,EmailID,customerData,etuid }) => {
                  setCustomerMailID(EmailID)
                  setOrderId(CustomerID);
                  setCustomerId(CustomerID);
                  setCustomerData(customerData)
                  setSelectedValue(CustomerID);
                  handleSelectDealerUser({
                    dealerProfile: (Customer as unknown) as Models.CustomerLicense.Main[],
                  });
                  navigate(
                    `/dealercustomer/customerdetail?q=${etuid}&m=${CustomerID}`
                  );
                }}
                customer={(Customer as unknown) as Models.DealerCustomer.Main}
              />
            </Mui.Grid>
          ))}
        {customers.length === 0 && (
          <Mui.Grid container>
            <Mui.Grid
              item
              style={{ marginTop: "29.5px", marginLeft: "12px" }}
              xs={12}
            >
              <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No customers found">No customers found</ReactI18next.Trans></MuiLab.Alert>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      
      {customers.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(customers.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    </>
  );
}
