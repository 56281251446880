/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD" | "LIST";

  export interface IStore {
    isLoading: boolean;
    dcustomerService: Services.DealerCustomerService;
    view: Store.View;
  }

  export type Options = Pick<IStore, "view" | "dcustomerService">;

  export namespace SetView {
    export interface Props extends Pick<Store.IStore, "view"> {}
  }

  export namespace Read {
    export interface Props
      extends Services.DealerCustomerService.GetAllData.Props {}
  }
  export namespace ReadOfCustomerProfile {
    export interface Props
      extends Services.DealerCustomerService.GetCustomerProfileData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public view: Store.View = "TABULAR";
  @Mobx.observable public delaerCustomer: Models.DealerCustomer.Main[] = [];
  @Mobx.observable
  public delaerCustomerProfile: Models.CustomerProfileOnDealer.Main[] = [];
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public selected: Models.CustomerLicense.Main[] = [];
  //public orderService: Services.Order;
  public dcustomerService: Services.DealerCustomerService;
  constructor({ view, dcustomerService }: Store.Options) {
    this.view = view;
    this.dcustomerService = dcustomerService;
  }

  @Mobx.action setView({ view }: Store.SetView.Props) {
    this.view = view;
  }

  @Mobx.action public async readOfCustomerProfile(
    props: Store.ReadOfCustomerProfile.Props
  ) {
    try {
      this.isLoading = true;
      const { result } =
        await this.dcustomerService.getCustomerProfileWithLicense(props);
      this.delaerCustomerProfile = result;
      //
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.dcustomerService.getCustomer(props);
      this.delaerCustomer = result;
      //
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
  @Mobx.computed public get isInitialLoadings(): boolean {
    if (this.delaerCustomerProfile.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.delaerCustomer.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
  @Mobx.action public setSelected({
    selected,
  }: {
    selected: Models.CustomerLicense.Main[];
  }) {
    this.selected = selected;
  }
}
