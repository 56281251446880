/** @format */

import * as Factories from "./factories";
import * as Managers from "./managers";
export * from "./actions";
export * from "./addcustomer ";
export * from "./addDealer";
export * from "./address";
export * from "./adminmanageservice";
export * from "./article";
export * from "./customer";
export * from "./dashboard";
export * from "./dealercustomerservice";
export * from "./device-configuration-upload";
export * from "./easy-touch-device";
export * from "./function";
export * from "./icon";
export * from "./importdealer";
export * from "./industry-segment";
export * from "./invitecustomer";
export * from "./license";
export * from "./order";
export * from "./tenant";
export * from "./user";
export * from "./user-management";
export { Factories, Managers };
