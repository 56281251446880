/** @format */

import * as Mui from "@material-ui/core";
import * as DateFns from "date-fns";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Configs from "src/configs";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

export declare namespace OrderForSuccessPage {
  export interface Props {
    order: Models.Order.Main;
    hideHeader?: boolean;
    hideAddress?: boolean;
    hideUserDetails?: boolean;
  }
}

// eslint-disable-next-line no-redeclare
export function OrderForSuccessPage({
  order,
  hideAddress,
  hideHeader,
  hideUserDetails,
}: OrderForSuccessPage.Props) {
  const theme = Mui.useTheme();
  const [data,setData] = React.useState<string[]>([]);
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  var order1:any = [];
  
  const orderDetail = order?.orderDetails?.map?.(k=>{
    
    return {
      
      setName:k.sets?.setName,
      quantity:k?.quantity,
      hundCode:k.sets?.hundCode,
      singleNetPrice:k.singleNetPrice,
      setCode:k.sets?.setCode,
      iconClass:k.sets?.iconClass,
      orderCode:k.orderCode

    }
   });
   const orderFilterBasedOnOrderCode = orderDetail?.filter?.((k:any)=>k.orderCode === order?.orderCode)


  var orderDataDuplicationCombineBasedOnSetNameUsingReducer = orderFilterBasedOnOrderCode?.reduce?.(function(accumulator:any, cur) {
    var setName:any = cur?.setName, 
    found:any = accumulator?.find?.(function(elem:any) {
        return elem?.setName === setName
    });
    if (found) {
    found.quantity += cur?.quantity;
     }
  else 
    {
       accumulator?.push?.(cur);
      
    }
    return accumulator;
  }, []);

  // console.log(JSON.stringify(orderDataDuplicationRemoveUsingReducer))
     

  const totalQuantity = orderDataDuplicationCombineBasedOnSetNameUsingReducer
    ?.map?.((k:any) => k.quantity)
    .reduce?.((a:any, b:any) => a + b, 0);
    const totalAmount = order?.orderDetails
    ?.map?.((k) => k.quantity * k.singleNetPrice).reduce((a, b) => a + b, 0);
    const { t } = ReactI18next.useTranslation();
    // alert(totalAmount)
    const [language,setLanguage] = React.useState('en-IN');
    const { i18n } = ReactI18next.useTranslation();
    const languageValue = i18n.language
    React.useEffect(()=>{
      if(languageValue === "en"){
        setLanguage('en-IN')
      }else if(languageValue === "fr")
      {
        setLanguage('fr-FR')
      }
      else{
        setLanguage('de-DE')
      }
    },[languageValue])
  
  return (
    totalQuantity ? ( 
    <>
      {hideHeader ? null : (
        <Mui.CardHeader
          title={order?.orderCode ?? null}
          action={
            order?.orderedOn
              ? (t('Ordered on')) + ` : ${DateFns.format(
                  new Date(),
                  "dd.MM.yyyy"
                )}`
              : null
          }
        />
      )}
      <Mui.Table style={{ borderSpacing: "unset" }}>
        <Mui.TableHead>
          <Mui.TableRow>
            <Mui.TableCell
              style={{
                color: theme.palette.text.secondary,
                textTransform: "uppercase",
              }}
            >
              <ReactI18next.Trans i18nKey="Model">Model</ReactI18next.Trans>
            </Mui.TableCell>
            <Mui.TableCell
              style={{
                color: theme.palette.text.secondary,
                textTransform: "uppercase",
              }}
            >
              <ReactI18next.Trans i18nKey="Name">Name</ReactI18next.Trans>
            </Mui.TableCell>
            {order?.assignedTo != "0" && userRole == "customer" ? null : (
              <Mui.TableCell
                align="right"
                style={{
                  color: theme.palette.text.secondary,
                  textTransform: "uppercase",
                }}
              >
                <ReactI18next.Trans i18nKey="PriceexclVAT">
                  Price excl. VAT
                </ReactI18next.Trans>
              </Mui.TableCell>
            )}
            <Mui.TableCell
              align="right"
              style={{
                color: theme.palette.text.secondary,
                textTransform: "uppercase",
              }}
            >
              <ReactI18next.Trans i18nKey="Quantity">
                Quantity
              </ReactI18next.Trans>
            </Mui.TableCell>
            {order?.assignedTo != "0" && userRole == "customer" ? null : (
              <Mui.TableCell
                align="right"
                style={{
                  color: theme.palette.text.secondary,
                  textTransform: "uppercase",
                }}
              >
                <ReactI18next.Trans i18nKey="Sub total">
                  Sub total
                </ReactI18next.Trans>
              </Mui.TableCell>
            )}
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          {orderDataDuplicationCombineBasedOnSetNameUsingReducer?.map((product: any) => {
            const singleNetPrice = product.singleNetPrice
            const subTotal = product.quantity * product.singleNetPrice
            const vat = (!order?.vatTaxPercent ? (19/100 ) : (order?.vatTaxPercent/100)) * totalAmount
            const grandTotal = ((!order?.vatTaxPercent ? (19/100 ) : (order?.vatTaxPercent/100)) * totalAmount)+ +totalAmount
             
            const localeSingleNetPrice = singleNetPrice?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
            const localeSubTotal = subTotal?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
            const localeVat = vat?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
            const localeGrandTotal = grandTotal?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
            return(
              <Mui.TableRow key={uuidv4()}>
              <Mui.TableCell style={{ fontWeight: "bold" }}>
                {product?.hundCode}
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Box
                    // color="primary.main"
                    marginRight={1}
                    component="span"
                    style={{ fontSize: theme.typography.h5.fontSize }}
                  >
                    <i className={product?.iconClass} />
                  </Mui.Box>
                  <Mui.Typography
                    // color="primary"
                    style={{ fontWeight: "bold" }}
                    variant="body1"
                  >
                   {product?.setName}
                  </Mui.Typography>
                </Mui.Box>
              </Mui.TableCell>
              {order?.assignedTo != "0" && userRole == "customer" ? null : (
                <Mui.TableCell align="right" style={{ fontWeight: "bold" }}>
                  &euro;&nbsp;{localeSingleNetPrice ?? 0}
                  {(Configs.Workaround.isCloudArticle({
                    code: product?.setCode,
                  }) ||
                    Configs.Workaround.isCloudArticle({
                      code: product?.hundCode,
                    })) && (
                    <Mui.Typography variant="caption" component="div">
                      monthly
                    </Mui.Typography>
                  )}
                </Mui.TableCell>
              )}
              <Mui.TableCell align="right" style={{ fontWeight: "bold" }}>
                {product.quantity}
              </Mui.TableCell>
              {order?.assignedTo != "0" && userRole == "customer" ? null : (
                <Mui.TableCell align="right" style={{ fontWeight: "bold" }}>
                  &euro;&nbsp;{localeSubTotal ?? 0 }
                  {(Configs.Workaround.isCloudArticle({
                    code: product?.setCode,
                  }) ||
                    Configs.Workaround.isCloudArticle({
                      code: product?.hundCode,
                    })) && (
                    <Mui.Typography variant="caption" component="div">
                      monthly
                    </Mui.Typography>
                  )}
                </Mui.TableCell>
              )}
            </Mui.TableRow>
            )
          }
            
          )}
          {order?.assignedTo != "0" && userRole == "customer" ? null : (
            <Mui.TableRow>
              <td />
              <td />
              <td />
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">
                  <ReactI18next.Trans i18nKey="Total">Total</ReactI18next.Trans>
                </Mui.Box>
              </Mui.TableCell>
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">
                &euro;&nbsp;{(totalAmount?totalAmount:0)?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2}) ?? 0}
                </Mui.Box>
              </Mui.TableCell>
            </Mui.TableRow>
          )}
          {order?.assignedTo != "0" && userRole == "customer" ? null : ( 
            <Mui.TableRow>
              <td />
              <td />
              <td />
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">
                  <ReactI18next.Trans i18nKey="VAT">VAT</ReactI18next.Trans> (
                    {Numeral(!order?.vatTaxPercent ? 19 : order.vatTaxPercent).format("0%")})
                </Mui.Box>
              </Mui.TableCell>
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">
                &euro;&nbsp;{((!order?.vatTaxPercent ? (19/100 ) : (order?.vatTaxPercent/100)) * totalAmount)?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})?? 0}
                </Mui.Box>
              </Mui.TableCell>
            </Mui.TableRow>
          )}
          {order?.assignedTo != "0" && userRole == "customer" ? null : (
            <Mui.TableRow>
              <td />
              <td />
              <td />
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">
                  <ReactI18next.Trans i18nKey="Grand total">
                    Grand total
                  </ReactI18next.Trans>
                </Mui.Box>
              </Mui.TableCell>
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">
                &euro;&nbsp;{(((!order?.vatTaxPercent ? (19/100 ) : (order?.vatTaxPercent/100)) * totalAmount)+ +totalAmount)?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})}
                </Mui.Box>
              </Mui.TableCell>
            </Mui.TableRow>
          )}
          {order?.assignedTo !== "0" && userRole === "customer" ? (
            <Mui.TableRow>
              <td />
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold"><ReactI18next.Trans i18nKey="Total quantity">
                Total quantity
            </ReactI18next.Trans></Mui.Box>
              </Mui.TableCell>
              <Mui.TableCell align="right">
                <Mui.Box fontWeight="bold">{totalQuantity?.toLocaleString?.(language)}</Mui.Box>
              </Mui.TableCell>
            </Mui.TableRow>
          ) : null}
        </Mui.TableBody>
      </Mui.Table>
      <Mui.Grid container spacing={2} style={{ marginTop: theme.spacing(3) }}>
        {hideAddress ? null : order?.assignedTo !== "0" &&
          userRole === "customer" ? null : (
          <Mui.Grid item xs={12} md={6}>
            <Mui.Typography variant="h6">
              <ReactI18next.Trans i18nKey="Billing address">
                Billing address
              </ReactI18next.Trans>
            </Mui.Typography>
            <address style={{ textTransform: "capitalize" }}>
              {order?.billingAddress ? (
                <>
                  <Mui.Typography variant="body1">
                    {order?.billingAddress?.companyName}
                  </Mui.Typography>
                  <Mui.Typography variant="body1">
                    {order?.billingAddress?.firstName}{" "}
                    {order?.billingAddress?.lastName}
                  </Mui.Typography>
                  <Mui.Typography variant="body2">
                    {order?.billingAddress?.address1
                      ? `${order?.billingAddress?.address1}, `
                      : ""}
                    {order?.billingAddress?.address2
                      ? `${order?.billingAddress?.address2}, `
                      : ""}
                    {order?.billingAddress?.address3}
                  </Mui.Typography>
                  <Mui.Typography variant="body2">
                    {order?.billingAddress?.address3}
                  </Mui.Typography>
                  <Mui.Typography variant="body2">
                    {order?.billingAddress?.state
                      ? `${order?.billingAddress?.state}, `
                      : ""}
                    {order?.billingAddress?.city
                      ? `${order?.billingAddress?.city}, `
                      : ""}
                    {order?.billingAddress?.country}
                  </Mui.Typography>
                  <Mui.Typography variant="body2">
                    {order?.billingAddress?.postalCode}
                  </Mui.Typography>
                </>
              ) : (
                <Mui.Typography variant="body1"><ReactI18next.Trans i18nKey="Not available">
                Not available
              </ReactI18next.Trans></Mui.Typography>
              )}
            </address>
          </Mui.Grid>
        )}
        {order?.assignedTo !== "0" && userRole === "customer" ? null : (
          <Mui.Grid item xs={12} md={6}>
            <Mui.Typography variant="h6">
              <ReactI18next.Trans i18nKey="Payment details">
                Payment details
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography variant="body2">
              <ReactI18next.Trans i18nKey="Payment mode">
                Payment mode
              </ReactI18next.Trans>
              :&nbsp;
              <Mui.Typography
                style={{ fontWeight: "bold" }}
                component="span"
                variant="body2"
              >
                {/* TODO: Change this hardcoded payment mode value */}
                Payment by credit card
              </Mui.Typography>
            </Mui.Typography>
            <Mui.Typography variant="body2">
              <ReactI18next.Trans i18nKey="Transaction code">
                Transaction code
              </ReactI18next.Trans>
              :&nbsp;
              <Mui.Typography
                style={{ fontWeight: "bold" }}
                component="span"
                variant="body2"
              >
                {order?.paymentDetails?.transCode}
              </Mui.Typography>
            </Mui.Typography>
          </Mui.Grid>
        )}
        {hideUserDetails ? null : (
          <Mui.Grid item xs={12} md={6}>
            {order?.assignedTo != "0" && userRole == "customer" ? (
              <Mui.Typography variant="h6"><ReactI18next.Trans i18nKey="Dealer details">
              Dealer details
            </ReactI18next.Trans></Mui.Typography>
            ) : (
              <Mui.Typography variant="h6">
                <ReactI18next.Trans i18nKey="User details">
                  User details
                </ReactI18next.Trans>
              </Mui.Typography>
            )}
            <Mui.Typography variant="body2">
              <ReactI18next.Trans i18nKey="Full name">
                Full name
              </ReactI18next.Trans>
              :&nbsp;
              <Mui.Typography
                style={{ fontWeight: "bold" }}
                component="span"
                variant="body2"
              >
                {order?.userFirstName} {order?.userLastName}
              </Mui.Typography>
            </Mui.Typography>
            <Mui.Typography variant="body2">
              <ReactI18next.Trans i18nKey="Email">Email</ReactI18next.Trans>
              :&nbsp;
              <Mui.Typography
              onClick={()=>{window.open(`mailto:${order?.userEmail}`)}}
                style={{ fontWeight: "bold" }}
                component="span"
                variant="body2"
              >
                <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{order?.userEmail}</Mui.Link></Mui.Tooltip>
              </Mui.Typography>
            </Mui.Typography>
          </Mui.Grid>
        )}
      </Mui.Grid>
    </>
    ):
    (
      <Mui.Box
        padding={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={"100%"}
        minHeight={"100%"}
        maxHeight={"100%"}
        height={400}
        flexDirection="column"
      >
        <Mui.CircularProgress />
      </Mui.Box>
    )
  );
}
