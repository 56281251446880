/** @format */

import * as Mui from "@material-ui/core";
import * as DateFns from "date-fns";
import * as MuiIcons from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FlagIcon from "@material-ui/icons/Flag";
import InfoIcon from "@material-ui/icons/Info";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as MuiIcon from "@material-ui/icons";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import { makeStyles, Theme } from "@material-ui/core";
import * as CustomerDetail from "src/app/pages/dealercustomer/customerdetail";
import SwipeableViews from "react-swipeable-views";
import { Article } from "../..";

interface Data extends Models.Order.Main {}

interface Dealer extends Models.ImportDealer.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

function descendingComparatorForDealer<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Ordered = "asc" | "desc";

function getComparatorForDealer<Key extends keyof any>(
  order: Ordered,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparatorForDealer(a, b, orderBy)
    : (a, b) => -descendingComparatorForDealer(a, b, orderBy);
}

function stableSortForDealer<T>(
  array: T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTablePropsForDealer {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSortForDealer: (
    event: React.MouseEvent<unknown>,
    property: keyof Dealer
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Ordered;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHeadForDealer(
  props: EnhancedTablePropsForDealer & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSortForDealer, ...restOfProps } =
    props;
  const createSortHandler =
    (property: keyof Dealer) => (event: React.MouseEvent<unknown>) => {
      onRequestSortForDealer(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Dealer)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}
const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);
function EnhancedTableForUser({
  rows,
  headComapany,
  headEmail,
  headPhone,
  headCountry,
  headCity,
  headLocation,
  headMasterID,
  headProfileID,
  headFirstName,
  headLastName,
  headUserID,
  headUserType,
  headStatus,
  headDealerID,
  dealerAddressID,
  postalCode,
  street,
  dealerContactID,
  handleUpdateRoleClick: handleRoleClick,
}: {
  rows: Models.ImportDealer.Main[];
  headComapany: string | null;
  headEmail: string | null;
  headPhone: string | null;
  headCountry: string | null;
  headCity: string | null;
  headLocation: string | null;
  headMasterID: string | null;
  headProfileID: string | null;
  headFirstName: string | null;
  headLastName: string | null;
  headUserID: string | null;
  headUserType: string | null;
  headStatus: string | null;
  headDealerID: string | null;
  dealerAddressID: string | null;
  postalCode: string | null;
  street: string | null;
  dealerContactID: string | null;
  handleUpdateRoleClick(props: { roles: any }): void;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Ordered>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Dealer>("dealerProfileID");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [defaultCheck, setDefaultCheck] = React.useState<boolean>(true);
  const [disable, setDisable] = React.useState<boolean>(true);
  const [rowsPerPage] = React.useState(5);
  const importDealerStoreS = React.useContext(App.Contexts.importDealerStore);
  const [importname, setImportname] = React.useState<string>();

  React.useEffect(() => {
    if (importDealerStoreS.selected.length > 0) {
      var result = importDealerStoreS.selected.find(
        ({ userType }) => userType === "P"
      );
      if (result?.status === "1") {
        setDisable(true);
      } else if (result?.status === "0") {
        setDisable(false);
      }
      const storeArray = importDealerStoreS.importDealerFromSSO;

      //Yet to import user will having the false value and Imported user will have true value

      var isFirstDealerArray = storeArray
        .filter((k) => k.status)
        .map((k) => (k.status == 0 ? false : true));

      const isFirstDealer = isFirstDealerArray.every((k) =>
        k == false ? true : false
      );

      var dealerTypeArray = importDealerStoreS.importDealerFromSSO.map((k) =>
        k.dealerProfileID == headProfileID && k.userType == "S"
          ? k.userType
          : ""
      );

      // userType of secondary will have true and usertype of primary will have false
      var dealerType = dealerTypeArray.some((k) => (k == "S" ? true : false));
    
      if (isFirstDealer == true && dealerType == true) {
        let newSelected: Models.ImportDealer.DealerUser[] = [];
        var primaryDealerArray = importDealerStoreS.importDealerFromSSO.find(
          (k) => k.userType == "P"
        );
        var maninDealer =
          primaryDealerArray as unknown as Models.ImportDealer.DealerUser;

        const selectedIndex = importDealerStoreS.selected.indexOf(maninDealer);
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(
            importDealerStoreS.selected,
            maninDealer
          );
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(
            importDealerStoreS.selected.slice(1)
          );
        } else if (selectedIndex === importDealerStoreS.selected.length - 1) {
          newSelected = newSelected.concat(
            importDealerStoreS.selected.slice(0, -1)
          );
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            importDealerStoreS.selected.slice(0, selectedIndex),
            importDealerStoreS.selected.slice(selectedIndex + 1)
          );
        }
        setDisable(false);
      }
    }
  }, [
    importDealerStoreS.selected,
    disable,
    importDealerStoreS.importDealer,
    headProfileID,
    importDealerStoreS,
  ]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Dealer
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.dealerProfileID);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  // based on the selection selected row value will be inserted in the row
  // using the handleSelectDealerUser Event
  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: Models.ImportDealer.DealerUser;
  }) => {
    localStorage.removeItem("namedatad");
    let dealerStatus = dealerProfile.status;
    const selectedIndex = importDealerStoreS.selected.indexOf(dealerProfile);
    let newSelected: Models.ImportDealer.DealerUser[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        importDealerStoreS.selected,
        dealerProfile
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(importDealerStoreS.selected.slice(1));
    } else if (selectedIndex === importDealerStoreS.selected.length - 1) {
      newSelected = newSelected.concat(
        importDealerStoreS.selected.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        importDealerStoreS.selected.slice(0, selectedIndex),
        importDealerStoreS.selected.slice(selectedIndex + 1)
      );
    }
    importDealerStoreS.setSelected({ selected: newSelected });

    const filterByStatus = importDealerStoreS.selected.map(
      (k) => k.status === "0"
    );
    const disableImportDealer = filterByStatus.some((k) => k === true);
    if (disableImportDealer === true) {
      setImportDealerDisable(false);
    } else {
      setImportDealerDisable(true);
    }
    localStorage.removeItem("namedatad");
    if (importDealerStoreS.selected.length > 0) {
      var result = importDealerStoreS.selected.find(
        ({ userType }) => userType === "P"
      );
    }
    if (result?.status === "1") {
      setDisable(true);
    } else if (newSelected.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const userStore = React.useContext(App.Contexts.userStore);
  const navigate = Router.useNavigate();
  const etuid = userStore.user?.ETUID;
  const { i18n } = ReactI18next.useTranslation();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();
  const [open, setOpen] = React.useState(false);
  const [importDealerDisable, setImportDealerDisable] = React.useState(false);
  const theme = Mui.useTheme();
  const Id = JSON.stringify(localStorage.getItem("dealerID"));
  const dealerData = JSON.parse(Id);
  const data = JSON.parse(dealerData);
  const handleClose = () => {
    setOpen(false);
  };
  const importDealerStore = React.useContext(App.Contexts.importDealerStore);

  const succesNavigateHandle = () => {
    navigate(`/dealercustomer/managedealer`);
  };
  const handleDisable = () => {
    if (selected.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const stopnavigationHandle = (event: any) => {
    event.stopPropagation();
  };
  React.useEffect(() => {
    const filterByStatus = importDealerStoreS.selected.map(
      (k) => k.status === "0"
    );
    const disableImportDealer = filterByStatus.some((k) => k === true);
    if (disableImportDealer === true) {
      setImportDealerDisable(false);
    } else {
      setImportDealerDisable(true);
    }
    // 
    // 
  }, [importDealerStoreS.selected, importDealerDisable]);
  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <Mui.TableHead>
            <Mui.TableRow>
              <Components.Mui.TableCell leftmost color={theme.palette.divider}>
                <ReactI18next.Trans i18nKey="Dealer user name">
                  Dealer user name
                </ReactI18next.Trans>
              </Components.Mui.TableCell>

              <Components.Mui.TableCell color={theme.palette.divider}>
                <ReactI18next.Trans i18nKey="Email ID">
                  Email ID
                </ReactI18next.Trans>
              </Components.Mui.TableCell>
              <Components.Mui.TableCell color={theme.palette.divider}>
                <ReactI18next.Trans i18nKey="Phone no">
                  Phone no
                </ReactI18next.Trans>
              </Components.Mui.TableCell>
              <Components.Mui.TableCell color={theme.palette.divider}>
                <ReactI18next.Trans i18nKey="Country">
                  Country
                </ReactI18next.Trans>
              </Components.Mui.TableCell>
              <Components.Mui.TableCell color={theme.palette.divider}>
                <ReactI18next.Trans i18nKey="City">City</ReactI18next.Trans>
              </Components.Mui.TableCell>
              <Components.Mui.TableCell color={theme.palette.divider}>
                <ReactI18next.Trans i18nKey="Status">Status</ReactI18next.Trans>
              </Components.Mui.TableCell>
              <Components.Mui.TableCell
                rightmost
                color={theme.palette.divider}
              ></Components.Mui.TableCell>
              
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSortForDealer(
              rows as any,
              getComparatorForDealer(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(
                  row.dealerProfileID as string
                );
                const labelId = `enhanced-table-checkbox-${index}`;
            
                return (
                  <Mui.TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.dealerProfileID}
                  >
                 
                    <Components.Mui.TableCell leftmost
                      specialRowColor
                      style={{ border: "none" }}
                    >
                      {row.firstName + " " + row.lastName}

                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      specialRowColor
                      onClick={() => {
                        window.open(`mailto:${row.email}`);
                      }}
                    >
                      <Mui.Tooltip
                        title={
                          <ReactI18next.Trans i18nKey="click to open an mail">
                            click to open an mail
                          </ReactI18next.Trans>
                        }
                      >
                        <Mui.Link style={{ color: "black" }}>
                          {row.email}
                        </Mui.Link>
                      </Mui.Tooltip>
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      specialRowColor
                      onClick={stopnavigationHandle}
                    >
                      <Mui.Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {row.phoneNumber}
                        <Mui.Tooltip
                          title={
                            <ReactI18next.Trans
                              i18nKey={"Click to copy the phone number"}
                            >
                              Click to copy the phone number.
                            </ReactI18next.Trans>
                          }
                        >
                          <ReactCopyToClipboard.CopyToClipboard
                            text={row.phoneNumber}
                            onCopy={() => {
                              enqueueSnackbar(
                                t(
                                  `Phone number of ${row.firstName} has been successfully  copied to clipboard.`
                                ),
                                {
                                  variant: "success",
                                  preventDuplicate: false,
                                }
                              );
                            }}
                          >
                            <Mui.IconButton size="small" onClick={() => {}}>
                              <MuiIcons.ContentCopy />
                            </Mui.IconButton>
                          </ReactCopyToClipboard.CopyToClipboard>
                        </Mui.Tooltip>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell specialRowColor>
                      {row.countryName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell specialRowColor>
                      {row.city}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell specialRowColor>
                      {row.userID === "" ? (<Mui.Chip
                          icon={
                            <InfoIcon
                              style={{
                                color: theme.palette.info.main,
                              }}
                            />
                          }
                          label="KERN account missing"
                        ></Mui.Chip>) : row.status === "0" ? (
                        <Mui.Chip
                          icon={
                            <InfoIcon
                              style={{
                                color: theme.palette.info.main,
                              }}
                            />
                          }
                          label="Yet to import"
                        ></Mui.Chip>
                      ) : (
                        <Mui.Chip
                          icon={
                            <CheckCircleIcon
                              style={{ color: theme.palette.success.main }}
                            />
                          }
                          label="Imported"
                        ></Mui.Chip>
                      )}
                    </Components.Mui.TableCell>
                   
                    <Components.Mui.TableCell
                      specialRowColor
                      rightmost
                      component="th"
                      id={labelId}
                    >
                      {row.userID === "" ? 
                      <></>: row.status == "1" || row.dealerID === "1"  ? (
                        <Mui.Checkbox
                          checked
                          disabled
                          onChange={(event) =>
                            // by selecting the check box entire row data will be converted to the model class and will be passed as the parameter to the select dealer metho
                            //from the handle click method this row value will be stored in react store
                            // during the submit slected value will be retrived from the store and passed to the method.
                           
                            handleSelectDealerUser({
                              dealerProfile:
                                row as unknown as Models.ImportDealer.DealerUser,
                            })
                          }
                          inputProps={{
                            "aria-label": "select all articles",
                          }}
                        />
                      ) : (
                        <Mui.Checkbox
                        style ={{
                          color: "#0080ff",
                        }}
                          defaultChecked={row.dealerID === "1" ? true : false}
                          onChange={(event) =>
                            // by selecting the check box entire row data will be converted to the model class and will be passed as the parameter to the select dealer metho
                            //from the handle click method this row value will be stored in react store
                            // during the submit slected value will be retrived from the store and passed to the method.
                            handleSelectDealerUser({
                              dealerProfile:
                                row as unknown as Models.ImportDealer.DealerUser,
                            })
                          }
                          inputProps={{
                            "aria-label": "select all articles",
                          }}
                        />
                      )}
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}

            {emptyRows >= 5 && (
              <Mui.TableCell style={{ display: "none" }} colSpan={4}>
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No dealer user found">
                        No dealer user found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}

            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell style={{ display: "none" }} colSpan={0} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      
        <Mui.Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: theme.spacing(4),
          }}
        >
          <Mui.Grid item>
            <Mui.Paper
              square
              style={{
                bottom: "2px",
                borderLeft: "4px solid green",
              }}
            >
              <Mui.Grid>
              </Mui.Grid>
            </Mui.Paper>
          </Mui.Grid>
          <Mui.Grid container style={{marginBottom:"110px",justifyContent:"space-between"}}>
            <Mui.Paper
          square
          style={{ padding: "4px", borderLeft: "4px solid green" }}
        >
          <Mui.Typography
            style={{
              borderRadius: "4px",
              position: "sticky",
            }}
          >
            <span
              style={{
                fontWeight: "bolder",
                fontSize: "15px",
                marginRight: "2px",
              }}
            >
              <ReactI18next.Trans i18nKey="Note">Note</ReactI18next.Trans>:
            </span>
            &nbsp;
            <span>
              <ReactI18next.Trans i18nKey="Please select the dealer users to import">
                Please select the dealer users to import.
              </ReactI18next.Trans>
            </span>
          </Mui.Typography>
        </Mui.Paper>
        {rows.length > 5 && (  <MuiLab.Pagination
              // NOTE: Mui Lab Pagination assumes page starts at 1
              page={page + 1}
              count={Math.ceil(rows.length / rowsPerPage)}
              // NOTE: Mui Lab Pagination assumes page starts at 1
              onChange={(event, page) => handleChangePage(event, page - 1)}
              shape="rounded"
            />)}
          </Mui.Grid>
        </Mui.Grid>
      
 {userStore.user?.RoleName !== "dealer" && (
        <Mui.Button
          variant="contained"
          style={{
          height: "43px",

            marginTop: "30px",
            position: "fixed",
            bottom: "26px",
            left: "18px",
            zIndex: 1,
          }}
          onClick={() => {
            navigate(`/dealercustomer/managedealer`);
          }}
        >
          <ReactI18next.Trans i18nKey="Back">Back</ReactI18next.Trans>
        </Mui.Button>
      )}
      <Mui.Button
        style={{
          height: "43px",
          marginTop: "70px",
          position: "fixed",
          bottom: "26px",
          right: "24px",
        }}
        color="primary"
        variant="contained"
        type="submit"
        disabled={importDealerDisable}
        onClick={async () => {
          const dealerUserResult = {
            dealerProfileID: headProfileID != null ? headProfileID : "",
            dealerMasterID: headMasterID != null ? headMasterID : "",
            firstName: headFirstName != null ? headFirstName : "",
            lastName: headLastName != null ? headLastName : "",
            email: headEmail != null ? headEmail : "",
            userID: headUserID != null ? headUserID : "",
            city: headCity != null ? headCity : "",
            countryName: headComapany != null ? headComapany : "",
            phoneNumber: headPhone != null ? headPhone : "",
            userType: headStatus != null ? headStatus : "",
            status: headComapany != null ? headComapany : "",
            companyName: headComapany != null ? headComapany : "",
            dealerID: headDealerID != null ? headDealerID : "",
          } as unknown as Models.ImportDealer.DealerUser;
          const requestDealer: Models.ImportDealer.RequestDealer = {
            dealerMasterID: headMasterID != null ? headMasterID : "",
            firstName: headFirstName != null ? headFirstName : "",
            lastName: headLastName != null ? headLastName : "",
            email: headEmail != null ? headEmail : "",
            userID: headUserID != null ? headUserID : "",
            city: headCity != null ? headCity : "",
            countryName: headCountry != null ? headCountry : "",
            phoneNumber: headPhone != null ? headPhone : "",
            userType: "P",
            status: headStatus != null ? headStatus : "",
            eTUID: etuid,
            dealerID: headDealerID != null ? headDealerID : "",
            companyName: headComapany != null ? headComapany : "",
            langCode: i18n.language,
            dealerAddressID: dealerAddressID ?? "",
            street: street ?? "",
            postalCode: postalCode ?? "",
            dealerContactID: dealerContactID ?? "",
            dealerUser: Array.from(importDealerStoreS.selected.values()).map(
              (item) =>
                ({
                  companyName: headComapany != null ? headComapany : "",
                  dealerProfileID: item.dealerProfileID,
                  dealerMasterID: item.dealerMasterID,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  email: item.email,
                  userID: item.userID,
                  phoneNumber: item.phoneNumber,
                  city: item.city,
                  userType: item.userType,
                  eTUID: etuid,
                  countryName: item.countryName,
                  
                } as Models.ImportDealer.DealerUser)
            ),
          };

          const namedata = Array.from(importDealerStoreS.selected.values())
            .map((item) => {
              return item.firstName + " " + item.lastName;
            })
            .join(",");
          localStorage.setItem("namedatad", namedata);
      
          try {
            if (requestDealer.dealerUser.length !== 0) {
              var result = await importDealerStoreS.addDealers({
                body: requestDealer,
              });
              await importDealerStoreS.readDealerUsers({
                searchtext: headMasterID,
              });
              if (result === "Mail id is not available sso") {
                enqueueSnackbar(
                  t(
                    `Selected dealer's mail id not available in SSO`
                  ),
                  {
                    variant: "error",
                    preventDuplicate: false,
                  }
                );
                } else {
                  enqueueSnackbar(
                    t(
                      `Dealer has been imported successfully.`
                    ),
                    {
                      variant: "success",
                      preventDuplicate: false,
                    }
                  );
                 await importDealerStore.readDealerUsers({
                    searchtext: headDealerID,
                  });
                }
            }
          } catch (error) {
            throw error;
          }
        }}
      >
        <ReactI18next.Trans i18nKey="IMPORT DEALER">

          IMPORT DEALER
        </ReactI18next.Trans>
      </Mui.Button>
      

      <Mui.Grid container>
      
      </Mui.Grid>
    </div>
  );
}

function EnhancedTable({ rows, cid }: { rows: Models.Order.Main[]; cid: any }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderedOn, setOrderedOn] = React.useState<any>();
  const [orderBy, setOrderBy] = React.useState<keyof Data>("orderCode");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderCode, setOrderCode] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] =
    React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const uid =
    userStore.user?.RoleName === "dealer"
      ? userStore.user?.ETUID
      : localStorage.getItem("etID") ?? "";
  React.useEffect(() => {
    setPage(0);
  }, [rows]);
  const key = "orderCode";

  const orderArrayUniqueByKey = [
    ...new Map(rows.map((item) => [item[key], item])).values(),
  ];
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const orderDetaile = rows;
  var keys = ["setName"];
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();


  const [language, setLanguage] = React.useState("en-IN");
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language;
  React.useEffect(() => {
    if (languageValue === "en") {
      setLanguage("en-IN");
    } else if (languageValue === "fr") {
      setLanguage("fr-FR");
    } else {
      setLanguage("de-DE");
    }
  }, [languageValue]);
  return (
    <div className={classes.root}>
   
      <Components.LightBox
        open={orderDetailsDialogOpen}
        handleClose={() => setOrderDetailsDialogOpen(false)}
        content={
          <CustomerDetail.Details
            orderId={orderId}
            cid={cid}
            orderCode={orderCode}
            orderedOn={orderedOn}
            isDealer={true}
          />
        }
        title={
          <Mui.Typography
            style={{ fontWeight: "bold" }}
            variant="h6"
            className={classes.title}
          >
            <ReactI18next.Trans i18nKey="Order details">
              Order details
            </ReactI18next.Trans>
          </Mui.Typography>
        }
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setOrderDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => {
                window.print();
              }}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      {/* <Mui.Paper className={classes.paper}> */}
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderCode"
                  label={t("Order #")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="Orderdate"
                  label={t("Order date")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="Sourceoforder"
                  label={t("Seller")}
                />
                <EnhancedTableHead
                  rightmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="grossTotalPriceInclVAT"
                  label={t("Status")}
                />
             

              
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(
              orderArrayUniqueByKey as any,
              getComparator(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.orderCode as string);
                const labelId = `enhanced-table-checkbox-${index}`;
                const grossTotalPriceInclVAT: any = row.grossTotalPriceInclVAT;
                const totalPrice =
                  row?.vatTaxPercent === 0
                    ? (19 / 100) * grossTotalPriceInclVAT +
                      +grossTotalPriceInclVAT
                    : row.grossTotalPriceInclVAT;
               
                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => {
                      handleClick(event, row.orderCode as string);
                      setOrderCode(row.orderCode);
                      setOrderedOn(row.orderedOn);
                      setOrderId(row.orderID);
                      setOrderDetailsDialogOpen(true);
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderCode}

                    // selected={isItemSelected}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                      specialRowColor

                      // style={{backgroundColor: theme.palette.background.default}}
                    >
                      {(row.dataFrom === "webshop" &&
                        row.sageOLReferenceBillID != null &&
                        row.assignedTo === "0") ||
                      null
                        ? row.orderCode + "-" + row.sageOLReferenceBillID
                        : row.orderCode}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell specialRowColor>
                      {/* {Numeral(row.grossTotalPriceInclVAT).format("$ 0,.00a")} */}
                      {DateFns.format(new Date(row.orderedOn), "dd.MM.yyyy")}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell specialRowColor>
                      {row.assignedTo === "0"
                        ? "KERN"
                        : row.userFirstName + " " + row.userLastName}
                    </Components.Mui.TableCell>


                    <Components.Mui.TableCell rightmost specialRowColor>
                      <Mui.Grid container justify="space-between">
                        <Components.OrderStatus
                          status={row?.status as 1 | 2 | 3 | 4 | 5}

                         
                        />
                        <Mui.IconButton
                          size="medium"
                          style={{
                            padding: theme.spacing(0.125),
                            fontSize: "18px",
                          }}
                        >
                          <MuiIcon.ChevronRight
                            style={{ fontSize: "30px" }}
                            color="primary"
                          />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                  
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: "0 0 60px 0" }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No orders found">
                        No orders found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
     
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const [txtCompanyName, setTxtCompany] = React.useState<string>("");
  const [txtEmail, setTxtEmail] = React.useState<string>("");
  const [txtPhoneNumber, setTxtPhoneNumber] = React.useState<string>("");
  const [txtCountryName, setTxtCountryName] = React.useState<string>("");
  const [txtCity, setTxtCity] = React.useState<string>("");
  const [txtLocation, setTxtLocation] = React.useState<string>("");
  const [txtMasterID, setTxtMasterID] = React.useState<string>("");
  const [txtProfileID, setTxtProfileID] = React.useState<string>("");
  const [txtFirstName, setTxtFirstName] = React.useState<string>("");
  const [txtLastName, setTxtLastName] = React.useState<string>("");
  const [txtUserID, setTxtUserID] = React.useState<string>("");
  const [txtUserType, setTxtUserType] = React.useState<string>("");
  const [txtStatus, setTxtStatus] = React.useState<any>("");
  const [txtDealerID, setTxtDealerID] = React.useState<string>("");
  const [dealerAddressID, setDealerAddressID] = React.useState<string>("");
  const [street, setStreet] = React.useState<string>("");
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [dealerContactID, setDealerContactID] = React.useState<string>("");

  const [updateDialogOpen, setUpadateRoleDialogOpen] = React.useState<
    | {
        roles: number;
      }
    | undefined
  >();
  const [failureDialogOpen, setfailureRoleDialogOpen] = React.useState<
    | {
        roles: number;
      }
    | undefined
  >();
  const namedata = localStorage.getItem("namedatad");
  const [state, setState] = React.useState<any>();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const [isLoadings] = React.useState<boolean>(false);
  const theme = Mui.useTheme();
  const importDealerStore = React.useContext(App.Contexts.importDealerStore);
  const [searchParams] = Router.useSearchParams();
  const userStore = React.useContext(App.Contexts.userStore);
  const etuid =
    userStore.user?.RoleName === "dealer"
      ? userStore.user?.ETUID
      : localStorage.getItem("etID") ?? "";
  const RoleName = userStore.user?.RoleName;
  const navigate = Router.useNavigate();
  let dealerHeaderDetails = null;
  //Decrypt Dealer Profile ID - start
  let DealerMasterID_Decrypt_q = searchParams.get("q");
  let DealerProfileID_Decrypt_m = searchParams.get("m");
  var DealerMasterID_decodedString = atob(
    DealerMasterID_Decrypt_q != null ? DealerMasterID_Decrypt_q : ""
  );
  var DealerProfileID_decodedString = atob(
    DealerProfileID_Decrypt_m != null ? DealerProfileID_Decrypt_m : ""
  );
  let DealerMasterID = DealerMasterID_decodedString
    ? DealerMasterID_decodedString
    : etuid;
  let DealerProfileID = DealerProfileID_decodedString
    ? DealerProfileID_decodedString
    : etuid;

  var dealerUserNavigation = "";
  if (
    userStore.user?.RoleName === "admin" ||
    userStore.user?.RoleName === "superadmin"
  ) {
    dealerUserNavigation = "/dealercustomer/managedealer";
  } else {
    dealerUserNavigation = "/dealercustomer/adddealeruser";
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  if (window.location.search === "") {
    var DealertxtProfileID = btoa(DealerMasterID.toString() ?? "");
    var DealertxtMasterID = btoa(dealerAddressID);
    navigate(
      `/dealercustomer/adddealeruser?q=${DealertxtMasterID}&m=${DealertxtProfileID}`
    );
  }

  React.useEffect(() => {
    importDealerStore.readUsers({
      searchtext: DealerProfileID,
    });
    BindComapnay(DealerMasterID, DealerProfileID);

    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DealerMasterID, DealerProfileID, RoleName, importDealerStore]);

  async function BindComapnay(DealerMasterID: any, DealerProfileID: any) {
    const company = await importDealerStore.readUsers({
      searchtext: DealerProfileID,
      // roleName: DealerProfileID,
    });
    var dealerResult;
    if (userStore.user?.RoleName === "dealer") {
      var masID = importDealerStore.importDealerFromSSO.map((k) => k.dealerMasterID);
      DealerMasterID = masID[0];
      dealerResult = importDealerStore.importDealerFromSSO.find(
        ({ dealerMasterID }) => dealerMasterID === DealerMasterID
      );
    } else {
      dealerResult = importDealerStore.importDealerFromSSO.find(
        ({ dealerMasterID }) => dealerMasterID === DealerMasterID
      );
      if (dealerResult == undefined) {
        dealerResult = importDealerStore.importDealerFromSSO[0]
      }
    }
    dealerHeaderDetails =
      dealerResult as unknown as Models.ImportDealer.DealerUser;
    const companyName = dealerResult != null ? dealerResult.companyName : "";
    const email = dealerResult != null ? dealerResult.dealerCompanyEmail : "";
    const phoneNumber = dealerResult != null ? dealerResult.phoneNumber : "";
    const countryName = dealerResult != null ? dealerResult.countryName : "";
    const city = dealerResult != null ? dealerResult.city : "";
    const location = dealerResult != null ? dealerResult.countryName : "";
    const masterID = dealerResult != null ? dealerResult.dealerMasterID : "";
    const profileID = dealerResult != null ? dealerResult.dealerProfileID : "";
    const firstName = dealerResult != null ? dealerResult.firstName : "";
    const lastName = dealerResult != null ? dealerResult.lastName : "";
    const userID = dealerResult != null ? dealerResult.userID : "";
    const userType = dealerResult != null ? dealerResult.userType : "";
    const status = dealerResult != null ? dealerResult.status : "";
    const dealerID = dealerResult != null ? dealerResult.dealerID : "";
    const dealerAddressID = dealerResult != null ? dealerResult.dealerAddressID : "";
    const street = dealerResult != null ? dealerResult.street : "";
    const postalCode = dealerResult != null ? dealerResult.postalCode : "";
    const dealerContactID = dealerResult != null ? dealerResult.dealerContactID : "";
    setTxtMasterID(masterID);
    setTxtProfileID(profileID);
    setTxtFirstName(firstName);
    setTxtLastName(lastName);
    setTxtCompany(companyName);
    setTxtEmail(email ?? "");
    setTxtPhoneNumber(phoneNumber);
    setTxtCountryName(countryName);
    setTxtCity(city);
    setTxtLocation(location);
    setTxtUserID(userID);
    setTxtUserType(userType);
    setTxtStatus(status);
    setTxtDealerID(dealerID);
    setDealerAddressID(dealerAddressID ?? "");
    setStreet(street);
    setPostalCode(postalCode);
    setDealerContactID(profileID ?? "");
  }

  const handleChange = (event: any) => {
    if (event.target.value === "All") {
      setSearch("");
    } else {
      setSearch(event.target.value as string);
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const { t } = ReactI18next.useTranslation();

  const [searchOrder, setSearchOrder] = React.useState<string>("");
  const [searchOrderLicense, setSearchOrderLicense] =
    React.useState<string>("");
  const [searchOrderDevice, setSearchOrderDevice] = React.useState<string>("");
  const [filterHide, setFilterHide] = React.useState<boolean>(false);
  const [displayNoData, setDisplayNoData] = React.useState<boolean>(false);
  const [isEmailColor, setIsEmailColor] = React.useState<boolean>(true);
  const [disable, setDisable] = React.useState<boolean>(true);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const [sParams] = Router.useSearchParams();
  var searchParam = sParams.get("q");
  var searchParamCustomerID = sParams.get("m");
  React.useEffect(() => {
    const uid =
      userStore.user?.RoleName === "dealer"
        ? userStore.user?.ETUID
        : localStorage.getItem("etID") ?? "";
    const userRole = "customer";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  React.useEffect(() => {
    var uid = 
    userStore.user?.RoleName === "dealer"
        ? userStore.user?.ETUID
        : DealerMasterID;
    
    const userRole = "customer";
    if (
      userRole === "customer" ||
      userRole === "dealer" ||
      userRole === "admin" ||
      userRole === "superadmin"
    ) {
      if (uid !== undefined && uid !== null) {
        orderStore.read({
          uid,
          userRole,
        });
      }
    }
    localStorage.removeItem("namedatad");
    let dealer: any;
  }, [DealerMasterID, DealerMasterID_Decrypt_q, DealerProfileID, orderStore, userStore.user]);
  let searchHighlight: Boolean = false;
  const getSearchHighlight = (highlightFlag: boolean) => {
    searchHighlight = highlightFlag;
    return highlightFlag;
  };
  const handleSearchOrder = (event: any) => {
    setSearchOrder(event.target.value);
  };
  const customerName: any = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.customerName);
  const emailID = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.emailID);
  const etUserStatus: any = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.etUserStatus);
  const companyName: any = dCustomerStore?.delaerCustomer
    ?.filter((k) => k.customerID === searchParamCustomerID)
    ?.map((k) => k.companyName);
  const fullName = customerName.toString();
  const statusConversion = parseInt(etUserStatus.toString());
  const [states, setStates] = React.useState<any>();
  
  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
    },
    link: {
      display: "flex",
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }));
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Mui.Box p={3}>
            <Mui.Typography>{children}</Mui.Typography>
          </Mui.Box>
        )}
      </div>
    );
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const [detailsDrawerOpen, setDetailsDrawerOpen] =
    React.useState<boolean>(false);
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const customerStore = React.useContext(App.Contexts.customerStore);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const handleSearchLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOrderLicense(event.target.value);
    licenseStore.setSearch({ search: event.target.value as string });
  };
  const eTUID =
    userStore.user?.RoleName === "dealer"
      ? userStore.user?.ETUID
      : localStorage.getItem("etID") ?? "";
  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  const handleStatusFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const status = event.target.value as string | number;
    if (typeof status === "string" && status !== "") {
      return;
    }

    if (status === "") {
      licenseStore.setStatus({ status: undefined });
      return;
    }

    licenseStore.setStatus({ status });
  };
  React.useEffect(() => {
    const uid: any = eTUID;

    const userRole = "customer";
    if (
      (userRole === "customer" || userRole === "dealer") &&
      uid !== undefined &&
      uid !== null
    ) {
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });
    }

    customerStore.read({});

    functionStore.read({
      rows: 1,
      userRole: "customer",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removingLicenseDuplication = licenseStore.filtered.filter((k) =>
    orderStore.orders.length !== 0
      ? k.licenseCode !== "CustInvite"
      : k.setID !== 2
  );
  var licenseFilter = removingLicenseDuplication.filter((k) => k.status === 1);

  /********************End of duplication article remove filter  ********************/

  const [isLoading, setIsLoadings] = React.useState<boolean>(false);
  const [deactivationDialogOpen, setDeactivationDialogOpen] =
    React.useState<boolean>(false);
  const [detailsDrawerOpenDevice, setDetailsDrawerOpenDevice] =
    React.useState<boolean>(false);
  const [currentDevice, setCurrentDevice] = React.useState<
    Models.Devices & { sets: Set<Models.Set.Article> }
  >();
  const easyTouchDeviceStore = React.useContext(
    App.Contexts.easyTouchDeviceStore
  );

  React.useEffect(() => {
    const macId = searchParams.get("macId");
    if (!macId) {
      return;
    }
    if (!easyTouchDeviceStore.devices) {
      return;
    }
    const device = easyTouchDeviceStore.devices.find(
      (device) => device.macID === macId
    );
    if (!device) {
      return;
    }
    if (device) {
      Array.from(licenseStore.selectedArticlesForDeactivation.values()).forEach(
        (setID) => {
          licenseStore.toggleArticleSelectionForDeactivation({ setID });
        }
      );
      device?.sets?.forEach((set) => {
        licenseStore.toggleArticleSelectionForDeactivation({
          setID: set?.setID,
        });
      });
      setDetailsDrawerOpen(true);
      setCurrentDevice(device);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [easyTouchDeviceStore.devices, deactivationDialogOpen]);

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;

    if (userRole === "customer") {
      return;
    }

    const customer = searchParams.get("customer");

    if (!customer) {
      return;
    }

    customerChange(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerChange = (customer: number | string) => {
    const uid: any = eTUID;

    easyTouchDeviceStore.setCustomer({ customer });

    if (uid === undefined || uid === null) {
      return;
    }

    if (customer === "" || customer === undefined || customer === null) {
      return;
    }

    easyTouchDeviceStore.read({
      body: { status: null, userID: customer },
      userRole: "customer",
      uid,
    });
  };

  const handleChangeDevice = (event: React.ChangeEvent<{ value: unknown }>) => {
    const customer = event.target.value as string | number;
    customerChange(customer);
  };

  const handleSearchDevice = (event: any) => {
    setSearchOrderDevice(event.target.value);
    easyTouchDeviceStore.setSearch({ search: event.target.value as string });
  };

  const toggleDetailsDrawerDevice = () => {
    setDetailsDrawerOpenDevice(!detailsDrawerOpenDevice);
  };

  React.useEffect(() => {
    functionStore.read({
      rows: 1,
      userRole: "customer",
    });

    const userRole = "customer";
    const uid: any = eTUID;

    customerStore.read({});
    if (
      (userRole === "customer" || userRole === "EU") &&
      uid !== undefined &&
      uid !== null
    ) {
      easyTouchDeviceStore.read({ userRole: "customer", status: -1, uid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDeactivateAll() {
    Array.from(licenseStore.selectedArticlesForDeactivation.values()).forEach(
      (setID) => {
        licenseStore.toggleArticleSelectionForDeactivation({ setID });
      }
    );
    currentDevice?.sets.forEach(({ setID }) => {
      licenseStore.toggleArticleSelectionForDeactivation({ setID });
    });
    setDetailsDrawerOpenDevice(false);
    setDeactivationDialogOpen(true);
  }

  function handleDeactivateSelected() {
    setDetailsDrawerOpenDevice(false);
    setDeactivationDialogOpen(true);
  }
  React.useEffect(() => {
    functionStore.read({
      rows: 1,
      userRole: "customer",
    });

    const userRole = userStore.user?.RoleName ?? ("customer" || "EU");
    const uid = userStore.user?.ETUID;

    if (userRole === "admin" || userRole === "superadmin") {
      //  customerStore.read({});
    }
    if (
      (userRole === "customer" || userRole === "EU") &&
      uid !== undefined &&
      uid !== null
    ) {
      easyTouchDeviceStore.read({ userRole: "customer", status: 1, uid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleDeactivate() {
    try {
      const userRole = userStore.user?.RoleName;
      const etuid: any = eTUID;

      if (!etuid) {
        return;
      }

      if (!currentDevice) {
        return;
      }

      setIsLoadings(true);

      const successAcknowledgement = () => {
        enqueueSnackbar(t("License(s) deactivated successfully."), {
          variant: "success",
          preventDuplicate: false,
        });

        if (userRole === "admin") {
          easyTouchDeviceStore.read({
            body: { status: null, userID: easyTouchDeviceStore.customer },
            userRole: "admin",
            uid: etuid,
          });
          return;
        }

        easyTouchDeviceStore.read({
          userRole: "customer",
          status: 1,
          uid: etuid,
        });
      };

      if (
        userRole === "customer" &&
        licenseStore.selectedArticlesForDeactivation.size > 0 &&
        licenseStore.selectedArticlesForDeactivation.size ===
          currentDevice?.sets.size
      ) {
        await licenseStore.deactivateAll({
          body: {
            MacID: currentDevice.macID,
            UserId: etuid,
          },
        });
        successAcknowledgement();
        return;
      }

      if (
        (userRole === "admin" || userRole === "superadmin") &&
        easyTouchDeviceStore.customer &&
        licenseStore.selectedArticlesForDeactivation.size > 0 &&
        licenseStore.selectedArticlesForDeactivation.size ===
          currentDevice?.sets.size
      ) {
        await licenseStore.deactivateAll({
          body: {
            MacID: currentDevice.macID,
            UserId: easyTouchDeviceStore.customer as number,
          },
        });
        successAcknowledgement();
        return;
      }

      const dependentSetIds = Array.from(currentDevice?.sets?.values() ?? [])
        .filter((set) =>
          licenseStore.selectedArticlesForDeactivation.has(set?.setID)
        )
        .reduce((acc, set) => {
          Array.from(currentDevice?.sets?.values() ?? [])
            .filter(
              (set) =>
                !licenseStore.selectedArticlesForDeactivation.has(set?.setID)
            )
            .forEach((otherSet) => {
              if (
                otherSet.dependentSets.includes(set.setID) &&
                licenseStore.selectedArticlesForDeactivation.has(otherSet.setID)
              ) {
                return;
              }
              if (otherSet.dependentSets.includes(set.setID)) {
                acc.add(otherSet.setID);
              }
            });
          return acc;
        }, new Set<number>());

      const dependentSets = Array.from(
        currentDevice?.sets?.values() ?? []
      ).filter((set) => dependentSetIds.has(set?.setID));

      const selectedSets = Array.from(currentDevice.sets.values()).filter(
        (set) => licenseStore.selectedArticlesForDeactivation.has(set.setID)
      );

      await licenseStore.deactivateSelected({
        body: {
          LicenseDetailsList: [...dependentSets, ...selectedSets].map((set) => {
            return {
              LicenseDetailID: (set as any)?.licenseDetailID,
              MacID: currentDevice.macID,
            };
          }),
        },
      });
      successAcknowledgement();
    } catch (error) {
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoadings(false);
      setDeactivationDialogOpen(false);
    }
  }
  const navigates = Router.useNavigate();
  return (
    <>
     
      <Mui.Grid container xs={12}>
        <Mui.Grid item>
          <Mui.Typography
            style={{
              height: "23px",
              fontWeight: "bold",
              fontSize: "17px",
              margin: "4px 0 6px 0px",
              opacity: "0.8",
            }}
          >
            <ReactI18next.Trans i18nKey="Dealer company selected for ET License import">
              Dealer company selected for ET License import
            </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
      {txtCompanyName !== null ? (
        <Mui.Paper
          square
          style={{
            margin: "0 0 0 0",
            borderRadius: "5px",
            paddingBottom: "16px",
          }}
        >
          <Mui.Grid xs={12} style={{ marginTop: "12px" }}>
            <Mui.Grid container>
              <Mui.Grid item>
                <Mui.Grid
                  style={{
                    // border: "1px black",
                    // borderRadius: "50%",
                    //  backgroundColor: "#eeeeee",
                    margin: "0px 17px 0px 33px",
                    padding: "8px 0px 2px 4px",
                  }}
                >
                  <MuiIcons.Business
                    style={{
                      // border: "1px black",
                      // borderRadius: "40%",
                      //  backgroundColor: "#eeeeee",
                      width: "53px",
                      height: "43px",
                      color: "grey",
                      margin: "0px 17px 22px 33px",
                    }}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "20px 0px 10px 10px",
                    opacity: "0.8",
                  }}
                >
                  {txtCompanyName != null ? txtCompanyName : ""}
                  {/* {data.companyName} */}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid xs={12}>
            <Mui.Grid container>
              <Mui.Grid item xs={3} style={{ marginLeft: "80px" }}>
                {/* <Mui.Typography
              style={{
                fontWeight: 1000,
                fontSize: theme.spacing(2),
                margin: "6px 0 0 0",
              }}
            >
              Address
            </Mui.Typography> */}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider
              style={{
                marginTop: theme.spacing(1),
              }}
            />
            <Mui.Grid container>
              <Mui.Grid item xs={3}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: theme.spacing(30),
                        height: "22px",
                        margin: "14px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Email Id">
                        Email Id
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Mui.Typography
                      onClick={() => {
                        window.open(`mailto:${txtEmail}`);
                      }}
                      style={{
                        margin: "14px 0 0 0",
                      }}
                    >
                      <Mui.Tooltip
                        title={
                          <ReactI18next.Trans i18nKey="click to open an mail">
                            click to open an mail
                          </ReactI18next.Trans>
                        }
                      >
                        <Mui.Link style={{ color: "black" }}>
                          {txtEmail != null ? txtEmail : ""}
                        </Mui.Link>
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 33px",
                  }}
                >
                  <Mui.Grid item xs={12} md={7} lg={7} xl={7}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        height: "22px",
                        margin: "16px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Telephone no">
                       {userStore.user?.RoleName === "dealer" ?  "Dealer customer ID" : "Telephone no"}
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={4} lg={4} xl={4}>
                    <Mui.Typography
                      style={{
                        margin: "16px 0 0 0",
                      }}
                    >
                      {userStore.user?.RoleName === "dealer" ? userStore.user.CustomerDealerID ?? "" : txtPhoneNumber != null ? txtPhoneNumber : ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid item xs={2}></Mui.Grid>
              <Mui.Grid item xs={3}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="City">
                        City
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography style={{ margin: "14px 0 0 0" }}>
                      {txtCity != null ? txtCity : ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Location">
                        Location
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        margin: "14px 0 0 0",
                      }}
                    >
                      {txtCountryName != null ? txtCountryName : ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid item xs={1}></Mui.Grid>
              <Mui.Grid item xs={3}>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                ></Mui.Grid>
                <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                  }}
                >
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        width: "80px",
                        height: "22px",
                        margin: "16px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Country">
                        Country
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                    <Mui.Typography style={{ margin: "14px 0 0 0" }}>
                      {txtCountryName != null ? txtCountryName : ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
               {userStore.user?.RoleName === "dealer" && <Mui.Grid
                  container
                  style={{
                    alignContent: "space-around",
                    justifyContent: "space-between",
                    margin: "0px 0 0 0px",
                  }}
                >
                  <Mui.Grid item xs={12} md={7} lg={7} xl={7}>
                    <Mui.Typography
                      style={{
                        fontWeight: "normal",
                        height: "22px",
                        margin: "16px 0 0 0",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      <ReactI18next.Trans i18nKey="Telephone no">
                        Telephone no
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={4} lg={4} xl={4}>
                    <Mui.Typography
                      style={{
                        margin: "16px 0 0 0",
                      }}
                    >
                      {txtPhoneNumber != null ? txtPhoneNumber : ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>}
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Paper>
      ) : (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      )}
      {orderStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          <Mui.Grid xs={12}>
            <Mui.Divider style={{ margin: "6px 6px 0 6px" }} />
            <div className={classes.root}>
              <Mui.AppBar
                position="static"
                color="transparent"
                style={{ margin: "0 4px 0 4px" }}
              >
                <Mui.Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  //variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Mui.Tab label={t("Users")} {...a11yProps(0)} />
                  <Mui.Tab label={t("Orders")} {...a11yProps(1)} />
                </Mui.Tabs>
              </Mui.AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  {importDealerStore.isInitialLoading ? (
                    <Mui.Box
                      padding={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width={"100%"}
                      minHeight={"100%"}
                      maxHeight={"100%"}
                      height={400}
                      flexDirection="column"
                    >
                      <Mui.CircularProgress />
                    </Mui.Box>
                  ) : (
                    <>
                      <EnhancedTableForUser
                        rows={importDealerStore.importDealerFromSSO.filter(
                          (userData) => {
                            if (!search) {
                              return true;
                            }
                            return (
                              userData?.firstName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              userData?.lastName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              userData?.countryName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              userData?.email
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              userData?.companyName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              userData?.city
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              userData?.phoneNumber
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            );
                          }
                        )}
                        handleUpdateRoleClick={({ roles }) => {
                          // alert("data main");
                          //alert(roles);
                          if (roles == 1) {
                            setUpadateRoleDialogOpen({ roles });
                          } else {
                            setfailureRoleDialogOpen({ roles });
                          }
                        }}
                        headComapany={txtCompanyName}
                        headEmail={txtEmail}
                        headPhone={txtPhoneNumber}
                        headCountry={txtCountryName}
                        headCity={txtCity}
                        headLocation={txtCountryName}
                        headMasterID={txtMasterID}
                        headProfileID={txtProfileID}
                        headFirstName={txtFirstName}
                        headLastName={txtLastName}
                        headUserID={txtUserID}
                        headUserType={txtUserType}
                        headStatus={txtStatus}
                        headDealerID={txtDealerID}
                        postalCode={postalCode}
                        dealerAddressID={dealerAddressID}
                        street={street}
                        dealerContactID={dealerContactID}
                      />
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <>
                    <Mui.Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width={1}
                      height={48}
                      marginBottom={4}
                    >
                      <Mui.Box display="flex" alignItems="baseline">
                        <Mui.FormControl style={{ width: 300 }}>
                          {orderStore.orders.length > 0 && (
                            <Mui.TextField
                              type="text"
                              value={searchOrder}
                              label={t("Search")}
                              onChange={handleSearchOrder}
                              autoFocus={searchOrder.length > 0}
                            />
                          )}
                        </Mui.FormControl>
                      </Mui.Box>
                      <Mui.Box>
                        <Mui.Tooltip title="Card View" placement="top">
                          <Mui.Box display="inline-block" color="divider">
                            <Mui.IconButton
                              color={
                                orderStore.view === "CARD"
                                  ? "default"
                                  : "inherit"
                              }
                              onClick={() =>
                                orderStore.setView({ view: "CARD" })
                              }
                              style={{ marginRight: theme.spacing(1) }}
                            >
                              <i className="icon-etc-card-view" />
                              {/* <MuiIcons.Dashboard /> */}
                            </Mui.IconButton>
                          </Mui.Box>
                        </Mui.Tooltip>

                        <Mui.Tooltip title="Tabular View" placement="top">
                          <Mui.Box display="inline-block" color="divider">
                            <Mui.IconButton
                              color={
                                orderStore.view === "TABULAR"
                                  ? "default"
                                  : "inherit"
                              }
                              onClick={() =>
                                orderStore.setView({ view: "TABULAR" })
                              }
                            >
                              <i className="icon-etc-table-view" />
                              {/* <MuiIcons.TableChart /> */}
                            </Mui.IconButton>
                          </Mui.Box>
                        </Mui.Tooltip>
                      </Mui.Box>
                    </Mui.Box>
                    {orderStore.isInitialLoading ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        {orderStore.view === "CARD" && (
                          <CustomerDetail.Views.Cards
                            orders={orderStore.orders.filter((order) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                order?.orderCode
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userFirstName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userLastName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.companyName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase())
                              );
                            })}
                          />
                        )}

                        {orderStore.view === "TABULAR" && (
                          <EnhancedTable
                            rows={orderStore.orders.filter((order) => {
                              if (!searchOrder) {
                                return true;
                              }
                              return (
                                order?.orderCode
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userFirstName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.userLastName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase()) ||
                                order?.companyName
                                  ?.toLowerCase()
                                  .includes(searchOrder.toLowerCase())
                              );
                            })}
                            cid={eTUID != null ? atob(eTUID.toString()) : "0"}
                          />
                        )}
                    
                      </>
                    )}
                  </>
                 
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <>
                    <CustomerDetail.DetailsLicense
                      open={detailsDrawerOpen}
                      handleClose={toggleDetailsDrawer}
                      functionData={functionStore.functions}
                      license={currentLicense}
                    />
                    <Mui.Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginBottom: theme.spacing(3) }}
                    >
                      <Mui.FormControl
                        style={{ width: 300, marginRight: theme.spacing(2) }}
                      >
                        {removingLicenseDuplication.length > 0 && (
                          <>
                            <Mui.InputLabel id="demo-simple-select-outlined-label">
                              <ReactI18next.Trans i18nKey="Filter by status">
                                Filter by status
                              </ReactI18next.Trans>
                            </Mui.InputLabel>
                            <Mui.Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={licenseStore.status}
                              onChange={handleStatusFilterChange}
                              label={t("Filter by status")}
                            >
                              <Mui.MenuItem value="">
                                <em>
                                  <ReactI18next.Trans i18nKey="None">
                                    None
                                  </ReactI18next.Trans>
                                </em>
                              </Mui.MenuItem>

                              <Mui.MenuItem value={1}>
                                <Components.WorkingStatus
                                  status={1}
                                  labels={{ 1: "Available", 2: "Used" }}
                                />
                              </Mui.MenuItem>

                              <Mui.MenuItem value={2}>
                                <Components.WorkingStatus
                                  status={2}
                                  labels={{ 1: "Available", 2: "Used" }}
                                />
                              </Mui.MenuItem>
                            </Mui.Select>
                          </>
                        )}
                      </Mui.FormControl>
                      {removingLicenseDuplication.length > 0 && (
                        <Mui.FormControl style={{ width: 300 }}>
                          <Mui.TextField
                            value={searchOrderLicense}
                            label={t("Search")}
                            onChange={handleSearchLicense}
                            autoFocus={searchOrderLicense.length > 0}
                          />
                        </Mui.FormControl>
                      )}
                    </Mui.Grid>
                    <Mui.Grid container spacing={3}>
                      {licenseStore.isInitialLoading ||
                      ((userStore.user?.RoleName === "admin" ||
                        userStore.user?.RoleName === "superadmin") &&
                        licenseStore.isLoading) ? (
                        <Mui.Box
                          padding={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={"100%"}
                          minHeight={"100%"}
                          maxHeight={"100%"}
                          height={400}
                          flexDirection="column"
                        >
                          <Mui.CircularProgress />
                        </Mui.Box>
                      ) : (
                        <>
                          {removingLicenseDuplication.length === 0 && (
                            <Mui.Grid
                              container
                              style={{ padding: "60px 0 60px 0" }}
                            >
                              <Mui.Grid item xs={12}>
                                <MuiLab.Alert severity="info">
                                  <ReactI18next.Trans i18nKey="No licenses found">
                                    No licenses found
                                  </ReactI18next.Trans>
                                </MuiLab.Alert>
                              </Mui.Grid>
                            </Mui.Grid>
                          )}
                          {licenseFilter.map((device: any) => (
                            <Mui.Grid key={uuidv4()} item xs={12} md={6}>
                              <CustomerDetail.CardLicense
                                license={{
                                  ...device,
                                  setFuncstionsList:
                                    functionStore.functions.filter((func) =>
                                      device.setList?.functions.includes(
                                        func.setFunctionID
                                      )
                                    ),
                                }}
                                handleClick={() => {
                                  setCurrentLicense(device);
                                  toggleDetailsDrawer();
                                }}
                                isDealer={true}
                              />
                            </Mui.Grid>
                          ))}
                        </>
                      )}
                    </Mui.Grid>
                  </>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Components.ResponsiveFullscreenDialogWithScroll
                    open={deactivationDialogOpen}
                    title={"Deactivate License(s)"}
                    handleClose={() => {}}
                    contentText={
                      <>
                        {(function () {
                          if (
                            currentDevice?.sets.size ===
                            licenseStore.selectedArticlesForDeactivation.size
                          ) {
                            return null;
                          }

                          const dependentSetIds = Array.from(
                            currentDevice?.sets?.values() ?? []
                          )
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .reduce((acc, set) => {
                              Array.from(currentDevice?.sets?.values() ?? [])
                                .filter(
                                  (set) =>
                                    !licenseStore.selectedArticlesForDeactivation.has(
                                      set?.setID
                                    )
                                )
                                .forEach((otherSet) => {
                                  if (
                                    otherSet.dependentSets.includes(
                                      set.setID
                                    ) &&
                                    licenseStore.selectedArticlesForDeactivation.has(
                                      otherSet.setID
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    otherSet.dependentSets.includes(set.setID)
                                  ) {
                                    acc.add(otherSet.setID);
                                  }
                                });
                              return acc;
                            }, new Set<number>());

                          if (dependentSetIds.size > 0) {
                            return (
                              <MuiLab.Alert
                                severity="warning"
                                style={{ marginBottom: theme.spacing(1.5) }}
                              >
                                <MuiLab.AlertTitle>
                                  Proceed with caution!
                                </MuiLab.AlertTitle>
                                Based on your selected license we found the
                                following dependent articles. The following
                                dependent articles will also be deactivated if
                                you proceed.
                              </MuiLab.Alert>
                            );
                          }
                        })()}

                        <Mui.Typography gutterBottom>
                          Are you sure you want to deactivate the following
                          articles for this device with{" "}
                          <strong>Machine ID - {currentDevice?.macID}</strong>?
                        </Mui.Typography>

                        <Mui.Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginTop: theme.spacing(0.5) }}
                        >
                          Selected license
                        </Mui.Typography>
                        <Mui.Box
                          style={{
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            padding: theme.spacing(2, 0),
                          }}
                        >
                          {Array.from(currentDevice?.sets?.values() ?? [])
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .map((set) => (
                              <Mui.Box
                                style={{
                                  minWidth: 300,
                                  maxWidth: 300,
                                  width: "100%",
                                  marginRight: theme.spacing(3),
                                  display: "inline-block",
                                }}
                                key={`Article#${set.setID}`}
                              >
                                <Article.Card
                                  style={{
                                    minWidth: "100%",
                                    backgroundColor:
                                      theme.palette.background.default,
                                  }}
                                  functions={functionStore.functions.filter(
                                    (func) =>
                                      set.functions.includes(func.setFunctionID)
                                  )}
                                  article={set}
                                  variant={"DETAILED"}
                                />
                              </Mui.Box>
                            ))}
                        </Mui.Box>
                        {(function () {
                          if (
                            currentDevice?.sets.size ===
                            licenseStore.selectedArticlesForDeactivation.size
                          ) {
                            return null;
                          }

                          const dependentSetIds = Array.from(
                            currentDevice?.sets?.values() ?? []
                          )
                            .filter((set) =>
                              licenseStore.selectedArticlesForDeactivation.has(
                                set?.setID
                              )
                            )
                            .reduce((acc, set) => {
                              Array.from(currentDevice?.sets?.values() ?? [])
                                .filter(
                                  (set) =>
                                    !licenseStore.selectedArticlesForDeactivation.has(
                                      set?.setID
                                    )
                                )
                                .forEach((otherSet) => {
                                  if (
                                    otherSet.dependentSets.includes(
                                      set.setID
                                    ) &&
                                    licenseStore.selectedArticlesForDeactivation.has(
                                      otherSet.setID
                                    )
                                  ) {
                                    return;
                                  }
                                  if (
                                    otherSet.dependentSets.includes(set.setID)
                                  ) {
                                    acc.add(otherSet.setID);
                                  }
                                });
                              return acc;
                            }, new Set<number>());

                          const dependentSets = Array.from(
                            currentDevice?.sets?.values() ?? []
                          ).filter((set) => dependentSetIds.has(set?.setID));

                          if (dependentSets.length > 0) {
                            return (
                              <>
                                <Mui.Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Dependent articles based on your selected
                                  license
                                </Mui.Typography>
                                <Mui.Box
                                  style={{
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                    padding: theme.spacing(2, 0),
                                  }}
                                >
                                  {Array.from(dependentSets.values()).map(
                                    (set) => (
                                      <Mui.Box
                                        style={{
                                          minWidth: 300,
                                          maxWidth: 300,
                                          width: "100%",
                                          marginRight: theme.spacing(3),
                                          display: "inline-block",
                                        }}
                                        key={`Article#${set.setID}`}
                                      >
                                        <Article.Card
                                          style={{
                                            minWidth: "100%",
                                            backgroundColor:
                                              theme.palette.background.default,
                                          }}
                                          functions={functionStore.functions.filter(
                                            (func) =>
                                              set.functions.includes(
                                                func.setFunctionID
                                              )
                                          )}
                                          article={set}
                                          variant={"DETAILED"}
                                        />
                                      </Mui.Box>
                                    )
                                  )}
                                </Mui.Box>
                              </>
                            );
                          }
                        })()}
                      </>
                    }
                    actions={
                      <>
                        <Mui.Button
                          variant="contained"
                          onClick={() => {
                            setDeactivationDialogOpen(false);
                            setDetailsDrawerOpenDevice(true);
                          }}
                        >
                          <ReactI18next.Trans i18nKey="Cancel">
                            Cancel
                          </ReactI18next.Trans>
                        </Mui.Button>
                        <Mui.Button
                          variant="contained"
                          onClick={() => handleDeactivate()}
                          disabled={isLoading}
                          {...(!isLoading && {
                            style: {
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.common.white,
                            },
                          })}
                        >
                          <ReactI18next.Trans i18nKey="Deactivate">
                            Deactivate
                          </ReactI18next.Trans>
                          {isLoading && (
                            <Mui.CircularProgress
                              style={{
                                marginLeft: theme.spacing(1),
                              }}
                              color={"inherit"}
                              size={theme.typography.button.fontSize}
                            />
                          )}
                        </Mui.Button>
                      </>
                    }
                  />
                  <CustomerDetail.DetailsDevice
                    open={detailsDrawerOpenDevice}
                    handleClose={toggleDetailsDrawerDevice}
                    device={currentDevice}
                    handleDeactivateAll={handleDeactivateAll}
                    handleDeactivateSelected={handleDeactivateSelected}
                  />
                  <Mui.Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    <Mui.FormControl style={{ width: 300 }}>
                      {easyTouchDeviceStore.filtered.length > 0 && (
                        <Mui.TextField
                          value={searchOrderDevice}
                          label={t("Search")}
                          onChange={handleSearchDevice}
                          autoFocus={searchOrderDevice.length > 0}
                        />
                      )}
                    </Mui.FormControl>
                  </Mui.Grid>
                  <Mui.Grid container spacing={3}>
                    {easyTouchDeviceStore.isInitialLoading ||
                    ((userStore.user?.RoleName === "admin" ||
                      userStore.user?.RoleName === "superadmin") &&
                      easyTouchDeviceStore.isLoading) ? (
                      <Mui.Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        minHeight={"100%"}
                        maxHeight={"100%"}
                        height={400}
                        flexDirection="column"
                      >
                        <Mui.CircularProgress />
                      </Mui.Box>
                    ) : (
                      <>
                        {easyTouchDeviceStore.filtered.length === 0 && (
                          <Mui.Grid item xs={12}>
                            <MuiLab.Alert
                              severity="info"
                              style={{ width: "100%" }}
                            >
                              <MuiLab.AlertTitle>
                                <ReactI18next.Trans i18nKey="No terminal devices found">
                                  No terminal devices found
                                </ReactI18next.Trans>
                              </MuiLab.AlertTitle>
                              <ReactI18next.Trans i18nKey="There are no terminal device(s) with installed">
                                There are no terminal device(s) with installed
                                license(s) found for you! Please try installing
                                your purchased license(s) on your terminal
                                device(s) or reach out to KERN admin.
                              </ReactI18next.Trans>
                            </MuiLab.Alert>
                          </Mui.Grid>
                        )}

                        {easyTouchDeviceStore.filtered.map(
                          (
                            device: Models.Devices & {
                              sets: Set<Models.Set.Article>;
                            }
                          ) => (
                            <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={4}>
                              <CustomerDetail.CardDevice
                                device={device}
                                handleClick={(event: any) => {
                                  licenseStore.resetSelectedArticlesForDeactivation();
                                  setCurrentDevice(device);
                                  toggleDetailsDrawerDevice();
                                }}
                              />
                            </Mui.Grid>
                          )
                        )}
                      </>
                    )}
                  </Mui.Grid>
                </TabPanel>
              </SwipeableViews>
            </div>
          </Mui.Grid>
        </>
      )}
    </>
  );
});
