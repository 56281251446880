/** @format */

import * as Models from "src/models";
import * as Services from "src/services";
// import * as Configs from "src/configs";

export declare namespace Order {
  export interface IOrder {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Order.IOrder;

  export namespace GetAllData {
    export interface Body {
      status?: number;
      FromDate: string;
      ToDate: string;
    }

    export interface Props extends Pick<Models.User.Main, "uid"> {
      body?: Order.GetAllData.Body;
      userRole: string;
    }

    export type Return = Models.API.Response<Models.Order.Main[]>;
  }

  export namespace GetData {
    export interface Body {
      id?: number | string;
      eid?: string;
      cid?: string | number;
    }

    export interface Props {
      body: Order.GetData.Body;
    }

    export type Return = Models.API.Response<Models.Order.Main[]>;
  }

  export namespace PassOrderDataForMailTrigger {
    export interface Body {
      id?: number | string;
      eid?: any;
      cid?: string | number;
      langCode?: any;
    }

    export interface Props {
      body: Order.PassOrderDataForMailTrigger.Body;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace GetGeneralTermsAndConditions {
    export interface Props {
      language: string;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace GetPrivacyPolicy {
    export type Props = Order.GetGeneralTermsAndConditions.Props;

    export type Return = Order.GetGeneralTermsAndConditions.Return;
  }

  export namespace Create {
    export type Body = Models.Order.Creation;

    export interface Props {
      body: Order.Create.Body;
      customerDealerId: string | number;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace AddOrderUsingReference {
    //export type Body = AddOrderUsingReference.Props;
    export interface Body {
      OrderCode: string;
      ETUID: string | number;
      LangCode: string;
    }
    export interface Props {
      body: Order.AddOrderUsingReference.Body;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace AssignOrder {
    //export type Body = AddOrderUsingReference.Props;
    export interface Body {
      OrderCode: string;
      ETUID: string | number;
      LangCode: string;
    }
    export interface Props {
      body: Order.AssignOrder.Body;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class Order {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Order.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getData({
    body,
  }: Order.GetData.Props): Promise<Order.GetData.Return> {
    const url = this.urlFactory.create({
      named: ["Order", "GetByOrderId"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.GetData.Return,
      Order.GetData.Body
    >({
      url,
      body: {
        ...body,
        eid: body.eid ?? "",
      },
    });
    console.log("Raw data" + { response });

    return response;
  }
  public async PassOrderDataForMailTrigger({
    body,
  }: Order.PassOrderDataForMailTrigger.Props): Promise<Order.PassOrderDataForMailTrigger.Return> {
    const url = this.urlFactory.create({
      named: [
        "Email",
        "Ordermailcustomerdealer",
        `${body.eid}`,
        `${body.langCode}`,
      ],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.PassOrderDataForMailTrigger.Return,
      Order.PassOrderDataForMailTrigger.Body
    >({
      url,
      body: {
        ...body,
        eid: body.eid ?? "",
      },
    });
    // console.log({ response });

    return response;
  }

  public async getAllData({
    body,
    uid,
    userRole,
  }: Order.GetAllData.Props): Promise<Order.GetAllData.Return> {
    const url =
      userRole === "customer" || userRole === "dealer"
        ? this.urlFactory.create({
            named: ["Order", "Get", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["Order", "GetAll"],
          });
    // console.log({ url });

    const response = await (userRole === "customer" || userRole === "dealer"
      ? this.httpManager.get<Order.GetAllData.Return>({
          url,
        })
      : this.httpManager.post<Order.GetAllData.Return, Order.GetAllData.Body>({
          url,
          body: body ?? {
            status: 1,
            FromDate: `${new Date(
              new Date().setDate(new Date().getDate() - 100)
            ).toUTCString()}`,
            ToDate: `${new Date().toUTCString()}`,
          },
        }));
    // console.log({ response });

    return response;
  }

  public async getPrivacyPolicy({
    language,
  }: Order.GetPrivacyPolicy.Props): Promise<Order.GetPrivacyPolicy.Return> {
    const url = this.urlFactory.create({
      named: ["Order", "GetPP", `${language}`],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.GetAllData.Return,
      undefined
    >({
      url,
      body: undefined,
    });
    // console.log({ response });

    return response;
  }

  public async getGeneralTermsAndConditions({
    language,
  }: Order.GetGeneralTermsAndConditions.Props): Promise<Order.GetGeneralTermsAndConditions.Return> {
    const url = this.urlFactory.create({
      named: ["Order", "GetGTC", `${language}`],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.GetAllData.Return,
      undefined
    >({
      url,
      body: undefined,
    });
    // console.log({ response });

    return response;
  }

  public async create({
    body,
    customerDealerId,
  }: Order.Create.Props): Promise<Order.Create.Return> {
    // TODO: Fix this hardcoded dynamic url
    const url = this.urlFactory.create({
      named: ["Order", "Create", `${customerDealerId}`],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.Create.Return,
      Order.Create.Body
    >({
      url,
      body,
    });
    // console.log({ response });

    return response;
  }
  public async AddOrderUsingReference({
    body,
  }: Order.AddOrderUsingReference.Props): Promise<Order.AddOrderUsingReference.Return> {
    // TODO: Fix this hardcoded dynamic url
    const url = this.urlFactory.create({
      named: ["Order", "Detail"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.AddOrderUsingReference.Return,
      Order.AddOrderUsingReference.Body
    >({
      url,
      body,
    });
    // console.log({ response });

    return response;
  }

  public async AssignOrder({
    body,
  }: Order.AssignOrder.Props): Promise<Order.AssignOrder.Return> {
    // TODO: Fix this hardcoded dynamic url
    const url = this.urlFactory.create({
      named: ["Order", "Assign"],
    });
    // console.log({ url });

    const response = await this.httpManager.post<
      Order.AssignOrder.Return,
      Order.AssignOrder.Body
    >({
      url,
      body,
    });
    // console.log({ response });

    return response;
  }
}
