import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import { v4 as uuidv4 } from "uuid";

export const Success = Mobx.observer(function () {
  const [copied, setCopied] = React.useState<boolean>(false);

  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const functionStore = React.useContext(App.Contexts.functionStore);

  React.useEffect(() => {
    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      activateStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!activateStore.activated.licenseKey ? (
        <Components.Redirect to="/licenses/activate" />
      ) : (
        <>
          <Mui.Box marginBottom={2}>
            <MuiLab.Alert severity="success">
              <MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="License successfully generated and activated.">
                License successfully generated and activated.
                </ReactI18next.Trans>
              </MuiLab.AlertTitle>
              <ul>
                <li>
                  <ReactI18next.Trans i18nKey="If your device is working on online mode">
                    If your device is working on online mode, your installations
                    will be automatically activated on your device.
                  </ReactI18next.Trans>
                </li>

                <li>
                  <ReactI18next.Trans i18nKey="If your device is working on offline mode">
                    If your device is working on offline mode, you will have to
                    manually add the license key from the following text box to
                    activate your installations on your device.
                  </ReactI18next.Trans>
                </li>
              </ul>
            </MuiLab.Alert>
          </Mui.Box>
          <Mui.Grid container spacing={3}>
            <Mui.Grid item xs={12} md={6} lg={4}>
              <Mui.Card
                style={{
                  height: "100%",
                  position: "relative",
                }}
              >
                <Mui.CardContent>
                  <Mui.TextField
                    label={t("License Key")}
                    value={activateStore.activated.licenseKey}
                    fullWidth
                    multiline
                    rows={2}
                    rowsMax={2}
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.Tooltip
                            title={copied ? "Copied!" : ""}
                            placement="bottom"
                          >
                            <ReactCopyToClipboard.CopyToClipboard
                              text={activateStore.activated.licenseKey}
                              onCopy={() => {
                                setCopied(true);
                              }}
                            >
                              <Mui.IconButton
                                aria-label="copy textbox content"
                                style={{
                                  padding: theme.spacing(1.125),
                                }}
                              >
                                <MuiIcons.ContentCopy />
                              </Mui.IconButton>
                            </ReactCopyToClipboard.CopyToClipboard>
                          </Mui.Tooltip>
                        </Mui.InputAdornment>
                      ),
                    }}
                  />
                  <Mui.Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: theme.spacing(2) }}
                  >
                    <ReactI18next.Trans i18nKey="Installed articles">
                      Installed articles
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Grid container>
                    {activateStore.activated.sets.map((article) => (
                      <Mui.Tooltip title={article.setName} key={uuidv4()}>
                        <Mui.Avatar
                          style={{
                            marginRight: theme.spacing(2.5),
                            marginTop: theme.spacing(1),
                          }}
                        >
                          <Mui.Box color="text.primary">
                            <i className={article.iconClass} />
                          </Mui.Box>
                        </Mui.Avatar>
                      </Mui.Tooltip>
                    ))}
                  </Mui.Grid>

                  <Mui.Box height={theme.spacing(5)} width={1} />

                  <Mui.Box
                    position="absolute"
                    bottom={theme.spacing(2.5)}
                    right={theme.spacing(2.5)}
                  >
                    <Mui.Button
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="/licenses/activate"
                    >
                      <ReactI18next.Trans i18nKey="Generate another license and activate">
                        Generate another license and activate
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={12}
              md={6}
              lg={8}
              style={{ height: "calc(100vh - 284px)", overflow: "auto" }}
            >
              <Article.Views.Cards
                disablePagination
                cardsPerRow={2}
                spacing={2}
                variant="DETAILED"
                functions={functionStore.functions}
                articles={activateStore.activated.sets}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Box display="grid" justifyContent="flex-end" alignItems="center">
            <Mui.Box marginTop={2}>
              <Mui.Button
                color="primary"
                component={Router.Link}
                to="/licenses"
              >
                <ReactI18next.Trans i18nKey="Back to licenses">
                Back to licenses
                      </ReactI18next.Trans>
                
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </>
      )}
    </>
  );
});
