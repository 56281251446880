import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export function Main() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Mui.AppBar position="static">
        <Mui.Toolbar>
          <Mui.IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MuiIcons.Menu />
          </Mui.IconButton>
          <Mui.Typography variant="h6" className={classes.title}>
            <ReactI18next.Trans i18nKey="News">News</ReactI18next.Trans>
          </Mui.Typography>
          <Mui.Button color="inherit">
            <ReactI18next.Trans i18nKey="Login">Login</ReactI18next.Trans>
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Router.Outlet />
    </div>
  );
}
