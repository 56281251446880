import * as React from "react";
import * as Mui from "@material-ui/core";

const useStyles = Mui.makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    backgroundColor: (props: TableCell.Styled.Props) =>
      props?.color ?? (props?.specialRowColor ? theme.palette.background.default : theme.palette.background.paper),
    // borderRight: (props: TableCell.Styled.Props) =>
    //   props?.rightmost ? "none" : `1px solid ${theme.palette.divider}`,
    borderBottom: "none",
    borderColor: "unset",
    borderRadius: (props: TableCell.Styled.Props) => {
      if (props?.leftmost) {
        return theme.spacing(1.5, 0, 0, 1.5);
      }
      if (props?.rightmost) {
        return theme.spacing(0, 1.5, 1.5, 0);
      }
      return "unset";
    },
  },
}));

export declare namespace TableCell {
  export interface Props extends Omit<Mui.TableCellProps, "color"> {
    leftmost?: boolean;
    rightmost?: boolean;
    color?: string;
    specialRowColor?: boolean;
    height?: any;
  }

  export namespace Styled {
    export interface Props
      extends Pick<TableCell.Props, "leftmost" | "rightmost" | "color" | "specialRowColor" | "height"> {}
  }
}

// eslint-disable-next-line no-redeclare
export function TableCell({
  leftmost,
  rightmost,
  specialRowColor,
  color,
  children,
  height,
  ...props
}: TableCell.Props) {
  const classes = useStyles({ leftmost, rightmost, color,specialRowColor,height });
  const theme = Mui.useTheme();
  return (
    <Mui.TableCell className={classes.root} {...props}>
      {!rightmost ? (
        <Mui.Box
          padding={height ?? 1}
          borderRight={`1px solid ${theme.palette.divider}`}
        >
          {children}
        </Mui.Box>
      ) : (
        <Mui.Box  padding={height ?? 1.5}>{children}</Mui.Box>
      )}
    </Mui.TableCell>
  );
}
