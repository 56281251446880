import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";
import * as Stores from "src/stores";

export declare namespace Store {
  export interface AdminAccount {
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
  }

  export interface IStore {
    isLoading: boolean;
    search: string;
    companyAliasName: string;
    etuid?: number;
    adminAccount: Store.AdminAccount;
    tenants: Models.Tenant.Main[];
    returnUrl: string;
  }

  export type Options = {
    tenantService: Services.Tenant;
  };

  export namespace SetCompanyName {
    export interface Props extends Pick<Store.IStore, "companyAliasName"> {}
  }

  export namespace SetEtuid {
    export interface Props extends Pick<Store.IStore, "etuid"> {}
  }

  export namespace SetAdminAccount {
    export interface Props extends Pick<Store.IStore, "adminAccount"> {}
  }

  export namespace Read {
    export interface Props extends Services.Tenant.GetAllData.Props {}
  }

  export namespace SetSearch {
    export interface Props extends Pick<Store.IStore, "search"> {}
  }

  export namespace SetReturnUrl {
    export interface Props extends Pick<Store.IStore, "returnUrl"> {}
  }

  export namespace Tasks {
    export type Task = Stores.Tenant.Tasks.Task;
    export type Return = Stores.Tenant.Tasks.Return;
  }

  export namespace UpdateCompanyReady {
    export interface Props extends Services.Tenant.UpdateCompanyReady.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public search: string = "";
  @Mobx.observable public companyAliasName: string = "";
  @Mobx.observable public etuid?: number;
  @Mobx.observable public adminAccount: Store.AdminAccount = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
  };
  @Mobx.observable public tenants: Models.Tenant.Main[] = [];
  public returnUrl: string = "";

  private _tenantService: Services.Tenant;

  constructor({ tenantService }: Store.Options) {
    this._tenantService = tenantService;
  }

  

  @Mobx.action public setReturnUrl({ returnUrl }: Store.SetReturnUrl.Props) {
    this.returnUrl = returnUrl;
  }

  @Mobx.action public async read(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this._tenantService.getAllData(props);
      this.tenants = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.tenants.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get filtered() {
    return this.tenants.filter((tenant) => {
      if (!this.search) {
        return true;
      }
      return (
        tenant?.companyAliasName
          ?.toLowerCase()
          .includes(this.search.toLowerCase()) ||
        tenant?.companyName
          ?.toLowerCase()
          .includes(this.search.toLowerCase()) ||
        tenant?.firstName?.toLowerCase().includes(this.search.toLowerCase()) ||
        tenant?.lastName?.toLowerCase().includes(this.search.toLowerCase()) ||
        tenant?.userName?.toLowerCase().includes(this.search.toLowerCase())
      );
    });
  }

  @Mobx.computed public get activeTenants() {
    return this.tenants.filter((tenant) => tenant.isActive);
  }

  @Mobx.action public async updateCompanyReady(
    props: Store.UpdateCompanyReady.Props
  ) {
    try {
      await this._tenantService.updateCompanyReady(props);
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.action public setSearch({ search }: Store.SetSearch.Props) {
    this.search = search;
  }

  @Mobx.action public setCompanyName({
    companyAliasName,
  }: Store.SetCompanyName.Props) {
    this.companyAliasName = companyAliasName;
  }

  @Mobx.action public setEtuid({ etuid }: Store.SetEtuid.Props) {
    this.etuid = etuid;
  }

  @Mobx.action public setAdminAccount({
    adminAccount,
  }: Store.SetAdminAccount.Props) {
    this.adminAccount = adminAccount;
  }

  @Mobx.action public reset() {
    this.companyAliasName = "";
    this.adminAccount = {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
    };
  }

  @Mobx.computed public get tasks(): Store.Tasks.Return {
    return this.tenants
      .filter((tenant) => !tenant.isActive)
      .map(
        (tenant) =>
          ({
            body: `Please do the needful for the creation of <strong>${tenant.companyAliasName}</strong> tenant!`,
            link: {
              isLink: true,
              to: "/tenants",
            },
            roles: ["superadmin","admin"],
            severity: "info",
            title: "Tenant creation request",
          } as Store.Tasks.Task)
      );
  }
}
