import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "src/app";

export declare namespace Route {
  export interface Props {
    restricted?: boolean;
    roles?: string[];
    caseSensitive?: boolean;
    children?: React.ReactNode;
    element?: React.ReactElement;
    path?: string;
  }
}

// eslint-disable-next-line no-redeclare
export const Route = Mobx.observer(function ({
  roles = ["superadmin", "admin", "customer","EU", "dealer"],
  restricted = false,
  ...routeProps
}: Route.Props) {
  const userStore = React.useContext(App.Contexts.userStore);

  const notRestrictedRouteAndUserNotAuthenticated =
    !restricted && !userStore.user ? true : false;

  const restrictedRouteAndUserAuthenticatedAndUserRoleAllowed =
    restricted && userStore.user && roles.includes(userStore.user.RoleName)
      ? true
      : false;

  const notRestrictedRouteButUserAuthenticated =
    !restricted && userStore.user ? true : false;

  const restrictedRouteAndUserNotAuthenticated =
    restricted && !userStore.user ? true : false;

  const restrictedRouteAndUserAuthenticatedButUserRoleNotAllowed =
    restricted && userStore.user && !roles.includes(userStore.user.RoleName);

  return (
    <>
      {/* NOTE: 2 happy paths */}
      {notRestrictedRouteAndUserNotAuthenticated && (
        <Router.Route {...routeProps} />
      )}
      {restrictedRouteAndUserAuthenticatedAndUserRoleAllowed && (
        <Router.Route {...routeProps} />
      )}

      {/* NOTE: 3 edge cases */}
      {restrictedRouteAndUserNotAuthenticated && (
        <Router.Navigate to="/account/login" />
      )}
      {restrictedRouteAndUserAuthenticatedButUserRoleNotAllowed && (
        <Router.Navigate to="/" />
      )}
      {notRestrictedRouteButUserAuthenticated && <Router.Navigate to="/" />}
    </>
  );
});
