import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

const useStyles = Mui.makeStyles((theme) => ({
  list: {
    width: 380,
  },
  title: {
    flexGrow: 1,
  },
}));

export declare namespace DetailsDevice {
  export interface Props {
    open: boolean;
    handleClose(): void;
    device?: Models.Devices & { sets: Set<Models.Set.Article> };
    handleDeactivateSelected(): void;
    handleDeactivateAll(): void;
  }
}

// eslint-disable-next-line no-redeclare
export const DetailsDevice = Mobx.observer(function ({
  open,
  handleClose,
  handleDeactivateAll,
  handleDeactivateSelected,
  device,
}: DetailsDevice.Props) {
  const classes = useStyles();
  const theme = Mui.useTheme();

  const functionStore = React.useContext(App.Contexts.functionStore);
  const licenseStore = React.useContext(App.Contexts.license.store);

  return (
    <Components.LightBox
      handleClose={handleClose}
      open={open}
      title={
        <>
          <Mui.Typography
            style={{ fontWeight: "bold" }}
            variant="body1"
            className={classes.title}
          >
            <ReactI18next.Trans i18nKey="Device details">
              Device details
            </ReactI18next.Trans>
          </Mui.Typography>
        </>
      }
      content={
        <>
          {device && (
            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="MAC ID (PC, tablet)">
                    MAC ID (PC, tablet)
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ textTransform: "uppercase" }}
                >
                  {device?.macID}
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="Device name">
                    Device name
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary">
                  {device?.deviceName}
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="License serial number">
                  License serial number
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary">
                  {device?.serialNumber}
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="Customer device id">
                    Customer device id
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary">
                  {device?.customerDeviceID}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          )}
          {device && Array.from(device.sets.values()).length > 0 && (
            <>
              <Mui.Grid container spacing={4}>
                <Mui.Grid item xs>
                  <Mui.CardHeader
                    style={{ padding: 0 }}
                    title={
                      <Mui.Typography
                        style={{ fontWeight: "bold" }}
                        variant="body1"
                      >
                        <ReactI18next.Trans i18nKey="List of installed articles">
                          List of installed articles
                        </ReactI18next.Trans>
                      </Mui.Typography>
                    }
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container spacing={3}>
                {Array.from(device.sets.values())
                  .filter((article) => article)
                  .map((article) => (
                    <Mui.Grid item xs={12} md={6} lg={4} key={uuidv4()}>
                      <Article.Card
                        style={{
                          backgroundColor: theme.palette.background.default,
                        }}
                        selected={licenseStore.selectedArticlesForDeactivation.has(
                          article.setID
                        )}
                        functions={functionStore.functions.filter((func) =>
                          article.functions.includes(func.setFunctionID)
                        )}
                        handleClick={() =>
                          licenseStore.toggleArticleSelectionForDeactivation({
                            setID: article.setID,
                          })
                        }
                        article={article}
                        variant={"DETAILED"}
                      />
                    </Mui.Grid>
                  ))}
              </Mui.Grid>
            </>
          )}
        </>
      }
      actions={
        <>
          <Mui.Button
            // {...(licenseStore.selectedArticlesForDeactivation.size > 0 && {
            //   style: {
            //     backgroundColor: theme.palette.primary.main,
            //     color: theme.palette.common.white,
            //   },
            // })}
            disabled={licenseStore.selectedArticlesForDeactivation.size === 0}
            variant="contained"
            color="default"
            onClick={handleDeactivateSelected}
          >
            <ReactI18next.Trans i18nKey="Deactivate installation">
              Deactivate installation
            </ReactI18next.Trans>
          </Mui.Button>
          <Mui.Button
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              if (device?.sets.size === 0) {
                return;
              }

              handleDeactivateAll();
            }}
          >
            <ReactI18next.Trans i18nKey="Deactivate terminal device">
              Deactivate Terminal Device
            </ReactI18next.Trans>
          </Mui.Button>
        </>
      }
    />
  );
});
