import * as Mui from "@material-ui/core";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Models from "src/models";
import { deepPurple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core";


export declare namespace CardLicense {
  export interface Props {
    license: Models.MyLicense & { hundCode: string };
    handleClick?(event: any): void;
    isDealer? : boolean ;
  }
}

// eslint-disable-next-line no-redeclare
export function CardLicense({ license, handleClick }: CardLicense.Props) {
  const theme = Mui.useTheme();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: "white",
      backgroundColor: "grey",
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    green: {
      backgroundColor: "green",
    },
  }));
    const classes = useStyles();
  const CardInternals = (
    <>
      <Mui.CardHeader
        title={
          <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
            <Mui.ListItemIcon>
              <Mui.Box fontSize={theme.typography.h4.fontSize} lineHeight={0}>
                <i className={license.hundCode === "SET-01" ||  license.setCode === "SET-01"? "icon-basic-license" : license.setFuncstionsList.map(k=>k.iconClass).toString()}/>
              </Mui.Box>
            </Mui.ListItemIcon>

            <Mui.ListItemText
              primary={license.setName}
              secondary={
                <>
                  <Containers.ShowIfAuthorised roles={["customer","EU","dealer"]}>
                    {license.hundCode}
                  </Containers.ShowIfAuthorised>

                  <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                    {license.setCode}
                  </Containers.ShowIfAuthorised>
                </>
              }
            />
             <Mui.Chip
                      style={{
                        marginLeft: "18px",
                        marginRight: "18px",
                        marginBottom: "3px",
                        paddingRight: "3px",
                        fontWeight: "bold",
                      }}
                      size="medium"
                      label="Licenses"
                      avatar={
                        <Mui.Avatar
                          style={{ color: "white" }}
                          className={classes.green}
                        >
                          {Math.ceil((license.total/ 4)) }
                        </Mui.Avatar>
                      }
                    ></Mui.Chip>
          </Mui.ListItem>
        }
        action={
          <Components.WorkingStatus
            variant="SHORT"
            status={license.status as 1 | 2}
            labels={{ 1: "Available", 2: "Used" }}
          />
        }
      />
      <Mui.CardContent style={{ marginTop: theme.spacing(-3.5) }}>
        <Mui.Box height={48}>
          <Components.FuctionIcons functions={license.setFuncstionsList} />
        </Mui.Box>

        <Mui.Typography variant="body2" color="textSecondary">
          <ReactI18next.Trans i18nKey="Available functions">
            Available functions
          </ReactI18next.Trans>
        </Mui.Typography>
        <Mui.Typography>
          {license.setFuncstionsList
            .map((func) => func.funcationName)
            .join(", ")}
        </Mui.Typography>
        <br />

        <Mui.Grid container spacing={2}>
          <Mui.Grid item xs={4} style={{ maxWidth: "min-content" }}>
            <Mui.Typography style={{ fontWeight: "bold" }}>
              {license.total}
            </Mui.Typography>
           
            <Mui.Typography variant="body2" color="textSecondary">
              <ReactI18next.Trans i18nKey="Total installations">
                Total installations
              </ReactI18next.Trans>
            </Mui.Typography>
          
          </Mui.Grid>

          <Mui.Grid item xs={4} style={{ maxWidth: "min-content" }}>
            <Mui.Typography style={{ fontWeight: "bold" }}>
              {license.totalUsed}
            </Mui.Typography>
            <Mui.Typography variant="body2" color="textSecondary">
            <ReactI18next.Trans i18nKey="Used installations">
                Used installations
              </ReactI18next.Trans>
            </Mui.Typography>
            
          </Mui.Grid>

          <Mui.Grid item xs={4} style={{ maxWidth: "min-content" }}>
            <Mui.Typography style={{ fontWeight: "bold" }}>
              {license.totalAvailable}
            </Mui.Typography>
            <Mui.Typography variant="body2" color="textSecondary">
            <ReactI18next.Trans i18nKey="Available installations">
                Available installations
              </ReactI18next.Trans>
            </Mui.Typography>
            
          </Mui.Grid>
        </Mui.Grid>
        <br />

      </Mui.CardContent>
    </>
  );

  return (
    <>
      <Mui.Card style={{ height: "100%" ,backgroundColor: theme.palette.background.default}}>
        {handleClick ? (
          <Mui.CardActionArea
            onClick={handleClick}
            style={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "unset",
              alignItems: "unset",
              verticalAlign: "unset",
            }}
          >
            {CardInternals}
          </Mui.CardActionArea>
        ) : (
          CardInternals
        )}
      </Mui.Card>
    </>
  );
}
