import * as React from "react";
import * as Mui from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';

const PurpleSwitch = withStyles({
    switchBase: {
      color: blue[300],
      '&$checked': {
        color: blue[500],
      },
      '&$checked + $track': {
        backgroundColor: blue[500],
      },
    },
    checked: {},
    track: {},
    
  })(Switch);



  export declare namespace Switches {
    export interface Props {
       labelName?: string;
    }
  }

export  function Switches({
    labelName}
  : Switches.Props) {
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedC: true,
    });
  
    const handleChange = (event:any) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };
  
    return (
      <Mui.FormGroup >
        <Mui.FormControlLabel

          control={<PurpleSwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />}
          label= {labelName}
        />
        {/* <Mui.FormControlLabel
          control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />}
          label="iOS style"
        />
        <Mui.Typography component="div">
          <Mui.Grid component="label" container alignItems="center" spacing={1}>
            <Mui.Grid item>Off</Mui.Grid>
            <Mui.Grid item>
              <AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />
            </Mui.Grid>
            <Mui.Grid item>On</Mui.Grid>
          </Mui.Grid>
        </Mui.Typography> */}
      </Mui.FormGroup>
    );
  }
  export default Switches;