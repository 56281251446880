/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";
import * as Components from "src/app/components";
import * as Models from "src/models";
import * as ReactI18next from "react-i18next";

export declare namespace Card {
  export interface Props {
    customer: Models.AdminManage.Main;
    selected: number | string;
    handleClick?(props: { CustomerID: number | string }): void;
  }
}

// eslint-disable-next-line no-redeclare
export function Card({
  customer,
  selected,
  handleClick,
}:
Card.Props) {
  const theme = Mui.useTheme();


  const CardInternals = (
    <>
      <Mui.Card
        style={{ height: "230px" }}
        {...(selected === customer.customerID && {
          elevation: selected ? 11 : undefined,
          style: {
            height: "100%",
            border: selected
              ? `2px solid ${theme.palette.success.main}`
              : "inherit",
          },
        })}>
        <Mui.CardHeader
          style={{
            minHeight: "80px",
            paddingTop: "7px",
            padding: "5px",
            margin: "3px",
            paddingBottom: "1px",
          }}
          title={
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
              <Mui.ListItemIcon>
                <Mui.ListItemAvatar>
                  <Mui.Avatar
                    alt={
                      "userStore.user?.FirstName" +
                      " " +
                      "userStore.user?.LastName"
                    }>
                    <MuiIcons.Person />
                  </Mui.Avatar>
                </Mui.ListItemAvatar>
              </Mui.ListItemIcon>
              <Mui.ListItemText
                primary={
                  <Mui.Typography
                    style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {customer.companyName}
                  </Mui.Typography>
                }
                secondary={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <Mui.Typography color="textSecondary">
                        Role:
                      </Mui.Typography>

                      <Mui.Typography
                        style={{ color: "black", fontSize: "14px" }}>
                        {customer.loginUserRole}
                      </Mui.Typography>
                    </div>
                  </>
                }
              />

              <Components.WorkingStatus
                status={1 as 1 | 2}
                labels={{
                  1: "Available  " + customer.licenseCount,
                  2: "Un Available",
                }}
              />
            </Mui.ListItem>
          }
          action={
            <>
              {selected !== undefined && (
                <Mui.Checkbox
                  size="small"
                  checked={selected === customer.customerID}
                  value={customer.customerName}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                />
              )}
            </>
          }
        />
        <Mui.CardContent
          style={{
            minHeight: "135px",
            padding: 6,
            paddingTop: 2,
            margin: 6,
            marginTop: 2,
          }}>
          <Mui.Divider />
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "4px",
                marginBottom: "4px",
              }}>
              <Mui.Typography color="textSecondary">
                Company name:
              </Mui.Typography>
              <Mui.Typography style={{ color: "black", fontSize: "14px" }}>
                {customer.companyName}
              </Mui.Typography>
            </div>
          </>

          {/*  */}
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "4px",
              }}>
              <Mui.Typography color="textSecondary">
                {" "}
                {"EmailID:"}
              </Mui.Typography>
              <Mui.Typography style={{ color: "black", fontSize: "14px" }}
               onClick={()=>{window.open(`mailto:${customer.emailID}`)}}
              >
                                      <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{customer.emailID}</Mui.Link></Mui.Tooltip>
              </Mui.Typography>
            </div>
          </>

          <Mui.Divider />
          <Mui.Grid
            container
            spacing={2}
            direction={"row"}
            style={{ marginTop: "2px" }}>
            <Mui.Grid
              item
              xs={8}
              style={{ fontWeight: "bold", paddingTop: "2px" }}>
              <Mui.Grid>
                <Mui.Typography
                  style={{ fontWeight: "bold", marginBottom: "12px" }}>
                    <ReactI18next.Trans i18nKey="Available license">
                    Available license
                    </ReactI18next.Trans>
                  
                </Mui.Typography>
                <Mui.Typography style={{ marginTop: "4px" }}>
                  <Components.FunctionCountIcons
                    functions={customer.functionsDisplay}
                  />

                  {customer?.functionCount > 4 && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "right",
                        float: "right",
                        color: "#5780d9",
                        fontSize: "0.200rem; ",
                      }}>
                      + {customer.functionCount}
                      {}more
                    </span>
                  )}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Divider orientation="vertical" flexItem />
            <Mui.Grid item xs={2}>
              <Mui.Typography style={{ fontWeight: "bold" }}>
                {"Status"}
              </Mui.Typography>
              <Mui.Typography>
                <Components.InviteStatus
                  status={customer.etUserStatus as 1 | 2}
                />
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );

  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea
          onClick={() => handleClick({ CustomerID: customer.customerID })}>
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        { CardInternals }
      )}
    </Mui.Card>
  );
}
