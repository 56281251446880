import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Shop from "src/app/pages/shop";
import * as Models from "src/models";

export declare namespace Address {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose" | "title"
  > & {
    address?: Models.Address;
  };
}

// eslint-disable-next-line no-redeclare
export const Address = function ({
  open,
  title,
  handleClose,
  address,
}: Address.Props) {
  const [initialValues, setInitialValues] = React.useState<
    Shop.AddressForm.Values
  >();
  const userStore = React.useContext(App.Contexts.userStore);
  const cartStore = React.useContext(App.Contexts.cartStore);

  React.useEffect(() => {
    if (!address) {
      setInitialValues(undefined);
      return;
    }

    setInitialValues(address);
  }, [address, open]);

  async function handleAddressSaveOrUpdate(
    values: Shop.AddressForm.Values,
    { setSubmitting }: Formik.FormikHelpers<Shop.AddressForm.Values>
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      const updatedAddress: Models.Address = {
        ...address,
        ...values,
      } as Models.Address;

      cartStore.setBillingAddress({ billingAddress: updatedAddress });
      // 
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Formik.Formik
        enableReinitialize
        initialValues={initialValues || ({} as Shop.AddressForm.Values)}
        validationSchema={Shop.Shared().Address.Form.validationSchema}
        onSubmit={handleAddressSaveOrUpdate}
      >
        {({ submitForm }) => (
          <Components.LightBox
            open={open}
            handleClose={handleClose}
            content={<Form open={open} />}
            title={title}
            actions={
              <>
                <Mui.Button variant="contained" onClick={handleClose}>
                  <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
                </Mui.Button>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  <ReactI18next.Trans i18nKey="Save / Update">
                    Save / Update
                  </ReactI18next.Trans>
                </Mui.Button>
              </>
            }
          />
        )}
      </Formik.Formik>
    </>
  );
};

function Form({ open }: Pick<Address.Props, "open">) {
  const { resetForm } = Formik.useFormikContext();

  React.useEffect(() => {
    if (open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return <Shop.AddressForm />;
}
