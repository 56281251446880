import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as React from "react";

export declare namespace WorkingStatus {
  export type Status = 1 | 2;
  export type Labels = {
    [Key in WorkingStatus.Status]: string;
  };

  export type VARIANT = "DEFAULT" | "SHORT";

  export interface Props {
    labels?: WorkingStatus.Labels;
    status: WorkingStatus.Status;
    variant?: WorkingStatus.VARIANT;
  }

  export interface GetLabelProps
    extends Pick<WorkingStatus.Props, "labels" | "status"> {}
}

export function getLabel({
  status,
  labels,
}: WorkingStatus.GetLabelProps): string {
  if (!labels && status === 1) {
    return "Active";
  }

  if (!labels && status === 2) {
    return "Deleted";
  }

  if (!labels) {
    return "";
  }

  return labels[status];
}

// eslint-disable-next-line no-redeclare
export function WorkingStatus({
  status,
  labels,
  variant,
}: WorkingStatus.Props) {
  const theme = Mui.useTheme();

  return variant === "SHORT" ? (
    <Mui.Tooltip placement="left" title={getLabel({ status, labels })}>
      <MuiIcons.Brightness1
        style={{
          fontSize: theme.typography.body2.fontSize,
          color:
            status === 1
              ? theme.palette.success.main
              : theme.palette.error.main,
        }}
      />
    </Mui.Tooltip>
  ) : (
    <Mui.Chip
      size="small"
      icon={
        <MuiIcons.Brightness1
          style={{
            color:
              status === 1
                ? theme.palette.success.main
                : theme.palette.error.main,
          }}
        />
      }
      label={getLabel({ status, labels })}
      variant="outlined"
    />
  );
}
