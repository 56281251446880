/** @format */

import * as Mobx from "mobx";
import * as Article from "src/app/pages/article";
import * as Models from "src/models";
import * as Services from "src/services";
import * as Stores from "src/stores";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    customer?: number | string;
    search: string;
    status?: number;
    devices?: Array<Models.Devices & { sets: Set<Models.Set.Article> }>;
  }

  export interface Options {
    easyTouchDeviceService: Services.EasyTouchDevice;
    userStore: Stores.User;
    articleStore: Article.Store;
  }

  export namespace SetLicenses {
    export interface Props extends Pick<Store.IStore, "devices"> {}
  }

  export namespace Read {
    export interface Props extends Services.EasyTouchDevice.GetAllData.Props {}
  }

  export namespace SetSearch {
    export interface Props extends Pick<Store.IStore, "search"> {}
  }

  export namespace SetStatus {
    export interface Props extends Pick<Store.IStore, "status"> {}
  }

  export namespace SetCustomer {
    export interface Props extends Pick<Store.IStore, "customer"> {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public devices?: Array<
    Models.Devices & { sets: Set<Models.Set.Article> }
  >;
  @Mobx.observable public search: string = "";
  @Mobx.observable public status?: number = 1;
  @Mobx.observable public customer?: string | number;
  @Mobx.observable public serialNumber?: Models.License[];

  private easyTouchDeviceService: Services.EasyTouchDevice;
  private userStore: Stores.User;
  private articleStore: Article.Store;

  constructor({
    articleStore,
    easyTouchDeviceService,
    userStore,
  }: Store.Options) {
    this.articleStore = articleStore;
    this.easyTouchDeviceService = easyTouchDeviceService;
    this.userStore = userStore;
  }

  @Mobx.action public setLicenses({ devices }: Store.SetLicenses.Props) {
    this.devices = devices;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;

      const initDevices = ({ result }: { result: Models.License[] }) => {
        var licenseList = result.filter((k) => k.macID !== null);
        //
        this.serialNumber = licenseList;
        this.devices = result
          .filter((device: any) => device.device && device.device.status === 1)
          .reduce((acc: any[], device: any) => {
            const isAlreadyInAcc = acc.findIndex(
              (accDevice) => accDevice.macID === device.device.macID
            );
            const article = this.articleStore.articles.find(
              (article) => article.setID === device.setID
            );
            if (isAlreadyInAcc !== -1) {
              if (article) {
                acc[isAlreadyInAcc].sets.add({
                  ...article,
                  licenseDetailID: device.licenseDetailID,
                });
              }
              return acc;
            }
            device.device["sets"] = new Set();
            if (article) {
              device.device["sets"].add({
                ...article,
                licenseDetailID: device.licenseDetailID,
              });
            }
            acc.push(device.device);
            return acc;
          }, []);
      };
      const { result } = await this.easyTouchDeviceService.getAllData(props);
      initDevices({ result });
      await this.articleStore.read();
      initDevices({ result });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.devices) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.action public setSearch({ search }: Store.SetSearch.Props) {
    this.search = search;
  }

  @Mobx.action public setStatus({ status }: Store.SetStatus.Props) {
    this.status = status;
  }

  @Mobx.action public setCustomer({ customer }: Store.SetCustomer.Props) {
    this.customer = customer;
  }

  @Mobx.computed public get filtered() {
    if (!this.devices) {
      return [];
    }

    return this.devices.filter(
      (device: Models.Devices & { sets: Set<Models.Set.Article> }) => {
        if (!this.search) {
          return true;
        }
        return device.macID.toLowerCase().includes(this.search.toLowerCase());
      }
    );
  }

  @Mobx.computed public get getSerialNumberList() {
    if (!this.serialNumber) {
      return [];
    }

    return this.serialNumber;
  }

  @Mobx.computed public get showAdminCustomerSelectionInfo() {
    if (this.userStore.user?.RoleName === "customer") {
      return false;
    }

    if (this.devices) {
      return false;
    }

    if (this.customer) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get showAdminNoRecordsInfo() {
    if (
      this.userStore.user?.RoleName !== "admin" &&
      this.userStore.user?.RoleName !== "superadmin"
    ) {
      return false;
    }

    if (!this.devices) {
      return false;
    }

    if (this.devices.length) {
      return false;
    }

    return true;
  }

  @Mobx.computed public get showCustomerNoRecordsInfo() {
    if (this.userStore.user?.RoleName !== "customer") {
      return false;
    }

    if (!this.devices) {
      return false;
    }

    if (this.devices.length) {
      return false;
    }

    return true;
  }
}
