import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function UserManagement() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["superadmin","admin"]}
          path="/"
          element={<Pages.UserManagement.Main />}
        />

        <Router.Route path="adddealer/*" element={<Routes.User.AddDealer />} />
      </Router.Routes>
    </>
  );
}
