import * as Mui from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import * as Faker from "faker";
import * as React from "react";
import * as ReactI18next from "react-i18next";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      display: "flex",
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }),
);

export function Main() {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <ReactI18next.Trans i18nKey="This is a profile main page">
        This is a profile main page
      </ReactI18next.Trans>
      <Mui.Box margin={3} width={400}>
        <Mui.Card className={classes.root}>
          <Mui.CardMedia
            className={classes.cover}
            image={Faker.internet.avatar()}
            title={t("Live from space album cover")}
          />
          <div className={classes.details}>
            <Mui.CardContent className={classes.content}>
              <Mui.Typography component="h5" variant="h5">
                <ReactI18next.Trans i18nKey="Live from space">
                  Live from space
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography variant="subtitle1" color="textSecondary">
                <ReactI18next.Trans i18nKey="Mac miller">
                  Mac miller
                </ReactI18next.Trans>
              </Mui.Typography>
            </Mui.CardContent>
            <div className={classes.controls}>
              <Mui.IconButton aria-label="previous">
                {theme.direction === "rtl" ? (
                  <SkipNextIcon />
                ) : (
                  <SkipPreviousIcon />
                )}
              </Mui.IconButton>
              <Mui.IconButton aria-label="play/pause">
                <PlayArrowIcon className={classes.playIcon} />
              </Mui.IconButton>
              <Mui.IconButton aria-label="next">
                {theme.direction === "rtl" ? (
                  <SkipPreviousIcon />
                ) : (
                  <SkipNextIcon />
                )}
              </Mui.IconButton>
            </div>
          </div>
        </Mui.Card>

        <br />
        <br />

        <Mui.Card style={{ display: "flex", padding: theme.spacing(4) }}>
          <div
            style={{
              padding: theme.spacing(1),
              paddingRight: theme.spacing(5),
              width: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Mui.Typography
              style={{
                color: Mui.lighten(theme.palette.text.primary, 0.65),
              }}
              variant="h3"
            >
              <i className="icon-basic-license" />
            </Mui.Typography>
            <Mui.Typography
              style={{
                color: Mui.lighten(theme.palette.text.primary, 0.65),
              }}
              variant="body1"
            >
              <ReactI18next.Trans i18nKey="Licenses">
                Licenses
              </ReactI18next.Trans>
            </Mui.Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: theme.spacing(1),
              paddingLeft: theme.spacing(4),
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Mui.CardContent>
              <Mui.Typography
                variant="h3"
                align="center"
                style={{
                  fontWeight: 700,
                  color: Mui.lighten(theme.palette.text.primary, 0.5),
                }}
              >
                17
              </Mui.Typography>
              <Mui.Typography
                variant="h6"
                align="center"
                style={{
                  fontWeight: 600,
                  color: Mui.lighten(theme.palette.text.primary, 0.5),
                }}
              >
                <ReactI18next.Trans i18nKey="In use">In use</ReactI18next.Trans>
              </Mui.Typography>
            </Mui.CardContent>
          </div>
        </Mui.Card>
      </Mui.Box>
    </>
  );
}
