import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Activate() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["EU"]}
          path="/"
          element={<Pages.License.Activate.Main />}
        />
        <Routes.Route
          restricted
          roles={["EU"]}
          path="success"
          element={<Pages.License.Activate.Success />}
        />
      </Router.Routes>
    </>
  );
}
