import * as React from "react";
import * as Router from "react-router-dom";
import * as Layouts from "src/app/layouts";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Global() {
  return (
    <Router.Routes>
      <Router.Route path="account/*" element={<Routes.Account />} />
      <Router.Route path="/" element={<Layouts.DrawerAppbarCombo.Main />}>
        <Routes.Route restricted path="/" element={<Pages.Home.Main />} />
        <Routes.Route
          restricted
          path="dashboard"
          element={<Pages.Home.Dashboard />}
        />
        <Router.Route
          path="device-configuration-upload/*"
          element={<Routes.DeviceConfigurationUpload />}
        />
        <Router.Route path="orders/*" element={<Routes.Order.Main />} />
        <Router.Route path="shop/*" element={<Routes.Shop.Main />} />
        <Router.Route path="payment/*" element={<Routes.Payment />} />
        <Router.Route path="dealercustomer/*" element={<Routes.Dealer.Main />} />
        <Router.Route path="customerinvite/*" element={<Routes.Dealer.CustomerInvite />} />
        <Router.Route
          path="easy-touch-devices/*"
          element={<Routes.EasyTouchDevice />}
        />
        <Router.Route path="icons/*" element={<Routes.Icon />} />
        <Router.Route path="licenses/*" element={<Routes.License.Main />} />
        <Router.Route path="articles/*" element={<Routes.Article />} />
        <Router.Route path="Activate/*" element={<Routes.License.AddLicense />} />
        <Router.Route path="functions/*" element={<Routes.Function />} />
        <Router.Route path="tenants/*" element={<Routes.Tenant />} />
        <Router.Route
          path="industry-segments/*"
          element={<Routes.IndustrySegment />}
        />
        <Router.Route
          path="user-management/*"
          element={<Routes.UserManagement />}
        />
        <Routes.Route
          restricted
          roles={["customer","EU"]}
          path="profile"
          element={<Pages.Profile.Main />}
        />
        <Routes.Route
          restricted
          path="settings"
          element={<Pages.Settings.Main />}
        />
        <Routes.Route
          restricted
          path="ETScaleConfig/*"
          element={<Layouts.ExternalNavi.Main />}
        />
      </Router.Route>
      <Router.Route path="*" element={<Layouts.Appbar.Main />}>
        <Router.Route path="*" element={<>Page Not Found</>} />
      </Router.Route>
    </Router.Routes>
  );
}
