/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as License from "src/app/pages/license";
import * as Generate from "src/app/pages/easy-touch-device/generate";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";
import * as MuiIcons from "@material-ui/icons";
import * as Yup from "yup";
import * as Notistack from "notistack";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CryptoJS from "crypto-js";

export declare namespace Device {
    export interface Props {
      open: boolean;
      handleClose(): void;
      device?: Models.Devices & { sets: Set<Models.Set.Article> };
      handleDeactivateSelected(): void;
      handleDeactivateAll(): void;
      licenseList?: Models.License[];
      counter:number;
    }
  }
export const DeviceDetail = function ({
    open,
    handleClose,
    handleDeactivateAll,
    handleDeactivateSelected,
    device,
    counter
  }: Device.Props) {
  const [data, setData] = React.useState<Models.Set.Article[]>([]);
  const [manualData, setManualData] = React.useState<any>([]);
  const [ackValue, setAckValue] = React.useState<string>("");
  const [dataLicense, setDataLicense] = React.useState<Models.Set.Article[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [deactivationDialogOpen, setDeactivationDialogOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const userStore = React.useContext(App.Contexts.userStore);
  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const navigate = Router.useNavigate();
  const [searchParams] = Router.useSearchParams();
  const userRole = userStore.user?.RoleName ?? "";
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const [serialNumberList, setserialNumberList] = React.useState<any>();
  const easyTouchDeviceStore = React.useContext(App.Contexts.easyTouchDeviceStore);
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const toggleShowMore = () => setShowMore(!showMore);
  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const licenseService = new Services.License({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const dealerCustomerService = new Services.DealerCustomerService({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    tenantStore.setReturnUrl({ returnUrl: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    var licenseList =  easyTouchDeviceStore.getSerialNumberList;
    var serialNumberList = licenseList.filter(k=>k.macID === device?.macID ).map(l=>l.licenseCode)
    setserialNumberList(serialNumberList);
   }, [counter, device, easyTouchDeviceStore.getSerialNumberList, licenseStore.licenses]);
  React.useEffect(() => {
    (function () {
      const tenantId = searchParams.get("tenantId");

      if (!tenantId) {
        return;
      }

    

      tenantStore.read().then(() => {
        const tenant = tenantStore.tenants.find(
          (tenant) => tenant.id === parseInt(tenantId, 10)
        );

        generateLicenseStore.setSelectedTenant({ selectedTenant: tenant });
      });
    })();
    return () => {
      if (generateLicenseStore.created) {
        return;
      }

      generateLicenseStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const userId = userStore.user?.uid;
    const ETUID= userStore.user?.ETUID
    

    if (userId === undefined || userRole === undefined) {
      return;
    }

    generateLicenseStore.reset();
  
    articleService
      .getAllData({ status: 1,isManualActivation: true ,ETUID:ETUID,roleName:userRole})
      .then((result) => {
        setData(result.result);
        generateLicenseStore.setArticles({ articles: result.result });

        const availableArticlesMap = new Map();
        result.result.forEach((article) => {
          availableArticlesMap.set(article.setID, article);
        });

        const sets = searchParams.getAll("set");

        sets.forEach((set) => {
          const article = availableArticlesMap.get(parseInt(set, 10));

          if (!article) {
            return;
          }

          if (
            Configs.Workaround.isCloudArticle({ code: article.hundCode }) ||
            Configs.Workaround.isCloudArticle({ code: article.setCode })
          ) {
            generateLicenseStore.setCloudArticle({ cloudArticle: article });
          }
        });

        if (sets.length) {
          generateLicenseStore.setSelectedArticles({
            selectedArticles: sets.reduce((acc, set) => {
              const article = availableArticlesMap.get(parseInt(set, 10));
              if (article) {
                generateLicenseStore.setIsBasicSetSelected({
                  article,
                  isArticleSelected: true,
                });
              }
              return { ...acc, [parseInt(set, 10)]: true };
            }, {}),
          });
        }
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    tenantStore.read();
  }, [generateLicenseStore.tenantSelectionDialogOpen, tenantStore]);



  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };

  async function handleCreate(
    values: Generate.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Generate.Form.Values>
  ) {
    try {
      const sets: number[] = Object.keys(
        generateLicenseStore.selectedArticles
      ).reduce((acc: number[], key) => {
        if (generateLicenseStore.selectedArticles[key]) {
          return [...acc, parseInt(key, 10)];
        }
        return acc;
      }, []);

      if (sets.length === 0) {
        return;
      }
      if (sets.includes(1)) {
        generateLicenseStore.isBasicSetSelected = true;
      } else {
        generateLicenseStore.isBasicSetSelected = false;
      }

      if (
        generateLicenseStore.noOfSelectedArticles > 0 &&
        !generateLicenseStore.isBasicSetSelected
      ) {
        return;
      }

      if (
        generateLicenseStore.noOfSelectedArticles > 0 &&
        !generateLicenseStore.isDependentArticlesValid
      ) {
        return;
      }

      const userId = userStore.user?.uid;
      if (userId === undefined) {
        throw new Error(t("uid is undefined"));
      }
      if (values.eT_version == null) {
        values.eT_version = 2;
      }

      if (userStore.user?.RoleName === "admin") {
        const { status, result } = await licenseService.Create({
          ...values,
          tenantId: generateLicenseStore.selectedTenant?.id,
          sets,
          userId,
        });

        if (status === 100 && (result as unknown as string) === "Error") {
          throw new Error(result as unknown as string);
        }

        generateLicenseStore.setCreated({
          created: {
            licenseKey: result.licenseKey,
            sets: data?.filter((article) => sets.includes(article.setID)),
          },
        });
      navigate("success")

      } else {
        const uid = userStore.user?.ETUID ?? "0";

        var manualLicenseAvailable =
          await licenseStore.getManualLicenseAvailableList({
            body: { status: null, userID: uid },
            userRole: "dealer",
            uid,
            isGetAll: true,
          });
          
        const manual = manualLicenseAvailable?.filter(
          ({ setID }) => sets.includes(setID)
        );
        var license: any = manual?.map((m) => {
          return {
            licenseDetailID: m.licenseDetailID,
            setID: m.setID,
          };
        });
        
        const { status, result } =
          await dealerCustomerService.CreateManualLicense({
            ETUID: userStore.user?.ETUID ?? 0,
            DeviceName: values.deviceName,
            ActivationAck: "1",
            Createdby: 0,
            LicenseList: license,
            LicenseKey: "",
            Remarks: values.remarks,
            ActivationKey: "fhvhjghfhgjbkjhgfgh",
            CustomerName: values.customerName ?? "",
            eT_version: values.eT_version,
            MacId: values.macId,
            Location: values.location ?? "",
          });
          
        if (status === 100 && (result as unknown as string) === "Error") {
          throw new Error(result as unknown as string);
        }

        generateLicenseStore.setCreated({
          created: {
            licenseKey: result.licenseKey,
            sets: data?.filter((article) => sets.includes(article.setID)),
          },
        });

        setDialogOpen(true)

      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedArticles = data
        ?.filter((article) => {
          if (!search) {
            return true;
          }
          return (
            (article.setName &&
              article.setName.toLowerCase().includes(search.toLowerCase())) ||
            (article.setCode &&
              article.setCode.toLowerCase().includes(search.toLowerCase())) ||
            (article.hundCode &&
              article.hundCode.toLowerCase().includes(search.toLowerCase()))
          );
        })
        .map((article) => {
          if (
            Configs.Workaround.isCloudArticle({ code: article.hundCode }) ||
            Configs.Workaround.isCloudArticle({ code: article.setCode })
          ) {
            generateLicenseStore.setCloudArticle({ cloudArticle: article });

            if (!generateLicenseStore.selectedTenant) {
              generateLicenseStore.setTenantSelectionDialogOpen({
                tenantSelectionDialogOpen: true,
              });
            }
          }

          return article.setID;
        })
        .reduce((acc, id) => {
          return { ...acc, [id]: true };
        }, {});
      generateLicenseStore.setSelectedArticles({
        selectedArticles: search
          ? { ...generateLicenseStore.selectedArticles, ...newSelectedArticles }
          : newSelectedArticles,
      });
      setSearch("");
      return;
    }

    const newSelectedArticles = data
      ?.filter((article) => {
        if (!search) {
          return true;
        }
        return (
          (article.setName &&
            article.setName.toLowerCase().includes(search.toLowerCase())) ||
          (article.setCode &&
            article.setCode.toLowerCase().includes(search.toLowerCase())) ||
          (article.hundCode &&
            article.hundCode.toLowerCase().includes(search.toLowerCase()))
        );
      })
      .map((article) => {
        if (
          Configs.Workaround.isCloudArticle({ code: article.hundCode }) ||
          Configs.Workaround.isCloudArticle({ code: article.setCode })
        ) {
          generateLicenseStore.setCloudArticle({ cloudArticle: undefined });
          generateLicenseStore.setSelectedTenant({ selectedTenant: undefined });
        }

        return article.setID;
      })
      .reduce((acc, id) => {
        return { ...acc, [id]: false };
      }, {});
    generateLicenseStore.setSelectedArticles({
      selectedArticles: search
        ? { ...generateLicenseStore.selectedArticles, ...newSelectedArticles }
        : {},
    });
    setSearch("");
  };
  // Function to decrypt the ciphertext
  function decrypt(ciphertext: string): string {
    try {
      var key = CryptoJS.enc.Utf8.parse(Constants.environment.AESDataKey); 
      var iv =  CryptoJS.enc.Utf8.parse(Constants.environment.AESDataKey); 
      var encryptText = ciphertext;
      
      const decrypted = CryptoJS.AES.decrypt(encryptText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
      console.log('decrypt: ', decrypted);

      return decrypted;
    } catch (error) {
      console.error('Decryption error:', (error as Error).message);
      return '';
    }
  }

function handleDeactivation(){
  var decryptedData = decrypt(ackValue)
  var decryptedDeactivationKey = JSON.parse(decryptedData)

  const { MacId,DeactivationStatus} = decryptedDeactivationKey;
  console.log(MacId)
  if(decryptedDeactivationKey != null && MacId === device?.macID && DeactivationStatus === 1){
  handleDeactivateAll();
  handleDeactivationClose();
  }else{
    enqueueSnackbar(
      t(
        `Enter the valid acknowledgement key`
      ),
      {
        variant: "warning",
        preventDuplicate: false,
      }
    ); 
  }
}

function handleDeactivationClose(){
setDeactivationDialogOpen(false)
}
  return (
     <>
      <Dialog open={deactivationDialogOpen} onClose={handleDeactivationClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Deactivation of terminal device</DialogTitle>
        <DialogContent>
          <DialogContentText>
           After entering the valid acknowledgement key the deactivation of this device will be completed.
          </DialogContentText>
          <Mui.TextField
          style={{marginBottom:"28px"}}
          fullWidth
            value={device?.licenseKey}
            margin="dense"
            id="name"
            label="Deactivation key"
            type="email"
            InputProps={{endAdornment: <ReactCopyToClipboard.CopyToClipboard
              text={device?.licenseKey}
              onCopy={() => {
                enqueueSnackbar(
                  t(
                    `Deactivation key successfully  copied to clipboard.`
                  ),
                  {
                    variant: "success",
                    preventDuplicate: false,
                  }
                );
              }}
            >
              <Mui.IconButton size="small" onClick={() => {}}>
                <MuiIcons.ContentCopy />
              </Mui.IconButton>
            </ReactCopyToClipboard.CopyToClipboard>}}
          />
           <Mui.TextField
          style={{marginBottom:"52px"}}
            autoFocus
            margin="dense"
            id="name"
            label="Acknowledgement key"
            placeholder="Enter the acknowledgement key"
            type="text"
            fullWidth
            value = {ackValue}
            onChange={(e:any)=>setAckValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Mui.Button onClick={handleDeactivationClose} color="primary">
            Cancel
          </Mui.Button>
          <Mui.Button onClick={()=>{
            if(device!.licenseKey !== ""){
              handleDeactivation()
          }else{
           handleDeactivateAll();
         }
          }} color="primary">
            Deactivate
          </Mui.Button>
        </DialogActions>
       </Dialog>
        <Dialog
        open={dialogOpen}
        keepMounted
        disableBackdropClick
        onClose={() => {
          setDialogOpen(false);
        }}
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <Mui.Grid
          style={{
           paddingLeft:"18px",
           paddingRight:"18px",
           marginBottom:"20px"
          }}
        >
         
      
       <Mui.Typography variant = "h6" style={{  opacity:"0.9" }}>
        <ReactI18next.Trans i18nKey="Installation key generated successfully.">
        Installation key generated successfully.
        </ReactI18next.Trans>
        </Mui.Typography>
          
          <Mui.Typography variant = "body1" style={{  opacity:"0.8", fontSize:"14px"}}>
          <ReactI18next.Trans i18nKey="Success!">
          The installation key generated can be copied for activating the easy touch manually.
          </ReactI18next.Trans>
          </Mui.Typography>
          </Mui.Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
      <Mui.Grid style={{backgroundColor:"#e6ecff",padding:"15px"}}>
      <Mui.Typography style={{wordWrap:"break-word", fontSize:"13px"}}>{generateLicenseStore.created?.licenseKey}</Mui.Typography>
          </Mui.Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
            setDialogOpen(false);
            
            }}
            variant="contained"
            color="default"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
             Back
          </Mui.Button>
          <ReactCopyToClipboard.CopyToClipboard
                            text={generateLicenseStore.created?.licenseKey}
                            onCopy={() => {
                              enqueueSnackbar(
                                t(
                                  `License key has been successfully  copied to clipboard.`
                                ),
                                {
                                  variant: "success",
                                  preventDuplicate: false,
                                }
                              );
                            }}
                          >
          <Mui.Button
            onClick={() => {
            
            
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
             Copy key
          </Mui.Button>
          </ReactCopyToClipboard.CopyToClipboard>
        </DialogActions>
      </Dialog>
      {open && (<Mui.Grid container style={{marginBottom:"0px"}} spacing={3}>
      <Mui.Grid item xs={12} md={12} lg={12}>
          <Mui.Card
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <Mui.CardContent>
              <Formik.Formik
                initialValues={
                  {
                    eT_version:
                      searchParams.get("eT_version") === "null"
                        ? 2
                        : searchParams.get("eT_version"),
                    macId:
                      searchParams.get("macId") === "null"
                        ? ""
                        : searchParams.get("macId"),
                    customerName:
                      searchParams.get("customerName") === "null"
                        ? ""
                        : searchParams.get("customerName"),
                    deviceName:
                      searchParams.get("deviceName") === "null"
                        ? ""
                        : searchParams.get("deviceName"),
                    remarks:
                      searchParams.get("remarks") === "null"
                        ? ""
                        : searchParams.get("remarks"),
                  } as Generate.Form.Values
                }
                validationSchema={Yup.object<Generate.Form.Values>({
                  macId: Yup.string()
                    .nullable()
                    .required(t("Machine ID is required")),
                  customerName:  userStore.user?.RoleName !== "customer" ? Yup.string().nullable()
                  .required(t("Customer name is required")):undefined,
                  deviceName: userStore.user?.RoleName === "customer" ? Yup.string().nullable()
                  .required(t("Device name is required")):undefined,
                })}
                onSubmit={handleCreate}
                enableReinitialize
              >
                {({ isSubmitting, values, submitForm, submitCount }) => (
                  <>
                    <Components.ResponsiveFullscreenDialogWithScroll
                      open={generateLicenseStore.tenantSelectionDialogOpen}
                      disableDividers
                      handleClose={() => {
                        generateLicenseStore.setTenantSelectionDialogOpen({
                          tenantSelectionDialogOpen: false,
                        });

                        if (generateLicenseStore.selectedTenant) {
                          return;
                        }

                        generateLicenseStore.setSelectedArticle({
                          article:
                            generateLicenseStore.cloudArticle as Models.Set.Article,
                        });
                      }}
                      contentText={
                        <Mui.List>
                          {tenantStore.activeTenants.length === 0 && (
                            <MuiLab.Alert severity="error">
                              <MuiLab.AlertTitle>
                                <ReactI18next.Trans i18nKey="No tenants found">
                                  No tenants found
                                </ReactI18next.Trans>
                              </MuiLab.AlertTitle>
                              <ReactI18next.Trans i18nKey="There are no active tenants to choose from">
                                There are no active tenants to choose from.
                                Please add active tenants and try again later!
                              </ReactI18next.Trans>
                            </MuiLab.Alert>
                          )}
                          {tenantStore.activeTenants.map((tenant, index) => (
                            <React.Fragment key={uuidv4()}>
                              <Mui.ListItem
                                button
                                onClick={() => {
                                  generateLicenseStore.setSelectedTenant({
                                    selectedTenant: tenant,
                                  });
                                  generateLicenseStore.setTenantSelectionDialogOpen(
                                    {
                                      tenantSelectionDialogOpen: false,
                                    }
                                  );
                                }}
                              >
                                <Mui.ListItemIcon>
                                  <Mui.Checkbox
                                    edge="start"
                                    checked={
                                      tenant.id ===
                                      generateLicenseStore.selectedTenant?.id
                                    }
                                    tabIndex={-1}
                                  />
                                </Mui.ListItemIcon>
                                <Mui.ListItemText
                                  primary={tenant.companyAliasName}
                                  secondary={tenant.companyName}
                                />
                              </Mui.ListItem>
                              {index !== tenantStore.tenants.length - 1 && (
                                <Mui.Divider />
                              )}
                            </React.Fragment>
                          ))}
                        </Mui.List>
                      }
                      actions={
                        <>
                          <Mui.Button
                            variant="contained"
                            onClick={() => {
                              generateLicenseStore.setSelectedArticle({
                                article:
                                  generateLicenseStore.cloudArticle as Models.Set.Article,
                              });
                              generateLicenseStore.setTenantSelectionDialogOpen(
                                {
                                  tenantSelectionDialogOpen: false,
                                }
                              );
                            }}
                          >
                            Cancel {generateLicenseStore.cloudArticle?.setName}{" "}
                            Article
                          </Mui.Button>
                          <Mui.Button
                            component={Router.Link}
                            color="primary"
                            variant="contained"
                            to="/tenants/create"
                            onClick={() => {
                              const returnUrl = `/licenses/generate?macId=${
                                values.macId
                              }&customerName=${values.customerName}&remarks=${
                                values.remarks
                              }&tenantId=${
                                generateLicenseStore.selectedTenant?.id ?? ""
                              }${generateLicenseStore.onlySelectedArticles.reduce(
                                (acc: string, set) => {
                                  return acc + `&set=${set}`;
                                },
                                ""
                              )}`;

                              tenantStore.setReturnUrl({ returnUrl });
                            }}
                          >
                            Create New Tenant
                          </Mui.Button>
                        </>
                      }
                      title={
                        <>
                          {generateLicenseStore.cloudArticle
                            ? `Select tenant for ${generateLicenseStore.cloudArticle.setName}`
                            : "Select tenant"}
                        </>
                      }
                    />
                    {/* <Generate.Form availableArticles={data} /> */}
                    <>
                    <Mui.Typography style={{opacity:"0.8"}} variant ={"h6"}>Terminal device detail</Mui.Typography>
    <Mui.Typography style={{opacity:"0.6" ,marginBottom:"24px"}} variant={"body2"}>Here you can view and update the terminal device</Mui.Typography>
                      <Mui.Grid container spacing={4}>
                        
              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="MAC ID (PC, tablet)">
                    MAC ID (PC, tablet)
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography
                  //variant="body2"
                  color="textPrimary"
                  style={{ textTransform: "uppercase" ,fontSize:"16px"}}
                >
                  {device?.macID}
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="Device name">
                    Device name
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary" style={{ fontSize:"16px"}}>
                  {device?.deviceName}
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="License serial number">
                  License serial number
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary">
                {serialNumberList.length > 0 && !showMore
                  ? serialNumberList[0]
                  : serialNumberList.join(", ")}{" "}
                {serialNumberList.length > 1 && !showMore && (
                  <Mui.Link
                    style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                    onClick={toggleShowMore}
                    component="span"
                  >
                    Show more
                  </Mui.Link>
                )}
                {serialNumberList.length > 1 && showMore && (
                  <Mui.Link
                    style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                    onClick={toggleShowMore}
                    component="span"
                  >
                    Show less
                  </Mui.Link>
                )}
              </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="Customer device id">
                    Customer device id
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary" style={{ fontSize:"16px"}}>
                  {device?.customerDeviceID}
                </Mui.Typography>
              </Mui.Grid>
             { device?.location !== null && <Mui.Grid item xs={12} md={6} lg={3}>
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="Location name">
                    Location name
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography variant="body2" color="textPrimary" style={{ fontSize:"16px"}}>
                  {device?.location}
                </Mui.Typography>
              </Mui.Grid>}
             {device?.licenseKey !== null && <Mui.Grid item xs={12} md={6} lg={3} >
              <Mui.Grid container style={{justifyContent:"space-between"}}>
              <Mui.Grid item >
                <Mui.Typography variant="body2" color="textSecondary">
                  <ReactI18next.Trans i18nKey="Installation key">
                    Installation key
                  </ReactI18next.Trans>
                </Mui.Typography>
                <Mui.Typography
                  variant="body2"
                  color="primary"
                  //style={{ overflow:"hidden",textOverflow:"ellipsis",fontSize:"16px" }}
                >
                  {device?.licenseKey !== null ? device?.licenseKey.slice(0, 30)+"..." : ""}
                </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item >
                <ReactCopyToClipboard.CopyToClipboard
                          text={device?.licenseKey}
                          onCopy={() => {
                            enqueueSnackbar(
                              t(
                                `Installation key has been successfully  copied to clipboard.`
                              ),
                              {
                                variant: "success",
                                preventDuplicate: false,
                              }
                            );
                          }}
                        >
                          <Mui.IconButton size="small" color="primary" onClick={() => {}}>
                            <MuiIcons.ContentCopy />
                          </Mui.IconButton>
                        </ReactCopyToClipboard.CopyToClipboard>
                        </Mui.Grid>
                        </Mui.Grid>
              </Mui.Grid>}
            
            </Mui.Grid></>
                    {generateLicenseStore.selectedTenant && (
                      <>
                        <Mui.Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginTop: theme.spacing(2) }}
                        >
                          <ReactI18next.Trans i18nKey="Selected tenant">
                            Selected tenant
                          </ReactI18next.Trans>
                        </Mui.Typography>
                        <Mui.Link
                          onClick={() =>
                            generateLicenseStore.setTenantSelectionDialogOpen({
                              tenantSelectionDialogOpen: true,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Mui.Tooltip
                            title={
                              <ReactI18next.Trans i18nKey="Change selected tenant">
                                Change selected tenant
                              </ReactI18next.Trans>
                            }
                            placement="bottom-start"
                          >
                            <Mui.Typography
                              style={{ marginTop: theme.spacing(0.5) }}
                            >
                              {
                                generateLicenseStore.selectedTenant
                                  ?.companyAliasName
                              }{" "}
                              (
                              {generateLicenseStore.selectedTenant?.companyName}
                              )
                            </Mui.Typography>
                          </Mui.Tooltip>
                        </Mui.Link>
                      </>
                    )}
                   {generateLicenseStore.noOfSelectedArticles > 0 && <Mui.Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: theme.spacing(2) }}
                    >
                      <ReactI18next.Trans i18nKey="Selected license">
                        Selected license(s)
                      </ReactI18next.Trans>
                    </Mui.Typography>}
                    {submitCount > 0 &&
                      generateLicenseStore.noOfSelectedArticles === 0 && (
                        <Mui.FormHelperText error>
                          <ReactI18next.Trans i18nKey="Please select an license before proceeding">
                            Please select an license before proceeding
                          </ReactI18next.Trans>
                        </Mui.FormHelperText>
                      )}
                    {submitCount > 0 &&
                      generateLicenseStore.noOfSelectedArticles > 0 &&
                      !generateLicenseStore.isDependentArticlesValid && (
                        <Mui.FormHelperText error>
                          <ReactI18next.Trans i18nKey="Please select dependent license">
                            Please select dependent license
                            {` (${Array.from(
                              generateLicenseStore.dependentArticles.values()
                            ).join(", ")})`}
                          </ReactI18next.Trans>
                        </Mui.FormHelperText>
                      )}
                   
                    <Mui.Grid container>
                      {data
                        ?.filter(
                          (article) =>
                            generateLicenseStore.selectedArticles[article.setID]
                        )
                        .map((article) => (
                          <Mui.Tooltip title={article.setName} key={uuidv4()}>
                            <Mui.Avatar
                              style={{
                                marginRight: theme.spacing(2.5),
                                marginTop: theme.spacing(1),
                              }}
                            >
                              <Mui.Box color="text.primary">
                                <i className={article.iconClass} />
                              </Mui.Box>
                            </Mui.Avatar>
                          </Mui.Tooltip>
                        ))}
                    </Mui.Grid>

                    <Mui.Box height={theme.spacing(5)} width={1} />

                    <Mui.Box
                      position="fixed"
                      bottom={theme.spacing(2.5)}
                      right={theme.spacing(2.5)}
                      zIndex={3}
                    >
                    
                      <Mui.Button
                           onClick={() => {
                            if (device?.sets.size === 0) {
                              return;
                            }
                            if(device?.licenseKey != null)
                          {
                            setDeactivationDialogOpen(true);
                          }else{
                            handleDeactivateAll();
                          }
                          }}
                        color="default"
                        variant="contained"
                        style={{marginRight:"18px"}}
                      >
                        <ReactI18next.Trans i18nKey="Deactivate terminal device">
                          Deactivate terminal device
                        </ReactI18next.Trans>
                      </Mui.Button>
                      <Mui.Button
                          onClick={() => {
                            const sets = Array.from(device!.sets.values())
                              .filter((article) => article)
                              .map((article) => (article.setID));
                            
                          
                            const url = `/easy-touch-devices/generate?macId=${
                              device!.macID
                            }&deviceName=${device!.deviceName}&remarks=${
                              device?.deviceName
                            }&location=${
                              device?.location ?? ""
                            }${sets?.reduce(
                              (acc:any, set:any) => {
                                return acc + `&set=${set}`;
                              },
                              ""
                            )}`;
  
                            navigate(url);
                          }}
                        color="primary"
                        variant="contained"
                      >
                        <ReactI18next.Trans i18nKey="Edit terminal device">
                          Edit terminal device
                        </ReactI18next.Trans>
                      </Mui.Button>
                    </Mui.Box>
                  </>
                )}
              </Formik.Formik>
            </Mui.CardContent>
          </Mui.Card>
        </Mui.Grid>
      </Mui.Grid>)}
      {open && device !== undefined && ( <Mui.Paper style={{backgroundColor:"#ffffff"}}>
        <Mui.Grid container spacing={1}>
        <Mui.Grid item xs={12} md={7} lg={7}>
        <Mui.Typography style={{opacity:"0.8",padding:"16px"}} variant ={"h6"}>List of installed licenses</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={5} lg={5}>
          <Mui.Grid container alignItems="center">
            <Mui.Grid item xs={6} >
              <Mui.FormControl
                style={{ width: 200, marginBottom: theme.spacing(4.5) }}
              >
              
              </Mui.FormControl>
            </Mui.Grid>

            <Mui.Grid item xs={6}>
              <Mui.FormControl style={{ marginBottom: theme.spacing(1.5) }}>
               
              </Mui.FormControl>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={3}>
        
        <Mui.Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ height: "calc(100vh - 274px)", overflow: "auto" , paddingLeft:"20px" , paddingRight:"20px"}}
        >
          <Mui.Grid container spacing={3}>
                {Array.from(device.sets.values())
                  .filter((article) => article)
                  .map((article) => (
                    <Mui.Grid item xs={12} md={6} lg={4} key={uuidv4()}>
                      <Article.Card
                        style={{
                          backgroundColor: theme.palette.background.default,
                        }}
                        selected={undefined}
                        functions={functionStore.functions.filter((func) =>
                          article.functions.includes(func.setFunctionID)
                        )}
                        handleClick={() =>
                          licenseStore.toggleArticleSelectionForDeactivation({
                            setID: article.setID,
                          })
                        }
                        article={article}
                        variant={"DETAILED"}
                      />
                    </Mui.Grid>
                  ))}
              </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      </Mui.Paper>)}
    </>
  );
          }
