/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FlagIcon from "@material-ui/icons/Flag";
import InfoIcon from "@material-ui/icons/Info";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import SearchIcon from "@material-ui/icons/Search";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as MuiIcon from "@material-ui/icons";
import * as ReactUndraw from "iblis-react-undraw";

interface Data extends Models.ImportDealer.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({
  rows,
  displayData,
  isEmailFlag,
}: {
  rows: Models.ImportDealer.Main[];
  isEmailFlag: boolean;
  displayData: boolean;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("companyName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.dealerProfileID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const navigate = Router.useNavigate();
  const stopnavigationHandle = (event: any) => {
    event.stopPropagation();
  };
  const importDealerStoreS = React.useContext(App.Contexts.importDealerStore);
  const handleSelectDealerUser = ({
    dealerProfile,
    etstatus,
  }: {
    dealerProfile: Models.ImportDealer.DealerUser;
    etstatus: number;
  }) => {
    let newSelected: Models.ImportDealer.DealerUser[] = [];
    importDealerStoreS.selected.slice(0, -1);
    importDealerStoreS.setSelected({ selected: newSelected });
    //alert(etstatus)
    // if (etstatus != 0) {
    //   let newSelected: Models.ImportDealer.DealerUser[] = [];
    // } else {
    localStorage.removeItem("selected");
    const selectedIndex = importDealerStoreS.selected.indexOf(dealerProfile);
    //let newSelected: Models.ImportDealer.DealerUser[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        importDealerStoreS.selected,
        dealerProfile
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(importDealerStoreS.selected.slice(1));
    } else if (selectedIndex === importDealerStoreS.selected.length - 1) {
      newSelected = newSelected.concat(
        importDealerStoreS.selected.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        importDealerStoreS.selected.slice(0, selectedIndex),
        importDealerStoreS.selected.slice(selectedIndex + 1)
      );
    }
    importDealerStoreS.setSelected({ selected: [] });
    localStorage.removeItem("namedatad");
    // }
    //alert("Imported"+JSON.stringify(newSelected));
  };
  const { i18n } = ReactI18next.useTranslation();


  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="companyName"
                  label={t("Company")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="email"
                  label={t("Company email ID")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="phoneNumber"
                  label={t("Phone no")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="country"
                  label={t("Country")}
                />
                <EnhancedTableHead rightmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="city"
                  label={t("City")}
                />
              
             
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.dealerMasterID as any);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <Mui.TableRow
                    hover
                    classes={classes}
                    onClick={async () => {
                  
                      localStorage.setItem("dealerID", JSON.stringify(row));
                      handleSelectDealerUser({
                        dealerProfile: (row as unknown) as Models.ImportDealer.DealerUser,
                        etstatus: row.status as number,
                      });
                      const flowFrom = "isFromImportDealer";
                      var dealerMasterID = btoa(row.dealerMasterID.toString());
                      var dealerProfileID = btoa(
                        row.dealerAddressID.toString()
                      );
                      navigate(
                        `/dealercustomer/adddealeruser?q=${dealerMasterID}&m=${dealerProfileID}&v=${flowFrom}`
                      );
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.dealerMasterID}
                  >
                    <Components.Mui.TableCell leftmost
                      component="th"
                      id={labelId}
                     
                    >
                      {row.companyName && isEmailFlag ? (
                        row.companyName
                      ) : (
                        <mark>{row.companyName}</mark>
                      )}
                    </Components.Mui.TableCell>
                   
                    <Components.Mui.TableCell
                    onClick={()=>{window.open(`mailto:${row.dealerCompanyEmail}`)}}
                    >
                                            <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{row.dealerCompanyEmail && isEmailFlag ? (
                        <mark>{row.dealerCompanyEmail}</mark>
                      ) : (
                        row.dealerCompanyEmail
                      )}</Mui.Link></Mui.Tooltip>

                     
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell onClick={stopnavigationHandle}>
                      <Mui.Grid style={{justifyContent:"space-between",display:"flex"}}>
                      {row.phoneNumber}
                      <Mui.Tooltip
                        title={
                          <ReactI18next.Trans i18nKey={"Click to copy the phone number"}>
                            Click to copy the phone number.
                          </ReactI18next.Trans>}
                      >
                        <ReactCopyToClipboard.CopyToClipboard
                          text={row.phoneNumber}
                          onCopy={() => {
                            enqueueSnackbar(
                              t(
                                `Phone number of ${row.firstName} has been successfully  copied to clipboard.`
                              ),
                              {
                                variant: "success",
                                preventDuplicate: false,
                              }
                            );
                          }}
                        >
                          <Mui.IconButton size="small" onClick={() => {}}>
                            <MuiIcons.ContentCopy />
                          </Mui.IconButton>
                        </ReactCopyToClipboard.CopyToClipboard>
                      </Mui.Tooltip>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.countryName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell rightmost>
                     

                      <Mui.Grid container justify="space-between">
                      {row.city}
                        <Mui.IconButton
                          size="medium"
                          style={{
                            padding: theme.spacing(0.125),
                            fontSize: "18px",
                          }}
                        >
                          <MuiIcon.ChevronRight
                            style={{ fontSize: "30px" }}
                            color="primary"
                          />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Components.Mui.TableCell>
                 
                   
                  
                  </Mui.TableRow>
                );
              })}
         
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows, border: "none" }}>
                <Mui.TableCell style={{ display: "none" }} colSpan={0} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: theme.spacing(4),
          }}
        >
          <Mui.Grid item>
           
          </Mui.Grid>
          <Mui.Grid item>
            <MuiLab.Pagination
              // NOTE: Mui Lab Pagination assumes page starts at 1
              page={page + 1}
              count={Math.ceil(rows.length / rowsPerPage)}
              // NOTE: Mui Lab Pagination assumes page starts at 1
              onChange={(event, page) => handleChangePage(event, page - 1)}
              shape="rounded"
            />
          </Mui.Grid>
        </Mui.Grid>
      )}
    </div>
  );
}
export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const [filterHide, setFilterHide] = React.useState<boolean>(false);
  const [displayNoData, setDisplayNoData] = React.useState<boolean>(false);
  const [isEmailColor, setIsEmailColor] = React.useState<boolean>(true);
  const [disable, setDisable] = React.useState<boolean>(true);
  const theme = Mui.useTheme();
  const importDealerStore = React.useContext(App.Contexts.importDealerStore);
  const userStore = React.useContext(App.Contexts.userStore);
  React.useEffect(() => {
    userStore.setPreviousUrl({
      urls: "/dealercustomer/managedealer",
    });
    localStorage.removeItem("namedatad");
    let dealer: any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let searchHighlight: Boolean = false;
  const getSearchHighlight = (highlightFlag: boolean) => {
    searchHighlight = highlightFlag;
    return highlightFlag;
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
    if (event.target.value !== "") {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };
  const [state, setState] = React.useState<any>();
  const handleChange = (event: any) => {
    if (event.target.value === "All") {
      setSearch("");
    } else {
      setSearch(event.target.value as string);
    }
  };
  const storeData = importDealerStore.importDealer;
  const noStoreData = storeData.length > 0 ? true : false;
  const { t } = ReactI18next.useTranslation();
  const [open,setOpen] = React.useState(true);
  const [dealerData,setDealerData] = React.useState<Models.ImportDealer.Main[]>([]);
  const [dealerDataString,setDealerDataString] = React.useState<any>("");
  const [displayEmptyMsg,setDisplayEmptyMsg] = React.useState<boolean>(false);
  const handleSearchClick = async() => {
    setFilterHide(true);
    const searchtext = search.trim();
    let searchType;
    var format = /[@]/;
    if (format.test(search.trim())) {
      setIsEmailColor(true);
      searchType = "1";
    } else {
      setIsEmailColor(false);
      searchType = "0";
    }
   const dealerFromSSO =  await importDealerStore.readUsers({
      searchtext,
    });
    if(dealerFromSSO !== undefined && dealerFromSSO !== null && dealerFromSSO.length > 0 && Array.isArray(dealerFromSSO)){
    setDealerData([dealerFromSSO[0]]);
    setDisplayEmptyMsg(false);
    setDealerDataString("");

    }else{
      setDisplayEmptyMsg(true);
    setDealerDataString(dealerFromSSO);

    }
    

  };
  return (
    <>
    <Mui.Dialog
        open={open}
        keepMounted
        disableBackdropClick
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Mui.DialogTitle id="alert-dialog-slide-title">{""}</Mui.DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <InfoIcon
            style={{
              color: theme.palette.primary.main,
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: theme.palette.primary.main, }}>
          <ReactI18next.Trans i18nKey="Information">
          Information!
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-slide-description">
          &emsp;<ReactI18next.Trans i18nKey="This module is fetching the dealers from HASE">This module is fetching the dealers from SAGE OL.</ReactI18next.Trans>

          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
              setOpen(false)
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <ReactI18next.Trans i18nKey="Ok">
            Ok
          </ReactI18next.Trans>
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      <Mui.Grid container>
        <Mui.Grid
          item
          xs={6}
          lg={3}
          md={3}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Mui.FormControl
            style={{
              width: "80%",
            }}
          >
            <Mui.TextField
              style={{
                width: "120%",
                marginTop: theme.spacing(3),
              }}
              label={t("Select dealer")}
            onChange={handleSearch}
              placeholder={t("Search with dealer's address ID or email ID")}
            />
          </Mui.FormControl>
        </Mui.Grid>
        <Mui.Grid item xs={6} lg={3} md={3}>
          <Mui.Button
            color="primary"
            variant="contained"
            style={{ margin: "34px 0 24px 0px", float: "left" }}
            onClick={handleSearchClick}
            disabled={disable}
          >
            <SearchIcon />
            <ReactI18next.Trans i18nKey="SEARCH">SEARCH</ReactI18next.Trans>
          </Mui.Button>
        </Mui.Grid>
        
        <Mui.Grid item xs={12} lg={3} md={3}></Mui.Grid>{" "}
        <Mui.Grid item xs={12} lg={1} md={1}></Mui.Grid>
        {noStoreData && (
          <Mui.Grid item xs={8} lg={2} md={2}>
            <Mui.FormControl
              style={{
                width: "100%",
                float: "right",
                margin: "22px 0px 0px 90px",
              }}
            >
              <Mui.InputLabel id="demo-simple-select-label">
                <ReactI18next.Trans i18nKey="Country">
                  Country
                </ReactI18next.Trans>
              </Mui.InputLabel>
              <Mui.Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state}
                onChange={handleChange}
              >
                <Mui.MenuItem value="">None</Mui.MenuItem>
                {Array.from(
                  importDealerStore &&
                    importDealerStore.importDealer
                      .reduce((acc: Set<any>, set) => {
                        return acc.add(set.countryName);
                      }, new Set())
                      .values()
                ).map((countryName: any) => (
                  <Mui.MenuItem key={uuidv4()} value={countryName}>
                    {countryName}
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
          </Mui.Grid>
        )}
      </Mui.Grid>

      
        <>
          <Mui.Typography color="textSecondary" style={{fontWeight:550,wordSpacing:"3px",marginTop:"5px"}}><InfoIcon
            style={{
              color: theme.palette.primary.main,
              width: "24px",
              height: "24px",
              marginBottom:"-5px"
            }}
          /> <ReactI18next.Trans i18nKey="This module is fetching the dealers from HASE">This module is fetching the dealers from SAGE OL.</ReactI18next.Trans>
          </Mui.Typography>
          { !displayEmptyMsg ? dealerData !== undefined && dealerData?.length > 0 && dealerDataString !== "Mail id not available in SSO" && <EnhancedTable
            rows={dealerData}
            displayData={displayNoData}
            isEmailFlag={isEmailColor}
          />: (
            <Mui.Box padding={12} >
            <ReactUndraw.SvgNoData
              style={{
                marginBottom: theme.spacing(6),
              }}
              accentcolor={theme.palette.primary.main}
              primarycolor={theme.palette.secondary.main}
              height={200}
            />
         
            <Mui.Typography
              variant="body1"
              align="center"
              style={{
                backgroundColor: theme.palette.info.light,
                opacity: "0.9",
                border: "2px solid transparent",
                borderRadius: "1%",
                }}
            >
              <ReactI18next.Trans i18nKey="There are no user from your customer list.">
                There are no company found for the selected address ID or email ID.
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
              
            </Mui.Box>
          </Mui.Box>
             
          )}
        </>
      
    </>
  );
});
