/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD" | "LIST";

  export interface IStore {
    isLoading: boolean;
    adminManageService: Services.AdminManageService;
    view: Store.View;
  }

  export type Options = Pick<IStore, "view" | "adminManageService">;

  export namespace SetView {
    export interface Props extends Pick<Store.IStore, "view"> {}
  }

  export namespace Read {
    export interface Props
      extends Services.AdminManageService.GetAllData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public view: Store.View = "TABULAR";
  @Mobx.observable public adminManage: Models.AdminManage.Main[] = [];
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public selected: Models.CustomerLicense.Main[] = [];
  //public orderService: Services.Order;
  public adminManageService: Services.AdminManageService;
  constructor({ view, adminManageService }: Store.Options) {
    this.view = view;
    this.adminManageService = adminManageService;
  }

  @Mobx.action setView({ view }: Store.SetView.Props) {
    this.view = view;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.adminManageService.getCustomer(props);
      this.adminManage = result;
      //
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.adminManage.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
  @Mobx.action public setSelected({
    selected,
  }: {
    selected: Models.CustomerLicense.Main[];
  }) {
    this.selected = selected;
   
  }
}
