import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as React from "react";
import * as ReactI18next from "react-i18next";

export declare namespace AddressForm {
  export interface Values {
    firstName: string;
    lastName?: string;
    companyName?: string;
    address1: string;
    address2?: string;
    address3?: string;
    city?: string;
    state: string;
    postalCode: string;
    country: string;
  }
}

// eslint-disable-next-line no-redeclare
export function AddressForm() {
  const { t } = ReactI18next.useTranslation();

  return (
    <>
      <Formik.Form>
        <Mui.Grid container spacing={4}>
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="firstName"
              component={FormikMui.TextField}
              label={t("First name")}
              fullWidth
              autoFocus
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="lastName"
              component={FormikMui.TextField}
              label={t("Last name")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="companyName"
              component={FormikMui.TextField}
              label={t("Company name")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="address1"
              component={FormikMui.TextField}
              label={t("Address line 1")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="address2"
              component={FormikMui.TextField}
              label={t("Address line 2")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="address3"
              component={FormikMui.TextField}
              label={t("Address line 3")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="city"
              component={FormikMui.TextField}
              label={t("City")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="state"
              component={FormikMui.TextField}
              label={t("State")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="country"
              component={FormikMui.TextField}
              label={t("Country")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="postalCode"
              component={FormikMui.TextField}
              label={t("Postal code")}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
