import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Containers from "src/app/containers";
import * as Configs from "src/configs";
import * as ReactI18next from "react-i18next";

export const Summary = Mobx.observer(function () {
  const theme = Mui.useTheme();

  const tenantStore = React.useContext(App.Contexts.tenantStore);

  return (
    <>
      <Mui.Box minHeight={275}>
        <Mui.Grid container spacing={3}>
          <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
            <Mui.Grid item xs={12}>
              <Mui.Typography
                component="div"
                style={{
                  fontWeight: "bold",
                }}
              >
                <MuiLab.Alert severity="success">
                  <MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="Congradulations! Request Placed!">
                  Congradulations! Request Placed!
                  </ReactI18next.Trans>
                  </MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="You've successfully placed your tenant creation request">
                   You've successfully placed your tenant creation request with the following details. Our system has started processing the request and you'll be notified when your request is fulfilled by our system.
                  </ReactI18next.Trans>
                </MuiLab.Alert>
              </Mui.Typography>
            </Mui.Grid>
          </Containers.ShowIfAuthorised>

          <Mui.Grid item xs={12}>
            <Mui.Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >
              <ReactI18next.Trans i18nKey="You can access your ET Cloud admin portal from the following URL">
                                 You can access your ET Cloud admin portal from the following URL
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Link
              href={`${
                Configs.ET_CLOUD?.BASE_URL
              }/${tenantStore.companyAliasName
                .split(" ")
                .join("_")
                .toLowerCase()}`}
            >
              {`${
                Configs.ET_CLOUD?.BASE_URL
              }/${tenantStore.companyAliasName
                .split(" ")
                .join("_")
                .toLowerCase()}`}
            </Mui.Link>
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Mui.Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >
              <ReactI18next.Trans i18nKey="Database name">
                                Database name
                                </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography>{`${tenantStore.companyAliasName
              .split(" ")
              .join("_")}_ETDB`}</Mui.Typography>
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Mui.Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >
              <ReactI18next.Trans i18nKey="Admin user name">
                                  Admin user name
                                </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography>{tenantStore.adminAccount.userName}</Mui.Typography>
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Mui.Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >
              <ReactI18next.Trans i18nKey="Admin name">
                                    Admin name
                                </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography>{`${tenantStore.adminAccount.firstName} ${tenantStore.adminAccount.lastName}`}</Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Box>
      <Mui.DialogActions style={{ padding: 0, marginTop: theme.spacing(4) }}>
        <Mui.Button
          type="submit"
          variant="contained"
          color="primary"
          component={Router.Link}
          to={tenantStore.returnUrl ? tenantStore.returnUrl : "/tenants"}
        >
          <ReactI18next.Trans i18nKey="Finish">
          Finish
          </ReactI18next.Trans>
        </Mui.Button>
      </Mui.DialogActions>
    </>
  );
});
