/** @format */

import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Containers from "src/app/containers";
import { Success } from "src/app/pages/license/activate";
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won"t need it on your project.
   */
  children?: string | JSX.Element | JSX.Element[];
}

export const Breadcrumb = Mobx.observer(function (props: Props) {
  const theme = Mui.useTheme();
  const { pathname } = Router.useLocation();
  const cartStore = React.useContext(App.Contexts.cartStore);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const customerStore = React.useContext(App.Contexts.delareCustomerStore);
  const manageStore = React.useContext(App.Contexts.manageDealerStore);
  const articleStore = React.useContext(App.Contexts.articleStore);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const industrySegmentStore = React.useContext(
    App.Contexts.industrySegmentStore
  );
  const iconStore = React.useContext(App.Contexts.iconStore);
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  // const userStore = React.useContext(App.Contexts.userStore);

  // const drawerItems = [
  //   {
  //     label: 'Dashboard',
  //     iconName: 'iconsmind-Shop-4',
  //     route: '/dashboard',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Shop',
  //     iconName: 'iconsmind-Shopping-Cart',
  //     route: '/shop',
  //     roles: ['customers'],
  //   },
  //   {
  //     label: 'Orders',
  //     iconName: 'iconsmind-Basket-Coins',
  //     route: '/orders',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Articles',
  //     iconName: 'iconsmind-Data-Copy',
  //     route: '/articles',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Functions',
  //     iconName: 'iconsmind-Data-Center',
  //     route: '/functions',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Industry / Segment',
  //     iconName: 'iconsmind-Factory',
  //     route: '/industry-segments',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Licenses',
  //     iconName: 'iconsmind-Security-Settings',
  //     route: '/licenses',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Easy Touch Devices',
  //     iconName: 'iconsmind-Wheelbarrow',
  //     route: '/easy-touch-devices',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Icons',
  //     iconName: 'iconsmind-Atom',
  //     route: '/icons',
  //     roles: ['admin'],
  //   },
  //   {
  //     label: 'Customers',
  //     iconName: 'iconsmind-Administrator',
  //     route: '/customers',
  //     roles: ['admin'],
  //   },
  // ];
  const [hideBreadcrumb, setHideBreadcrumb] = React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  // const paramsString = this.props.location.query;
  const params = new URLSearchParams(window.location.search);
  //alert(params);
  const manageParam = "v=isFromImportDealer";
  const { t } = ReactI18next.useTranslation();
  const indexOfLetterV = params.toString().indexOf("v");
  const parameter = params.toString().slice(indexOfLetterV,34)
  React.useEffect(() => {
    if (parameter === manageParam) {
      setHideBreadcrumb(true);
    } else {
      setHideBreadcrumb(false);
    }
  }, [parameter]);
  return (
    <div>
      <Router.Routes>
        <Router.Route
          path="/dashboard"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Dashboard'>
                    Dashboard
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dashboard"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Dashboard">
                        Dashboard
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/shop"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Shop'>Shop</ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/shop"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Apps">
                        Apps
                      </ReactI18next.Trans>
                    </Mui.Link>
                    {/* <Mui.Badge
                      color="secondary"
                      badgeContent={cartStore.cart.size}
                    >
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/shop/cart"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Cart">
                          Cart
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Badge> */}
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        {/* <Router.Route
          path="/shop/cart"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/shop"
                    >
                      <ReactI18next.Trans i18nKey="Shop">
                        Shop
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Badge
                      color="secondary"
                      badgeContent={cartStore.cart.size}
                    >
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/shop/cart"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Cart">
                          Cart
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Badge>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        /> */}
        <Router.Route
          path="/shop/checkout"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/shop"
                    >
                      <ReactI18next.Trans i18nKey="Apps">
                        Apps
                      </ReactI18next.Trans>
                    </Mui.Link>
                    {/* <Mui.Badge
                      color="secondary"
                      badgeContent={cartStore.cart.size}
                    >
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/shop/cart"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Cart">
                          Cart
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Badge> */}
                    {/* <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/shop/checkout"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Checkout">
                        Checkout
                      </ReactI18next.Trans>
                    </Mui.Link> */}
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/shop/payment/*"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Payment'>
                    Payment
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/shop"
                      // activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Apps">
                        Apps
                      </ReactI18next.Trans>
                    </Mui.Link>
                    {/* <Mui.Badge
                      color="secondary"
                      badgeContent={cartStore.cart.size}
                    >
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/shop/cart"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Cart">
                          Cart
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Badge> */}
                    {/* <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/shop/checkout"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Checkout">
                        Checkout
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to={pathname}
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Payment">
                        Payment
                      </ReactI18next.Trans>
                    </Mui.Link> */}
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/Orders"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/orders"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Orders">
                        Orders
                      </ReactI18next.Trans>
                    </Mui.Link>
                   
                  </Mui.Breadcrumbs>
                </Mui.Box>
                
                <Mui.Box>
                  <Mui.Tooltip title={
                <ReactI18next.Trans i18nKey="Card view">
                Card view
                </ReactI18next.Trans>} placement="top">
                    <Mui.Box display="inline-block" color="divider">
                      <Mui.IconButton
                        color={
                          orderStore.view === "CARD" ? "default" : "inherit"
                        }
                        onClick={() => orderStore.setView({ view: "CARD" })}
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <i className="icon-etc-card-view" />
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Tooltip>

                  <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Tabular view">
Tabular view
</ReactI18next.Trans>} placement="top">
                    <Mui.Box display="inline-block" color="divider">
                      <Mui.IconButton
                        color={
                          orderStore.view === "TABULAR" ? "default" : "inherit"
                        }
                        onClick={() => orderStore.setView({ view: "TABULAR" })}
                      >
                        <i className="icon-etc-table-view" />
                        {/* <MuiIcons.TableChart /> */}
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Tooltip>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/Orders/manage-order"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                    style={{ marginRight: theme.spacing(2) }}
                    component='span'
                    variant='h5'
                    gutterBottom
                  >
                    <ReactI18next.Trans i18nKey='Orders'>
                      Orders
                    </ReactI18next.Trans>
                  </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/orders"
                      //activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Orders">
                        Orders
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/orders/manage-order"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Manage order">
                        Manage order
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box>
                  <Mui.Tooltip title={
                <ReactI18next.Trans i18nKey="Card view">
                Card view
                </ReactI18next.Trans>} placement="top">
                    <Mui.Box display="inline-block" color="divider">
                      <Mui.IconButton
                        color={
                          orderStore.view === "CARD" ? "default" : "inherit"
                        }
                        onClick={() => orderStore.setView({ view: "CARD" })}
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <i className="icon-etc-card-view" />
                        {/* <MuiIcons.Dashboard /> */}
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Tooltip>

                  <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Tabular view">
Tabular view
</ReactI18next.Trans>} placement="top">
                    <Mui.Box display="inline-block" color="divider">
                      <Mui.IconButton
                        color={
                          orderStore.view === "TABULAR" ? "default" : "inherit"
                        }
                        onClick={() => orderStore.setView({ view: "TABULAR" })}
                      >
                        <i className="icon-etc-table-view" />
                        {/* <MuiIcons.TableChart /> */}
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Tooltip>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/articles"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                    style={{ marginRight: theme.spacing(2) }}
                    component='span'
                    variant='h5'
                    gutterBottom
                  >
                    <ReactI18next.Trans i18nKey='Articles'>
                      Articles
                    </ReactI18next.Trans>
                  </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/articles"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Articles">
                        Articles
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box>
                  <Mui.Button
                    style={{ marginRight: theme.spacing(1) }}
                    startIcon={<i className="icon-etc-add" />}
                    // startIcon={<MuiIcons.AddCircle />}
                    variant="contained"
                    color="primary"
                    onClick={() =>{
                      articleStore.setCreateDialogOpen({
                        createDialogOpen: true,
                      })
                      localStorage.removeItem("articleIdFromEdit")}
                    }
                  >
                    <ReactI18next.Trans i18nKey="Add new">
                      Add new
                    </ReactI18next.Trans>
                  </Mui.Button>

                  <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Card view">
Card view
</ReactI18next.Trans>} placement="top">
                    <Mui.Box display="inline-block" color="divider">
                      <Mui.IconButton
                        color={
                          articleStore.view === "CARD" ? "default" : "inherit"
                        }
                        onClick={() => articleStore.setView({ view: "CARD" })}
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <i className="icon-etc-card-view" />
                        {/* <MuiIcons.Dashboard /> */}
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Tooltip>

                  <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Tabular view">
Tabular view
</ReactI18next.Trans>} placement="top">
                    <Mui.Box display="inline-block" color="divider">
                      <Mui.IconButton
                        color={
                          articleStore.view === "TABULAR"
                            ? "default"
                            : "inherit"
                        }
                        onClick={() =>
                          articleStore.setView({ view: "TABULAR" })
                        }
                      >
                        <i className="icon-etc-table-view" />
                        {/* <MuiIcons.TableChart /> */}
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Tooltip>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/functions"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                    style={{ marginRight: theme.spacing(2) }}
                    component='span'
                    variant='h5'
                    gutterBottom
                  >
                    <ReactI18next.Trans i18nKey='Functions'>
                      Functions
                    </ReactI18next.Trans>
                  </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/functions"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Functions">
                        Functions
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box>
                  <Mui.Button
                    // startIcon={<MuiIcons.AddCircle />}
                    startIcon={<i className="icon-etc-add" />}
                    variant="contained"
                    color="primary"
                    onClick={() =>{functionStore.setCreateDialogOpen({
                      createDialogOpen: true,
                    })
                    localStorage.removeItem("functionIdFromEdit")}
                  }
                  >
                    <ReactI18next.Trans i18nKey="Add new">
                      Add new
                    </ReactI18next.Trans>
                  </Mui.Button>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/industry-segments"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                    style={{ marginRight: theme.spacing(2) }}
                    component='span'
                    variant='h5'
                    gutterBottom
                  >
                    <ReactI18next.Trans i18nKey='Industry / Segments'>
                      Industry / Segments
                    </ReactI18next.Trans>
                  </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/industry-segments"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Industry or Segments">
                        Industry or Segments
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box>
                  <Mui.Button
                    // startIcon={<MuiIcons.AddCircle />}
                    startIcon={<i className="icon-etc-add" />}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      industrySegmentStore.setCreateDialogOpen({
                        createDialogOpen: true,
                      })
                    }
                  >
                    <ReactI18next.Trans i18nKey="Add new">
                      Add new
                    </ReactI18next.Trans>
                  </Mui.Button>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/licenses"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Licenses'>
                    Licenses
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Containers.ShowIfAuthorised roles={["dealer"]}>
                    <Mui.Breadcrumbs aria-label="breadcrumb">
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/"
                      >
                        <ReactI18next.Trans i18nKey=" Home">
                          Home
                        </ReactI18next.Trans>
                      </Mui.Link>
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Licenses">
                          Licenses
                        </ReactI18next.Trans>
                      </Mui.Link>
                      {/* <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses/GetCustomerAssignedLicense"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Assigned License Detail">
                          Assigned License Detail
                        </ReactI18next.Trans>
                      </Mui.Link> */}
                    </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                  <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                    <Mui.Breadcrumbs aria-label="breadcrumb">
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/"
                      >
                        <ReactI18next.Trans i18nKey="Home">
                         Home
                        </ReactI18next.Trans>
                      </Mui.Link>
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Licenses">
                          Licenses
                        </ReactI18next.Trans>
                      </Mui.Link>
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses/manual"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Manual">
                          Manual
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                  <Containers.ShowIfAuthorised roles={["customer", "EU"]}>
                    <Mui.Breadcrumbs aria-label="breadcrumb">
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/"
                      >
                        <ReactI18next.Trans i18nKey="Home">
                         Home
                        </ReactI18next.Trans>
                      </Mui.Link>

                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Licenses">
                          Licenses
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                </Mui.Box>

                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Box>
                    <Mui.Button
                      // startIcon={<MuiIcons.AddCircle />}
                      startIcon={<i className="icon-etc-add" />}
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="generate"
                      style={{marginRight:"6px"}}
                    >
                      <ReactI18next.Trans i18nKey="Generate new">
                        Generate new
                      </ReactI18next.Trans>
                    </Mui.Button>
                
                    <Mui.Button
                      // startIcon={<MuiIcons.AddCircle />}
                      startIcon={<i className="icon-etc-add" />}
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="/Activate"
                    >
                      <ReactI18next.Trans i18nKey="Assign license">
                        Assign license
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
                <Containers.ShowIfAuthorised roles={["EU"]}>
                  <Mui.Box>
                    <Mui.Button
                      color="primary"
                      component={Router.Link}
                      to="activate"
                    >
                      <ReactI18next.Trans i18nKey="Activate License">
                        Activate License
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
                <Containers.ShowIfAuthorised roles={["customer"]}>
                  <Mui.Box>
                    <Mui.Button
                      startIcon={<i className="icon-etc-add" />}
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="/Activate"
                    >
                      <ReactI18next.Trans i18nKey="Add licenses">
                      Add licenses
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/licenses/activate"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Containers.ShowIfAuthorised roles={["customer", "EU"]}>
                    <Mui.Breadcrumbs aria-label="breadcrumb">
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/"
                      >
                        <ReactI18next.Trans i18nKey="Home">
                         Home
                        </ReactI18next.Trans>
                      </Mui.Link>

                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses"
                        end
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Licenses">
                          Licenses
                        </ReactI18next.Trans>
                      </Mui.Link>

                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses/activate"
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Activate">
                          Activate
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/licenses/activate/success"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Containers.ShowIfAuthorised roles={["customer", "EU"]}>
                    <Mui.Breadcrumbs aria-label="breadcrumb">
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/"
                      >
                        <ReactI18next.Trans i18nKey="Home">
                         Home
                        </ReactI18next.Trans>
                      </Mui.Link>

                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses"
                        end
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Licenses">
                          Licenses
                        </ReactI18next.Trans>
                      </Mui.Link>

                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses/activate"
                        end
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Activate">
                          Activate
                        </ReactI18next.Trans>
                      </Mui.Link>

                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/licenses/activate/success"
                        end
                        activeStyle={{ color: theme.palette.primary.main }}
                      >
                        <ReactI18next.Trans i18nKey="Success">
                          Success
                        </ReactI18next.Trans>
                      </Mui.Link>
                    </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/licenses/manual"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses"
                    >
                      <ReactI18next.Trans i18nKey="Licenses">
                        Licenses
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses/manual"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Manual">
                        Manual
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Containers.ShowIfAuthorised roles={["superadmin","admin","customer"]}>
                  <Mui.Box>
                    <Mui.Button
                      startIcon={<i className="icon-etc-add" />}
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="/licenses/generate"
                    >
                      <ReactI18next.Trans i18nKey="Generate New">
                        Generate New
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/licenses/generate"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Licenses'>
                    Licenses
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses"
                    >
                      <ReactI18next.Trans i18nKey="Licenses">
                        Licenses
                      </ReactI18next.Trans>
                    </Mui.Link>

                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses/manual"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Manual">
                        Manual
                      </ReactI18next.Trans>
                    </Mui.Link>

                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses/generate"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Generate">
                        Generate
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/licenses/generate/success"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses"
                    >
                      <ReactI18next.Trans i18nKey="Licenses">
                        Licenses
                      </ReactI18next.Trans>
                    </Mui.Link>

                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses/manual"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Manual">
                        Manual
                      </ReactI18next.Trans>
                    </Mui.Link>

                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses/generate"
                    >
                      <ReactI18next.Trans i18nKey="Generate">
                        Generate
                      </ReactI18next.Trans>
                    </Mui.Link>

                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/licenses/generate/success"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Success">
                        Success
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/easy-touch-devices"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Easy touch devices'>
                    Easy touch devices
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/easy-touch-devices"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Terminal devices">
                        Terminal devices
                      </ReactI18next.Trans>
                    </Mui.Link>
                   
                  </Mui.Breadcrumbs>
                </Mui.Box>
                <Containers.ShowIfAuthorised roles={["customer"]}>
                  <Mui.Box>
                    <Mui.Button
                      startIcon={<i className="icon-etc-add" />}
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="/easy-touch-devices/generate"
                    >
                      <ReactI18next.Trans i18nKey="Add terminal device">
                      Add terminal device
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/easy-touch-devices/generate"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Easy touch devices'>
                    Easy touch devices
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/easy-touch-devices"
                     // activeStyle={{ color: theme.palette.text }}
                    >
                      <ReactI18next.Trans i18nKey="Terminal devices">
                        Terminal devices
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/easy-touch-devices/generate"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Terminal devices">
                        Add terminal device
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
               
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/icons"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Icons'>Icons</ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/icons"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Icons">
                        Icons
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box>
                  <Mui.Button
                    // startIcon={<MuiIcons.AddCircle />}
                    startIcon={<i className="icon-etc-add" />}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      iconStore.setUploadDialogOpen({ uploadDialogOpen: true })
                    }
                  >
                    <ReactI18next.Trans i18nKey="UPLOAD ICONS">
                      Upload Icons
                    </ReactI18next.Trans>
                  </Mui.Button>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/device-configuration-upload"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/device-configuration-upload"
                      end
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Device configuration upload">
                        Device configuration upload
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/customers"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  {/* <Mui.Typography
                  style={{ marginRight: theme.spacing(2) }}
                  component='span'
                  variant='h5'
                  gutterBottom
                >
                  <ReactI18next.Trans i18nKey='Customers'>
                    Customers
                  </ReactI18next.Trans>
                </Mui.Typography> */}
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/customers"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Customers">
                        Customers
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/tenants"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/tenants"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Tenants">
                        Tenants
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Box>
                    <Mui.Button
                      // startIcon={<MuiIcons.AddCircle />}
                      startIcon={<i className="icon-etc-add" />}
                      variant="contained"
                      color="primary"
                      component={Router.Link}
                      to="/tenants/create"
                    >
                      <ReactI18next.Trans i18nKey="Create New">
                        Create New
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Containers.ShowIfAuthorised>
              </Mui.Box>
            </>
          }
        />

        <Router.Route
          path="/tenants/create"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/tenants"
                    >
                      <ReactI18next.Trans i18nKey="Tenants">
                        Tenants
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/tenants/create"
                      activeStyle={{ color: theme.palette.primary.main }}
                    >
                      <ReactI18next.Trans i18nKey="Create">
                        Create
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/user-management"
          element={
            <>
              <Mui.Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={48}
              >
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/user-management"
                    >
                      <ReactI18next.Trans i18nKey="User management">
                        User management
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/user-management/adddealer"
          element={
            <>
              <Mui.Box display="flex" width={1} height={48}>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/user-management"
                    >
                      <ReactI18next.Trans i18nKey="User management">
                        User management
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/user-management/addcustomer"
                    >
                      <ReactI18next.Trans i18nKey="ADD DEALER">
                          Add Dealer
                        </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/customerinvite"
          element={
            <>
              <Mui.Box display="flex" width={1} height={48}>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dealercustomer/"
                    >
                      <ReactI18next.Trans i18nKey="Customer">
                        Customer
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/customerinvite"
                    >
                      <ReactI18next.Trans i18nKey="customerinvite">
                        Customer invite
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/customerinvitesummary"
          element={
            <>
              <Mui.Box display="flex" width={1} height={48}>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dealercustomer/customerinvitesummary"
                    >
                      <ReactI18next.Trans i18nKey="dealercustomer">
                        Customer
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/addcustomer"
                    >
                      <ReactI18next.Trans i18nKey="customerinvitesummary">
                        Customer invite summary
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/addcustomer"
          element={
            <>
              <Mui.Box display="flex" width={1} height={48}>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dealercustomer/"
                    >
                      <ReactI18next.Trans i18nKey="Customer">
                        Customer
                      </ReactI18next.Trans>
                    </Mui.Link>
                    {/* <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dealercustomer/customerinvite"
                    >
                      <ReactI18next.Trans i18nKey="dealercustomer/customerinvite">
                        Customer Invite
                      </ReactI18next.Trans>
                    </Mui.Link> */}
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/addcustomer"
                    >
                      <ReactI18next.Trans i18nKey="Assign license">
                      Assign license
                        </ReactI18next.Trans>
                      
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/importdealer"
          element={
            <>
              <Mui.Box display="flex" width={1} height={48}>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dealercustomer/managedealer/"
                    >
                      <ReactI18next.Trans i18nKey="Manage dealers">
                        Manage dealers
                      </ReactI18next.Trans>
                    </Mui.Link>

                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/importdealer"
                    >
                      <ReactI18next.Trans i18nKey="IMPORT DEALER">
                        Import dealer
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/adddealeruser"
          element={
            <>
              <Mui.Box display="flex" width={1} height={48}>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    {(userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin") && (
                      <Mui.Link
                        color="inherit"
                        component={Router.NavLink}
                        to="/dealercustomer/managedealer/"
                      >
                        <ReactI18next.Trans i18nKey="Manage dealers">
                          Manage dealers
                        </ReactI18next.Trans>
                      </Mui.Link>
                    )}
                    {(userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin") && hideBreadcrumb && (
                      <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                        <Mui.Link
                          color="inherit"
                          component={Router.NavLink}
                          to="/dealercustomer/importdealer"
                        >
                          <ReactI18next.Trans i18nKey="IMPORT DEALER">
                            Import dealer
                          </ReactI18next.Trans>
                        </Mui.Link>
                      </Containers.ShowIfAuthorised>
                    )}
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to={`/dealercustomer/adddealeruser?${params}`}
                    >
                      <ReactI18next.Trans i18nKey="Dealer users">
                      Dealer users
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/managedealer"
          element={
            <>
              <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
                height={48}
              >
                <Mui.Box flexGrow={1} display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/managedealer/"
                    >
                      <ReactI18next.Trans i18nKey="Manage dealers">
                        Manage dealers
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                
                <Mui.Box display="flex" alignItems="baseline">
               

                  <Mui.Box>
                    <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                      <Mui.Box>
                        <Mui.Button
                          variant="contained"
                          color="primary"
                          component={Router.Link}
                          to="/dealercustomer/importdealer"
                        >
                          <ReactI18next.Trans i18nKey="IMPORT DEALER">
                            Import dealer
                          </ReactI18next.Trans>
                        </Mui.Button>
                      </Mui.Box>
                    </Containers.ShowIfAuthorised>
                  </Mui.Box>

                  <Mui.Box>
                    

                    <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Card view">
Card view
</ReactI18next.Trans>} placement="top">
                      <Mui.Box display="inline-block" color="divider">
                        <Mui.IconButton
                          color={
                            manageStore.view === "CARD" ? "default" : "inherit"
                          }
                          onClick={() => manageStore.setView({ view: "CARD" })}
                          style={{ marginRight: theme.spacing(1) }}
                        >
                          <i className="icon-etc-card-view" />
                        </Mui.IconButton>
                      </Mui.Box>
                    </Mui.Tooltip>

                    <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Tabular view">
Tabular view
</ReactI18next.Trans>} placement="top">
                      <Mui.Box display="inline-block" color="divider">
                        <Mui.IconButton
                          color={
                            manageStore.view === "TABULAR"
                              ? "default"
                              : "inherit"
                          }
                          onClick={() =>
                            manageStore.setView({ view: "TABULAR" })
                          }
                        >
                          <i className="icon-etc-table-view" />
                        </Mui.IconButton>
                      </Mui.Box>
                    </Mui.Tooltip>
                  </Mui.Box>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        <Router.Route
          path="/dealercustomer/manageadmin"
          element={
            <>
              <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
                height={48}
              >
                <Mui.Box flexGrow={1} display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/manageadmin/"
                    >
                      <ReactI18next.Trans i18nKey="Manage admins">
                        Manage admins
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Box>
                    <Containers.ShowIfAuthorised roles={["dealer"]}>
                      <Mui.Box>
                        <Mui.Button
                          variant="contained"
                          color="primary"
                          component={Router.Link}
                          to="addcustomer"
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          <ReactI18next.Trans i18nKey="ADD CUSTOMER">
                          Add customer
                      </ReactI18next.Trans>
                        </Mui.Button>
                      </Mui.Box>
                    </Containers.ShowIfAuthorised>
                  </Mui.Box>
                </Mui.Box>
                <Mui.Box display="flex" alignItems="baseline">
                 

             

                  <Mui.Box>
                 
                  </Mui.Box>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
        
        <Router.Route
          path="/dealercustomer/"
          element={
            <>
              <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
                height={48}
              >
                <Mui.Box flexGrow={1} display="flex" alignItems="baseline">
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/"
                    >
                      <ReactI18next.Trans>Manage customers</ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                </Mui.Box>

                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Box>
                    <Containers.ShowIfAuthorised roles={["dealer"]}>
                      <Mui.Box>
                        <Mui.Button
                          variant="contained"
                          color="primary"
                          component={Router.Link}
                          to="customerinvite"
                          onClick={() => {
                            customerInviteStore.resetInviteSummary();
                            cartStore.resetCart();
                            let newSelected: number[] = [];
                            customerInviteStore.setSelected({
                              selected: newSelected,
                            });
                            localStorage.setItem("DealerBasicSetValidation","false");
                          }}
                        >
                          <ReactI18next.Trans i18nKey="ADD CUSTOMER">
                          Add customer
                      </ReactI18next.Trans>
                        </Mui.Button>
                      </Mui.Box>
                    </Containers.ShowIfAuthorised>
                  </Mui.Box>
                </Mui.Box>
                <Mui.Box display="flex" alignItems="baseline">
                  <Mui.Box>
                    
                  </Mui.Box>

                  

                  <Mui.Box>
                 

                    <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Card view">
Card view
</ReactI18next.Trans>} placement="top">
                      <Mui.Box display="inline-block" color="divider">
                        <Mui.IconButton
                          color={
                            customerStore.view === "CARD"
                              ? "default"
                              : "inherit"
                          }
                          onClick={() =>
                            customerStore.setView({ view: "CARD" })
                          }
                          style={{ marginRight: theme.spacing(1) }}
                        >
                          <i className="icon-etc-card-view" />
                          {/* <MuiIcons.Dashboard /> */}
                        </Mui.IconButton>
                      </Mui.Box>
                    </Mui.Tooltip>

                    <Mui.Tooltip title={
<ReactI18next.Trans i18nKey="Tabular view">
Tabular view
</ReactI18next.Trans>} placement="top">
                      <Mui.Box display="inline-block" color="divider">
                        <Mui.IconButton
                          color={
                            customerStore.view === "TABULAR"
                              ? "default"
                              : "inherit"
                          }
                          onClick={() =>
                            customerStore.setView({ view: "TABULAR" })
                          }
                        >
                          <i className="icon-etc-table-view" />
                          {/* <MuiIcons.TableChart /> */}
                        </Mui.IconButton>
                      </Mui.Box>
                    </Mui.Tooltip>
                  </Mui.Box>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
         <Router.Route
          path="/dealercustomer/customerdetail"
          element={
            <>
              <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
                height={48}
              >
                <Mui.Box flexGrow={1} display="flex" alignItems="baseline">
                <Containers.ShowIfAuthorised roles={["dealer","admin","superadmin"]}>
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      to="/dealercustomer/"
                    >
                      <ReactI18next.Trans>Manage customers</ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                     to="/dealercustomer/customerdetail"
                    >
                      <ReactI18next.Trans>Customer details</ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                  <Containers.ShowIfAuthorised roles={["customer"]}>
                  <Mui.Breadcrumbs aria-label="breadcrumb">
                    <Mui.Link color="inherit" component={Router.NavLink} to="/">
                      <ReactI18next.Trans i18nKey="Home">
                       Home
                      </ReactI18next.Trans>
                    </Mui.Link>
                    <Mui.Link
                      color="inherit"
                      component={Router.NavLink}
                      activeStyle={{ color: theme.palette.primary.main }}
                      to="/dealercustomer/customerdetail"
                    >
                      <ReactI18next.Trans>Customer detail</ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.Breadcrumbs>
                  </Containers.ShowIfAuthorised>
                </Mui.Box>
              </Mui.Box>
            </>
          }
        />
      </Router.Routes>
    </div>
  );
});
