/** @format */

import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Hooks from "src/app/hooks";
import * as Configs from "src/configs";
import * as Models from "src/models";

export declare namespace Form {
  export interface Props {
    availableArticles: Models.Set.Article[];
  }

  export interface Values {
    macId: string;
    customerName?: string;
    remarks?: string;
    eT_version?: number;
    deviceName?: string;
    location?: string;
    name?:string;
    licenseType?:number;
    generatedBy?:number;
  }
}

function CustomTextField({
  availableArticles,
  ...props
}: FormikMui.TextFieldProps & Form.Props) {
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const [searchParams] = Router.useSearchParams();
  const forceUpdate = Hooks.useForceUpdate();

  const {
    // form: { setFieldTouched, setFieldValue },
    form: { setFieldTouched },
    field: { name },
  } = props;

  const onBlur = React.useCallback(
    async (event) => {
      const sets = searchParams.getAll("set");

      if (sets.length) {
        return;
      }

      const { value } = event.target;
      setFieldTouched(name);
      if (value) {
        const result = await generateLicenseStore.fetchWithMachineId({
          machineId: value,
          availableArticles,
        });


        (function () {
          result?.license?.sets.forEach((set) => {
            const article = result?.availableArticlesMap.get(set);

            if (!article) {
              return;
            }

            if (
              Configs.Workaround.isCloudArticle({
                code: article.hundCode,
              }) ||
              Configs.Workaround.isCloudArticle({ code: article.setCode })
            ) {
              generateLicenseStore.setCloudArticle({ cloudArticle: article });
            }
          });
        })();

        (function () {
          if (result?.license?.tenantId) {
            const tenantId = result?.license.tenantId;

            const tenant = tenantStore.tenants.find(
              (tenant) => tenant.id === tenantId
            );

            if (tenant) {
              generateLicenseStore.setSelectedTenant({
                selectedTenant: tenant,
              });
              forceUpdate();
              return;
            }

            tenantStore.read().then(() => {
              const tenant = tenantStore.tenants.find(
                (tenant) => tenant.id === tenantId
              );

              generateLicenseStore.setSelectedTenant({
                selectedTenant: tenant,
              });
              forceUpdate();
            });
          }
        })();
      }
    },
    [
      availableArticles,
      forceUpdate,
      generateLicenseStore,
      name,
      searchParams,
      setFieldTouched,
      tenantStore,
    ]
  );

  return (
    <Mui.TextField {...FormikMui.fieldToTextField(props)} onBlur={onBlur} />
  );
}
const CustomizedSelectForLicenseType = ({
  children,
  form,
  field,
}: {
  children: any;
  form: any;
  field: any;
  industrySegment:Models.IndustrySegment.Main[]
  isVersion:boolean;
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const [values, setValues] = React.useState<any>(3);
  return (
    <Mui.Select
      name={name}
      value={values}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
        setValues(e.target.value);
        console.log("set",e.target.value);
      }}
      placeholder={name}
      fullWidth
    >
      {children}
    </Mui.Select>
  );
};

const CustomizedSelectForFormik = ({
  children,
  form,
  field,
  industrySegment,
  isVersion
}: {
  children: any;
  form: any;
  field: any;
  industrySegment:Models.IndustrySegment.Main[]
  isVersion:boolean;
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const [values, setValues] = React.useState<any>(2);
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  return (
    <Mui.Select
      name={name}
      value={values}
      onChange={(e) => {
        if(!isVersion){
          generateLicenseStore.setIndustrySegmentID(e.target.value);
          const industryID : any = e.target.value;
          localStorage.setItem("industryID", industryID);
        }
       
        setFieldValue(name, e.target.value);
        setValues(e.target.value);
        console.log("set",e.target.value);
      }}
      placeholder={name}
      fullWidth
    >
      {children}
    </Mui.Select>
  );
};
// eslint-disable-next-line no-redeclare
export function Form({ availableArticles }: Form.Props) {
  const { t } = ReactI18next.useTranslation();
  const industrySegmentStore = React.useContext(
    App.Contexts.industrySegmentStore
  );
  React.useEffect(() => {
    industrySegmentStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const [showVersion, setShowVersion] = React.useState(false);
  console.log(false);

  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  React.useEffect(() => {
    const localStore = localStorage.getItem("industryID");
    console.log("localStore",localStore)
    const industrySegmentID = industrySegmentStore.filtered.find(k => k.name === 'EasyTouch')?.industrySegmentsId;

    setShowVersion(!!(industrySegmentID && industrySegmentID.toString() === localStore));   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateLicenseStore.industrySegmentId]);
  return (
    <>
      <Formik.Form>
        <Mui.Grid container spacing={4}>
        <Mui.Grid item xs={12}>
            <Formik.Field
              name="licenseType"
              component={CustomizedSelectForLicenseType}
              label="Select the license type"
              isVersion = {true}
            >
              <Mui.MenuItem value={0} disabled>
              Select the license type
              </Mui.MenuItem>
              <Mui.MenuItem value={2}>Internal license</Mui.MenuItem>
              <Mui.MenuItem value={3}>Demo license</Mui.MenuItem>
            </Formik.Field>
          </Mui.Grid>
        <Mui.Grid item xs={12} >
            <Formik.Field
              name="name"
              component={CustomizedSelectForFormik}
              label="Select the Industry/Segments"
              industrySegment = {industrySegmentStore}
              isVersion = {false}
            >
              <Mui.MenuItem value={0} disabled>
                Select the Industry/Segments
              </Mui.MenuItem>
             { industrySegmentStore?.filtered.map((k:any,index) =>
             (
              <Mui.MenuItem key = {k.industrySegmentsId} value={k.industrySegmentsId}>{k.name}</Mui.MenuItem>
             ))}
            </Formik.Field>
         </Mui.Grid>
          {showVersion && <Mui.Grid item xs={12}>
            <Formik.Field
              name="eT_version"
              component={CustomizedSelectForFormik}
              label="Version"
              isVersion = {true}
            >
              <Mui.MenuItem value={0} disabled>
                Select the version
              </Mui.MenuItem>
              <Mui.MenuItem value={1}>ET-V1</Mui.MenuItem>
              <Mui.MenuItem value={2}>ET-V2</Mui.MenuItem>
            </Formik.Field>
          </Mui.Grid>}

          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="macId"
              component={CustomTextField}
              availableArticles={availableArticles}
              label={t("Machine ID")}
              fullWidth
            ></Formik.Field>
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="deviceName"
              component={FormikMui.TextField}
              label={t("Device name")}
              fullWidth
            ></Formik.Field>
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="customerName"
              component={FormikMui.TextField}
              label={t("Customer name")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="remarks"
              component={FormikMui.TextField}
              label={t("Remarks")}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
