import * as React from "react";
import * as Router from "react-router-dom";

export function Main() {
  return (
    <>
      <Router.Navigate to="/dashboard" />
    </>
  );
}
