import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace DeviceConfigurationUpload {
  export interface IDeviceConfigurationUpload {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = DeviceConfigurationUpload.IDeviceConfigurationUpload;

  export namespace Upload {
    export type Body = {
      DynamicDataList: any[];
    };

    export interface Props {
      body: DeviceConfigurationUpload.Upload.Body;
    }

    export type Return = any;
  }
}

// eslint-disable-next-line no-redeclare
export class DeviceConfigurationUpload {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: DeviceConfigurationUpload.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async upload({
    body,
  }: DeviceConfigurationUpload.Upload.Props): Promise<
    DeviceConfigurationUpload.Upload.Return
  > {
    const url = this.urlFactory.create({
      named: ["ScaleInfo", "Upload"],
    });

    const response = await this.httpManager.post<
      DeviceConfigurationUpload.Upload.Return,
      DeviceConfigurationUpload.Upload.Body
    >({
      url,
      body,
    });

    return response;
  }

  public async download() {
    const url = this.urlFactory.create({
      named: ["ScaleInfo", "Download"],
    });

    const response = await this.httpManager.get<Models.API.Response<any[]>>({
      url,
    });

    return response;
  }
}
