import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Function from "src/app/pages/function";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
  }),
);

export declare namespace Edit {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  > & {
    func?: Models.Set.Function;
  };
}

// eslint-disable-next-line no-redeclare
export const Edit = Mobx.observer(function ({
  open,
  handleClose,
  func,
}: Edit.Props) {
  const theme = Mui.useTheme();
  // const { i18n } = ReactI18next.useTranslation();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const classes = useStyles();
  const { t } = ReactI18next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);
  const [initialValues, setInitialValues] = React.useState<
    Function.Form.Values
  >();
  const functionService = new Services.Function({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const functionStore = React.useContext(App.Contexts.functionStore);

  React.useEffect(() => {
    if (!func) {
      setInitialValues(undefined);
      return;
    }

    const initialValues: Function.Form.Values = {
      iconClass: func?.iconClass,
      funcationCode: func?.funcationCode,
      funcationName: func?.funcationName,
    };

    setInitialValues(initialValues);
  }, [func]);

  async function handleEdit(
    values: Function.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Function.Form.Values>,
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      if (!func) {
        throw new Error(t("Func is undefined"));
      }

      const iconClass = ((values.iconClass as unknown) as Function.Form.Options.Icon)
        .iconName;
      const updatedFunc: Models.Set.Function = {
        ...func,
        iconClass,
        funcationCode: values.funcationCode,
        funcationName: values.funcationName,
      };
      const res = await functionService.create({ body: updatedFunc, uid });
      const result: any = res?.result;
      //await functionService.create({ body: updatedFunc, uid });
      if(result == 2)
      {
      enqueueSnackbar(t("Function successfully updated!"), {
        variant: "success",
        preventDuplicate: false,
      });
    }
    if(result == 3)
      {
      enqueueSnackbar(t("Function code already exist!"), {
        variant: "warning",
        preventDuplicate: false,
      });
    }
      functionStore.read();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }

  return (
    <Components.LightBox
      open={open}
      handleClose={handleClose}
      variant={"RESPECT_ONLY_CONTENT_AND_IGNORE_COMPONENT_STYLES"}
      content={
        <>
          {initialValues && (
            <Formik.Formik
              enableReinitialize
              initialValues={initialValues || ({} as Function.Form.Values)}
              validationSchema={Function.Shared({ t }).Form.validationSchema}
              onSubmit={handleEdit}
            >
              {({ isSubmitting, submitForm }) => {
             
                return(
                  <>
                  <Mui.Box padding={3}>
                    <Function.Form />
                  </Mui.Box>
                  <div
                    className={classes.toolbar}
                    style={{ marginBottom: 12 }}
                  />
                  <Mui.Box
                    position="fixed"
                    bottom={0}
                    width={1}
                    maxWidth={theme.breakpoints.values.md}
                    style={{
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Mui.Divider />
                    <Mui.DialogActions>
                      <>
                        <Mui.Button variant="contained" onClick={handleClose}>
                          <ReactI18next.Trans i18nKey="CLOSE">
                            CLOSE
                          </ReactI18next.Trans>
                        </Mui.Button>
                        <Mui.Button
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={() => submitForm()}
                        >
                          <ReactI18next.Trans i18nKey="Update">
                            Update
                          </ReactI18next.Trans>
                          {isSubmitting && (
                            <Mui.CircularProgress
                              style={{
                                marginLeft: theme.spacing(1),
                              }}
                              color={"inherit"}
                              size={theme.typography.button.fontSize}
                            />
                          )}
                        </Mui.Button>
                      </>
                    </Mui.DialogActions>
                  </Mui.Box>
                </>
                )
              }
               
              }
            </Formik.Formik>
          )}
        </>
      }
      title={
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          {t("Edit function")}
        </Mui.Typography>
      }
    />
  );
});
