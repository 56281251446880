import * as Mobx from "mobx";
import * as Article from "src/app/pages/article";
import * as License from "src/app/pages/license";
import * as Models from "src/models";
import * as Services from "src/services";

export class Store {
  @Mobx.observable public search: string = "";
  @Mobx.observable public selected: number[] = [];
  @Mobx.observable public installed: number[] = [];
  @Mobx.observable public isLoadingPreviouslyInstalledArticles: boolean = false;
  @Mobx.observable public activated: Pick<
    Services.License.Create.Result,
    "licenseKey"
  > & {
    sets: Models.Set.Article[];
  } = {
    licenseKey: "",
    sets: [],
  };

  private licenseStore: License.Store;
  private articleStore: Article.Store;

  @Mobx.action public reset() {
    this.search = "";
    this.selected = [];
    this.installed = [];
    this.isLoadingPreviouslyInstalledArticles = false;
    this.activated = {
      licenseKey: "",
      sets: [],
    };
  }

  @Mobx.action public setActivated({
    activated,
  }: {
    activated: Pick<Services.License.Create.Result, "licenseKey"> & {
      sets: Models.Set.Article[];
    };
  }) {
    this.activated = activated;
  }

  @Mobx.action public setSearch({ search }: { search: string }) {
    this.search = search;
  }

  @Mobx.action public setIsLoadingPreviouslyInstalledArticles({
    isLoadingPreviouslyInstalledArticles,
  }: {
    isLoadingPreviouslyInstalledArticles: boolean;
  }) {
    this.isLoadingPreviouslyInstalledArticles = isLoadingPreviouslyInstalledArticles;
  }


  
  @Mobx.action public setSelected({ selected }: { selected: number[] }) {
    this.selected = selected;
  }

  @Mobx.action public setInstalled({ installed }: { installed: number[] }) {
    this.installed = installed;
  }

  constructor({
    licenseStore,
    articleStore,
  }: {
    licenseStore: License.Store;
    articleStore: Article.Store;
  }) {
    this.licenseStore = licenseStore;
    this.articleStore = articleStore;
  }

  @Mobx.computed get getArticlesFromLicenses(): Models.Set.Article[] {
    if (!this.licenseStore.licenses?.length) return [];

    return this.licenseStore.licenses
      .filter((license: any) => license.status === 1)
      .map((license: any) => license.setList)
      .filter((article) => article);
  }

  @Mobx.computed get getAvailableArticlesFromLicenses(): Models.Set.Article[] {
    return this.getArticlesFromLicenses.filter(
      (article) => !this.installed.includes(article.setID)
    );
  }

  @Mobx.computed get getInstalledArticlesFromLicenses(): Models.Set.Article[] {
    return this.getArticlesFromLicenses.filter((article) =>
      this.installed.includes(article.setID)
    );
  }

  @Mobx.computed get getFilteredAvailableArticles(): Models.Set.Article[] {
    if (!this.search) return this.getAvailableArticlesFromLicenses;

    return this.getAvailableArticlesFromLicenses.filter(
      (article) =>
        (article.setName &&
          article.setName.toLowerCase().includes(this.search.toLowerCase())) ||
        (article.setCode &&
          article.setCode.toLowerCase().includes(this.search.toLowerCase())) ||
        (article.hundCode &&
          article.hundCode.toLowerCase().includes(this.search.toLowerCase()))
    );
  }

  @Mobx.computed
  public get getFilteredInstalledArticles(): Models.Set.Article[] {
    if (!this.search) return this.getInstalledArticlesFromLicenses;

    return this.getInstalledArticlesFromLicenses
      .filter(
        (article) =>
          (article.setName &&
            article.setName
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (article.setCode &&
            article.setCode
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (article.hundCode &&
            article.hundCode.toLowerCase().includes(this.search.toLowerCase()))
      )
      .filter((article) => this.installed.includes(article.setID));
  }

  @Mobx.computed public get dependentArticles(): Set<string> {
    return ([...this.selected, ...this.installed]
      .map((setID) =>
        this.getAvailableArticlesFromLicenses.find(
          (article) => article.setID === setID
        )
      )
      .filter((article) => article) as Models.Set.Article[]).reduce(
      (acc: Set<string>, article: Models.Set.Article) => {
        article.dependentSets.forEach((setID) => {
          if ([...this.selected, ...this.installed].includes(setID)) {
            return;
          }
          acc.add(
            this.articleStore.articles
              ?.filter((article) => article.status === 1)
              .find((article) => article.setID === setID)?.hundCode ??
              `Article ID: ${setID}` // TODO: remove this after removing the duplicate article
          );
        });
        return acc;
      },
      new Set()
    );
  }

  @Mobx.computed public get isDependentArticlesValid(): boolean {
    return ([...this.selected, ...this.installed]
      .map((setID) =>
        this.getAvailableArticlesFromLicenses.find(
          (article) => article.setID === setID
        )
      )
      .filter((article) => article) as Models.Set.Article[]).reduce(
      (acc: boolean, article: Models.Set.Article) => {
        if (!acc) {
          return acc;
        }
        return article.dependentSets.reduce((acc, setID) => {
          if (!acc) {
            return acc;
          }
          if ([...this.selected, ...this.installed].includes(setID)) {
            return true;
          }
          return false;
        }, true);
      },
      true
    );
  }
}
