import * as React from "react";
import * as Router from "react-router-dom";
import * as Components from "src/app/components";
import * as Routes from "src/app/routes";

export function Payment() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["customer","dealer"]}
          path="failed"
          element={<Components.Redirect to="/shop/cart?status=failure" />}
        />
        <Routes.Route
          restricted
          roles={["customer","dealer"]}
          path="cancel"
          element={<Components.Redirect to="/shop/cart?status=cancelled" />}
        />
        <Routes.Route
          restricted
          roles={["customer","dealer"]}
          path="process"
          element={<Process />}
        />
      </Router.Routes>
    </>
  );
}

function Process() {
  const [searchParams] = Router.useSearchParams();
  const navigate = Router.useNavigate();

  React.useEffect(() => {
    (function () {
      const orderId = searchParams.get("q");
      if (orderId) {
        navigate(`/shop/payment/process?q=${orderId}`);
        return;
      }
      navigate("/shop/cart?status=failure");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
