import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace UserManagement {
  export interface IUserManagement {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = UserManagement.IUserManagement;

  export namespace GetAllData {
    export type Return = Models.API.Response<Models.UserManagement.Main[]>;
  }
  export namespace GetRole {
    export type Return = Models.API.Response<Models.Role.Main[]>;
  }
  export namespace updateRole {
    export interface Body {
      RoleId: any;
      ETUID: any;
    }
    export interface Props {
      body: UserManagement.updateRole.Body;
    }
    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class UserManagement {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: UserManagement.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  // eslint-disable-next-line no-empty-pattern
  public async getAllData(): Promise<UserManagement.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Users", "GetAll"],
    });

    const response = await this.httpManager.get<
      UserManagement.GetAllData.Return
    >({
      url,
    });

    return response;
  }
  public async getRoles(): Promise<UserManagement.GetRole.Return> {
    const url = this.urlFactory.create({
      named: ["Users", "GetRoles"],
    });

    const response = await this.httpManager.get<UserManagement.GetRole.Return>({
      url,
    });

    return response;
  }
  public async updateRole({
    body,
  }: UserManagement.updateRole.Props): Promise<
    UserManagement.updateRole.Return
  > {
    const url = this.urlFactory.create({
      named: ["Users", "AssignRole"],
    });

    const response = await this.httpManager.post<
      UserManagement.updateRole.Return,
      UserManagement.updateRole.Body
    >({
      body,
      url,
    });

    return response;
  }
}
