import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    userManagement: Models.UserManagement.Main[];
    userManagementService: Services.UserManagement;
  }

  export type Options = {
    userManagementService: Services.UserManagement;
  };

  export namespace Read {
    export interface Props extends Services.UserManagement.GetAllData.Return {}
  }
  export namespace ReadRoles {
    export interface Props extends Services.UserManagement.GetRole.Return {}
  }
  export namespace UpdateRoles {
    export interface Props extends Services.UserManagement.updateRole.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public userManagement: Models.UserManagement.Main[] = [];
  @Mobx.observable public userRoles: Models.Role.Main[] = [];

  @Mobx.observable public isLoading: boolean = false;
  public userManagementService: Services.UserManagement;

  constructor({ userManagementService }: Store.Options) {
    this.userManagementService = userManagementService;
  }

  @Mobx.action public async readUsers() {
    try {
      this.isLoading = true;
      const { result } = await this.userManagementService.getAllData();
      this.userManagement = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
  @Mobx.action public async readRoles() {
    try {
      this.isLoading = true;
      const { result } = await this.userManagementService.getRoles();
      this.userRoles = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async updateRole(props: Store.UpdateRoles.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.userManagementService.updateRole(props);
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.userManagement.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
