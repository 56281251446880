import * as Mobx from "mobx";
import * as Article from "src/app/pages/article";
import * as License from "src/app/pages/license";
import * as Models from "src/models";
import * as Services from "src/services";
export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    customerInvite: Models.InviteCustomer.Main[];
    invitecustomerService: Services.InviteCustomerService;
    functions: Models.Set.Function[];
  }
  export type Options = {
    invitecustomerService: Services.InviteCustomerService;
    licenseService: Services.License;
    licenseStore: License.Store;
    articleStore: Article.Store;
  };
  export namespace InviteCustomer {
    export interface Props
      extends Services.InviteCustomerService.InviteCustomer.Props {}
  }
  export namespace Read {
    export interface Props extends Services.License.GetAllData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public functions: Models.Set.Function[] = [];
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public search: string = "";
  @Mobx.observable public customerInvite: Models.InviteCustomer.Main[] = [];
  @Mobx.observable public selected: number[] = [];
  @Mobx.observable public installed: number[] = [];
  @Mobx.observable public isLoadingPreviouslyInstalledArticles: boolean = false;
  @Mobx.observable public activated: Pick<
    Services.License.Create.Result,
    "licenseKey"
  > & {
    sets: Models.Set.Article[];
  } = {
    licenseKey: "",
    sets: [],
  };
  public invitecustomerService: Services.InviteCustomerService;
  private licenseService: Services.License;
  articleStore: Article.Store;
  @Mobx.observable public licenses?: Models.License[];
  licenseStore: License.Store;
  constructor({
    invitecustomerService,
    licenseService,
    articleStore,
    licenseStore,
  }: Store.Options) {
    this.invitecustomerService = invitecustomerService;
    this.licenseService = licenseService;
    this.articleStore = articleStore;
    this.licenseStore = licenseStore;
  }

  @Mobx.action public async readFunction(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.invitecustomerService.getAllFuncationData(props);

    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async readSetFuncations(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.invitecustomerService.getAllSetFuncationsData(props);

      this.functions = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public setActivated({
    activated,
  }: {
    activated: Pick<Services.License.Create.Result, "licenseKey"> & {
      sets: Models.Set.Article[];
    };
  }) {
    this.activated = activated;
  }

  @Mobx.action public setSearch({ search }: { search: string }) {
    this.search = search;
  }
  @Mobx.action public async inviteCustomer(props: Store.InviteCustomer.Props) {
    try {
      const { result } = await this.invitecustomerService.inviteCustomer(props);
      this.invitecustomerService = result;
    } catch (error) {
      console.error(error);
    }
  }
  @Mobx.computed get getArticlesFromLicenses(): Models.Set.Article[] {
    if (!this.licenseStore.licenses?.length) return [];
    return this.licenseStore.licenses
      .filter((license: any) => license.status === 1)
      .map((license: any) => license.setList)
      .filter((article) => article);
  }
  @Mobx.computed get getInstalledArticlesFromLicenses(): Models.Set.Article[] {
    return this.getArticlesFromLicenses.filter((article) =>
      this.installed.includes(article.setID)
    );
  }

  @Mobx.action public setSelected({ selected }: { selected: number[] }) {
    this.selected = selected;
  }
  @Mobx.action public setInstalled({ installed }: { installed: number[] }) {
    this.installed = installed;
  }
  @Mobx.action public setIsLoadingPreviouslyInstalledArticles({
    isLoadingPreviouslyInstalledArticles,
  }: {
    isLoadingPreviouslyInstalledArticles: boolean;
  }) {
    this.isLoadingPreviouslyInstalledArticles = isLoadingPreviouslyInstalledArticles;
  }
  @Mobx.computed get getAvailableArticlesFromLicenses(): Models.Set.Article[] {
    return this.getArticlesFromLicenses.filter(
      (article) => !this.installed.includes(article.setID)
    );
  }
  @Mobx.computed get getFilteredAvailableArticles(): Models.Set.Article[] {
    if (!this.search) return this.getAvailableArticlesFromLicenses;

    return this.getAvailableArticlesFromLicenses.filter(
      (article) =>
        (article.setName &&
          article.setName.toLowerCase().includes(this.search.toLowerCase())) ||
        (article.setCode &&
          article.setCode.toLowerCase().includes(this.search.toLowerCase())) ||
        (article.hundCode &&
          article.hundCode.toLowerCase().includes(this.search.toLowerCase()))
    );
  }

  @Mobx.computed public get dependentArticles(): Set<string> {
    return ([...this.selected, ...this.installed]
      .map((setID) =>
        this.getAvailableArticlesFromLicenses.find(
          (article) => article.setID === setID
        )
      )
      .filter((article) => article) as Models.Set.Article[]).reduce(
      (acc: Set<string>, article: Models.Set.Article) => {
        article.dependentSets.forEach((setID) => {
          if ([...this.selected, ...this.installed].includes(setID)) {
            return;
          }
          acc.add(
            this.articleStore.articles
              ?.filter((article) => article.status === 1)
              .find((article) => article.setID === setID)?.hundCode ??
              `Article ID: ${setID}` // TODO: remove this after removing the duplicate article
          );
        });
        return acc;
      },
      new Set()
    );
  }
  @Mobx.computed public get isDependentArticlesValid(): boolean {
    return ([...this.selected, ...this.installed]
      .map((setID) =>
        this.getAvailableArticlesFromLicenses.find(
          (article) => article.setID === setID
        )
      )
      .filter((article) => article) as Models.Set.Article[]).reduce(
      (acc: boolean, article: Models.Set.Article) => {
        if (!acc) {
          return acc;
        }
        return article.dependentSets.reduce((acc, setID) => {
          if (!acc) {
            return acc;
          }
          if ([...this.selected, ...this.installed].includes(setID)) {
            return true;
          }
          return false;
        }, true);
      },
      true
    );
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const initLicenses = ({ result }: { result: Models.License[] }) => {
        this.licenses = result
          .filter(
            (license: any, index) =>
              result.findIndex(
                (a: any) => a["licenseCode"] === license["licenseCode"]
              ) === index
          )
          .map((license: any) => {
            const licenseDetailIDs = result
              .filter((x) => x.licenseCode === license.licenseCode && !x.macID)
              .map((x) => x.licenseDetailID);
            const total = result.filter(
              (x: any) => x.licenseCode === license.licenseCode
            ).length as number;
            const totalUsed = result.filter(
              (x: any) => x.licenseCode === license.licenseCode && x.macID
            ).length as number;
            const totalAvailable = (total - totalUsed) as number;
            const setList = this.articleStore.articles.find(
              (article) => article.setID === license.setID
            );
            return {
              licenseDetailIDs,
              licenseCodes: [license.licenseCode],
              total,
              totalUsed,
              totalAvailable,
              status: totalAvailable === 0 ? 2 : 1,
              setList: setList && { ...setList, licenseDetailIDs },
              setID: setList?.setID,
              setCode: setList?.setCode,
              setName: setList?.setName,
              hundCode: setList?.hundCode,
              devices: result
                .filter(
                  (x: any) => x.licenseCode === license.licenseCode && x.macID
                )
                .map((license: any) => license.device),
            } as any;
          });

        if (this.articleStore.articles.length > 0) {
          const licenses = Array.from(
            this.licenses
              .reduce((acc: Map<any, any>, license: any) => {
                const licenseAlreadyInAcc = acc.get(license.setID);
                if (licenseAlreadyInAcc) {
                  acc.set(license.setID, {
                    ...licenseAlreadyInAcc,
                    ...license,
                    ...{
                      licenseCodes: [
                        ...licenseAlreadyInAcc.licenseCodes,
                        ...license.licenseCodes,
                      ],
                      licenseDetailIDs: [
                        ...licenseAlreadyInAcc.licenseDetailIDs,
                        ...license.licenseDetailIDs,
                      ],
                      total: licenseAlreadyInAcc.total + license.total,
                      totalUsed:
                        licenseAlreadyInAcc.totalUsed + license.totalUsed,
                      totalAvailable:
                        licenseAlreadyInAcc.totalAvailable +
                        license.totalAvailable,
                      status:
                        licenseAlreadyInAcc.total + license.totalAvailable === 0
                          ? 2
                          : 1,
                      devices: [
                        ...licenseAlreadyInAcc.devices,
                        ...license.devices,
                      ],
                    },
                  });

                  return acc;
                }

                acc.set(license.setID, license);
                return acc;
              }, new Map())
              .values()
          );

          this.licenses = [];
          this.licenses = licenses;
        }
      };

      const { result } = await this.licenseService.getAllData(props);
      initLicenses({ result });

      await this.articleStore.read();
      initLicenses({ result });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.licenses) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }



}
