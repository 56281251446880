import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Icon {
  export interface IIcon {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Icon.IIcon;

  export namespace GetAllData {
    export interface Props {
      rows?: number;
      pageId?: number;
    }

    export type Return = Models.API.Response<Models.Icon.Name[]>;
  }

  export namespace Upload {
    export interface Props {
      body?: object;
      formData: FormData;
    }

    export type Return = Models.API.Response<Models.Icon.Name[]>;
  }
}

// eslint-disable-next-line no-redeclare
export class Icon {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Icon.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllData(
    props?: Icon.GetAllData.Props
  ): Promise<Icon.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Icons", "GetIconNameList"],
    });

    const response = await this.httpManager.get<Icon.GetAllData.Return>({
      url,
    });

    return response;
  }

  public async upload({
    formData,
  }: Icon.Upload.Props): Promise<Icon.Upload.Return> {
    const url = this.urlFactory.create({
      named: ["Icons", "Update", `${formData}`],
    });

    const response = await this.httpManager.post<Icon.Upload.Return, undefined>(
      {
        url,
        body: undefined,
      }
    );

    return response;
  }
}
