import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Tenant from "src/app/pages/tenant";
import * as Constants from "src/constants";
import * as Services from "src/services";

export declare namespace AdminAccountForm {
  export interface Values {
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
    confirmPassword: string;
  }

  export interface Props {
    handleContinue(): void;
  }
}

// eslint-disable-next-line no-redeclare
export function AdminAccountForm({ handleContinue }: AdminAccountForm.Props) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(
    false
  );

  const userStore = React.useContext(App.Contexts.userStore);
  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const globalTenantStore = React.useContext(App.Contexts.globalTenantStore);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  const tenantService = new Services.Tenant({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  function handleClickShowPassword() {
    return function () {
      setShowPassword(!showPassword);
    };
  }

  function handleMouseDownPassword() {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
    };
  }

  function handleClickShowConfirmPassword() {
    return function () {
      setShowConfirmPassword(!showConfirmPassword);
    };
  }

  function handleMouseDownConfirmPassword() {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
    };
  }

  async function handleSubmit(
    values: AdminAccountForm.Values,
    { setSubmitting }: Formik.FormikHelpers<AdminAccountForm.Values>
  ) {
    try {
      const ETUID = tenantStore.etuid ?? userStore.user?.ETUID;

      if (!ETUID) {
        return;
      }

      const { firstName, lastName, userName, password } = values;

      tenantStore.setAdminAccount({
        adminAccount: { firstName, lastName, userName, password },
      });

      await tenantService.create({
        body: {
          ETUID,
          companyName: tenantStore.companyAliasName,
          firstName,
          lastName,
          password,
          userName,
          companyAliasName: tenantStore.companyAliasName,
          databaseName: `${tenantStore.companyAliasName
            .split(" ")
            .join("_")}_ETDB`,
        },
      });

      if (userStore.user?.RoleName === "customer") {
        globalTenantStore.read({ etuid: ETUID });
      }

      if (userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin") {
        tenantStore.read();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      handleContinue();
    }
  }

  return (
    <>
      <Formik.Formik<Tenant.AdminAccountForm.Values>
        initialValues={{
          firstName: "",
          lastName: "",
          userName: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={
          Tenant.Shared({ t, tenantService, tenantStore, userStore }).Admin
            .Account.Form.validationSchema
        }
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Formik.Form>
            <Mui.Box minHeight={275}>
              <Mui.Grid container spacing={3}>
                <Mui.Grid item xs={12} md={6}>
                  <Formik.Field
                    type="text"
                    name="firstName"
                    component={FormikMui.TextField}
                    label={t("First name")}
                    fullWidth
                    autoFocus
                  />
                </Mui.Grid>

                <Mui.Grid item xs={12} md={6}>
                  <Formik.Field
                    type="text"
                    name="lastName"
                    component={FormikMui.TextField}
                    label={t("Last name")}
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container spacing={3}>
                <Mui.Grid item xs={12} md={6}>
                  <Formik.Field
                    type="text"
                    name="userName"
                    component={FormikMui.TextField}
                    label={"User name"}
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container spacing={3}>
                <Mui.Grid item xs={12} md={6}>
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="password"
                    fullWidth
                    label={
                      <ReactI18next.Trans i18nKey={"Password"}>
                        Password
                      </ReactI18next.Trans>
                    }
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword()}
                            onMouseDown={handleMouseDownPassword()}
                            style={{
                              padding: theme.spacing(1.125),
                            }}
                          >
                            <Mui.Box lineHeight={0} fontSize={30}>
                              {showPassword ? (
                                // <MuiIcons.Visibility />
                                <i className="icon-etc-password"></i>
                              ) : (
                                // <MuiIcons.VisibilityOff />
                                <i className="icon-etc-hide-password"></i>
                              )}
                            </Mui.Box>
                          </Mui.IconButton>
                        </Mui.InputAdornment>
                      ),
                    }}
                  />
                </Mui.Grid>

                <Mui.Grid item xs={12} md={6}>
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="confirmPassword"
                    fullWidth
                    label={
                      <ReactI18next.Trans i18nKey={"Confirm password"}>
                        Confirm password
                      </ReactI18next.Trans>
                    }
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword()}
                            onMouseDown={handleMouseDownConfirmPassword()}
                            style={{
                              padding: theme.spacing(1.125),
                            }}
                          >
                            <Mui.Box lineHeight={0} fontSize={30}>
                              {showConfirmPassword ? (
                                // <MuiIcons.Visibility />
                                <i className="icon-etc-password"></i>
                              ) : (
                                // <MuiIcons.VisibilityOff />
                                <i className="icon-etc-hide-password"></i>
                              )}
                            </Mui.Box>
                          </Mui.IconButton>
                        </Mui.InputAdornment>
                      ),
                    }}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Box>
            <Mui.DialogActions
              style={{ padding: 0, marginTop: theme.spacing(4) }}
            >
              <Mui.Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                <ReactI18next.Trans i18nKey={"Continue"}>
                Continue
                      </ReactI18next.Trans>
              </Mui.Button>
            </Mui.DialogActions>
          </Formik.Form>
        )}
      </Formik.Formik>
    </>
  );
}
