/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Dashboard {
  export interface IDashboard {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Dashboard.IDashboard;

  export namespace GetAllData {
    export interface Props extends Pick<Models.User.Main, "uid"> {
      userRole: string;
      uid: string | any;
      EmailId?: string | any;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class Dashboard {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: Dashboard.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllData({
    userRole,
    uid,
    EmailId,
  }: Dashboard.GetAllData.Props): Promise<Dashboard.GetAllData.Return> {
    const url =
      (userRole === "admin" || userRole == "superadmin")
        ? this.urlFactory.create({
            named: ["Admin", "GetDashboard"],
          })
        : this.urlFactory.create({
            named: ["dashboard", "get", `${uid}`, `${EmailId}`],
          });

    const response = await this.httpManager.get<Dashboard.GetAllData.Return>({
      url,
    });

    return response;
  }
}
