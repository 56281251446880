import * as AxiosTypes from "axios";
import * as Services from "src/services";

export declare namespace Axios {
  export interface IAxios {
    readonly agent: AxiosTypes.AxiosInstance;
  }

  export type Options = Axios.IAxios;
}

// eslint-disable-next-line no-redeclare
export class Axios implements Services.Managers.HTTP.IHTTP, Axios.IAxios {
  readonly agent: AxiosTypes.AxiosInstance;
  constructor({ agent }: Axios.Options) {
    this.agent = agent;
  }

  public async post<Return, Body>({
    body,
    url,
    headers,
  }: Services.Managers.HTTP.Request.Props<Body, true>): Promise<Return> {
    const response = await this.agent.post<Return>(url, body || undefined, {
      headers: headers?.getHeaders(),
    });

    return response.data;
  }

  public async get<Return>({
    url,
    headers,
  }: Services.Managers.HTTP.Request.Props<undefined, false>): Promise<Return> {
    const response = await this.agent.get<Return>(url, {
      headers: headers?.getHeaders(),
    });

    return response.data;
  }
}
