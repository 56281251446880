/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace InviteCustomerService {
  export interface IInviteCustomerService {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = InviteCustomerService.IInviteCustomerService;

  export namespace InviteCustomer {
    export type Return = Models.API.Response<any>;
    export type Body = Models.InviteCustomer.Main;
    // }

    export interface Props {
      body: InviteCustomerService.InviteCustomer.Body;
    }
  }

  export namespace GetAllData {
    export interface Body {
      status: null | number;
      userID: string | number;
    }

    export interface Props {
      body: GetAllData.Body;
      userRole: string;
      uid: number;
    }

    export type Return = Models.API.Response<Models.License[]>;
  }

  export namespace GetAddCustomerAllData {
    export type Return = Models.API.Response<Models.AddDealer.Main>;
    export interface Props {
      EmailID?: string;
      ETUID?: any;
    }
  }

  export namespace GetAllSetFuncationData {
    export interface Props {
      rows?: number;
      userId?: number;
      userRole: string;
    }

    export type Return = Models.API.Response<Models.Set.Function[]>;
  }
}

// eslint-disable-next-line no-redeclare
export class InviteCustomerService {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: InviteCustomerService.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async inviteCustomer({
    body,
  }: InviteCustomerService.InviteCustomer.Props): Promise<
    InviteCustomerService.InviteCustomer.Return
  > {
    const url = this.urlFactory.create({
      named: ["Customer", "AddCustomer"],
    });

    const response = await this.httpManager.post<
      InviteCustomerService.InviteCustomer.Return,
      InviteCustomerService.InviteCustomer.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  // eslint-disable-next-line no-empty-pattern
  public async getCustomerAllData({
    EmailID,
    ETUID,
  }: InviteCustomerService.GetAddCustomerAllData.Props): Promise<
    InviteCustomerService.GetAddCustomerAllData.Return
  > {
    const url = this.urlFactory.create({
      named: ["Customer", "GetCustomerByEmailID", `${EmailID}`, `${ETUID}`],
    });

    const response = await this.httpManager.get<
      InviteCustomerService.GetAddCustomerAllData.Return
    >({
      url,
    });


    return response;
  }

  public async getAllSetFuncationsData(
    props?: InviteCustomerService.GetAllSetFuncationData.Props
  ): Promise<InviteCustomerService.GetAllSetFuncationData.Return> {
    const url = this.urlFactory.create({
      named: [
        "SetFuncations",
        "GetAll",
        `${props?.userRole === "customer" ? props?.rows ?? 1 : -1}`,
      ],
    });

    const response = await this.httpManager.get<
      InviteCustomerService.GetAllSetFuncationData.Return
    >({
      url,
    });

    return response;
  }

  public async getAllFuncationData(
    props?: InviteCustomerService.GetAllData.Props
  ): Promise<InviteCustomerService.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: [
        "SetFuncations",
        "GetAll",
        `${props?.userRole === "customer" ? props?.userRole ?? 1 : -1}`,
      ],
    });

    const response = await this.httpManager.get<
      InviteCustomerService.GetAllData.Return
    >({
      url,
    });

    return response;
  }
  // license getAll data
  public async getAllData({
    body,
    userRole,
    uid,
  }: InviteCustomerService.GetAllData.Props): Promise<
    InviteCustomerService.GetAllData.Return
  > {
    const url =
      userRole === "customer"
        ? this.urlFactory.create({
            named: ["license", "get", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["license", "GetAdmin"],
          });

    const response = await (userRole === "customer"
      ? this.httpManager.get<InviteCustomerService.GetAllData.Return>({ url })
      : this.httpManager.post<
          InviteCustomerService.GetAllData.Return,
          InviteCustomerService.GetAllData.Body
        >({
          body: {
            ...body,
            status: 2,
          },
          url,
        }));

    return response;
  }
}
