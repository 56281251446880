import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as FormikMuiLab from "formik-material-ui-lab";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Hooks from "src/app/hooks";
import * as Constants from "src/constants";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";

export declare namespace Form {
  export interface Values {
    funcationCode: string;
    funcationName: string;
    iconClass: string;
  }

  export namespace Options {
    export interface Icon {
      iconName: string;
      iconType: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export function Form() {
  const [iconOptions, setIconOptions] = React.useState<Form.Options.Icon[]>([]);
  const { touched, errors, values } = Formik.useFormikContext<Form.Values>();
  const forceUpdate = Hooks.useForceUpdate();
  const { t } = ReactI18next.useTranslation();

  const iconService = new Services.Icon({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    (async function () {
      try {
        const { result: icons } = await iconService.getAllData();
        setIconOptions(icons);
        if (values.iconClass) {
          values.iconClass = ({
            iconName: values.iconClass,
          } as unknown) as string;
          forceUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik.Form>
        <Mui.Grid container spacing={4}>
          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="funcationName"
              component={FormikMui.TextField}
              label={t("Function name")}
              fullWidth
              autoFocus
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="funcationCode"
              component={FormikMui.TextField}
              label={t("Function code")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              component={FormikMuiLab.Autocomplete}
              name="iconClass"
              fullWidth
              options={iconOptions}
              getOptionLabel={(option: Form.Options.Icon) => option.iconName}
              renderTags={(tagValue: Form.Options.Icon[], getTagProps: any) =>
                tagValue.map((option, index) => (
                  <Mui.Chip
                    label={option.iconName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(option: Form.Options.Icon) => (
                <Mui.Box key={uuidv4()} display="flex" alignItems="baseline">
                  <Mui.Box marginRight={1} component="span">
                    <i className={option.iconName} />
                  </Mui.Box>
                  <Mui.Typography variant="body1">
                    {option.iconName}
                  </Mui.Typography>
                </Mui.Box>
              )}
              renderInput={(
                params: FormikMuiLab.AutocompleteRenderInputParams,
              ) => (
                <Mui.TextField
                  {...params}
                  name="iconClass"
                  error={
                    touched["iconClass"] && errors["iconClass"] ? true : false
                  }
                  helperText={touched["iconClass"] && errors["iconClass"] ? t("Icon name is required") : ""}
                  label={t("Icon name")}
                />
              )}
            />
          </Mui.Grid>
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
