/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import * as Services from "src/services";
import * as Constants from "src/constants";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import ReplyIcon from "@material-ui/icons/Reply";
import * as Router from "react-router-dom";
import {  ShoppingCart } from "@material-ui/icons";
import * as MuiIcon from "@material-ui/icons";
import * as Notistack from "notistack";

interface Data extends Models.License {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);

function EnhancedTable({
  rows,
  isCustomerInvite = false,
  orderCode = "",
  buttonIncrementer = 0,
  isAssignAll = false,
  OrderDetailsOpen = false,
  isManageOrderDetail = false,
}: {
  rows: Models.License[];
  isCustomerInvite: boolean;
  orderCode: string;
  buttonIncrementer: number;
  isAssignAll: boolean;
  OrderDetailsOpen: boolean;
  isManageOrderDetail: boolean;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("licenseCode");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] =
    React.useState<boolean>(false);
  const importDealerStore = React.useContext(App.Contexts.importDealerStore);

  const [licenseList, setSelectedLicenseList] = React.useState<any>();
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const [isDeviceDeactive, setDeviceDeactive] = React.useState<boolean>(false);
  const [isCustomerExist, setIsCustomerExist] = React.useState<boolean>(false);
  const [customerData, setCustomerData] = React.useState<Models.DealerCustomer.Main[]>([]);
  const [customerID, setcustomerID] = React.useState<any>("0");

  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  const uid = userStore.user?.ETUID;
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);

  React.useEffect(() => {
    setPage(0);
    
    if (isAssignAll) {
      setSelectAll(isAssignAll);

      setOrderDetailsDialogOpen(true);
    }
    setRowsPerPage(7);
    if (isManageOrderDetail) {
      setRowsPerPage(3);
    }
  }, [
    OrderDetailsOpen,
    isAssignAll,
    isManageOrderDetail,
    rows,
    buttonIncrementer,
    importDealerStore,
    userStore.user,
  ]);
  React.useEffect(() => {
    if (isAssignAll) {
      setSelectedLicenseList(rows);
    }
  }, [rows, isAssignAll, buttonIncrementer]);


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.licenseCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const { enqueueSnackbar } = Notistack.useSnackbar();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const [language, setLanguage] = React.useState("en-IN");
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language;
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  React.useEffect(() => {
    if (languageValue === "en") {
      setLanguage("en-IN");
    } else if (languageValue === "fr") {
      setLanguage("fr-FR");
    } else {
      setLanguage("de-DE");
    }
  }, [languageValue]);
  const [license, setSelectedLicense] = React.useState<any>();
  const [isAssign, setAssign] = React.useState<number>(0);
  const [isAvailable, setAvailable] = React.useState<boolean>(false);
  const [unAssign, setUnAssign] = React.useState<number>(0);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const addCustomerStore = React.useContext(App.Contexts.addCustomerStore);
  const [isCustomerAdd, setIsCustomerAdd] = React.useState<boolean>(false);
  
  const licenses = orderStore.getAssignLicense()[0];
  const navigate = Router.useNavigate();
  React.useEffect(()=>{
    setIsCustomerAdd(dCustomerStore.isCustomerSelected)
  },[dCustomerStore.isCustomerSelected])
  const handleAssign = () => {
    (async () => {
      var dealerCompany = importDealerStore.importDealer.map(
        (k) => k.companyName
      )[0];
      var license
      if(isAssignAll){
       license = orderStore.getAllAssignLicense().map((licenses) => {
        return {
          setID: licenses.setID,
          setName: licenses.setName,
          hundCode: licenses.setCode,
          assignedQuantity: 1,
          licenseCode: licenses.licenseCode,
          OrderDetailID: licenses.orderDetailID,
        };
      });
    }else{
      license = orderStore.getAssignLicense().map((licenses) => {
        return {
          setID: licenses.setID,
          setName: licenses.setName,
          hundCode: licenses.setCode,
          assignedQuantity: 1,
          licenseCode: licenses.licenseCode,
          OrderDetailID: licenses.orderDetailID,
        };
      });
    }
      var customer = localStorage.getItem("customerObject") ?? "";
      var customerObject: Models.DealerCustomer.Main = JSON.parse(customer);

      if (!isCustomerExist) {
        
        const inviteCustomer: Models.InviteFromManageOrder.Main = {
          EmailID: customerObject.emailID ?? "",
          CompanyName: customerObject?.companyName ?? "",
          FirstName: customerObject?.customerName ?? "",
          LastName: "",
          ETUID: userStore.user?.ETUID,
          DealerEmail: userStore.user?.UserEmail ?? "",
          DealerName:
            userStore.user?.FirstName + " " + userStore.user?.LastName,
          langCode: i18n.language,
          DealerCompanyName: dealerCompany,
          IsExsitingCustomer: "0",
          AssignAll: isAssignAll ? 1 : 0,
          OrderCode: orderCode,
          SerialNumber: license,
        };
        

         await addCustomerStore.inviteFromManageOrder({
          body: inviteCustomer,
        });
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Invite has been successfully sent to the user!">
            Invite has been successfully sent to the user!
          </ReactI18next.Trans>,
          {
            variant: "success",
          }
        );
        navigate("/dealercustomer");
      }
    })();
  };
  const handleUnAssign = () => {
    (async () => {
      if(!isDeviceDeactive){
      var customer = customerData[0];
      

      var customerObject: Models.DealerCustomer.Main = customer;

      
      const unAssignedRequest = {
        CustomerETUID: customerObject.etuid,
        CustomerEmailID: customerObject.emailID,
        DealerETUID: userStore.user?.ETUID ?? 0,
        LicenseCode: licenses?.licenseCode ?? "",
        OrderDetailID: licenses?.orderDetailID ?? 0,
        SetID: licenses?.setID ?? 0,
      };
     const result = await addCustomerStore.cancelInviteForSingleLicense({
        body: unAssignedRequest,
      });
      if(result?.status === 100){
      enqueueSnackbar(
        <ReactI18next.Trans i18nKey="License are unassigned from the customer!">
          License are unassigned from the customer!
        </ReactI18next.Trans>,
        {
          variant: "success",
        }
      );
      navigate("/dealercustomer");
      }else{
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Error occured, please contact admin">
            Error occured, please contact admin
          </ReactI18next.Trans>,
          {
            variant: "error",
          }
        );
      }
    }else{
      enqueueSnackbar(
        <ReactI18next.Trans i18nKey="Please deactivate the device and unassign the license">
          Please deactivate the device and unassign the license
        </ReactI18next.Trans>,
        {
          variant: "error",
        }
      );
    }
    })();
  };
  return (
    <div className={classes.root}>
      <Components.LightBox
        open={orderDetailsDialogOpen}
        handleClose={() => {
          setOrderDetailsDialogOpen(false);
          OrderDetailsOpen = false;
        }}
        content={
          <Order.ManageOrderDetail
            licenseList={licenseList}
            license={license}
            isAssign={isAssign}
            unAssign={unAssign}
            isAssignAll={selectAll}
            orderCode={orderCode}
            isAvailable={isAvailable}
          />
        }
        title={
          <>
            <Mui.Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Mui.Grid item xs={6} style={{ display: "flex" }}>
                <Mui.Typography
                  style={{ fontWeight: "bold" }}
                  variant="h6"
                  className={classes.title}
                >
                  <ReactI18next.Trans i18nKey="Selected license">
                    Manage license
                  </ReactI18next.Trans>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={2} style={{ display: "flex" }}>
            
              </Mui.Grid>
            </Mui.Grid>
          </>
        }
        actions={
          <>
            <Mui.Button
              variant="contained"
              className="noprint"
              onClick={() => {
                setOrderDetailsDialogOpen(false);
                OrderDetailsOpen = false;
              }}
            >
              <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
            </Mui.Button>
            {!isAvailable && !isAssignAll && (
              <Mui.Button
                className="noprint"
                variant="contained"
                color="primary"
                onClick={handleUnAssign}
              >
                <ReactI18next.Trans i18nKey="Unassign">
                  Unassign
                </ReactI18next.Trans>
              </Mui.Button>
            )}
            {(isAvailable || isAssignAll) && (
              <Mui.Button
                className="noprint"
                variant="contained"
                color="primary"
                
                onClick={handleAssign}
              >
                Assign
              </Mui.Button>
            )}
          </>
        }
      />
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="grossTotalPriceInclVAT"
                  label={t("Model")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderCode"
                  label={t("Name")}
                />

                {!isCustomerInvite && !isManageOrderDetail && (
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    id="orderedBy"
                    label={t("License serial number")}
                  />
                )}

                {(isCustomerInvite || isManageOrderDetail) && (
                  <EnhancedTableHead
                    rightmost
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    id="orderedBy"
                    label={t("License serial number")}
                  />
                )}
                {!isCustomerInvite && !isManageOrderDetail && (
                  <EnhancedTableHead
                    rightmost
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    id="companyName"
                    label={t("Status")}
                  />
                )}
              
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                
                const isItemSelected = isSelected(row.licenseCode as string);
                const tableCellColor = isManageOrderDetail
                  ? "#f5f4fa"
                  : "#ffffff";
                
               
                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => {
                      if(userStore.user?.RoleName === "dealer"){
                      dCustomerStore.setIsCustomerAdded(false);
                      if(row.status === 3 || row.status === 5 || (row.assignedCustomerID === 0 && row.assignedTo !== "0")){

                        enqueueSnackbar(
                         `Detail view not available for ${ (row.assignedCustomerID === 0 && row.assignedTo !== "0") ? "Invite sent" :row.status === 3 ? "License returned" : "Return failed"} `,
                          {
                            variant: "error",
                          }
                        );
                      }else{
                        localStorage.setItem("AssignFlag","0");
                      
                      if(row.status === 2){
                        setDeviceDeactive(true)
                      }else{
                        setDeviceDeactive(false)
                      }
                      setOrderDetailsDialogOpen(true);
                      setSelectedLicense(row);
                      setSelectAll(false);
                      if (row.status === 1 && row.assignedCustomerID === 0) {
                        setAvailable(true);
                      } else if (
                        row.status === 1 &&
                        row.assignedCustomerID !== 0
                      ) {
                        setAvailable(false);
                      }
                      localStorage.setItem("orderCode", orderCode);
                      orderStore.setAssignLicense(row.licenseCode);
                      var isAssignAl = "0";
                      localStorage.setItem("isAssignAl", isAssignAl);
                      const uid = row.assignedCustomerID;
                      
                      (async () => {
                        const userRole = "customer";
                        if (uid !== undefined && uid !== null && uid !== 0) {
                          await dCustomerStore.read({
                            uid,
                            userRole,
                          });
                          
                         setCustomerData(dCustomerStore.delaerCustomer);
                      
                        }
                      })();
                    }
                      }
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.licenseCode}
                  >
                    <Components.Mui.TableCell leftmost color={tableCellColor}>
                      {row.setCode}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      color={tableCellColor}
                      component="th"
                      scope="row"
                    >
                      {row.setName}
                    </Components.Mui.TableCell>

                    {!isCustomerInvite && !isManageOrderDetail && (
                      <Components.Mui.TableCell color={tableCellColor}>
                        {row.licenseCode}
                      </Components.Mui.TableCell>
                    )}
                    {(isCustomerInvite || isManageOrderDetail) && (
                      <Components.Mui.TableCell
                        rightmost
                        color={tableCellColor}
                      >
                        {row.licenseCode}
                      </Components.Mui.TableCell>
                    )}
                    {!isCustomerInvite && !isManageOrderDetail && (
                      <Components.Mui.TableCell rightmost>
                        <Mui.Grid container justify="space-between">
                          <Components.OrderStatus
                            isChip={false}
                            status={row?.status as 1 | 2 | 3 | 4 | 5}
                            assignedCustomerID={row.assignedCustomerID}
                            assignedTo = {row.assignedTo.toString()}
                          />
                          <Mui.IconButton
                            size="medium"
                            style={{
                              padding: theme.spacing(0.125),
                              fontSize: "18px",
                            }}
                          >
                           {userStore.user?.RoleName === "dealer" && <MuiIcon.ChevronRight
                              style={{ fontSize: "35px" }}
                              color="primary"
                            />}
                          </Mui.IconButton>
                        </Mui.Grid>
                      </Components.Mui.TableCell>
                    )}
                   
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No orders found">
                        No orders found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > rowsPerPage && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    
    </div>
  );
}

export declare namespace ManageOrder {
  export interface Props {
    licenseList?: Models.License[];
    isManageOrderDetail?: boolean;
    isCustomerInvite?: boolean;
  }
}
// eslint-disable-next-line no-redeclare
export const ManageOrder = Mobx.observer(function ({
  licenseList,
  isManageOrderDetail = false,
  isCustomerInvite = false,
}: ManageOrder.Props) {
  const [search, setSearch] = React.useState<string>("");
  const [orderCode, setOrderCode] = React.useState<string>("");
  const [orderId, setOrderId] = React.useState<Number>(0);
  const [isAssignAll, setIsAssignAll] = React.useState<boolean>(false);
  const [buttonIncrementer, setButtonIncrementer] = React.useState<number>(0);
  const [OrderDetailsDialogOpen, setOrderDetailsDialogOpen] =
    React.useState<boolean>(false);
  const [license, setLicense] = React.useState<Models.License[]>();
  const userStore = React.useContext(App.Contexts.userStore) ?? "";
  const orderStore = React.useContext(App.Contexts.orderStore);
  const theme = Mui.useTheme();
  const [searchParams] = Router.useSearchParams();
  const [buttonDefiner, setButtonDefiner] = React.useState<number>();

  var overAllStatus = searchParams.get("m") ?? "";
  React.useEffect(() => {
    (async function () {
      if (!isManageOrderDetail) {
        var orderId = searchParams.get("q") ?? "";
        var createdBy = searchParams.get("cid") ?? "";
        localStorage.setItem("orderID",orderId);
        localStorage.setItem("overAllStatus",overAllStatus);
        var uid = createdBy;
        var userRole = userStore.user?.RoleName ?? "customer";
        var isGetAll = true;
        
        const orderRequest = { cid: uid, eid: "", id: orderId };
        await orderStore.getOrderAndLicense(orderRequest, {
          body: { status: null, userID: uid },
          userRole,
          uid,
          isGetAll,
        });
        await setLicense(orderStore.orderDetail[0].license);
        await setOrderCode(orderStore.orderDetail[0].orderCode);
        setOrderId(orderStore.orderDetail[0].orderID)
      } else {
        await setLicense(licenseList);
      }
      
       

      if (license?.map((k) => k.assignedCustomerID).every((k) => k === 0)) {
        setButtonDefiner(1);
        
      } else if (license?.map((k) => k.status).every((k) => k === 2)) {
        setButtonDefiner(2);
        
      } else {
        setButtonDefiner(3);
        
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  const addCustomerStore = React.useContext(App.Contexts.addCustomerStore);

  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const labelSize = "17px";
  const labelFontWeight = "bold";
  const { t } = ReactI18next.useTranslation();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const navigate = Router.useNavigate();


  function FormRow({
    labelList,
    isLabel,
  }: {
    labelList: any;
    isLabel: boolean;
  }) {
    const listCount = labelList.length === 3 ? 4 : 3;
    
    return (
      <React.Fragment>
        {labelList.map((title: any) => {
          return (
            <>
              <Mui.Grid item xs={listCount} >
                {" "}
                <Mui.Typography
                  style={{
                    fontSize: isLabel ? labelSize : "13.5px",
                    fontWeight: isLabel ? labelFontWeight : "normal",
                    opacity: isLabel ? "0.8" : "",
                  }}
                >
                  {title}
                </Mui.Typography>
              </Mui.Grid>
            </>
          );
        })}
      </React.Fragment>
    );
  }
  return (
    <>
      {!isManageOrderDetail ? (
        <>
          {orderStore.orderDetail.length > 0 && (
            <Mui.Paper
              square
              style={{
                marginBottom: "1px",
                borderLeft: "4px solid dodgerblue",
                borderRadius: "4px",
                padding: "20px",
              }}
            >
              <Timeline align="left">
                {orderStore.orders.map((k, index) => {
                  var createdDateTime = new Date(
                    k.orderedOn + "Z"
                  ).toLocaleDateString();
                  var createdDateTimeProcessed = new Date(
                    k.processedOn + "Z"
                  ).toLocaleDateString();

                  return (
                    <TimelineItem>
                      <TimelineOppositeContent
                        style={{
                          maxWidth: "0px",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      ></TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          color={k.status === 2 ? "secondary" : "primary"}
                        >
                          {k.status === 2 ? (
                            <ReplyIcon style={{ fontSize: "40px" }} />
                          ) : (
                            <ShoppingCart style={{ fontSize: "40px" }} />
                          )}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent style={{ padding: "20px" }}>
                        <Mui.Grid
                          container
                          justify="space-between"
                          style={{
                            marginBottom: theme.spacing(3),
                          }}
                        >
                          <Mui.Grid item>
                            <Mui.Grid container>
                              <Mui.Typography
                                style={{
                                  fontSize: "22px",
                                  fontWeight: labelFontWeight,
                                  opacity: "0.8",
                                }}
                              >
                                {k.status === 2
                                  ? "Return order"
                                  : "Order processed"}
                              </Mui.Typography>
                            </Mui.Grid>
                          </Mui.Grid>
                          <Mui.Grid item>
                            {index === 0 && (
                              <Components.OrderStatus
                                fontSize="16px"
                                status={+overAllStatus as 1 | 2 | 3 | 4 | 5}
                              />
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container spacing={0}>
                          <Mui.Grid container item xs={12} >
                            <FormRow
                              labelList={[
                                "Order number",
                                "Reference Number",
                                "Order date",
                                "Processed on",
                              ]}
                              isLabel={false}
                            />
                            <FormRow
                              labelList={[
                                k.orderCode,
                                k.sageOLReferenceBillID,
                                createdDateTime,
                                createdDateTimeProcessed,
                              ]}
                              isLabel={true}
                            />
                    

                           <Mui.Box mt={6} /> 
                             <FormRow
                              labelList={[
                                "Order created by",
                                "Company name",
                                "",
                                ""
                              ]}
                              isLabel={false}
                            />
                            <FormRow
                              labelList={[
                                k.userFirstName +" "+ k.userLastName,
                                k.billingAddress?.companyName,
                                "",
                                ""
                              ]}
                              isLabel={true}
                            />
                          </Mui.Grid>
                        </Mui.Grid>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </Mui.Paper>
          )}
          <Mui.Grid
            container
            justify="flex-start"
            alignItems="center"
            style={{ marginBottom: theme.spacing(3) }}
          >
            <Mui.FormControl style={{ width: 300, marginTop: "8px" }}>
              {orderStore.orderDetail.length > 0 && (
                <Mui.TextField label={t("Search")} onChange={handleSearch} />
              )}
            </Mui.FormControl>
          </Mui.Grid>
        </>
      ) : (
        <></>
      )}
      {license === undefined ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {orderStore.orderDetail[0].license.length > 0 &&
            license !== undefined && (
              <EnhancedTable
                buttonIncrementer={buttonIncrementer}
                isManageOrderDetail={isManageOrderDetail}
                OrderDetailsOpen={OrderDetailsDialogOpen}
                isAssignAll={isAssignAll}
                isCustomerInvite={isCustomerInvite}
                orderCode={orderCode}
                rows={license.filter((order) => {
                  if (!search) {
                    return true;
                  }
                  return (
                    order?.licenseCode
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    order?.setName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    order?.setCode?.toLowerCase().includes(search.toLowerCase())
                  );
                })}
              />
            )}
         
          {!isManageOrderDetail && orderStore.checkAssignAll() && userStore.user?.RoleName === "dealer" && (
            <Mui.Button
              style={{
           
                marginTop: "30px",
                position: "fixed",
                bottom: "30px",
                right: "24px",
                
              }}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                localStorage.setItem("orderCode", orderCode);
                var isAssignAll = "1";
                localStorage.setItem("isAssignAl", isAssignAll);
                setOrderDetailsDialogOpen(true);
                setIsAssignAll(true);
                setButtonIncrementer(() => buttonIncrementer + 1);
              }}
            >
              Assign all
            </Mui.Button>
          )}
          { !isManageOrderDetail && orderStore.checkUnAssignAll() && userStore.user?.RoleName === "dealer" && <Mui.Button
            style={{
           
              marginTop: "30px",
              position: "fixed",
              bottom: "30px",
              right: "24px",
             
            }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={()=>  {
              (async () => {
              var DealerID = userStore?.user?.ETUID  ?? ""
                await addCustomerStore.unAssignAll({
                body: {
                  DealerETUID: DealerID,
                  OrderID: orderId,
                  OrderCode: orderCode
                },
              });
            })();
            enqueueSnackbar(
              <ReactI18next.Trans i18nKey="Email ID is required!">
                All the licenses are unassigned from the customer 
              </ReactI18next.Trans>,
              {
                variant: "success",
              }
            );
            navigate(
              `/Orders`
            );
            }}
            
          >
            Unassign all
          </Mui.Button>
}
        </>
      )}
    </>
  );
});
