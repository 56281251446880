import * as React from "react";
import * as Router from "react-router-dom";

export function Process() {
  const [searchParams] = Router.useSearchParams();
  const navigate = Router.useNavigate();
  React.useEffect(() => {
    (function () {
      const orderId = searchParams.get("q");
      if (!orderId) {
        
         
        return;
      }

      localStorage.setItem("orderDetails", orderId);
      navigate("/shop/payment/success");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
