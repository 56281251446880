import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
const CustomTextField = Mobx.observer(function (
  props: FormikMui.TextFieldProps
) {
  const userStore = React.useContext(App.Contexts.userStore);

  const dealerStore = React.useContext(App.Contexts.addDealerStore);
  const {
    form: { setFieldTouched },
    field: { name },
  } = props;

  const onBlur = React.useCallback(
    async (event) => {
      const { value } = event.target;
      const etuid = userStore.user?.ETUID;
      setFieldTouched(name);
      try {
        await dealerStore.readUsers();
        const names = dealerStore.addDealer?.firstName;
        //alert(names);
      } catch (error) {
        console.error(error);
      }
    },
    [dealerStore, name, setFieldTouched, userStore.user]
  );

  return (
    <>
      <Mui.TextField {...FormikMui.fieldToTextField(props)} onBlur={onBlur} />
    </>
  );
});

export const Form = Mobx.observer(function () {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const { submitCount, submitForm } = Formik.useFormikContext();
  const dealerStore = React.useContext(App.Contexts.addDealerStore);
  return (
    <>
      <Formik.Form>
        <Formik.Field
          name="emailID"
          component={CustomTextField}
          fullWidth
          label={t("Email")}
          margin="dense"
        />

        <Formik.Field
          name="companyName"
          component={FormikMui.TextField}
          fullWidth
          label={t("Company name")}
          margin="dense"
        />
        <Formik.Field
          name="firstname"
          component={FormikMui.TextField}
          fullWidth
          label={t("First name")}
          margin="dense"
          id="firstname"
        />
        <Formik.Field
          name="lastname"
          component={FormikMui.TextField}
          fullWidth
          label={t("Last name")}
          margin="dense"
        />
        <Mui.Box
          position="absolute"
          width={"calc(100% - 40px)"}
          bottom={theme.spacing(2.5)}
          right={theme.spacing(2.5)}>
          <Mui.Button
            onClick={submitForm}
            color="primary"
            variant="contained"
            fullWidth>
            <ReactI18next.Trans i18nKey="Assign licenses">
            Assign licenses
            </ReactI18next.Trans>
          </Mui.Button>
        </Mui.Box>
      </Formik.Form>
    </>
  );
});
