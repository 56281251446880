import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import { v4 as uuidv4 } from "uuid";

export const Success = Mobx.observer(function () {
  const [copied, setCopied] = React.useState<boolean>(false);

  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  const functionStore = React.useContext(App.Contexts.functionStore);

  React.useEffect(() => {
    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      generateLicenseStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!generateLicenseStore.created ? (
        <Components.Redirect to="/licenses/generate" />
      ) : (
        <>
          <Mui.Box marginBottom={2}>
            <MuiLab.Alert severity="success">
              <MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="License successfully generated.">
                  License successfully generated.
                </ReactI18next.Trans>
              </MuiLab.AlertTitle>
              <ReactI18next.Trans i18nKey="Share the license key from the below checkbox with your relevant customer.">
                Share the license key from the below checkbox with your relevant
                customer.
              </ReactI18next.Trans>
            </MuiLab.Alert>
          </Mui.Box>
          <Mui.Grid container spacing={3}>
            <Mui.Grid item xs={12} md={6} lg={4}>
              <Mui.Card
                style={{
                  height: "100%",
                  position: "relative",
                }}
              >
                <Mui.CardContent>
                  <Mui.TextField
                    label={t("License key")}
                    value={generateLicenseStore.created.licenseKey}
                    fullWidth
                    multiline
                    rows={2}
                    rowsMax={2}
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.Tooltip
                            title={copied ? "Copied!" : ""}
                            placement="bottom"
                          >
                            <ReactCopyToClipboard.CopyToClipboard
                              text={generateLicenseStore.created.licenseKey}
                              onCopy={() => {
                                setCopied(true);
                              }}
                            >
                              <Mui.IconButton
                                aria-label="copy textbox content"
                                style={{
                                  padding: theme.spacing(1.125),
                                }}
                              >
                                <MuiIcons.ContentCopy />
                              </Mui.IconButton>
                            </ReactCopyToClipboard.CopyToClipboard>
                          </Mui.Tooltip>
                        </Mui.InputAdornment>
                      ),
                    }}
                  />
                  <Mui.Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: theme.spacing(2) }}
                  >
                    <ReactI18next.Trans i18nKey="Bought articles">
                      Bought articles
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Grid container>
                    {generateLicenseStore.created?.sets.map((article) => (
                      <Mui.Tooltip title={article.setName} key={uuidv4()}>
                        <Mui.Avatar
                          style={{
                            marginRight: theme.spacing(2.5),
                            marginTop: theme.spacing(1),
                          }}
                        >
                          <Mui.Box color="text.primary">
                            <i className={article.iconClass} />
                          </Mui.Box>
                        </Mui.Avatar>
                      </Mui.Tooltip>
                    ))}
                  </Mui.Grid>

                  <Mui.Box height={theme.spacing(5)} width={1} />

                  <Mui.Box
                    position="absolute"
                    bottom={theme.spacing(2.5)}
                    right={theme.spacing(2.5)}
                  >
                    <Mui.Button
                      color="primary"
                      variant="contained"
                      component={Router.Link}
                      to="/licenses/generate"
                    >
                      <ReactI18next.Trans i18nKey="Generate another license">
                        Generate another license
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.Box>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={12}
              md={6}
              lg={8}
              style={{ height: "calc(100vh - 284px)", overflow: "auto" }}
            >
              {generateLicenseStore.created && (
                <Article.Views.Cards
                  disablePagination
                  cardsPerRow={2}
                  spacing={2}
                  variant="DETAILED"
                  functions={functionStore.functions}
                  articles={generateLicenseStore.created.sets}
                />
              )}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Box display="grid" justifyContent="flex-end" alignItems="center">
            <Mui.Box marginTop={2}>
              <Mui.Button
                color="primary"
                component={Router.Link}
                to="/licenses/manual"
              >
                <ReactI18next.Trans i18nKey="Back to manual licenses">
                Back to manual licenses
                      </ReactI18next.Trans>
                
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </>
      )}
    </>
  );
});
