import * as React from "react";
import * as ReactI18next from "react-i18next";

export function Main() {
  return (
    <>
      <ReactI18next.Trans i18nKey="This is a customers main page">
        This is a customers main page
      </ReactI18next.Trans>
    </>
  );
}
