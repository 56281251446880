/** @format */

import * as Mobx from "mobx";
import * as Article from "src/app/pages/article";
import * as License from "src/app/pages/license";
import * as Models from "src/models";
import * as Services from "src/services";
import * as Stores from "src/stores";
export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    customerInvite: Models.InviteCustomer.Main[];
    invitecustomerService: Services.InviteCustomerService;
    functions: Models.Set.Function[];
  }
  export type Options = {
    invitecustomerService: Services.InviteCustomerService;
    licenseService: Services.License;
    licenseStore: License.Store;
    articleStore: Article.Store;
    userStore: Stores.User;
  };
  export namespace InviteCustomer {
    export interface Props
      extends Services.InviteCustomerService.InviteCustomer.Props {}
  }
  export namespace Read {
    export interface Props extends Services.License.GetAllData.Props {}
  }

  export namespace ReadAddCustomer {
    export interface Props
      extends Services.InviteCustomerService.GetAddCustomerAllData.Props {}
  }

  export namespace GetAllPurchanseSetData {
    export interface Props {
      orderID?: string | any;
    }
    export type Return = Models.API.Response<Models.Set.Article[]>;
  }

  export namespace IncreaseProductQuantity {
    export interface Props {
      article: any;
    }
  }

  export namespace AddProduct {
    export interface Props {
      article: any;
    }
  }
  export namespace DecreaseProductQuantity {
    export interface Props {
      article: any;
    }
  }
  export namespace RemoveProduct {
    export interface Props {
      article: any;
    }
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public article: Map<string, any> = new Map();
  @Mobx.observable public functions: Models.Set.Function[] = [];
  @Mobx.observable public addcustomer: Map<
    Models.Customer.AddCustomerUser,
    any
  > = new Map();
  private userStore: Stores.User;

  @Mobx.observable
  public existingcustomer: Models.Customer.AddCustomerUser[] = [];
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public search: string = "";
  @Mobx.observable public customerInvite: Models.InviteCustomer.Main[] = [];
  @Mobx.observable public selected: number[] = [];
  @Mobx.observable
  public assignedArticle: Models.InviteCustomer.InviteArticle[] = [];
  @Mobx.observable public selectedArticle: Models.Set.Article[] = [];
  @Mobx.observable public installed: number[] = [];
  @Mobx.observable public isLoadingPreviouslyInstalledArticles: boolean = false;
  @Mobx.observable public activated: Pick<
    Services.License.Create.Result,
    "licenseKey"
  > & {
    sets: Models.Set.Article[];
  } = {
    licenseKey: "",
    sets: [],
  };
  public invitecustomerService: Services.InviteCustomerService;
  private licenseService: Services.License;
  articleStore: Article.Store;
  @Mobx.observable public licenses?: Models.License[];
  licenseStore: License.Store;
  constructor({
    invitecustomerService,
    licenseService,
    articleStore,
    licenseStore,
    userStore,
  }: Store.Options) {
    this.invitecustomerService = invitecustomerService;
    this.licenseService = licenseService;
    this.articleStore = articleStore;
    this.licenseStore = licenseStore;
    this.userStore = userStore;
    this.rehydrate();
    const disposer = Mobx.reaction(
      () => this.userStore.user?.ETUID,
      () => {
        if (
          this.userStore.user?.ETUID !== undefined ||
          this.userStore.user?.ETUID !== null
        ) {
          this.rehydrate();
          disposer();
        }
      }
    );
  }

  @Mobx.action public async readFunction(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.invitecustomerService.getAllFuncationData(
        props
      );

      
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
  @Mobx.action public addProduct({ article }: Store.AddProduct.Props) {
    function processAdd(this: Store) {
      const productPresentInCart = this.article.get(article.setID);
      if (productPresentInCart) {
        return;
      }

      this.article.set(article.setID, { ...article, quantity: 1 });
    }

    processAdd.bind(this)();
    this.persist();
  }

  @Mobx.action public removeProduct({ article }: Store.RemoveProduct.Props) {
    this.article.delete(article.setID);
    this.persist();
  }

  @Mobx.action public increaseProductQuantity({
    article,
  }: Store.IncreaseProductQuantity.Props) {
    const productInCart = this.article.get(article.setID);
    if (!productInCart) {
      return;
    }
    productInCart.quantity += 1;
    this.persist();
  }

  @Mobx.action public decreaseProductQuantity({
    article,
  }: Store.DecreaseProductQuantity.Props) {
    const productInCart = this.article.get(article.setID);

    if (!productInCart) {
      return;
    }
    productInCart.quantity -= 1;
    this.persist();
  }

  @Mobx.action public resetInviteSummary() {
    const ETUID = this.userStore.user?.ETUID;
    if (ETUID === undefined || ETUID === null) {
      return;
    }
    localStorage.removeItem(`article-${ETUID}`);
    this.article = new Map();
  }

  @Mobx.action public async readSetFuncations(props?: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } =
        await this.invitecustomerService.getAllSetFuncationsData(props);

      this.functions = result;
      
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public setActivated({
    activated,
  }: {
    activated: Pick<Services.License.Create.Result, "licenseKey"> & {
      sets: Models.Set.Article[];
    };
  }) {
    this.activated = activated;
  }

  @Mobx.action public setSearch({ search }: { search: string }) {
    this.search = search;
  }
  @Mobx.action public async inviteCustomer(props: Store.InviteCustomer.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.invitecustomerService.inviteCustomer(props);
      this.invitecustomerService = result;
      return result;
    } catch (error) {
      console.error(error);
    }
  }
  @Mobx.computed get getArticlesFromLicenses(): Models.Set.Article[] {
    if (!this.licenseStore.licenses?.length) return [];
    var article = this.licenseStore.licenses
      .filter((license: any) => license.status === 1)
      .map((license: any) => license.setList)
      .filter((article) => article)
      .sort(function (a, b) {
        const setNumbersA = parseInt(
          a.setCode.replace(/^'|'$/g, "").split(/\s*\-\s*/g)[1]
        );
        const setNumbersB = parseInt(
          b.setCode.replace(/^'|'$/g, "").split(/\s*\-\s*/g)[1]
        );

   
        return setNumbersA - setNumbersB;
      });
    var articleStore = article.sort((a, b) => {
      if (b.availableQuantity !== null && a.availableQuantity !== null) {
        return (
          parseFloat(b.availableQuantity.toString()) -
          parseFloat(a.availableQuantity.toString())
        );
      }
      return 0;
    });

    return articleStore;
  }
  @Mobx.computed get getInstalledArticlesFromLicenses(): Models.Set.Article[] {
    return this.getArticlesFromLicenses.filter((article) =>
      this.installed.includes(article.setID)
    );
  }

  @Mobx.action public setArticleSelected({
    selectedArticle,
  }: {
    selectedArticle: Models.Set.Article[];
  }) {
    this.selectedArticle = selectedArticle;
  }

  @Mobx.action public setSelected({ selected }: { selected: number[] }) {
    this.selected = selected;
  }
  @Mobx.action public setInstalled({ installed }: { installed: number[] }) {
    this.installed = installed;
  }
  @Mobx.action public setIsLoadingPreviouslyInstalledArticles({
    isLoadingPreviouslyInstalledArticles,
  }: {
    isLoadingPreviouslyInstalledArticles: boolean;
  }) {
    this.isLoadingPreviouslyInstalledArticles =
      isLoadingPreviouslyInstalledArticles;
  }
  @Mobx.computed get getAvailableArticlesFromLicenses(): Models.Set.Article[] {
    return this.getArticlesFromLicenses.filter(
      (article) => !this.installed.includes(article.setID)
    );
  }
  @Mobx.computed get getFilteredAvailableArticles(): Models.Set.Article[] {
    if (!this.search) return this.getAvailableArticlesFromLicenses;

    return this.getAvailableArticlesFromLicenses.filter(
      (article) =>
        (article.setName &&
          article.setName.toLowerCase().includes(this.search.toLowerCase())) ||
        (article.setCode &&
          article.setCode.toLowerCase().includes(this.search.toLowerCase())) ||
        (article.hundCode &&
          article.hundCode.toLowerCase().includes(this.search.toLowerCase()))
    );
  }

  @Mobx.computed public get dependentArticles(): Set<string> {
    return (
      [...this.selected, ...this.installed]
        .map((setID) =>
          this.getAvailableArticlesFromLicenses.find(
            (article) => article.setID === setID
          )
        )
        .filter((article) => article) as Models.Set.Article[]
    ).reduce((acc: Set<string>, article: Models.Set.Article) => {
      article.dependentSets.forEach((setID) => {
        if ([...this.selected, ...this.installed].includes(setID)) {
          return;
        }
        acc.add(
          this.articleStore.articles
            ?.filter((article) => article.status === 1)
            .find((article) => article.setID === setID)?.hundCode ??
            `Article ID: ${setID}` // TODO: remove this after removing the duplicate article
        );
      });
      return acc;
    }, new Set());
  }
  @Mobx.computed public get isDependentArticlesValid(): boolean {
    return (
      [...this.selected, ...this.installed]
        .map((setID) =>
          this.getAvailableArticlesFromLicenses.find(
            (article) => article.setID === setID
          )
        )
        .filter((article) => article) as Models.Set.Article[]
    ).reduce((acc: boolean, article: Models.Set.Article) => {
      if (!acc) {
        return acc;
      }
      return article.dependentSets.reduce((acc, setID) => {
        if (!acc) {
          return acc;
        }
        if ([...this.selected, ...this.installed].includes(setID)) {
          return true;
        }
        return false;
      }, true);
    }, true);
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.licenses) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }


  @Mobx.action public async getCustomerDetails(
    props: Store.ReadAddCustomer.Props
  ) {
    try {
      this.isLoading = true;
      const { result } = await this.invitecustomerService.getCustomerAllData(
        props
      );
      return { result };
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  private rehydrate() {
    const ETUID = this.userStore.user?.ETUID;
    if (ETUID === undefined || ETUID === null) {
      return;
    }

    const serialisedCart = localStorage.getItem(`article-${ETUID}`);
    if (serialisedCart) {
      this.article = new Map(JSON.parse(serialisedCart));
    }
  }
  private persist() {
    const ETUID = this.userStore.user?.ETUID;
    if (ETUID === undefined || ETUID === null) {
      return;
    }

    const article = JSON.stringify(Array.from(this.article.entries()));
    localStorage.setItem(`article-${ETUID}`, article);
  }
}
