import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {}

  export type Options = {
    deviceConfigurationUploadService: Services.DeviceConfigurationUpload;
  };
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  public deviceConfigurationUploadService: Services.DeviceConfigurationUpload;

  constructor({ deviceConfigurationUploadService }: Store.Options) {
    this.deviceConfigurationUploadService = deviceConfigurationUploadService;
  }

  public upload(props: Services.DeviceConfigurationUpload.Upload.Props) {
    return this.deviceConfigurationUploadService.upload(props);
  }

  public download() {
    return this.deviceConfigurationUploadService.download();
  }
}
