import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Containers from "src/app/containers";
import * as AdminManage from "src/app/pages/dealercustomer/manageadmin";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
interface Data extends Models.AdminManage.Main {}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export declare namespace Lists {
  export interface Props {
    customers: Models.AdminManage.Main[];
  }
}

export declare namespace ListsDetails {
  export interface Props {
    customerLicense: Models.Set.Function[];
  }
}

// eslint-disable-next-line no-redeclare
export function Lists({ customers }: Lists.Props) {
  const theme = Mui.useTheme();

  const [orderId, setOrderId] = React.useState<number | string>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(8);
  const [selectedValue, setSelectedValue] = React.useState<any>("");
  React.useEffect(() => {
    setPage(0);
  }, [customers]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const adminManageStore = React.useContext(App.Contexts.adminManageStore);
  const handleSelectDealerUser = ({
    dealerProfile,
  }: {
    dealerProfile: any;
  }) => {
    //alert(JSON.stringify(dealerProfile));

    const selectedIndex = adminManageStore.selected.indexOf(dealerProfile);
    let newSelected: Models.CustomerLicense.Main[] = [];
    adminManageStore.setSelected({ selected: newSelected });
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        adminManageStore.selected,
        dealerProfile
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(adminManageStore.selected.slice(1));
    } else if (selectedIndex === adminManageStore.selected.length - 1) {
      newSelected = newSelected.concat(adminManageStore.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        adminManageStore.selected.slice(0, selectedIndex),
        adminManageStore.selected.slice(selectedIndex + 1)
      );
    }
    adminManageStore.setSelected({ selected: newSelected });
  };
  return (
    <>
      <div style={{ clear: "both", display: "table", content: "" }}>
        <div style={{ width: "30%", float: "left" }}>
          <Mui.Grid
            container
            spacing={2}
            style={{ marginBottom: theme.spacing(0.5), width: "130%" }}
          >
            {stableSort(customers as any, getComparator("asc", "customerName"))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((Customer, index) => (
                <Mui.Grid key={uuidv4()} item xs={9} md={9} lg={9}>
                  <AdminManage.List
                    selected={selectedValue}
                    handleClick={({ CustomerID }) => {
                      setOrderId(CustomerID);
                      setSelectedValue(CustomerID);
                      handleSelectDealerUser({
                        dealerProfile: (Customer as unknown) as Models.CustomerLicense.Main[],
                      });
                    }}
                    customer={(Customer as unknown) as Models.AdminManage.Main}
                  />
                </Mui.Grid>
              ))}
          </Mui.Grid>
        </div>
        <div style={{ width: "70%", float: "left" }}>
      
          {stableSort(customers as any, getComparator("asc", "customerName"))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((Customer, index) => (
              <Mui.Grid key={uuidv4()} item xs={9} md={9} lg={9}>
                <AdminManage.ListDeatils
                  selected={selectedValue}
                  handleClick={({ CustomerID }) => {
                    setOrderId(CustomerID);
                  }}
                  customer={(customers as unknown) as Models.AdminManage.Main}
                />
              </Mui.Grid>
            ))}
          <Mui.Card style={{ height: "100%", width: "100%" }}>
            <Mui.CardActionArea>
              <Mui.CardContent>
                <>
                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Used installations">
                        Used installations
                      </ReactI18next.Trans>
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.totalUsed"}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Used installations">
                        Used installations
                      </ReactI18next.Trans>2
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.totalUsed"}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Used installations">
                        Used installations
                      </ReactI18next.Trans>3
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.totalUsed"}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Used installations">
                        Used installations
                      </ReactI18next.Trans>4
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.totalUsed"}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Containers.ShowIfAuthorised roles={["customer", "EU"]}>
                    <Mui.Typography
                      style={{ fontWeight: "bold" }}
                      variant="body1"
                    >
                      {"license?.hundCode"} - {"license?.setName"}
                    </Mui.Typography>
                  </Containers.ShowIfAuthorised>

                  <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                    <Mui.Typography
                      style={{ fontWeight: "bold" }}
                      variant="body1"
                    >
                      {"license?.setCode"} - {"license?.setName"}
                    </Mui.Typography>
                  </Containers.ShowIfAuthorised>
                </>
                <Mui.Grid container spacing={4}>
                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Total installations">
                        Total installations
                      </ReactI18next.Trans>
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.total"}
                    </Mui.Typography>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Used installations">
                        Used installations
                      </ReactI18next.Trans>
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.totalUsed"}
                    </Mui.Typography>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Available installations">
                        Available installations
                      </ReactI18next.Trans>
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="textPrimary">
                      {"license?.totalAvailable"}
                    </Mui.Typography>
                  </Mui.Grid>

                  <Mui.Grid item xs={12} md={6} lg={3}>
                    <Mui.Typography variant="body2" color="textSecondary">
                      <ReactI18next.Trans i18nKey="Available functions">
                        Available functions
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>

                <>
                  <Mui.Grid container spacing={4}>
                    <Mui.Grid item xs>
                      <Mui.CardHeader
                        style={{ padding: 0 }}
                        title={
                          <Mui.Typography
                            style={{ fontWeight: "bold" }}
                            variant="body1"
                          >
                            <ReactI18next.Trans i18nKey="List of devices">
                              List of devices
                            </ReactI18next.Trans>
                          </Mui.Typography>
                        }
                      />
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Grid container spacing={3}>
                    <Mui.Grid item xs={12} md={6} lg={4} key={uuidv4()}>
                      <Mui.Card
                        style={{
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        <Mui.CardActionArea>
                          <Mui.CardContent>
                            <Mui.Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              <ReactI18next.Trans i18nKey="MAC ID (PC, tablet):">
                                MAC ID (PC, tablet):
                              </ReactI18next.Trans>
                            </Mui.Typography>
                            <Mui.Typography
                              style={{ textTransform: "capitalize" }}
                              variant="body2"
                              color="textPrimary"
                            >
                              {"device?.macID"}
                            </Mui.Typography>
                            <br />
                            <Mui.Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              <ReactI18next.Trans i18nKey="Device name">
                                Device name
                              </ReactI18next.Trans>
                            </Mui.Typography>
                            <Mui.Typography
                              style={{ textTransform: "capitalize" }}
                              variant="body2"
                              color="textPrimary"
                            >
                              {"device?.deviceName"}
                            </Mui.Typography>
                            <br />
                            <Mui.Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              <ReactI18next.Trans i18nKey="License serial number">
                              License serial number
                              </ReactI18next.Trans>
                            </Mui.Typography>
                            <Mui.Typography variant="body2" color="textPrimary">
                              {"device?.serialNumber"}
                            </Mui.Typography>
                            <br />
                            <Mui.Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              <ReactI18next.Trans i18nKey="Customer device id">
                                Customer device id
                              </ReactI18next.Trans>
                            </Mui.Typography>
                            <Mui.Typography variant="body2" color="textPrimary">
                              {"device?.customerDeviceID"}
                            </Mui.Typography>
                          </Mui.CardContent>
                        </Mui.CardActionArea>
                      </Mui.Card>
                    </Mui.Grid>
                  </Mui.Grid>
                </>
              </Mui.CardContent>
            </Mui.CardActionArea>
          </Mui.Card>
        </div>
      </div>
      {customers.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          page={page + 1}
          count={Math.ceil(customers.length / rowsPerPage)}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
    </>
  );
}
