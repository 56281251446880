/** @format */

import * as Mui from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as CustomerInvite from "src/app/pages/dealercustomer/customerinvite";
import * as Models from "src/models";
import * as Pages from "src/app/pages";
import * as Yup from "yup";
import * as MuiIcons from "@material-ui/icons";
export interface FormProps {
  emailID: string;
  firstname?: string;
  lastname?: string;
  companyName?: string;
}

export const Main = Mobx.observer(function () {
  const navigate = Router.useNavigate();
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  const functionStore = React.useContext(App.Contexts.functionStore);
  const [displayLabel, setDisplayLabel] = React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const uid = userStore.user?.ETUID as number;
  const licenseStore = React.useContext(App.Contexts.license.store);
  const Email = localStorage.getItem("customermailID")
  const cid = localStorage.getItem("customerID")
  const homeStore = React.useContext(App.Contexts.homeStore);
  const [searchParams] = Router.useSearchParams();
  const [isFromManageOrder, setIsFromManageOrder] = React.useState<boolean>(false);
  const orderStore = React.useContext(App.Contexts.orderStore);

  const searchPara = searchParams.get("m");
  React.useEffect(()=>{
    const userRole = "customer"
    const EmailId = Email ? Email : undefined;
    const uid = cid ? cid : "0";
    if ((uid !== undefined && uid !== null) && Email) {
      homeStore.read({
        uid,
        userRole,
        EmailId,
      });
    }
      if(searchPara === "ManageOrder"){
        setIsFromManageOrder(true)
  }
  },[Email, cid, homeStore, navigate, searchPara])

  const frontName = localStorage.getItem("cfname");
  const endName = localStorage.getItem("clname");
  const comName = localStorage.getItem("ccname");
  const mailId = localStorage.getItem("mail");
  const validationSchema = Yup.object().shape<FormProps>({
    emailID: Yup.string().required("Email ID is required"),
  });

  const handleLabel = () => {
    setDisplayLabel(true);
  };
  const licenseList = orderStore.getAssignLicense();
  return (
    <>
      <Mui.Grid
        container
        spacing={2}
        style={{
          padding: "15px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
          borderRadius: "8px",
        }}
      >
        <Mui.Grid xs={12}>
        <MuiIcons.Person
            style={{
              border: "1px grey",
              borderRadius: "50%",
              backgroundColor: "lightgray",
              width: "30px",
              height: "30px",
              color: "grey",
              margin: "0px 0px 10px 6px",
              padding: "3px"
            }}
          />
          <Mui.Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginLeft: "46px",
              marginTop: "-42px",
              opacity:"0.8"
            }}
          >
            <ReactI18next.Trans i18nKey="Customer details">
            Customer details
        </ReactI18next.Trans>
            
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid style={{ marginTop: "12px" }} item xs={12} md={4} lg={3}>
          <Mui.TextField
            name="emailID"
            value={mailId}
            fullWidth
            label={t("Email")}
            margin="dense"
            size="small"
            InputProps={{
              readOnly: true,
            }}
          ></Mui.TextField>
        </Mui.Grid>

        <Mui.Grid style={{ marginTop: "12px" }} item xs={12} md={4} lg={3}>
          <Mui.TextField
            name="Companyname"
            value={comName}
            fullWidth
            label={t("Company name")}
            margin="dense"
            size="small"
            InputProps={{
              readOnly: true,
            }}
          ></Mui.TextField>
        </Mui.Grid>

        <Mui.Grid style={{ marginTop: "12px" }} item xs={12} md={4} lg={3}>
          <Mui.TextField
            name="Companyname"
            value={frontName}
            fullWidth
            label={t("First name")}
            margin="dense"
            size="small"
            disabled
          ></Mui.TextField>
        </Mui.Grid>
        <Mui.Grid
          style={{ marginTop: "12px", marginBottom: "24px" }}
          item
          xs={12}
          md={4}
          lg={3}
        >
          <Mui.TextField
            name="Companyname"
            value={endName}
            fullWidth
            label={t("Last name")}
            margin="dense"
            size="small"
            disabled
          ></Mui.TextField>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Button
        color="primary"
        variant="contained"
        size="small"
        onClick={handleLabel}
        style={{
          marginTop: "24px",
          float: "right",
        }}
      >
        <ReactI18next.Trans i18nKey="Continue">
         Continue
        </ReactI18next.Trans>
      </Mui.Button>
      {displayLabel && (
        <Mui.Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: theme.spacing(2) }}
        >
          <ReactI18next.Trans key={"Selected license"}>
            Selected license
          </ReactI18next.Trans>
        </Mui.Typography>
      )}
      {displayLabel && (
        <Mui.Grid container spacing={3} style={{ marginTop: theme.spacing(2) }}>
         <Pages.Order.ManageOrder  licenseList={licenseList} isManageOrderDetail={true}/>  
        </Mui.Grid>
      )}
    </>
  );
});

const AvailableArticles = Mobx.observer(() => {
  const functionStore = React.useContext(App.Contexts.functionStore);

  const handleSelect = ({ article }: { article: Models.Set.Article }) => {
    const selectedIndex = customerInviteStore.selected.indexOf(article.setID);
    let newSelected: number[] = [];
    //alert('handleSelect');
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected,
        article.setID
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(customerInviteStore.selected.slice(1));
    } else if (selectedIndex === customerInviteStore.selected.length - 1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, selectedIndex),
        customerInviteStore.selected.slice(selectedIndex + 1)
      );
      setDisable(false);
    }

    customerInviteStore.setSelected({ selected: newSelected });
  };
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  const licenseStore = React.useContext(App.Contexts.license.store);
  const userStore = React.useContext(App.Contexts.userStore);
  const uid = userStore.user?.ETUID as number;
  const userRole = userStore.user?.RoleName ?? "customer";
  const [disable, setDisable] = React.useState<boolean>(true);
  const [getArticle, setArticle] = React.useState<Models.Set.Article[]>();
  const theme = Mui.useTheme();

  React.useEffect(() => {
    if (
      !licenseStore.licenses?.length &&
      userRole === "customer" &&
      uid !== undefined &&
      uid !== null
    )
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });
    if (!functionStore.functions.length) functionStore.read();
    customerInviteStore.readSetFuncations();
  }, [customerInviteStore, functionStore, licenseStore, uid, userRole]);

  return (
    <Mui.Grid container spacing={2}>
      {customerInviteStore.getAvailableArticlesFromLicenses.length > 0 &&
        customerInviteStore.getFilteredAvailableArticles.length === 0 &&
        customerInviteStore.search && (
          <Mui.Grid item xs={12}>
            <MuiLab.Alert severity="info">
              <MuiLab.AlertTitle>No license(s) found</MuiLab.AlertTitle>
              No available license(s) found for the search term. Use a different
              search term or clear the search to view some available license(s).
            </MuiLab.Alert>
          </Mui.Grid>
        )}
      {customerInviteStore.getArticlesFromLicenses.map((article) => (
        <Mui.Grid item xs={3} lg={3} key={article.setID}>
          <CustomerInvite.Card
            selected={customerInviteStore.selected.includes(article.setID)}
            functions={functionStore.functions?.filter((func) =>
              (article as Models.Set.Article).functions.includes(
                func.setFunctionID
              )
            )}
            handleClick={handleSelect}
            article={article}
          />
        </Mui.Grid>
      ))}
      <Mui.Button
        style={{
          float: "right",
          borderRadius: "4px",
          width: "150px",
          height: "43px",
          marginBottom: theme.spacing(3),
        }}
        color="primary"
        variant="contained"
        type="submit"
        disabled={disable}
      >
        <ReactI18next.Trans i18nKey="Assign license">
          Assign license
        </ReactI18next.Trans>
      </Mui.Button>
    </Mui.Grid>
  );
});
