/** @format */

import * as Mui from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as MuiIcons from "@material-ui/icons";
import * as ReactI18next from "react-i18next";
const CustomTextField = Mobx.observer(function (
  props: FormikMui.TextFieldProps
) {
  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const easyTouchDeviceStore = React.useContext(
    App.Contexts.easyTouchDeviceStore
  );
  const userStore = React.useContext(App.Contexts.userStore);
  const prevValue = React.useRef("");

  const {
    form: { setFieldTouched },
    field: { name },
  } = props;

  const onBlur = React.useCallback(
    async (event) => {
      const { value } = event.target;
      const etuid = userStore.user?.ETUID;
      setFieldTouched(name);
      if (!value && activateStore.installed.length) {
        activateStore.setInstalled({ installed: [] });
      }

      if (!value || !etuid || value === prevValue.current) {
        prevValue.current = value;
        return;
      }

      try {
        activateStore.setIsLoadingPreviouslyInstalledArticles({
          isLoadingPreviouslyInstalledArticles: true,
        });

        await easyTouchDeviceStore.read({
          userRole: "customer",
          status: -1,
          uid: etuid,
        });

        const installed: Set<number> = new Set();
        easyTouchDeviceStore.devices
          ?.filter((device) => device.macID === value)
          .map((device) => {
            device.sets.forEach((set) => installed.add(set.setID));
          });

        if (installed.size > 0 && activateStore.selected.length > 0) {
          activateStore.setSelected({
            selected: activateStore.selected.filter(
              (setID) => !installed.has(setID)
            ),
          });
        }

        activateStore.setInstalled({
          installed: Array.from(installed.values()),
        });
      } catch (error) {
        console.error(error);
      } finally {
        activateStore.setIsLoadingPreviouslyInstalledArticles({
          isLoadingPreviouslyInstalledArticles: false,
        });
        prevValue.current = value;
      }
    },
    [activateStore, easyTouchDeviceStore, name, setFieldTouched, userStore.user]
  );

  return (
    <>
      <Mui.TextField {...FormikMui.fieldToTextField(props)} onBlur={onBlur} />
    </>
  );
});

export const Form = Mobx.observer(function () {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const { submitCount, submitForm } = Formik.useFormikContext();

  return (
    <>
      <Mui.Grid container>
        <MuiIcons.Person
          style={{
            border: "1px grey",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    width: "30px",
                    height: "30px",
                    color: "grey",
                    margin: "0px 0px 10px 6px",
                    padding: "3px"
          }}
        />
        <Mui.Typography
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
            verticalAlign: "text-bottom",
            opacity:"0.8"
          }}
        >
          Customer Details
        </Mui.Typography>
      </Mui.Grid>
      <Formik.Form>
        <Formik.Field
          name="emailID"
          component={CustomTextField}
          fullWidth
          label={t("Email")}
          margin="dense"
        />

        <Formik.Field
          name="companyName"
          component={CustomTextField}
          fullWidth
          label={t("Company name")}
          margin="dense"
        />
        <Formik.Field
          name="firstname"
          component={CustomTextField}
          fullWidth
          label={t("First name")}
          margin="dense"
        />
        <Formik.Field
          name="lastname"
          component={CustomTextField}
          fullWidth
          label={t("Last name")}
          margin="dense"
        />
        <Mui.Box
          position="absolute"
          width={"calc(100% - 40px)"}
          bottom={theme.spacing(2.5)}
          right={theme.spacing(2.5)}
        >
          <Mui.Button
            onClick={submitForm}
            color="primary"
            variant="contained"
            component={Router.Link}
            to="/dealercustomer/customerinvite"
            fullWidth
          >
            <ReactI18next.Trans i18nKey="Assign licenses">
            Assign Licenses
            </ReactI18next.Trans>
            
          </Mui.Button>
        </Mui.Box>
      </Formik.Form>
    </>
  );
});
