/** @format */

import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Function from "src/app/pages/function";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
  })
);

export declare namespace Translate {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  > & {
    func?: Models.Set.Function;
  };
}

// eslint-disable-next-line no-redeclare
export const Translate = Mobx.observer(function ({
  open,
  handleClose,
  func,
}: Translate.Props) {
  const [initialValues, setInitialValues] = React.useState<
    Function.TranslateForm.Values
  >();
  const theme = Mui.useTheme();
  const classes = useStyles();
  const functionStore = React.useContext(App.Contexts.functionStore);
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const userStore = React.useContext(App.Contexts.userStore);
  const functionService = new Services.Function({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    if (!func) {
      setInitialValues(undefined);
      return;
    }

    const initialValues = {
      funcationName_de:
        func?.setFunctionsLangs?.reduce((name: string, lang) => {
          if (lang.langCode.toLowerCase() === "de") {
            return name + lang.funcationName;
          }
          return name;
        }, "") || "",
      funcationName_es:
        func?.setFunctionsLangs?.reduce((name: string, lang) => {
          if (lang.langCode.toLowerCase() === "es") {
            return name + lang.funcationName;
          }
          return name;
        }, "") || "",
      funcationName_fr:
        func?.setFunctionsLangs?.reduce((name: string, lang) => {
          if (lang.langCode.toLowerCase() === "fr") {
            return name + lang.funcationName;
          }
          return name;
        }, "") || "",
      funcationName_it:
        func?.setFunctionsLangs?.reduce((name: string, lang) => {
          if (lang.langCode.toLowerCase() === "it") {
            return name + lang.funcationName;
          }
          return name;
        }, "") || "",
    } as Function.TranslateForm.Values;

    setInitialValues(initialValues);
  }, [func]);

  async function handleTranslate(
    values: Function.TranslateForm.Values,
    { setSubmitting }: Formik.FormikHelpers<Function.TranslateForm.Values>
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      if (!func) {
        throw new Error(t("func is undefined"));
      }

      const setFunctionsLangs: Partial<Models.SetFunctionsLang>[] = [
        {
          funcationName: values.funcationName_de,
          setFunctionID: func.setFunctionID,
          langCode: "de",
          funcationCode: "",
        },
        {
          funcationName: values.funcationName_fr,
          setFunctionID: func.setFunctionID,
          langCode: "fr",
          funcationCode: "",
        },
        {
          funcationName: values.funcationName_es,
          setFunctionID: func.setFunctionID,
          langCode: "es",
          funcationCode: "",
        },
        {
          funcationName: values.funcationName_it,
          setFunctionID: func.setFunctionID,
          langCode: "it",
          funcationCode: "",
        },
      ];
      await functionService.createLangs({ body: setFunctionsLangs, uid });
      enqueueSnackbar(t("Function successfully translated!"), {
        variant: "success",
        preventDuplicate: false,
      });
      functionStore.read();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Formik.Formik
        enableReinitialize
        initialValues={initialValues || ({} as Function.TranslateForm.Values)}
        validationSchema={
          Function.Shared({ t }).Translate.Form.validationSchema
        }
        onSubmit={handleTranslate}
      >
        {({ isSubmitting, submitForm }) =>  { 
        
          return (
          <Components.LightBox
          open={open}
          handleClose={handleClose}
          content={
            <>
              {func && (
                <>
                  <Mui.Typography variant="body2" color="textSecondary">
                    <ReactI18next.Trans i18nKey="Function name EN">
                      Function name EN
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography gutterBottom>
                    {func?.funcationName}
                  </Mui.Typography>
                </>
              )}
              <Function.TranslateForm />
            </>
          }
          title={
            <Mui.Typography
              style={{ fontWeight: "bold" }}
              variant="h6"
              className={classes.title}
            >
              {t("Translate function name")}
            </Mui.Typography>
          }
          actions={
            <>
              <Mui.Button variant="contained" onClick={handleClose}>
                <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
              </Mui.Button>
              <Mui.Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={() => submitForm()}
              >
                <ReactI18next.Trans i18nKey="Save / Update">
                  Save / Update
                </ReactI18next.Trans>
                {isSubmitting && (
                  <Mui.CircularProgress
                    style={{
                      marginLeft: theme.spacing(1),
                    }}
                    color={"inherit"}
                    size={theme.typography.button.fontSize}
                  />
                )}
              </Mui.Button>
            </>
          }
        />
        )}
          
        }
      </Formik.Formik>
    </>
  );
});
