import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Order from "src/app/pages/order";
import * as Tenant from "src/app/pages/tenant";
import * as Configs from "src/configs";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

interface Data extends Models.Tenant.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order?: Order;
  orderBy?: string;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id?: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    if (!onRequestSort) {
      return;
    }
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      {orderBy && props.id ? (
        <Mui.TableSortLabel
          active={orderBy === props.id}
          direction={orderBy === props.id ? order : "asc"}
          onClick={createSortHandler(props.id as keyof Data)}
        >
          {props.label}
          {orderBy === props.id ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </Mui.TableSortLabel>
      ) : (
        props.label
      )}
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({
  rows,
  handleReadyClick,
}: {
  rows: Models.Tenant.Main[];
  handleReadyClick(props: { tenantId: string | number }): void;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("companyAliasName");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  return (
    <div className={classes.root}>
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead
                leftmost
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                id="companyAliasName"
                label={t("Alias name")}
              />

              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                id="companyName"
                label={t("Name")}
              />

              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                id="firstName"
                label={t("Admin")}
              />

              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                id="userName"
                label={t("User name")}
              />

              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                id="isActive"
                label={t("Status")}
              />

              <EnhancedTableHead
                classes={classes}
                rightmost
                numeric
                label={t("Actions")}
              />
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id as string)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={uuidv4()}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.companyAliasName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.companyName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.firstName}&nbsp;{row.lastName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.userName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {row.isActive ? "Ready" : "Requested"}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell rightmost align="right">
                      <Mui.IconButton
                        disabled={row.isActive ? true : false}
                        onClick={() =>
                          handleReadyClick({
                            tenantId: row.id,
                          })
                        }
                        size="small"
                        style={{
                          padding: theme.spacing(0.125),
                        }}
                      >
                        <Mui.Box fontSize={26} lineHeight={0}>
                          <i className="icon-etc-activate" />
                        </Mui.Box>
                      </Mui.IconButton>
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          page={page + 1}
          count={Math.ceil(rows.length / rowsPerPage)}
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [activateDialogOpen, setActivateDialogOpen] = React.useState<
    Tenant.Store.UpdateCompanyReady.Props
  >();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const globalTenantStore = React.useContext(App.Contexts.globalTenantStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const navigate = Router.useNavigate();
  const theme = Mui.useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = Notistack.useSnackbar();

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;

    if (userRole !== "admin" && userRole !== "superadmin") {
      return;
    }

    tenantStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;
    const etuid = userStore.user?.ETUID;

    if ((userRole !== "customer" && userRole !== "EU") || !etuid) {
      return;
    }

    globalTenantStore.read({ etuid });

    if (
      globalTenantStore.tenant === null ||
      globalTenantStore.tenant === undefined
    ) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    tenantStore.setSearch({ search: event.target.value as string });
  };

  const { t } = ReactI18next.useTranslation();

  async function handleActivateFunction(
    props: Tenant.Store.UpdateCompanyReady.Props
  ) {
    setIsLoading(true);
    const uid = userStore.user?.uid;
    if (activateDialogOpen === undefined || uid === undefined) {
      
      return;
    }
    try {
      tenantStore.updateCompanyReady(props);
      enqueueSnackbar(t("Tenant is now ready!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setIsLoading(false);
      setActivateDialogOpen(undefined);
      tenantStore.read();
    }
  }

  return (
    <>
      <Components.ResponsiveFullscreenDialogWithScroll
        open={activateDialogOpen !== undefined ? true : false}
        handleClose={() => {
          setActivateDialogOpen(undefined);
        }}
        title={t("Activate tenant")}
        contentText={t("Are you sure you want to activate this tenant?")}
        actions={
          <>
            <Mui.Button
              variant="contained"
              onClick={() => {
                setActivateDialogOpen(undefined);
              }}
            >
              <ReactI18next.Trans i18nKey="Cancel">Cancel</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              onClick={() =>
                handleActivateFunction(
                  activateDialogOpen as Tenant.Store.UpdateCompanyReady.Props
                )
              }
              disabled={isLoading}
              {...(!isLoading && {
                style: {
                  backgroundColor: theme.palette.success.main,
                  color: theme.palette.common.white,
                },
              })}
            >
              <ReactI18next.Trans i18nKey="Activate">
                Activate
              </ReactI18next.Trans>
              {isLoading && (
                <Mui.CircularProgress
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  color={"inherit"}
                  size={theme.typography.button.fontSize}
                />
              )}
            </Mui.Button>
          </>
        }
      />
      <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
        <>
          <Mui.Grid
            container
            justify="flex-start"
            alignItems="center"
            style={{ marginBottom: theme.spacing(3) }}
          >
            <Mui.FormControl style={{ width: 300 }}>
              <Mui.TextField label={t("Search")} onChange={handleSearch} />
            </Mui.FormControl>
          </Mui.Grid>
          {tenantStore.isInitialLoading ? (
            <Mui.Box
              padding={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              minHeight={"100%"}
              maxHeight={"100%"}
              height={400}
              flexDirection="column"
            >
              <Mui.CircularProgress />
            </Mui.Box>
          ) : (
            <>
              <EnhancedTable
                rows={tenantStore.filtered}
                handleReadyClick={(props) => {
                  setActivateDialogOpen({ body: props });
                }}
              />
            </>
          )}
        </>
      </Containers.ShowIfAuthorised>
      {globalTenantStore.tenant && (
        <Containers.ShowIfAuthorised roles={["customer", "EU"]}>
          <>
            <Mui.Card style={{ width: "100%" }}>
              <Mui.CardHeader
                title={
                  <Mui.Typography color="textSecondary" variant="h6">
                    <ReactI18next.Trans i18nKey="Your tenant details">
                    Your tenant details
                  </ReactI18next.Trans>
                  </Mui.Typography>
                }
              />
              <Mui.Divider />
              <Mui.CardContent>
                <div className={classes.root}>
                  <div>
                    <div>
                      <Mui.Typography className={classes.instructions}>
                        <Mui.Box minHeight={275}>
                          <Mui.Grid container spacing={3}>
                            {globalTenantStore.tenant?.isActive ? (
                              <Mui.Grid item xs={12}>
                                <Mui.Typography
                                  component="div"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  <MuiLab.Alert severity="success">
                                    <MuiLab.AlertTitle>
                                    <ReactI18next.Trans i18nKey="Your tenant is created successfully!">
                                    Your tenant is created successfully!
                                    </ReactI18next.Trans>
                                    </MuiLab.AlertTitle>
                                    <ReactI18next.Trans i18nKey="Your have successfully created a tenant and following are your tenant details.">
                                    Your have successfully created a tenant and following are your tenant details.
                                    </ReactI18next.Trans>
                                  </MuiLab.Alert>
                                </Mui.Typography>
                              </Mui.Grid>
                            ) : (
                              <Mui.Grid item xs={12}>
                                <Mui.Typography
                                  component="div"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  <MuiLab.Alert severity="success">
                                    <MuiLab.AlertTitle>
                                    <ReactI18next.Trans i18nKey="Congradulations! Request Placed!">
                                    Congradulations! Request Placed!
                                    </ReactI18next.Trans>
                                    </MuiLab.AlertTitle>
                                    <ReactI18next.Trans i18nKey="You've successfully placed your tenant creation request">
                                    You've successfully placed your tenant creation request with the following details. Our system has started processing the request and you'll be notified when your request is fulfilled by our system.
                                    </ReactI18next.Trans>
                                  </MuiLab.Alert>
                                </Mui.Typography>
                              </Mui.Grid>
                            )}
                            <Mui.Grid item xs={12}>
                              <Mui.Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                              >
                                 <ReactI18next.Trans i18nKey="You can access your ET Cloud admin portal from the following URL">
                                 You can access your ET Cloud admin portal from the following URL
                                </ReactI18next.Trans>
                              </Mui.Typography>
                              <Mui.Link
                                href={`${
                                  Configs.ET_CLOUD?.BASE_URL
                                }/${globalTenantStore.tenant?.companyAliasName
                                  .split(" ")
                                  .join("_")
                                  .toLowerCase()}`}
                              >
                                {`${
                                  Configs.ET_CLOUD?.BASE_URL
                                }/${globalTenantStore.tenant?.companyAliasName
                                  .split(" ")
                                  .join("_")
                                  .toLowerCase()}`}
                              </Mui.Link>
                            </Mui.Grid>

                            <Mui.Grid item xs={12}>
                              <Mui.Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                              >
                                <ReactI18next.Trans i18nKey="Database name">
                                Database name
                                </ReactI18next.Trans>
                              </Mui.Typography>
                              <Mui.Typography>{`${globalTenantStore.tenant?.companyAliasName
                                .split(" ")
                                .join("_")}_ETDB`}</Mui.Typography>
                            </Mui.Grid>

                            {globalTenantStore.tenant?.userName && (
                              <Mui.Grid item xs={12}>
                                <Mui.Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <ReactI18next.Trans i18nKey="Admin user name">
                                  Admin user name
                                </ReactI18next.Trans>
                                </Mui.Typography>
                                <Mui.Typography>
                                  {globalTenantStore.tenant?.userName}
                                </Mui.Typography>
                              </Mui.Grid>
                            )}

                            {globalTenantStore.tenant?.firstName &&
                              globalTenantStore.tenant?.lastName && (
                                <Mui.Grid item xs={12}>
                                  <Mui.Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <ReactI18next.Trans i18nKey="Admin name">
                                    Admin name
                                </ReactI18next.Trans>
                                  </Mui.Typography>
                                  <Mui.Typography>{`${globalTenantStore.tenant?.firstName} ${globalTenantStore.tenant?.lastName}`}</Mui.Typography>
                                </Mui.Grid>
                              )}
                          </Mui.Grid>
                        </Mui.Box>
                      </Mui.Typography>
                    </div>
                  </div>
                </div>
              </Mui.CardContent>
            </Mui.Card>
          </>
        </Containers.ShowIfAuthorised>
      )}
    </>
  );
});
