import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Payment() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["customer","dealer"]}
          path="success"
          element={<Pages.Shop.Payment.Success.Main />}
        />
      
        <Routes.Route
          restricted
          roles={["customer","dealer"]}
          path="process"
          element={<Pages.Shop.Payment.Process />}
        />
      </Router.Routes>
    </>
  );
}
