export declare namespace Workaround {
  export namespace IsCloudArticle {
    export interface Props {
      code: string;
    }
  }
}

const hundCodes = [
  process.env.REACT_APP_CLOUD_ARTICLE_HUND_CODE?.toLowerCase(),
];
const typeCodes = [
  process.env.REACT_APP_CLOUD_ARTICLE_TYPE_CODE?.toLowerCase(),
];

// eslint-disable-next-line no-redeclare
export const Workaround = {
  isCloudArticle: ({ code }: Workaround.IsCloudArticle.Props): boolean => {
    if (
      !typeCodes.includes(code.toLowerCase()) &&
      !hundCodes.includes(code.toLowerCase())
    ) {
      return false;
    }

    return true;
  },
};
