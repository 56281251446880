/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as License from "src/app/pages/license";
import { v4 as uuidv4 } from "uuid";
import * as Router from "react-router-dom";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    link: {
      display: "flex",
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

export const Main = Mobx.observer(function () {
  const [detailsDrawerOpen, setDetailsDrawerOpen] = React.useState<boolean>(
    false
  );
  const [customerID, setCustomerID] = React.useState<any>();
  const [currentLicense, setCurrentLicense] = React.useState<boolean>(false);
  const customerStore = React.useContext(App.Contexts.customerStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const classes = useStyles();
  const theme = Mui.useTheme();
  const [searchParams] = Router.useSearchParams();
  const userID = searchParams.get("userID");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    
    const uid = userStore.user?.ETUID;
    const customer = event.target.value as string | number;
    setCustomerID(customer)
    console.log("chyangecustomer"+customer)
    licenseStore.setCustomer({ customer });

    if (uid === undefined || uid === null) {
      return;
    }

    if (customer === "" || customer === undefined || customer === null) {
      return;
    }

    licenseStore.read({
      body: { status: null, userID: customer },
      userRole: "admin",
      uid,
    });
  };
  const handleChangeManual = () => {
    if(userStore.user?.RoleName === 'admin' && userID != null && userID !== '')
    {

    const uid = userStore.user?.ETUID;
    const customer = userID as string | number;
    licenseStore.setCustomer({ customer });

    if (uid === undefined || uid === null) {
      return;
    }

    if (customer === "" || customer === undefined || customer === null) {
      return;
    }

    licenseStore.read({
      body: { status: null, userID: customer },
      userRole: "admin",
      uid,
    });
  }};
 const [filter,setFilter] = React.useState<string>("1")
 const [value,setValue] = React.useState<string>("1")
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    licenseStore.setSearch({ search: event.target.value as string });
  };

  const handleStatusFilterChange = (
    event: any
  ) => {
      const search = (event.target.value).toString()
      setValue((event.target.value).toString())
      setFilter(search)
  };

  const toggleDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };
  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName ?? "customer";
    if (userRole === "customer" || userRole === "dealer" || userRole === "admin" || userRole === "superadmin") {
      if (uid !== undefined && uid !== null) {
        orderStore.read({
          uid,
          userRole,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName ?? ("customer" || "dealer");
    if (
      (userRole === "customer" || userRole === "dealer") &&
      uid !== undefined &&
      uid !== null
    ) {
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });
    }

    if (userRole === "admin" || userRole === "superadmin") {
      customerStore.read({});
    }

    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(()=>{
    handleChangeManual();
  },[handleChangeManual]);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const { t } = ReactI18next.useTranslation();
  /******************** Duplication article remove filter and Filtering based on the available and used  ********************/
 const [removingLicenseDuplication,setRemovingLicenseDuplication]= React.useState<any>([])
 React.useEffect(()=>{
   /********************Filtering not applied based on available and used********************/
if(filter === ""){
  setRemovingLicenseDuplication(licenseStore.filtered.filter(
    (k) => orderStore.orders.length === 0 ? k.licenseCode === "CustInvite": k.setID !== 2
  ))
}
 /********************Filtering  applied based on available("1" means Available)********************/
else if(filter === "1"){
  setRemovingLicenseDuplication(licenseStore.filtered.filter(
    (k) => orderStore.orders.length === 0 ? k.licenseCode === "CustInvite": k.setID !== 2
  ).filter(k=> k.totalAvailable > 0 ))
}
 /********************Filtering  applied based on available("0" means Used)********************/
else if(filter === "0"){
  setRemovingLicenseDuplication(licenseStore.filtered.filter(
    (k) => orderStore.orders.length === 0 ? k.licenseCode === "CustInvite": k.setID !== 2
  ).filter(k=> k.totalAvailable === parseInt(filter) ))
}
 },[filter, licenseStore.filtered, orderStore.orders.length])
  
 const navigate = Router.useNavigate();

   //

  /********************End of duplication article remove filter  ********************/
  return (
    <>
    <React.Suspense fallback={<Mui.CircularProgress />}>
      <License.Details
        open={detailsDrawerOpen}
        handleClose={toggleDetailsDrawer}
        functionData={functionStore.functions}
        license={currentLicense}
      />
      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Containers.ShowIfAuthorised roles={["customer", "dealer"]}>
      
        <Mui.FormControl
            style={{ width: 300, marginRight: theme.spacing(2) }}
          >
             {(removingLicenseDuplication.length > 0 || filter === "0") && ( <><Mui.InputLabel id="demo-simple-select-outlined-label">
                <ReactI18next.Trans i18nKey="Filter by status">
                  Filter by status
                </ReactI18next.Trans>
              </Mui.InputLabel><Mui.Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value}
                onChange={handleStatusFilterChange}
                label={t("Filter by status")}
              >
                  <Mui.MenuItem value="">
                    <em>
                      <ReactI18next.Trans i18nKey="None">None</ReactI18next.Trans>
                    </em>
                  </Mui.MenuItem>
                  <Mui.MenuItem value="1">
                    <Mui.Chip
                      size="small"
                      icon={<MuiIcons.Brightness1
                        style={{
                          color: theme.palette.success.main
                        }} />}
                      label="Available"
                      variant="outlined" />
                  </Mui.MenuItem>

                  <Mui.MenuItem value="0">
                    <Mui.Chip
                      size="small"
                      icon={<MuiIcons.Brightness1
                        style={{
                          color: theme.palette.error.main,
                        }} />}
                      label="Used"
      variant="outlined" 
    />
              </Mui.MenuItem>

            </Mui.Select></>)}
          </Mui.FormControl>
        </Containers.ShowIfAuthorised>

        <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
          <Mui.FormControl
            style={{ width: 300, marginRight: theme.spacing(2) }}
          >
            <Mui.InputLabel id="demo-simple-select-outlined-label">
              <ReactI18next.Trans i18nKey="Customer">
                Customer
              </ReactI18next.Trans>
            </Mui.InputLabel>
            <Mui.Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={licenseStore.customer}
              onChange={handleChange}
              label={t("Customer")}
            >
              <Mui.MenuItem value="">
                <em>
                  <ReactI18next.Trans i18nKey="None">None</ReactI18next.Trans>
                </em>
              </Mui.MenuItem>
              <Mui.Divider />
              {customerStore.customers.map((customer) => (
                <Mui.MenuItem
                  key={uuidv4()}
                  style={{
                    display: "grid",
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                  value={customer.etuid}
                >
                  <Mui.Box className={classes.link}>
                    <MuiIcons.Person className={classes.icon} />
                    {customer.firstName} {customer.lastName}
                  </Mui.Box>
                  <Mui.Box className={classes.link}>
                    <MuiIcons.Email className={classes.icon} />
                    {customer.userEmail}
                  </Mui.Box>
                  {customer.companyName && (
                    <Mui.Box className={classes.link}>
                      <MuiIcons.Business className={classes.icon} />
                      {customer.companyName}
                    </Mui.Box>
                  )}
                  {customer.departmentName && (
                    <Mui.Box className={classes.link}>
                      <MuiIcons.Category className={classes.icon} />
                      {customer.departmentName}
                    </Mui.Box>
                  )}
                </Mui.MenuItem>
              ))}
            </Mui.Select>
          </Mui.FormControl>
        </Containers.ShowIfAuthorised>
        <Mui.FormControl style={{ width: 300 }}>
        {((removingLicenseDuplication.length > 0) && (userStore.user?.RoleName === "customer" && "dealer")) && <Mui.TextField label={t("Search")} onChange={handleSearch} />}
        </Mui.FormControl>
      </Mui.Grid>
      <Mui.Grid container spacing={3}>
        {licenseStore.isInitialLoading ||
        ((userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin") && licenseStore.isLoading) ? (
          <Mui.Box
            padding={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            minHeight={"100%"}
            maxHeight={"100%"}
            height={400}
            flexDirection="column"
          >
            <Mui.CircularProgress />
          </Mui.Box>
        ) : (
          <>
            {licenseStore.showAdminCustomerSelectionInfo && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                  <MuiLab.AlertTitle>
                    <ReactI18next.Trans i18nKey="Select customer!">
                      Select customer!
                    </ReactI18next.Trans>
                  </MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="Select a customer from the above dropdown">
                    Select a customer from the above dropdown selection to view
                    their licenses.
                  </ReactI18next.Trans>
                </MuiLab.Alert>
              </Mui.Grid>
            )}
            {licenseStore.showAdminNoRecordsInfo && filter !== "0" && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                  <MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="No licenses found">
                  No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                        <ReactI18next.Trans i18nKey="There are no licenses">
                        There are no licenses found for the selected customer! Please try selecting a different customer to view their licenses.
                        </ReactI18next.Trans>
            
                </MuiLab.Alert>
              </Mui.Grid>
            )}
            {licenseStore.showAdminNoRecordsInfo && filter === "0" && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert severity="info" style={{ width: "100%" }}>
              
                  <ReactI18next.Trans i18nKey="No used licenses found">
                  No used licenses found
                        </ReactI18next.Trans>
            
                </MuiLab.Alert>
              </Mui.Grid>
            )}
            {/* {licenseStore.showCustomerNoRecordsInfo && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert
                  severity="info"
                  style={{ width: "100%" }}
                  action={
                    <Mui.Button
                      variant="contained"
                      color="primary"
                      component={Router.Link}
                      to="/shop"
                    >
                       <ReactI18next.Trans i18nKey="Purchase articles">
                       Purchase articles
                      </ReactI18next.Trans>
                    </Mui.Button>
                  }
                >
                  <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No licenses found">
                  No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                        <ReactI18next.Trans i18nKey="There are no license(s)">
                        There are no license(s) found for you! Please try purchasing new license(s) or reach out to KERN admin.
                        </ReactI18next.Trans>
                  
                </MuiLab.Alert>
              </Mui.Grid>
            )} */}
            {licenseStore.showDealerNoRecordsInfo && (
              <Mui.Grid item xs={12}>
                <MuiLab.Alert
                  severity="info"
                  style={{ width: "100%" }}
                  action={
                    <Mui.Button
                      variant="contained"
                      color="primary"
                      component={Router.Link}
                      to="/shop"
                    >
                      Purchase license(s)
                    </Mui.Button>
                  }
                >
                  <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No licenses found">
                  No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                        <ReactI18next.Trans i18nKey="There are no license(s)">
                        There are no license(s) found for you! Please try purchasing new license(s) or reach out to KERN admin.
                        </ReactI18next.Trans>
                  
                </MuiLab.Alert>
              </Mui.Grid>
            )}
            {/* {userStore.user?.RoleName === ("customer") &&
              !licenseStore.showCustomerNoRecordsInfo &&
              removingLicenseDuplication.length === 0 &&
              licenseStore.status === 1 && (
                <Mui.Grid item xs={12}>
                  <MuiLab.Alert
                    severity="info"
                    style={{ width: "100%" }}
                    action={
                      <Mui.Button
                        variant="contained"
                        color="primary"
                        component={Router.Link}
                        to="/shop"
                      >
                        <ReactI18next.Trans i18nKey="Purchase licenses">
                        Purchase licenses
                      </ReactI18next.Trans>
                      </Mui.Button>
                    }
                  >
                    <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No licenses found">
                    No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                    <ul>
                      <li>
                      <ReactI18next.Trans i18nKey="Looks like you have">
                      Looks like you have completely used all your license(s).
                        </ReactI18next.Trans>
                      </li>
                      <li>
                      <ReactI18next.Trans i18nKey="If you">
                      If you are looking to view the used license(s) details, please change the above filter to "Used".
                        </ReactI18next.Trans>
                        
                      </li>
                      <li>
                      <ReactI18next.Trans i18nKey="Please try purchasing">
                      Please try purchasing new license(s) to have available license(s) shown here.
                        </ReactI18next.Trans>
                        
                      </li>
                    </ul>
                  </MuiLab.Alert>
                </Mui.Grid>
              )} */}
              {userStore.user?.RoleName === ("dealer") &&
              !licenseStore.showCustomerNoRecordsInfo &&
              removingLicenseDuplication.length === 0 &&
              licenseStore.status === 1 && (
                <Mui.Grid item xs={12}>
                   <MuiLab.Alert
                    severity="info"
                    style={{ width: "100%" }}
                    
                  >
                    <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No licenses found">
                    No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                    
                  
                        </MuiLab.Alert></Mui.Grid>
              )}
            {userStore.user?.RoleName === ("customer") &&
              
              removingLicenseDuplication.length === 0 && (
                <Mui.Grid item xs={12}>
                  <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                    <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No licenses found">
                    No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                    {/* <ul>
                      <li>
                      <ReactI18next.Trans i18nKey="Looks like you haven't">
                      Looks like you haven't really fully utilised your purchased license(s).
                        </ReactI18next.Trans>
                      </li>
                      <li>
                      <ReactI18next.Trans i18nKey="If you are looking">
                      If you are looking to view the available license(s) details, please change the above filter to "Available".
                        </ReactI18next.Trans>
                      </li>
                      <li>
                      <ReactI18next.Trans i18nKey="Please try installing">
                      Please try installing your all available installations per license(s) to your device(s) to have used license(s) shown here.
                        </ReactI18next.Trans>
                      </li>
                    </ul> */}
                  </MuiLab.Alert>
                </Mui.Grid>
              )}
              {userStore.user?.RoleName === ("dealer") &&
              
              removingLicenseDuplication.length === 0 &&
              licenseStore.status === 2 && (
                <Mui.Grid item xs={12}>
                  <MuiLab.Alert severity="info" style={{ width: "100%" }}>
                    <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No licenses found">
                    No licenses found
                        </ReactI18next.Trans></MuiLab.AlertTitle>
                    
                  </MuiLab.Alert>
                </Mui.Grid>
              )}
            {removingLicenseDuplication.map((device: any) => (
              <Mui.Grid key={uuidv4()} item xs={12} md={6}>
                <License.Card
                  license={{
                    ...device,
                    setFuncstionsList: functionStore.functions.filter((func) =>
                      device.setList?.functions.includes(func.setFunctionID)
                    ),
                  }}
               
                  handleClick={() => {
                    if(userStore.user?.RoleName !== "dealer")
                    {
                      setCurrentLicense(device);
                      toggleDetailsDrawer();
                    }
                    var license = {
                      ...device,
                      setFuncstionsList: functionStore.functions.filter((func) =>
                        device.setList?.functions.includes(func.setFunctionID)
                      ),
                    }
                    const userRole = userStore.user?.RoleName;
                  }}
                />
              </Mui.Grid>
            ))}
          </>
        )}
      </Mui.Grid>
      </React.Suspense>
    </>
  );
});
