/** @format */

import * as Mui from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import * as DateFns from "date-fns";
import * as Faker from "faker";
import * as Mobx from "mobx-react-lite";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";

Numeral.register("locale", "fr", {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});
Numeral.locale("fr");

export const Dashboard = Mobx.observer(function () {
  const { t } = ReactI18next.useTranslation();
  const [dummyYoySalesData, setDummyYoySalesData] = React.useState<any>();
  const theme = Mui.useTheme();
  const navigate = Router.useNavigate();

  const userStore = React.useContext(App.Contexts.userStore);
  const homeStore = React.useContext(App.Contexts.homeStore);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const articleStore = React.useContext(App.Contexts.articleStore);

  const lic = homeStore.dashboard?.sets?.map((row:any)=>row);
  

  React.useEffect(() => {
    const userRole = userStore.user?.RoleName;
    if (
      userRole === "customer" &&
      homeStore.dashboard?.myLicense === 0 &&
      homeStore.dashboard?.assignedLicense === 0
    ) {
      navigate("/Activate");
      return;
    }
    if (userRole === "dealer" && homeStore.dashboard?.myLicense === 0) {
      navigate("/Activate");
      return;
    }
    orderStore.clearOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeStore.dashboard, userStore.user]);
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    // userStore.setPreviousUrl({ urls: "/dealercustomer?roles=all" });
    const userRole = "customer";
    if (uid !== undefined && uid !== null) {
      dCustomerStore.read({
        uid,
        userRole,
      });
    } 
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dCustomerStore]);
  React.useEffect(() => {
    const uid = userStore.user?.ETUID ? userStore.user?.ETUID : "0";
    //alert(uid);
    const userRole = userStore.user?.RoleName ?? ("customer" || "dealer");
    const EmailId = userStore.user?.UserEmail ? userStore.user?.UserEmail : undefined;
    if (uid !== undefined && uid !== null) {
      homeStore.read({
        uid,
        userRole,
        EmailId,
      });
    }

    if (
      userRole === ("customer" || "dealer") &&
      uid !== undefined &&
      uid !== null
    ) {
      articleStore.read({status:1});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const thisYear = DateFns.getYear(new Date());
    const dummyYoySalesData = [
      t("Jan"),
      t("Feb"),
      t("Mar"),
      t("Apr"),
      t("May"),
      t("Jun"),
      t("Jul"),
      t("Aug"),
      t("Sep"),
      t("Oct"),
      t("Nov"),
      t("Dec"),
    ].map((month) => {
      return {
        month,
        [thisYear]: Faker.random.number({ min: 10, max: 53 }),
        [thisYear - 1]: Faker.random.number({ min: 0, max: 25 }),
      };
    });
    setDummyYoySalesData(dummyYoySalesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const useStyles = makeStyles({
    table: {
      minWidth: 10,
      padding:"5px 25px 25px 25px"
    },
  });
  const classes = useStyles();
  const totalBasicLicenses = homeStore.dashboard?.licenseSummary?.filter((row:any) => row.hundCode  === 'SET-01' )
  const basicSetLicenseAvail = totalBasicLicenses?.map((k:any)=>k.availableQuantity)
  const totalBasicLicenseInstock = userStore.user?.RoleName ==="dealer"? +basicSetLicenseAvail : (+basicSetLicenseAvail/4) 
  const totalBasicLicenseInstockDashBoarddisplay =  +basicSetLicenseAvail
  //
  localStorage.setItem("totalBasicLicensesInStock", JSON.stringify(totalBasicLicenseInstock));
  
  return (
    <>
  <Containers.ShowIfAuthorised roles={["customer"]}>
            <Mui.Paper
        square
        style={{
          marginBottom: "12px",
          borderLeft: "4px solid grey",
          borderRadius: "4px",
        }}
      >
        {/* <Mui.Grid container xs={12} >
          <Mui.Grid item>
            <Mui.Typography
              style={{
                height: "23px", 
                fontWeight: "bold",
                fontSize: "20px",
                margin: "0px 0 10px 12px",
                borderRadius: "none",
                opacity: "0.8",
              }}
            >
              <ReactI18next.Trans i18nKey="Installation based on the total installations of Set - 01">
              Each license can be installed on 4 devices
            </ReactI18next.Trans>
              
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid> */}
      </Mui.Paper>
      </Containers.ShowIfAuthorised>
     <Mui.Grid container spacing={3}>
        {homeStore.isInitialLoading ? (
          <Mui.Box
            padding={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            minHeight={"100%"}
            maxHeight={"100%"}
            height={1000}
            flexDirection="column"
          >
            <Mui.CircularProgress />
          </Mui.Box>
        ) : (
          <>
            

            <Containers.ShowIfAuthorised roles={["dealer"]}>
              <Mui.Grid item xs={12} md={4} lg={3}>
                <Components.QuickInfoTile
                  label={t("Licenses available")}
                  iconName={"icon-etc-orders"}
                  isColorChange = {homeStore.dashboard?.availableLicense > 0 ? true : false}
                  // handleClick={() => navigate("/dealercustomer")}
                  title={homeStore.dashboard?.availableLicense ?? ""}
                  body={t("Available")}
                />
              </Mui.Grid>
            </Containers.ShowIfAuthorised>

            <Containers.ShowIfAuthorised roles={["dealer"]}>
              <Mui.Grid item xs={12} md={4} lg={3}>
                <Components.QuickInfoTile
                  label={t("Licenses assigned")}
                  iconName={"icon-etc-orders"}
                  isColorChange = {false}
                title={homeStore.dashboard?.assignedLicense ?? ""}
                  body={t("Assigned")}
                />
              </Mui.Grid>
            </Containers.ShowIfAuthorised>

            
            <Containers.ShowIfAuthorised roles={["dealer"]}>
              <Mui.Grid item xs={12} md={4} lg={3}>
                <Components.QuickInfoTile
                  label={t("Licenses purchased")}
                  iconName={"icon-etc-licenses"}
                  isColorChange = {false}
                  handleClick={() => navigate("/licenses")}
                  title={homeStore.dashboard?.myLicense ?? ""}
                  body={t("Purchased")}
               
                />
              </Mui.Grid>
            </Containers.ShowIfAuthorised>
            
            <Containers.ShowIfAuthorised roles={[ "dealer"]}>
              <Mui.Grid item xs={12} md={4} lg={3}>
                <Components.QuickInfoTile
                  label={t("My orders")}
                  iconName={"icon-etc-orders"}
                  isColorChange = {false}
                  handleClick={() => navigate("/orders")}
                  title={homeStore.dashboard?.myOrders ?? ""}
                  body={t("Placed")}
                />
              </Mui.Grid>
            </Containers.ShowIfAuthorised>
          
            <Containers.ShowIfAuthorised roles={["customer", "dealer"]}>
              <>
                {homeStore.dashboard?.sets.length > 0 && (
                  <Mui.Grid item xs={12} md={6} lg={12}>
                    <Mui.Paper>
                      <Mui.Box
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                        component={(props) => (
                          <Router.Link {...props} to="/licenses" />
                        )}
                      >
                        <div
                          style={{
                            padding: theme.spacing(-1),
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Mui.Typography
                            style={{
                              margin:"24px 0px 0px 12px",
                              fontWeight: 600,
                              color: Mui.lighten(theme.palette.text.primary, 0.4),
                              textTransform: "uppercase",
                              fontSize:"28"
                            }}
                          >
                            {userStore.user?.RoleName === "customer" ?
                            <ReactI18next.Trans i18nKey="Installations summary">
                              Installations summary
                            </ReactI18next.Trans>:<ReactI18next.Trans i18nKey="Licenses summary">
                              Licenses summary
                            </ReactI18next.Trans>}
                          </Mui.Typography>

                         
                        </div>
                        <Mui.TableContainer component={Mui.Paper}>
      <Mui.Table className={classes.table}  aria-label="simple table">
        <Mui.TableHead>
          <Mui.TableRow >
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }}align="left" ><ReactI18next.Trans i18nKey="Product Name">
                              Product name
                            </ReactI18next.Trans></Mui.TableCell>
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }}align="left" >
                             <ReactI18next.Trans i18nKey="Model">
                              Model
                            </ReactI18next.Trans>
                           
                            </Mui.TableCell>
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }} align="left">{userStore.user?.RoleName === "customer"? t("Total installations"):t("Licenses purchased")}
                              </Mui.TableCell>
             <Mui.TableCell style={{ color: theme.palette.text.secondary,
                              }}align="left" ></Mui.TableCell>
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }} align="left">{userStore.user?.RoleName === "customer"? t("Used installations"):t("Licenses sold")}
                              </Mui.TableCell>
             <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }}align="left" >
                              </Mui.TableCell>
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }} align="left">{userStore.user?.RoleName === "customer"? t("Available installations"):t("Licenses in stock")}
                             </Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          {homeStore.dashboard?.licenseSummary?.map((row:any) => (
            <Mui.TableRow key={row.setID} style={{marginBottom:"50px"}}>
              <Mui.TableCell style={{ fontWeight: "bold" }} align="left">
              <Components.WorkingStatus
                variant="SHORT"
                status={ row.availableQuantity > 0 ? 1 : 2 as 1 | 2}
                labels={{ 1: "Available", 2: "Used" }}
                
              />
                <Mui.Typography component="span"
                style={{ fontWeight: "bold",fontSize:"18px", marginLeft:"4px" }}>{row.setName}</Mui.Typography></Mui.TableCell>
              <Mui.TableCell component="th" scope="row">
                {row.hundCode}
              </Mui.TableCell>
              <Mui.TableCell style={{ fontWeight: "bold",fontSize:"16px" }}
                                  align="center">{row.totalQuantity}</Mui.TableCell>
               <Mui.TableCell><Mui.Typography style={{ fontWeight: 400,fontSize:"36px" ,color: Mui.lighten(theme.palette.text.primary, 0.5)}}>
                                <ReactI18next.Trans i18nKey="Available / Total quantity /Assigned quantity ">
                                 -
                                </ReactI18next.Trans>
                          </Mui.Typography> </Mui.TableCell>
              <Mui.TableCell style={{ fontWeight: "bold",fontSize:"16px" }} align="center">
                                   {row.assignedQuantity ?? 0}</Mui.TableCell>
              <Mui.TableCell> 
                          <Mui.Typography style={{ fontWeight: 400,fontSize:"22px",color: Mui.lighten(theme.palette.text.primary, 0.5), }}>
                                <ReactI18next.Trans i18nKey="Available / Total quantity /Assigned quantity ">
                                 =
                                </ReactI18next.Trans>
                          </Mui.Typography> 
                </Mui.TableCell>
              <Mui.TableCell style={{ fontWeight: "bold",fontSize:"16px" }}
                                   align="center">{row.availableQuantity}</Mui.TableCell>
            </Mui.TableRow>
          ))}
        </Mui.TableBody>
      </Mui.Table>
    </Mui.TableContainer>
                      </Mui.Box>
                    </Mui.Paper>
                  </Mui.Grid>
                )}
              </>
            </Containers.ShowIfAuthorised>

            

              <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Grid item xs={12} md={4} lg={3}>
                    <Components.QuickInfoTile
                      label={t("Total sales")}
                      iconName={"icon-etc-db-tasks"}
                      isColorChange = {false}
                      title={Numeral(
                        homeStore.dashboard?.weeklySales
                          ?.filter(
                            (sale: any) =>
                              sale.month === DateFns.getMonth(new Date()) + 1
                          )
                          .reduce((sum: number, sale: any) => {
                            return (sum += sale.count);
                          }, 0)
                      ).format("0a")}
                      body={t("")}
                    />
                  </Mui.Grid>
                </Containers.ShowIfAuthorised>

                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Grid item xs={12} md={4} lg={3}>
                    <Components.QuickInfoTile
                      label={t("Total orders")} 
                      iconName={"icon-etc-orders"}
                      isColorChange = {false}
                      title={homeStore.dashboard?.overall?.totalOrders ?? ""}
                      body={t("")}
                    />
                  </Mui.Grid>
                </Containers.ShowIfAuthorised>

                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Grid item xs={12} md={4} lg={3}>
                    <Components.QuickInfoTile
                      label={t("Active articles")}
                      iconName={"icon-etc-articles"}
                      isColorChange = {false}
                      title={
                        homeStore.dashboard?.overall?.totalActiveSets ?? ""
                      }
                      body={t("")}
                    />
                  </Mui.Grid>
                </Containers.ShowIfAuthorised>

                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Grid item xs={12} md={4} lg={3}>
                    <Components.QuickInfoTile
                      label={t("Active users")}
                      iconName={"icon-etc-db-users"}
                      isColorChange = {false}
                      title={homeStore.dashboard?.overall?.usersActive}
                      body={t("")}
                    />
                  </Mui.Grid>
                </Containers.ShowIfAuthorised>
           
                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                  <Mui.Grid item xs={12} md={12} lg={12}>
                    <Mui.Paper style={{ minHeight: 439 }}>
                      <Mui.Box padding={2}>
                      <Mui.Typography
                            style={{
                              margin:"6px 0px 0px 4px",
                              fontWeight: 600,
                              color: Mui.lighten(theme.palette.text.primary, 0.4),
                              textTransform: "uppercase",
                            }}
                          >
                          <ReactI18next.Trans i18nKey="Sales by articles">
                            Sales by articles
                          </ReactI18next.Trans>
                        </Mui.Typography>
                        <Mui.Box height={350} style={{
                              fontWeight: 600,
                              }}>
                          <Components.Charts.ResponsiveHorizontalBar
                            indexBy={"setName"}
                            data={homeStore.dashboard?.sets
                              ?.sort(
                                (a: any, b: any) =>
                                  a.setName.length - b.setName.length
                              )
                              .sort(
                                (a: any, b: any) => a.quantity - b.quantity
                              )}
                            keys={["quantity"]}
                            margin={{
                              bottom: 25, left: 120, right: 60, top: 0
                            }}
                          />
                        </Mui.Box>
                      </Mui.Box>
                    </Mui.Paper>
                  </Mui.Grid>
                </Containers.ShowIfAuthorised>
                <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
              <>
               
                  {/* <Mui.Grid item xs={12} md={6} lg={6}>
                    <Mui.Paper>
                      <Mui.Box
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                        
                      >
                        <div
                          style={{
                            padding: theme.spacing(-1),
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Mui.Typography
                            style={{
                              margin:"24px 0px 0px 12px",
                              fontWeight: 600,
                              color: Mui.lighten(theme.palette.text.primary, 0.4),
                              textTransform: "uppercase",
                              fontSize:"28"
                            }}
                          >
                            <ReactI18next.Trans i18nKey="Licenses sales summary">
                              Licenses sales summary
                            </ReactI18next.Trans>
                          </Mui.Typography>

                          
                        </div>
                        <Mui.TableContainer component={Mui.Paper}>
      <Mui.Table className={classes.table}  aria-label="simple table">
        <Mui.TableHead>
          <Mui.TableRow >
            
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }}align="left" ><ReactI18next.Trans i18nKey="Product Name">
                              Product name
                            </ReactI18next.Trans></Mui.TableCell>
            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }}align="left" >
                            
                            {(userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin")? t("Code"):t("Model")}
                            </Mui.TableCell>
                            <Mui.TableCell style={{ color: theme.palette.text.secondary,fontSize:"18px"
                              }}align="center" ><ReactI18next.Trans i18nKey="Sales quantity">
                              Sales quantity
                            </ReactI18next.Trans></Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          {homeStore.dashboard?.sets?.map((row:any) => (
            <Mui.TableRow key={row.setID} style={{marginBottom:"50px"}}>
              <Mui.TableCell style={{ fontWeight: "bold" }} align="left">
             
                <Mui.Typography component="span"
                style={{ fontWeight: "bold",fontSize:"16px", marginLeft:"4px" }}>
                  {row.setName}
                  </Mui.Typography></Mui.TableCell>
              <Mui.TableCell component="th" scope="row">
                {row.setCode}
              </Mui.TableCell>
              <Mui.TableCell style={{ fontWeight: "bold",fontSize:"16px" }}
                                  align="center">{row.quantity}</Mui.TableCell>
            </Mui.TableRow>
          ))}
        </Mui.TableBody>
      </Mui.Table>
    </Mui.TableContainer>
                      </Mui.Box>
                    </Mui.Paper>
                  </Mui.Grid> */}
                
              </>
            </Containers.ShowIfAuthorised>
          </>
        )}
      </Mui.Grid>
    </>
  );
});
