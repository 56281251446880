/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as DateFns from "date-fns";
import * as Mobx from "mobx-react-lite";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";

interface Data extends Models.AssignedLicenseDetail {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);

function EnhancedTable({ rows }: { rows: Models.AssignedLicenseDetail[] }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderedOn, setOrderedOn] = React.useState<any>();
  const [orderBy, setOrderBy] = React.useState<keyof Data>("index");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  const uid = userStore.user?.ETUID;
  React.useEffect(() => {
    setPage(0);
  }, [rows]);
  const key = 'index';

  const orderArrayUniqueByKey = [...new Map(rows.map(item =>
  [item[key], item])).values()];
// const orderDetaile = orderArrayUniqueByKey.map(k=>k.orderDetails[0])
const orderDetaile = rows
// const orderedDetailed = orderDetaile.filter(k=>k.orderCode === "ET-20220219-00006")
// const setFilter = orderedDetailed.map(k=>k.sets) 
//   
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
//  const license = orderArrayUniqueByKey.map(k=>k.orderDetails).filter((k:any)=>{k.orderCode === OrderCodeForLicense }).map(k=>k.sets)
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const [language,setLanguage] = React.useState('en-IN');
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language
  React.useEffect(()=>{
    if(languageValue === "en"){
      setLanguage('en-IN')
    }else if(languageValue === "fr")
    {
      setLanguage('fr-FR')
    }
    else{
      setLanguage('de-DE')
    }
  },[languageValue])

  // 
  return (
    <div>
    
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
          // key="etCustomerInviteID"
          //key={uuidv4()}
        >
          {rows.length > 0 && (
          <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead                 leftmost                 classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderCode"
                  label={t("Order #")}
                />
                <EnhancedTableHead 
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="customerName"
                  label={t("Customer name")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="userEmail"
                  label={t("Email Id")}
                />
                <EnhancedTableHead rightmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="assignedQuantity"
                  label={t("Assigned quantity")}
                />
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(orderArrayUniqueByKey as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) =>{
                    }
                    }
                    key={row.index}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id="orderCode"
                      scope="row"
                    >
                      {row.orderCode}
                     
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell align="left" id="customerName">
                    {row.customerName}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell onClick={()=>{window.open(`mailto:${row.userEmail}`)}} id ="userEmail">
                    <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{row.userEmail}</Mui.Link></Mui.Tooltip>
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell rightmost id ="assignedQuantity">
                   {row.assignedQuantity}
                    </Components.Mui.TableCell>
                    
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No orders found">No Sales data found for {localStorage.getItem("setName")}</ReactI18next.Trans></MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const userStore = React.useContext(App.Contexts.userStore);
  const licenseStore = React.useContext(App.Contexts.license.assignedLicenseDetailStore);
  const theme = Mui.useTheme();
  React.useEffect(() => {

    const userRole : any = userStore.user?.RoleName ?? "customer";
    const ETUID : any = userStore.user?.ETUID;
    const SetId = localStorage.getItem("setCode");
    if (userRole === "customer" || userRole === "dealer" || userRole === "admin" || userRole === "superadmin") {
      if (ETUID !== undefined && ETUID !== null) {
        licenseStore.read({
          ETUID,
          SetId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Mui.Paper
        square
        style={{
          marginBottom: "12px",
          marginTop:"6px",
          borderLeft: "4px solid grey",
          borderRadius: "4px",
        }}
      >
        <Mui.Grid container xs={12} >
          <Mui.Grid item>
            <Mui.Typography
              style={{
                height: "23px", 
                fontWeight: "bold",
                fontSize: "20px",
                margin: "0px 0 10px 12px",
                borderRadius: "none",
                opacity: "0.8",
              }}
            >
              <ReactI18next.Trans i18nKey="Sales details">
             Sales details for the {localStorage.getItem("setName")}
            </ReactI18next.Trans>
              
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        </Mui.Paper>
      <Mui.Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
      
        <Mui.FormControl style={{ width: 300 }}>
          {licenseStore.assignedLicenseDetail.length > 0 && (
            <Mui.TextField label={t("Search")} onChange={handleSearch} />
          )}
        </Mui.FormControl>
      </Mui.Grid>
      {licenseStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          
          {licenseStore.view === "TABULAR" && (
            <EnhancedTable
              rows={licenseStore.assignedLicenseDetail.filter((license) => {
                if (!search) {
                  return true;
                }
                return (
                  license?.customerName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                    license?.userEmail
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                    license?.orderCode
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) 
                 
                 
                );
              })}
            />
          )}
         
        </>
      )}
    </>
  );
});
