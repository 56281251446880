/** @format */

import * as Mui from "@material-ui/core";
import * as Themes from "src/themes";

// NOTE: This import is important done remove
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type from "@material-ui/lab/themeAugmentation";

export declare namespace Overrides {
  export interface Props extends Themes.Global.Palette.Props {
    palette: Pick<Mui.ThemeOptions, "palette">;
    theme: Mui.Theme;
  }
}

// eslint-disable-next-line no-redeclare
export function Overrides({
  palette,
  theme,
  themeMode = "light",
}: Overrides.Props): Pick<Mui.ThemeOptions, "overrides"> {
  return {
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
            },
          ],
        },
      },
      MuiPaginationItem: {
        root: {
          "&$selected": {
            backgroundColor: `${theme.palette.text.secondary} !important`,
            color: theme.palette.common.white,
          },
        },
        selected: {},
      },
      MuiTable: {
        root: {
          borderCollapse: "separate",
          borderSpacing: `0 ${theme.spacing(1.2)}px`,
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: "none",
        },
      },
      MuiButton: {
        root: {
          borderRadius: 500,
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: theme.spacing(1.5),
        },
      },
    },
  };
}
