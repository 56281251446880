/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as MuiLab from "@material-ui/lab";
import * as Formik from "formik";
import * as ReactUndraw from "iblis-react-undraw";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Shop from "src/app/pages/shop";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    centering: {
      textAlign: "center",
    },
  })
);

export const Cart = Mobx.observer(function () {
  const cartStore = React.useContext(App.Contexts.cartStore);
  // 
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  // const telecashURL = Constants.environment.paymentURL;
  // const telecashURLs = Constants.environment.TelecashURL;
   //
  // 
  // let environmet = 'test';
  // if (telecashURL?.includes('www.ipg-online.com')) { 
  //    environmet = 'live';
  // }
  // 
  const VatPercent = Array?.from(cartStore?.cart?.values())?.map?.((k) => (k?.vatPercentage))?.slice(0,1)?.toString?.();
  const taxPercentage:any = parseInt(VatPercent)
  // 

  const handleRemoveSelect = ({ article }: { article: any }) => {
    // alert("Hi");
    //alert(JSON.stringify(article));

    const selectedIndex = customerInviteStore.selected.indexOf(article.setID);
    //alert(JSON.stringify(selectedIndex));
    let newSelected: number[] = [];
    let newRowSelected: Models.Set.Article[] = [];
    //alert('handleSelect');
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected,
        article.setID
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle,
        article
      );
    } else if (selectedIndex === 0) {
    } else if (selectedIndex === customerInviteStore.selected.length - 1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, -1)
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, selectedIndex),
        customerInviteStore.selected.slice(selectedIndex + 1)
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(0, selectedIndex),
        customerInviteStore.selectedArticle.slice(selectedIndex + 1)
      );
      // setDisable(false);
    }

    customerInviteStore.setArticleSelected({ selectedArticle: newRowSelected });
    customerInviteStore.setSelected({ selected: newSelected });
  };
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const theme = Mui.useTheme();
  const classes = useStyles();
  const { t } = ReactI18next.useTranslation();
  const [addressLightboxOpen, setAddressLightboxOpen] = React.useState<boolean>(
    false
  );
  const [
    creatingOrderWarningDialogOpen,
    setCreatingOrderWarningDialogOpen,
  ] = React.useState<boolean>(false);
 let totalPurchaseValue:any = ( ((!taxPercentage ? 19/100  : taxPercentage/100)*cartStore.total)+ +cartStore.total)
 if(totalPurchaseValue !== 0 || totalPurchaseValue !== null || totalPurchaseValue !== undefined || totalPurchaseValue !== NaN){
  totalPurchaseValue = totalPurchaseValue
 }
 else 
 {
 totalPurchaseValue = 0
 }
 localStorage.setItem("totalPurchaseValue",totalPurchaseValue)
 
 const navigate = Router.useNavigate();
  const [searchParams] = Router.useSearchParams();
  const userStore = React.useContext(App.Contexts.userStore);

  const status = searchParams.get("status");

  const addressServices = new Services.Address({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    (async function () {
      try {
        const customerAddressNumber = userStore.user?.AddressNumber;
        const contactPersonNumber = userStore.user?.ContactPersonNr;
        if (
          !cartStore.billingAddress &&
          customerAddressNumber &&
          contactPersonNumber
        ) {
          const { result: address } = await addressServices.getAddress({
            contactPersonNumber,
            customerAddressNumber,
          });
          cartStore.setBillingAddress({ billingAddress: address });
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const orderDetails = localStorage.getItem("orderDetails");

    if ((status === "failure" || status === "cancelled") && orderDetails) {
      cartStore.resetCart();
      navigate("/shop/payment/success");
      return;
    }

    if (
      (status === "failure" || status === "cancelled") &&
      !orderDetails &&
      cartStore.cart.size === 0
    ) {
      navigate("/shop");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [language,setLanguage] = React.useState('en-IN');
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language
  React.useEffect(()=>{
    if(languageValue === "en"){
      setLanguage('en-IN')
    }else if(languageValue === "fr")
    {
      setLanguage('fr-FR')
    }
    else{
      setLanguage('de-DE')
    }
  },[languageValue])
  
  const grandTotal = ((!taxPercentage ? 19/100  : taxPercentage/100)*cartStore.total)+ +cartStore.total
  const vat = (!taxPercentage ? 19/100  : taxPercentage/100)*cartStore.total
  const total = cartStore.total
  const localeGrandTotal = grandTotal?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
  const localeVat = vat?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
  const localetotal = total?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
  const dealerBasicSetFlag = localStorage.getItem("DealerBasicSetValidation")
  const homeStore = React.useContext(App.Contexts.homeStore);
  
  React.useEffect(() => {
    const uid = userStore.user?.ETUID ? userStore.user?.ETUID : "0";
    //alert(uid);
    const userRole = "customer"
    const EmailId = userStore.user?.UserEmail ? userStore.user?.UserEmail : undefined;
    const status = 1;
    if (uid !== undefined && uid !== null) {
      homeStore.read({
        uid,
        userRole,
        EmailId,
      });
    }},[homeStore, userStore.user])
  return (
    <>
      <Components.ResponsiveFullscreenDialogWithScroll
        open={creatingOrderWarningDialogOpen}
        handleClose={() => {}}
        title={t("Creating your order")}
        disableDividers
        contentText={
          <>
            {t(
              "We're creating your order, Please don't go back or close or refresh the page!"
            )}
            <Mui.Box
              margin={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Mui.CircularProgress />
            </Mui.Box>
          </>
        }
      />
      <Shop.Address
        title={
          <Mui.Typography
            style={{ fontWeight: "bold", flexGrow: 1 }}
            variant="h6"
          >
            {cartStore.billingAddress
              ? t("Edit Billing Address")
              : t("Add Billing Address")}
          </Mui.Typography>
        }
        address={cartStore.billingAddress}
        open={addressLightboxOpen}
        handleClose={() => {
          setAddressLightboxOpen(false);
        }}
      />
      {cartStore.cart.size === 0 ? (
        <Mui.Paper>
          <Mui.Box padding={2}>
            <ReactUndraw.SvgEmptyCart
              style={{
                backgroundImage: `radial-gradient(closest-side at 50%, ${theme.palette.text.disabled}, ${theme.palette.background.paper})`,
                marginBottom: theme.spacing(6),
              }}
              accentcolor={theme.palette.primary.main}
              primarycolor={theme.palette.secondary.main}
              height={300}
            />
            <Mui.Typography variant="h4" align="center" gutterBottom>
              <ReactI18next.Trans i18nKey="Empty cart">
                Empty cart
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography
              variant="body2"
              align="center"
              style={{ marginBottom: theme.spacing(4) }}
            >
              <ReactI18next.Trans i18nKey="There are no items in your cart">
                There are no items in your cart.
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
              <Mui.Button
                variant="contained"
                color="primary"
                component={Router.Link}
                to="/shop"
              >
                <ReactI18next.Trans i18nKey="Continue shopping">
                  Continue shopping
                </ReactI18next.Trans>
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </Mui.Paper>
      ) : (
        <>
          {status === "cancelled" && (
            <Mui.Box marginBottom={2}>
              <MuiLab.Alert severity="warning">
                <MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="We couldn't process your payment">
                    We couldn't process your payment
                  </ReactI18next.Trans>
                </MuiLab.AlertTitle>
                <ReactI18next.Trans
                  i18nKey="Unfortunately, we couldn't collect payment on your purchase. Please
      take a moment to review your order and continue purchase."
                >
                  Unfortunately, we couldn't collect payment on your purchase.
                  Please take a moment to review your order and continue
                  purchase.
                </ReactI18next.Trans>
              </MuiLab.Alert>
            </Mui.Box>
          )}

          {status === "failure" && (
            <Mui.Box marginBottom={2}>
              <MuiLab.Alert severity="error">
                <MuiLab.AlertTitle>
                  <ReactI18next.Trans i18nKey="Payment failure">
                  Payment failure
                  </ReactI18next.Trans>
                </MuiLab.AlertTitle>
                <Mui.Typography component="div" variant="body2">
                  <ReactI18next.Trans i18nKey="If you received a payment failure alert, it means the payment may have failed due to one of the following reasons">
                    If you received a payment failure alert, it means the payment may have failed due to one of the following reasons
                  </ReactI18next.Trans>
                  <ul>
                    <li>
                      <ReactI18next.Trans i18nKey="Payment was declined by your bank or credit card provider">
                        Payment was declined by your bank or credit card
                        provider
                      </ReactI18next.Trans>
                    </li>
                    <li>
                      <ReactI18next.Trans i18nKey="Credit card provided has expired">
                        Credit card provided has expired
                      </ReactI18next.Trans>
                    </li>
                    <li>
                      <ReactI18next.Trans i18nKey="Credit card has insufficient funds">
                        Credit card has insufficient funds
                      </ReactI18next.Trans>
                    </li>
                    <li>
                      <ReactI18next.Trans i18nKey="Credit card number provided is invalid">
                        Credit card number provided is invalid
                      </ReactI18next.Trans>
                    </li>
                    <li>
                      <ReactI18next.Trans i18nKey="Credit card provided has not been approved for recurring">
                        Credit card provided has not been approved for recurring
                      </ReactI18next.Trans>
                      &nbsp;
                      <ReactI18next.Trans i18nKey=" payments">
                        payments
                      </ReactI18next.Trans>
                    </li>
                  </ul>
                  <ReactI18next.Trans
                    i18nKey=" For more information on why the payment failed, please contact
        your bank or credit card company."
                  >
                    For more information on why the payment failed, please
                    contact your bank or credit card company.
                  </ReactI18next.Trans>
                </Mui.Typography>
              </MuiLab.Alert>
            </Mui.Box>
          )}
          <div className={classes.root}>
            <Mui.Grid container spacing={3}>
              <Mui.Grid item xs={12} lg={8}>
                <Mui.Card>
                  <Mui.CardHeader
                    title={
                      <Mui.Box textAlign="center" color="text.secondary">
                        <ReactI18next.Trans i18nKey="Shopping cart">
                        Shopping cart
                            </ReactI18next.Trans>
                        
                      </Mui.Box>
                    }
                  />
                  <Mui.CardContent>
                    <Mui.Table style={{ borderSpacing: "unset" }}>
                      <Mui.TableHead>
                        <Mui.TableRow>
                          <Mui.TableCell
                            align="left"
                            style={{
                              color: theme.palette.text.secondary,
                              textTransform: "uppercase",
                            }}
                          >
                            <ReactI18next.Trans i18nKey="Model">
                              Model
                            </ReactI18next.Trans>
                          </Mui.TableCell>
                          <Mui.TableCell
                            style={{
                              color: theme.palette.text.secondary,
                              textTransform: "uppercase",
                            }}
                          >
                            <ReactI18next.Trans i18nKey="Product">
                              Product
                            </ReactI18next.Trans>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{
                              color: theme.palette.text.secondary,
                              textTransform: "uppercase",
                            }}
                          >
                            <ReactI18next.Trans i18nKey="PriceexclVAT">
                              Price excl. VAT
                            </ReactI18next.Trans>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{
                              color: theme.palette.text.secondary,
                              textTransform: "uppercase",
                            }}
                          >
                            <ReactI18next.Trans i18nKey="Quantity">
                              Quantity
                            </ReactI18next.Trans>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{
                              color: theme.palette.text.secondary,
                              textTransform: "uppercase",
                            }}
                          >
                            <ReactI18next.Trans i18nKey="Sub total">
                              Sub total
                            </ReactI18next.Trans>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{
                              color: theme.palette.text.secondary,
                              textTransform: "uppercase",
                            }}
                          >
                            <ReactI18next.Trans i18nKey="Remove">
                              Remove
                            </ReactI18next.Trans>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </Mui.TableHead>
                      <Mui.TableBody>
                        {Array.from(cartStore.cart.values()).map((product) => {
                          const singlePriceInclOfVat = product?.olPrice
                          ?.grossPositionSinglePriceInclVAT
                          const singleNetPrice = product?.olPrice?.singleNetPrice 
                          const subTotalOfSinglePriceInclOfVat = product.quantity * product?.olPrice
                          .grossPositionSinglePriceInclVAT
                          const subTotalOfSingleNetPrice = product.quantity * product?.olPrice?.singleNetPrice
                          
                          const localeSinglePriceInclOfVat = singlePriceInclOfVat?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
                          const localeSubTotalOflocaleSinglePriceInclOfVat = subTotalOfSinglePriceInclOfVat?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
                          const localeSingleNetPrice = singleNetPrice?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
                          const localeSubTotalOflocaleSingleNetPrice = subTotalOfSingleNetPrice?.toLocaleString?.(language,{maximumFractionDigits:2,minimumFractionDigits:2})
                          return (
                            <Mui.TableRow key={uuidv4()}>
                            <Mui.TableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              {product.hundCode}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.Box display="flex" alignItems="baseline">
                                <Mui.Box
                                  marginRight={1}
                                  component="span"
                                  style={{
                                    fontSize: theme.typography.h5.fontSize,
                                  }}
                                >
                                  <i className={product.iconClass} />
                                </Mui.Box>
                                <Mui.Typography
                                  style={{ fontWeight: "bold" }}
                                  variant="body1"
                                >
                                  {product.setName}
                                </Mui.Typography>
                              </Mui.Box>
                            </Mui.TableCell>
                            <Mui.TableCell
                              align="right"
                              style={{ fontWeight: "bold" }}
                            >
                              &euro;&nbsp;{product?.olPrice
                                  ?.grossPositionSinglePriceInclVAT
                                  ? localeSinglePriceInclOfVat
                                  : localeSingleNetPrice
                              }
                              {(Configs.Workaround.isCloudArticle({
                                code: product.setCode,
                              }) ||
                                Configs.Workaround.isCloudArticle({
                                  code: product.hundCode,
                                })) && (
                                <Mui.Typography
                                  component="div"
                                  variant="caption"
                                >
                                  monthly
                                </Mui.Typography>
                              )}
                            </Mui.TableCell>
                            <Mui.TableCell align="right">
                              <Components.Counter
                                min={1}
                                max={10}
                                value={product.quantity}
                                handleDecrement={() => {
                                  if(userStore.user?.RoleName === "dealer"){
                                    cartStore.decreaseProductQuantity({
                                      product,
                                    });
                                    let article = [];
                                    article = product;
                                    customerInviteStore.decreaseProductQuantity({
                                      article,
                                    });
                                  }else{
                                    let article:any = [];
                                    article = product
                                    let validationData = [];
                    let dashboardReplica:any = [];
                    dashboardReplica = homeStore.dashboard?.licenseSummary
                      for (var i = 0; i < dashboardReplica.length; i++) {
                      dashboardReplica[i].totalQuantity = +dashboardReplica[i].totalQuantity;
                    }
                    const dash = dashboardReplica.map((k:any)=>{
                      return {
                        hundCode: k.hundCode,
                        totalQuantity: k.totalQuantity / 4,
                        setName: k.setName
                      }
                    })
                    
                     let  cartStores:any = Array.from(cartStore.cart.values())
                    //  const replacing = cartStores.map(({ quantity }:any) => ({ totalQuantity: quantity}));
                    //  
                      
                     for (var j = 0; j < cartStores.length; j++) {
                      cartStores[j].quantity = +cartStores[j].quantity;
                  }
                  const cart = cartStores.map((k:any)=>{
                    return {
                      hundCode: k.hundCode,
                      totalQuantity: k.quantity,
                      setName: k.setName
                    }
                  })
                  
                     if(dashboardReplica.length > 0 && cartStores.length > 0){
                      const result = Object.values([...cart, ...dash].reduce((acc, { hundCode, totalQuantity,setName }) => {
                        acc[hundCode] = { hundCode, totalQuantity: (acc[hundCode] ? acc[hundCode].totalQuantity : 0) + totalQuantity,setName  };
                        return acc;
                      }, {}));
                      validationData = result;
                      
                       
                     }
                     else if(dashboardReplica.length > 0 && (cartStores.length === 0 || cartStores === undefined )){
                      const dash = dashboardReplica.map((k:any)=>{
                        return {
                          hundCode: k.hundCode,
                          totalQuantity: k.totalQuantity / 4,
                          setName: k.setName
                        }
                      })
                      validationData = dash
                    }
                    else if((dashboardReplica.length === 0 || dashboardReplica === undefined ) && cartStores.length > 0){
                      const cart = cartStores.map((k:any)=>{
                        return {
                          hundCode: k.hundCode,
                          totalQuantity: k.quantity,
                          setName: k.setName
                        }
                      })
                      validationData = cart;
  
                    }
                    else
                    {
                      validationData = [];
                    }
                    
                    
                    if(product.hundCode === "SET-01"){
                      const basicSetQuantity = validationData.filter((k:any)=>k.hundCode === "SET-01").map((s:any)=>s.totalQuantity)
                      
                      const OtherSetQuantityExceptBasic = validationData.filter((k:any)=>k.hundCode !== "SET-01").map((s:any)=>s.totalQuantity)
                      const maximumQuantityFromOtherSets:any = Math.max(...OtherSetQuantityExceptBasic)
                      const MaxValue = maximumQuantityFromOtherSets
  
                      const subFunction = (a:number,b:number)=>{
                           return a - b
                      }
                      const oneDecrement = 1
                      const twoDecrement = parseInt(basicSetQuantity[0])
                      const subtraction = subFunction(twoDecrement,oneDecrement)
                      
                      var comaprator ;
                        if(subtraction > MaxValue || subtraction === MaxValue){
                          comaprator = true
                        }
                        else {
                          comaprator = false
                          
                        }
                                            
  
                      if(comaprator){
                        cartStore.decreaseProductQuantity({product});
                      
  
                    }
                    else{
                      enqueueSnackbar(
                      t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                      {
                        variant: "warning",
                        preventDuplicate: false,
                      }
                      );
                    }
                  }
                  else if(product.hundCode !== "SET-01"){
                    const basicSetQuantity = validationData.filter((k:any)=>k.hundCode === "SET-01").map((s:any)=>s.totalQuantity)
                    
                    const selectedQuantity = validationData.filter((k:any)=>k.hundCode === product.hundCode).map((s:any)=>s.totalQuantity)
                    const oneDecrement = 1
                    if((selectedQuantity[0] - oneDecrement) <= basicSetQuantity[0]){
                      cartStore.decreaseProductQuantity({product});
                    
  
                  }
                  else{
                    enqueueSnackbar(
                      t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                    {
                      variant: "warning",
                      preventDuplicate: false,
                    }
                    );
                  }
                  
                  }
   }
                                  
                                
                                }}
                                handleIncrement={() => {
                                  if(userStore.user?.RoleName === "dealer"){
                                    cartStore.increaseProductQuantity({
                                      product,
                                    });
                                    let article = [];
                                    article = product;
                                    customerInviteStore.increaseProductQuantity({
                                      article,
                                    });
                                  }else{
                                      let article:any = [];
                                      article = product
                                      let validationData = [];
                      let dashboardReplica:any = [];
                      dashboardReplica = homeStore.dashboard?.licenseSummary
                        for (var i = 0; i < dashboardReplica.length; i++) {
                        dashboardReplica[i].totalQuantity = +dashboardReplica[i].totalQuantity;
                      }
                      const dash = dashboardReplica.map((k:any)=>{
                        return {
                          hundCode: k.hundCode,
                          totalQuantity: k.totalQuantity / 4,
                          setName: k.setName
                        }
                      })
                      
                       let  cartStores:any = Array.from(cartStore.cart.values())
                      //  const replacing = cartStores.map(({ quantity }:any) => ({ totalQuantity: quantity}));
                      //  
                        
                       for (var j = 0; j < cartStores.length; j++) {
                        cartStores[j].quantity = +cartStores[j].quantity;
                    }
                    const cart = cartStores.map((k:any)=>{
                      return {
                        hundCode: k.hundCode,
                        totalQuantity: k.quantity,
                        setName: k.setName
                      }
                    })
                    
                       if(dashboardReplica.length > 0 && cartStores.length > 0){
                        const result = Object.values([...cart, ...dash].reduce((acc, { hundCode, totalQuantity,setName }) => {
                          acc[hundCode] = { hundCode, totalQuantity: (acc[hundCode] ? acc[hundCode].totalQuantity : 0) + totalQuantity,setName  };
                          return acc;
                        }, {}));
                        validationData = result;
                        
                         
                       }
                       else if(dashboardReplica.length > 0 && (cartStores.length === 0 || cartStores === undefined )){
                        const dash = dashboardReplica.map((k:any)=>{
                          return {
                            hundCode: k.hundCode,
                            totalQuantity: k.totalQuantity / 4,
                            setName: k.setName
                          }
                        })
                        validationData = dash
                      }
                      else if((dashboardReplica.length === 0 || dashboardReplica === undefined ) && cartStores.length > 0){
                        const cart = cartStores.map((k:any)=>{
                          return {
                            hundCode: k.hundCode,
                            totalQuantity: k.quantity,
                            setName: k.setName
                          }
                        })
                        validationData = cart;
    
                      }
                      else
                      {
                        validationData = [];
                      }
                      
                      
                      // const validat = validationData.filter((k:any)=>k.hundCode === article.hundCode
                        
                      // ).map((k:any)=>k.totalQuantity + 1)
                      for(var m = 0; m < validationData.length; m++){
                        if(validationData[m].hundCode === article.hundCode){
                          validationData[m].totalQuantity = validationData[m].totalQuantity + 1;
                        }
                        
                      }
                      
                      const validationCheckfilter = validationData.filter((k:any)=>k.hundCode === article.hundCode)
                      if(validationCheckfilter.length > 0){
                        
                        
                      }else{
                        
                        
                        validationData.push({
                          hundCode: article.hundCode,
                          totalQuantity: 1,
                          setName: article.setName
                        })
                      }
    
                      
                      const filteringSelectedArticleQuantity = validationData.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)
                      const filteringBasicSetQuantity= validationData.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
                     
                      if(article.hundCode === "SET-01"){
                        cartStore.addProduct({ product: article })
                          enqueueSnackbar(t("Added to cart!"), {
                            variant: "success",
                            preventDuplicate: false,
                          });
                      }
                      else if(article.hundCode !== "SET-01"){
    
                        const FilteringSelectedArticleQuantityisZeroChecks = filteringSelectedArticleQuantity.length === 0 || filteringSelectedArticleQuantity.length === undefined || filteringSelectedArticleQuantity.length === null ? 0 : filteringSelectedArticleQuantity[0]
                        const FilteringBasicSetQuantityisZeroChecks = filteringBasicSetQuantity.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
                        
                        const checkForAddToCart = FilteringSelectedArticleQuantityisZeroChecks <= FilteringBasicSetQuantityisZeroChecks 
                        
                        const cartStoress = cartStores.map((k:any)=>{
                          return {
                            hundCode: k.hundCode,
                            totalQuantity: k.totalQuantity,
                            setName: k.setName
                          }
                        })
                        const filteringBasicSetQuantityAutomateBasicAdd= cartStoress.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
                        const FilteringBasicSetQuantityAutomateBasicAddInCart = filteringBasicSetQuantityAutomateBasicAdd.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
                        let isEnableBasicset = false;
                        if((FilteringBasicSetQuantityisZeroChecks === 0 )&& (FilteringBasicSetQuantityAutomateBasicAddInCart === 0)){
                          isEnableBasicset = true
                          // const articled:any = data.filter((k:any) => k.hundCode === "SET-01")
                          
                          //     const articles = Object.assign({}, ...articled);
                          //     cartStore.addProduct({ product: articles })
                          //     enqueueSnackbar(t("Added to cart!"), {
                          //       variant: "success",
                          //       preventDuplicate: false,
                          //     });
                              }else{
                                isEnableBasicset = false
                              }
                        
                        if((checkForAddToCart === true)|| (isEnableBasicset === true)){
                          cartStore.addProduct({ product: article })
                          enqueueSnackbar(t("Added to cart!"), {
                            variant: "success",
                            preventDuplicate: false,
                          });
                        }
                        else
                        {
                          enqueueSnackbar(
                                    t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                                    {
                                      variant: "warning",
                                      preventDuplicate: false,
                                    }
                                  );
                        }
                     
                    }
                                    
                                      }
                                  }}
                                   
                                  
                                style={{ justifyContent: "flex-end" }}
                              />
                            </Mui.TableCell>
                            <Mui.TableCell
                              align="right"
                              style={{ fontWeight: "bold" }}
                            >
                              &euro;&nbsp;{product?.olPrice
                                    .grossPositionSinglePriceInclVAT
                                  ? localeSubTotalOflocaleSinglePriceInclOfVat
                                  : localeSubTotalOflocaleSingleNetPrice
                              }
                              {(Configs.Workaround.isCloudArticle({
                                code: product.setCode,
                              }) ||
                                Configs.Workaround.isCloudArticle({
                                  code: product.hundCode,
                                })) && (
                                <Mui.Typography
                                  component="div"
                                  variant="caption"
                                >
                                  monthly
                                </Mui.Typography>
                              )}
                            </Mui.TableCell>
                            <Mui.TableCell align="right">
                              <Mui.IconButton
                                size="small"
                                onClick={() => {
                                  if(userStore.user?.RoleName === "dealer"){
                                    cartStore.removeProduct({ product });
                                            let article = [];
                                            article = product;
                                            // alert(JSON.stringify(article));
                                            customerInviteStore.removeProduct({
                                              article,
                                            });
                                            handleRemoveSelect({ article });
                                            enqueueSnackbar(t("Removed from cart!"), {
                                              variant: "warning",
                                              preventDuplicate: false,
                                            });
                                  }
                                  else{
                                    const responseBasicset = localStorage.getItem(
                                      "Basicset"
                                    );
                                    const Basicset = localStorage.getItem(
                                      "Basicsetfromdealerflow"
                                    );
                                    const roleName  = userStore.user?.RoleName;
                                    const responseBasicsetFromShopForCustomer = localStorage.getItem("isBasicSetFromShop")
                                    const BasicsetFromShopForCustomer = localStorage.getItem("BasicSetFromShop")
                                    const totalBasicSetInCartStore = Array.from(
                                      cartStore.cart.values()
                                    ).filter((item) => item.setID !== 1).map((k) => k);
              
                                    const lengthCheckOfExceptBasicSetFromCart = totalBasicSetInCartStore.length;
                                    
                                    if((product.setID == 1 &&(responseBasicsetFromShopForCustomer == "0" || responseBasicsetFromShopForCustomer == "undefined") && BasicsetFromShopForCustomer === "true" ) || (product.setID == 1 &&
                                      (responseBasicset == "0" ||
                                        responseBasicset == "undefined") && roleName ==="dealer") && Basicset === "true")
                                        { 
                                            enqueueSnackbar(
                                              t(`SET-01 is a dependent for your selected license, hence it cannot be removed`),
                                              {
                                                variant: "warning",
                                                preventDuplicate: false,
                                              }
                                            );
                                        } else {
                                          const totalBasicLicensesInStock:any = localStorage.getItem("totalBasicLicensesInStock") ? localStorage.getItem("totalBasicLicensesInStock") : 0 ;
                                          const parseIntOftotalBasicLicensesInStock = parseInt(totalBasicLicensesInStock);
                                          if(parseIntOftotalBasicLicensesInStock === 0){
                                            if(lengthCheckOfExceptBasicSetFromCart  > 0 && product.setID === 1){
                                              enqueueSnackbar(
                                                t(`SET-01 is a dependent for your selected license, hence it cannot be removed.`),
                                                {
                                                  variant: "warning",
                                                  preventDuplicate: false,
                                                }
                                              );
                                            }else{
                                              cartStore.removeProduct({ product });
                                              let article = [];
                                              article = product;
                                              // alert(JSON.stringify(article));
                                              customerInviteStore.removeProduct({
                                                article,
                                              });
                                              handleRemoveSelect({ article });
                                              enqueueSnackbar(t("Removed from cart!"), {
                                                variant: "warning",
                                                preventDuplicate: false,
                                              });
                                            }
                                          }
                                          else{
                                             
                                            const filterCartStoreForExceptBasicSetQuantity = Array.from(
                                              cartStore.cart.values()
                                            )
                                              .filter((item) => item.setID !== 1)
                                              .map((k) => k);
                                            const quantityOfExceptBasicSetQuantity = filterCartStoreForExceptBasicSetQuantity.map(
                                              (k) => k.quantity
                                            );
                                            const checkWhetherTheBasicLicenseInStockIsGreaterThanTheQuantityOfExceptBasicSetQuantity = quantityOfExceptBasicSetQuantity.some(k=> k > parseIntOftotalBasicLicensesInStock);
                                            if(checkWhetherTheBasicLicenseInStockIsGreaterThanTheQuantityOfExceptBasicSetQuantity === true && product.setID === 1){
                                              enqueueSnackbar(
                                                t(`SET-01 is a dependent for your selected license, hence it cannot be removed.`),
                                                {
                                                  variant: "warning",
                                                  preventDuplicate: false,
                                                }
                                              );
                                            }else{
                                              cartStore.removeProduct({ product });
                                              let article = [];
                                              article = product;
                                              // alert(JSON.stringify(article));
                                              customerInviteStore.removeProduct({
                                                article,
                                              });
                                              handleRemoveSelect({ article });
                                              enqueueSnackbar(t("Removed from cart!"), {
                                                variant: "warning",
                                                preventDuplicate: false,
                                              });
                                            }
                                            
                                          }
                                         }
  
                                  }
                                  

                                }}
                              >
                                <MuiIcons.Close />
                              </Mui.IconButton>
                            </Mui.TableCell>
                          </Mui.TableRow>
                          )
                        }
                          
                        )}
                        <Mui.TableRow>
                          <td />
                          <td />
                          <td />
                          <Mui.TableCell align="left">
                            <Mui.Box fontWeight="bold"><ReactI18next.Trans i18nKey="Total">
                            Total
                            </ReactI18next.Trans></Mui.Box>
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Mui.Box fontWeight="bold">
                            &euro;&nbsp;{localetotal}
                            </Mui.Box>
                          </Mui.TableCell>
                        </Mui.TableRow>
                        <Mui.TableRow>
                          <td />
                          <td />
                          <td />
                          <Mui.TableCell align="left">
                            <Mui.Box fontWeight="bold">
                            <ReactI18next.Trans i18nKey="VAT">
                            VAT 
                            </ReactI18next.Trans> (
                              {Numeral(!taxPercentage  ?  19 :taxPercentage).format("0%")})
                            </Mui.Box>
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Mui.Box fontWeight="bold">
                            &euro;&nbsp;{localeVat}
                            </Mui.Box>
                          </Mui.TableCell>
                        </Mui.TableRow>
                        <Mui.TableRow>
                          <td />
                          <td />
                          <td />
                          <Mui.TableCell align="left">
                            <Mui.Box fontWeight="bold"><ReactI18next.Trans i18nKey="Grand total">
                            Grand total
                            </ReactI18next.Trans></Mui.Box>
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Mui.Box fontWeight="bold">
                            &euro;&nbsp;{localeGrandTotal}
                            </Mui.Box>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </Mui.TableBody>
                    </Mui.Table>
                    <Mui.Box marginTop={2}></Mui.Box>
                  </Mui.CardContent>
                  <Mui.CardActions>
                    <Mui.Link
                      style={{
                        margin: theme.spacing(1),
                      }}
                      component={Router.Link}
                      to="/shop"
                    >
                      <ReactI18next.Trans i18nKey="Continue shopping">
                        Continue shopping
                      </ReactI18next.Trans>
                    </Mui.Link>
                  </Mui.CardActions>
                </Mui.Card>
              </Mui.Grid>
              <Mui.Grid item xs={12} lg={4}>
                <Mui.Paper className={classes.paper}>
                  <Formik.Formik
                    initialValues={{ accept: false }}
                    validationSchema={Shop.Shared().Form.validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        setCreatingOrderWarningDialogOpen(true);
                        const customerDealerId =
                          userStore.user?.CustomerDealerID;
                        const etuid = userStore.user?.ETUID;
                        // if (customerDealerId === undefined || customerDealerId === null) {
                        //   throw new Error("customerDealerId is undefined or null");
                        // }

                        if (etuid === undefined || etuid === null) {
                          throw new Error("etuid is undefined or null");
                        }

                        // if (cartStore.billingAddress === undefined) {
                        //   return;
                        // }

                        const billingAddress = ({
                          addressID: undefined,
                          etuid: undefined,
                          firstName: undefined,
                          lastName: undefined,
                          companyName: undefined,
                          address1: undefined,
                          address2: undefined,
                          address3: undefined,
                          city: undefined,
                          state: undefined,
                          postalCode: undefined,
                          country: undefined,
                          status: undefined,
                        } as unknown) as Models.AddressDetails;

                        const {
                          outputData: billingAddressID,
                        } = await addressServices.create({
                          body: {
                            ...(cartStore.billingAddress ?? billingAddress),
                            status: 1,
                          },
                        });
                        const order: Models.Order.Creation = {
                          orderCode: "",
                          etuid,
                          billingAddressID,
                          subTotal: cartStore.total,
                          totalTax: 0,
                          discountAmount: 0,
                          totalAmount: cartStore.total,
                          paymentID: 0,
                          orderedBy: etuid,
                          orderedOn: new Date(),
                          status: 1,
                          orderDetails: Array.from(cartStore.cart.values()).map(
                            (item) =>
                              ({
                                orderDetailID: 0,
                                orderID: 0,
                                orderCode: "",
                                setID: item.setID,
                                unitPrice: item.setCost,
                                quantity: item.quantity,
                                discount: 0,
                                validFrom: new Date(),
                                validTo: new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 1
                                  )
                                ),
                              } as Models.OrderDetails)
                          ),
                          billingAddress,
                          paymentDetails: {
                            paymentID: 0,
                            orderID: 0,
                            totalAmount: cartStore.total,
                            paymentTypeID: 1,
                            transDate: new Date(),
                            transCode: "SCC01",
                            transStatus: 1,
                            transMsg: t("Success"),
                            userIpAddress: "",
                          },
                        } as Models.Order.Creation;

                        const { status, result } = await orderServices.create({
                          body: order,
                          customerDealerId: customerDealerId || 0,
                        });
                        if (
                          status === Constants.environment.apiStatus.Success
                        ) {
                          {
                            submitFormData(result);
                          }
                          document.getElementById("btnProcess")!.click();
                        }
                      } catch (error) {
                        console.error(error);
                      } finally {
                        setCreatingOrderWarningDialogOpen(false);
                      }
                    }}
                  >
                    {({ isSubmitting, errors }) => (
                      <>
                        <Mui.Typography
                          variant="subtitle1"
                          align="left"
                          gutterBottom
                        >
                           <ReactI18next.Trans i18nKey="ORDER SUMMARY">
                           ORDER SUMMARY
                            </ReactI18next.Trans>
                        </Mui.Typography>
                        <Mui.Divider />
                        <Mui.Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginTop={1}
                          marginBottom={2}
                        >
                          <Mui.Typography
                            color="textPrimary"
                            style={{ fontWeight: "bold" }}
                          >
                            <ReactI18next.Trans i18nKey="Total">
                            Total 
                            </ReactI18next.Trans>
                            {`(${cartStore.cart.size} Article(s))`}
                          </Mui.Typography>
                          <Mui.Typography
                            color="textPrimary"
                            style={{ fontWeight: "bold" }}
                          >
                            &euro;&nbsp;{localeGrandTotal}
                          </Mui.Typography>
                        </Mui.Box>
                        <Mui.Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Mui.Typography color="textSecondary" gutterBottom>
                          <ReactI18next.Trans i18nKey="Billing address">
                          Billing address
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          {!cartStore.billingAddress && (
                            <Mui.Button
                              onClick={() => setAddressLightboxOpen(true)}
                              color="primary"
                            >
                              {cartStore.billingAddress ? "EDIT" : "ADD"}
                            </Mui.Button>
                          )}
                        </Mui.Box>
                        <Mui.Divider />
                        {cartStore.billingAddress ? (
                          <>
                            <address
                              style={{
                                textTransform: "capitalize",
                                textAlign: "initial",
                                marginTop: theme.spacing(1),
                                marginBottom: theme.spacing(2),
                              }}
                            >
                              <Mui.Typography color="textPrimary">
                                {cartStore.billingAddress?.companyName}
                              </Mui.Typography>
                              <Mui.Typography color="textPrimary">
                                {cartStore.billingAddress?.firstName}{" "}
                                {cartStore.billingAddress?.lastName}
                              </Mui.Typography>
                              <Mui.Typography color="textPrimary">
                                {cartStore.billingAddress?.address1
                                  ? `${cartStore.billingAddress?.address1}, `
                                  : ""}
                                {cartStore.billingAddress?.address2
                                  ? `${cartStore.billingAddress?.address2}, `
                                  : ""}
                                {cartStore.billingAddress?.address3}
                              </Mui.Typography>
                              <Mui.Typography color="textPrimary">
                                {cartStore.billingAddress?.state
                                  ? `${cartStore.billingAddress?.state}, `
                                  : ""}
                                {cartStore.billingAddress?.city
                                  ? `${cartStore.billingAddress?.city}, `
                                  : ""}
                                {cartStore.billingAddress?.country}
                              </Mui.Typography>
                              <Mui.Typography color="textPrimary">
                                {cartStore.billingAddress?.postalCode}
                              </Mui.Typography>
                            </address>
                          </>
                        ) : (
                          <div
                            style={{
                              textAlign: "initial",
                              marginTop: theme.spacing(2),
                            }}
                          >
                            <MuiLab.Alert severity="warning">
                              <MuiLab.AlertTitle>
                                <ReactI18next.Trans i18nKey="Address missing">
                                  Address missing
                                </ReactI18next.Trans>
                              </MuiLab.AlertTitle>
                              <ReactI18next.Trans i18nKey="Add a billing address before proceeding.">
                                Add a billing address before proceeding.
                              </ReactI18next.Trans>
                            </MuiLab.Alert>
                          </div>
                        )}
                        <Mui.Box
                          textAlign="initial"
                          marginBottom={2}
                          marginTop={2}
                        >
                          <Shop.Form />
                        </Mui.Box>
                        <Mui.Box display="flex" justifyContent="flex-end">
                          {/* <Mui.Button
                    style={{ marginTop: theme.spacing(2) }}
                    color="primary"
                    variant="contained"
                    component={Router.Link}
                    to="/shop/checkout"
                  >
                    Checkout
                  </Mui.Button> */}
                          <Shop.ProceedToPaymentButton status={status} />
                        </Mui.Box>
                      </>
                    )}
                  </Formik.Formik>
                </Mui.Paper>
              </Mui.Grid>
            </Mui.Grid>
          </div>

          {/* <form method="post" id="myForm" action="https://www.ipg-online.com/connect/gateway/processing" >             */}
          
          <form method="post" id="myForm" action= {Configs.APP.ENV === "production" ? "https://www.ipg-online.com/connect/gateway/processing":"https://test.ipg-online.com/connect/gateway/processing" } >
            <input type="hidden" name="chargetotal" id="chargetotal" value="13.00"/>
            <input type="hidden" name="checkoutoption" id="checkoutoption" value="combinedpage"/>
            <input type="hidden" name="currency" id="currency" value="978" />
            <input type="hidden" name="hash_algorithm" id="hash_algorithm" value="HMACSHA256"/>
            <input type="hidden" name="language" id="language" value="" />
            <input type="hidden" name="mode" id="mode" value="payonly" />
            <input type="hidden" name="oid" id="oid" value="" />
            <input type="hidden" name="paymentMethod" id="paymentMethod" value="M"/>
            <input type="hidden" name="storename" id="storename" value="" />
            <input type="hidden" name="timezone" id="timezone" value="" />
            <input type="hidden" name="txndatetime" id="txndatetime" value="" />
            <input type="hidden" name="txntype" id="txntype" value="preauth" />
            <input type="hidden" name="hashExtended" id="hashExtended"value=""/>
            <input type="hidden" name="article" id="article" value="" />
            <input type="hidden" name="articleBy" id="articleBy" value="" />
            <input type="hidden" name="hash" id="hash" value="" />
            <input type="hidden" name="isCustomerInvitePurchase" id="isCustomerInvitePurchase" value="0" />
            {ProcessBtn()}
            <div id="divProcess">
              <Mui.Button type="submit" id="btnProcess"></Mui.Button>
            </div>
          </form>
        </>
      )}
    </>
  );
  function ProcessBtn() {
    window.onload = () => {
      document.getElementById("btnProcess")!.style.visibility = "hidden";
      document.getElementById("divProcess")!.style.display = "none";
    };

    if (document.getElementById("btnProcess")) {
      document.getElementById("btnProcess")!.style.visibility = "hidden";
    }
  }

  function submitFormData(fpay: any) {
    var chargetotal = fpay["requestedAmountValue"] + ".00";
    var currency = document.getElementById("currency")?.getAttribute("value");
    var paymentMethod = document.getElementById("paymentMethod")?.getAttribute("value");
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //fpay["timeZone"]; //"Europe/Berlin";//"Asia/Calcutta"; /
    var type = fpay["type"];
    type = type.split("#");
    var txntype = "preauth";
    var oId = fpay["requestId"]; // "teset01"
    var strValues = localStorage.getItem("isCustomerInvitePurchase");
    var isCustPurchans = strValues != null ? strValues : "0";
    var language = localStorage.getItem("currentCultureInfo") != null ? localStorage.getItem("currentCultureInfo") : "en_US";//"en_US";
    //
    //var language = "en_US";
    var today = new Date();
    var txndatetime = today.getFullYear() + ":" + (today.getMonth() + 1) +  ":" + today.getDate() +  "-" + today.getHours() 
    + ":" + today.getMinutes() + ":" + today.getSeconds();
    //If it is production  we need to pass Type[1] and Type[3] 
    //If it is dev/test we need to pass Type[1]
    var typeKey = Configs.APP.ENV === "production" ? (atob(type[1]) + "," + atob(type[3])+ "S")  : (atob(type[1]) + "B") ;
    var hashValue = createExtendedHash(
      chargetotal,
      currency,
      language,
      paymentMethod,
      timezone,
      txntype,
      txndatetime,
      typeKey,
      fpay["storeName"],
      oId
    );
    document.getElementById("chargetotal")!.setAttribute("value", fpay["requestedAmountValue"] + ".00");
    document.getElementById("storename")!.setAttribute("value", fpay["storeName"]);
    document.getElementById("txndatetime")!.setAttribute("value", txndatetime);
    document.getElementById("oid")!.setAttribute("value", oId);
    document.getElementById("timezone")!.setAttribute("value", timezone);
    document.getElementById("article")!.setAttribute("value", fpay["article"]);
    document.getElementById("language")!.setAttribute("value", language != null ? language :"en_US");
    document.getElementById("articleBy")!.setAttribute("value", fpay["articleBy"]);
    document.getElementById("isCustomerInvitePurchase")!.setAttribute("value", isCustPurchans);
    document.getElementById("hash")!.setAttribute("value",hashValue + "|" + oId + "|" + today.getDate() + "|11b480");
    document.getElementById("btnProcess")!.style.visibility = "hidden";
  }

  function createExtendedHash(
    chargetotal: any,
    currency: any,
    language: any,
    paymentMethod: any,
    timezone: any,
    txntype: any,
    txndatetime: any,
    sharedsecret: any,
    storeNameKey: any,
    oId: any
  ) {
    var CryptoJS = require("crypto-js");
    var storename = storeNameKey;
    var stringToExtendedHash =
      chargetotal +
      "|combinedpage|" +
      currency +
      "|HMACSHA256|" +
      language +
      "|payonly|" +
      oId +
      "|" +
      paymentMethod +
      "|" +
      //responseFailURL + "|" + //responseSuccessURL + "|" +
      storename +
      "|" +
      timezone +
      "|" +
      //transactionNotificationURL + "|" +
      txndatetime +
      "|" +
      txntype;
    //alert(stringToExtendedHash)
    var hashHMACSHA256 = CryptoJS.HmacSHA256(
      stringToExtendedHash,
      sharedsecret
    );
    var extendedhash = CryptoJS.enc.Base64.stringify(hashHMACSHA256);
    //alert(extendedhash)
    document.getElementById("language")!.setAttribute("value", language);
    document.getElementById("hashExtended")!.setAttribute("value", extendedhash);
    // 
    return extendedhash;
  }
});
