import * as Mobx from "mobx";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    dashboardService: Services.Dashboard;
    dashboard?: any;
  }

  export type Options = Pick<IStore, "dashboardService">;

  export namespace Read {
    export interface Props extends Services.Dashboard.GetAllData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public isLoading: boolean = false;
  @Mobx.observable public dashboard?: any;

  public dashboardService: Services.Dashboard;

  constructor({ dashboardService }: Store.Options) {
    this.dashboardService = dashboardService;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.dashboardService.getAllData(props);
      const reducedSets = result.sets.filter( (x: any) => x.licenseCode != 'CustInvite')
      .reduce(
        (reducedSets: Map<string, any>, set: any) => {
          const storedSet = reducedSets.get(set.setID);
          if (storedSet && set.status === 1) {
            storedSet.available += 1;
            return reducedSets;
          }
          if (storedSet && set.status !== 1) {
            storedSet.used += 1;
            return reducedSets;
          }

          if (set.status === 1) {
            set["available"] = 1;
            set["used"] = 0;
          } else {
            set["available"] = 1;
            set["used"] = 1;
          }
          reducedSets.set(set.setID, set);
          return reducedSets;
        },
        new Map()
      );

     this.dashboard = { ...result, sets: Array.from(reducedSets.values()) };

    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.dashboard) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
