import * as React from "react";
import * as Router from "react-router-dom";
import * as Layouts from "src/app/layouts";
import * as Routes from "src/app/routes";

export function DeviceConfigurationUpload() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["superadmin","admin"]}
          path="/"
          element={<Layouts.ExternalNavi.Main />}
        />
      </Router.Routes>
    </>
  );
}
