/** @format */

import * as Mobx from "mobx";
import * as Article from "src/app/pages/article";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    addCustomer: Models.AddDealer.Main | undefined;
    addCustomerService: Services.AddCustomer;
  }

  export type Options = {
    addCustomerService: Services.AddCustomer;
    articleStore: Article.Store;
  };

  export namespace InviteCustomer {
    export interface Props
      extends Services.InviteCustomerService.InviteCustomer.Props {}
  }
  export namespace InviteFromManageOrder {
    export interface Props
      extends Services.AddCustomer.InviteFromManageOrder.Props {}
  }

  export namespace Read {
    export interface Props extends Services.AddCustomer.GetAllData.Return {}
  }
  export namespace GetAllPurchanseSetData {
    export interface Props {
      orderID?: string | any;
    }
    export type Return = Models.API.Response<Models.Set.Article[]>;
  }
  export namespace cancelInvite {
    export type Props = Services.AddCustomer.CancelInvite.Props;
    export type Return = Models.API.Response<any>;
  }

  export namespace unassignAll {
    export type Props = Services.AddCustomer.UnAssignAll.Props;
    export type Return = Models.API.Response<any>;
  }

  export namespace cancelInviteForSingleLicense {
    export type Props = Services.AddCustomer.CancelInviteForSingleLicense.Props;
    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public addCustomer: Models.AddDealer.Main | undefined;

  @Mobx.observable public isLoading: boolean = false;
  public addCustomerService: Services.AddCustomer;
  articleStore: Article.Store;
  constructor({ addCustomerService, articleStore }: Store.Options) {
    this.addCustomerService = addCustomerService;
    this.articleStore = articleStore;
  }

  @Mobx.action public async readUsers() {
    try {
      this.isLoading = true;
      const { result } = await this.addCustomerService.getAllData();
      this.addCustomer = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async inviteCustomer(props: Store.InviteCustomer.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.addCustomerService.inviteCustomer(props);
      return result;
    } catch (error) {
      console.error(error);
    }
  }
  @Mobx.action public async inviteFromManageOrder(
    props: Store.InviteFromManageOrder.Props
  ) {
    try {
      this.isLoading = true;
      const { result } = await this.addCustomerService.inviteFromManageOrder(
        props
      );
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.action public async getPurchangeArticleDetails(
    props: Store.GetAllPurchanseSetData.Props
  ) {
    try {
      this.isLoading = true;
      const result = await this.articleStore.readCustomerInvitePurchange({
        orderID: props?.orderID,
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
  @Mobx.action public async cancelInvite(props: Store.cancelInvite.Props) {
    try {
      this.isLoading = true;
      const result = await this.addCustomerService.cancelInvite(props);
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async unAssignAll(props: Store.unassignAll.Props) {
    try {
      this.isLoading = true;
      const result = await this.addCustomerService.unAssignAll(props);
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async cancelInviteForSingleLicense(
    props: Store.cancelInviteForSingleLicense.Props
  ) {
    try {
      this.isLoading = true;
      const result = await this.addCustomerService.cancelInviteForSingleLicense(
        props
      );
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
