import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Tenant {
  export interface ITenant {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = Tenant.ITenant;

  export namespace Create {
    export type Body = Pick<
      Models.Tenant.Main,
      | "companyAliasName"
      | "companyName"
      | "databaseName"
      | "firstName"
      | "lastName"
      | "userName"
      | "ETUID"
    > & {
      password: string;
    };

    export interface Props {
      body: Tenant.Create.Body;
    }

    export type Return = Models.API.Response<number>;
  }

  export namespace GetAllData {
    export interface Props {}

    export type Return = Models.API.Response<Models.Tenant.Main[]>;
  }

  export namespace GetById {
    export interface Props {
      etuid: number;
    }

    export type Return = Models.API.Response<any[]>;
  }

  export namespace IsCompanyNameAlreadyExists {
    export interface Props extends Pick<Models.Company.Main, "companyName"> {}

    export type Return = Models.API.Response<boolean>;
  }

  export namespace IsUserNameAlreadyExists {
    export interface Body
      extends Pick<
        Models.Company.Main,
        "userName" | "companyAliasName" | "firstName" | "lastName"
      > {}

    export interface Props {
      body: Tenant.IsUserNameAlreadyExists.Body;
    }

    export type Return = Models.API.Response<boolean>;
  }

  export namespace UpdateCompanyReady {
    export interface Body {
      tenantId: number | string;
    }

    export interface Props {
      body: Tenant.UpdateCompanyReady.Body;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class Tenant {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;

  constructor({ urlFactory, httpManager }: Tenant.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllData(
    props?: Tenant.GetAllData.Props
  ): Promise<Tenant.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["Company", "GetAll"],
    });
  //  console.log({ url });

    const response = await this.httpManager.get<Tenant.GetAllData.Return>({
      url,
    });
    // console.log({ response });

    return response;
  }

  public async getById({
    etuid,
  }: Tenant.GetById.Props): Promise<Tenant.GetById.Return> {
    const url = this.urlFactory.create({
      named: ["Company", "GetById", `${etuid}`],
    });
    // console.log({ url });

    const response = await this.httpManager.get<Tenant.GetById.Return>({
      url,
    });
    // console.log({ response });

    return response;
  }

  public async isCompanyNameAlreadyExists({
    companyName,
  }: Tenant.IsCompanyNameAlreadyExists.Props): Promise<
    Tenant.IsCompanyNameAlreadyExists.Return
  > {
    // const url = this.urlFactory.create({
    //   named: ["Company​", "IsNameExist​", `${companyName}`],
    // });

    const url: string = `${this.urlFactory.create(
      {}
    )}/Company/IsNameExist/${companyName}`;

   // console.log({ url });

    const response = await this.httpManager.get<
      Tenant.IsCompanyNameAlreadyExists.Return
    >({
      url,
      // url: `${this.urlFactory.create({})}/Company/IsNameExist/${companyName}`,
    });
    // console.log({ response });

    return response;
  }

  public async isUserNameAlreadyExists({
    body,
  }: Tenant.IsUserNameAlreadyExists.Props): Promise<
    Tenant.IsUserNameAlreadyExists.Return
  > {
    const url = this.urlFactory.create({
      named: ["Company​", "IsUserExistInCompany"],
    });
    //console.log({ url });

    const response = await this.httpManager.post<
      Tenant.IsUserNameAlreadyExists.Return,
      Tenant.IsUserNameAlreadyExists.Body
    >({
      body,
      url: `${this.urlFactory.create({})}/Company/IsUserExistInCompany`,
    });
    // console.log({ response });

    return response;
  }

  public async create({
    body,
  }: Tenant.Create.Props): Promise<Tenant.Create.Return> {
    const url = this.urlFactory.create({
      named: ["Company"],
    });
   // console.log({ url });

    const response = await this.httpManager.post<
      Tenant.Create.Return,
      Tenant.Create.Body
    >({
      url,
      body,
    });
    // console.log({ response });

    return response;
  }

  public async updateCompanyReady({
    body,
  }: Tenant.UpdateCompanyReady.Props): Promise<
    Tenant.UpdateCompanyReady.Return
  > {
    const url = this.urlFactory.create({
      named: ["Company", "Ready"],
    });
    //console.log({ url });

    const response = await this.httpManager.post<
      Tenant.UpdateCompanyReady.Return,
      Tenant.UpdateCompanyReady.Body
    >({
      url,
      body,
    });
    // console.log({ response });

    return response;
  }
}
