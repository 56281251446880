import * as Mui from "@material-ui/core";
import { ResponsiveBar } from "@nivo/bar";
import * as React from "react";

const data = [
  {
    country: "AD",
    "hot dog": 7,
    "hot dogColor": "hsl(60, 70%, 50%)",
    burger: 16,
    burgerColor: "hsl(44, 70%, 50%)",
    sandwich: 65,
    sandwichColor: "hsl(114, 70%, 50%)",
    kebab: 117,
    kebabColor: "hsl(151, 70%, 50%)",
    fries: 162,
    friesColor: "hsl(226, 70%, 50%)",
    donut: 71,
    donutColor: "hsl(75, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 124,
    "hot dogColor": "hsl(240, 70%, 50%)",
    burger: 29,
    burgerColor: "hsl(120, 70%, 50%)",
    sandwich: 188,
    sandwichColor: "hsl(343, 70%, 50%)",
    kebab: 160,
    kebabColor: "hsl(81, 70%, 50%)",
    fries: 6,
    friesColor: "hsl(132, 70%, 50%)",
    donut: 135,
    donutColor: "hsl(142, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 156,
    "hot dogColor": "hsl(226, 70%, 50%)",
    burger: 71,
    burgerColor: "hsl(193, 70%, 50%)",
    sandwich: 55,
    sandwichColor: "hsl(234, 70%, 50%)",
    kebab: 32,
    kebabColor: "hsl(178, 70%, 50%)",
    fries: 141,
    friesColor: "hsl(322, 70%, 50%)",
    donut: 184,
    donutColor: "hsl(318, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 199,
    "hot dogColor": "hsl(1, 70%, 50%)",
    burger: 180,
    burgerColor: "hsl(356, 70%, 50%)",
    sandwich: 133,
    sandwichColor: "hsl(359, 70%, 50%)",
    kebab: 54,
    kebabColor: "hsl(271, 70%, 50%)",
    fries: 113,
    friesColor: "hsl(34, 70%, 50%)",
    donut: 157,
    donutColor: "hsl(327, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 65,
    "hot dogColor": "hsl(194, 70%, 50%)",
    burger: 158,
    burgerColor: "hsl(85, 70%, 50%)",
    sandwich: 40,
    sandwichColor: "hsl(18, 70%, 50%)",
    kebab: 154,
    kebabColor: "hsl(130, 70%, 50%)",
    fries: 191,
    friesColor: "hsl(184, 70%, 50%)",
    donut: 146,
    donutColor: "hsl(59, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 134,
    "hot dogColor": "hsl(96, 70%, 50%)",
    burger: 21,
    burgerColor: "hsl(216, 70%, 50%)",
    sandwich: 38,
    sandwichColor: "hsl(111, 70%, 50%)",
    kebab: 119,
    kebabColor: "hsl(157, 70%, 50%)",
    fries: 164,
    friesColor: "hsl(257, 70%, 50%)",
    donut: 199,
    donutColor: "hsl(135, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 55,
    "hot dogColor": "hsl(101, 70%, 50%)",
    burger: 75,
    burgerColor: "hsl(316, 70%, 50%)",
    sandwich: 49,
    sandwichColor: "hsl(48, 70%, 50%)",
    kebab: 122,
    kebabColor: "hsl(210, 70%, 50%)",
    fries: 50,
    friesColor: "hsl(179, 70%, 50%)",
    donut: 78,
    donutColor: "hsl(247, 70%, 50%)",
  },
];

export const ResponsiveVerticalBar = (props?: {
  data?: any[];
  indexBy?: string;
  keys?: string[];
  margin?: Partial<{
    bottom: number;
    left: number;
    right: number;
    top: number;
  }>;
}) => {
  const theme = Mui.useTheme();

  return (
    <ResponsiveBar
      theme={{
        tooltip: {
          container: {
            background: theme.palette.background.default,
          },
        },
        axis: {
          ticks: {
            line: {
              stroke: theme.palette.text.secondary,
            },
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
      }}
      data={props?.data || data}
      keys={
        props?.keys || [
          "hot dog",
          "burger",
          "sandwich",
          "kebab",
          "fries",
          "donut",
        ]
      }
      indexBy={props?.indexBy || "country"}
      margin={props?.margin || { top: 0, right: 0, bottom: 40, left: 50 }}
      padding={0.3}
      innerPadding={theme.spacing(1)}
      layout="vertical"
      colors={[theme.palette.secondary.main, theme.palette.primary.main]}
      groupMode="grouped"
     
      borderRadius={4}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={theme.palette.getContrastText(theme.palette.primary.main)}
      legends={[]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};
