import * as Models from "src/models";
import * as Services from "src/services";
// import * as Configs from "src/configs";

export declare namespace AdminManageService {
  export interface IAdminManageService {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = AdminManageService.IAdminManageService;

  export namespace GetAllCustomerData {
    export interface Body {
      customerName?: string;
      companyName: string;
      emailID: string;
    }
    export type Props = Models.API.Response<Models.AdminManage.Main[]>;

    export type Return = Models.API.Response<Models.AdminManage.Main[]>;
  }
  export namespace GetAllData {
    export interface Body {
      status?: number;
      FromDate: string;
      ToDate: string;
    }

    export interface Props extends Pick<Models.User.Main, "uid"> {
      body?: AdminManageService.GetAllData.Body;
      userRole: string;
    }

    export type Return = Models.API.Response<Models.AdminManage.Main[]>;
  }

  export namespace GetData {
    export interface Body {
      id?: number | string;
      eid?: string;
    }

    export interface Props {
      body: AdminManageService.GetData.Body;
    }

    export type Return = Models.API.Response<Models.DealerCustomer.Main[]>;
  }

  export namespace GetGeneralTermsAndConditions {
    export interface Props {
      language: string;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace GetPrivacyPolicy {
    export type Props = AdminManageService.GetGeneralTermsAndConditions.Props;

    export type Return = AdminManageService.GetGeneralTermsAndConditions.Return;
  }

  export namespace Create {
    export type Body = Models.DealerCustomer.Creation;
    export interface Props {
      body: AdminManageService.Create.Body;
      customerDealerId: string | number;
    }

    export type Return = Models.API.Response<any>;
  }
  export namespace InviteCustomer {
    export type Return = Models.API.Response<any>;
    export type Body = Models.InviteCustomer.Main;
    // }

    export interface Props {
      body: AdminManageService.InviteCustomer.Body;
    }
  }
}

// eslint-disable-next-line no-redeclare
export class AdminManageService {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: AdminManageService.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getData({
    body,
  }: AdminManageService.GetData.Props): Promise<
  AdminManageService.GetData.Return
  > {
    const url = this.urlFactory.create({
      named: ["Order", "GetByOrderId"],
    });

    const response = await this.httpManager.post<
    AdminManageService.GetData.Return,
    AdminManageService.GetData.Body
    >({
      url,
      body: {
        ...body,
        eid: body.eid ?? "",
      },
    });

    return response;
  }
  public async getCustomer({
    body,
    uid,
    userRole,
  }: AdminManageService.GetAllData.Props): Promise<
  AdminManageService.GetAllCustomerData.Return
  > {
    const url =
      userRole === "customer"
        ? this.urlFactory.create({
            named: ["Admin", "GetAdminList",],
          })
        : this.urlFactory.create({
            named: ["Admin", "GetAdminList",],
          });

    const response = await (userRole === "customer" ||
    userRole === "admin" || userRole === "superadmin" ||
    userRole === "dealer"
      ? this.httpManager.get<AdminManageService.GetAllData.Return>({
          url,
        })
      : this.httpManager.post<
      AdminManageService.GetAllData.Return,
      AdminManageService.GetAllData.Body
        >({
          url,
          body: body ?? {
            status: 1,
            FromDate: `${new Date(
              new Date().setDate(new Date().getDate() - 100)
            ).toUTCString()}`,
            ToDate: `${new Date().toUTCString()}`,
          },
        }));

    return response;
  }

  public async getAllData({
    body,
    uid,
    userRole,
  }: AdminManageService.GetAllData.Props): Promise<
  AdminManageService.GetAllData.Return
  > {
    const url =
      userRole === "customer"
        ? this.urlFactory.create({
            named: ["Order", "Get", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["Order", "GetAll"],
          });

    const response = await (userRole === "customer"
      ? this.httpManager.get<AdminManageService.GetAllData.Return>({
          url,
        })
      : this.httpManager.post<
      AdminManageService.GetAllData.Return,
      AdminManageService.GetAllData.Body
        >({
          url,
          body: body ?? {
            status: 1,
            FromDate: `${new Date(
              new Date().setDate(new Date().getDate() - 100)
            ).toUTCString()}`,
            ToDate: `${new Date().toUTCString()}`,
          },
        }));

    return response;
  }

  public async create({
    body,
    customerDealerId,
  }: AdminManageService.Create.Props): Promise<
  AdminManageService.Create.Return
  > {
    const url = this.urlFactory.create({
      named: ["Order", "Create", `${customerDealerId}`],
    });

    const response = await this.httpManager.post<
    AdminManageService.Create.Return,
    AdminManageService.Create.Body
    >({
      url,
      body,
    });

    return response;
  }


  public async inviteCustomer({
    body,
  }: AdminManageService.InviteCustomer.Props): Promise<AdminManageService.InviteCustomer.Return> {
    const url = this.urlFactory.create({
      named: ["Customer", "AddCustomer"],
    });

    const response = await this.httpManager.post<
    AdminManageService.InviteCustomer.Return,
    AdminManageService.InviteCustomer.Body
    >({
      body: body,
      url,
    });

    return response;
  }
}
