import * as Mui from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import * as React from "react";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
export declare namespace FunctionCountIcons {
  export interface Props {
    functions: Models.Set.Function[];
  }
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
    border: '2px solid #dadde9',
  },
}))(Mui.Tooltip);
export function FunctionCountIcons({ functions }: FunctionCountIcons.Props) {
  const theme = Mui.useTheme();
  return (
    <Mui.Box position="relative">
     
      <Mui.Box
        position="absolute"
        width={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        top= "-14px"
      >
        {functions.slice(0,4).map((func) => (
          <HtmlTooltip title={func.funcationName} key={uuidv4()}>
            <Mui.Avatar style={{ marginRight: theme.spacing(1.0) }}>
              <Mui.Box color="text.primary">
                <i className={func.iconClass} />
              </Mui.Box>
            </Mui.Avatar>
          </HtmlTooltip>
        ))}
        {
         functions.length > 4 ?(<HtmlTooltip title={<>{functions.slice(4,functions.length).map((k)=>(<><i className={k.iconClass} style={{margin:"6px"}} />
         {k.funcationName}<br/></>))}</>} >
         <Mui.Typography
         >+{functions.length - 4}more </Mui.Typography>
         </HtmlTooltip>
         ):""}

      </Mui.Box>
    </Mui.Box>
  );
}
