import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as ReactUndraw from "iblis-react-undraw";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as Components from "src/app/components";
import * as Yup from "yup";

const useStyles = Mui.makeStyles((theme) => ({
  resetPasswordCardContainer: {
    paddingTop: theme.spacing(6),
  },
  forgotPasswordIllustration: {
    width: "inherit",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

export declare namespace ResetPassword {
  export namespace Form {
    export interface Values {
      email: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export function ResetPassword() {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("pages.account.resetPassword.inputErrors.email.required"))
      .email(t("pages.account.resetPassword.inputErrors.email.invalid")),
  });

  return (
    <>
      <Mui.Box className={classes.resetPasswordCardContainer}>
        <Mui.Typography
          component="div"
          variant="h6"
          color="primary"
          align="center"
          gutterBottom
        >
          <Mui.Box>
            <ReactI18next.Trans i18nKey="Easy Touch Shop & License Management">
              Easy Touch Shop & License Management
            </ReactI18next.Trans>
          </Mui.Box>
        </Mui.Typography>
        <Mui.Paper>
          <ReactUndraw.SvgMyPassword
            primarycolor={theme.palette.primary.main}
            height={theme.spacing(27)}
            className={classes.forgotPasswordIllustration}
          />
          <Mui.Box padding={3}>
            <Mui.Box marginBottom={3}>
              <Components.Logo />
            </Mui.Box>
            <Mui.Box
              display="inline-flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Mui.Typography variant="h5" gutterBottom>
                <ReactI18next.Trans i18nKey={"Forgot Password"}>
                  Forgot Password
                </ReactI18next.Trans>
              </Mui.Typography>
              <Components.LocaleDropdown />
            </Mui.Box>
            <Formik.Formik
              initialValues={{
                email: "",
              }}
              validationSchema={ResetPasswordSchema}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  alert(JSON.stringify(values, null, 2));
                }, 500);
              }}
            >
              {({
                isSubmitting,
                submitForm,
              }: Formik.FormikProps<ResetPassword.Form.Values>) => (
                <Formik.Form>
                  <Mui.FormControl fullWidth margin="normal">
                    <Formik.Field
                      autoFocus
                      component={FormikMui.TextField}
                      name="email"
                      type="email"
                      label={
                        <ReactI18next.Trans i18nKey={"Email ID"}>
                          Email ID
                        </ReactI18next.Trans>
                      }
                      variant="outlined"
                    />
                  </Mui.FormControl>

                  <Mui.FormControl>
                    <Mui.Typography gutterBottom>
                      <ReactI18next.Trans
                        i18nKey=" A link to reset password will be sent to your email
                        address."
                      >
                        A link to reset password will be sent to your email
                        address.
                      </ReactI18next.Trans>
                    </Mui.Typography>
                  </Mui.FormControl>

                  {isSubmitting && <Mui.LinearProgress />}

                  <Mui.Box
                    display="grid"
                    gridAutoFlow="column"
                    gridColumnGap={theme.spacing(3)}
                  >
                    <Mui.FormControl fullWidth margin="normal">
                      <Mui.Button
                        component={Router.Link}
                        to="/account/login"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        <ReactI18next.Trans i18nKey={"Back To Login"}>
                          Back To Login
                        </ReactI18next.Trans>
                      </Mui.Button>
                    </Mui.FormControl>
                    <Mui.FormControl fullWidth margin="normal">
                      <Mui.Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <ReactI18next.Trans i18nKey={"Send Email"}>
                          Send Email
                        </ReactI18next.Trans>
                      </Mui.Button>
                    </Mui.FormControl>
                  </Mui.Box>

                  <Mui.FormControl>
                    <Mui.Typography>
                      <ReactI18next.Trans i18nKey="Don't have an account?">
                        Don't have an account?
                      </ReactI18next.Trans>
                      &nbsp;&nbsp;
                      <Mui.Typography component="span">
                        <Mui.Link
                          component={Router.Link}
                          to="/account/register"
                          color="primary"
                        >
                          <ReactI18next.Trans i18nKey="Register">
                            Register
                          </ReactI18next.Trans>
                        </Mui.Link>
                      </Mui.Typography>
                    </Mui.Typography>
                  </Mui.FormControl>
                </Formik.Form>
              )}
            </Formik.Formik>
          </Mui.Box>
        </Mui.Paper>
      </Mui.Box>
    </>
  );
}
