import * as Mui from "@material-ui/core";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Configs from "src/configs";
import { v4 as uuidv4 } from "uuid";

const useStyles = Mui.makeStyles((theme) => ({
  selectRoot: {
    color: (props: LocaleDropdown.Styled.Props) => props.color || "inherit",
  },
  select: {
    "& .MuiSelect-select": {
      paddingRight: (props: LocaleDropdown.Styled.Props) =>
        props.showLabel ? "initial" : 0,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: (props: LocaleDropdown.Styled.Props) =>
        props.showLabel ? "initial" : "transparent",
    },
    "& .MuiSelect-icon": {
      display: (props: LocaleDropdown.Styled.Props) =>
        props.showLabel ? "initial" : "none",
    },
  },
  selectFormControl: {
    "& .MuiInput-underline:before,.MuiInput-underline:after,.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
  },
}));

export declare namespace LocaleDropdown {
  export interface Props extends Omit<Mui.FormControlProps, "color"> {
    color?: string;
    showLabel?: boolean;
  }

  export namespace Styled {
    export interface Props
      extends Pick<LocaleDropdown.Props, "color" | "showLabel"> {}
  }
}

// eslint-disable-next-line no-redeclare
export function LocaleDropdown({
  color,
  showLabel,
  ...props
}: LocaleDropdown.Props) {
  const classes = useStyles({
    color,
    showLabel,
  } as LocaleDropdown.Styled.Props);
  const theme = Mui.useTheme();
  const { i18n } = ReactI18next.useTranslation();

  const dropdownOptions = [
    {
      value: "en",
      shortLabel: "EN",
      label: "English",
    },
    {
      value: "de",
      shortLabel: "DE",
      label: "German",
    },
    {
      value: "fr",
      shortLabel: "FR",
      label: "French",
    },
    {
      value: "it",
      shortLabel: "IT",
      label: "Italian",
    },
    {
      value: "es",
      shortLabel: "ES",
      label: "Spanish",
    },
  ];

  function handleChangeLocaleDropdown() {
    return function (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) {
      i18n.changeLanguage(event.target.value as string);
      let currentCultureInfo : any;
      currentCultureInfo = event.target.value;
      if(currentCultureInfo == "de")
      {
        currentCultureInfo = "de_DE"
      } else if(currentCultureInfo == "fr")
      {
        currentCultureInfo = "fr_FR"
      } else if(currentCultureInfo == "it")
      {
        currentCultureInfo = "it_IT"
      } else if(currentCultureInfo == "es")
      {
        currentCultureInfo = "es_ES"
      } else 
      {
        currentCultureInfo = "en_US"
      }
      localStorage.setItem("currentCultureInfo", currentCultureInfo);
      // console.log('event.target.value', event.target.value);
    };
  }

  return (
    <Mui.Tooltip
      title={
        showLabel ? (
          ""
        ) : (
          <ReactI18next.Trans i18nKey={"Language"}>
            Language
          </ReactI18next.Trans>
        )
      }
      placement="left"
    >
      <Mui.FormControl
        {...props}
        {...(!showLabel && { className: classes.selectFormControl })}
      >
        {showLabel && (
          <Mui.InputLabel id="locale-dropdown-label"><ReactI18next.Trans i18nKey={"Language"}>
          Language
        </ReactI18next.Trans></Mui.InputLabel>
        )}
        <Mui.Select
          classes={{ root: classes.selectRoot }}
          labelId="locale-dropdown-label"
          id="local-dropdown"
          value={i18n.language}
          onChange={handleChangeLocaleDropdown()}
          className={classes.select}
        >
          {dropdownOptions.map((option) => (
            <Mui.MenuItem key={uuidv4()} value={option.value}>
              <Mui.Box display="inline-flex" alignItems="flex-end">
                <img
                  style={{
                    height: 23,
                    width: 25,
                    padding: 0,
                    margin: 0,
                    marginRight: theme.spacing(1),
                  }}
                  src={Configs.APP.prefixPublicUrl({
                    url: `assets/flags/${option.label}.svg`,
                  })}
                  alt={option.shortLabel.toLowerCase()}
                />
                <Mui.Typography >{showLabel ? option.label : option.shortLabel}</Mui.Typography>
              </Mui.Box>
            </Mui.MenuItem>
          ))}
        </Mui.Select>
      </Mui.FormControl>
    </Mui.Tooltip>
  );
}
