/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Components from "src/app/components";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as Services from "src/services";
import * as App from "src/app";
import * as Constants from "src/constants";

interface Data extends Models.Order.Main {}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export declare namespace Cards {
  export interface Props {
    orders: Models.Order.Main[];
  }
}

// eslint-disable-next-line no-redeclare
export function Cards({ orders }: Cards.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);

  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderCode, setOrderCode] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);
  const [orderedOn,setOrderedOn] = React.useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(16);
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  React.useEffect(() => {
    setPage(0);
  }, [orders]);
  
  React.useEffect(() => {
    (async function () {
  const uid = userStore.user?.ETUID;

      if (!orderId) {
        return;
      }

      try {
        const {
          result: [order],
        } = await orderServices.getData({
          body: { id: orderId, cid: uid },
        });
     
       var {result} =  await orderServices.getData({
          body: { id: orderId, cid: uid },
        });

        //overAllStatus = 3 -- > Order completely returned
        if(result.find((k:any)=>k.status === 3)){
        order.overAllStatus = 3
        var partialReturnOrderObjectForStatus3 =  result.find((k:any)=>k.status === 2);
        partialReturnOrderObjectForStatus3?.orderDetails.map((k:any)=>{
         // eslint-disable-next-line array-callback-return
         order.orderDetails.map((q:any)=>{
        if(q.setID === k.setID ){
        q.returnQuantity = k.quantity
        }
         })
        })
        } 
        //overAllStatus = 2 -- > Order paretially returned
        else if(result.find((k:any)=>k.status === 4)){
          order.overAllStatus = 4
       var partialReturnOrderObjectForStatus4 =  result.find((k:any)=>k.status === 2);
       partialReturnOrderObjectForStatus4?.orderDetails.map((k:any)=>{
        // eslint-disable-next-line array-callback-return
        order.orderDetails.map((q:any)=>{
       if(q.setID === k.setID ){
       q.returnQuantity = k.quantity
       }
        })
       })
       // 
     
      } 
      else if(result.find((k:any)=>k.status === 5)){
        order.overAllStatus = 5
     var partialReturnOrderObjectForStatus5 =  result.find((k:any)=>k.status === 2);
     partialReturnOrderObjectForStatus5?.orderDetails.map((k:any)=>{
      // eslint-disable-next-line array-callback-return
      order.orderDetails.map((q:any)=>{
     if(q.setID === k.setID ){
     q.returnQuantity = k.quantity
     }
      })
     })
     // 
   
    } 
      //overAllStatus = 1 -- > Order paretially returned Order with no return
      else{
        order.overAllStatus = 1
      }
      
       setOrders(order);
   
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsDialogOpen]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    
    title: {
      flexGrow: 1,
    },
  })
);

const key = 'orderCode';
const [order, setOrders] = React.useState<Models.Order.Main>();

const orderArrayUniqueByKey = [...new Map(orders.map(item =>
  [item[key], item])).values()];

const classes = useStyles();
  return (
    <>
      <Components.LightBox
      open={orderDetailsDialogOpen}
      handleClose={() => setOrderDetailsDialogOpen(false)}
      content={<Order.Details orderId={orderId}  orderCode={orderCode} orderedOn={orderedOn}/>}
      title={
        <>
        <Mui.Grid container style={{display:"flex",justifyContent:"flex-start",flexDirection:"row"}}>
              <Mui.Grid item xs={2} style={{display:"flex"}}>
               
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          <ReactI18next.Trans i18nKey="Order details">
            Order details
          </ReactI18next.Trans>
        </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item xs={2} style={{display:"flex"}}>
           <Components.OrderStatus
              status={order?.overAllStatus   as 1 | 2 | 3 | 4 | 5}
    
               />
                 </Mui.Grid>
      </Mui.Grid>
    </>
      }
      actions={
        <>
        <Mui.Button className="noprint" variant="contained" onClick={() => setOrderDetailsDialogOpen(false)}>
          <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
        </Mui.Button>
        <Mui.Button
        className="noprint"
          variant="contained"
          color="primary"
          onClick={() => {
            window.print();
          }}
        >
          <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
        </Mui.Button>
      </>
      }
    />

      <Mui.Grid
        container
        spacing={3}
        style={{ marginBottom: theme.spacing(0.5) }}
      >
        {stableSort(orderArrayUniqueByKey as any, getComparator("asc", "orderCode"))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((order, index) => (
            <Mui.Grid key={uuidv4()} item xs={12} md={6} lg={3}>
              <Order.Card
                handleClick={({ orderId,OrderedOn }) => {
                  setOrderDetailsDialogOpen(true);
                  setOrderId(orderId);
                  setOrderCode(order.orderCode)
                  setOrderedOn(OrderedOn)
                }}
                order={(order as unknown) as Models.Order.Main}
              />
            </Mui.Grid>
          ))}
        {orders.length === 0 && (
          <Mui.Grid container>
            <Mui.Grid
              item
              style={{ marginTop: "29.5px", marginLeft: "12px" }}
              xs={12}
            >
              <MuiLab.Alert severity="info"><ReactI18next.Trans i18nKey="No orders found">No orders found</ReactI18next.Trans></MuiLab.Alert>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      {orders.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(orders.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    </>
  );
}
