import * as Mui from "@material-ui/core";
import "mobx-react-lite/batchingForReactDom";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as App from "src/app";
import "src/styles/index.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
   
    <React.Suspense
      fallback={
        <Mui.Grid container
          justify="center"
          alignItems="center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <Mui.CircularProgress />
        </Mui.Grid>
      }
    >
      <App.Main />
    </React.Suspense>
,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
