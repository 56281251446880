import * as React from "react";

export declare namespace Redirect {
  export interface Props {
    to: string;
  }
}

// eslint-disable-next-line no-redeclare
export function Redirect({ to }: Redirect.Props) {
  window.location.replace(to);
  return <>{null}</>;
}
