import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as DateFns from "date-fns";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";

interface Data extends Models.Order.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

function EnhancedTable({ rows }: { rows: Models.Order.Main[] }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("orderCode");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(5);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();

  return (
    <div className={classes.root}>
      <Components.ResponsiveFullscreenDialogWithScroll
        fullscreenBreakpoint="md"
        open={orderDetailsDialogOpen}
        handleClose={() => setOrderDetailsDialogOpen(false)}
        title={t("Order details")}
        contentText={<Order.Details orderId={orderId} />}
        actions={
          <>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => window.print()}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              onClick={() => setOrderDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="Close">Close</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      {/* <Mui.Paper className={classes.paper}> */}
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <Mui.TableHead>
            <Mui.TableRow>
              <EnhancedTableHead
                leftmost
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="orderCode"
                label={t("Order #")}
              />

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="grossTotalPriceInclVAT"
                label={t("Amount")}
                numeric
              />

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                id="orderedOn"
                label={t("Date")}
              />

              <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderedBy"
                  label={t("Ordered by")}
                />
              </Containers.ShowIfAuthorised>

              <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="companyName"
                  label={t("Company")}
                />
              </Containers.ShowIfAuthorised>

              <Components.Mui.TableCell
                color={theme.palette.divider}
                rightmost
                align="right"
              >
                <ReactI18next.Trans i18nKey="View">View</ReactI18next.Trans>
              </Components.Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(rows as any, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.orderCode as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) =>
                      handleClick(event, row.orderCode as string)
                    }
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderCode}
                    // selected={isItemSelected}
                  >
                    <Components.Mui.TableCell
                      leftmost
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                                      {row.dataFrom === "webshop" && row.sageOLReferenceBillID != null && row.sageOLReferenceBillID > 0 && row.assignedTo === "0" ? row.orderCode+"-"+row.sageOLReferenceBillID : row.orderCode}

                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell align="right">
                      {Numeral(row.grossTotalPriceInclVAT).format("$ 0,.00a")}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell>
                      {DateFns.format(new Date(row.orderedOn), "dd.MM.yyyy")}
                    </Components.Mui.TableCell>
                    <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                      <Components.Mui.TableCell>
                      {row.dataFrom ==="webshop" && row.assignedTo !== "0" ? "-" : row.userFirstName +" "+ row.userLastName}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    <Containers.ShowIfAuthorised roles={["superadmin","admin"]}>
                      <Components.Mui.TableCell>
                        {row.companyName}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    <Components.Mui.TableCell rightmost align="right">
                      <Mui.IconButton
                        size="small"
                        onClick={() => {
                          setOrderId(row.orderID);
                          setOrderDetailsDialogOpen(true);
                        }}
                        style={{
                          padding: theme.spacing(0.125),
                        }}
                      >
                        <Mui.Box fontSize={30} lineHeight={0}>
                          {/* <MuiIcons.Visibility /> */}
                          <i className="icon-etc-view"></i>
                        </Mui.Box>
                      </Mui.IconButton>
                    </Components.Mui.TableCell>
                  </Mui.TableRow>
                );
              })}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
      <Mui.Grid container justify="flex-end" alignItems="flex-end">
        <MuiLab.Pagination
          style={{ marginTop: theme.spacing(2) }}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          page={page + 1}
          count={Math.ceil(rows.length / rowsPerPage)}
          // NOTE: Mui Lab Pagination assumes page starts at 1
          onChange={(event, page) => handleChangePage(event, page - 1)}
          shape="rounded"
        />
      </Mui.Grid>
      )}
   
    </div>
  );
}

export declare namespace MsiGridComponent {
  export interface Props {
    customer: Models.Order.Main[];
    hideHeader?: boolean;
    hideAddress?: boolean;
    hideUserDetails?: boolean;
  }
}

// eslint-disable-next-line no-redeclare
export function MsiGridComponent({
  customer,
  hideAddress,
  hideHeader,
  hideUserDetails,
}: MsiGridComponent.Props) {
  const theme = Mui.useTheme();

  return (
    <>
   <EnhancedTable rows={customer} />
    </>
  );
}
