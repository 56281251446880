/** @format */

export const environment = {
  production: false,
  apiBaseUrlProtocol: process.env.REACT_APP_API_PROTOCOL,
  apiBaseUrlHostname: process.env.REACT_APP_API_HOSTNAME,
  apiBaseUrlVersion: process.env.REACT_APP_API_VERSION,
  KARegisterURL: process.env.REACT_APP_ACCOUNT_REGISTER_URL,
  BaseKARegisterURL: process.env.REACT_APP_ACCOUNT_BASE_KERN_URL,
  BaseSSORegisterURL: process.env.REACT_APP_ACCOUNT_BASE_SSO_URL,
  BaseWEBSHOPURL: process.env.REACT_APP_ACCOUNT_BASE_WEBSHOP_URL,
  KAForgetPasswordURL: process.env.REACT_APP_ACCOUNT_FORGOT_PASSWORD_URL,
  AESDataKey: process.env.REACT_APP_AES_SECERET_KEY,
  apiStatus: {
    Success: 100,
    Error: 200,
  },
};
