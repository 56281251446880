/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcon from "@material-ui/icons";
import * as MuiIcons from "@material-ui/icons";
import LanguageIcon from "@material-ui/icons/Language";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactCookie from "react-cookie";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Layouts from "src/app/layouts";
import * as Configs from "src/configs";
import { Breadcrumb } from "./breadcrumb";
import * as Constants from "src/constants";

const drawerWidth = 140;

const useStyles = Mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 3, 0, 3),
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
  },
  rightShortProfile: {
    display: "flex",
    height: "100%",
  },
  rightShortProfileName: {
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
  },
}));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  children?: string | JSX.Element | JSX.Element[];
}

export const Main = Mobx.observer(function (props: Props) {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const [, setCookie, removeCookie] = ReactCookie.useCookies();
  const userStore = React.useContext(App.Contexts.userStore);
  const cartStore = React.useContext(App.Contexts.cartStore);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = Router.useNavigate();
  const open = Boolean(anchorEl);
  const { t } = ReactI18next.useTranslation();
  const dCustomerStore = React.useContext(App.Contexts.delareCustomerStore);
  const getRole = (roleName: any) => {
    if (roleName === "admin") {
      return "Admin";
    }
    else if (roleName === "superadmin") {
      return "Superadmin";
    } else if (roleName === "customer") {
      return "Customer";
    } else {
      return "Dealer";
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const customerID = dCustomerStore?.delaerCustomer?.map(k=>k.customerID)
  // console.log("customerID",JSON.stringify(customerID))
  const handleCompanyNavigation = (event: React.MouseEvent<HTMLElement>) => {
    const userRole = getRole(userStore.user?.RoleName);
    const ETUID = userStore.user?.ETUID;
    if (userRole === "Admin" || userRole === "Superadmin") {
      navigate("/dealercustomer/manageadmin");
    }
    if (userRole === "Customer") {
      navigate(`/dealercustomer/customerdetail?q=${ETUID}&m=${customerID[0]}`);
    }
    if (userRole === "Dealer") {
      var dealerAddressID = btoa(userStore.user?.AddressNumber != undefined ? userStore.user?.AddressNumber.toString() : "");
      var dealerMasterID = btoa(userStore.user?.CustomerDealerID != undefined ? userStore.user?.CustomerDealerID.toString() : "");

      var Dealer = btoa("Dealer");
      navigate("dealercustomer/adddealeruser?q=" + dealerMasterID + "&m=" + dealerAddressID );
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileClose = () => {
    setProfileOpen(false);
  };
  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  function handleLogout() {
    customerInviteStore.resetInviteSummary();
    localStorage.removeItem("dealerID");
    handleClose();
    userStore.logout({ axios: App.axios, setCookie, removeCookie });
  }

  function handleAccountNavigation() {
    const userRole = getRole(userStore.user?.RoleName);
    const baseURl = `${Constants.environment.BaseKARegisterURL}/Admin/CustomerList/Index`
    // console.log(userRole)
       window.location.href = `${Constants.environment.BaseKARegisterURL}/Admin/CustomerList/Index` ;
    
  }
  const handleProfileNavigation = () =>{
    setProfileOpen(true)
  }
  const version = "1.0.2.0"
  return (
    <div className={classes.root}>
      <Components.LightBox
      open={profileOpen}
      handleClose={handleProfileClose}
      content={<></>}
      title={
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          <ReactI18next.Trans i18nKey="My Profile">
            My Profile
          </ReactI18next.Trans>
        </Mui.Typography>
      }
      actions={
        <>
        
        </>
      }
    />
      <Mui.CssBaseline />
      <Mui.AppBar position="fixed" className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MuiIcons.Menu />
          </Mui.IconButton>

          <Mui.Button
            style={{ marginLeft: theme.spacing(-2), borderRadius: "unset" }}
            component={Router.Link}
            to="/"
          >
            <img
              src={Configs.APP.prefixPublicUrl({
                url: `assets/logo/etl-logo-${theme.palette.type}.svg`,
              })}
              alt="Easy Touch Logo"
              style={{ height: "40px", maxHeight: "40px" }}
            />
            {/* <Components.Logo
              color={theme.palette.getContrastText(theme.palette.primary.main)}
            /> */}
          </Mui.Button>

          {/* <Mui.Typography variant="h6" className={classes.title}>
            Dashboard
          </Mui.Typography> */}

          <Mui.Grid container justify="flex-end" alignItems="center">
            <Mui.Box display="flex" alignItems="center">
              <Mui.Box marginRight={2}>
                <Components.LocaleDropdown
                // color={theme.palette.getContrastText(theme.palette.primary.main)}
                />
              </Mui.Box>
              <Mui.Box
                border={0}
                padding={0}
                margin={0}
                marginRight={0.5}
                marginLeft={0.5}
                width={"1px"}
                height={theme.typography.h4.fontSize}
                style={{ backgroundColor: theme.palette.divider }}
              />
            </Mui.Box>
       
            {/* <Containers.ShowIfAuthorised roles={["customer", "dealer"]}>
              <Mui.Box display="flex" alignItems="center">
                <Mui.IconButton
                  onClick={() => {
                    navigate("/shop/cart");
                  }}
                >
                  <Mui.Box
                 
                  >
                    <Mui.Badge
                      color="secondary"
                      badgeContent={cartStore.cart.size}
                    >
                      <i className="icon-etc-cart" />
                    </Mui.Badge>
                  </Mui.Box>
                </Mui.IconButton>
                <Mui.Box
                  border={0}
                  padding={0}
                  margin={0}
                  marginRight={0.5}
                  marginLeft={0.5}
                  width={"1px"}
                  height={theme.typography.h4.fontSize}
                  style={{ backgroundColor: theme.palette.divider }}
                />
              </Mui.Box>
            </Containers.ShowIfAuthorised> */}
            <Containers.ShowIfAuthorised roles={["customer", "dealer"]}>
              <Mui.Box
                display="flex"
                alignItems="center"
                className={classes.rightShortProfile}
              >
                <Mui.Tooltip title={getRole(userStore.user?.RoleName)}>
                  <Mui.ListItem button onClick={handleCompanyNavigation}>
                    {(getRole(userStore.user?.RoleName) === "Admin" || getRole(userStore.user?.RoleName) === "Superadmin") ? (
                      ""
                    ) : getRole(userStore.user?.RoleName) === "Customer" ? (
                      <MuiIcons.Business
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "grey",
                          marginRight: "4px",
                          padding:"4px"
                          //  margin: "0px 17px 10px 33px",
                        }}
                      />
                    ) : getRole(userStore.user?.RoleName) === "Dealer" ? (
                      <MuiIcons.Business
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "grey",
                          marginRight: "4px",
                          padding:"4px"
                        }}
                      />
                    ) : (
                      ""
                    )}

                    <Mui.Hidden xsDown>
                      <Mui.ListItemText
                        primary={
                          <Mui.Box style={{ textTransform: "capitalize" }}>
                            {userStore.user?.CompanyName !== null
                              ? userStore.user?.CompanyName
                              : userStore.user?.RoleName}
                          </Mui.Box>
                        }
                      />
                    </Mui.Hidden>
                  </Mui.ListItem>
                </Mui.Tooltip>
              </Mui.Box>
            </Containers.ShowIfAuthorised>
            <Mui.Box
              display="flex"
              alignItems="center"
            >
              <Mui.Tooltip
                title={
                  userStore.user?.FirstName + " " + userStore.user?.LastName
                }
              >
                <Mui.ListItem button onClick={handleMenu}>
                  <Mui.ListItemAvatar>
                    <Mui.Avatar
                      alt={
                        userStore.user?.FirstName +
                        " " +
                        userStore.user?.LastName
                      }
                    >
                      <MuiIcons.Person />
                    </Mui.Avatar>
                  </Mui.ListItemAvatar>
                  <Mui.Hidden xsDown>
                    <Mui.ListItemText
                      primary={
                        <Mui.Box style={{ textTransform: "capitalize" }}>
                          {userStore.user?.FirstName +
                            " " +
                            userStore.user?.LastName}
                        </Mui.Box>
                      }
                      secondary={
                        <Mui.Box
                          component="span"
                        >
                          {t(getRole(userStore.user?.RoleName))}
                        </Mui.Box>
                      }
                    />
                  </Mui.Hidden>
                </Mui.ListItem>
              </Mui.Tooltip>
            </Mui.Box>
            <Mui.Menu
              id="menu-appbar"
              keepMounted
              anchorEl={anchorEl}
              anchorReference="anchorPosition"
              anchorPosition={{
                top: anchorEl?.getBoundingClientRect().bottom ?? 0,
                left: anchorEl?.getBoundingClientRect().left ?? 0,
              }}
              open={open}
              onClose={handleClose}
            >
             
              <Mui.MenuItem onClick={handleAccountNavigation}>
                <Mui.ListItemIcon>
                  <Mui.Box fontSize={26} lineHeight={0}>
                   
                    <i className="icon-etc-user-name" />
                  </Mui.Box>
                </Mui.ListItemIcon>
                  View profile
              </Mui.MenuItem>
             
              <Mui.MenuItem>
              <Components.Switches labelName="Email reminder"/>
              <Mui.Box >
                  
                  </Mui.Box>
              </Mui.MenuItem>
              <Mui.MenuItem onClick={handleLogout}>
                <Mui.ListItemIcon>
                  <Mui.Box fontSize={26} lineHeight={0}>
                   
                    <i className="icon-etc-sign-out" />
                  </Mui.Box>
                </Mui.ListItemIcon>
                <ReactI18next.Trans i18nKey="Sign out">
                  Sign out
                </ReactI18next.Trans>
              </Mui.MenuItem>
            </Mui.Menu>
            <Mui.Hidden smDown>
              <Mui.Button
                style={{ marginLeft: theme.spacing(1),width:"120px",height:"" }}
                component={Router.Link}
                to="/"
              >
                {theme.palette.type === "light" ? (
                  <img
                    src={Configs.APP.prefixPublicUrl({
                      url: "images/ETLogo.svg",
                    })}
                    alt="Kern Logo"
                  />
                ) : (
                  <img
                    src={Configs.APP.prefixPublicUrl({
                      url: "images/ETLogo.svg",
                    })}
                    alt="Kern Logo"
                  />
                )}
              </Mui.Button>
            </Mui.Hidden>
          </Mui.Grid>
        </Mui.Toolbar>
      </Mui.AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Mui.Hidden smUp implementation="css">
          <Mui.Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Layouts.DrawerAppbarCombo.Drawer />
          </Mui.Drawer>
        </Mui.Hidden>
        <Mui.Hidden xsDown implementation="css">
          <Mui.Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Layouts.DrawerAppbarCombo.Drawer />
          </Mui.Drawer>
        </Mui.Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div
          style={{
            height: `calc(100% - ${72}px)`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Mui.Box marginTop={-1.5}>
              <Breadcrumb />
            </Mui.Box>
            <Router.Outlet />
            <div className={classes.toolbar} />
          </div>
          {window.location.pathname === "/shop" && (
            <>
              <div className={classes.toolbar} />
              <div className={classes.toolbar} />
            </>
          )}
          <Mui.Box component="footer" marginBottom = {-0.5}>
            <Mui.Grid container justify="space-between" alignItems="flex-end">
              <Mui.Box minWidth="fit-content">
                <Mui.Typography component = "span">
                  { new Date().getFullYear() } © KERN&nbsp;&nbsp;|&nbsp;&nbsp;
                </Mui.Typography>
                <Mui.Tooltip 
                title={
                  <ReactI18next.Trans i18nKey="To have an optimal user experience make sure you are using the latest version of the modern browsers listed in the following link">
                    To have an optimal user experience make sure you are using the latest version of the modern browsers listed in the following link.
                  </ReactI18next.Trans>}>
                  <Mui.Typography
                    component={Mui.Link}
                    rel="noopener"
                    target="__blank"
                    href="https://browsehappy.com/"
                  >
                    <ReactI18next.Trans i18nKey="Browser Support">
                    Browser Support
                    </ReactI18next.Trans>
                    
                  </Mui.Typography>
                </Mui.Tooltip>
                <Mui.Typography component="span">
                 &nbsp;&nbsp;|&nbsp;<ReactI18next.Trans i18nKey="Version">
                 Version
                </ReactI18next.Trans> {version}
                </Mui.Typography>
              </Mui.Box>
              <Mui.Typography>
                <ReactI18next.Trans i18nKey="Designed & Developed by Marlensoft">
                  Designed & Developed by Marlensoft
                </ReactI18next.Trans>
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Box>
        </div>
      </main>
    </div>
  );
});
