import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactCookie from "react-cookie";
// NOTE: For translations
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
// import * as ReactUndraw from "iblis-react-undraw";
import * as Configs from "src/configs";
import * as Constants from "src/constants";

const useStyles = Mui.makeStyles((theme) => ({
  loginCardContainer: {
    paddingTop: theme.spacing(6),
  },
  easyTouchIllustration: {
    width: "inherit",
    paddingTop: theme.spacing(3),
  },
  media: {
    height: 240,
  },
}));

export declare namespace Login {
  export namespace Form {
    export interface Values {
      email: string;
      password: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export function Login() {
  // NOTE: For translations
  const { t } = ReactI18next.useTranslation();

  const [searchParams] = Router.useSearchParams();
  const [, setCookie] = ReactCookie.useCookies();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const userStore = React.useContext(App.Contexts.userStore);

  const id = searchParams.get("id");
  const sr = searchParams.get("sr");
  const did = searchParams.get("did");
 // const token = searchParams.get("token");
// const returnUrl = searchParams.get("returnUrl") || "/";
 
  function getCommonCookieOptions() {
    const futureTime = new Date();
    futureTime.setHours(futureTime.getHours() + 7);
    return {
      expires: futureTime,
      path: "/",
    };
  }

  if (id) {
    setCookie("macid", id, getCommonCookieOptions());
    setCookie("needact", "1", getCommonCookieOptions());

    if (sr) {
      setCookie("needact", "0", getCommonCookieOptions());
    }
  }

  if (did) {
    setCookie("dmacid", did, getCommonCookieOptions());
  }

  const classes = useStyles();
  const theme = Mui.useTheme();


  function validateEmail(value: any) {
    let error;
    if (!value) {
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(value)) {
      error = t("Email is invalid");
    }
    return error;
  }

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  function handleClickShowPassword() {
    return function () {
      setShowPassword(!showPassword);
    };
  }

  function handleMouseDownPassword() {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
    };
  }

  async function handleLogin(
    values: Login.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Login.Form.Values>
  ) {
    try {
      await userStore.login({
        axios: App.axios,
        credential: {
          username: values.email,
          password: values.password,
          sysdate: new Date(),
        },
        getCommonCookieOptions,
        setCookie,
        setSubmitting,
      });
    } catch (error) {
      console.error(error);
      if (
        error?.name === "INVALID_CREDENTIAL" &&
        (values.email === "" || values.email === null)
      ) {
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Email ID is required!">
            Email ID is required!
          </ReactI18next.Trans>,
          {
            variant: "error",
          }
        );
      } else if (
        error?.name === "INVALID_CREDENTIAL" &&
        (values.password === "" || values.password === null)
      ) {
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Password is required!">
            Password is required!
          </ReactI18next.Trans>,
          {
            variant: "error",
          }
        );
      } else if (error?.name === "INVALID_CREDENTIAL") {
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Invalid email ID or password!">
            Invalid email ID or password!
          </ReactI18next.Trans>,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(
          <ReactI18next.Trans i18nKey="Something went wrong! Please try again later.">
            Something went wrong! Please try again later.
          </ReactI18next.Trans>,
          {
            variant: "error",
          }
        );
      }
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Mui.Box className={classes.loginCardContainer}>
      <Mui.Typography
        component="div"
        align="center"
        style={{
          fontSize: theme.typography.h5.fontSize,
          fontWeight: 400,
          textTransform: "uppercase",
        }}
        gutterBottom
      >
        <Mui.Box>
          <Mui.Typography
            component="span"
            style={{ fontSize: "inherit", fontWeight: 200 }}
          >
            <ReactI18next.Trans i18nKey="Welcome to">
              Welcome to
            </ReactI18next.Trans>{" "}
            &nbsp;
          </Mui.Typography>
            KERN License Management
        </Mui.Box>
      </Mui.Typography>
      <Mui.Typography color="textSecondary" align="center" gutterBottom>
        <ReactI18next.Trans i18nKey="Login to get started">
          Login to get started
        </ReactI18next.Trans>
      </Mui.Typography>

      <Mui.Container style={{ padding: 0 }} maxWidth="sm">
        <Mui.Paper style={{ margin: theme.spacing(2.5, 0) }}>
        
          <Mui.Box padding={3}>
            <Mui.Grid container justify="space-between" alignItems="center">
              {theme.palette.type === "light" ? (
                <img
                width="118px"                  src={Configs.APP.prefixPublicUrl({
                    url: "images/ETLogo.svg",
                  })}
                  alt="Kern Logo"
                />
              ) : (
                <img
                  src={Configs.APP.prefixPublicUrl({
                    url: "images/kern-logo-white.png",
                  })}
                  alt="Kern Logo"
                />
              )}
              <img
                src={Configs.APP.prefixPublicUrl({
                  url: `assets/logo/etl-logo-${theme.palette.type}.svg`,
                })}
                style={{ height: "40px", maxHeight: "40px" }}
                alt="Easy Touch Logo"
              />
            </Mui.Grid>
            <Mui.Divider style={{ margin: theme.spacing(3.5, 0, 2, 0) }} />
         
            <Formik.Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={handleLogin}
            >
              {({
                isSubmitting,
                submitForm,
              }: Formik.FormikProps<Login.Form.Values>) => (
                <Formik.Form>
                  <Mui.FormControl fullWidth margin="normal">
                    <Formik.Field
                      autoFocus
                      component={FormikMui.TextField}
                      validate={validateEmail}
                      name="email"
                      type="email"
                      label={
                        <ReactI18next.Trans i18nKey={"Email ID"}>
                          Email ID
                        </ReactI18next.Trans>
                      }
                      placeholder={t("Please enter Email ID")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <Mui.InputAdornment position="end">
                            <Mui.IconButton disableRipple tabIndex={-1}>
                              {/* <MuiIcons.Person /> */}
                              <i className="icon-etc-user-name"></i>
                            </Mui.IconButton>
                          </Mui.InputAdornment>
                        ),
                      }}
                    />
                  </Mui.FormControl>

                  <Mui.FormControl fullWidth margin="normal">
                    <Formik.Field
                      component={FormikMui.TextField}
                      name="password"
                      label={
                        <ReactI18next.Trans i18nKey={"Password"}>
                          Password
                        </ReactI18next.Trans>
                      }
                      placeholder={t("Please enter password")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <Mui.InputAdornment position="end">
                            <Mui.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword()}
                              onMouseDown={handleMouseDownPassword()}
                              style={{
                                padding: theme.spacing(1.125),
                              }}
                            >
                              <Mui.Box lineHeight={0} fontSize={30}>
                                {showPassword ? (
                                  // <MuiIcons.Visibility />
                                  <i className="icon-etc-password"></i>
                                ) : (
                                  // <MuiIcons.VisibilityOff />
                                  <i className="icon-etc-hide-password"></i>
                                )}
                              </Mui.Box>
                            </Mui.IconButton>
                          </Mui.InputAdornment>
                        ),
                      }}
                    />
                  </Mui.FormControl>

                  <Components.LocaleDropdown
                    showLabel
                    margin="normal"
                    fullWidth
                  />

                  {isSubmitting && <Mui.LinearProgress />}

                  <Mui.Grid
                    style={{ marginTop: theme.spacing(1) }}
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <Mui.FormControl>
                      <Mui.Typography>
                      
                        <Mui.Typography component="span">
                          <Mui.Link
                            href={Constants.environment.KAForgetPasswordURL}
                            color="primary"
                          >
                            <ReactI18next.Trans i18nKey="Forgot password?">
                              Forgot password?
                            </ReactI18next.Trans>
                          </Mui.Link>
                        </Mui.Typography>
                      </Mui.Typography>
                    </Mui.FormControl>
                    <Mui.FormControl margin="normal">
                      <Mui.Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <ReactI18next.Trans i18nKey={"Login"}>
                          Login
                        </ReactI18next.Trans>
                      </Mui.Button>
                    </Mui.FormControl>
                  </Mui.Grid>
                </Formik.Form>
              )}
            </Formik.Formik>
          </Mui.Box>
        </Mui.Paper>
      </Mui.Container>
      <Mui.Typography color="textSecondary" align="center">
        <ReactI18next.Trans i18nKey="If you are new or don't have a KERN account, please">
          If you are new or don't have a KERN account, please
        </ReactI18next.Trans>
        &nbsp;
        <Mui.Typography component="span">
          <Mui.Link
            href={Constants.environment.KARegisterURL}
            color="primary"
          >
            <ReactI18next.Trans i18nKey="register">register</ReactI18next.Trans>
          </Mui.Link>
        </Mui.Typography>
      </Mui.Typography>
    </Mui.Box>
  );
}
