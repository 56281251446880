/** @format */
import * as Mui from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as MuiIcons from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import LanguageIcon from "@material-ui/icons/Language";
import * as ReactUndraw from "iblis-react-undraw";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";

import { useLocation } from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import { CustomerInviteSummary } from "src/app/routes/dealer";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import * as Services from "src/services";
import * as Constants from "src/constants";
export interface FormProps {
  emailID: string;
  firstname?: any;
  companyName?: any;
  lastname?: any;
}

export const Main = Mobx.observer(function () {
  const [updateDialogOpen, setUpadateRoleDialogOpen] = React.useState<
    | {
        roles: number;
      }
    | undefined
  >();
  const [failureDialogOpen, setfailureRoleDialogOpen] = React.useState<
    | {
        roles: number;
      }
    | undefined
  >();

  const [purchaseDialogOpen, setfPurchaseDialogOpen] = React.useState<
    | {
        roles: number;
      }
    | undefined
  >();
  //const [modalFlag, setModalFlag] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const homeStore = React.useContext(App.Contexts.homeStore);
  const [loader, setLoader] = React.useState<boolean>(false);
  const navigate = Router.useNavigate();
  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  // const cartStore = React.useContext(App.Contexts.cartStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const validationSchema = Yup.object().shape<FormProps>({
    emailID: Yup.string().required("Email ID is required"),
  });
  const [searchParams] = Router.useSearchParams();

  const [isFromManageOrder, setIsFromManageOrder] = React.useState<boolean>(false);

  const orderStore = React.useContext(App.Contexts.orderStore);

  const customerInviteStore = React.useContext(
    App.Contexts.customerInviteStore
  );
  const importDealerStore = React.useContext(App.Contexts.importDealerStore);

  const addCustomerStore = React.useContext(App.Contexts.addCustomerStore);
  const { enqueueSnackbar } = Notistack.useSnackbar();
  var orderIdFromPurchaseRedirection = searchParams.get("isP");
  var navigationFromManageCustomer = searchParams.get("q");
  var navigationFromManageOrder = searchParams.get("m");
  //console.log("navigationFromManageCustomer", navigationFromManageCustomer);
  React.useEffect(() => {
    const result = customerInviteStore.setArticleSelected;
    const dealerUser = Array.from(
      customerInviteStore.selectedArticle.values()
    ).map(
      (item) =>
        ({
          setDescriptions: item.setDescriptions,
          setName: item.setName,
          setID: item.setID,
        } as any)
    );

    //alert(JSON.stringify(dealerUser));

    // const ETUID = userStore.user?.ETUID.toString();

    //**********Getting the OrderId from query string******

    //alert(orderIdFromPurchaseRedirection);
    if (orderIdFromPurchaseRedirection) {
      setOpen(true);
    }

    importDealerStore.readUsers({ searchtext:userStore.user?.emailID ?? "All",  });

    //
    //alert(purchaseNavigation);

    //   customerInviteStore.getPurchangeArticleDetails({
    //     orderID: OrderID,
    //   });
  }, [customerInviteStore.setArticleSelected, customerInviteStore.selectedArticle, orderIdFromPurchaseRedirection, importDealerStore, userStore.user]);
 
  const Email = localStorage.getItem("customermailID")
  const cid = localStorage.getItem("customerID")
  React.useEffect(()=>{
    const userRole = "customer"
    const EmailId = Email? Email : undefined;
    const uid = cid ? cid : "0";
    if ((uid !== undefined && uid !== null) && Email) {
      homeStore.read({
        uid,
        userRole,
        EmailId,
      });
    }
  },[Email, cid, homeStore])
  //BindComapnay(OrderID);

  function ArticleUpdateInCart(article: Models.Set.Article) {
    const cartStore = React.useContext(App.Contexts.cartStore);
    article.olPrice.singleNetPrice = article.setCost;
    article.olPrice.grossPositionSinglePriceInclVAT = article.setCost;
    article.olPrice.grossPositionTotalPriceInclVAT = article.setCost;
    article.olPrice.totalNetPrice = article.setCost;

    if (article.quantity > article.availableQuantity) {
      // article.quantity = article.availableQuantity - article.quantity;
      cartStore.vatTaxPercent = article.vatPercentage;
      cartStore.addProduct({ product: article });
      enqueueSnackbar(t("Added to cart!"), {
        variant: "success",
        preventDuplicate: false,
      });
    }
  }
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("isP");
  function ArticleQuantityUpdateAfterPurchase(article: Models.Set.Article) {
    // const cartStore = React.useContext(App.Contexts.cartStore);
    // article.setCost = 300;
    // if (article.quantity > article.availableQuantity) {
    //   // article.quantity = article.availableQuantity - article.quantity;
    //   cartStore.addProduct({ product: article });
    // }
    // const OrderID = "196";
    purchaseUpdate(id);
    async function purchaseUpdate(orderID: any) {
      //alert(DealerMasterID);
      //alert(DealerProfileID);
      // alert('BindComapnay method');
      const company = await addCustomerStore.getPurchangeArticleDetails({
        orderID: orderID,
      });

      //alert(JSON.stringify(company))
      const purchaseAvailableQuantity: any = company?.map((k) => ({
        setID: k.setID,
        availableQuantity: k.availableQuantity,
        quantity:
          k.setID == article.setID
            ? (article.availableQuantity =
                +article.availableQuantity + +k.availableQuantity)
            : article.availableQuantity,
      }));

      //alert(JSON.stringify(purchaseAvailableQuantity));
    }
  }

  function ArticleIncreaseUpdate(article: Models.Set.Article): void {


    let validationData = [];
    let dashboardReplica:any = [];
    dashboardReplica = homeStore.dashboard?.licenseSummary
      for (var i = 0; i < dashboardReplica.length; i++) {
      dashboardReplica[i].totalQuantity = +dashboardReplica[i].totalQuantity;
    }
    const dash = dashboardReplica.map((k:any)=>{
      return {
        hundCode: k.hundCode,
        totalQuantity: k.totalQuantity / 4,
        setName: k.setName
      }
    })
    
     let  customerInviteStores:any = Array.from(customerInviteStore.article.values())
    //  const replacing = cartStores.map(({ quantity }:any) => ({ totalQuantity: quantity}));
    //  
      
     for (var j = 0; j < customerInviteStores.length; j++) {
      customerInviteStores[j].quantity = +customerInviteStores[j].quantity;
      customerInviteStores[j].availableQuantity = +customerInviteStores[j].availableQuantity;
  }
  const customerInviteStoress = customerInviteStores.map((k:any)=>{
    return {
      hundCode: k.hundCode,
      totalQuantity: k.quantity,
      setName: k.setName,
      availableQuantity: k.availableQuantity
    }
  })
  
     if(dashboardReplica.length > 0 && customerInviteStoress.length > 0){
      const result = Object.values([...customerInviteStoress, ...dash].reduce((acc, { hundCode, totalQuantity,setName }) => {
        acc[hundCode] = { hundCode, totalQuantity: (acc[hundCode] ? acc[hundCode].totalQuantity : 0) + totalQuantity,setName  };
        return acc;
      }, {}));
      validationData = result;
      
       
     }
     else if(dashboardReplica.length > 0 && (customerInviteStoress.length === 0 || customerInviteStoress === undefined )){
      const dash = dashboardReplica.map((k:any)=>{
        return {
          hundCode: k.hundCode,
          totalQuantity: k.totalQuantity / 4,
          setName: k.setName
        }
      })
      validationData = dash
    }
    else if((dashboardReplica.length === 0 || dashboardReplica === undefined ) && customerInviteStoress.length > 0){
      const inviteStore = customerInviteStoress.map((k:any)=>{
        return {
          hundCode: k.hundCode,
          totalQuantity: k.totalQuantity,
          setName: k.setName,
          availableQuantity: k.availableQuantity
        }
      })
      validationData = inviteStore;

    }
    else
    {
      validationData = [];
    }
    
    
    // const validat = validationData.filter((k:any)=>k.hundCode === article.hundCode
      
    // ).map((k:any)=>k.totalQuantity + 1)
    for(var m = 0; m < validationData.length; m++){
      if(validationData[m].hundCode === article.hundCode){
        validationData[m].totalQuantity = validationData[m].totalQuantity + 1;
      }
      
    }
    
    const validationCheckfilter = validationData.filter((k:any)=>k.hundCode === article.hundCode)
    if(validationCheckfilter.length > 0){
      
      
    }else{
      
      
      validationData.push({
        hundCode: article.hundCode,
        totalQuantity: 1,
        setName: article.setName
      })
    }

    
    const filteringSelectedArticleQuantity = validationData.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)
    const filteringBasicSetQuantity= validationData.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
   
    if(article.hundCode === "SET-01"){
      customerInviteStore.addProduct({ article: article });
      
        // const InviteStore = customerInviteStoress.map((k:any)=>{
        //   return {
        //     hundCode: k.hundCode,
        //     totalQuantity: k.quantity,
        //     setName: k.setName,
        //     availableQuantity: k.availableQuantity
        //   }
        // })
        const quantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)[0]
        const availableQuantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.availableQuantity)[0]
        customerInviteStore.increaseProductQuantity({
          article,
         });
         customerInviteStore.addProduct({ article: article })
         if((quantityOfInvite + +1) - availableQuantityOfInvite >  0){
          cartStore.addProduct({ product: article })
          enqueueSnackbar(t("Added to cart!"), {
            variant: "success",
            preventDuplicate: false,
          });
         }
    }
    else if(article.hundCode !== "SET-01"){

      const FilteringSelectedArticleQuantityisZeroChecks = filteringSelectedArticleQuantity.length === 0 || filteringSelectedArticleQuantity.length === undefined || filteringSelectedArticleQuantity.length === null ? 0 : filteringSelectedArticleQuantity[0]
      const FilteringBasicSetQuantityisZeroChecks = filteringBasicSetQuantity.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
      
      const checkForAddToCart = FilteringSelectedArticleQuantityisZeroChecks <= FilteringBasicSetQuantityisZeroChecks 
      
      const inviteStore = customerInviteStoress.map((k:any)=>{
        return {
          hundCode: k.hundCode,
          totalQuantity: k.totalQuantity,
          setName: k.setName,
          availableQuantity: k.availableQuantity
        }
      })
      const filteringBasicSetQuantityAutomateBasicAdd= inviteStore.filter((k:any)=>k.hundCode === "SET-01").map((k:any)=>k.totalQuantity)
      const FilteringBasicSetQuantityAutomateBasicAddInvite = filteringBasicSetQuantityAutomateBasicAdd.length === 0 || filteringBasicSetQuantity.length === undefined || filteringBasicSetQuantity.length === null ? 0 : filteringBasicSetQuantity[0]
      let isEnableBasicset = false;
      if((FilteringBasicSetQuantityisZeroChecks === 0 )&& (FilteringBasicSetQuantityAutomateBasicAddInvite === 0)){
        isEnableBasicset = true
        const articled:any = customerInviteStore.selectedArticle.filter((k:any) => k.hundCode === "SET-01")
        
            const articles = Object.assign({}, ...articled);
            customerInviteStore.increaseProductQuantity({
              article,
             });
             customerInviteStore.addProduct({ article: article });
             
               // const InviteStore = customerInviteStoress.map((k:any)=>{
               //   return {
               //     hundCode: k.hundCode,
               //     totalQuantity: k.quantity,
               //     setName: k.setName,
               //     availableQuantity: k.availableQuantity
               //   }
               // })
               const quantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)[0]
               const availableQuantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.availableQuantity)[0]
               customerInviteStore.increaseProductQuantity({
                 article,
                });
                customerInviteStore.addProduct({ article: article })
                if((quantityOfInvite + +1) - availableQuantityOfInvite >  0){
                 cartStore.addProduct({ product: article })
                 enqueueSnackbar(t("Added to cart!"), {
                   variant: "success",
                   preventDuplicate: false,
                 });
                
         }
            }else{
              isEnableBasicset = false
            }
      
      if((checkForAddToCart === true)|| (isEnableBasicset === true)){
        customerInviteStore.increaseProductQuantity({
              article,
             });
             customerInviteStore.addProduct({ article: article });
             
               // const InviteStore = customerInviteStoress.map((k:any)=>{
               //   return {
               //     hundCode: k.hundCode,
               //     totalQuantity: k.quantity,
               //     setName: k.setName,
               //     availableQuantity: k.availableQuantity
               //   }
               // })
               const quantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.totalQuantity)[0]
               const availableQuantityOfInvite = customerInviteStoress.filter((k:any)=>k.hundCode === article.hundCode).map((k:any)=>k.availableQuantity)[0]
              //  customerInviteStore.increaseProductQuantity({
              //    article,
              //   });
              //   customerInviteStore.addProduct({ article: article })
                if((quantityOfInvite + +1) - availableQuantityOfInvite >  0){
                 cartStore.addProduct({ product: article })
                 enqueueSnackbar(t("Added to cart!"), {
                   variant: "success",
                   preventDuplicate: false,
                 });
                
         }else{
           
         }
        
      }
      else
      {
        enqueueSnackbar(
                  t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                  {
                    variant: "warning",
                    preventDuplicate: false,
                  }
                );
      }
   
  }




   
  }
  function ArticleDecreaseUpdate(article: Models.Set.Article): void {

                    let product:any = []
                    product = article
                    let validationData = [];
                    let dashboardReplica:any = [];
                    dashboardReplica = homeStore.dashboard?.licenseSummary
                      for (var i = 0; i < dashboardReplica.length; i++) {
                      dashboardReplica[i].totalQuantity = +dashboardReplica[i].totalQuantity;
                    }
                    const dash = dashboardReplica.map((k:any)=>{
                      return {
                        hundCode: k.hundCode,
                        totalQuantity: k.totalQuantity / 4,
                        setName: k.setName
                      }
                    })
                    
                     let  customerInviteStores:any = Array.from(customerInviteStore.article.values())
                    //  const replacing = cartStores.map(({ quantity }:any) => ({ totalQuantity: quantity}));
                    //  
                      
                     for (var j = 0; j < customerInviteStores.length; j++) {
                      customerInviteStores[j].quantity = +customerInviteStores[j].quantity;
                  }
                  const invite = customerInviteStores.map((k:any)=>{
                    return {
                      hundCode: k.hundCode,
                      totalQuantity: k.quantity,
                      setName: k.setName,
                      availableQuantity: k.availableQuantity
                    }
                  })
                  
                     if(dashboardReplica.length > 0 && customerInviteStores.length > 0){
                      const result = Object.values([...invite, ...dash].reduce((acc, { hundCode, totalQuantity,setName,availableQuantity }) => {
                        acc[hundCode] = { hundCode, totalQuantity: (acc[hundCode] ? acc[hundCode].totalQuantity : 0) + totalQuantity,setName,availableQuantity };
                        return acc;
                      }, {}));
                      validationData = result;
                      
                       
                     }
                     else if(dashboardReplica.length > 0 && (customerInviteStores.length === 0 || customerInviteStores === undefined )){
                      const dash = dashboardReplica.map((k:any)=>{
                        return {
                          hundCode: k.hundCode,
                          totalQuantity: k.totalQuantity / 4,
                          setName: k.setName
                        }
                      })
                      validationData = dash
                    }
                    else if((dashboardReplica.length === 0 || dashboardReplica === undefined ) && customerInviteStores.length > 0){
                      const customerInvite = customerInviteStores.map((k:any)=>{
                        return {
                          hundCode: k.hundCode,
                          totalQuantity: k.quantity,
                          setName: k.setName,
                          availableQuantity: k.availableQuantity
                        }
                      })
                      validationData = customerInvite;
  
                    }
                    else
                    {
                      validationData = [];
                    }
                    
                    
                    if(article.hundCode === "SET-01"){
                      const basicSetQuantity = validationData.filter((k:any)=>k.hundCode === "SET-01").map((s:any)=>s.totalQuantity)
                      
                      const OtherSetQuantityExceptBasic = validationData.filter((k:any)=>k.hundCode !== "SET-01").map((s:any)=>s.totalQuantity)
                      const maximumQuantityFromOtherSets:any = Math.max(...OtherSetQuantityExceptBasic)
                      const MaxValue = maximumQuantityFromOtherSets
  
                      const subFunction = (a:number,b:number)=>{
                           return a - b
                      }
                      const oneDecrement = 1
                      const twoDecrement = parseInt(basicSetQuantity[0])
                      const subtraction = subFunction(twoDecrement,oneDecrement)
                      
                      var comaprator ;
                        if(subtraction > MaxValue || subtraction === MaxValue){
                          comaprator = true
                        }
                        else {
                          comaprator = false
                          
                        }
                                         
  
                      if(comaprator){
                        customerInviteStore.decreaseProductQuantity({article});
                        cartStore.decreaseProductQuantity({product});
                      
                  
                    }
                    else{
                      enqueueSnackbar(
                        t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                      {
                        variant: "warning",
                        preventDuplicate: false,
                      }
                      );
                    }
                  }
                  else if(article.hundCode !== "SET-01"){
                    const basicSetQuantity = validationData.filter((k:any)=>k.hundCode === "SET-01").map((s:any)=>s.totalQuantity)
                    
                    const selectedQuantity = validationData.filter((k:any)=>k.hundCode === article.hundCode).map((s:any)=>s.totalQuantity)
                    const oneDecrement = 1
                    if((selectedQuantity[0] - oneDecrement) <= basicSetQuantity[0]){
                      customerInviteStore.decreaseProductQuantity({article});
                      cartStore.decreaseProductQuantity({product});
                    
  
                  }
                  else{
                    enqueueSnackbar(
                      t(`Basic Set cannot be lesser than the other article, please add Basic Set`),
                    {
                      variant: "warning",
                      preventDuplicate: false,
                    }
                    );
                  }
                  
                  }
  
    }

  const handleRemoveSelect = ({ article }: { article: any }) => {
    // alert("Hi");
    //alert(JSON.stringify(article));

    const selectedIndex = customerInviteStore.selected.indexOf(article.setID);
    //alert(JSON.stringify(selectedIndex));
    let newSelected: number[] = [];
    let newRowSelected: Models.Set.Article[] = [];
    //alert('handleSelect');
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected,
        article.setID
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle,
        article
      );
    } else if (selectedIndex === 0) {
    } else if (selectedIndex === customerInviteStore.selected.length - 1) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, -1)
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        customerInviteStore.selected.slice(0, selectedIndex),
        customerInviteStore.selected.slice(selectedIndex + 1)
      );
      newRowSelected = newRowSelected.concat(
        customerInviteStore.selectedArticle.slice(0, selectedIndex),
        customerInviteStore.selectedArticle.slice(selectedIndex + 1)
      );
      // setDisable(false);
    }

    customerInviteStore.setArticleSelected({ selectedArticle: newRowSelected });
    customerInviteStore.setSelected({ selected: newSelected });
  };
  //const [isPurchase, setIsPurchase] = React.useState<boolean>(true);
  const purchaseEnableFromInviteCustomer = Array.from(
    customerInviteStore.article.values()
  )
    .map((k) => k)
    .some((k) => (k.quantity > k.availableQuantity ? true : false));
  //alert(purchaseEnableFromInviteCustomer);
  localStorage.setItem(
    "isCustomerInvitePurchase",
    JSON.stringify(purchaseEnableFromInviteCustomer)
  );

  const fName = localStorage.getItem("cfname");
  const lName = localStorage.getItem("clname");
  const cName = localStorage.getItem("ccname");
  const mailI = localStorage.getItem("mailId");

  localStorage.setItem("nfname", JSON.stringify(fName));
  localStorage.setItem("nlname", JSON.stringify(lName));
  localStorage.setItem("ncname", JSON.stringify(cName));
  localStorage.setItem("nmailId", JSON.stringify(mailI));
  const DarkerDisabledTextField = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "black", // (default alpha is 0.38)
      },
    },
  })(Mui.TextField);

  var quantityUpdate = Array.from(
    customerInviteStore.article.values()
  ).map((k) =>
    k.quantity > k.availableQuantity
      ? { setID: k.setID, quantity: k.quantity }
      : ""
  );

  const cartStore = React.useContext(App.Contexts.cartStore);

  const handleClose = () => {
    setOpen(false);
  };
  
  const isPurchase = localStorage.getItem("isCustomerInvitePurchase");
  const purchaseCheck = isPurchase == "true" ? false : true;
  const sendInviteCheck = isPurchase == "true" ? true : false;
  const { i18n } = ReactI18next.useTranslation(); 
  const localdisplayCustomerDetailCard = localStorage.getItem(
    "displayCustomerDetailCard"
  );
  var checkDisplay = localdisplayCustomerDetailCard === "true";
  const licenseList = orderStore.getAssignLicense();

  React.useEffect(() => {
    const custID = localStorage.getItem("customerID");

   
  });
  
 const handleSendInvite =  async () => {
  try {
    
    let newSelected: number[] = [];
    customerInviteStore.setSelected({
      selected: newSelected,
    });
    const articleIds = Array.from(
      customerInviteStore.article.values()
    ).map(
      (item) =>
        ({
          setId: item.setID,
          setName: item.setName,
          hundCode: item.hundCode,
          assignedQuantity: item.quantity,
        } as Models.InviteCustomer.InviteArticle)
    );
    const dealerEmail = userStore.user?.UserEmail;
    const fName = localStorage.getItem("cfname");
    const lName = localStorage.getItem("clname");
    const cName = localStorage.getItem("ccname");
    const mailI = localStorage.getItem("mailId");
    const custID = localStorage.getItem("customerID");
    const dealerFirstName = userStore.user?.FirstName;
    const dealerLastName = userStore.user?.LastName;
    const dealerName = dealerFirstName+" "+dealerLastName;
    var dealerCompany = importDealerStore.importDealer.map((k) => k.companyName)[0];
    if(navigationFromManageOrder !== "ManageOrder"){
    const inviteCustomer: Models.InviteCustomer.Main = {
      EmailID: mailI ? mailI : "",
      CompanyName: cName ? cName : "",
      FirstName: fName ? fName : "",
      LastName: lName ? lName : "",
      ETUID: userStore.user?.RoleName === "dealer"? userStore.user?.ETUID:  localStorage.getItem("etID") ?? "",
      DealerEmail: dealerEmail ? dealerEmail : "",
      DealerName: dealerFirstName ? dealerName : "",
      langCode: i18n.language, 
      DealerCompanyName: dealerCompany,
      IsExsitingCustomer: navigationFromManageCustomer
        ? custID
        : "0",
      SerialNumber: articleIds,
    };
    var result = await addCustomerStore.inviteCustomer({
      body: inviteCustomer,
    });
  }else{
    var licenses
    if(licenseList !== undefined ){
      licenses = licenseList.map((k:Models.License)=>{
       return{
         setID : k.setID,
         setName : k.setName,
         hundCode : k.setCode,
         assignedQuantity : 1,
         licenseCode: k.licenseCode,
         OrderDetailID: k.orderDetailID,
       }
     })}
    const inviteCustomer: Models.InviteFromManageOrder.Main = {
      EmailID: mailI ? mailI : "",
      CompanyName: cName ? cName : "",
      FirstName: fName ? fName : "",
      LastName: lName ? lName : "",
      ETUID: userStore.user?.ETUID,
      DealerEmail: userStore.user?.UserEmail ?? "",
      DealerName: userStore.user?.FirstName + " "+userStore.user?.LastName,
      langCode: i18n.language, 
      DealerCompanyName: userStore.user?.CompanyName,
      IsExsitingCustomer:  "0",
      AssignAll:  localStorage.getItem("isAssignAl") === "0" ? 0 : 1,
      OrderCode:localStorage.getItem("orderCode") ?? "",
      SerialNumber: licenses,
    };
  
    var result =   await addCustomerStore.inviteFromManageOrder({
      body: inviteCustomer,
    });
  }
  if (result != "") {
    setUpadateRoleDialogOpen({ roles: 1 });
  } else {
    setfailureRoleDialogOpen({ roles: 1 });
  }
    }
   catch (error) {
    throw error;
  }
}
  const handlePurchase = async () => {
    window.location.href =  'https://www.kern-sohn.com/shop/en/products/software/easytouch'
    // Array.from(customerInviteStore.selectedArticle).map(
    //   (item) =>
    //     (({
    //       setID: item.setID,
    //       setName: item.setName,
    //       setDescriptions: item.setDescriptions,
    //       createdBy: item.createdBy,
    //       createdOn: item.createdOn,
    //       functions: item.functions,
    //       hundCode: item.hundCode,
    //       iconClass: item.iconClass,
    //       industrySegment: item.industrySegment,
    //       industrySegmentsId: item.industrySegmentsId,
    //       isItFree: item.isItFree, 
    //       langId: item.langId,
    //       licensePerSet: item.licensePerSet,
    //       modifiedBy: item.modifiedBy,
    //       modifiedOn: item.modifiedOn,
    //       moreInfo: item.setCode,
    //       setCode: item.setID,
    //       setCost: item.setCost,
    //       setsLang: item.setsLang,
    //       status: item.status,
    //       validityPeriod: item.validityPeriod,
    //       vatPercentage: item.vatPercentage,
    //       quantity: item.quantity,
    //       availableQuantity: item.availableQuantity,
    //       olPrice: item.olPrice,
    //       dependentSets: item.dependentSets,
    //     } as unknown) as Models.Set.Article)
    // );
  
    // navigate("/shop/cart");
  }

  return (
    <>
      <Mui.Paper
        square
        style={{
          marginBottom: "12px",
          borderLeft: "4px solid grey",
          borderRadius: "4px",
        }}
      >
        <Mui.Grid container xs={12}>
          <Mui.Grid item>
            <Mui.Typography
              style={{
                height: "23px",
                fontWeight: "bold",
                fontSize: "20px",
                margin: "0px 0 10px 12px",
                borderRadius: "none",
                opacity: "0.8",
              }}
            >
              <ReactI18next.Trans i18nKey="Customer company">
              Customer company selected for customer invite
            </ReactI18next.Trans>
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Paper>

      <Mui.Grid
        container
        style={{
          padding: "15px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
          borderRadius: "8px",
        }}
      >
        {checkDisplay == true ? (
          <>
            <Mui.Grid xs={12} style={{ marginTop: "2px" }}>
              <Mui.Grid container>
                <Mui.Grid item>
                  <MuiIcons.Business
                    style={{
                    
                      width: "53px",
                      height: "53px",
                      color: "grey",
                      margin: "0px 17px 22px 33px",
                    }}
                  />
                </Mui.Grid>
                <Mui.Grid item>
                  <Mui.Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      margin: "12px 0px 10px 10px",
                      opacity: "0.8",
                    }}
                  >
                    {cName}
                   
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Grid container>
                <Mui.Grid item xs={3}>
                  <Mui.Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: theme.spacing(2),
                      margin: "0px 0 0 33px",
                      opacity: "0.8",
                    }}
                  >
                    <ReactI18next.Trans i18nKey="Company details">
                    Company details
                    </ReactI18next.Trans>

                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={3} style={{ marginLeft: "80px" }}></Mui.Grid>
              </Mui.Grid>
              <Mui.Divider
                style={{
                  marginTop: theme.spacing(1),
                }}
              />
              <Mui.Grid container>
                <Mui.Grid item xs={3}>
                  
                  <Mui.Grid
                    container
                    style={{
                      alignContent: "space-around",
                      justifyContent: "space-between",
                      margin: "0px 0 0 33px",
                    }}
                  >
                    <Mui.Grid item xs={12} md={7} lg={7} xl={7}>
                      <Mui.Typography
                        style={{
                          fontWeight: "normal",
                          height: "22px",
                          margin: "16px 0 0 0",
                          opacity: "0.5",
                          fontSize: "16px",
                          width:theme.spacing(50),
                        }}
                      >
                        <ReactI18next.Trans i18nKey="Email ID">
                        Email ID
                        </ReactI18next.Trans>
                        
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3} lg={3} xl={3}>
                      <Mui.Typography
                        style={{
                          margin: "16px 0 0 0",
                        }}
                        onClick={()=>{window.open(`mailto:${mailI}`)}}
                      >
                <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{mailI}</Mui.Link></Mui.Tooltip>
                  
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid
                    container
                    style={{
                      alignContent: "space-around",
                      justifyContent: "space-between",
                      margin: "0px 0 0 33px",
                    }}
                  >
                    <Mui.Grid item xs={12} md={7} lg={7} xl={7}>
                      <Mui.Typography
                        style={{
                          fontWeight: "normal",
                          height: "22px",
                          width:theme.spacing(30),
                          margin: "16px 0 0 0",
                          opacity: "0.5",
                          fontSize: "16px",
                        }}
                      >
                      
                        <ReactI18next.Trans i18nKey="First name">
                        First name
                        </ReactI18next.Trans>
                     
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3} lg={3} xl={3}>
                      <Mui.Typography
                        style={{
                          margin: "16px 0 0 0",
                        }}
                      >
                        {fName}
                       
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                 
                </Mui.Grid>
                <Mui.Grid item xs={2}></Mui.Grid>
                <Mui.Grid item xs={3}>
                  <Mui.Grid
                    container
                    style={{
                      alignContent: "space-around",
                      justifyContent: "space-between",
                    }}
                  ></Mui.Grid>
                  <Mui.Grid
                    container
                    style={{
                      alignContent: "space-around",
                      justifyContent: "space-between",
                    }}
                  >
                    <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                      <Mui.Typography
                        style={{
                          fontWeight: "normal",
                          width: "80px",
                          height: "22px",
                          margin: "16px 0 0 0",
                          opacity: "0.5",
                          fontSize: "16px",
                        }}
                      >
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                      <Mui.Typography style={{ margin: "14px 0 0 0" }}>
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid
                    container
                    style={{
                      alignContent: "space-around",
                      justifyContent: "space-between",
                    }}
                  >
                    <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                      <Mui.Typography
                        style={{
                          fontWeight: "normal",
                          maxWidth: "80vw",
                          height: "22px",
                          margin: "16px 0 0 0",
                          opacity: "0.5",
                          fontSize: "16px",
                        }}
                      >

                        <ReactI18next.Trans i18nKey="Last name">
                        Last name
                        </ReactI18next.Trans>
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6} lg={6} xl={3}>
                      <Mui.Typography
                        style={{
                          margin: "14px 0 0 0",
                        }}
                      >
                        {lName}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </>
        ) : (
          <>
            <Mui.Grid xs={12}>
            <MuiIcons.Person
                style={{
                  border: "1px grey",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    width: "30px",
                    height: "30px",
                    color: "grey",
                    margin: "0px 0px 10px 6px",
                    padding: "3px"
                }}
              />
              <Mui.Typography
                style={{
                  fontSize: "16px",
                    fontWeight: "bold",
                    marginLeft: "46px",
                    marginTop: "-42px",
                    opacity:"0.8"
                }}
              >
                <ReactI18next.Trans i18nKey="Customer details">
                Customer details
            </ReactI18next.Trans>

              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid style={{ marginTop: "12px" }} item xs={12} md={4} lg={3}>
              <DarkerDisabledTextField
                name="emailID"
                value={mailI}
                fullWidth
                label={t("Email")}
                margin="dense"
                size="small"
                disabled
              ></DarkerDisabledTextField>
            </Mui.Grid>

            <Mui.Grid style={{ marginTop: "12px" }} item xs={12} md={4} lg={3}>
              <DarkerDisabledTextField
                name="Companyname"
                value={cName}
                fullWidth
                label={t("Company name")}
                margin="dense"
                size="small"
                disabled
              ></DarkerDisabledTextField>
            </Mui.Grid>

            <Mui.Grid style={{ marginTop: "12px" }} item xs={12} md={4} lg={3}>
              <DarkerDisabledTextField
                name="Companyname"
                value={fName}
                fullWidth
                label={t("First name")}
                margin="dense"
                size="small"
                disabled
              ></DarkerDisabledTextField>
            </Mui.Grid>
            <Mui.Grid
              style={{ marginTop: "12px", marginBottom: "24px" }}
              item
              xs={12}
              md={4}
              lg={3}
            >
              <DarkerDisabledTextField
                name="Companyname"
                value={lName}
                fullWidth
                label={t("Last name")}
                margin="dense"
                size="small"
                disabled
              ></DarkerDisabledTextField>
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    
      <Mui.Grid item xs={12} lg={8}>
        <Mui.Card
          style={{
            padding: "18px",
            backgroundColor: "white",
            marginTop: "18PX",
            width: "150%",
           
          }}
        >
          <Mui.CardHeader
            title={
              <Mui.Box textAlign="center" color="text.secondary">
                <ReactI18next.Trans i18nKey="Selected license">
                Selected license
                </ReactI18next.Trans>

              </Mui.Box>
            }
          />
          {Array.from(customerInviteStore.article.values()).length > 0 ? (
            <>
              <Mui.CardContent>
                <Mui.Table style={{ borderSpacing: "unset" }}>
                  <Mui.TableHead>
                    <Mui.TableRow>
                      <Mui.TableCell
                        align="left"
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="Model">
                          Model
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                      <Mui.TableCell
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="Product">
                          Product
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="right"
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="">
                          {/* Price excl. VAT */}
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="right"
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="Quantity">
                          Quantity
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="right"
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="Available">
                          Available
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="right"
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="To be purchased">
                          To be purchased
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="right"
                        style={{
                          color: theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <ReactI18next.Trans i18nKey="Remove">
                          Remove
                        </ReactI18next.Trans>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {Array.from(customerInviteStore.article.values()).map(
                      (article) => (
                        <Mui.TableRow key={uuidv4()}>
                          <Mui.TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            {article.hundCode}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Box display="flex" alignItems="baseline">
                              <Mui.Box
                                marginRight={1}
                                component="span"
                                style={{
                                  fontSize: theme.typography.h5.fontSize,
                                }}
                              >
                                <i className={article.iconClass} />
                              </Mui.Box>
                              <Mui.Typography
                                style={{ fontWeight: "bold" }}
                                variant="body1"
                              >
                                {article.setName}
                              </Mui.Typography>
                            </Mui.Box>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                        
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Components.Counter
                              min={1}
                              max={10}
                              value={article.quantity}
                              handleDecrement={() =>
                              
                                ArticleDecreaseUpdate(article)
                              }
                              handleIncrement={() =>
                               
                                ArticleIncreaseUpdate(article)
                              }
                              style={{ justifyContent: "flex-end" }}
                            />
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            {article.availableQuantity ? article.availableQuantity : 0}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            {(article.quantity - article.availableQuantity) < 0 ? 0: article.quantity - article.availableQuantity}
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Mui.IconButton
                              size="small"
                              onClick={function () {
                                const responseCheckerForMailID = localStorage.getItem(
                                  "responseOfCustMail"
                                );
                                const responseBasicset = localStorage.getItem(
                                  "Basicset"
                                );
                                const responseChecker =
                                  article.setID == 1 &&
                                  (responseCheckerForMailID == "0" ||
                                    responseBasicset == "0" ||
                                    responseBasicset == "null")
                                    ? (() => {
                                        enqueueSnackbar(
                                          t(`SET-01 is a dependent for your selected license, hence it cannot be removed`),
                                          {
                                            variant: "warning",
                                            preventDuplicate: false,
                                          }
                                        );
                                      })()
                                    : (() => {
                                      const totalBasicSetInCartStore = Array.from(
                                        customerInviteStore.article.values()
                                      ).filter((item) => item.setID !== 1).map((k) => k);

                                      const lengthCheckOfExceptBasicSetFromCart = totalBasicSetInCartStore.length;
                                      if(lengthCheckOfExceptBasicSetFromCart  > 0 && article.setID === 1){
                                        enqueueSnackbar(
                                          t(`SET-01 is a dependent for your selected license, hence it cannot be removed`),
                                          {
                                            variant: "warning",
                                            preventDuplicate: false,
                                          }
                                        );
                                      }else{
                                        customerInviteStore.removeProduct({
                                          article,
                                        });
                                        let product = [];
                                        product = article;
                                        cartStore.removeProduct({ product });
                                        handleRemoveSelect({ article });
                                      }

                                      })();

                             
                              }}
                            >
                              <MuiIcons.Close />
                            </Mui.IconButton>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )
                    )}
                  </Mui.TableBody>
                </Mui.Table>
                <Mui.Box marginTop={2}></Mui.Box>
              </Mui.CardContent>

              <Mui.CardActions
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Mui.Button
                  style={{
                    margin: theme.spacing(1),
                    width: "auto",
                    height: "32px",
                    marginBottom: theme.spacing(3),
                    marginTop: theme.spacing(2),
                  }}
                  variant="contained"
                  component={Router.Link}
                  to={
                    checkDisplay
                      ? "/dealercustomer/customerinvite?isBack=existingUser"
                      : "/dealercustomer/customerinvite?isBack=true"
                  }
                >
                  <ReactI18next.Trans i18nKey="Add more license">
                  Add more license
                  </ReactI18next.Trans>
                </Mui.Button>
                {sendInviteCheck && (
                  <Mui.Button
                    style={{
                      float: "right",
                      width: "auto",
                      height: "34px",
                      marginLeft: "auto",
                      marginRight: "20px",
                      marginBottom: theme.spacing(3),
                      marginTop: theme.spacing(2),
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loader}
                    onClick={handlePurchase}
                  >
                   {loader?<Mui.CircularProgress/>:<ReactI18next.Trans i18nKey="Purchase">
                      Purchase
                    </ReactI18next.Trans>} 
                  </Mui.Button>
                )}
                {purchaseCheck && (
                  <Mui.Button
                    style={{
                      float: "right",
                      width: "auto",
                      height: "34px",
                      marginBottom: theme.spacing(3),
                      marginTop: theme.spacing(2),
                      marginRight: theme.spacing(2),
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleSendInvite}
                  >
                    <ReactI18next.Trans i18nKey="Assign license">
                      Assign license
                    </ReactI18next.Trans>
                  </Mui.Button>
                )}
                  
              </Mui.CardActions>
            </>
          ) : (
            navigationFromManageOrder === "ManageOrder" ? <> <Pages.Order.ManageOrder isCustomerInvite={true} licenseList={licenseList} isManageOrderDetail={true}/>
              <Mui.Button
                style={{
                  float: "right",
                  width: "auto",
                  height: "34px",
                  marginBottom: theme.spacing(3),
                  marginTop: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
                color="primary"
                variant="contained"
                type="submit"  
                onClick={handleSendInvite}
              >
                <ReactI18next.Trans i18nKey="Assign license">
                  Assign license
                </ReactI18next.Trans>
              </Mui.Button>
           </>: <Mui.Box padding={2}>
              <ReactUndraw.SvgNoData
                style={{
                  backgroundImage: `radial-gradient(closest-side at 50%, ${theme.palette.text.disabled}, ${theme.palette.background.paper})`,
                  marginBottom: theme.spacing(6),
                }}
                accentcolor={theme.palette.primary.main}
                primarycolor={theme.palette.primary.main}
                height={200}
              />
              <Mui.Typography variant="h4" align="center" gutterBottom>
                <ReactI18next.Trans i18nKey="No articles are assigned">
                  No articles are assigned
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography
                variant="body2"
                align="center"
                style={{ marginBottom: theme.spacing(4) }}
              >
                <ReactI18next.Trans i18nKey="Please click on the Assign license to add the license">
                Please click on the Assign license to add the article.
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Box display="flex" justifyContent="center" marginBottom={2}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  component={Router.Link}
                  to="/dealercustomer/customerinvite?isBack=true"
                >
                  <ReactI18next.Trans i18nKey="Assign licenses">
                    Assign licenses
                  </ReactI18next.Trans>
                </Mui.Button>
              </Mui.Box>
            </Mui.Box>
          )}
        </Mui.Card>
      </Mui.Grid>

      <Dialog
        open={updateDialogOpen !== undefined ? true : false}
        keepMounted
        disableBackdropClick
        onClose={() => {
          setUpadateRoleDialogOpen(undefined);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon
            style={{
              color: "green",
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: "green" }}>
          <ReactI18next.Trans i18nKey="Success!">
          Success!
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <ReactI18next.Trans i18nKey="You have successfully sent the customer invite to the new customer">
          You have successfully sent the customer invite to the new customer
          </ReactI18next.Trans> "<b>{fName + " " + lName}</b>" <ReactI18next.Trans i18nKey="from the Easy Touch License">
          from the Easy Touch License.
          </ReactI18next.Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
              navigate("/dealercustomer");
              localStorage.removeItem("cfname");
              localStorage.removeItem("clname");
              localStorage.removeItem("ccname");
              localStorage.removeItem("mailId");
              customerInviteStore.resetInviteSummary();
              cartStore.resetCart();
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            OK
          </Mui.Button>
        </DialogActions>
      </Dialog>
      {/* Failure Response pop up  start*/}
      <Dialog
        open={failureDialogOpen !== undefined ? true : false}
        keepMounted
        disableBackdropClick
        onClose={() => {
          setfailureRoleDialogOpen(undefined);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <InfoIcon
            style={{
              color: "grey",
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: "green" }}>
          <ReactI18next.Trans i18nKey="Information!">
          Information!
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <ReactI18next.Trans i18nKey="Unable to onboard the HASE dealer into Easy Touch License">
          Unable to onboard the HASE dealer into Easy Touch License. Please contact admin.
          </ReactI18next.Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
              setfailureRoleDialogOpen(undefined);
              navigate("/dealercustomer/adddealeruser");
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            OK
          </Mui.Button>
        </DialogActions>
      </Dialog>
      {/* Failure pop up end */}
      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon
            style={{
              color: "green",
              width: "60px",
              height: "60px",
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography style={{ fontSize: "28px", color: "green" }}>
          <ReactI18next.Trans i18nKey="Success!">
          Success!
          </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <ReactI18next.Trans i18nKey="Purchase articles have been successfully added to Easy Touch License">
          Purchase articles have been successfully added to Easy Touch License.
          </ReactI18next.Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={async () => {
              setOpen(false);
              
              const langCode = localStorage.getItem("i18nextLng")
              const orderServices = new Services.Order({
                httpManager: new Services.Managers.Axios({ agent: App.axios }),
                urlFactory: new Services.Factories.URL({
                  protocol: Constants.environment.apiBaseUrlProtocol as "http",
                  hostname: Constants.environment.apiBaseUrlHostname,
                  version: Constants.environment.apiBaseUrlVersion,
                }),
              });
            
              const { result:any } =  await orderServices.PassOrderDataForMailTrigger({
                body: { eid: id, langCode: langCode },
              });
              let newSelected: number[] = [];
              customerInviteStore.setSelected({
                selected: newSelected,
              });
              cartStore.resetCart();
              setLoader(true);
              Array.from(customerInviteStore.article.values()).map((article) =>
                ArticleQuantityUpdateAfterPurchase(article)
              );
              navigate(`/dealercustomer/addcustomer`);
              setLoader(false);
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            OK
          </Mui.Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
