/** @format */

import * as Mui from "@material-ui/core";
import * as React from "react";
import * as ReactI18Next from "react-i18next";
import * as Components from "src/app/components";
import * as Models from "src/models";

export declare namespace Card {
  export interface Props {
    selected: boolean;
    functions: Models.Set.Function[];
    article: Models.Set.Article;
    handleClick(props: { article: Models.Set.Article }): void;
  }
}

// eslint-disable-next-line no-redeclare
export function Card({
  article,
  handleClick,
  selected,
  functions,
}: Card.Props) {
  const theme = Mui.useTheme();
  const { t } = ReactI18Next.useTranslation();
  return (
    <Mui.Card
      style={{ height: "100%" }}
      {...(selected !== undefined  && {
        elevation: selected ? 0 : undefined,
        style: {
          height: "100%",
          border: (selected && article.olPrice.singleNetPrice.toString() !== "0.0" )
            ? `2px solid ${theme.palette.success.main}`
            : "inherit",
        },
      })}
    >
      <Mui.CardActionArea
        style={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "unset",
          alignItems: "unset",
          verticalAlign: "unset",
        }}
        onClick={() => handleClick({ article })}
      >
        <Mui.CardHeader
          title={
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
              <Mui.ListItemIcon>
                <Mui.Box fontSize={theme.typography.h4.fontSize} lineHeight={0}>
                  <i className={article.iconClass} />
                </Mui.Box>
              </Mui.ListItemIcon>

              <Mui.ListItemText
                primary={article.setName}
                secondary={article.hundCode}
              />
              {(article.availableQuantity === null || undefined || "" ) ? <Mui.CircularProgress/> : 
              <Components.WorkingStatus
                status={article.availableQuantity == 0 ? 2 : 1}
                labels={{
                  1: t("Available")+" "+ article.availableQuantity,
                  2: t("To be purchased"),
                }}
              />}
            </Mui.ListItem>
          }
          action={
            <>
            </>
          }
        />
        <Mui.CardContent>
          {functions && functions.length > 0 && (
            <Mui.Box height={48} overflow={"auto"}>
              <Components.FuctionIcons functions={functions} />
            </Mui.Box>
          )}

          <Mui.Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: theme.spacing(2) }}
          >
            <ReactI18Next.Trans i18nKey="Available functions">
              Available functions
            </ReactI18Next.Trans>
          </Mui.Typography>
          <Mui.Typography noWrap>
            {functions?.map((func) => func.funcationName).join(", ")}
          </Mui.Typography>
        </Mui.CardContent>
      </Mui.CardActionArea>
    </Mui.Card>
  );
}
