/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as Article from "src/app/pages/article";
import * as Activate from "src/app/pages/license/activate";
import * as Models from "src/models";
import * as ReactI18next from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = Mobx.observer(function (props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const classes = useStyles({
    loading: activateStore.isLoadingPreviouslyInstalledArticles,
  });

  return (
    <div
      style={{
        position: "relative",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Mui.Grid
        container
        spacing={2}
        className={classes.root}
        style={{
          marginTop: 16,
          height: "calc(100vh - 336px)",
          overflow: "auto",
        }}
      >
        <Mui.Grid item xs={12}>
          {children}
        </Mui.Grid>
      </Mui.Grid>
    </div>
  );
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = Mui.makeStyles((theme: Mui.Theme) => ({
  root: {
    // flexGrow: 1,
    "&::before": {
      content: "''",
      display: ({ loading }: { loading: boolean }) =>
        loading ? "block" : "none",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      opacity: 0.5,
      backgroundColor: theme.palette.background.paper,
      zIndex: 2,
      cursor: "not-allowed",
    },
  },
}));

export const Layout = Mobx.observer(function () {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const userStore = React.useContext(App.Contexts.userStore);
  const { t } = ReactI18next.useTranslation();

  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName ?? "customer";

    if (
      !licenseStore.licenses?.length &&
      userRole === "customer" &&
      uid !== undefined &&
      uid !== null
    )
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });

    if (!functionStore.functions.length) functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (activateStore.search) activateStore.setSearch({ search: "" });

    if (event.target.checked) {
      const newSelecteds = activateStore.getAvailableArticlesFromLicenses.map(
        (article) => article.setID
      );
      activateStore.setSelected({ selected: newSelecteds });
      return;
    }

    activateStore.setSelected({ selected: [] });
  };

  return (
    <>
      <Mui.Grid container spacing={3}>
        <Mui.Grid item xs={12} md={6} lg={4}></Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={8}>
          <Mui.Grid container spacing={2} alignItems="center">
            <Mui.Grid item xs={12} md={6}>
              <Search />
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6}>
              <Mui.FormControl style={{ marginBottom: theme.spacing(1.5) }}>
                <Mui.FormControlLabel
                  label={t("Select all")}
                  control={
                    <Mui.Checkbox
                      indeterminate={
                        activateStore.selected.length > 0 &&
                        activateStore.selected.length <
                          activateStore.getAvailableArticlesFromLicenses.length
                      }
                      checked={
                        activateStore.getAvailableArticlesFromLicenses.length >
                          0 &&
                        activateStore.selected.length ===
                          activateStore.getAvailableArticlesFromLicenses.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all articles",
                      }}
                    />
                  }
                />
              </Mui.FormControl>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container spacing={3}>
        <Mui.Grid item xs={12} md={6} lg={4}>
          <Mui.Card
            style={{ height: "calc(100vh - 288px)", position: "relative" }}
          >
            <Mui.CardContent>
              <Activate.Form />
            </Mui.CardContent>
          </Mui.Card>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={6} lg={8}>
          <div className={classes.root}>
            <Mui.AppBar color="transparent" position="static">
              <Mui.Tabs value={value} onChange={handleChange}>
                <Mui.Tab
                  label={
                    <Mui.Badge
                      color="primary"
                      badgeContent={activateStore.selected.length}
                    >
                      <ReactI18next.Trans i18nKey="Available license">
                      Available license
                        </ReactI18next.Trans>
                      
                    </Mui.Badge>
                  }
                  {...a11yProps(0)}
                />

                <Mui.Tab
                  label={
                    <Mui.Badge
                      color="primary"
                      badgeContent={activateStore.installed.length}
                    >
                      <ReactI18next.Trans i18nKey="Installed articles">
                      Installed articles
                        </ReactI18next.Trans>
                      
                    </Mui.Badge>
                  }
                  {...a11yProps(1)}
                />
              </Mui.Tabs>
            </Mui.AppBar>

            <TabPanel value={value} index={0}>
              <AvailableArticles />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <InstalledArticles />
            </TabPanel>
          </div>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
});

const AvailableArticles = Mobx.observer(() => {
  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const functionStore = React.useContext(App.Contexts.functionStore);

  /******************** Duplication article remove filter  ********************/
  const availableArticleDuplicationRemoved = activateStore.getFilteredAvailableArticles.filter(
    (k) => k.setID !== 23
  );
  //alert(JSON.stringify(availableArticleDuplicationRemoved));

  /****************** End of duplication article remove filter  ***************/

  const handleSelect = ({ article }: { article: Models.Set.Article }) => {
    const selectedIndex = activateStore.selected.indexOf(article.setID);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(activateStore.selected, article.setID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(activateStore.selected.slice(1));
    } else if (selectedIndex === activateStore.selected.length - 1) {
      newSelected = newSelected.concat(activateStore.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        activateStore.selected.slice(0, selectedIndex),
        activateStore.selected.slice(selectedIndex + 1)
      );
    }

    activateStore.setSelected({ selected: newSelected });
  };

  return (
    <Mui.Grid container spacing={2}>
      {activateStore.getAvailableArticlesFromLicenses.length > 0 &&
        availableArticleDuplicationRemoved.length === 0 &&
        activateStore.search && (
          <Mui.Grid item xs={12}>
            <MuiLab.Alert severity="info">
              <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="No license(s) found">
              No license(s) found</ReactI18next.Trans>
                </MuiLab.AlertTitle>
                <ReactI18next.Trans i18nKey="No available license(s)">
                No available license(s) found for the search term. Use a different search term or clear the search to view some available license(s).</ReactI18next.Trans>
            </MuiLab.Alert>
          </Mui.Grid>
        )}
      {availableArticleDuplicationRemoved.map((article) => (
        <Mui.Grid item xs={12} lg={6} key={article.setID}>
          <Activate.Card
            selected={activateStore.selected.includes(article.setID)}
            functions={functionStore.functions?.filter((func) =>
              (article as Models.Set.Article).functions.includes(
                func.setFunctionID
              )
            )}
            handleClick={handleSelect}
            article={article}
          />
        </Mui.Grid>
      ))}
    </Mui.Grid>
  );
});

const InstalledArticles = Mobx.observer(() => {
  const { values } = Formik.useFormikContext<Activate.FormProps>();

  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const functionStore = React.useContext(App.Contexts.functionStore);

  return (
    <Mui.Grid container spacing={2}>
      {!values.machineId && (
        <Mui.Grid item xs={12}>
          <MuiLab.Alert severity="info">
            <MuiLab.AlertTitle><ReactI18next.Trans i18nKey="Invalid Machine ID">
            Invalid Machine ID</ReactI18next.Trans>
              </MuiLab.AlertTitle>
              <ReactI18next.Trans i18nKey="Please enter a valid Machine ID">
              Please enter a valid Machine ID to view the machine's previously installed license(s).</ReactI18next.Trans>
          </MuiLab.Alert>
        </Mui.Grid>
      )}
      {values.machineId &&
        activateStore.getInstalledArticlesFromLicenses.length === 0 && (
          <Mui.Grid item xs={12}>
            <MuiLab.Alert severity="info">
              <MuiLab.AlertTitle>
              <ReactI18next.Trans i18nKey="No records for this machine">
              No records for this machine</ReactI18next.Trans></MuiLab.AlertTitle>
              <ReactI18next.Trans i18nKey="There are no previously">
              There are no previously installed license(s) found for this
              machine. Please recheck the machine ID if you were expecting to
              see some installed license(s) for this machine.</ReactI18next.Trans>
            </MuiLab.Alert>
          </Mui.Grid>
        )}
      {activateStore.getInstalledArticlesFromLicenses.length > 0 &&
        activateStore.getFilteredInstalledArticles.length === 0 &&
        activateStore.search && (
          <Mui.Grid item xs={12}>
            <MuiLab.Alert severity="info">
              <MuiLab.AlertTitle>
              <ReactI18next.Trans i18nKey="No license(s) found">
              No license(s) found</ReactI18next.Trans></MuiLab.AlertTitle>
              <ReactI18next.Trans i18nKey="No installed license(s) found">
              No installed license(s) found for the search term. Use a different
              search term or clear the search to view some installed license(s).</ReactI18next.Trans>
            </MuiLab.Alert>
          </Mui.Grid>
        )}
      {activateStore.getFilteredInstalledArticles.map((article) => (
        <Mui.Grid item xs={12} lg={6} key={article.setID}>
          <Article.Card
            functions={functionStore.functions?.filter((func) =>
              (article as Models.Set.Article).functions.includes(
                func.setFunctionID
              )
            )}
            article={article}
            variant={"DETAILED"}
          />
        </Mui.Grid>
      ))}
    </Mui.Grid>
  );
});

const Search = Mobx.observer(() => {
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();

  const activateStore = React.useContext(App.Contexts.license.activateStore);

  const handleSearch: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void = (event) => {
    activateStore.setSearch({ search: event.target.value });
  };

  return (
    <Mui.FormControl style={{ width: 300, marginBottom: theme.spacing(1.5) }}>
      <Mui.TextField
        value={activateStore.search}
        onChange={handleSearch}
        label={t("Search")}
      />
    </Mui.FormControl>
  );
});
