/** @format */

import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import axios from "axios";
import * as Notistack from "notistack";
import * as Numeral from "numeral";
import * as React from "react";
import * as ReactCookie from "react-cookie";
import * as Router from "react-router-dom";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Customer from "src/app/pages/customer";
import * as DealerCustomer from "src/app/pages/dealercustomer";
import * as AddCustomer from "src/app/pages/dealercustomer/addcustomer";
import * as CustomerInvite from "src/app/pages/dealercustomer/customerinvite";
import * as ImportDealer from "src/app/pages/dealercustomer/importdealer";
import * as AdminManage from "src/app/pages/dealercustomer/manageadmin";
import * as manageDealer from "src/app/pages/dealercustomer/managedealer";
import * as DeviceConfigurationUpload from "src/app/pages/device-configuration-upload";
import * as EasyTouchDevice from "src/app/pages/easy-touch-device";
import * as Function from "src/app/pages/function";
import * as Home from "src/app/pages/home";
import * as Icon from "src/app/pages/icon";
import * as IndustrySegment from "src/app/pages/industry-segment";
import * as License from "src/app/pages/license";
import * as Order from "src/app/pages/order";
import * as Tenant from "src/app/pages/tenant";
import * as UserManagement from "src/app/pages/user-management";
import * as AddDealer from "src/app/pages/user-management/adddealer";
import * as Routes from "src/app/routes";
import * as Constants from "src/constants";
import * as Services from "src/services";
import * as Stores from "src/stores";
import * as Themes from "src/themes";

Numeral.options.scalePercentBy100 = false;

const httpManager = new Services.Managers.Axios({ agent: axios });
const urlFactory = new Services.Factories.URL({
  protocol: Constants.environment.apiBaseUrlProtocol as "http" | "https",
  hostname: Constants.environment.apiBaseUrlHostname,
  version: Constants.environment.apiBaseUrlVersion,
});

const articleService = new Services.Article({ httpManager, urlFactory });
const dashboardService = new Services.Dashboard({ httpManager, urlFactory });
const functionService = new Services.Function({ httpManager, urlFactory });
const industrySegmentService = new Services.IndustrySegment({
  httpManager,
  urlFactory,
});
const userService = new Services.User({ httpManager, urlFactory });
const licenseService = new Services.License({ httpManager, urlFactory });
const easyTouchDeviceService = new Services.EasyTouchDevice({
  httpManager,
  urlFactory,
});
const tenantService = new Services.Tenant({ httpManager, urlFactory });
const orderService = new Services.Order({ httpManager, urlFactory });
const dcustomerService = new Services.DealerCustomerService({
  httpManager,
  urlFactory,
});
const adminManageService = new Services.AdminManageService({
  httpManager,
  urlFactory,
});
const invitecustomerService = new Services.InviteCustomerService({
  httpManager,
  urlFactory,
});
const adddealerService = new Services.AddDealer({
  httpManager,
  urlFactory,
});
const addCustomerService = new Services.AddCustomer({
  httpManager,
  urlFactory,
});
const iconService = new Services.Icon({ httpManager, urlFactory });
const userManagementService = new Services.UserManagement({
  httpManager,
  urlFactory,
});
const importDealerService = new Services.ImportDealer({
  httpManager,
  urlFactory,
});
const manageDealerService = new Services.ImportDealer({
  httpManager,
  urlFactory,
});
const customerService = new Services.Customer({ httpManager, urlFactory });
const deviceConfigurationUploadService = new Services.DeviceConfigurationUpload(
  { httpManager, urlFactory }
);

const userStore = new Stores.User({ userService });
const articleStore = new Article.Store({ articleService, view: "TABULAR" });
const licenseStore = new License.Store({
  articleStore,
  licenseService,
  userStore,
});
export const Contexts = {
  userStore: React.createContext(userStore),
  cartStore: React.createContext(new Stores.Cart({ userStore })),
  customerStore: React.createContext(new Customer.Store({ customerService })),
  orderStore: React.createContext(
    new Order.Store({ view: "TABULAR", orderService, licenseService ,articleService})
  ),
  delareCustomerStore: React.createContext(
    new DealerCustomer.Store({ view: "TABULAR", dcustomerService })
  ),
  adminManageStore: React.createContext(
    new AdminManage.Store({ view: "TABULAR", adminManageService })
  ),
  userManagementStore: React.createContext(
    new UserManagement.Store({ userManagementService })
  ),
  importDealerStore: React.createContext(
    new ImportDealer.Store({ importDealerService })
  ),
  manageDealerStore: React.createContext(
    new manageDealer.Store({ view: "TABULAR", manageDealerService })
  ),
  customerInviteStore: React.createContext(
    new CustomerInvite.Store({
      userStore,
      invitecustomerService,
      licenseStore,
      articleStore,
      licenseService,
    })
  ),
  addDealerStore: React.createContext(
    new AddDealer.Store({ adddealerService })
  ),
  addCustomerStore: React.createContext(
    new AddCustomer.Store({ addCustomerService, articleStore })
  ),
  homeStore: React.createContext(new Home.Store({ dashboardService })),
  articleStore: React.createContext(articleStore),
  functionStore: React.createContext(new Function.Store({ functionService })),
  industrySegmentStore: React.createContext(
    new IndustrySegment.Store({ industrySegmentService })
  ),
  iconStore: React.createContext(new Icon.Store({ iconService })),
  easyTouchDeviceStore: React.createContext(
    new EasyTouchDevice.Store({
      articleStore,
      easyTouchDeviceService,
      userStore,
    })
  ),

  license: {
    store: React.createContext(licenseStore),
    generateStore: React.createContext(
      new License.Generate.Store({ licenseService })
    ),
    activateStore: React.createContext(
      new License.Activate.Store({ articleStore, licenseStore })
    ),
    assignedLicenseDetailStore : React.createContext(
      new License.AssignedLicenseDetail.Store({ licenseService})
    ),
  },
  globalTenantStore: React.createContext(
    new Stores.Tenant({ tenantService, dashboardService })
  ),
  tenantStore: React.createContext(
    new Tenant.Store({
      tenantService,
    })
  ),
  deviceConfigurationUploadStore: React.createContext(
    new DeviceConfigurationUpload.Store({ deviceConfigurationUploadService })
  ),
};

export { axios };

export function Main() {
  const [darkMode, setDarkMode] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const notistackRef = React.createRef<Notistack.SnackbarProvider>();
  const userStore = React.useContext(Contexts.userStore);
  const [cookies] = ReactCookie.useCookies(["etutoken"]);

  React.useEffect(() => {
    try {
      const token = userStore.setUserFromToken({ token: cookies["etutoken"] });
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onClickDismiss({ key }: { key: React.ReactText }) {
    return function () {
      notistackRef?.current?.closeSnackbar(key);
    };
  }

  function handleThemeToggle() {
    return function () {
      setDarkMode(!darkMode);
    };
  }

  const themeMode: Themes.Global.Palette.ThemeMode = darkMode
    ? "dark"
    : "light";
  const theme = Mui.createMuiTheme({
    ...Themes.Global.Shared({ themeMode }),
    palette: {
      ...Themes.Global.Palette({ themeMode }).palette,
      type: themeMode,
    },
  });

  return (
    <>
      <Router.BrowserRouter>
        <Mui.ThemeProvider theme={theme}>
          <Mui.CssBaseline />
          <ReactCookie.CookiesProvider>
            <Notistack.SnackbarProvider
              ref={notistackRef}
              action={(key) => (
                <Mui.IconButton onClick={onClickDismiss({ key })}>
                  <MuiIcons.Close />
                </Mui.IconButton>
              )}
              maxSnack={4}
              preventDuplicate
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {!isLoading && (
                <Routes.Global />
              )}
            </Notistack.SnackbarProvider>
          </ReactCookie.CookiesProvider>
          <Components.ThemeToggle
            {...{
              darkMode,
              handleThemeTaggleClick: handleThemeToggle,
            }}
          />
        </Mui.ThemeProvider>
      </Router.BrowserRouter>
    </>
  );
}
