import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace IndustrySegment {
  export interface IIndustrySegment {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = IndustrySegment.IIndustrySegment;

  export namespace GetAllData {
    export interface Props {
      status?: number;
      userId?: number;
    }

    export type Return = Models.API.Response<Models.IndustrySegment.Main[]>;
  }

  export namespace Create {
    export type Body = Models.IndustrySegment.Main;

    export interface Props {
      body: IndustrySegment.Create.Body;
      uid: string | number;
    }

    export type Return = Models.API.Response<number>;
  }

  export namespace Delete {
    export interface Props {
      industrySegmentsId: string | number;
      uid: string | number;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace Activate {
    export interface Props {
      industrySegmentsId: string | number;
      uid: string | number;
    }

    export type Return = Models.API.Response<any>;
  }
}

// eslint-disable-next-line no-redeclare
export class IndustrySegment {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: IndustrySegment.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getAllData(
    props?: IndustrySegment.GetAllData.Props
  ): Promise<IndustrySegment.GetAllData.Return> {
    const url = this.urlFactory.create({
      named: ["IndustrySegments", "GetAll", `${props?.status || -1}`],
    });

    const response = await this.httpManager.get<
      IndustrySegment.GetAllData.Return
    >({
      url,
    });

    return response;
  }

  public async create({
    body,
    uid,
  }: IndustrySegment.Create.Props): Promise<IndustrySegment.Create.Return> {
    const url = this.urlFactory.create({
      named: ["IndustrySegments", "Create"],
    });

    const response = await this.httpManager.post<
      IndustrySegment.Create.Return,
      IndustrySegment.Create.Body
    >({
      url,
      body,
    });

    return response;
  }

  public async delete({
    industrySegmentsId,
    uid,
  }: IndustrySegment.Delete.Props): Promise<IndustrySegment.Delete.Return> {
    const url = this.urlFactory.create({
      named: ["IndustrySegments", "Delete", `${industrySegmentsId}`],
    });

    const response = await this.httpManager.get<IndustrySegment.Create.Return>({
      url,
    });

    return response;
  }

  public async activate({
    industrySegmentsId,
    uid,
  }: IndustrySegment.Activate.Props): Promise<IndustrySegment.Activate.Return> {
    const url = this.urlFactory.create({
      named: ["IndustrySegments", "Activate", `${industrySegmentsId}`],
    });

    const response = await this.httpManager.get<IndustrySegment.Create.Return>({
      url,
    });

    return response;
  }
}
