/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export interface IStore {
    isLoading: boolean;
    importDealer: Models.ImportDealer.Main[];
    importDealerService: Services.ImportDealer;
  }
  export type Options = {
    importDealerService: Services.ImportDealer;
  };

  export namespace AddDealer {
    export interface Props extends Services.ImportDealer.AddDealer.Props {}
  }

  export namespace Read {
    export interface Props extends Services.ImportDealer.GetAllData.Props {}
    export interface DealerProps
      extends Services.ImportDealer.GetAllData.DealerProps {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public importDealer: Models.ImportDealer.Main[] = [];
  @Mobx.observable public importDealerFromSSO: Models.ImportDealer.Main[] = [];
  @Mobx.observable public selected: Models.ImportDealer.DealerUser[] = [];
  @Mobx.observable public isLoading: boolean = false;
  public importDealerService: Services.ImportDealer;

  constructor({ importDealerService }: Store.Options) {
    this.importDealerService = importDealerService;
  }

  @Mobx.action public async readUsers(props: Store.Read.DealerProps) {
    try {
      this.isLoading = true;
      const { result } = await this.importDealerService.getDealerFromSSO(props);

      return (this.importDealerFromSSO = result);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async readDealerUsers(props: Store.Read.DealerProps) {
    try {
      this.isLoading = true;
      const { result } = await this.importDealerService.getDealerUserDetails(
        props
      );
      return (this.importDealer = result);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async readDealer(props: Store.Read.DealerProps) {
    try {
      this.isLoading = true;
      const { result } = await this.importDealerService.getDealerUserDetails(
        props
      );
      const response = this.importDealer;
      var resultValue: any = response.find((k) => k.dealerProfileID === "1011");
      var resultData = delete resultValue?.compentencyDisplay;
      if (resultValue != undefined) {
        localStorage.setItem("dealerID", JSON.stringify(resultValue));
      } else {
      }
      const local = localStorage.getItem("dealerID");
      return (this.importDealer = result);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async addDealers(props: Store.AddDealer.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.importDealerService.addDealer(props);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (!this.isLoading) {
      return false;
    }

    return true;
  }

  @Mobx.action public setSelected({
    selected,
  }: {
    selected: Models.ImportDealer.DealerUser[];
  }) {
    this.selected = selected;
  }
}
