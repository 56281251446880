import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as AddDealer from "src/app/pages/user-management/adddealer";

const useStyles = Mui.makeStyles((theme: Mui.Theme) => ({
  root: {
    "&::before": {
      content: "''",
      display: ({ loading }: { loading: boolean }) =>
        loading ? "block" : "none",
      height: "100%",
      position: "absolute",

      top: 0,
      left: 0,
      width: "100%",
      opacity: 0.5,
      backgroundColor: theme.palette.background.paper,
      zIndex: 2,
      cursor: "not-allowed",
    },
  },
}));

export const Layout = Mobx.observer(function () {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const activateStore = React.useContext(App.Contexts.license.activateStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const userStore = React.useContext(App.Contexts.userStore);

  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName ?? "customer";

    if (
      !licenseStore.licenses?.length &&
      userRole === "customer" &&
      uid !== undefined &&
      uid !== null
    )
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });

    if (!functionStore.functions.length) functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Mui.Grid item xs={12} md={6} lg={4} alignItems="center">
        <Mui.Card
          style={{
            height: "calc(100vh - 288px)",
            position: "relative",
            alignItems: "center",
          }}>
          <Mui.CardContent>
            <AddDealer.Form />
          </Mui.CardContent>
        </Mui.Card>
      </Mui.Grid>
    </>
  );
});
