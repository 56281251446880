import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as IndustrySegment from "src/app/pages/industry-segment";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
  }),
);

export declare namespace Edit {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  > & {
    industrySegment: Models.IndustrySegment.Main;
  };
}

// eslint-disable-next-line no-redeclare
export const Edit = Mobx.observer(function ({
  industrySegment,
  open,
  handleClose,
}: Edit.Props) {
  const [initialValues, setInitialValues] = React.useState<
    IndustrySegment.Form.Values
  >();
  const theme = Mui.useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const { t } = ReactI18next.useTranslation();
  const industrySegmentStore = React.useContext(
    App.Contexts.industrySegmentStore
  );
  const userStore = React.useContext(App.Contexts.userStore);
  const industrySegmentService = new Services.IndustrySegment({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  React.useEffect(() => {
    if (!industrySegment) {
      setInitialValues(undefined);
      return;
    }

    const initialValues = {
      name_de: industrySegment.nameDE,
      name_en: industrySegment.nameEN,
      name_es: industrySegment.nameES,
      name_fr: industrySegment.nameFR,
      name_it: industrySegment.nameIT,
    } as IndustrySegment.Form.Values;

    setInitialValues(initialValues);
  }, [industrySegment]);

  async function handleEdit(
    values: IndustrySegment.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<IndustrySegment.Form.Values>,
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      if (!industrySegment) {
        throw new Error(t("IndustrySegment is undefined"));
      }

      const updatedIndustrySegment: Models.IndustrySegment.Main = {
        ...industrySegment,
        nameDE: values.name_de,
        nameEN: values.name_en,
        nameES: values.name_es,
        nameFR: values.name_fr,
        nameIT: values.name_it,
      } as Models.IndustrySegment.Main;
      await industrySegmentService.create({
        body: updatedIndustrySegment,
        uid,
      });
      industrySegmentStore.read();
      enqueueSnackbar(t("Industry/Segments successfully updated!"), {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }

  return (
    <>
      <Formik.Formik
        enableReinitialize
        initialValues={initialValues || ({} as IndustrySegment.Form.Values)}
        validationSchema={IndustrySegment.Shared({ t }).Form.validationSchema}
        onSubmit={handleEdit}
      >
        {({ isSubmitting, submitForm }) => (
          <Components.LightBox
            open={open}
            handleClose={handleClose}
            content={<IndustrySegment.Form />}
            title={
              <Mui.Typography
                style={{ fontWeight: "bold" }}
                variant="h6"
                className={classes.title}
              >
                {t("Edit Industry / Segment")}
              </Mui.Typography>
            }
            actions={
              <>
                <Mui.Button variant="contained" onClick={handleClose}>
                  <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
                </Mui.Button>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => submitForm()}
                >
                  <ReactI18next.Trans i18nKey="Update">
                    Update
                  </ReactI18next.Trans>
                  {isSubmitting && (
                    <Mui.CircularProgress
                      style={{
                        marginLeft: theme.spacing(1),
                      }}
                      color={"inherit"}
                      size={theme.typography.button.fontSize}
                    />
                  )}
                </Mui.Button>
              </>
            }
          />
        )}
      </Formik.Formik>
    </>
  );
});
