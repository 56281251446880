import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as ReactUndraw from "iblis-react-undraw";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as Components from "src/app/components";
import * as Yup from "yup";

const useStyles = Mui.makeStyles((theme) => ({
  registerCardContainer: {
    paddingTop: theme.spacing(6),
  },
  registrationIllustration: {
    width: "inherit",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

export declare namespace Register {
  export namespace Form {
    export interface Values {
      email: string;
      password: string;
      salutation: string;
      firstName: string;
      lastName: string;
      position: string;
      country: string;
      language: string;
      telephone: string;
    }
  }
}

// eslint-disable-next-line no-redeclare
export function Register() {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("Email ID is required"))
      .email(t("Email is invalid")),
    password: Yup.string().required(t("Password is required")),
    firstName: Yup.string()
      .min(2, t("pages.account.register.inputErrors.firstName.min"))
      .required(t("pages.account.register.inputErrors.firstName.required")),
    lastName: Yup.string()
      .min(2, t("pages.account.register.inputErrors.lastName.min"))
      .required(t("pages.account.register.inputErrors.lastName.required")),
    position: Yup.string()
      .min(2, t("pages.account.register.inputErrors.position.min"))
      .required(t("pages.account.register.inputErrors.position.required")),
    country: Yup.string(),
    language: Yup.string(),
    telephone: Yup.string()
      .min(2, t("pages.account.register.inputErrors.position.min"))
      .required(t("pages.account.register.inputErrors.position.required")),
  });

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  function handleClickShowPassword() {
    return function () {
      setShowPassword(!showPassword);
    };
  }

  function handleMouseDownPassword() {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
    };
  }

  return (
    <Mui.Box className={classes.registerCardContainer}>
      <Mui.Typography
        component="div"
        variant="h6"
        color="primary"
        align="center"
        gutterBottom
      >
        <Mui.Box>
          <ReactI18next.Trans i18nKey="Easy Touch Shop & License Management">
            Easy Touch Shop & License Management
          </ReactI18next.Trans>
        </Mui.Box>
      </Mui.Typography>
      <Mui.Paper>
        <ReactUndraw.SvgWelcome
          primarycolor={theme.palette.primary.main}
          height={theme.spacing(27)}
          className={classes.registrationIllustration}
        />
        <Mui.Box padding={3}>
          <Mui.Typography variant="body2">
            <ReactI18next.Trans
              i18nKey="KERN team thank you for your interest to create the KERN Account.
              Please provide the details below to create your KERN account. We
              will send an email to your address to verify your email after the
              registration, please validate your email using the link provided
              in the email. You will be redirected to the web shop upon success
              creation of KERN account."
            >
              KERN team thank you for your interest to create the KERN Account.
              Please provide the details below to create your KERN account. We
              will send an email to your address to verify your email after the
              registration, please validate your email using the link provided
              in the email. You will be redirected to the web shop upon success
              creation of KERN account.
            </ReactI18next.Trans>
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box padding={3}>
          <Mui.Box marginBottom={3}>
            <Components.Logo />
          </Mui.Box>
          <Mui.Box
            display="inline-flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Mui.Typography variant="h5" gutterBottom>
              <ReactI18next.Trans i18nKey={"Register"}>
                Register
              </ReactI18next.Trans>
            </Mui.Typography>
            <Components.LocaleDropdown />
          </Mui.Box>
          <Formik.Formik
            initialValues={{
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              country: "",
              language: "",
              position: "",
              salutation: "",
              telephone: "",
            }}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                alert(JSON.stringify(values, null, 2));
              }, 500);
            }}
          >
            {({
              isSubmitting,
              submitForm,
            }: Formik.FormikProps<Register.Form.Values>) => (
              <Formik.Form>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    autoFocus
                    component={FormikMui.TextField}
                    name="email"
                    type="email"
                    label={
                      <ReactI18next.Trans i18nKey={"Email ID"}>
                        Email ID
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="password"
                    label={
                      <ReactI18next.Trans i18nKey={"Password"}>
                        Password
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <Mui.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword()}
                            onMouseDown={handleMouseDownPassword()}
                          >
                            {showPassword ? (
                              <MuiIcons.Visibility />
                            ) : (
                              <MuiIcons.VisibilityOff />
                            )}
                          </Mui.IconButton>
                        </Mui.InputAdornment>
                      ),
                    }}
                  />
                </Mui.FormControl>
                <Mui.Typography variant="h6" gutterBottom>
                  Personal Details
                </Mui.Typography>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="salutation"
                    label={
                      <ReactI18next.Trans i18nKey={"Salutation"}>
                        Salutation
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="text"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="firstName"
                    label={
                      <ReactI18next.Trans i18nKey={"First name"}>
                        First Name
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="text"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="lastName"
                    label={
                      <ReactI18next.Trans i18nKey={"Last name"}>
                        Last Name
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="text"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="position"
                    label={
                      <ReactI18next.Trans i18nKey={"Position"}>
                        Position
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="text"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="country"
                    label={
                      <ReactI18next.Trans i18nKey={"Country"}>
                        Country
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="text"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="language"
                    label={
                      <ReactI18next.Trans i18nKey={"Language"}>
                        Language
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="text"
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth margin="normal">
                  <Formik.Field
                    component={FormikMui.TextField}
                    name="telephone"
                    label={
                      <ReactI18next.Trans i18nKey={"Telephone"}>
                        Telephone
                      </ReactI18next.Trans>
                    }
                    variant="outlined"
                    type="tel"
                  />
                </Mui.FormControl>
                {isSubmitting && <Mui.LinearProgress />}
                <Mui.Box
                  display="grid"
                  gridAutoFlow="column"
                  gridColumnGap={theme.spacing(3)}
                >
                  <Mui.FormControl fullWidth margin="normal">
                    <Mui.Button
                      component={Router.Link}
                      to="/account/login"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      <ReactI18next.Trans i18nKey={"Back To Login"}>
                        Back To Login
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.FormControl>
                  <Mui.FormControl fullWidth margin="normal">
                    <Mui.Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      <ReactI18next.Trans i18nKey={"Create Account"}>
                        Create Account
                      </ReactI18next.Trans>
                    </Mui.Button>
                  </Mui.FormControl>
                </Mui.Box>
              </Formik.Form>
            )}
          </Formik.Formik>
        </Mui.Box>
      </Mui.Paper>
    </Mui.Box>
  );
}
