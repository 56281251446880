import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as Notistack from "notistack";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    title: {
      flexGrow: 1,
    },
  })
);

export declare namespace Translate {
  export type Props = Pick<
    Components.ResponsiveFullscreenDialogWithScroll.Props,
    "open" | "handleClose"
  > & {
    article?: Models.Set.Article;
  };
}

// eslint-disable-next-line no-redeclare
export const Translate = Mobx.observer(function ({
  article,
  open,
  handleClose,
}: Translate.Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const [initialValues, setInitialValues] = React.useState<
    Article.TranslateForm.Values
  >();
  const [industrySegments, setIndustrySegments] = React.useState<
    Models.IndustrySegment.Main[]
  >([]);
  const { t } = ReactI18next.useTranslation();
  const articleStore = React.useContext(App.Contexts.articleStore);
  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const theme = Mui.useTheme();
  const userStore = React.useContext(App.Contexts.userStore);
  const industrySegmentService = new Services.IndustrySegment({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  async function handleTranslate(
    values: Article.TranslateForm.Values,
    { setSubmitting }: Formik.FormikHelpers<Article.TranslateForm.Values>
  ) {
    try {
      const uid = userStore.user?.uid;
      if (uid === undefined) {
        throw new Error(t("uid is undefined"));
      }

      if (!article) {
        throw new Error(t("Article is undefined"));
      }

      const setsLang: Partial<Models.SetsLang>[] = [
        {
          setID: article.setID,
          setName: values.name_de,
          langCode: "de",
          setDescriptions: values.description_de,
          industrySegment: industrySegments.find(
            (industrySegment) =>
              industrySegment.industrySegmentsId === article.industrySegmentsId
          )?.nameDE,
        },
        {
          setID: article.setID,
          setName: values.name_fr,
          langCode: "fr",
          setDescriptions: values.description_fr,
          industrySegment: industrySegments.find(
            (industrySegment) =>
              industrySegment.industrySegmentsId === article.industrySegmentsId
          )?.nameFR,
        },
        {
          setID: article.setID,
          setName: values.name_it,
          langCode: "it",
          setDescriptions: values.description_it,
          industrySegment: industrySegments.find(
            (industrySegment) =>
              industrySegment.industrySegmentsId === article.industrySegmentsId
          )?.nameIT,
        },
        {
          setID: article.setID,
          setName: values.name_es,
          langCode: "es",
          setDescriptions: values.description_es,
          industrySegment: industrySegments.find(
            (industrySegment) =>
              industrySegment.industrySegmentsId === article.industrySegmentsId
          )?.nameES,
        },
      ];
      await articleService.createLangs({ body: setsLang, uid });
      enqueueSnackbar(t("Article successfully translated!"), {
        variant: "success",
        preventDuplicate: false,
      });
      articleStore.read();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Something went wrong! Please try again later."), {
        variant: "warning",
        preventDuplicate: false,
      });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  }

  React.useEffect(() => {
    if (!open) {
      return;
    }
    (async function () {
      try {
        const {
          result: industrySegments,
        } = await industrySegmentService.getAllData();
        setIndustrySegments(industrySegments);
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (!article) {
      setInitialValues(undefined);
      return;
    }

    const initialValues: Article.TranslateForm.Values = {
      name_de: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "de") {
          return name + lang.setName;
        }
        return name;
      }, ""),
      name_es: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "es") {
          return name + lang.setName;
        }
        return name;
      }, ""),
      name_fr: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "fr") {
          return name + lang.setName;
        }
        return name;
      }, ""),
      name_it: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "it") {
          return name + lang.setName;
        }
        return name;
      }, ""),
      description_de: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "de") {
          return name + lang.setDescriptions;
        }
        return name;
      }, ""),
      description_es: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "es") {
          return name + lang.setDescriptions;
        }
        return name;
      }, ""),
      description_fr: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "fr") {
          return name + lang.setDescriptions;
        }
        return name;
      }, ""),
      description_it: article?.setsLang?.reduce((name: string, lang) => {
        if (lang.langCode.toLowerCase() === "it") {
          return name + lang.setDescriptions;
        }
        return name;
      }, ""),
    };
    setInitialValues(initialValues);
  }, [article]);

  return (
    <>
      <Formik.Formik
        enableReinitialize
        initialValues={initialValues || ({} as Article.TranslateForm.Values)}
        validationSchema={Article.Shared({ t }).Translate.Form.validationSchema}
        onSubmit={handleTranslate}
      >
        {({ isSubmitting, submitForm }) => {
        
          return (
          
          <Components.LightBox
            open={open}
            handleClose={handleClose}
            content={
              <>
                {article && (
                  <>
                  
                    <Article.TranslateForm
                      name={article?.setName}
                      description={article?.setDescriptions}
                    />
                  </>
                )}
              </>
            }
            title={
              <Mui.Typography
                style={{ fontWeight: "bold" }}
                variant="h6"
                className={classes.title}
              >
                {t("Translate article name & description")}
              </Mui.Typography>
            }
            actions={
              <>
                <Mui.Button variant="contained" onClick={handleClose}>
                  <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
                </Mui.Button>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => submitForm()}
                >
                  <ReactI18next.Trans i18nKey="Save / Update">
                    Save / Update
                  </ReactI18next.Trans>
                  {isSubmitting && (
                    <Mui.CircularProgress
                      style={{
                        marginLeft: theme.spacing(1),
                      }}
                      color={"inherit"}
                      size={theme.typography.button.fontSize}
                    />
                  )}
                </Mui.Button>
              </>
            }
          />
          )}}
      </Formik.Formik>
    </>
  );
});
