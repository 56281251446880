import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as React from "react";
import * as ReactI18next from "react-i18next";

export declare namespace TranslateForm {
  export interface Values {
    name_de: string;
    name_fr: string;
    name_it: string;
    name_es: string;
    description_de: string;
    description_fr: string;
    description_it: string;
    description_es: string;
  }

  export interface Props {
    name: string;
    description: string;
  }
}

// eslint-disable-next-line no-redeclare
export function TranslateForm({ name, description }: TranslateForm.Props) {
  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  return (
    <>
      <Formik.Form>
        <Mui.Grid container spacing={4}>
          <Mui.Grid item xs={12}>
            <Mui.Typography variant="body2" color="textSecondary">
              <ReactI18next.Trans i18nKey="Article name">
                Article name
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography gutterBottom>{name}</Mui.Typography>
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_de"
              component={FormikMui.TextField}
              label={t("Article name DE")}
              fullWidth
              autoFocus
              style={{ marginBottom: theme.spacing(2) }}
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_fr"
              component={FormikMui.TextField}
              label={t("Article name FR")}
              fullWidth
              style={{ marginBottom: theme.spacing(2) }}
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_it"
              component={FormikMui.TextField}
              label={t("Article name IT")}
              fullWidth
              style={{ marginBottom: theme.spacing(2) }}
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="name_es"
              component={FormikMui.TextField}
              label={t("Article name ES")}
              fullWidth
              style={{ marginBottom: theme.spacing(2) }}
            />
          </Mui.Grid>

          <Mui.Grid item xs={12}>
            <Mui.Typography variant="body2" color="textSecondary">
              <ReactI18next.Trans i18nKey="Article description">
                Article description
              </ReactI18next.Trans>
            </Mui.Typography>
            <Mui.Typography gutterBottom>{description}</Mui.Typography>
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="description_de"
              component={FormikMui.TextField}
              label={t("Article description DE")}
              multiline
              rows={3}
              rowsMax={5}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="description_fr"
              multiline
              rows={3}
              rowsMax={5}
              component={FormikMui.TextField}
              label={t("Article description FR")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="description_it"
              multiline
              rows={3}
              rowsMax={5}
              component={FormikMui.TextField}
              label={t("Article description IT")}
              fullWidth
            />
          </Mui.Grid>

          <Mui.Grid item xs={12} md={6} lg={4}>
            <Formik.Field
              type="text"
              name="description_es"
              multiline
              rows={3}
              rowsMax={5}
              component={FormikMui.TextField}
              label={t("Article description ES")}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
