/** @format */

import * as Mui from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import * as MuiIcons from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FlagIcon from "@material-ui/icons/Flag";
import InfoIcon from "@material-ui/icons/Info";
import * as Notistack from "notistack";
import * as React from "react";
import * as Models from "src/models";
import * as ReactI18next from "react-i18next";
export declare namespace Card {
  export interface Props {
    dealer: Models.ImportDealer.Main;
    selected: number | string;
    handleClick?(props: { DealerProfileID: number | string }): void;
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: "white",
    backgroundColor: "grey",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  green: {
    backgroundColor: "green",
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      root: {
        "& $avatarColorPrimary": {
          color: "lightgrey",
        },
      },
    },
  },
});

export function Card({
  dealer,
  selected,
  handleClick,
}: 
Card.Props) {
 
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const classes = useStyles();
  const stopnavigationHandle = (event: any) => {
    event.stopPropagation();
  };
  const CardInternals = (
    <>
      <Mui.Card
        style={{ maxHeight: "260vh" }}
        {...(selected === dealer.dealerProfileID && {
          elevation: selected ? 11 : undefined,
          style: {
            minHeight: "200px",
            height: "100%",
            border: selected
              ? `2px solid ${theme.palette.success.main}`
              : "inherit",
          },
        })}
      >
        <Mui.CardHeader
          style={{
            minHeight: "70px",
            paddingTop: "7px",
            padding: "5px",
            margin: "3px",
            paddingBottom: "1px",
          }}
          title={
            <Mui.ListItem component="div" style={{ padding: 0, margin: 0 }}>
             

              <MuiIcons.Business
                style={{
               
                  width: "35px",
                  height: "35px",
                  color: "grey",
                  marginRight: "4px",
                
                }}
              />

            
              <Mui.ListItemText
                primary={
                  <Mui.Typography
                    style={{ fontWeight: "bold", fontSize: "20px" }}
                  >
                    {dealer.companyName}
                  </Mui.Typography>
                }
                secondary={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                     

                      <Mui.Typography
                        style={{ color: "black", fontSize: "14px" }}
                      >
                       
                      </Mui.Typography>
                    </div>
                  </>
                }
              />
             

              {dealer.status === 0 ? (
                <Mui.Chip
                  icon={
                    <InfoIcon
                      style={{
                        color: theme.palette.info.main,
                      }}
                    />
                  }
                  label={"Yet to import"}
                ></Mui.Chip>
              ) : (
                <Mui.Chip
                  icon={
                    <CheckCircleIcon
                      style={{ color: theme.palette.success.main }}
                    />
                  }
                  label={"Imported"}
                ></Mui.Chip>
              )}
            </Mui.ListItem>
          }
      
        />
        <Mui.CardContent
          style={{
            minHeight: "170px",
            padding: 6,
            paddingTop: 2,
            margin: 6,
            marginTop: 2,
          }}
        >
          <Mui.Divider />
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                marginTop: "3px",
              }}
            >
              <Mui.Typography color="textSecondary">
              <ReactI18next.Trans i18nKey={"Dealer name"}>
              Dealer name
                </ReactI18next.Trans>:
              </Mui.Typography>
              <Mui.Typography style={{ fontSize: "14px", marginLeft: "6px" }}>
                &nbsp;{dealer.firstName}
              </Mui.Typography>
              {dealer.userType === "P" ? (
                <Mui.Tooltip title={
                  <ReactI18next.Trans i18nKey={"Primary user"}>
                    Primary user
                  </ReactI18next.Trans>}>
                  <FlagIcon
                    style={{
                      color: theme.palette.success.main,
                      opacity: "0.7",
                      marginLeft: "20px",
                    }}
                  />
                </Mui.Tooltip>
              ) : (
                ""
              )}
            </div>
          </>
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "4px",
              }}
            >
              {dealer.userType === "P" ? (
                <Mui.Typography color="textSecondary"><ReactI18next.Trans i18nKey={"User"}>
                User
                  </ReactI18next.Trans>:</Mui.Typography>
              ) : (
                ""
              )}
              <Mui.Typography style={{ fontSize: "14px" }}>
                &nbsp;
                {dealer.userType === "P" ? (
                  <>
                    <Mui.Chip
                      style={{
                        marginLeft: "18px",
                        marginRight: "18px",
                        marginBottom: "3px",
                        paddingRight: "3px",
                        fontWeight: "bold",
                      }}
                      size="small"
                      label="Primary user"
                      avatar={
                        <Mui.Avatar
                          style={{ color: "white" }}
                          className={classes.green}
                        >
                          {dealer.primaryUser}
                        </Mui.Avatar>
                      }
                    ></Mui.Chip>
                    <Mui.Chip
                      style={{
                        marginLeft: "8px",
                        marginBottom: "3px",
                        paddingRight: "3px",
                        fontWeight: "bold",
                      }}
                      size="small"
                      label="Secondary user"
                      avatar={
                        <Mui.Avatar
                          style={{ color: "white", fontWeight: 1000 }}
                          className={classes.orange}
                        >
                          {dealer.secondaryUser}
                        </Mui.Avatar>
                      }
                    ></Mui.Chip>
                  </>
                ) : (
                  ""
                )}
              </Mui.Typography>
            </div>
          </>

          <Mui.Divider />
          <Mui.Grid
            container
            spacing={2}
            direction={"row"}
            style={{ marginTop: "2px" }}
          >
            <Mui.Grid
              item
              xs={8}
              style={{ fontWeight: "bold", paddingTop: "2px" }}
            >
              <Mui.Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginBottom: "4px",
                  }}
                >
                  <Mui.Typography style={{ fontWeight: "bold" }}>
                  <ReactI18next.Trans i18nKey={"Contact information"}>
                  Contact information
                  </ReactI18next.Trans>
                  </Mui.Typography>
                </div>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "4px",
                    }}
                  >
                    <Mui.Typography
                      color="textSecondary"
                      style={{ marginRight: "4px" }}
                    >
                      {" "}
                      <ReactI18next.Trans i18nKey={"Email ID"}>
                      Email ID
                     </ReactI18next.Trans>:
                      {/* {"Email ID:"} */}
                    </Mui.Typography>
                    <Mui.Typography style={{ fontSize: "14px" }}
                    onClick={()=>{window.open(`mailto:${dealer.email}`)}}>
                      &nbsp;                      <Mui.Tooltip title={<ReactI18next.Trans i18nKey="click to open an mail">
                      click to open an mail
                </ReactI18next.Trans>}><Mui.Link style={{color:"black"}}>{dealer.email}</Mui.Link></Mui.Tooltip>

                    </Mui.Typography>
                  </div>
                </>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "6px",
                    }}
                  >
                    <Mui.Typography
                      color="textSecondary"
                      style={{ marginRight: "4px" }}
                    >
                      {" "}
                      <ReactI18next.Trans i18nKey={"Phone no"}>
                      Phone no
                     </ReactI18next.Trans>:
                      {/* {"Phone number:"} */}
                    </Mui.Typography>
                    <Mui.Typography
                      style={{ fontSize: "14px", marginRight: "60px" }}
                    >
                      &nbsp;{dealer.phoneNumber}
                    </Mui.Typography>
                    
                  </div>
                </>
                
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Divider orientation="vertical" flexItem />
           
            <>
              <Mui.Grid>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "2px",
                      marginLeft: "4px",
                    }}
                  >
                    <Mui.Typography color="textSecondary">
                      {" "}
                      <ReactI18next.Trans i18nKey={"Country"}>
                      Country
                     </ReactI18next.Trans>
                    
                    </Mui.Typography>
                  </div>
                </>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "2px",
                      marginLeft: "4px",
                    }}
                  >
                    <Mui.Typography style={{ fontSize: "14px" }}>
                      {dealer.countryName}
                    </Mui.Typography>
                  </div>
                </>
       
              </Mui.Grid>
        
            </>
           
          </Mui.Grid>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );

  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea
          onClick={() =>
            handleClick({ DealerProfileID: dealer.dealerProfileID })
          }
        >
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        { CardInternals }
      )}
    </Mui.Card>
  );
}
function t(arg0: string): Notistack.SnackbarMessage {
  throw new Error("Function not implemented.");
}
