/** @format */

import * as Mui from "@material-ui/core";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Hooks from "src/app/hooks";
import * as Configs from "src/configs";
import * as Models from "src/models";
import * as Notistack from "notistack";
import { IndustrySegment } from "src/services";

export declare namespace Form {
  export interface Props {
    availableArticles: Models.Set.Article[];
    generateFlag(props: boolean): void;
  }

  export interface Values {
    macId: string;
    customerName?: string;
    location?: string;
    remarks?: string;
    eT_version?: number;
    deviceName?: string;
    licenseType?: number;
  }
}

function CustomTextField({
  availableArticles,
  generateFlag,
  ...props
}: FormikMui.TextFieldProps & Form.Props) {
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  const tenantStore = React.useContext(App.Contexts.tenantStore);

  const [searchParams] = Router.useSearchParams();
  const forceUpdate = Hooks.useForceUpdate();
  const navigate = Router.useNavigate();
  const userStore = React.useContext(App.Contexts.userStore);
  const { enqueueSnackbar } = Notistack.useSnackbar();

  const {
    // form: { setFieldTouched, setFieldValue },
    form: { setFieldTouched },
    field: { name },
  } = props;

  const onBlur = React.useCallback(
    async (event) => {
      const sets = searchParams.getAll("set");

      if (sets.length) {
        return;
      }

      const { value } = event.target;
      setFieldTouched(name);
      if (value) {
        const result = await generateLicenseStore.fetchWithMachineId({
          machineId: value,
          availableArticles,
        });
        if(result?.license?.macId !== undefined){
          if(result?.license?.createdby === userStore.user?.ETUID){
        var newurl = `/easy-touch-devices/generate?macID=${result?.license?.macId}&deviceName=${result?.license?.deviceName}&location=${
          result?.license?.location
        }`;
      
        navigate(newurl);
      }else{
       var macQuery =  searchParams.get("macId") === "" ? null : ""
        var pageUrl = `/easy-touch-devices/generate?gnot=${"true"}`;
        navigate(pageUrl);
        enqueueSnackbar(
          "Entered mac-Id already assigned to other account, please use the different one",
          {
            variant: "error",
          }
        );
        
      }
      }else{
       
        var newurls = `/easy-touch-devices/generate`
        navigate(newurls);
      }

        (function () {
          result?.license?.sets.forEach((set) => {
            const article = result?.availableArticlesMap.get(set);

            if (!article) {
              return;
            }

            if (
              Configs.Workaround.isCloudArticle({
                code: article.hundCode,
              }) ||
              Configs.Workaround.isCloudArticle({ code: article.setCode })
            ) {
              generateLicenseStore.setCloudArticle({ cloudArticle: article });
            }
          });
        })();

        (function () {
          if (result?.license?.tenantId) {
            const tenantId = result?.license.tenantId;

            const tenant = tenantStore.tenants.find(
              (tenant) => tenant.id === tenantId
            );

            if (tenant) {
              generateLicenseStore.setSelectedTenant({
                selectedTenant: tenant,
              });
              forceUpdate();
              return;
            }

            tenantStore.read().then(() => {
              const tenant = tenantStore.tenants.find(
                (tenant) => tenant.id === tenantId
              );

              generateLicenseStore.setSelectedTenant({
                selectedTenant: tenant,
              });
              forceUpdate();
            });
          }
        })();
      }
    },
    [availableArticles, enqueueSnackbar, forceUpdate, generateLicenseStore, name, navigate, searchParams, setFieldTouched, tenantStore, userStore.user]
  );

  return (
    <Mui.TextField {...FormikMui.fieldToTextField(props)} onBlur={onBlur} />
  );
}
const CustomizedSelectForFormik = ({
  children,
  form,
  field,
  isVersion
}: {
  children: any;
  form: any;
  field: any;
  isVersion:boolean;
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const [values, setValues] = React.useState<any>(2);
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  return (
    <Mui.Select
      name={name}
      value={values}
      onChange={(e) => {

        if(!isVersion){
          generateLicenseStore.setIndustrySegmentID(e.target.value);
          const industryID : any = e.target.value;
          localStorage.setItem("industryID", industryID);
        }
        setFieldValue(name, e.target.value);
        setValues(e.target.value);
      }}
      placeholder={name}
      fullWidth
    >
      {children}
    </Mui.Select>
  );
};
// eslint-disable-next-line no-redeclare
export function Form({ availableArticles, generateFlag }: Form.Props) {
  const { t } = ReactI18next.useTranslation();
  const userStore = React.useContext(App.Contexts.userStore);
  const generateStore = React.useContext(App.Contexts.license.generateStore);
  const userRole = userStore.user?.RoleName;
  const [searchParams] = Router.useSearchParams();
  const industrySegmentStore = React.useContext(
    App.Contexts.industrySegmentStore
  );
  React.useEffect(() => {
    industrySegmentStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  React.useEffect(() => {
    const localStore = localStorage.getItem("industryID");
    console.log("localStore",localStore)

   // setShowVersion(!!(industrySegmentID && industrySegmentID.toString() === localStore));   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateLicenseStore.industrySegmentId]);
  return (
    <>
    
      <Formik.Form>
       
        <Mui.Grid container spacing={6}>
        <Mui.Grid item xs={3} style={{marginTop:"18px"}}>
            <Formik.Field
              name="industrySegments"
              component={CustomizedSelectForFormik}
              label="Select the Industry/Segments"
            >
              <Mui.MenuItem value={0} disabled>
                Select the Industry/Segments
              </Mui.MenuItem>
             { industrySegmentStore?.filtered.map((k:any,index)=>
             (
              <Mui.MenuItem key = {k.industrySegmentsId} value={k.industrySegmentsId}>{k.name}</Mui.MenuItem>
             ))}
              
            </Formik.Field>
          </Mui.Grid>
          <Mui.Grid item xs={3}>
            <Formik.Field
              type="text"
              name="macId"
              disabled={userRole === "customer" && searchParams.get("macId") !== null}
              component={CustomTextField}
              availableArticles={availableArticles}
              label={t("Machine ID")}
              fullWidth
            ></Formik.Field>
          </Mui.Grid>
          
             {(userRole === "customer" || userRole === "dealer") && <Mui.Grid item xs={3}>
            <Formik.Field
            InputLabelProps={{ shrink: true }}
              type="text"
              name="deviceName"
              component={FormikMui.TextField}
              label={t("Device name")}
              fullWidth
              
            ></Formik.Field>
          </Mui.Grid>}
          <Mui.Grid item xs={3}>
            <Formik.Field
            InputLabelProps={{ shrink: true }}
              type="text"
              name="location"
              component={FormikMui.TextField}
              label={t("Location")}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
          <Mui.Grid container >
          {(userRole !== "customer" && userRole !== "dealer") && <Mui.Grid item xs={12}>
            <Formik.Field
              type="text"
              name="customerName"
              component={FormikMui.TextField}
              label={t("Customer name")}
              fullWidth
            />
          </Mui.Grid>}
        </Mui.Grid>
      </Formik.Form>
    </>
  );
}
