import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Models from "src/models";
import * as Components from "src/app/components";

export declare namespace Card {
  export interface Props {
    article: Models.Set.Article;
    handleClick?(props: { article: Models.Set.Article }): void;
  }
}

// eslint-disable-next-line no-redeclare
export function Card({ article, handleClick }: Card.Props) {
  const CardInternals = (
    <>
      <Mui.CardHeader
        title={
          <>
            <Mui.Typography variant="body2" color="textSecondary">
              Name
            </Mui.Typography>
            <Mui.Typography>{article.setName}</Mui.Typography>
          </>
        }
        action={
          <Components.WorkingStatus
            variant="SHORT"
            status={article.status as 1 | 2}
          />
        }
      />
      <Mui.CardContent>
        <Mui.Typography variant="body2" color="textSecondary">
          Code
        </Mui.Typography>
        <Mui.Typography>{article.setCode}</Mui.Typography>
      </Mui.CardContent>
    </>
  );
  return (
    <Mui.Card>
      {handleClick ? (
        <Mui.CardActionArea onClick={() => handleClick({ article })}>
          {CardInternals}
        </Mui.CardActionArea>
      ) : (
        CardInternals
      )}
    </Mui.Card>
  );
}
