/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD";

  export interface IStore {
    isLoading: boolean;
    assignedLicenseDetail: Models.AssignedLicenseDetail[];
    view: Store.View;
    licenseService: Services.License;
  }

  export type Options = Pick<IStore, "licenseService">;

  export namespace SetView {
    export interface Props extends Pick<Store.IStore, "view"> {}
  }

  export namespace Read {
    export interface Props
      extends Services.License.GetCustomerAssignedLicense.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public view: Store.View = "TABULAR";
  @Mobx.observable
  public assignedLicenseDetail: Models.AssignedLicenseDetail[] = [];
  @Mobx.observable public isLoading: boolean = false;

  public licenseService: Services.License;

  constructor({ licenseService }: Store.Options) {
    this.licenseService = licenseService;
  }

  @Mobx.action setView({ view }: Store.SetView.Props) {
    this.view = view;
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.isLoading = true;
      const { result } = await this.licenseService.GetCustomerAssignedLicense(
        props
      );

      this.assignedLicenseDetail = result;
      if (this.assignedLicenseDetail.length > 0) {
        this.assignedLicenseDetail = this.assignedLicenseDetail.map((e) => ({
          ...e,
          index: Math.floor(Math.random() * 10000) + 1,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.assignedLicenseDetail.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
