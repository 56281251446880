import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Function() {
  return (
    <>
      <Router.Routes>
        <Routes.Route restricted
        roles={["superadmin","admin"]}
         path="/" element={<Pages.Function.Main />} />
      </Router.Routes>
    </>
  );
}
