import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Main() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          roles={["customer", "dealer"]}
          path="/"
          element={<Pages.Shop.Main />}
        />
        <Routes.Route
          restricted
          roles={["customer", "dealer"]}
          path="cart"
          element={<Pages.Shop.Main />}
        />
        <Routes.Route
          restricted
          roles={["customer", "dealer"]}
          path="checkout"
          element={<Pages.Shop.Main />}
        />
        <Router.Route path="payment/*" element={<Routes.Shop.Payment />} />
      </Router.Routes>
    </>
  );
}
