/** @format */

import * as Mui from "@material-ui/core";
// import * as MuiIcons from "@material-ui/icons";
import Numeral from "numeral";
import * as React from "react";
// import { v4 as uuidv4 } from "uuid";
import * as ReactI18next from "react-i18next";
// import * as Containers from "src/app/containers";
import * as Components from "src/app/components";
import * as Configs from "src/configs";
import * as Models from "src/models";

const useStyles = Mui.makeStyles((theme) => ({
  list: {
    width: 380,
  },
  title: {
    flexGrow: 1,
  },
  contentwidth: {
    width: theme.breakpoints.values.md,
  },
  contentEle: {
    padding: 8,
    marginBottom: 12,
  },
}));

export declare namespace Details {
  export interface Props {
    open: boolean;
    handleClose(): void;
    functionData: Models.Set.Function[];
    articlesData: Models.Set.Article[];
    article: Models.Set.Article;
    handleEditClick(props: { article: Models.Set.Article }): void;
    handleTranslateClick(props: { article: Models.Set.Article }): void;
    handleDeleteClick(props: { id: string | number }): void;
    handleActivateClick(props: { id: string | number }): void;
  }
}

// eslint-disable-next-line no-redeclare
export function Details({
  open,
  handleClose,
  article,
  functionData,
  articlesData,
  handleEditClick,
  handleDeleteClick,
  handleActivateClick,
  handleTranslateClick,
}: Details.Props) {
  const classes = useStyles();
  const theme = Mui.useTheme();

  return (
    <Components.LightBox
      handleClose={handleClose}
      open={open}
      content={
        <>
          <Mui.Grid container spacing={4}>
            <Mui.Grid item xs={12} md={6} lg={3}>
              <Mui.Grid container spacing={4}>
                <Mui.Grid item xs={12}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Article name"}>
                      Article name
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.setName}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={12}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Article description"}>
                      Article description
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.setDescriptions}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6} lg={9}>
              <Mui.Grid container spacing={4}>
                <Mui.Grid item xs={12} md={6} lg={4}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Article code (Type code)"}>
                      Article code (Type code)
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.setCode}
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={6} lg={4}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Model number"}>
                      Model number
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.hundCode}
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={6} lg={4}>
                  <Mui.Typography color="textSecondary">
                    {Configs.Workaround.isCloudArticle({
                      code: article?.setCode ?? "",
                    }) ||
                    Configs.Workaround.isCloudArticle({
                      code: article?.hundCode ?? "",
                    }) ? (
                      <ReactI18next.Trans
                        i18nKey={"Price (net) per month in €"}
                      >
                        Price (net) per month in €
                      </ReactI18next.Trans>
                    ) : (
                      <ReactI18next.Trans i18nKey={"Price (net) per item in €"}>
                        Price (net) per item in €
                      </ReactI18next.Trans>
                    )}
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {Numeral(article?.setCost).format("$ 0,.00a")}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={12} md={6} lg={4}>
                  <Mui.Typography color="textSecondary">
                    {Configs.Workaround.isCloudArticle({
                      code: article?.setCode ?? "",
                    }) ||
                    Configs.Workaround.isCloudArticle({
                      code: article?.hundCode ?? "",
                    }) ? (
                      <ReactI18next.Trans i18nKey={"Users per article"}>
                        Users per article
                      </ReactI18next.Trans>
                    ) : (
                      <ReactI18next.Trans i18nKey={"Licenses per article"}>
                        Licenses per article
                      </ReactI18next.Trans>
                    )}
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.isLimited === false ? "Unlimited" : article?.licensePerSet}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={12} md={6} lg={4}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Icon name"}>
                      Icon name
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.iconClass}
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Link for more information"}>
                      Link for more information
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {article?.moreInfo}
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Select functions"}>
                      Select functions
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {functionData
                      .filter((func) =>
                        article?.functions?.includes(func.setFunctionID)
                      )
                      .map((func) => func.funcationName)
                      .join(", ")}
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12}>
                  <Mui.Typography color="textSecondary">
                    <ReactI18next.Trans i18nKey={"Dependent articles"}>
                      Dependent articles
                    </ReactI18next.Trans>
                  </Mui.Typography>
                  <Mui.Typography color="textPrimary">
                    {articlesData
                      .filter((articleData) =>
                        article?.dependentSets?.includes(articleData.setID)
                      )
                      .map((articleData) => articleData.setName)
                      .join(", ") || "-"}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </>
      }
      title={
        <Mui.Typography
          style={{ fontWeight: "bold" }}
          variant="h6"
          className={classes.title}
        >
          {article?.setName}
        </Mui.Typography>
      }
      actions={
        <>
          <Mui.Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {article?.status === 1 ? (
              <Mui.Button
                style={{ marginRight: theme.spacing(1) }}
                variant="contained"
                onClick={() => handleDeleteClick({ id: article.setID })}
              >
                <ReactI18next.Trans i18nKey="Delete">Delete</ReactI18next.Trans>
              </Mui.Button>
            ) : (
              <Mui.Button
                onClick={() => handleActivateClick({ id: article.setID })}
              >
                <ReactI18next.Trans i18nKey="Activate">
                  Activate
                </ReactI18next.Trans>
              </Mui.Button>
            )}

            <Mui.Box>
              <Mui.Button
                style={{ marginRight: theme.spacing(1) }}
                variant="contained"
                onClick={() => handleTranslateClick({ article })}
              >
                <ReactI18next.Trans i18nKey="Translate">
                  Translate
                </ReactI18next.Trans>
              </Mui.Button>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleEditClick({ article });
                  localStorage.setItem(
                    "articleIdFromEdit",
                    article.setID.toString()
                  );
                }}
              >
                <ReactI18next.Trans i18nKey="Edit">Edit</ReactI18next.Trans>
              </Mui.Button>
            </Mui.Box>
          </Mui.Grid>
        </>
      }
    />
  );
}
