import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function ManageDealer() {
  return (
    <>
      <Router.Routes>
        <Routes.Route
          restricted
          path="/"
          element={<Pages.ManageDealer.Main />}
        />
      </Router.Routes>
    </>
  );
}
