/** @format */

import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";
import * as AddCustomer from "src/app/pages/dealercustomer/addcustomer";
export const Layout = Mobx.observer(function () {
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const userStore = React.useContext(App.Contexts.userStore);

  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName ?? "customer";

    if (
      !licenseStore.licenses?.length &&
      userRole === "customer" &&
      uid !== undefined &&
      uid !== null
    )
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });

    if (!functionStore.functions.length) functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Mui.Grid item xs={12} md={6} lg={4} alignItems="center">
        <Mui.Card
          style={{
            height: "calc(100vh - 288px)",
            position: "absolute",
            alignItems: "center",
            marginLeft: "300px",
            width: "650px",
          }}
        >
          <Mui.CardContent>
            <AddCustomer.Form />
          </Mui.CardContent>
        </Mui.Card>
      </Mui.Grid>
    </>
  );
});
