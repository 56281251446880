import * as React from "react";
import * as Router from "react-router-dom";
import * as Pages from "src/app/pages";
import * as Routes from "src/app/routes";

export function Main() {
  return (
    <>
      <Router.Routes>
      <Routes.Route restricted path="/" element={<Pages.DealarCustomer.Main/>} />
        <Router.Route path="importdealer/*" element={<Routes.Dealer.ImportDealer />} />
        <Router.Route path="customerinvite/*" element={<Routes.Dealer.CustomerInvite />} />
        <Router.Route path="customerinvitesummary/*" element={<Routes.Dealer.CustomerInviteSummary />} />
        <Router.Route path="addcustomer/*" element={<Routes.Dealer.AddCustomer />} />
        <Router.Route path="adddealeruser/*" element={<Routes.Dealer.AddDealerUser />} />
        <Router.Route path="customerdetail/*" element={<Routes.Dealer.CustomerDetail/>} />
        <Router.Route path="managedealer/*" element={<Routes.Dealer.ManageDealer />} />
        <Router.Route path="manageadmin/*" element={<Routes.Dealer.ManageAdmin />} />
      </Router.Routes>
    </>
  );
}
