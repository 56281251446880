/** @format */

import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace DealerCustomerService {
  export interface IDealerCustomerService {
    urlFactory: Services.Factories.URL;
    httpManager: Services.Managers.HTTP.IHTTP;
  }

  export type Options = DealerCustomerService.IDealerCustomerService;

  export namespace GetAllCustomerData {
    export interface Body {
      customerName?: string;
      companyName: string;
      emailID: string;
    }
    export type Props = Models.API.Response<Models.DealerCustomer.Main[]>;

    export type Return = Models.API.Response<Models.DealerCustomer.Main[]>;
  }
  export namespace GetCustomerProfileData {
    export interface Body {
      status?: number;
      FromDate: string;
      ToDate: string;
    }

    export interface Props extends Pick<Models.User.Main, "uid"> {
      body?: DealerCustomerService.GetCustomerProfileData.Body;
      userRole: string;
      mailID: string;
      roleID: string;
    }

    export type Return = Models.API.Response<
      Models.CustomerProfileOnDealer.Main[]
    >;
  }

  export namespace GetAllData {
    export interface Body {
      status?: number;
      FromDate: string;
      ToDate: string;
    }

    export interface Props extends Pick<Models.User.Main, "uid"> {
      body?: DealerCustomerService.GetAllData.Body;
      userRole: string;
    }

    export type Return = Models.API.Response<Models.DealerCustomer.Main[]>;
  }

  export namespace GetData {
    export interface Body {
      id?: number | string;
      eid?: string;
    }

    export interface Props {
      body: DealerCustomerService.GetData.Body;
    }

    export type Return = Models.API.Response<Models.DealerCustomer.Main[]>;
  }

  export namespace GetGeneralTermsAndConditions {
    export interface Props {
      language: string;
    }

    export type Return = Models.API.Response<any>;
  }

  export namespace GetPrivacyPolicy {
    export type Props =
      DealerCustomerService.GetGeneralTermsAndConditions.Props;

    export type Return =
      DealerCustomerService.GetGeneralTermsAndConditions.Return;
  }

  export namespace Create {
    export type Body = Models.DealerCustomer.Creation;
    export interface Props {
      body: DealerCustomerService.Create.Body;
      customerDealerId: string | number;
    }

    export type Return = Models.API.Response<any>;
  }
  export namespace InviteCustomer {
    export type Return = Models.API.Response<any>;
    export type Body = Models.InviteCustomer.Main;

    export interface Props {
      body: DealerCustomerService.InviteCustomer.Body;
    }
  }

  export namespace CreateManualLicense {
    export interface Body {
      ETUID: number;
      CustomerName?: string;
      DeviceName: any;
      ActivationKey: string;
      ActivationAck: string;
      MacId?: string;
      Remarks?: string;
      LicenseList?: [];
      eT_version?: number;
      Createdby: number;
      LicenseKey: string;
      Location: string;
    }

    export interface Props
      extends Pick<
        DealerCustomerService.CreateManualLicense.Body,
        | "CustomerName"
        | "ETUID"
        | "DeviceName"
        | "ActivationKey"
        | "ActivationAck"
        | "MacId"
        | "Remarks"
        | "LicenseList"
        | "eT_version"
        | "Createdby"
        | "LicenseKey"
        | "Location"
      > {}

    export type Result = {
      customerName?: string;
      licenseKey: string;
      macId?: string;
      remarks?: string;
      createdby?: number;
    };

    export type Return =
      Models.API.Response<DealerCustomerService.CreateManualLicense.Result>;
  }
}

// eslint-disable-next-line no-redeclare
export class DealerCustomerService {
  private urlFactory: Services.Factories.URL;
  private httpManager: Services.Managers.HTTP.IHTTP;
  constructor({ urlFactory, httpManager }: DealerCustomerService.Options) {
    this.urlFactory = urlFactory;
    this.httpManager = httpManager;
  }

  public async getData({
    body,
  }: DealerCustomerService.GetData.Props): Promise<DealerCustomerService.GetData.Return> {
    const url = this.urlFactory.create({
      named: ["Order", "GetByOrderId"],
    });

    const response = await this.httpManager.post<
      DealerCustomerService.GetData.Return,
      DealerCustomerService.GetData.Body
    >({
      url,
      body: {
        ...body,
        eid: body.eid ?? "",
      },
    });

    return response;
  }
  public async getCustomer({
    body,
    uid,
    userRole,
  }: DealerCustomerService.GetAllData.Props): Promise<DealerCustomerService.GetAllCustomerData.Return> {
    const url =
      userRole === "customer"
        ? this.urlFactory.create({
            named: ["Customer", "GetCustomers", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["Customer", "GetCustomers", `${uid}`],
          });

    const response = await (userRole === "customer" ||
    userRole === "admin" ||
    userRole === "superadmin" ||
    userRole === "dealer"
      ? this.httpManager.get<DealerCustomerService.GetAllData.Return>({
          url,
        })
      : this.httpManager.post<
          DealerCustomerService.GetAllData.Return,
          DealerCustomerService.GetAllData.Body
        >({
          url,
          body: body ?? {
            status: 1,
            FromDate: `${new Date(
              new Date().setDate(new Date().getDate() - 100)
            ).toUTCString()}`,
            ToDate: `${new Date().toUTCString()}`,
          },
        }));

    return response;
  }

  public async getCustomerProfileWithLicense({
    body,
    uid,
    mailID,
    roleID,
    userRole,
  }: DealerCustomerService.GetCustomerProfileData.Props): Promise<DealerCustomerService.GetCustomerProfileData.Return> {
    const url =
      userRole === "dealer"
        ? this.urlFactory.create({
            named: [
              "Customer",
              "GetCustomerProfileWithLicense",
              `${uid}`,
              `${mailID}`,
              `${roleID}`,
            ],
          })
        : this.urlFactory.create({
            named: ["Customer", "GetCustomerProfileWithLicense", `${uid}`],
          });

    const response = await (userRole === "customer" ||
    userRole === "admin" ||
    userRole === "superadmin" ||
    userRole === "dealer"
      ? this.httpManager.get<DealerCustomerService.GetCustomerProfileData.Return>(
          {
            url,
          }
        )
      : this.httpManager.post<
          DealerCustomerService.GetCustomerProfileData.Return,
          DealerCustomerService.GetCustomerProfileData.Body
        >({
          url,
          body: body ?? {
            status: 1,
            FromDate: `${new Date(
              new Date().setDate(new Date().getDate() - 100)
            ).toUTCString()}`,
            ToDate: `${new Date().toUTCString()}`,
          },
        }));

    return response;
  }

  public async getAllData({
    body,
    uid,
    userRole,
  }: DealerCustomerService.GetAllData.Props): Promise<DealerCustomerService.GetAllData.Return> {
    const url =
      userRole === "customer"
        ? this.urlFactory.create({
            named: ["Order", "Get", `${uid}`],
          })
        : this.urlFactory.create({
            named: ["Order", "GetAll"],
          });

    const response = await (userRole === "customer"
      ? this.httpManager.get<DealerCustomerService.GetAllData.Return>({
          url,
        })
      : this.httpManager.post<
          DealerCustomerService.GetAllData.Return,
          DealerCustomerService.GetAllData.Body
        >({
          url,
          body: body ?? {
            status: 1,
            FromDate: `${new Date(
              new Date().setDate(new Date().getDate() - 100)
            ).toUTCString()}`,
            ToDate: `${new Date().toUTCString()}`,
          },
        }));

    return response;
  }

  public async create({
    body,
    customerDealerId,
  }: DealerCustomerService.Create.Props): Promise<DealerCustomerService.Create.Return> {
    // TODO: Fix this hardcoded dynamic url
    const url = this.urlFactory.create({
      named: ["Order", "Create", `${customerDealerId}`],
    });

    const response = await this.httpManager.post<
      DealerCustomerService.Create.Return,
      DealerCustomerService.Create.Body
    >({
      url,
      body,
    });

    return response;
  }

  public async inviteCustomer({
    body,
  }: DealerCustomerService.InviteCustomer.Props): Promise<DealerCustomerService.InviteCustomer.Return> {
    const url = this.urlFactory.create({
      named: ["Customer", "AddCustomer"],
    });

    const response = await this.httpManager.post<
      DealerCustomerService.InviteCustomer.Return,
      DealerCustomerService.InviteCustomer.Body
    >({
      body: body,
      url,
    });

    return response;
  }

  public async CreateManualLicense({
    ...restOfProps
  }: DealerCustomerService.CreateManualLicense.Props): Promise<DealerCustomerService.CreateManualLicense.Return> {
    const url = this.urlFactory.create({
      named: ["Customer", "CreateManualLicense"],
    });

    const {
      ETUID,
      CustomerName,
      DeviceName,
      ActivationKey,
      ActivationAck,
      MacId,
      Remarks,
      LicenseList,
      eT_version,
      Createdby,
      LicenseKey,
      Location,
    } = restOfProps;

    const response = await this.httpManager.post<
      DealerCustomerService.CreateManualLicense.Return,
      {
        ETUID: number;
        CustomerName?: string;
        DeviceName: string;
        ActivationKey: string;
        ActivationAck: string;
        MacId?: string;
        Remarks?: string;
        tenantId?: number;
        LicenseList?: any;
        eT_version?: number;
        Createdby: number;
        Location?: string;
        LicenseKey: string;
      }
    >({
      body: {
        ETUID: ETUID,
        CustomerName: CustomerName,
        DeviceName: DeviceName,
        ActivationKey: ActivationKey,
        ActivationAck: ActivationAck,
        MacId: MacId,
        Remarks: Remarks,
        LicenseList: LicenseList,
        eT_version: eT_version,
        Createdby: Createdby,
        Location: Location,
        LicenseKey: LicenseKey,
      },
      url,
    });

    return response;
  }
}
