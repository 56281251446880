import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "src/app";

export interface ShowIfAuthorisedProps {
  roles: string[];
  children?: JSX.Element | string | number;
}

export const ShowIfAuthorised = Mobx.observer(function ({
  roles,
  children,
}: ShowIfAuthorisedProps) {
  const userStore = React.useContext(App.Contexts.userStore);

  return (
    <>{userStore.user && roles.includes(userStore.user.RoleName) && children}</>
  );
});
