import * as Mui from "@material-ui/core";
import * as React from "react";

const useStyles = Mui.makeStyles((theme) => ({
  logoContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(0.5),
    justifyContent: "end",
    userSelect: "none",
  },
  logoFragmentContainer: {
    maxWidth: "fit-content",
    borderBottom: (props: Logo.Style.Color.Props) =>
      props.color
        ? `${theme.spacing(0.6)}px solid ${props.color}`
        : `${theme.spacing(0.6)}px solid ${theme.palette.primary.main}`,
  },
  logoFragmentText: {
    fontStyle: "italic",
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: 1,
    color: (props: Logo.Style.Color.Props) =>
      props.color || theme.palette.primary.main,
  },
}));

export declare namespace Logo {
  export interface Props {
    color?: string;
  }

  export namespace Style {
    export type Props = Logo.Style.Color.Props;
    export namespace Color {
      export type Props = Logo.Props;
    }
  }
}

// eslint-disable-next-line no-redeclare
export function Logo(props: Logo.Props) {
  const classes = useStyles(props as Logo.Style.Props);
  return (
    <>
      <Mui.Box className={classes.logoContainer}>
        <Mui.Box className={classes.logoFragmentContainer}>
          <Mui.Typography className={classes.logoFragmentText} variant="h4">
            KE
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box className={classes.logoFragmentContainer}>
          <Mui.Typography className={classes.logoFragmentText} variant="h4">
            RN
          </Mui.Typography>
        </Mui.Box>
      </Mui.Box>
    </>
  );
}
