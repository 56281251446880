import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Models from "src/models";
import { v4 as uuidv4 } from "uuid";
const useStyles = Mui.makeStyles((theme) => ({
  list: {
    width: 380,
  },
  title: {
    flexGrow: 1,
  },
}));

export declare namespace Details {
  export interface Props {
    open: boolean;
    handleClose(): void;
    functionData: Models.Set.Function[];
    license: any;
  }
}

// eslint-disable-next-line no-redeclare
export const Details = Mobx.observer(function ({
  open,
  handleClose,
  license,
  functionData,
}: Details.Props) {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const classes = useStyles();
  const theme = Mui.useTheme();
  const navigate = Router.useNavigate();
  const userStore = React.useContext(App.Contexts.userStore);
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const toggleShowMore = () => setShowMore(!showMore);
  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole =
      userStore.user?.RoleName ?? ("customer" || "EU" || "admin" || "superadmin");
    if (
      (userRole === "customer" || userRole === "EU" || userRole === "admin" || userRole === "superadmin") &&
      uid !== undefined &&
      uid !== null
    ) {
      licenseStore.read({
        body: { status: null, userID: uid },
        userRole,
        uid,
      });
    }

    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (open) setShowMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Components.LightBox
      handleClose={handleClose}
      open={open}
      title={
        <>
          <Containers.ShowIfAuthorised roles={["customer", "EU"]}>
            <Mui.Typography
              style={{ fontWeight: "bold" }}
              variant="body1"
              className={classes.title}>
              {license?.hundCode} - {license?.setName}
            </Mui.Typography>
          </Containers.ShowIfAuthorised>

          <Containers.ShowIfAuthorised roles={["superadmin","admin", "dealer"]}>
            <Mui.Typography
              style={{ fontWeight: "bold" }}
              variant="body1"
              className={classes.title}>
              {license?.setCode} - {license?.setName}
            </Mui.Typography>
          </Containers.ShowIfAuthorised>
        </>
      }
      content={
        <>
          <Mui.Grid container spacing={4}>
            <Mui.Grid item xs={12} md={6} lg={3}>
              <Mui.Typography variant="body2" color="textSecondary">
                <ReactI18next.Trans i18nKey="License serial number">
                License serial number(s)
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography variant="body2" color="textPrimary">
                {license?.licenseCodes?.length > 0 && !showMore
                  ? license?.licenseCodes[0]
                  : license?.licenseCodes?.join(", ")}{" "}
                {license?.licenseCodes?.length > 1 && !showMore && (
                  <Mui.Link
                    style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                    onClick={toggleShowMore}
                    component="span">
                    Show more
                  </Mui.Link>
                )}
                {license?.licenseCodes?.length > 1 && showMore && (
                  <Mui.Link
                    style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                    onClick={toggleShowMore}
                    component="span">
                    Show less
                  </Mui.Link>
                )}
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6} lg={3}>
              <Mui.Typography variant="body2" color="textSecondary">
                <ReactI18next.Trans i18nKey="Total installations">
                  Total installations
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography variant="body2" color="textPrimary">
                {license?.total}
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6} lg={3}>
              <Mui.Typography variant="body2" color="textSecondary">
                <ReactI18next.Trans i18nKey="Used installations">
                  Used installations
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography variant="body2" color="textPrimary">
                {license?.totalUsed}
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6} lg={3}>
              <Mui.Typography variant="body2" color="textSecondary">
                <ReactI18next.Trans i18nKey="Available installations">
                  Available installations
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Typography variant="body2" color="textPrimary">
                {license?.totalAvailable}
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={12} lg={3}>
              <Mui.Typography variant="body2" color="textSecondary">
                <ReactI18next.Trans i18nKey="Available functions">
                  Available functions
                </ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Box marginTop={3} marginRight={2}>
                <Components.FunctionCountIcons functions={functionData} />
              </Mui.Box>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={6} lg={3}>
              <Mui.Typography variant="body2" color="textSecondary">
                <ReactI18next.Trans i18nKey="Status">Status</ReactI18next.Trans>
              </Mui.Typography>
              <Mui.Box marginTop={0.5}>
                <Components.WorkingStatus
                  status={license?.status}
                  labels={{ 1: "Available", 2: "Un Available" }}
                />
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>

          {license?.devices?.length > 0 && (
            <>
              <Mui.Grid container spacing={4}>
                <Mui.Grid item xs>
                  <Mui.CardHeader
                    style={{ padding: 0 }}
                    title={
                      <Mui.Typography
                        style={{ fontWeight: "bold" }}
                        variant="body1">
                        <ReactI18next.Trans i18nKey="List of devices">
                          List of devices
                        </ReactI18next.Trans>
                      </Mui.Typography>
                    }
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container spacing={3}>
                {license?.devices?.map((device: any) => (
                  <Mui.Grid item xs={12} md={6} lg={4} key={uuidv4()}>
                    <Mui.Card
                      style={{
                        backgroundColor: theme.palette.background.default,
                      }}>
                      <Mui.CardActionArea
                        onClick={() =>
                          navigate(
                            `/easy-touch-devices?macId=${device?.macID}${
                              (userStore.user?.RoleName === "admin" || userStore.user?.RoleName === "superadmin")
                                ? `&customer=${licenseStore.customer}`
                                : ""
                            }`
                          )
                        }>
                        <Mui.CardContent>
                          <Mui.Typography variant="body2" color="textSecondary">
                            <ReactI18next.Trans i18nKey="MAC ID (PC, tablet):">
                              MAC ID (PC, tablet):
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography
                            style={{ textTransform: "capitalize" }}
                            variant="body2"
                            color="textPrimary">
                            {device?.macID}
                          </Mui.Typography>
                          <br />
                          <Mui.Typography variant="body2" color="textSecondary">
                            <ReactI18next.Trans i18nKey="Device name">
                              Device name
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography
                            style={{ textTransform: "capitalize" }}
                            variant="body2"
                            color="textPrimary">
                            {device?.deviceName}
                          </Mui.Typography>
                          <br />
                          <Mui.Typography variant="body2" color="textSecondary">
                            <ReactI18next.Trans i18nKey="License serial number">
                            License serial number
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography variant="body2" color="textPrimary">
                            {device?.serialNumber}
                          </Mui.Typography>
                          <br />
                          <Mui.Typography variant="body2" color="textSecondary">
                            <ReactI18next.Trans i18nKey="Customer device id">
                              Customer device id
                            </ReactI18next.Trans>
                          </Mui.Typography>
                          <Mui.Typography variant="body2" color="textPrimary">
                            {device?.customerDeviceID}
                          </Mui.Typography>
                        </Mui.CardContent>
                      </Mui.CardActionArea>
                    </Mui.Card>
                  </Mui.Grid>
                ))}
              </Mui.Grid>
            </>
          )}
        </>
      }
      actions={
        <>
          <Mui.Button variant="contained" onClick={handleClose}>
            <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
          </Mui.Button>
        </>
      }
    />
  );
});
