import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as App from "src/app";
import * as Tenant from "src/app/pages/tenant";

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

function getSteps() {
  return ["Create Account", "Admin Account", "Summary"];
}

export const Create = Mobx.observer(function () {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const tenantStore = React.useContext(App.Contexts.tenantStore);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <Tenant.CompanyForm
            handleContinue={handleNext}
            activeStep={activeStep}
          />
        );
      case 1:
        return <Tenant.AdminAccountForm handleContinue={handleNext} />;
      case 2:
        return <Tenant.Summary />;
      default:
        return "Unknown stepIndex";
    }
  }

  React.useEffect(() => {
    tenantStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Mui.Card style={{ width: "100%" }}>
        <Mui.CardHeader
          title={
            <Mui.Typography color="textSecondary" variant="h6">
              <ReactI18next.Trans i18nKey="Welcome to Easy Touch tenant setup">
              Welcome to Easy Touch tenant setup
                      </ReactI18next.Trans>
            </Mui.Typography>
          }
        />
        <Mui.Divider />
        <Mui.CardContent>
          <div className={classes.root}>
            <Mui.Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Mui.Step key={label}>
                  <Mui.StepLabel>{label}</Mui.StepLabel>
                </Mui.Step>
              ))}
            </Mui.Stepper>
            <div>
              {activeStep === steps.length ? null : (
                <div>
                  <Mui.Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Mui.Typography>
                </div>
              )}
            </div>
          </div>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );
});
