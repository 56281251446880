import * as Mui from "@material-ui/core";

export declare namespace Typography {
  export interface Props {}
}

// eslint-disable-next-line no-redeclare
export function Typography(
  props?: Typography.Props
): Pick<Mui.ThemeOptions, "typography"> {
  return {
    typography: {
      fontFamily: ["Open Sans", "sans-serif"].join(", "),
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
  };
}
